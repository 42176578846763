import React from 'react';

// 假设我们有一个函数用来获取当前用户的权限
function getCurrentUserPermissions() {
    // 这里应该从后端获取用户权限信息
    // 返回一个权限列表，例如 ['admin', 'user']
    let user = JSON.parse(localStorage.getItem("User"));
    return user.roles;
}

class Authority extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userPermissions: getCurrentUserPermissions(),
        };
    }

    render() {
        const { permission, children } = this.props;
        const { userPermissions } = this.state;

        if (userPermissions.some(userPerm => permission.includes(userPerm))) {
            return <>{children}</>;
        } else {
            return null;
        }
    }
}

export default Authority;