import React from "react";

import {Button, Col, Row, Space} from "antd";

import AccountApi from "api/user/account";
import {ProForm, ProFormText} from "@ant-design/pro-components";
import {setFieldsError} from "plugin/formily";
import DraggableModal from "component/DraggableModal";

/**
 * 重置密码对话框
 * 输入新密码 确认新密码 调用修改账户密码接口 修改账户密码
 */
export default class ResetPasswordModal extends React.Component {

    state = {
        show: false,
        loading: false,
        accountId: ""
    };

    formRef = React.createRef();
    draggableRef = React.createRef();

    /**
     * 控制重置密码对话框显示
     */
    show = id => {
        this.draggableRef.current.show();
        this.setState({accountId: id});
    };

    close = () => {
        this.setState({loading: false});
        this.draggableRef.current.close();
    };

    /**
     * 控制 loading 加载效果 显示
     */
    toggleLoading = () => {
        this.setState({
            loading: !this.state.loading
        });
    };

    /**
     * 重置密码
     * @param data  表单数据
     */
    handle = (data) => {
        if (data.confirm_password !== data.password) {
            this.formRef.current.setFields([{name: "password", errors: ["两次密码不一致"]}, {
                name: "confirm_password",
                errors: ["两次密码不一致"]
            }]);
            return;
        }
        data.accountId = this.state.accountId;
        this.toggleLoading();
        AccountApi.resetPassword(data).then((response) => {
            console.info("create account success", response);
            this.close();
            this.props.onSuccess();
        }).catch(error => {
            this.toggleLoading();
            if (error.status === 40000) {
                setFieldsError(this.formRef, error.data);
            }
            console.warn("create account failed", error);
        });
    };

    render() {
        return <DraggableModal onCancel={this.close} title="重置密码" ref={this.draggableRef}>
            <ProForm formRef={this.formRef} onFinish={this.handle} layout={"vertical"} submitter={{
                resetButtonProps: {style: {display: "none"}},
                submitButtonProps: {style: {display: "none"}}
            }}>
                <ProFormText.Password label="密码" name="password" required placeholder="请输入密码"
                                      rules={[{required: true, message: "请输入密码"}]} maxLength="20" minLength="6"/>
                <ProFormText.Password label="确认密码" name="confirm_password" required placeholder="请确认密码"
                                      rules={[{required: true, message: "确认密码"}]} maxLength="20" minLength="6"/>
                <Row gutter={8} justify="center">
                    <Col>
                        <Space>
                            <Button disabled={this.state.loading} onClick={this.close}>取消</Button>
                            <Button loading={this.state.loading} type="primary" htmlType="submit">重置</Button>
                        </Space>
                    </Col>
                </Row>
            </ProForm>
        </DraggableModal>;
    }
}
