import React from "react";
import {Button, Cascader, Space, Tooltip} from "antd";
import {DeleteOutlined, EditOutlined, EyeOutlined} from "@ant-design/icons";
import {ProTable,PageContainer} from "@ant-design/pro-components";

import CustomerApi from "api/customer/customer";

import DeleteCustomerDialog from "./modal/DeleteCustomerDialog";
import CreateCustomerModal from "./modal/CreateCustomerModal";
import UpdateCustomerDialog from "./modal/UpdateCustomerDialog";
import PreviewCustomerEquipmentDrawer from "./drawer/PreviewCustomerEquipmentDrawer";
import CustomerDepartmentApi from "../../../../api/customer/department";
import {getUser} from "../../../../component/SecurityInterceptor";

/**
 * 客户
 *
 * @description 客户
 *
 * @author huang Carder
 * @since 2021-06-23
 * @version 1.0.0
 */
export default class Customers extends React.Component {

    state = {
        columnStateMap: {
            email: {
                show: false
            }
        }
    };

    actionRef = null;

    createRef = React.createRef();
    editRef = React.createRef();
    deleteRef = React.createRef();
    previewCustomerEquipmentRef = React.createRef();

    renderColumnOperations = entity => <Space>
        <Tooltip title="查看客户设备">
            <Button icon
                    size="small"
                    onClick={() => this.previewCustomerEquipmentRef.current.show(entity.id)}>
                <EyeOutlined/>
            </Button>
        </Tooltip>
        <Tooltip title="编辑">
            <Button icon size="small" onClick={() => this.editRef.current.show(entity.id)}><EditOutlined/></Button>
        </Tooltip>
        <Tooltip title="删除">
            <Button icon size="small" danger onClick={() => this.deleteRef.current.show([entity.id])}><DeleteOutlined/></Button>
        </Tooltip>
    </Space>;

    columns = [
        {
            title: "ID",
            hideInTable: true,
            hideInSearch: true,
            sorter: (a, b) => a.id - b.id,
            dataIndex: "id"
        },
        {
            title: "客户姓名",
            sorter: (a, b) => a.name.localeCompare(b.name),
            dataIndex: "name"
        },
        {
            title: "手机号",
            hideInForm: true,
            dataIndex: "cellphone"
        },
        {
            title: "邮箱",
            hideInSearch: true,
            dataIndex: "email",
            ellipsis: true,
            key: "email"
        },
        {
            title: "所在单位",
            hideInSearch: true,
            dataIndex: "departmentId",
            valueType: "treeSelect",
            fieldProps: {
                showSearch: true,
                debounce: 500,
                fieldNames: {label: "name", value: "id"},
                treeNodeFilterProp: "name"
            },
            request: () => CustomerDepartmentApi.tree(getUser().departments[0].id).then(res => res.data).catch(() => []),
        },
        {
            title: "所在区县",
            key: "locate",
            hideInTable: true,
            renderFormItem: (_, {type, defaultRender, formItemProps, fieldProps}) => {
                return (
                    <Cascader options={require("asset/location.json")} {...fieldProps}/>
                );
            }
        },
        {
            title: "所在省",
            dataIndex: "province",
            hideInSearch: true
        },
        {
            title: "所在市",
            dataIndex: "city",
            hideInSearch: true
        },
        {
            title: "所在区/县",
            dataIndex: "county",
            hideInSearch: true
        },
        {
            title: "详细地址",
            dataIndex: "address",
            hideInSearch: true,
            ellipsis: true
        },
        {
            title: "创建时间",
            hideInSearch: true,
            sorter: (a, b) => a.createTime.localeCompare(b.createTime),
            dataIndex: "createTime",
            valueType: "dateTime"
        },
        {
            title: "操作",
            valueType: "option",
            render: (_, entity) => this.renderColumnOperations(entity)
        }
    ];

    renderToolbar = () => <Space>
        <Button type="primary" onClick={() => this.createRef.current.show()}>新建客户</Button>
    </Space>;

    /**
     * 请求表格信息
     * @param params
     */
    requestPage = params => {
        if (!!params.locate) {
            params.province = params.locate[0];
            params.city = params.locate[1];
            params.county = params.locate[2];
            params.locate = undefined;
        }
        return CustomerApi.page(params).then(response => {
            return {data: response.data.records, success: true, total: response.data.total};
        }).catch(error => {
            console.log("Get customer page failed", error);
            return {data: [], success: true, total: 0};
        });
    };

    reload = () => this.actionRef?.reload();

    onColumnStateMapChange = columnStateMap => this.setState({columnStateMap});

    render() {
        return <PageContainer header={{title: "客户", breadcrumb: {}}} extra={this.renderToolbar()}>
            <ProTable headerTitle="客户信息"
                      rowKey="id"
                      defaultSize="small"
                      options={{reload: true, setting: true, density: true}}
                      actionRef={actionRef => this.actionRef = actionRef}
                      columnsState={this.state.columnStateMap}
                      onColumnsStateChange={this.onColumnStateMapChange}
                      columns={this.columns}
                      request={this.requestPage}/>

            <UpdateCustomerDialog onSuccess={this.reload} ref={this.editRef}/>
            <DeleteCustomerDialog onSuccess={this.reload} ref={this.deleteRef}/>
            <CreateCustomerModal onSuccess={this.reload} ref={this.createRef}/>
            <PreviewCustomerEquipmentDrawer onSuccess={this.reload} ref={this.previewCustomerEquipmentRef}/>
        </PageContainer>;
    }
}
