import React, {Component} from "react";
import DraggableModal from "component/DraggableModal";
import {ProForm, ProFormSelect} from "@ant-design/pro-components";
import EquipmentTypeApi from "api/equipment/type";

/**
 * @author       : AngelBeats
 * @date         : 2022/12/19 10:01
 * @description  : 导入已有设备类型弹窗
 */
export default class ExportAlreadyDeviceType extends Component {

    state = {
        deviceType: {},
        loading: false,
        deviceTypeData: []
    };

    draggableRef = React.createRef();

    show = () => {
        this.draggableRef.current.show();
        this.requestDeviceType();
    };

    close = () => {
        this.draggableRef.current.close();
        this.setState({deviceTypeData: []});
    };

    toggleLoading = () => this.setState({loading: !this.state.loading});

    requestDeviceType = () => {
        EquipmentTypeApi.selectAll().then(res => {
            res.data.forEach(item => this.setState({
                deviceTypeData: [...this.state.deviceTypeData, {
                    label: item.name,
                    value: item.id, ...item
                }]
            }));
            console.log(this.state.deviceTypeData);
        }).catch(err => {
            console.error("Requst device type all failed", err);
        });
    };

    selectChange = value => {
        if (!!value) {
            for (let item of this.state.deviceTypeData) {
                if (item.id === value) {
                    this.props.onSuccess(item);
                    this.close();
                    return;
                }
            }
        }
    };

    render() {
        return (<DraggableModal onCancel={this.close} title="导入设备类型" ref={this.draggableRef}>
            <ProForm formRef={this.formRef} onFinish={this.handle} layout={"vertical"} submitter={{
                resetButtonProps: {style: {display: "none"}},
                submitButtonProps: {style: {display: "none"}}
            }}>

                <ProFormSelect required rules={[{required: true, message: "请选择设备类型"}]} name="deviceType"
                               label="设备类型"
                               showSearch
                               options={this.state.deviceTypeData}
                               fieldProps={{onChange: this.selectChange}}/>

                {/*<Row justify="end">*/}
                {/*    <Col>*/}
                {/*        <Space>*/}
                {/*            <Button disabled={this.state.loading} onClick={this.close}>取消</Button>*/}
                {/*            <Button loading={this.state.loading} type="primary" htmlType="submit">导出</Button>*/}
                {/*        </Space>*/}
                {/*    </Col>*/}
                {/*</Row>*/}
            </ProForm>
        </DraggableModal>);
    }

}
