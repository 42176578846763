import {Button, Col, Divider, Drawer, Row, Space, Table, Tag, Timeline, Tooltip} from "antd";
import React, {Component} from "react";
import RemoteApi from "../../../../api/remote/RemoteApi";
import {ProDescriptions} from "@ant-design/pro-components";
import {DownOutlined, EyeOutlined, UpOutlined} from "@ant-design/icons";
import HistorApi from "../../../../api/remote/HistoryApi";
import styles from "./index.module.css"
import PreviewRecordDrawer from "./PreviewRecordDrawer";

export default class PreviewRemoteDrawer extends Component {
   state = {
       drawerWidth:700,
       remoteDeviceId: null,
       loading: false,
       show: false,
       sn: null,
       workorder: {},
       logs: [],
       allLogs: [], //所有日志
       newLogs: [],//最新三条
       historyList : []
   }
   columns = [
        {
            title: "设备ID",
            dataIndex: "id",
            key: "id"
        },
        {
            title: '关联工单编号',
            dataIndex: 'associatedWorkorderNumber',
            key: 'associatedWorkorderNumber',
        },
        {
            title: '关联客户单位',
            dataIndex: 'relatedCustomerDepartment',
            key: 'relatedCustomerDepartment',
        },
        {
            title: '操作人',
            dataIndex: 'operator',
            key: 'operator',
        },
        {
            title: '访问时间',
            dataIndex: 'startTime',
            key: 'startTime',
        },
        {
            title: '退出时间',
            key: 'endTime',
            dataIndex: 'endTime',
        },
        {
            title: "操作",
            valueType: "option",
            render: (node, entity, index, action) => this.renderColumnOperations(node, entity, index, action)
        },
    ];

    previewrecordRef = React.createRef();

    renderColumnOperations = (node, entity, index, action) => [
        <Tooltip title="查看" key="preview">
            <Button icon size="small" onClick={() => this.previewrecordRef.current.show(entity)}>
                <EyeOutlined/>
            </Button>
        </Tooltip>,
    ];

    show = (entity) => {
        this.setState({
            show: true,
            remoteDeviceId: entity.id,
            drawerWidth: window.innerWidth < 576 ? window.innerWidth : window.innerWidth * 0.55
        }, () => {
            this.requestRemoteDeviceInfo(entity.id)
            this.requestRemoteRecordDeviceLogs({deviceNumber: entity.sn}) // 查询远程设备日志
            this.requestRemoteDeviceHistory({deviceNumber: entity.sn})
        });
    };

    close = () => this.setState({show: false, loading: false});
    /**
     * 查询远程设备信息
     */
    requestRemoteDeviceInfo = id => {
        return RemoteApi.find(id).then(res => {
            this.setState({workorder: res.data});
        }).catch(err => {
            console.error("request remote device info failed", err)
        })
    }
    /**
     * 查询远程设备日志
     * @param params
     */
    requestRemoteRecordDeviceLogs = params => {
        RemoteApi.disconnectDeviceLogs(params).then(res => {
            if (res.status === 20000){
                let logsData = []
                res.data.forEach(item => {
                    logsData.push({
                        children: item.log + " " + item.createTime,
                    })
                })
                this.setState({allLogs: logsData})
                // 取logsData数组 最后三个
                this.setState({newLogs: logsData.slice(logsData.length - 3, logsData.length),logs: logsData.slice(logsData.length - 3, logsData.length)})
            }
        }).catch(err => {
            console.error("request remote device info failed", err)
        })
    }
    /**
     * 切换日志
     */
    onChangeLogs = () => {
        let {logs, allLogs, newLogs} = this.state
        if(logs.length === 3){
            this.setState({logs: allLogs})
            console.log(3)
        }else {
            this.setState({logs: newLogs})
            console.log(1)
        }

    }
    /**
     * 远程连接历史
     */
    requestRemoteDeviceHistory = params => {
        HistorApi.page(params).then(res => {
            this.setState({historyList: res.data.records})
        }).catch(err => {
            console.error("request remote device info failed", err)
        })
    }
    render()
    {
        return (
            <Drawer
                title="查看远程设备详情"
                width={this.state.drawerWidth}
                onClose={this.close}
                open={this.state.show}
                bodyStyle={{ paddingBottom: 80 }}
            >
                <ProDescriptions bordered size="small" layout="horizontal" column={window.innerWidth < 800 ? 1 : 4} dataSource={this.state.workorder}>
                    <ProDescriptions.Item label="设备名称" span={4} dataIndex="name"/>
                    <ProDescriptions.Item label="设备sn码" span={4} dataIndex="sn"/>
                    <ProDescriptions.Item label="客户单位" span={4} dataIndex={["customerDepartmentObj", "name"]}/>
                    <ProDescriptions.Item label="客户" span={4} dataIndex={["customerObj", "name"]}/>
                    <ProDescriptions.Item label="创建时间" span={4} dataIndex="createTime"/>
                    <ProDescriptions.Item label="修改时间" span={4} dataIndex="modifyTime"/>
                    <ProDescriptions.Item label="设备日志" span={4} >
                        <div className={styles.logsBox}>
                            <Timeline items={this.state.logs}/>
                            <div style={{textAlign: "left"}}>
                                <Button type="link" onClick={this.onChangeLogs} iconPosition="end">
                                    {this.state.logs.length === 3 ? "查看全部" : "收起"}
                                </Button>
                            </div>
                        </div>
                    </ProDescriptions.Item>
                </ProDescriptions>
                <Divider orientation="left">远程操作历史</Divider>
                <Table columns={this.columns} dataSource={this.state.historyList} />

                <PreviewRecordDrawer ref={this.previewrecordRef} onSuccess={this.reload}/>
            </Drawer>
        )
    }
}