import React, {Component} from "react";
import {Button, Drawer, Space, Tooltip} from "antd";
import {ProTable} from "@ant-design/pro-components";
import {DeleteOutlined, EditOutlined, EyeOutlined} from "@ant-design/icons";

import CustomerApi from "api/customer/customer";

import CreateCustomerModal from "../../customer/modal/CreateCustomerModal";
import UpdateCustomerDialog from "../../customer/modal/UpdateCustomerDialog";
import DeleteCustomerDialog from "../../customer/modal/DeleteCustomerDialog";
import PreviewCustomerEquipmentDrawer from "../../customer/drawer/PreviewCustomerEquipmentDrawer";

/**
 * 客户单位下的客户 Drawer
 *
 * @description 查看客户单位下的客户
 */
class PreviewCustomerDepartmentCustomerDrawer extends Component {

    state = {
        departmentCustomerDrawerShow: false,
        drawerWidth: 300,
        departmentName: "",
        departmentId: "",
        columnsStateMap: {
            email: {
                show: false
            },
            createTime: {
                show: false
            },
            address: {
                show: false
            }
        },
        customerData: []
    };

    createRef = React.createRef();
    editRef = React.createRef();
    deleteRef = React.createRef();
    previewCustomerEquipmentRef = React.createRef();

    columns = [
        {
            title: "ID",
            hideInTable: true,
            hideInSearch: true,
            dataIndex: "id",
            editable: false
        },
        {
            title: "客户姓名",
            dataIndex: "name"
        },
        {
            title: "手机号",
            hideInForm: true,
            dataIndex: "cellphone",
            editable: false
        },
        {
            title: "邮箱",
            hideInSearch: true,
            dataIndex: "email",
            ellipsis: true,
            key: "email"
        },
        {
            title: "所在省",
            dataIndex: "province",
            hideInSearch: true
        },
        {
            title: "所在市",
            dataIndex: "city",
            hideInSearch: true
        },
        {
            title: "所在区/县",
            dataIndex: "county",
            hideInSearch: true
        },
        {
            title: "详细地址",
            dataIndex: "address",
            hideInSearch: true,
            ellipsis: true,
            key: "address"
        },
        {
            title: "创建时间",
            hideInSearch: true,
            dataIndex: "createTime",
            valueType: "dateTime",
            key: "createTime"
        },
        {
            title: "操作",
            valueType: "option",
            render: (_, entity) => this.renderColumnOperations(entity)
        }
    ];

    renderColumnOperations = entity => <Space>
        <Tooltip title="查看客户设备">
            <Button icon
                    size="small"
                    onClick={() => this.previewCustomerEquipmentRef.current.show(entity.id)}>
                <EyeOutlined/>
            </Button>
        </Tooltip>
        <Tooltip title="编辑">
            <Button icon size="small" onClick={() => this.editRef.current.show(entity.id)}><EditOutlined/></Button>
        </Tooltip>
        <Tooltip title="删除">
            <Button icon size="small" danger onClick={() => this.deleteRef.current.show([entity.id])}><DeleteOutlined/></Button>
        </Tooltip>
    </Space>;

    toolbarRender = () => <Button type="primary"
                                  onClick={() => this.createRef.current.show(this.state.departmentId)}>新建客户</Button>;


    show = (id, name) => {
        this.setState({
            departmentCustomerDrawerShow: true,
            drawerWidth: window.innerWidth < 576 ? window.innerWidth : window.innerWidth * 0.55,
            departmentName: name,
            departmentId: id
        });
    };

    close = () => this.setState({departmentCustomerDrawerShow: false});

    onColumnsStateMap = columnsStateMap => this.setState({columnsStateMap});

    reload = () => this.actionRef?.reload();

    requestPage = params => {
        if (!!this.state.departmentId) {
            params.departmentId = this.state.departmentId;
        }
        return CustomerApi.page(params).then(response => {
            return {data: response.data.records, success: true, total: response.data.total};
        }).catch(error => {
            console.log("Get customer page failed", error);
            return {data: [], success: true, total: 0};
        });
    };

    render() {
        return <Drawer title={this.state.departmentName} placement="right" width={this.state.drawerWidth}
                       onClose={this.close} open={this.state.departmentCustomerDrawerShow} destroyOnClose>
            <ProTable rowKey="id"
                      defaultSize="small"
                      bordered
                      toolBarRender={this.toolbarRender}
                      options={{reload: true, setting: true, density: true}}
                      actionRef={actionRef => this.actionRef = actionRef}
                      columns={this.columns}
                      columnsState={this.state.columnsStateMap}
                      onColumnsStateChange={this.onColumnsStateMap}
                      request={this.requestPage}/>
            <CreateCustomerModal onSuccess={this.reload} ref={this.createRef}/>
            <UpdateCustomerDialog onSuccess={this.reload} ref={this.editRef}/>
            <DeleteCustomerDialog onSuccess={this.reload} ref={this.deleteRef}/>
            <PreviewCustomerEquipmentDrawer onSuccess={this.reload} ref={this.previewCustomerEquipmentRef}/>
        </Drawer>;
    }
}

export default PreviewCustomerDepartmentCustomerDrawer;
