import React from "react";
import {Button, Cascader, Col, Form, Input, message, Row, Skeleton, Space} from "antd";

import CustomerApi from "api/customer/customer";
import CustomerDepartmentPicker from "component/customer/department/CustomerDepartmentPicker";
import {setFieldsError} from "plugin/formily";
import DraggableModal from "component/DraggableModal";

/**
 * 编辑客户弹窗 Modal
 *
 * @description 编辑客户
 *
 * @author huang Carder
 * @since 2021-06-23
 * @version 1.0.0
 */
export default class UpdateCustomerDialog extends React.Component {

    state = {
        show: false,
        loading: false,
        customerLoading: true,
        oldCustomerInformation: {}
    };

    formRef = React.createRef();
    draggableRef = React.createRef();

    show = id => {
        this.draggableRef.current.show();
        this.getCustomerInfo(id);
    };

    close = () => {
        this.setState({loading: false});
        this.draggableRef.current.close();
    };

    /**
     * 获取客户信息
     * @param id
     */
    getCustomerInfo = id => {
        CustomerApi.find(id).then(res => {
            let params = {
                departmentId: res.data.departmentId,
                cellphone: res.data.cellphone,
                email: res.data.email,
                name: res.data.name,
                address: res.data.address,
                ares: [],
                id: res.data.id
            };
            params.ares[0] = res.data.province;
            params.ares[1] = res.data.city;
            params.ares[2] = res.data.county;
            this.setState({
                customerLoading: false,
                oldCustomerInformation: res.data
            }, () => this.formRef.current.setFieldsValue(params));
        }).catch(err => {
            this.setState({customerLoading: false});
            console.error("Request customer failed.", err);
        });
    };

    toggleLoading = () => this.setState({loading: !this.state.loading});

    /**
     * 判断客户信息是否更改
     * @param param 客户新信息
     */
    isNecessaryToHandle = param => {

        const old = {
            id: this.state.oldCustomerInformation.id,
            departmentId: this.state.oldCustomerInformation.departmentId,
            ares: null,
            address: this.state.oldCustomerInformation.address,
            name: this.state.oldCustomerInformation.name,
            cellphone: this.state.oldCustomerInformation.cellphone,
            email: this.state.oldCustomerInformation.email,
            province: this.state.oldCustomerInformation.province,
            city: this.state.oldCustomerInformation.city,
            county: this.state.oldCustomerInformation.county
        };

        if (Object.entries(param).toString() === Object.entries(old).toString()) {
            return true;
        }
    };

    /**
     * 编辑客户
     * @param data 提交数据
     */
    handle = data => {
        data.province = data.ares[0];
        data.city = data.ares[1];
        data.county = data.ares[2];
        data.ares = null;
        data.name = data.name.trim();
        if (this.isNecessaryToHandle(data)) {
            this.close();
            return;
        }
        this.toggleLoading();
        CustomerApi.update(data).then(() => {
            this.close();
            this.props.onSuccess();
        }).catch(error => {
            if (error.status === 40000) {
                setFieldsError(error, this.formRef);
            } else {
                message.error({key: "failed", content: error.data});
            }
            this.toggleLoading();
            console.warn("Update customer failed", error);
        });
    };

    render() {
        return <DraggableModal onCancel={this.close} title="更新客户信息" ref={this.draggableRef}>
            <Skeleton active loading={this.state.customerLoading}>
                <Form ref={this.formRef} onFinish={this.handle} layout="vertical">
                    <Form.Item hidden name="id" label="ID">
                        <Input/>
                    </Form.Item>
                    <Row gutter={8}>
                        <Col span={12}>
                            <CustomerDepartmentPicker name="departmentId"
                                                      rules={[{required: true, message: "请选择客户单位"}]}/>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="ares" label="客户地址:" required
                                       rules={[{required: true, message: "请选择客户地址"}]}>
                                <Cascader options={require("asset/location.json")}/>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name="address" label="楼层及门牌号:" required
                                       rules={[{required: true, message: "请填写详细地址"},
                                           {pattern: /\S/g, message: "内容不能为空"}]}>
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name="name" label="客户姓名:" required rules={[
                                {required: true, message: "请输入客户姓名"},
                                {pattern: /\S/g, message: "内容不能为空"}
                            ]}>
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="cellphone" label="手机号:" required
                                       rules={[
                                           {required: true, message: "请输入手机号"},
                                           {pattern: /^[0-9]*$/g, message: "请输入正确的手机号"}
                                       ]}>
                                <Input/>
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item name="email" label="邮箱:" rules={[
                                {pattern: /\S/g, message: "不能输入空字符串"}
                            ]}>
                                <Input/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={8} justify="end">
                        <Col>
                            <Space>
                                <Button disabled={this.state.loading} onClick={this.close}>取消</Button>
                                <Button loading={this.state.loading} type="primary" htmlType="submit">更新</Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
            </Skeleton>
        </DraggableModal>;
        // <Modal title="更新客户信息" visible={this.state.show} confirmLoading={this.state.loading} footer={null}
        //               centered
        //               destroyOnClose
        //               onCancel={this.close}>
        //
        // </Modal>;
    }
}
