const StorehouseInventoryApi = {
    /**
     * 仓库库存分页
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    page: params => global.axios.get("/repository/repository-stock/page", {params}),

    /**
     * 查询调拨单
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    schedulePage: params => global.axios.get("/repository/schedules/page", {params}),

    /**
     * 添加库存
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    create: data => global.axios.post("/repository/add", data),

    /**
     * 仓库调拨
     * @param data 调拨数据
     * @returns {Promise<AxiosResponse<any>>}
     */
    schedule: data => global.axios.post("/repository/schedule", data),

    /**
     * 领用备件
     * @param data 领用参数
     * @returns {Promise<AxiosResponse<any>>}
     */
    solicit: data => global.axios.post("/repository/solicit", data),

    /**
     * 退还备件
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    revert: data => global.axios.post("/repository/revert", data),

    /**
     * 销毁备件
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    destroy: data => global.axios.post("/repository/destroy", data),

    /**
     * 查询仓库备件详情
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    repositoryStock: id => global.axios.get(`/repository/repository-stock/${id}`),

    /**
     * 查询仓库库存总数量
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    inventoryTotal: params => global.axios.get("/repository/repository-stock/sum", {params}),

    /**
     * 查询领用数量情况
     * @param solicit
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    revertCount: (solicit, params) => global.axios.get(`/repository/solicit/${solicit}/revert-count`, {params}),

    /**
     * 分页查询退还审核单
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    revertAudit: params => global.axios.get("/repository/reverts/page", {params}),

    /**
     * 查看工单审领备件
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    previewWorkorderSolicit: params => global.axios.get("/repository/solicit", {params})
};

export default StorehouseInventoryApi;
