import React, {useEffect, useRef, useState} from 'react';
import {Badge, Divider, Dropdown, Typography} from "antd";
import RevisePassword from "./components/RevisePassword";
import SetStatus from "./components/SetStatus";
import {getUser} from "../SecurityInterceptor";
import Upload from "antd/es/upload/Upload";
import Avatar from "antd/es/avatar/avatar";
import {UserOutlined} from "@ant-design/icons";

import style from './upload.css'

/**
 * @author       : AngelBeats
 * @date         : 2023-02-22 11:30:38
 * @description  : 退出登录
 */
const UserLabel = () => {

    const [userName, setUserName] = useState("");
    const [avatarImg, setAvatarImg] = useState(getUser()?.avatar || "");
    const [status, setStatus] = useState(getUser()?.situation || "0");

    const revisePasswordRef = useRef(null);
    const setStatusRef = useRef(null);

    const statusColor = {
        0: "gold",
        1: "green",
        2: "red"
    };

    const statusText = {
        0: "休息中",
        1: "工作中",
        2: "非常忙碌"
    };

    useEffect(() => {
        getUserInfo();
    }, [])

    const items = [
        {
            label: <a href="https://www.antgroup.com">1st menu item</a>,
            key: '0',
        },
        {
            label: <a href="https://www.aliyun.com">2nd menu item</a>,
            key: '1',
        },
        {
            type: 'divider',
        },
        {
            label: '3rd menu item',
            key: '3',
        },
    ];
    const userMenu = () => [
        {
            label: <Badge.Ribbon key="status" text={statusText[status]}
                                 color={statusColor[status]}
                                 style={{top: 0}}>
                <Typography.Paragraph style={{width: 200, padding: "5px 12px", marginBottom: 0}}>
                    姓名：{getUser()?.name}
                    <span style={{display: "inline-block", marginTop: 8}}>所在单位：{getUser()?.departments[0]?.name}</span>
                </Typography.Paragraph>
                <Divider style={{margin: "0"}}/>
            </Badge.Ribbon>,
            key: "0"
        },
        // {
        //     label: <a key="setStatus" onClick={againSetStatus}>设置状态</a>,
        //     key: "1"
        // },
        {
            label: <Upload
                name="avatar"
                accept="image/*"
                className={style.avatar}
                style={{display: "inline-block", width: "300px"}}
                action={global.config.url + `/user/account/${getUser()?.id}/avatar`}
                headers={{token: localStorage.getItem("Token")}}
                maxCount={1}
                showUploadList={false}
                onChange={onChange}>
                设置头像
            </Upload>,
            key: "2"
        },
        {
            label: <div key="password" onClick={restPassword}>修改密码</div>,
            key: "3"
        },
        {
            label: <div key="exit" onClick={exit}>退出登录</div>,
            key: "4"
        },
    ]

    /**
     * 设置状态
     */
    const againSetStatus = () => setStatusRef.current.show();

    /**
     * 退出登录
     */
    const exit = () => {
        window.location.replace("/login");
        localStorage.removeItem("Token");
        localStorage.removeItem("User");
        localStorage.removeItem("_AMap_AMap.MarkerCluster");
        localStorage.removeItem("_AMap_AMap.Geocoder");
    };

    /**
     * 打开修改密码弹窗
     */
    const restPassword = () => {
        revisePasswordRef.current.show();
    };

    /**
     * 获取用户信息
     */
    const getUserInfo = () => {
        let user = getUser();
        if (!user) {
            window.location.replace("/login");
        }
        setUserName(user?.name)
    };

    const onChange = (value) => {
        if (value.file.status === "done") {
            setAvatarImg(value.file.response.data)
            localStorage.setItem("User", JSON.stringify({...getUser(), avatar: value.file.response.data}));
        }
    };

    const updateStatus = value => setStatus(value)

    return (<>
        <Dropdown menu={{items: userMenu()}} trigger={["click"]} placement="bottomLeft" arrow>
            <Badge dot color={statusColor[status]} offset={[2, 16]}
                   style={{width: 11, height: 11}}>
                <Avatar icon={<UserOutlined/>} size={36} style={{cursor: "pointer"}} src={avatarImg}/>
            </Badge>
        </Dropdown>
        <RevisePassword ref={revisePasswordRef}/>
        <SetStatus ref={setStatusRef} onSuccess={updateStatus}/>
    </>);
};

export default UserLabel;
