import React, {Component} from "react";
import {ProForm, ProFormCascader, ProFormSelect, ProFormText, ProFormTreeSelect} from "@ant-design/pro-components";

import DraggableModal from "component/DraggableModal";
import {getUser, hasAnyRole, isAdmin} from "component/SecurityInterceptor";

import DepartmentApi from "api/user/department";
import StorehouseApi from "api/spares/storehouse";
import {Button, Col, Row, Space} from "antd";
import FileUploadRender from "component/FileUploadRender";


class CreateStorehouseModal extends Component {

    draggableRef = React.createRef();
    formRef = React.createRef();
    fileUploadRef = React.createRef();
    map;

    state = {
        departmentAdmin: [],
        loading: false,
        lnglatDataLng: "",
        lnglatDataLat: "",
        level: null
    };

    toggleLoading = () => this.setState({loading: !this.state.loading});

    show = () => {
        this.draggableRef.current.show();
        this.requestDepartment();
        //增加延迟时间 使 DOM 渲染出来后调用 getMap方法
        setTimeout(this.getMap, 100);
    };

    close = () => {
        this.draggableRef.current.close();
        this.setState({loading: false}, () => {
            this.map.destroy();
        });

    };

    requestDepartment = () => {
        const shouldRequestAllDepartment = isAdmin();
        return shouldRequestAllDepartment ? this.requestAllDepartment() : this.requestUserDepartment();
    };

    requestUserDepartment = () => {
        const userDepartments = getUser().departments;
        if (userDepartments !== undefined && userDepartments !== null && userDepartments.length >= 1) {
            return DepartmentApi.findDepartmentTree(userDepartments[0].id).then(response => {
                console.log(response);
                this.filterNowDepartmentWeight(response.data, userDepartments[0].id);
                console.log(this.state.level);
                return this.processUserDepartmentTreeData(response.data, 2);
            }).catch(error => {
                console.log("Get user department failed", error);
            });
        } else {
            return [];
        }
    };

    filterNowDepartmentWeight = (data, departmentId) => {
        for (let item of data) {
            if (item.id === departmentId) {
                this.setState({level: item.weight});
                break;
            }
            this.filterNowDepartmentWeight(item.children, departmentId);
        }
    };

    requestAllDepartment = () => {
        return DepartmentApi.findAllTrees().then(response => {
            return this.processUserDepartmentTreeData(response.data);
        }).catch(error => {
            console.log("Get all department failed", error);
            return [];
        });
    };

    processUserDepartmentTreeData = (tree, weight = 1) => tree.map(item => ({
        title: item.name,
        value: item.id,
        //disabled: item.weight < weight,
        children: !!item.children ? this.processUserDepartmentTreeData(item.children) : undefined
    }));

    onDepartmentChange = (value) => {
        this.formRef.current.setFieldsValue({managers: undefined})
        this.setState({departmentAdmin: []}, () => this.requestDepartmentAdmin(value));
    };

    requestDepartmentAdmin = (id) => {
        DepartmentApi.getDepartmentAccount(id).then(res => {
            this.handleDepartmentAdmin(res.data);
        }).catch(err => {
            console.error("Request department admin failed", err);
        });
    };

    handleDepartmentAdmin = (data) => {
        let departmentAdminList = []
        data.forEach(item => departmentAdminList.push({label: item.name, value: item.id}));
        this.setState({departmentAdmin: departmentAdminList})
    };

    getMap = () => {
        this.map = new AMap.Map("map-container", {
            pitch: 0,
            viewMode: "3D",
            terrain: true,
            center: [114.973172, 25.817861],
            zoom: 17,
            zoomEnable: false,
            dragEnable: false,
            resizeEnable: true
        });

        AMap.plugin(["AMap.PlaceSearch", "AMap.AutoComplete"], () => {
            new AMap.AutoComplete({input: "address"}).on("select", e => {
                this.formRef.current.setFieldsValue({address: e.poi.name});
                this.map.clearMap();
                this.map.add(new AMap.Marker({position: [e.poi.location.lng, e.poi.location.lat]}));
                this.setState({lnglatDataLng: e.poi.location.lng, lnglatDataLat: e.poi.location.lat});
                this.map.setFitView();
                let lnglat = [e.poi.location.lng, e.poi.location.lat];
                this.regeoCode(lnglat);
            });
        });
    };

    /**
     * 当所选的地址与详细地址不同时更新所选的地址
     * @param lnglat 经纬度
     */
    regeoCode = lnglat => new AMap.Geocoder().getAddress(lnglat, (status, result) => {
        // 特别行政区或直辖市的 city 为空 所有直接用 province
        this.formRef.current.setFieldsValue({
            ares: [
                result.regeocode.addressComponent.province,
                result.regeocode.addressComponent.city ? result.regeocode.addressComponent.city : result.regeocode.addressComponent.province,
                result.regeocode.addressComponent.district
            ]
        });
    });

    handle = (data) => {
        if (!!data.ares) {
            data.province = data.ares[0];
            data.city = data.ares[1];
            data.county = data.ares[2];
            data.ares = undefined;
        }
        data.files = this.fileUploadRef.current.getFilesValue();
        data.longitude = this.state.lnglatDataLng || null;
        data.latitude = this.state.lnglatDataLat || null;
        data.lnglat = `${this.state.lnglatDataLng},${this.state.lnglatDataLat}` || null;
        this.toggleLoading();
        StorehouseApi.create(data).then(res => {
            this.toggleLoading();
            if (!!this.props.onSuccess) {
                this.props.onSuccess()
            }
            this.close();
        }).catch(err => {
            this.toggleLoading();
            console.error("Create storehouse failed", err);
        });
    };

    render() {
        return (<DraggableModal onCancel={this.close} title="新建仓库" ref={this.draggableRef}>
                <ProForm formRef={this.formRef} onFinish={this.handle} layout={"vertical"} submitter={{
                    resetButtonProps: {style: {display: "none"}},
                    submitButtonProps: {style: {display: "none"}}
                }}>
                    <ProFormText name="name" label="仓库名称" placeholder="请输入仓库名称" required
                                 rules={[{required: true, message: "请输入仓库名称"}, {pattern: /\S/, message: "不能为空"}]}/>
                    <ProFormCascader required rules={[{required: true, message: "请选择所在地区"}]} name="ares" label="仓库地区"
                                     fieldProps={{options: require("asset/location.json")}} hidden/>
                    <ProFormTreeSelect required rules={[{required: true, message: "请选择机构"}]} name="department"
                                       label="机构"
                                       allowClear
                                       request={async () => {
                                           return this.requestDepartment();
                                       }}
                                       fieldProps={{
                                           showArrow: false,
                                           filterTreeNode: true,
                                           showSearch: true,
                                           treeNodeFilterProp: "title",
                                           onChange: this.onDepartmentChange
                                       }}/>
                    <ProFormSelect required rules={[{required: true, message: "请选择管理员"}]} name="managers" label="仓库管理员"
                                   fieldProps={{options: this.state.departmentAdmin, mode: "multiple"}}/>
                    <ProFormText name="address" label="详细地址" placeholder="请输入详细地址" required id="address"
                                 autoComplete="off"
                                 rules={[{required: true, message: "请输入详细地址"}, {pattern: /\S/, message: "不能为空"}]}/>
                    <div style={{width: "100%", height: "200px", margin: "16px 0", pointerEvents: "none"}}
                         id="map-container">
                    </div>
                    <FileUploadRender label="附件上传(最多三张)" uploadPath="/repository/repository/file"
                                      ref={this.fileUploadRef}
                                      uploadProps={{maxCount: 3}}/>
                    <Row justify="end">
                        <Col>
                            <Space>
                                <Button disabled={this.state.loading} onClick={this.close}>取消</Button>
                                <Button loading={this.state.loading} type="primary" htmlType="submit">新建</Button>
                            </Space>
                        </Col>
                    </Row>
                </ProForm>
            </DraggableModal>
        );
    }
}

export default CreateStorehouseModal;
