import React, {Component} from "react";
import {Button, Col, Row, Space} from "antd";
import WorkorderApi from "api/workorder";
import {ProForm, ProFormRate, ProFormTextArea} from "@ant-design/pro-components";
import {setFieldsError} from "plugin/formily";
import DraggableModal from "component/DraggableModal";

/**
 * 回访
 *
 * @description 工单的满意度
 */
export default class FeedbackWorkorderDialog extends Component {

    state = {
        loading: false,
        show: false,
        workorderId: ""
    };

    formRef = React.createRef();
    draggableRef = React.createRef();

    show = id => {
        this.draggableRef.current.show();
        this.setState({workorderId: id});
    };

    close = () => {
        this.setState({loading: false});
        this.draggableRef.current.close();
    };

    toggleLoading = () => this.setState({loading: !this.state.loading});

    /**
     * 工单回访
     * @param data 回访数据
     */
    handle = data => {
        this.toggleLoading();
        data.workorderId = this.state.workorderId;
        WorkorderApi.visit(data).then(response => {
            this.close();
            if (!!this.props.onSuccess) {
                this.props.onSuccess();
            }
        }).catch(error => {
            this.toggleLoading();
            setFieldsError(error,this.formRef);
            console.warn("Denied workorder failed", error);
        });
    };

    render() {
        return <DraggableModal onCancel={this.close} title="回访" ref={this.draggableRef}>
            <ProForm formRef={this.formRef} onFinish={this.handle} layout={"vertical"} submitter={{
                resetButtonProps: {style: {display: "none"}},
                submitButtonProps: {style: {display: "none"}}
            }}>
                <ProFormTextArea name="feedback" label="回访内容" placeholder="请输入回访内容"
                                 fieldProps={{maxLength: 120, showCount: true}}
                                 rules={[{pattern: /\S/, message: "不能为空"}]}/>
                <ProFormRate name="score" label="满意度" count={3} fieldProps={{count: 3, allowHalf: false}} required
                             rules={[{required: true, message: "请选择满意度"}]}/>
                <Row gutter={8} justify="end">
                    <Col>
                        <Space>
                            <Button disabled={this.state.loading} onClick={this.close}>取消</Button>
                            <Button loading={this.state.loading} type="primary" htmlType="submit">回访</Button>
                        </Space>
                    </Col>
                </Row>
            </ProForm>
        </DraggableModal>;
    }
}
