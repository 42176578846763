const EquipmentMakerApi = {
    /**
     * 分页查询
     */
    page: (data) => global.axios.get("/resource/manufacturers/page", {params: data}),
    /**
     * 添加制造商
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    create: (data) => global.axios.post("/resource/manufacturer", data),
    /**
     * 删除制造商
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    delete: (id) => global.axios.delete(`/resource/manufacturer/${id}`),
    /**
     * 查询指定制造商信息
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    info: (id) => global.axios.get(`/resource/manufacturer/${id}`),
    /**
     * 编辑制造商
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    edit: (data) => global.axios.patch(`/resource/manufacturer`, data),
    /**
     * 查询所有制造商
     * @returns {Promise<AxiosResponse<any>>}
     */
    queryAll: () => global.axios.get(`/resource/manufacturers`)
};

export default EquipmentMakerApi;
