import React, {Component} from "react";
import {Button, Col, Modal, Row, Space} from "antd";
import {getUser} from "../../SecurityInterceptor";
import {ProForm, ProFormText} from "@ant-design/pro-components";

class RevisePassword extends Component {

    state = {
        show: false,
        loading: false,
        base64Image: "",
        captchaId: ""
    };

    formRef = React.createRef();

    show = () => {
        this.setState({show: true});
    };

    close = () => {
        this.setState({
            show: false
        });
    };

    toggleLoading = () => {
        this.setState({
            loading: !this.state.loading
        });
    };

    /**
     * 获取验证码
     */
    getCaptcha = () => {
        window.axios.get("/user/captcha/update-account-password/base64", {
            params: {
                height: 44,
                width: 180
            }
        }).then(res => {
            this.setState({base64Image: res.data.base64Image, captchaId: res.data.captchaId});
        }).catch(error => {
            console.warn("Get captcha failed", error);
        });
    };

    /**
     * 用户重置密码
     * @param data  表单数据
     */
    handle = (data) => {
        if (data.newPassword === data.oldPassword) {
            this.formRef.current.setFieldsValue({captcha: ""});
            this.formRef.current.setFields([{name: "oldPassword", errors: ["新旧密码一致"]}, {
                name: "newPassword",
                errors: ["新旧密码一致"]
            }]);
            this.getCaptcha();
            return;
        }
        this.toggleLoading();
        data.captchaId = this.state.captchaId;
        data.accountId = getUser().id;
        global.axios.patch("/user/account/password", data).then((response) => {
            this.toggleLoading();
            window.location.replace("/login");
            localStorage.clear();
        }).catch(error => {
            this.toggleLoading();
            console.warn("Revise password failed.", error);
            // 重新获取验证码
            switch (error.status) {
                case 40000:
                    this.formRef.current.setFields([{name: "captcha", errors: [error.data]}]);
                    break;
                case 40400:
                    this.formRef.current.setFields([{name: "captcha", errors: [error.data]}]);
                    break;
                case 40001:
                    // 密码错误
                    this.formRef.current.setFieldsValue({captcha: ""});
                    this.formRef.current.setFields([{name: "oldPassword", errors: ["旧密码错误"]}]);
                    break;
                default:
                    console.log("error status", error);
            }
            this.getCaptcha();
        });
    };

    render() {
        return <Modal title="修改密码" open={this.state.show} confirmLoading={this.state.loading} footer={null} centered
                      onCancel={this.close} destroyOnClose>
            <ProForm formRef={this.formRef} onFinish={this.handle} layout={"vertical"} submitter={{
                resetButtonProps: {style: {display: "none"}},
                submitButtonProps: {style: {display: "none"}}
            }}>
                <ProFormText.Password label="旧密码" name="oldPassword" required placeholder="请输入旧密码"
                                      rules={[{required: true, message: "请输入旧密码"}]} maxLength="20" minLength="6"/>
                <ProFormText.Password label="新密码" name="newPassword" required placeholder="请输入新密码"
                                      rules={[{required: true, message: "请输入新密码"}]} maxLength="20" minLength="6"/>
                {/*<Row>*/}
                {/*    <Col span={12}>*/}
                {/*        <ProFormText name="captcha" label="验证码" placeholder="请输入验证码" required*/}
                {/*                     rules={[{required: true, message: "请输入验证码"}, {pattern: /\S/, message: "不能为空"}]}/>*/}
                {/*    </Col>*/}
                {/*    <Col span={12}>*/}
                {/*        <img src={this.state.base64Image} alt="验证码（点击刷新）" className={style.verifyImage}*/}
                {/*             onClick={this.getCaptcha}/>*/}
                {/*    </Col>*/}
                {/*</Row>*/}
                <Row gutter={8} justify="end">
                    <Col>
                        <Space>
                            <Button disabled={this.state.loading} onClick={this.close}>取消</Button>
                            <Button loading={this.state.loading} type="primary" htmlType="submit">修改</Button>
                        </Space>
                    </Col>
                </Row>
            </ProForm>
        </Modal>;
    }
}

export default RevisePassword;
