import React, {Component} from "react";
import {Descriptions, Skeleton} from "antd";

import DepartmentApi from "api/user/department";
import DraggableModal from "component/DraggableModal";

/**
 * 查看单位详情
 * 根据id 查看单位的详细信息  根据 Descriptions 渲染到页面
 */
export default class PreviewUserDepartmentDetailModal extends Component {

    state = {
        previewDepartmentDetailDialog: false,
        userDepartmentInfo: {},
        userDepartmentName: "",
        userDepartmentInfoLoading: true
    };

    draggableRef = React.createRef();

    show = (id, name) => {
        this.draggableRef.current.show();
        this.setState({
            userDepartmentName: name
        }, () => {
            this.getDepartmentDetail(id);
        });
    };

    close = () => this.draggableRef.current.close();

    getDepartmentDetail = id => {
        DepartmentApi.getDepartmentDetail(id).then(response => {
            this.setState({
                userDepartmentInfo: response.data,
                userDepartmentInfoLoading: false
            });
        }).catch(error => {
            this.setState({
                departmentInfoLoading: false
            });
        });
    };

    render() {
        return <DraggableModal onCancel={this.close} title={`${this.state.userDepartmentName}的单位信息`}
                               ref={this.draggableRef}>
            <Skeleton loading={this.state.userDepartmentInfoLoading}>
                <Descriptions
                    bordered
                    column={1}>
                    <Descriptions.Item label="单位名称">
                        {this.state.userDepartmentInfo.name}
                    </Descriptions.Item>
                    <Descriptions.Item label="单位区域">
                        {`${this.state.userDepartmentInfo.province} - ${this.state.userDepartmentInfo.city} - ${this.state.userDepartmentInfo.county}`}
                    </Descriptions.Item>
                    <Descriptions.Item label="单位详细地址">
                        {this.state.userDepartmentInfo.address}
                    </Descriptions.Item>
                </Descriptions>
            </Skeleton>
        </DraggableModal>;
    }
}

