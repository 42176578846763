import React, {Component} from "react";
import {Button, Drawer, Dropdown, Menu, Tooltip} from "antd";
import {ProTable} from "@ant-design/pro-components";
import {EditOutlined, EyeOutlined, MoreOutlined, SwapOutlined} from "@ant-design/icons";

import style from "../../../user/department/index.module.css";
import {isAdmin, isAdminDepartment} from "component/SecurityInterceptor";

import EquipmentListApi from "api/equipment/list";

import CreateEquipmentDrawer from "../../../equipment/list/drawer/CreateEquipmentDrawer";
import DeleteEquipmentModal from "../../../equipment/list/modal/DeleteEquipmentModal";
import EditEquipmentDrawer from "../../../equipment/list/drawer/EditEquipmentDrawer";
import PreviewEquipmentDrawer from "../../../equipment/list/drawer/PreviewEquipmentDrawer";
import QRCodeModal from "../../../equipment/list/modal/QRCodeModal";
import EquipmentQrCodeModal from "../../../equipment/list/modal/EquipmentQrCodeModal";
import MigrationEquipmentDrawer from "../../../equipment/list/drawer/MigrationEquipmentDrawer";

class PreviewDepartmentEquipmentDrawer extends Component {

    state = {
        drawerWidth: 300,
        show: false,
        departmentId: ""
    };
    createEquipmentRef = React.createRef();
    editEquipmentRef = React.createRef();
    deleteEquipmentRef = React.createRef();
    previewEquipmentRef = React.createRef();
    migrationEquipmentRef = React.createRef();
    QRCodeRef = React.createRef();
    equipmentQRCodeRef = React.createRef();

    show = (id) => {
        this.setState({
            show: true,
            drawerWidth: window.innerWidth < 576 ? window.innerWidth : window.innerWidth * 0.55,
            departmentId: id
        });
    };

    close = () => this.setState({show: false});

    renderColumnOperations = (node, entity, index, action) => {
        return [
            <Tooltip title="查看设备" key="preview">
                <Button icon
                        size="small"
                        onClick={() => this.previewEquipmentRef.current.show(entity.id)}>
                    <EyeOutlined/>
                </Button>
            </Tooltip>,
            <Tooltip title="编辑设备" key="edit">
                <Button icon
                        size="small"
                        onClick={() => this.editEquipmentRef.current.show(entity.id)}>
                    <EditOutlined/>
                </Button>
            </Tooltip>,
            <Tooltip title="迁移设备" key="migration">
                <Button icon
                        size="small"
                        onClick={() => this.migrationEquipmentRef.current.show(entity.id)}>
                    <SwapOutlined/>
                </Button>
            </Tooltip>,
            <Tooltip key="more" title="更多">
                <Dropdown key="more" overlay={this.renderColumnOptionsMenu(entity, action)} trigger={["click"]}>
                    <Button icon size="small"><MoreOutlined/></Button>
                </Dropdown>
            </Tooltip>
        ];
    };

    columns = [
        {
            title: "ID",
            dataIndex: "id",
            editable: false
        },
        {
            title: "设备名称",
            dataIndex: "name"
        },
        {
            title: "设备型号",
            dataIndex: "model"
        },
        {
            title: "录入人",
            dataIndex: "creator",
            hideInTable: true
        },
        {
            title: "客户",
            dataIndex: ["customer", "name"],
            hideInSearch: true
        },
        {
            title: "客户单位",
            dataIndex: ["customer", "department", "name"],
            hideInSearch: true
        },
        {
            title: "维护次数",
            dataIndex: "num"
        },
        {
            title: "地区",
            dataIndex: "province",
            hideInSearch: true,
            hideInTable: true,
            valueType: "option",
            render: (node, entity) => entity.province === entity.city ? `${entity.province}-${entity.county}` : `${entity.province}-${entity.city}-${entity.county}`
        },
        // {
        //     title: "所在省",
        //     dataIndex: "province",
        //     hideInSearch: true
        // },
        // {
        //     title: "所在市",
        //     dataIndex: "city",
        //     hideInSearch: true
        // },
        // {
        //     title: "所在区县",
        //     dataIndex: "county",
        //     hideInSearch: true
        // },
        {
            title: "安装时间",
            dataIndex: "installTime",
            hideInSearch: true
        },
        {
            title: "保修时间",
            dataIndex: "warrantyPeriod",
            hideInSearch: true
        },
        {
            title: "操作",
            valueType: "option",
            render: (node, entity, index, action) => this.renderColumnOperations(node, entity, index, action)
        }
    ];

    renderColumnOptionsMenu = (entity, action) => {
        return <Menu>
            <Menu.Item key="0">
                <span onClick={() => this.equipmentQRCodeRef.current.show(entity.id)}
                      className={style.btn}>打印设备二维码</span>
            </Menu.Item>
            <Menu.Item key="2" danger>
                <span onClick={() => this.deleteEquipmentRef.current.show(entity.id)} className={style.btn}>删除设备</span>
            </Menu.Item>
        </Menu>;
    };

    toolbarRender = () => {
        return [
            <Button key="qr" type="primary" onClick={() => this.QRCodeRef.current.show()}>批量生成二维码</Button>,
            <Button key="create" type="primary" onClick={() => this.createEquipmentRef.current.show()}>新增设备</Button>
        ];
    };

    requestPage = data => {
        const customerDepartmentIds = localStorage.getItem("customerDepartmentIds");

        if (isAdmin() || isAdminDepartment()) {
            return this.requestEquipmentList(data);
        } else {
            if (!!customerDepartmentIds) {
                data.customerDepartmentIds = customerDepartmentIds;
                return this.requestEquipmentList(data);
            }
        }
    };

    requestEquipmentList = data => {
        data.customerDepartmentIds = this.state.departmentId;
        return EquipmentListApi.page(data).then(response => {
            return {data: response.data.records, success: true, total: response.data.total};
        }).catch(error => {
            console.warn("equipment list page", error);
        });
    };

    reload = () => this.actionRef?.reload();

    render() {
        return <Drawer title="查看单位设备" placement="right" width={this.state.drawerWidth}
                       onClose={this.close} open={this.state.show}
                       destroyOnClose>

            <ProTable headerTitle="设备列表"
                      rowKey="id"
                      defaultSize="small"
                      options={{reload: true, setting: true, density: true}}
                      actionRef={actionRef => this.actionRef = actionRef}
                      columns={this.columns}
                      request={this.requestPage}/>

            <CreateEquipmentDrawer onSuccess={this.reload} ref={this.createEquipmentRef}/>
            <DeleteEquipmentModal onSuccess={this.reload} ref={this.deleteEquipmentRef}/>
            <EditEquipmentDrawer onSuccess={this.reload} ref={this.editEquipmentRef}/>
            <PreviewEquipmentDrawer onSuccess={this.reload} ref={this.previewEquipmentRef}/>
            <QRCodeModal onSuccess={this.reload} ref={this.QRCodeRef}/>
            <EquipmentQrCodeModal onSuccess={this.reload} ref={this.equipmentQRCodeRef}/>
            <MigrationEquipmentDrawer onSuccess={this.reload} ref={this.migrationEquipmentRef}/>
        </Drawer>;
    }
}

export default PreviewDepartmentEquipmentDrawer;
