import React, {Component} from "react";
import {Modal} from "antd";
import Draggable from "react-draggable";
import PropTypes from "prop-types";

class DraggableModal extends Component {

    static defaultProps = {
        title: "拖拽弹窗",
        centered: true,
        destroyOnClose: true,
        visible: false,
        footer: null,
        draggableModalProps: {},
        onShow: undefined
    };

    state = {
        show: this.props.visible,
        disabled: true,
        bounds: {left: 0, top: 0, bottom: 0, right: 0}
    };

    draggableRef = React.createRef();

    show = () => this.setState({show: true}, this.props.onShow);

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    close = () => this.setState({show: false});

    onStart = (event, uiData) => {
        const {clientWidth, clientHeight} = window.document.documentElement;
        const targetRect = this.draggableRef.current?.getBoundingClientRect();
        if (!targetRect) {
            return;
        }
        this.setState({
            bounds: {
                left: -targetRect.left + uiData.x,
                right: clientWidth - (targetRect.right - uiData.x),
                top: -targetRect.top + uiData.y,
                bottom: clientHeight - (targetRect.bottom - uiData.y)
            }
        });
    };

    render() {
        return (<Modal

                title={<div style={{width: "100%", cursor: "move"}}
                            onMouseOver={() => {
                                if (this.state.disabled) {
                                    this.setState({disabled: false});
                                }
                            }}
                            onMouseOut={() => {
                                this.setState({disabled: true});
                            }}>
                    {this.props.title}
                </div>}
                centered={this.props.centered}
                destroyOnClose={this.props.destroyOnClose}
                open={this.state.show}
                onCancel={this.props.onCancel}
                footer={null}
                // forceRender
                modalRender={modal => (
                    <Draggable disabled={this.state.disabled}
                               bounds={this.state.bounds}
                               onStart={(event, uiData) => this.onStart(event, uiData)}>
                        <div ref={this.draggableRef}>{modal}</div>
                    </Draggable>
                )}
                {...this.props.draggableModalProps}>
                {this.props.children}
            </Modal>
        );
    }
}

DraggableModal.propTypes = {
    title: PropTypes.string,
    centered: PropTypes.bool,
    destroyOnClose: PropTypes.bool,
    visible: PropTypes.bool,
    draggableModalProps: PropTypes.object,
    onCancel: PropTypes.func,
    onShow: PropTypes.func
};

export default DraggableModal;
