import React from "react";
import {Button, Col, Divider, Drawer, Form, Input, message, Row, Select, Space} from "antd";
import {ProDescriptions} from "@ant-design/pro-components";
import EquipmentListApi from "api/equipment/list";
import CustomerApi from "api/customer/customer";
import CustomerDepartmentApi from "api/customer/department";
import {hasAnyRole} from "component/SecurityInterceptor";

import CreateEquipmentDrawer from "page/home/equipment/list/drawer/CreateEquipmentDrawer";
import MigrationEquipmentDrawer from "page/home/equipment/list/drawer/MigrationEquipmentDrawer";
import SetWorkorderDeviceModal from "../../modal/SetWorkorderDeviceModal";
import ConfirmCreateKnowledgeModal from "../../modal/ConfirmCreateKnowledgeModal";

import FileUploadRender from "component/FileUploadRender";
import {setFieldsError} from "plugin/formily";
import WorkorderApi from "api/workorder";
import EnumDict from "../../../../../../enum/dict";
import DictApi from "../../../../../../api/dict";

/**
 * 完成工单弹窗
 *
 * @description 完成工单
 *
 * @author huang Carder
 * @since 2021-06-23
 * @version 1.0.0
 */
export default class CompleteWorkorderDrawer extends React.Component {

    state = {
        loading: false,
        show: false,
        entryState: false,
        finishDisabled: false,
        faultDetailOpen :false,
        drawerWidth: 280,
        equipment: {},
        customer: {},
        customerDepartment: {},
        equipmentId: undefined,
        pid: undefined,
        fautTypeOption:[],
        fautDetailOption:[]
    };

    formRef = React.createRef();
    filesRef = React.createRef();
    createEquipmentRef = React.createRef();
    equipmentRef = React.createRef();
    migrationRef = React.createRef();
    createKnowledgeRef = React.createRef();

    /**
     * 控制完成工单对话框显示隐藏
     */
    toggle = () => {

        this.setState({
            show: !this.state.show,
            loading: this.state.show ? this.state.show : false,
            drawerWidth: window.innerWidth < 576 ? window.innerWidth : window.innerWidth * 0.55
        }, () => {
            if (!!this.state.show) {
                this.formRef.current.setFieldsValue({
                    type: this.props.workorderType || ""
                });
                this.setState({equipmentId: this.props.equipmentId}, this.requestEquipment);
            }
        });
    };

    /**
     * 控制 loading效果 加载显示
     */
    toggleLoading = () => this.setState({loading: !this.state.loading});

    /**
     * 完成工单
     */
    handle = data => {
        if (!!this.state.equipmentId && this.state.equipmentId === "0" && !!hasAnyRole(["device-engineer"]) && this.props.origin === "prepare-empty-device") {
            message.error({
                content: "由于您是尊贵的工程师,所以请先添加设备再进行下一步",
                key: "entry"
            });
            return;
        }
        let param = {
            workorderId: this.props.workorderId,
            result: data.result,
            files: this.filesRef.current.getFilesValue(),
            type: "",
            faultDetail:data.faultDetail,
            faultType: data.faultType,
            otherDesc:data.otherDesc,
            resourceType: null,
            resourceName: null
        };
        switch (data.type) {
            case "gcoawh":
                param.type = "gcoawh";
                // param.resourceType = "";
                // param.resourceName = "";
                break;
            case "xtrjwh":
                param.type = "xtrjwh";
                // param.resourceType = "";
                // param.resourceName = "";
                break;
            case "zyjwh":
                param.type = "zyjwh";
                // param.resourceType = "";
                // param.resourceName = "";
                break;
            case "zyjwh-cyst-DT3000-L12":
                param.type = "zyjwh";
                param.resourceType = "超越申泰";
                param.resourceName = "DT3000-L12";
                break;
            case "zyjwh-cyst-DT3010-L12":
                param.type = "zyjwh";
                param.resourceType = "超越申泰";
                param.resourceName = "DT3010-L12";
                break;
            case "tyjwh":
                param.type = "tyjwh";
                // param.resourceType = "";
                // param.resourceName = "";
                break;
            case "tyjwh-ty-TR1192":
                param.type = "tyjwh";
                param.resourceType = "天玥";
                param.resourceName = "TR1192";
                break;
            case "tyjwh-cystDT-3010-F":
                param.type = "tyjwh";
                param.resourceType = "超越申泰";
                param.resourceName = "DT-3010-F";
                break;
            case "tyjwh-cystDT-3010-L":
                param.type = "tyjwh";
                param.resourceType = "超越申泰";
                param.resourceName = "DT-3010-L";
                break;
            case "dyjwh":
                param.type = "dyjwh";
                // param.resourceType = "";
                // param.resourceName = "";
                break;
            case "dyjwh-gdtsmy-OEM200M":
                param.type = "dyjwh";
                param.resourceType = "光电通";
                param.resourceName = "扫描仪 OEM200M";
                break;
            case "dyjwh-gdtbsytj-OEM3000DN":
                param.type = "dyjwh";
                param.resourceType = "光电通";
                param.resourceName = "白色一体机 OEM3000DN";
                break;
            case "dyjwh-gdthsytj-OEP400DN":
                param.type = "dyjwh";
                param.resourceType = "光电通";
                param.resourceName = "黑色一体机 OEP400DN";
                break;
            case "dyjwh-gdt-OEP102D":
                param.type = "dyjwh";
                param.resourceType = "光电通";
                param.resourceName = "OEP102D";
                break;
            case "dyjwh-bt-M7105DN":
                param.type = "dyjwh";
                param.resourceType = "奔图";
                param.resourceName = "M7105DN";
                break;
            case "yjaz":
                param.type = "yjaz";
                // param.resourceType = "";
                // param.resourceName = "";
                break;
            case "xtxj":
                param.type = "xtxj";
                // param.resourceType = "";
                // param.resourceName = "";
                break;
            case "ldcl":
                param.type = "ldcl";
                // param.resourceType = "";
                // param.resourceName = "";
                break;
            case "dyjhf":
                param.type = "dyjhf";
                // param.resourceType = "";
                // param.resourceName = "";
                break;
            case "yjhsxh":
                param.type = "yjhsxh";
                // param.resourceType = "";
                // param.resourceName = "";
                break;
            case "zzwjxh":
                param.type = "zzwjxh";
                // param.resourceType = "";
                // param.resourceName = "";
                break;
            default:
                param.type = data.type;
                // param.resourceType = "";
                // param.resourceName = "";
                break;
        }
        this.toggleLoading();
        WorkorderApi.complete(param).then(response => {
            this.toggle();
            if (!!this.props.onSuccess) {
                this.props.onSuccess();
            }
        }).catch(error => {
            if (error.status === 40000) {
                setFieldsError(error, this.formRef);
            } else {
                message.error({key: "failed", content: error.data});
            }
            this.toggleLoading();
            console.warn("Finish workorder failed", error);
            global.formily.setFieldsErrorFeedback(this.form, error.data);
        });
    };

    trigger = () => <Button onClick={this.toggle}>完成</Button>;
    /**
     * 查询设备信息
     */
    requestEquipment = () => {
        if (!!this.state.equipmentId && this.state.equipmentId !== "0") {
            EquipmentListApi.info(this.state.equipmentId).then(res => {
                this.requestCustomer(res.data.customerId);
                this.requestCustomerDepartment(res.data.customerDepartmentId);
                this.setState({equipment: res.data,pid: res.data.deviceType.commonType}, () => {
                    this.getCommonType();
                });
            }).catch(err => {
                console.error("request equipment failed", err);
                if (!!hasAnyRole(["device-engineer"])) {
                    message.error("该设备暂未录入系统，请先录入设备！");
                    if (err.status === 40400) {
                        this.setState({
                            entryState: true,
                            finishDisabled: true
                        });
                    }
                }

            });
        }
    };
    /**
     * 查询客户信息
     * @returns {JSX.Element}
     */
    requestCustomer = (id) => {
        CustomerApi.find(id).then(res => {
            this.setState({customer: res.data});
        });
    };
    /**
     * 查询客户单位信息
     * @param id
     */
    requestCustomerDepartment = (id) => {
        CustomerDepartmentApi.find(id).then(res => {
            this.setState({customerDepartment: res.data});
        });
    };

    /**
     * 打开录入设备弹窗
     * @returns {JSX.Element}
     */
    entryEquipment = () => {
        this.createEquipmentRef.current.show();
    };

    addEquipment = () => {
        this.equipmentRef.current.show();
    };

    reload = () => {
        this.setState({
            finishDisabled: false,
            entryState: false,
            equipmentId: !!this.equipmentRef && !!this.equipmentRef.current.state && !!this.equipmentRef.current.state.equipmentId ? this.equipmentRef.current.state.equipmentId : this.state.equipmentId
        }, this.requestEquipment);
    };

    renderOptions = () => {
        return [
            <Button type="primary" key="replace"
                    onClick={() => this.equipmentRef.current.show()}>更换</Button>,
            <Button type="primary" key="migration"
                    onClick={() => this.migrationRef.current.show(this.state.equipmentId)}>迁移</Button>
        ];
    };

    onConnectWithDevice = (data) => {
        this.reload();
        console.log(data)
        this.setState({
            finishDisabled: false,
            entryState: false,
            pid:data.commonType,
            equipmentId: data.equipmentId
        },()=>{
            this.requestEquipment();
            this.getCommonType();
        });

        //this.props.onConnectWithDevice();
    };

    /**
     * 获取设备大类列表
     * @returns {Promise<void>}
     */

    getCommonType = () => {
        // 故障类型
        DictApi.list({code : EnumDict.DEVICE_FAULT_TYPE , pid:this.state.pid}).then(res => {
            let selectData = [];
            res.data.forEach(item => {
                selectData.push({label: item.value, value: item.id});
            });
            console.log(selectData)
            this.setState({
                fautTypeOption: selectData
            });
        })

    };
    /**
     * 获取设备故障明细
     * @returns {Promise<void>}
     */
    getFaultDetail = (value) => {
        // 故障明细
        DictApi.list({code : EnumDict.DEVICE_FAULT_DETAIL, pid:value}).then(res => {
            let selectData = [];
            res.data.forEach(item => {
                selectData.push({label: item.value, value: item.id});
            });
            selectData.push({label: "其他", value: "-1"});
            console.log(selectData)
            this.setState({
                fautDetailOption: selectData
            });
        })
    }
    setFaultDetail = (value) => {
        this.setState({
            faultDetailOpen:value === "-1"
        })
    }

    dialog = () => {
        return <Drawer title="处理过程" width={this.state.drawerWidth} open={this.state.show}
                       destroyOnClose onClose={this.toggle} closable={!this.state.loading}>
            <Divider plain orientation={"left"}><h3>设备信息</h3></Divider>
            {!!this.state.equipmentId && this.state.equipmentId !== "0" ?
                <ProDescriptions bordered size="small" layout="horizontal"
                                 column={window.innerWidth < 800 ? 1 : 4} extra={this.renderOptions()}>
                    <ProDescriptions.Item label="设备名称" span={2}>
                        {this.state.equipment.name || "设备暂未录入"}
                    </ProDescriptions.Item>
                    <ProDescriptions.Item label="设备型号" span={2}>
                        {this.state.equipment.model || "设备暂未录入"}
                    </ProDescriptions.Item>
                    {
                        !!this.state.customer ? <ProDescriptions.Item label="客户姓名" span={2}>
                            {this.state.customer.name || "设备暂未录入"}
                        </ProDescriptions.Item> : null

                    }
                    {
                        !!this.state.customer ? <ProDescriptions.Item label="客户电话" span={2}>
                            {this.state.customer.cellphone || "设备暂未录入"}
                        </ProDescriptions.Item> : null
                    }
                    {
                        !!this.state.customerDepartment ? <ProDescriptions.Item label="客户单位" span={4}>
                            {this.state.customerDepartment.name || "设备暂未录入"}
                        </ProDescriptions.Item> : null
                    }

                    <ProDescriptions.Item label="设备描述" span={4}>
                        {this.state.equipment.description || "--"}
                    </ProDescriptions.Item>
                </ProDescriptions> : <>
                    <Button type="primary"
                            onClick={this.addEquipment}>关联设备</Button>
                    <p style={{color: "red"}}>请工程师查看当前工单对应的设备是否有贴有‘泰狐’标签的设备二维码，如果有请完成关联设备，否则可以跳过此步骤</p>
                </>}
            <Divider plain orientation={"left"}><h3>完成信息</h3></Divider>
            <Form ref={this.formRef} onFinish={this.handle} layout={"vertical"}>
                <Row>
                    <Col span={24}>
                        <Form.Item name="type" label="服务类型" required rules={[{required: true, message: "请选择服务类型"}]}>
                            <Select options={[
                                {label: "国产OA维护", value: "gcoawh"},
                                {label: "系统软件维护", value: "xtrjwh"},
                                {label: "专用机维护", value: "zyjwh"},
                                {label: "通用机维护", value: "tyjwh"},
                                {label: "打印机维护", value: "dyjwh"},
                                {label: "硬件安装", value: "yjaz"},
                                {label: "系统巡检", value: "xtxj"},
                                {label: "漏洞处理", value: "ldcl"},
                                {label: "打印机换粉", value: "dyjhf"},
                                {label: "硬件回收销毁", value: "yjhsxh"},
                                {label: "纸质文件销毁", value: "zzwjxh"},
                                {label: "其他客户", value: "others-customer"}
                            ]} showSearch optionFilterProp="label"/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={10}>
                    {this.state.fautTypeOption.length > 0 ?
                        <Col span={12}>
                            <Form.Item name="faultType" label="故障类型" required rules={[{required: true, message: "请选择故障类型"}]}>
                                <Select options={this.state.fautTypeOption} showSearch onChange={this.getFaultDetail} optionFilterProp="label"/>
                            </Form.Item>
                        </Col> : <></>}
                    {this.state.fautDetailOption.length > 0 ? <Col span={12}>
                        <Form.Item name="faultDetail" label="故障明细" required rules={[{required: true, message: "请选择故障明细"}]}>
                            <Select options={this.state.fautDetailOption} showSearch optionFilterProp="label" onChange={this.setFaultDetail}/>
                        </Form.Item>
                    </Col> : <></>}

                </Row>

                <Row>
                    {this.state.faultDetailOpen ? <Col span={24}>
                        <Form.Item name="otherDesc" label="备注" required rules={[
                            {required: true, message: "其他描述"},
                            {pattern: /\S/g, message: "内容不能为空"}]}>
                            <Input.TextArea/>
                        </Form.Item>
                    </Col> : <></>}
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item name="result" label="服务说明" required rules={[
                            {required: true, message: "请填写服务说明"},
                            {pattern: /\S/g, message: "内容不能为空"}]}>
                            <Input.TextArea/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <FileUploadRender name="files" uploadPath="/workorder/result/file"
                                          ref={this.filesRef}/>
                    </Col>
                </Row>
                <Row gutter={8} justify="end">
                    <Col>
                        <Space>
                            <Button disabled={this.state.loading} onClick={this.toggle}>取消</Button>
                            <Button loading={this.state.loading} type="primary" htmlType="submit"
                                    disabled={this.state.finishDisabled}>完成</Button>
                            {!!this.state.equipmentId && this.state.equipmentId !== "0" && !!this.state.entryState && !!hasAnyRole(["device-engineer"]) ?
                                <Button loading={this.state.loading} type="primary"
                                        onClick={this.entryEquipment}>录入设备</Button> : null}
                        </Space>
                    </Col>
                </Row>
            </Form>
            <CreateEquipmentDrawer onSuccess={this.reload} ref={this.createEquipmentRef}
                                   equipmentId={this.state.equipmentId}/>
            <SetWorkorderDeviceModal onSuccess={this.onConnectWithDevice} ref={this.equipmentRef}
                                     workorderId={this.props.workorderId}/>
            <MigrationEquipmentDrawer onSuccess={this.reload} ref={this.migrationRef}/>
            <ConfirmCreateKnowledgeModal onSuccess={this.reload} ref={this.createKnowledgeRef}
                                         closeDrawer={this.toggle} reloadStatus={this.props.onSuccess}/>
        </Drawer>;

    };

    render() {
        return <>
            {this.trigger()}
            {this.dialog()}
        </>;
    }
}
