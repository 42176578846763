import React from "react";
import {Button, Col, Form, Input, message, Row, Select, Space, Upload} from "antd";

import WorkorderApi from "api/workorder";
import {setFieldsError} from "plugin/formily";
import DraggableModal from "component/DraggableModal";


/**
 * 完成工单 Modal
 *
 * @description 完成工单
 *
 * @author huang Carder
 * @since 2021-06-23
 * @version 1.0.0
 */
export default class FinishWorkorderDialog extends React.Component {

    state = {
        loading: false,
        show: false
    };

    formRef = React.createRef();
    draggableRef = React.createRef();


    show = () => this.draggableRef.current.show();

    close = () => {
        this.setState({loading: false});
        this.draggableRef.current.close();
    };

    toggleLoading = () => this.setState({loading: !this.state.loading});

    handle = data => {
        this.toggleLoading();
        let param = {
            workorderId: this.props.workorderId,
            result: data.result,
            files: [],
            type: data.type
        };
        if (!!data.files && !!data.files.fileList) {
            data.files.fileList.forEach((file) => {
                param.files.push(file.response.data);
            });
        }
        WorkorderApi.complete(param).then(response => {
            this.close();
            if (!!this.props.onSuccess) {
                this.props.onSuccess();
            }
        }).catch(error => {
            if (error.status === 40000) {
                setFieldsError(error, this.formRef);
            } else {
                message.error({key: "failed", content: [error.data]});
            }
            this.toggleLoading();
            console.warn("Finish workorder failed", error);
        });
    };

    trigger = () => <Button onClick={this.show}>完成</Button>;

    uploadOnChange = (info) => {
        this.setState({
            loading: info.fileList.some((item) => {
                return item.status === "uploading";
            })
        });
    };

    /**
     * 删除文件
     * @param file 文件
     */
    onRemoveFile = file => {
        if (!file.response || !file.response.data && file.status === "error") {
            return true;
        }
        const path = file.response.data;
        return global.axios.delete("/workorder/result/file", {params: {path}}).then(() => {
            console.log("Delete workorder result file success", path);
            return true;
        }).catch(error => {
            console.error("Delete workorder result file failed", error);
            return false;
        });
    };

    dialog = () => {
        return <DraggableModal onCancel={this.close} title="处理过程" ref={this.draggableRef}>
            <Form ref={this.formRef} onFinish={this.handle} layout={"vertical"}>
                <Row>
                    <Col span={24}>
                        <Form.Item name="type" label="服务类型">
                            <Select options={[
                                {label: "智慧党建维护", value: "zhdjwh"},
                                {label: "OA维护", value: "oawh"},
                                {label: "系统软件维护", value: "xtrjwh"},
                                {label: "网站维护", value: "wzwh"},
                                {label: "服务器维修", value: "fwqwx"},
                                {label: "网络设备维修", value: "wlsbwx"},
                                {label: "硬件安装", value: "yjaz"},
                                {label: "系统巡检", value: "xtxj"},
                                {label: "漏洞处理", value: "ldcl"},
                                {label: "敏感字眼处理", value: "mgzycl"}
                            ]}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item name="result" label="文字说明" rules={[
                            {pattern: /\S/g, message: "内容不能为空"}
                        ]}>
                            <Input.TextArea/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item name="files" label="附件上传">
                            <Upload name="file" showUploadList multiple
                                    headers={{token: localStorage.getItem("Token")}}
                                    action={global.config.url + "/workorder/result/file"}
                                    onRemove={this.onRemoveFile}
                                    onChange={this.uploadOnChange}>
                                <Button disabled={this.state.loading}>文件上传</Button>
                            </Upload>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={8} justify="end">
                    <Col>
                        <Space>
                            <Button disabled={this.state.loading} onClick={this.close}>取消</Button>
                            <Button loading={this.state.loading} type="primary" htmlType="submit">完成</Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </DraggableModal>;
    };

    render() {
        return <>
            {this.trigger()}
            {this.dialog()}
        </>;
    }
}
