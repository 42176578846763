import React, {Component} from "react";
import {Button, Col, Row, Space} from "antd";
import {ProForm, ProFormRadio} from "@ant-design/pro-components";

import AccountApi from "api/user/account";

import DraggableModal from "../../DraggableModal";
import {getUser} from "../../SecurityInterceptor";

class SetStatus extends Component {

    draggableRef = React.createRef();

    state = {
        loading: false,
        status: getUser()?.situation || "0"
    };

    toggleLoading = () => this.setState({loading: !this.state.loading});

    show = () => this.draggableRef.current.show();

    close = () => {
        this.draggableRef.current.close();
        this.setState({status: getUser()?.situation || "0"});
    };

    radioChange = value => this.setState({status: value.target.value});

    handle = () => {
        this.toggleLoading();
        AccountApi.setUserStatus(getUser().id, {situation: this.state.status}).then(res => {
            this.toggleLoading();
            localStorage.setItem("User", JSON.stringify({...getUser(), situation: this.state.status}));
            this.props.onSuccess(this.state.status);
            this.close();
        }).catch(err => {
            this.toggleLoading();
            console.error("Set User status failed", err);
        });
    };

    render() {
        return <DraggableModal onCancel={this.close} title="设置状态" ref={this.draggableRef}>
            <ProForm formRef={this.formRef} onFinish={this.handle} layout={"vertical"} submitter={{
                resetButtonProps: {style: {display: "none"}},
                submitButtonProps: {style: {display: "none"}}
            }}>
                <ProFormRadio.Group
                    name="situation"
                    label={null}
                    value={this.state.status.toString()}
                    onChange={this.radioChange}
                    options={[{label: "休息中", value: "0"},
                        {label: "工作中", value: "1"},
                        // {label: "非常忙碌", value: "2"}
                    ]}
                />
                <Row justify="end">
                    <Col>
                        <Space>
                            <Button disabled={this.state.loading} onClick={this.close}>取消</Button>
                            <Button loading={this.state.loading} type="primary" htmlType="submit">设置</Button>
                        </Space>
                    </Col>
                </Row>
            </ProForm>
        </DraggableModal>;
    }
}

export default SetStatus;
