import React, {Component} from "react";
import {Button, Divider, Drawer, Skeleton} from "antd";
import {ProDescriptions, ProTable} from "@ant-design/pro-components";

import StorehouseRecordApi from "api/spares/record";
import StorehouseApi from "api/spares/storehouse";
import AccountApi from "api/user/account";

import RevertSparesDrawer from "../../storehouse/commponent/drawer/RevertSparesDrawer";
import {getUser, hasAnyRole} from "component/SecurityInterceptor";

class PreviewSolicitRecordDrawer extends Component {

    revertSparesRef = React.createRef();

    columns = [
        {
            title: "类型",
            dataIndex: ["stocktype", "model"],
            editable: false,
            hideInSearch: true
        },
        {
            title: "名称",
            dataIndex: ["stocktype", "name"],
            hideInSearch: true
        },
        {
            title: "数量",
            dataIndex: "count",
            hideInSearch: true
        },
        {
            title: "是否SM",
            dataIndex: ["stocktype", "classified"],
            hideInSearch: true,
            valueEnum: {
                "0": {
                    "text": "否"
                },
                "1": {
                    "text": "是"
                }
            }
        }
    ];

    state = {
        show: false,
        loading: true,
        drawerWidth: 600,
        solicitRecord: {},
        repositoryOutputRecord: {},
        stocks: [],
        solicitId: "",
        solicitAuditor: "-"
    };

    show = (id) => {
        this.setState({
            show: true,
            drawerWidth: window.innerWidth < 576 ? window.innerWidth : window.innerWidth * 0.55,
            solicitId: id
        }, () => {
            this.requestSolicitRecord(id);
        });
    };

    close = () => this.setState({show: false});

    toggleLoading = () => this.setState({loading: !this.state.loading});

    requestSolicitRecord = (id) => {
        StorehouseRecordApi.solicitRepository(id).then(res => {
            this.requestRepositoryOutputRecord(res.data.output);
            if (!!res.data.creator) {
                this.requestSolicitAuditor(res.data.creator)
            }
            let stocksData = []
            res.data.stocks.forEach(item => {
                let stocksObj = {
                    count: "",
                    id: "",
                    output: "",
                    stocktype: {}
                };
                stocksObj.id = item.stock.id;
                stocksObj.output = item.stock.output;
                stocksObj.stocktype = item.stock.stocktype;
                stocksObj.count = item.stock.count - item.revert.count ?? 0;
                stocksData.push(stocksObj)
            })
            this.setState({solicitRecord: res.data, stocks: stocksData, loading: false});
        }).catch(err => {
            this.setState({loading: false});
            console.error("Request solicit record failed", err);
        });
    };

    requestRepositoryOutputRecord = (id) => {
        StorehouseRecordApi.repositoryOutput(id).then(res => {
            this.setState({repositoryOutputRecord: res.data});
        }).catch(err => {
            console.error("Request repository input record failed", err);
        });
    };

    requestSolicitAuditor = (id) => {
        AccountApi.findUserInfo({params: {accountId: id}}).then(res => {
            this.setState({solicitAuditor: res.data.name});
        }).catch(err => {
            console.error("Request account info failed", err);
            return "";
        });
    };

    renderOptions = () => {
        const showRevert = (hasAnyRole(["admin"]) || this.state.solicitRecord.user === getUser().id) && this.state.solicitRecord.reverted !== 1;
        return showRevert ? <Button type="primary"
                                    onClick={() => this.revertSparesRef.current.show(this.state.solicitId, this.state.stocks)}>退还</Button> : null;};

    requestStorehouseInfo = () => {
        return StorehouseApi.find(this.state.solicitRecord.repository.id).then(res => {
            return {success: true, data: res.data};
        }).catch(err => {
            console.error("Request origin storehouse info failed", err);
        });
    };

    reload = () => this.requestSolicitRecord(this.state.solicitId);

    render() {
        return (<Drawer title="工程师领用记录"
                        destroyOnClose
                        width={this.state.drawerWidth}
                        open={this.state.show}
                        closable={!this.state.loading}
                        onClose={this.close}>
                <ProDescriptions bordered size="small" layout="horizontal"
                                 column={window.innerWidth < 800 ? 1 : 4}
                                 extra={this.renderOptions()}
                                 loading={this.state.loading}>
                    <ProDescriptions.Item label="类型" span={4}>
                        领用库存
                    </ProDescriptions.Item>
                    <ProDescriptions.Item label="领用说明" span={4}>
                        {this.state.solicitRecord.comment}
                    </ProDescriptions.Item>
                    <ProDescriptions.Item label="退还状态" span={4}>
                        {this.state.solicitRecord.reverted === 1 ? "已全部退还" : "待退还"}
                    </ProDescriptions.Item>
                </ProDescriptions>

                <Skeleton active loading={this.state.loading}>
                    <ProDescriptions bordered size="small" layout="horizontal"
                                     column={window.innerWidth < 800 ? 1 : 4}
                                     request={this.requestStorehouseInfo}
                                     loading={this.state.loading}>
                        <ProDescriptions.Item label="仓库" span={4} dataIndex="name"/>
                        <ProDescriptions.Item label="单位" span={4} dataIndex={["department", "name"]}/>
                        <ProDescriptions.Item label="领用人" span={4}>
                            {this.state.solicitAuditor}
                        </ProDescriptions.Item>
                    </ProDescriptions>
                </Skeleton>

                <Divider/>

                <ProTable headerTitle="领用物资列表"
                          search={false}
                          rowKey="id"
                          defaultSize="small"
                          pagination={false}
                          options={false}
                          columns={this.columns}
                          dataSource={this.state.stocks}/>

                <RevertSparesDrawer onSuccess={this.reload} ref={this.revertSparesRef}/>
            </Drawer>
        );
    }
}

export default PreviewSolicitRecordDrawer;
