import React from "react";
import {Button, Col, message, Row, Space, Typography} from "antd";

import DraggableModal from "component/DraggableModal";
import DictApi from "../../../../api/dict";

/**
 * 删除机构对话框
 * 根据id 删除对应的机构
 */
export default class DeleteDictModal extends React.Component {

    state = {
        loading: false,
        show: false,
        id: ""
    };

    draggableRef = React.createRef();

    /**
     * 控制删除对话框显示隐藏
     */
    show = id => {
        this.draggableRef.current.show();
        this.setState({id});
    };

    close = () => {
        this.setState({loading: false});
        this.draggableRef.current.close();
    };

    /**
     * 控制 loading 加载显示
     */
    toggleLoading = () => {
        this.setState({
            loading: !this.state.loading
        });
    };



    /**
     * 删除字典
     */
    handle = () => {
        this.toggleLoading();
        DictApi.page({pid:this.state.id}).then(response => {
            if (response.data.records.length > 0) {
                this.close();
                message.error("当前字典下有子集字典，请先删除子集字典！");
            }else {
                DictApi.delete(this.state.id).then(response => {
                    this.close();
                    message.success("删除成功！");
                    this.props.onSuccess();
                })
            }
        })

    };

    render() {
        return <DraggableModal onCancel={this.close} title="是否删除字典" ref={this.draggableRef}>
            <Typography>数据删除后无法恢复，请谨慎操作！</Typography>
            <Row gutter={8} justify="end">
                <Col>
                    <Space>
                        <Button disabled={this.state.loading} onClick={this.close}>取消</Button>
                        <Button loading={this.state.loading} type="primary" onClick={this.handle}>删除</Button>
                    </Space>
                </Col>
            </Row>
        </DraggableModal>;
    }
}
