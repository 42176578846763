import React, {Component} from 'react';
import DraggableModal from "../../../../component/DraggableModal";
import {Button, Col, Row, Space, Typography} from "antd";

/**
 * @author       : AngelBeats
 * @date         : 2023-06-01 16:07:05
 * @description  : 显示分享地址
 */
export default class ClipboardModal extends Component {

    draggableRef = React.createRef();

    state = {
        loading: false,
        url: ""
    }

    /**
     * 显示
     */
    show = url => {
        this.draggableRef.current.show();
        this.setState({url})
    }

    /**
     * 隐藏
     */
    close = () => {
        this.draggableRef.current.close();
    }

    /**
     * 显示loading
     */
    showLoading = () => this.setState({loading: true});

    /**
     * 隐藏loading
     */
    closeLoading = () => this.setState({loading: false})

    /**
     * 删除
     */
    handle = () => {
        this.close();
    }
    render() {
        return (<DraggableModal title="远程观看地址" ref={this.draggableRef} onCancel={this.close}>
            <div className="text-center">
                <Typography.Text type="secondary">请手动复制以下地址，分享给其他用户</Typography.Text>
            </div>
            {this.state.url}
            <Row gutter={8} justify="end">
                <Col>
                    <Space>
                        <Button loading={this.state.loading} type="primary" onClick={this.handle}>确定</Button>
                    </Space>
                </Col>
            </Row>
        </DraggableModal>)
    }
}
