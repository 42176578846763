import React, {Component} from "react";
import {Spin} from "antd";

import WorkorderInfoApi from "api/workorderInfo";

import style from "./index.module.css";


/**
 * 服务概览
 * @description 已评价工单数量统计
 */
class TrendStatistic extends Component {

    state = {
        loading: true,
        statisticNumber: 0
    }

    async componentDidMount() {
        await this.getStatisticNumber();
    }

    getStatisticNumber = async () => {
        const {data} = await WorkorderInfoApi.statisticNumber();
        this.setState({
            statisticNumber: data,
            loading: false
        })
    };

    render() {
        return (
            <div className={style.workorderInfoItem}>
                <p>已评价</p>
                {this.state.loading ? <Spin/> : <p>{this.state.statisticNumber}</p>}
            </div>
        );
    }
}

export default TrendStatistic;
