import React, {forwardRef, useImperativeHandle, useState} from 'react';
import styles from "./index.module.css"
import AccountApi from "api/user/account/index";
import WorkorderApi from "api/workorder";
import {useNavigate} from "react-router";
import defaultAvatar from "asset/defaultAvatar.jpeg"

/**
 * @author       : AngelBeats
 * @date         : 2023-03-28 14:19:15
 * @description  : 工程师详情弹窗
 */
const EngineerDetailDialog = (props, ref) => {

    useImperativeHandle(ref, () => ({
        show
    }))

    const navigate = useNavigate()

    const [open, setOpen] = useState(false)
    const [accountScore, setAccountScore] = useState(0)
    const [finishWorkorderNumber, setFinishWorkorderNumber] = useState(0)
    const [nowWorkorder, setNowWorkorder] = useState({})
    const [account, setAccount] = useState({})
    const [accountId, setAccountId] = useState("")
    const [workorderGoodReview, setWorkorderGoodReview] = useState("---")
    const [workorderTodayTotal, setWorkorderTodayTotal] = useState("---")

    const show = (id) => {
        setOpen(true)
        setAccountId(id)
        requestAccountIntegration(id)
        requestWorkorderTotal(id)
        requestOngoingWorkorder(id)
        requestAccountDetail(id)
        requestWorkorderGoodReview(id)
        requestWorkorderTodayTotal(id)
    }

    const close = () => setOpen(false)

    /**
     * 查询用户积分
     */
    const requestAccountIntegration = (accountId) => {
        AccountApi.queryUserScore(accountId).then(res => {
            setAccountScore(res.data)
        }).catch(err => {
            console.error("Request account integration failed", err)
        })

    }

    /**
     * 获取用户完成工单总数
     */
    const requestWorkorderTotal = (accountId) => {
        WorkorderApi.findWorkorderNumber({handlerId: accountId, progress: 17}).then(res => {
            setFinishWorkorderNumber(res.data)
        }).catch(err => {
            console.error("Request workorder total", err)
        })
    }

    /**
     * 查询用户正在进行中的工单
     * @param accountId
     */
    const requestOngoingWorkorder = (accountId) => {
        WorkorderApi.ongoingWorkorder(accountId).then(res => {
            setNowWorkorder(res.data)
        }).catch(err => {
            console.error("Request ongoing workorder failed", err)
        })
    }

    /**
     * 获取用户工单好评率
     */
    const requestWorkorderGoodReview = (accountId) => {
        WorkorderApi.findWorkorderGoodReview({handler: accountId}).then(res => {
            setWorkorderGoodReview(res.data.toFixed(2) * 100 + "%")
        }).catch(err => {
            console.error("Request workorder total", err)
        })
    }

    /**
     * 查询工程师详情
     * @param accountId
     */
    const requestAccountDetail = (accountId) => {
        AccountApi.findAccountDetail({accountId}).then(res => {
            setAccount(res.data)
        }).catch(err => {
            console.error("request account detail failed", err)
        })
    }

    /**
     * 获取用户完成工单总数
     */
    const requestWorkorderTodayTotal = (accountId) => {
        let time = new Date();
        WorkorderApi.findWorkorderNumber({
            handlerId: accountId,
            startTime: `${time.getFullYear()}-${(time.getMonth() + 1).toString().padStart(2, 0)}-${time.getDate().toString().padStart(2, 0)} 00:00:00`,
            endTime: `${time.getFullYear()}-${(time.getMonth() + 1).toString().padStart(2, 0)}-${time.getDate().toString().padStart(2, 0)} 23:59:59`
        }).then(res => {
            setWorkorderTodayTotal(res.data)
        }).catch(err => {
            console.error("Request workorder today total", err)
        })
    }

    /**
     * 跳转工程师详情页面
     */
    const previewAccountDetail = () => {
        navigate("/home/engineer-detail", {state: {accountId}})
    }

    return (<>
        {
            open ? <div className={styles.engineer_dialog_mask_layer} onClick={close}>
                <div className={`engineer_box ${styles.engineer_dialog_box}`}
                     onClick={e => e.stopPropagation()}>
                    <div className="engineer_department">
                        <span
                            className="department_name">{account?.departments ? account?.departments[0]?.name : "---"}</span>
                    </div>
                    <div className="engineer_info">
                        <img src={account?.avatar ? account.avatar : defaultAvatar} alt=""/>
                        <div className="engineer_name_mark">
                            <p className="engineer_name">{account?.name ?? "---"}</p>
                            <p className="engineer_mark"><span>积分：</span><span
                                id="account_integration">{accountScore ?? "---"}</span>
                            </p>
                        </div>
                    </div>
                    <p className="today_work text_style"><span>今日完成工单：</span><span
                        id="today_finish_workorder">{workorderTodayTotal}</span></p>
                    <p className="total_work text_style"><span>总计完成工单：</span><span
                        id="finish_workorder">{finishWorkorderNumber ?? "---"}</span>
                    </p>
                    <p className="praise text_style"><span>工单好评率：</span>{workorderGoodReview}</p>
                    <div className="engineer_department now_work">
                        <span className="department_name">当前工单</span>
                    </div>
                    <p className="customer_department text_style"><span>客户单位：</span><span
                        id="customer_department">{nowWorkorder?.clientDepartmentName ?? "---"}</span></p>
                    <p className="customer_name text_style"><span>客户姓名：</span><span
                        id="customer_name">{nowWorkorder?.clientName ?? "---"}</span>
                    </p>
                    <p className="address text_style"><span>详细地址：</span><span
                        id="address">{nowWorkorder?.clientDepartmentAddress ?? "---"}</span></p>
                    {/*<div className="detail" onClick={previewAccountDetail}>详情</div>*/}
                </div>
            </div> : null
        }
    </>)
}

export default forwardRef(EngineerDetailDialog)
