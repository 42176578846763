import React, {Component} from "react";
import {Button, Drawer, Tooltip} from "antd";
import {ProDescriptions, ProTable} from "@ant-design/pro-components";
import {EyeOutlined} from "@ant-design/icons";

import StorehouseInventoryApi from "api/spares/storehouse/inventory";
import StorehouseRecordApi from "api/spares/record";

import PreviewAddRecordDrawer from "../../../record/drawer/PreviewAddRecordDrawer";
import PreviewRevertRecordDrawer from "../../../record/drawer/PreviewRevertRecordDrawer";
import PreviewSolicitRecordDrawer from "../../../record/drawer/PreviewSolicitRecordDrawer";
import PreviewDestroyRecordDrawer from "../../../record/drawer/PreviewDestroyRecordDrawer";
import PreviewScheduleRecordDrawer from "../../../record/drawer/PreviewScheduleRecordDrawer";
import StorehouseApi from "api/spares/storehouse";
import StorehouseTypeApi from "api/spares/type";

class PreviewInventoryDrawer extends Component {

    state = {
        show: false,
        loading: false,
        drawerWidth: 600,
        stockId: "",
        repositoryStock: {},
        storehouseData: {},
        storehouseType: {}
    };

    previewAddRecordRef = React.createRef();
    PreviewRevertRecordRef = React.createRef();
    PreviewSolicitRecordRef = React.createRef();
    PreviewDestroyRecordRef = React.createRef();
    PreviewScheduleRecordRef = React.createRef();

    columns = [
        {
            title: "ID",
            dataIndex: "id",
            editable: false
        },
        {
            title: "记录类型",
            dataIndex: "type",
            valueEnum: {
                "add": {
                    "text": "添加库存"
                },
                "revert": {
                    "text": "工程师退还"
                },
                "solicit": {
                    "text": "工程师领用"
                },
                "schedule": {
                    "text": "库存调拨"
                },
                "destroy": {
                    "text": "消耗"
                }
            }
        },
        {
            title: "剩余数量",
            dataIndex: "balance",
            hideInSearch: true
        },
        {
            title: "出入库数量",
            dataIndex: "changed",
            hideInSearch: true
        },
        {
            title: "操作",
            valueType: "option",
            render: (node, entity, index, action) => this.renderColumnOperations(node, entity, index, action)
        }
    ];

    renderColumnOperations = (node, entity, index, action) => {
        return [
            <Tooltip title="查看记录" key="preview">
                <Button icon size="small" onClick={() => this.previewRecord(entity)}>
                    <EyeOutlined/>
                </Button>
            </Tooltip>
        ];
    };

    previewRecord = (entity) => {
        const inOrOut = entity.changed > 0 ? "input" : "output";
        const action = {
            add: () => this.previewAddRecordRef.current.show(entity.evidence),
            revert: () => this.PreviewRevertRecordRef.current.show(entity.evidence),
            solicit: () => this.PreviewSolicitRecordRef.current.show(entity.evidence),
            schedule: () => this.PreviewScheduleRecordRef.current.show(entity.evidence, inOrOut),
            destroy: () => this.PreviewDestroyRecordRef.current.show(entity.evidence)
        };
        action[entity.type]();
    };

    show = (id) => {
        this.setState({
            show: true,
            stockId: id,
            drawerWidth: window.innerWidth < 576 ? window.innerWidth : window.innerWidth * 0.55
        }, () => {
            this.requestRepositoryStock(id);
        });
    };

    close = () => this.setState({show: false});

    toggleLoading = () => this.setState({loading: !this.state.loading});

    requestRepositoryStock = (id) => {
        StorehouseInventoryApi.repositoryStock(id).then(res => {
            this.requestStorehouseInfo(res.data.repository);
            this.requestStocktype(res.data.stocktype);
            this.setState({repositoryStock: res.data});
        }).catch(err => {
            console.error("Request repository stock failed", err);
        });
    };

    requestStorehouseInfo = (id) => {
        StorehouseApi.find(id).then(res => {
            this.setState({storehouseData: res.data});
        }).catch(err => {
            console.error("Request origin storehouse info failed", err);
        });
    };

    requestStocktype = (id) => {
        StorehouseTypeApi.find(id).then(res => {
            this.setState({storehouseType: res.data});
        }).catch(err => {
            console.error("Request stocktype failed", err);
        });
    };

    requestPage = params => {
        params.stock = this.state.stockId;
        return StorehouseRecordApi.page(params).then(res => {
            return {data: res.data.records, total: res.data.total, success: true};
        }).catch(err => {
            console.error("Request page failed", err);
            return {data: [], total: 0, success: true};
        });
    };

    render() {
        return (<Drawer title="查看库存详情"
                        destroyOnClose
                        width={this.state.drawerWidth}
                        open={this.state.show}
                        closable={!this.state.loading}
                        onClose={this.close}>
                <ProDescriptions bordered size="small" layout="horizontal"
                                 column={window.innerWidth < 800 ? 1 : 4}
                                 loading={this.state.loading}>
                    <ProDescriptions.Item label="仓库名称" span={2}>
                        {this.state.storehouseData.name}
                    </ProDescriptions.Item>
                    <ProDescriptions.Item label="仓库地址" span={2}>
                        {this.state.storehouseData.address}
                    </ProDescriptions.Item>
                    <ProDescriptions.Item label="类型名称" span={2}>
                        {this.state.storehouseType.name}
                    </ProDescriptions.Item>
                    <ProDescriptions.Item label="类型型号" span={2}>
                        {this.state.storehouseType.model}
                    </ProDescriptions.Item>
                    <ProDescriptions.Item label="制造商" span={2}>
                        {!!this.state.storehouseType.manufacturer ? this.state.storehouseType.manufacturer.name : null}
                    </ProDescriptions.Item>
                    <ProDescriptions.Item label="是否SM" span={2}>
                        {this.state.storehouseType.classified === 0 ? "否" : "是"}
                    </ProDescriptions.Item>
                    <ProDescriptions.Item label="备件数量" span={2}>
                        {this.state.repositoryStock.count}
                    </ProDescriptions.Item>
                </ProDescriptions>

                <ProTable headerTitle="出入库记录"
                          options={{reload: true, setting: true, density: true}}
                          rowKey="id"
                          defaultSize="small"
                          columns={this.columns}
                          request={this.requestPage}/>
                <PreviewAddRecordDrawer onSuccess={this.reload} ref={this.previewAddRecordRef}/>
                <PreviewRevertRecordDrawer onSuccess={this.reload} ref={this.PreviewRevertRecordRef}/>
                <PreviewSolicitRecordDrawer onSuccess={this.reload} ref={this.PreviewSolicitRecordRef}/>
                <PreviewDestroyRecordDrawer onSuccess={this.reload} ref={this.PreviewDestroyRecordRef}/>
                <PreviewScheduleRecordDrawer onSuccess={this.reload} ref={this.PreviewScheduleRecordRef}/>
            </Drawer>
        );
    }
}

export default PreviewInventoryDrawer;
