import React from "react";
import moment from "moment";
import {Button, Col, DatePicker, Drawer, Form, Input, message, Row, Select, Space, TreeSelect} from "antd";
import {ProDescriptions} from "@ant-design/pro-components";

import {setFieldsError} from "plugin/formily";
import CustomerDepartmentApi from "api/customer/department";
import CustomerApi from "api/customer/customer";
import EquipmentListApi from "api/equipment/list";
import WorkorderApi from "api/workorder";

import {ConvertSmpWorkorderType, SmpWorkorderType} from "../../assets/WorkorderType";

import CreateCustomerModal from "page/home/customer/customer/modal/CreateCustomerModal";
import CreateCustomerDepartmentModal from "page/home/customer/department/modal/CreateCustomerDepartmentModal";
import DispatchUnassignedWorkorderModal from "../../unassigned/modal/DispatchUnassignedWorkorderModal";
import dayjs from "dayjs";
import {getUser} from "../../../../../component/SecurityInterceptor";


/**
 * 报修工单转为信创工单 Drawer
 *
 * @description 创建信创工单
 *
 * @author  Mae Carder
 * @version 1.0.0
 * @since 2021-11-4
 */
export default class PrepareToSmpWorkorderDrawer extends React.Component {

    state = {
        drawerWidth: 280,
        visible: false,
        loading: false,
        dispatch: false,
        showDispatchModal: false,
        progressDispatchWorkorder: false,
        loadingWorkorderHandlersOptions: false,
        departmentId: "",
        description: "",
        workorderId: "",
        WorkorderHandlerId: "",
        customerData: {},
        prepareWorkorder: {},
        customerDepartmentsOptions: [],
        customersOptions: [],
        equipmentOptions: [],
        equipmentInfo: {},
        files: []
    };

    /**
     * 表单引用
     */
    formRef = React.createRef();
    /**
     * 指派表单引用
     */
    dispatchFormRef = React.createRef();
    /**
     * 创建客户单位弹窗引用
     */
    createCustomerDepartmentModalRef = React.createRef();
    /**
     * 创建客户弹窗引用
     */
    createCustomerModalRef = React.createRef();
    /**
     * 指派弹窗的引用
     */
    dispatchWorkorderModalRef = React.createRef();

    show = entity => this.setState({
        visible: true,
        prepareWorkorder: entity,
        description: entity.description,
        files: entity.files,
        drawerWidth: window.innerWidth < 576 ? window.innerWidth : window.innerWidth * 0.55
    }, () => {
        this.formRef.current.setFieldsValue({
            description: this.state.description,
            appointment: dayjs(entity.appointmentTime, "YYYY-MM-DD HH:mm")
        });
        this.requestCustomerDepartmentsOptions();
    });

    /**
     * 请求客户单位选项
     */
    requestCustomerDepartmentsOptions = () => {
        CustomerDepartmentApi.tree(getUser().departments[0].id).then(response => {
            const treeData = [];
            response.data.forEach(department => {
                treeData.push(this.handleDepartmentTreeNode(department));
            });
            this.setState({customerDepartmentsOptions: treeData});
        }).catch(error => {
            console.warn("Get department tree data failed", error);
        });
    };

    /**
     * 处理客户单位的下级单位
     * @description 将服务端返回的客户单位对象转换为选项节点
     */
    handleDepartmentTreeNode = department => {
        const node = {
            title: department.name,
            key: department.id,
            value: department.id,
            children: []
        };
        if (!!department.children && department.children.length > 0) {
            department.children.forEach(subDepartment => {
                node.children.push(this.handleDepartmentTreeNode(subDepartment));
            });
        }
        return node;
    };

    /**
     * 客户单位选中回调
     * @description 选中客户单位后触发
     */
    onCustomerDepartmentChange = departmentId => {
        // 取消当前选中客户
        this.formRef.current?.setFieldsValue({clientCustomer: ""});
        // 重新根据当前客户单位获取客户选项
        this.requestCustomersOptions(departmentId);
        this.setState({departmentId});
    };

    /**
     * 请求客户选项
     */
    requestCustomersOptions = departmentId => CustomerApi.list({departmentId}).then(response => {
        const customerOptions = [];
        if (!!response.data && response.data.length > 0) {
            response.data.forEach(customer => {
                customerOptions.push(this.handleCustomerOption(customer));
            });
        }
        this.setState({customersOptions: customerOptions});
    }).catch(error => {
        console.warn("Get customers failed", error);
    });

    /**
     * 将客户转化为选项
     * @param customer 客户
     */
    handleCustomerOption = customer => {
        return {
            label: `${customer.name}(${customer.cellphone})`,
            value: customer.id
        };
    };

    /**
     * 显示新建客户单位弹窗
     */
    showCreateDepartmentDialog = () => this.createCustomerDepartmentModalRef.current.show();

    /**
     * 显示新建客户弹窗
     */
    showCustomerDialog = () => this.createCustomerModalRef.current.show(this.state.departmentId);

    /**
     * 获取新建后的单位
     * @param departmentId
     */
    onCreateCustomerDepartmentSuccess = departmentId => {
        this.requestCustomerDepartmentsOptions();
        this.requestCustomersOptions(departmentId);
        this.formRef.current?.setFieldsValue({
            clientDepartment: departmentId,
            clientCustomer: null
        });
        this.setState({departmentId});
    };

    /**
     * 获取新建后的客户
     * @param departmentId
     * @param customerId
     */
    onCreateCustomerSuccess = (departmentId, customerId) => {
        const customerDepartmentId = this.formRef.current?.getFieldValue("clientDepartment");
        if (!!customerDepartmentId) {
            this.requestCustomersOptions(customerDepartmentId);
        }
        if (departmentId === customerDepartmentId) {
            this.formRef.current?.setFieldsValue({
                clientCustomer: customerId
            });
        }
    };

    /**
     * 发起工单
     */
    handle = data => {
        this.toggleLoading();
        let param = {
            id: this.state.prepareWorkorder.id,
            clientDepartmentId: data.clientDepartment,
            clientId: data.clientCustomer,
            deadLine: data.deadLine.format("YYYY-MM-DD HH:mm"),
            appointment: data.appointment.format("YYYY-MM-DD HH:mm"),
            description: data.description,
            priority: data.priority,
            title: data.title,
            type: data.type,
            files: [],
            logicType: "smp",
            origin: this.state.prepareWorkorder.origin,
            resourceId: data.resourceId || undefined,
           resourceName: (this.state.equipmentInfo.name + " " + this.state.equipmentInfo.model) || undefined,
            // resourceType: !!this.state.equipmentInfo && !!this.state.equipmentInfo.manufacturer && !!this.state.equipmentInfo.manufacturer.name ? this.state.equipmentInfo.manufacturer.name : undefined
        };
        // if (!!this.state.files) {
        //     this.state.files.forEach(item => {
        //         param.files.push(item.path);
        //     });
        // }
        ConvertSmpWorkorderType(param);
        WorkorderApi.create(param).then(response => {
            this.formRef.current?.resetFields();
            if (this.state.dispatch) {
                // 打开指派弹窗
                this.dispatchWorkorderModalRef.current.show(response.data);
            } else {
                this.onSuccess();
            }
        }).catch(error => {
            console.warn("Create workorder failed", error);
            if (error.status === 40000) {
                setFieldsError(error, this.formRef);
            } else {
                message.error({key: "failed", content: error.data});
            }
            this.toggleLoading();
        });
    };

    /**
     * 弹出指派工单的弹窗
     */
    handleAndDispatch = () => {
        this.setState({dispatch: true}, () => {
            this.formRef.current?.submit();
        });
    };

    onCustomerChange = (value) => {
        this.requestEquipment(value);
    };

    requestEquipment = (id) => {
        EquipmentListApi.queryEquipment(id).then(res => {
            if (!!res.data) {
                const equipmentOptions = [];
                res.data.forEach(item => {
                    let obj = {
                        label: "",
                        value: ""
                    };
                    obj.label = item.name;
                    obj.value = item.id;
                    equipmentOptions.push(obj);
                });
                this.setState({equipmentOptions});
            }
        });
    };
    requestEquipmentInfo = (id) => {
        EquipmentListApi.queryEquipmentInfo(id).then(res => {
            this.setState({equipmentInfo: res.data});
        });
    };

    onSelectEquipmentChange = value => {
        this.requestEquipmentInfo(value);
    };


    toggleLoading = () => this.setState({loading: !this.state.loading});

    onSuccess = () => {
        this.close();
        if (!!this.props.onSuccess) {
            this.props.onSuccess();
        }
    };

    /**
     * 关闭 Drawer
     */
    close = () => {
        this.setState({
            visible: !this.state.visible,
            loading: false
        }, () => {
            if (!this.state.visible) {
                this.requestCustomerDepartmentsOptions();
            } else {
                this.props.onSuccess();
                this.formRef.current?.setFieldsValue({
                    type: "dyjhf",
                    priority: "5",
                    deadLine: null,
                    clientDepartment: null,
                    clientCustomer: null,
                    description: null,
                    files: []
                });
                this.setState({
                    dispatch: false,
                    departmentId: null,
                    customersOptions: null
                });
            }
        });
    };

    render = () => <Drawer destroyOnClose title="发起信创工单" closable={!this.state.loading}
                           width={this.state.drawerWidth}
                           onClose={this.close}
                           open={this.state.visible}>

        <ProDescriptions bordered size="small" layout="horizontal"
                         column={window.innerWidth < 800 ? 1 : 4}
                         dataSource={this.state.prepareWorkorder}>

            <ProDescriptions.Item label="客户姓名" span={4} dataIndex="clientName"/>
            <ProDescriptions.Item label="客户手机号码" span={4} dataIndex="clientCellphone"/>
            <ProDescriptions.Item label="客户地址" span={4} dataIndex="clientAddress"/>
        </ProDescriptions>

        <Form layout="vertical" ref={this.formRef} style={{marginTop: 24}} onFinish={this.handle}>
            <Row gutter={8}>
                <Col span={24}>
                    <Form.Item name="type" label="服务类型" required
                               rules={[{required: true, message: "请选择服务类型"}]}>
                        <TreeSelect showSearch treeNodeFilterProp="title" treeData={SmpWorkorderType}/>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={8}>
                <Col span={24}>
                    <Form.Item name="priority" label="优先级" required initialValue="5"
                               rules={[{required: true, message: "请选择优先级"}]}>
                        <Select options={require("../../assets/WorkorderPriority").priorityOptions()}/>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={8}>
                <Col span={12}>
                    <Form.Item name="deadLine" label="计划时间" required
                               rules={[{required: true, message: "请选择计划时间"}]}>
                        <DatePicker showTime inputReadOnly format="YYYY-MM-DD HH:mm" style={{width: "100%"}}/>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="appointment" label="预约时间" required
                               rules={[{required: true, message: "请选择预约时间"}]}>
                        <DatePicker showTime inputReadOnly format="YYYY-MM-DD HH:mm" style={{width: "100%"}}/>
                    </Form.Item>
                </Col>
                {/*<Col span={12}>*/}
                {/*    <Form.Item name="appointment" label="预约时间" required*/}
                {/*               rules={[{required: true, message: "请选择预约时间"}]}>*/}
                {/*        <DatePicker showTime inputReadOnly format="yyyy-MM-DD HH:mm" style={{width: "100%"}}/>*/}
                {/*    </Form.Item>*/}
                {/*</Col>*/}
            </Row>
            <Row gutter={8} align="middle">
                <Col span={20}>
                    <Form.Item name="clientDepartment" label="客户单位" required
                               rules={[{required: true, message: "请选择客户单位"}]}>
                        <TreeSelect treeData={this.state.customerDepartmentsOptions}
                                    showSearch treeNodeFilterProp="title"
                                    onChange={this.onCustomerDepartmentChange}/>
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <Button block style={{marginTop: 6}} type="link"
                            onClick={this.showCreateDepartmentDialog}>新建单位</Button>
                </Col>
            </Row>
            <Row gutter={8} align="middle">
                <Col span={20}>
                    <Form.Item name="clientCustomer" label="客户" required
                               rules={[{required: true, message: "请选择客户"}]}>
                        <Select options={this.state.customersOptions}
                                onChange={this.onCustomerChange}
                                showSearch optionFilterProp="label"/>
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <Button block style={{marginTop: 6}} type="link" onClick={this.showCustomerDialog}>新建客户</Button>
                </Col>
                {/*<Col span={24}>*/}
                {/*    <Form.Item name="resourceId" label="选择设备">*/}
                {/*        <Select loading={this.state.loadingCustomersOptions}*/}
                {/*                showSearch*/}
                {/*                onChange={this.onSelectEquipmentChange}*/}
                {/*                options={this.state.equipmentOptions}*/}
                {/*                optionFilterProp="label"/>*/}
                {/*    </Form.Item>*/}
                {/*</Col>*/}
            </Row>
            <Row gutter={8}>
                <Col span={24}>
                    <Form.Item name="description" label="工单描述"
                               rules={[{pattern: /\S/, message: "内容不能为空"}]}>
                        <Input.TextArea/>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={8} justify="end">
                <Col span={24}>
                    <Space>
                        <Button disabled={this.state.loading} onClick={this.close}>取消</Button>
                        <Button type="primary" loading={this.state.loading} htmlType="submit">发起工单</Button>
                        <Button type="primary" loading={this.state.loading}
                                onClick={this.handleAndDispatch}>发起工单并指派</Button>
                    </Space>
                </Col>
            </Row>
        </Form>

        <CreateCustomerDepartmentModal onSuccess={this.onCreateCustomerDepartmentSuccess}
                                       ref={this.createCustomerDepartmentModalRef}/>
        <CreateCustomerModal onSuccess={this.onCreateCustomerSuccess} ref={this.createCustomerModalRef}/>

        <DispatchUnassignedWorkorderModal ref={this.dispatchWorkorderModalRef} onSuccess={this.onSuccess}/>
    </Drawer>;
}
