import React from "react";
import {Button, Col, Form, Row, Space, TreeSelect} from "antd";

import CustomerDepartmentApi from "api/customer/department";
import DraggableModal from "component/DraggableModal";
import FileUploadRender from "../../../../../component/FileUploadRender";
import {getUser} from "../../../../../component/SecurityInterceptor";

/**
 * 批量导入客户单位
 */
export default class BatchImportModal extends React.Component {

    formRef = React.createRef();
    draggableRef = React.createRef();
    filesRef = React.createRef();

    state = {
        show: false,
        loading: false,
        departmentId: "",
        treeData: []
    };

    show = id => {
        this.draggableRef.current.show();
        this.setState({departmentId: id}, this.getDepartment);
    };

    close = () => {
        this.setState({loading: false});
        this.draggableRef.current.close();
    };


    /**
     * 获取客户单位
     */
    getDepartment = () => {
        CustomerDepartmentApi.tree(getUser().departments[0].id).then(response => {
            let departmentList = this.handleDepartmentTreeNode(response.data)
            departmentList.unshift({
                title: "顶层单位",
                key: "0",
                value: "0",
                children: undefined
            })
            this.setState({treeData: departmentList});
        }).catch(error => {
            console.warn("Get department info", error);
        });
    };

    /**
     * 转换单位信息为树形节点
     * @param tree 单位信息
     */
    handleDepartmentTreeNode = tree => tree?.map(item => ({
        ...item,
        title: item.name,
        key: item.id,
        value: item.id,
        children: !!item.children ? this.handleDepartmentTreeNode(item.children) : undefined
    }))

    fileChange = (v) => {
        if (v.file.status === "done") {
            this.close();
            this.props?.onSuccess()
        }
    }

    treeSelectChange = (v) => this.setState({departmentId: v})

    onChange = (v) => {
        console.log(v)

    }

    /**
     * 更新客户单位
     * @param data   表单数据
     */
    handle = data => {};

    render() {
        return <DraggableModal onCancel={this.close} title="批量导入客户单位" ref={this.draggableRef}>
            <a href={`${global.config.url}/customer/department/excel-template`}>下载模板</a>
            <Form ref={this.formRef} onFinish={this.handle} layout="vertical">
                <Form.Item name="department" label="选择父单位" required
                           rules={[{required: true, message: "请选择父单位"}]}>
                    <TreeSelect treeData={this.state.treeData}
                                showSearch
                                allowClear
                                onChange={this.treeSelectChange}
                                treeNodeFilterProp="title"/>
                </Form.Item>
                {
                    this.state.departmentId ?
                        <FileUploadRender uploadPath={`/customer/department/${this.state.departmentId}/excel`}
                                          uploadProps={{maxCount: 1}}
                                          ref={this.filesRef} required onChange={this.fileChange}/> : null
                }
                <Row gutter={8} justify="end">
                    <Col>
                        <Space>
                            <Button disabled={this.state.loading} onClick={this.close}>关闭</Button>
                            {/*<Button loading={this.state.loading} type="primary" htmlType="submit">导入</Button>*/}
                        </Space>
                    </Col>
                </Row>
            </Form>
        </DraggableModal>;
    }
}
