import React, {forwardRef, useImperativeHandle, useState} from "react";
import {Drawer} from "antd";
import {ProDescriptions} from "@ant-design/pro-components";
import VersionApi from "api/user/version";

const PreviewVersionUpdate = (props, ref) => {

    const [visible, setVisible] = useState(false);
    const [versionId, setVersionId] = useState(false);
    const [versionData, setVersionData] = useState(false);

    useImperativeHandle(ref, () => ({
        show
    }));

    const show = (id) => {
        setVisible(true);
        setVersionId(id);
        requestVersionInfo(id);
    };

    const close = () => setVisible(false);

    /**
     * 获取版本信息
     * @param versionId
     */
    const requestVersionInfo = (versionId) => {
        VersionApi.find(versionId).then(res => {
            setVersionData(res.data);
        }).catch(err => {
            console.error("Request version info failed", err);
        });
    };

    return (<Drawer title="查看版本更新" open={visible} onClose={close} destroyOnClose
                    width={window.innerWidth < 576 ? window.innerWidth : window.innerWidth * 0.55}>
        <ProDescriptions bordered size="small" layout="horizontal"
                         column={window.innerWidth < 800 ? 1 : 4}
                         title="查看版本更新">
            <ProDescriptions.Item label="版本名称" span={4}>
                {versionData.versionName}
            </ProDescriptions.Item>
            <ProDescriptions.Item label="版本序号" span={4}>
                {versionData.versionSerial}
            </ProDescriptions.Item>
            <ProDescriptions.Item label="平台" span={4}>
                {versionData.platform}
            </ProDescriptions.Item>
            <ProDescriptions.Item label="下载地址" span={4}>
                <a href={versionData.path} target="_blank">{versionData.path}</a>
            </ProDescriptions.Item>
            <ProDescriptions.Item label="描述" span={4}>
                <p dangerouslySetInnerHTML={{__html: versionData.description}} style={{margin: 0}}/>
            </ProDescriptions.Item>
        </ProDescriptions>

    </Drawer>);
};

export default forwardRef(PreviewVersionUpdate);
