import React, {Component} from "react";
import {Button, Col, Form, Input, message, Row, Space} from "antd";
import axiosInterceptors from "axios";
import {setFieldsError} from "plugin/formily";
import DraggableModal from "component/DraggableModal";

/**
 * 批量生成二维码
 * 输入生成数量 调用接口
 * 将返回值转换为zip 格式下载
 */
export default class QRCodeModal extends Component {

    formRef = React.createRef();
    draggableRef = React.createRef();

    state = {
        show: false,
        loading: false
    };

    show = () => {
        this.draggableRef.current.show();
    };

    close = () => {
        this.setState({
            loading: false
        });
        this.draggableRef.current.close();
    };

    toggleLoading = () => this.setState({loading: !this.state.loading});

    handle = data => {
        this.toggleLoading();
        axiosInterceptors.get(`${global.config.url}/resource/device/qr-code/excel`, {
            responseType: "blob",
            headers: {token: window.localStorage.getItem("Token")},
            params: {...data}
        }).then(res => {
            this.close();
            this.props.onSuccess();
            let url = window.URL.createObjectURL(new Blob([res.data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8"}));
            let link = document.createElement("a");
            link.style.display = "none";
            link.href = url;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }).catch(error => {
            if (error.status === 40000) {
                setFieldsError(error, this.formRef);
            } else {
                message.error({key: "failed", content: error.data});
            }
            this.toggleLoading();
            console.warn("Request interceptors failed.", error);
        });
    };

    render() {
        return <DraggableModal onCancel={this.close} title="生成二维码" ref={this.draggableRef}>
            <Form ref={this.formRef} onFinish={this.handle} layout={"vertical"}>
                <Form.Item name="count" label="生成数量" rules={[{
                    required: false,
                    pattern: new RegExp(/^[1-9]\d*$/, "g"),
                    message: "请输入正确的整数"
                }]}>
                    <Input/>
                </Form.Item>
                <Form.Item name="tag" label="初始标识" rules={[{
                    required: false,
                    pattern: new RegExp('^[A-Z]{2,4}$'),
                    message: "请输入开头2-4个大写字母",
                }]}>
                    <Input />
                </Form.Item>
                <Row gutter={8} justify="end">
                    <Col>
                        <Space>
                            <Button disabled={this.state.loading} onClick={this.close}>取消</Button>
                            <Button loading={this.state.loading} type="primary" htmlType="submit">生成</Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </DraggableModal>;
    }
}

