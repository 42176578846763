const EnumServeType = {
    "rjwh": "软件维护",
    "tyjwh-ty": "通用机维护（天玥）",
    "tyjwh-cyst": "通用机维护（超越申泰）",
    "gcoawh": "国产OA维护",
    "oa/yjgz": "国产OA维护/硬件故障",
    "oa/xtgz": "国产OA维护/系统故障",
    "oa/lcdz": "国产OA维护/流程制定",
    "oa/rcwh": "国产OA维护/日常维护",
    "xtrjwh": "系统软件维护",

    "zyjwh": "专用机维护",
    "zyjwh-cyst-DT3000-L12": "专用机维护（超越申泰 DT3000-L12）",
    "zyjwh-cyst-DT3010-L12": "专用机维护（超越申泰 DT3010-L12）",
    "zyjwh-cyst-DT3020-L": "专用机维护（超越申泰 DT3020-L）",
    "zyjwh-cyst-DT3030-L": "专用机维护（超越申泰 DT3030-L）",

    "tyjwh": "通用机维护",
    "tyjwh-ty-TR1192": "通用机维护（天玥 TR1192）",
    "tyjwh-cystDT-3010-F": "通用机维护（超越申泰DT-3010-F）",
    "tyjwh-cystDT-3010-L": "通用机维护（超越申泰DT-3010-L）",
    "tyjwh-cystDT-3020-L": "通用机维护（超越申泰DT-3020-L）",
    "tyjwh-cystDT-3030-L": "通用机维护（超越申泰DT-3030-L）",
    "dyjwh": "打印机维护",
    "dyjwh-gdtsmy-OEM200M": "打印机维护（光电通扫描仪 OEM200M）",
    "dyjwh-gdtbsytj-OEM3000DN": "打印机维护（光电通白色一体机 OEM3000DN）",
    "dyjwh-gdthsytj-OEP400DN": "打印机维护（光电通黑色一体机 OEP400DN）",
    "dyjwh-gdt-OEP102D": "打印机维护（光电通 OEP102D）",
    "dyjwh-gdt- MP3104CDN": "打印机维护（光电通 MP3104CDN）",
    "dyjwh-gdt-MP3020DN": "打印机维护（光电通 MP3020DN）",
    "dyjwh-gdt-OEP6015DN": "打印机维护（光电通 OEP6015DN）",
    "dyjwh-bt-M7105DN": "打印机维护（奔图 M7105DN）",
    "dyjwh-gdt-MP4024DN": "打印机维护（光电通 MP4024DN）",
    "yjaz": "硬件安装",
    "xtxj": "系统巡检",
    "ldcl": "漏洞处理",
    "dyjhf": "打印机换粉",
    "yjhsxh": "硬件回收销毁",
    "zzwjxh": "纸质文件销毁",
    "dzgwyw": "大组工网运维",
    "others-customer": "其他客户发起"
}
export default EnumServeType
