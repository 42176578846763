import {formatDate} from "../../utils/tools";
import dayjs from "dayjs";

const RemoteApi = {
    /**
     * 分页查询
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    page: params => {

        if (params.time){
            //console.log(dayjs(params.time[0]).format('YYYY-MM-DD'),dayjs(params.time[1]).format('YYYY-MM-DD'))
           params.empowerStartTime = dayjs(params.time[0]).format('YYYY-MM-DD');
           params.empowerEndTime = dayjs(params.time[1]).format('YYYY-MM-DD');
           delete params.time;
        }
        return global.axios.get("/resource/ahri/ahri/page", {params})
    },

    /**
     * 查询远程设备信息
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    find: id => global.axios.get(`/resource/ahri/ahri/${id}`),

    /**
     * 新建
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    create: params => global.axios.post("/resource/ahri", params),

    /**
     * 更新
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    update: params => global.axios.patch(`/resource/ahri/ahri/${params.id}`, params),

    /**
     * 删除
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    remove: id => global.axios.delete(`/resource/ahri/ahri/${id}`),

    /**
     * 连接设备
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    connectDevice: id => global.axios.get(`/resource/ahri/ahri/remote/${id}`),

    /**
     * 分页查询远程连接设备日志
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    disconnectDeviceLogPage: params => global.axios.get(`/resource/ahri/log/page/`, {params}),
    /**
     * 远程连接设备全部日志
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    disconnectDeviceLogs: params => global.axios.get(`/resource/ahri/log/logs`,{params}),
    /**
     * 查询所有设备的经纬度
     * @returns {Promise<AxiosResponse<any>>}
     */
    getLnglatEquipment: () => global.axios.get("/resource/ahri/ahri/lnglat/list"),

    /**
     * 开启阿里云端录制
     */
    startRecordCloud: params => global.axios.post("/resource/ahri/ahri/start-cloud", params),

    /**
     * 意见反馈
     * @returns {Promise<AxiosResponse<any>>}
     */
    feedBackPage: params => {
        if (params.time){
            //console.log(dayjs(params.time[0]).format('YYYY-MM-DD'),dayjs(params.time[1]).format('YYYY-MM-DD'))
            params.createStartTime = dayjs(params.time[0]).format('YYYY-MM-DD');
            params.createEndTime = dayjs(params.time[1]).format('YYYY-MM-DD');
            delete params.time;
        }
        return global.axios.get("/resource/ahri/feedback/page", {params})
    },
    /**
     * 更新反馈记录
     * @param params
     */
    feedBackUpdate: params => global.axios.patch(`/resource/ahri/feedback`, params),

    /**
     * 获取设备状态
     */
    WorkRecPage: params => {
        if (params.time){
            //console.log(dayjs(params.time[0]).format('YYYY-MM-DD'),dayjs(params.time[1]).format('YYYY-MM-DD'))
            params.createStartTime = dayjs(params.time[0]).format('YYYY-MM-DD');
            params.createEndTime = dayjs(params.time[1]).format('YYYY-MM-DD');
            delete params.time;
        }
        return global.axios.get("/resource/ahri/work-rec/page", {params})
    }

}

export default RemoteApi;
