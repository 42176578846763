import React from "react";
import moment from "moment";
import {Button, Cascader, Col, DatePicker, Dropdown, Menu, Row, Space, Tooltip} from "antd";
import {DeleteOutlined, EditOutlined, EyeOutlined} from "@ant-design/icons";
import {PageContainer, ProTable, StatisticCard} from "@ant-design/pro-components";

import WorkorderApi from "api/workorder";
import {getUser, hasAnyRole} from "component/SecurityInterceptor";

import CreateOpsUnassignedWorkorderDrawer from "../unassigned/drawer/CreateOpsUnassignedWorkorderDrawer";
import CreateSmpUnassignedWorkorderDrawer from "../unassigned/drawer/CreateSmpUnassignedWorkorderDrawer";

import PreviewSmpWorkorderDrawer from "./drawer/PreviewSmpWorkorderDrawer";
import PreviewOpsWorkorderDrawer from "./drawer/PreviewOpsWorkorderDrawer";

import UpdateSmpWorkorderDrawer from "./drawer/UpdateSmpWorkorderDrawer";
import UpdateOpsWorkorderDrawer from "./drawer/UpdateOpsWorkorderDrawer";

import DeleteWorkorderModal from "./modal/DeleteWorkorderModal";

import ExportOpsWorkorderAsExcelModal from "./modal/export/ExportOpsWorkorderAsExcelModal";
import ExportOpsWorkorderAsWordModal from "./modal/export/ExportOpsWorkorderAsWordModal";
import ExportSmpWorkorderAsExcel from "./modal/export/ExportSmpWorkorderAsExcelModal";
import ExportSmpWorkorderAsWordModal from "./modal/export/ExportSmpWorkorderAsWordModal";
import ExportWorkorderAsExcelModal from "./modal/export/ExportWorkorderAsExcelModal";
import ExportWorkorderAsWordModal from "./modal/export/ExportWorkorderAsWordModal";
import dayjs from "dayjs";
import isDevEnvironment from "../../../../utils/isDevEnvironment";
import DictApi from "../../../../api/dict";
import EnumDict from "../../../../enum/dict";

/**
 * 我发起的工单
 *
 * @description 我发起的工单
 *
 * @author huang Carder
 * @since 2021-06-23
 * @version 1.0.0
 */
export default class TheWorkorderICreatePage extends React.Component {

    /**
     * AntdPro Table ActionRef
     */
    actionRef = null;

    editSmpRef = React.createRef();
    editOpsRef = React.createRef();
    deleteRef = React.createRef();
    previewSmpRef = React.createRef();
    previewOpsRef = React.createRef();

    state = {
        totalWorkorder: 0,
        startWorkorder: 0,
        dispatchWorkorder: 0,
        receiveWorkorder: 0,
        hangupWorkorder: 0,
        overtimeWorkorder: 0,
        loadingTotal: true,
        loadingStart: true,
        loadingDispatch: true,
        loadingReceive: true,
        loadingHangup: true,
        loadingOvertime: true,
        workorderId: "",
        columnStateMap: {
            priority: {
                show: false
            },
            appointmentTime: {
                show: false
            },
            deadLine: {
                show: false
            }
        }
    };

    /**
     * 表格的操作
     * @param node
     * @param entity
     * @param index
     * @param action
     * @returns {JSX.Element[]}
     */
    renderColumnOperations = (node, entity, index, action) => {
        return entity.logicType === "smp" ? this.renderOfColumnOperationWhenSmp(entity) : this.renderOfColumnOperationWhenOps(entity);
    };

    renderOfColumnOperationWhenSmp = (entity) => {
        return <Space>
            <Tooltip title="查看">
                <Button icon size="small" onClick={() => this.dialog(entity.id, this.previewSmpRef)}>
                    <EyeOutlined/>
                </Button>
            </Tooltip>
            <Tooltip title="编辑">
                <Button icon size="small"
                        onClick={() => this.dialog(entity.id, this.editSmpRef)}
                        disabled={entity.progress === 13 || entity.progress === 17}>
                    <EditOutlined/>
                </Button>
            </Tooltip>
            <Tooltip title="删除">
                <Button danger icon size="small"
                        onClick={() => this.dialog(entity.id, this.deleteRef)}
                        disabled={entity.progress === 13 || entity.progress === 17}>
                    <DeleteOutlined/>
                </Button>
            </Tooltip>
        </Space>;
    };

    renderOfColumnOperationWhenOps = (entity) => {
        return <Space>
            <Tooltip title="查看">
                <Button icon size="small" onClick={() => this.dialog(entity.id, this.previewOpsRef)}>
                    <EyeOutlined/>
                </Button>
            </Tooltip>
            <Tooltip title="编辑">
                <Button icon size="small" onClick={() => this.dialog(entity.id, this.editOpsRef)}
                        disabled={entity.progress === 13 || entity.progress === 17}>
                    <EditOutlined/>
                </Button>
            </Tooltip>
            <Tooltip title="删除">
                <Button danger icon size="small" onClick={() => this.dialog(entity.id, this.deleteRef)}
                        disabled={entity.progress === 13 || entity.progress === 17}>
                    <DeleteOutlined/>
                </Button>
            </Tooltip>
        </Space>;
    };

    dialog = (id, ref) => {
        this.setState({workorderId: id});
        ref.current.show(id);
    };
    openInspect = (id) => {
        this.dialog(id, this.previewSmpRef)
    };
    /**
     * AntdPro Table Columns
     */
    columns = [
        {
            title: "ID",
            dataIndex: "id",
            copyable: true,
            render: (node, entity) => {
                return entity.logicType === "smp" ?
                    <Button type="text" danger={entity.delay === 1 }
                            size="small"
                            onClick={() => this.dialog(entity.id, this.previewSmpRef)}>{entity.id}</Button> :
                    <Button type="text" danger={entity.delay === 1 }
                            size="small"
                            onClick={() => this.dialog(entity.id, this.previewOpsRef)}>{entity.id}</Button>;
            }
            // render: (node, entity) => {
            //     let time = dayjs(entity.deadLine).format("YYYYMMDD");
            //     let nowTime = dayjs().locale("zh-cn").format("YYYYMMDD");
            //     let flag;
            //     if (nowTime - time > 0 && entity.progress === 5) {
            //         flag = true;
            //     }
            //     return entity.logicType === "smp" ?
            //         [<Button type="link" danger={entity.progress === 0 || flag}
            //                  key="id"
            //                  size="small"
            //                  onClick={() => this.dialog(entity.id, this.previewSmpRef)}>{entity.id}</Button>] :
            //         [<Button type="link" danger={entity.progress === 0 || flag}
            //                  key="id"
            //                  size="small"
            //                  onClick={() => this.dialog(entity.id, this.previewOpsRef)}>{entity.id}</Button>];
            // }
        },
        {
            title: "处理进度",
            dataIndex: "progress",
            valueEnum: require("../assets/WorkorderProgress").default
        },
        {
            title: "服务类型",
            dataIndex: "type",
            valueEnum: require("../assets/WorkorderType").WorkorderType
        },
        {
            title: "工单类型",
            dataIndex: "logicType",
            valueEnum: require("../assets/WorkorderLogicType").default
        },
        {
            title: "客户姓名",
            dataIndex: "clientName"
        },
        {
            title: "客户电话",
            dataIndex: "clientCellphone"
        },
        // {
        //     title: "填单人",
        //     dataIndex: "creatorName",
        //     hideInSearch: true
        // },
        {
            title: "处理人",
            dataIndex: "handlerName"
        },
        {
            title: "创建时间",
            dataIndex: "createTime",
            hideInSearch: true
        },
        {
            title: "计划完成时间",
            dataIndex: "deadLine",
            hideInSearch: true,
            key: "deadLine"
        },
        {
            title: "预约时间",
            dataIndex: "appointmentTime",
            key: "appointmentTime",
            hideInSearch: true
        },
        {
            title: "优先级",
            dataIndex: "priority",
            key: "priority",
            valueEnum: require("../assets/WorkorderPriority").default
        },
        {
            title: "满意度",
            dataIndex: "feedbackScore",
            hideInTable: !hasAnyRole(["customer-service", "admin"]),
            hideInSearch: !hasAnyRole(["customer-service", "admin"]),
            valueEnum: require("../assets/WorkorderFeedbackType").default
        },
        {
            title: "所在区县",
            key: "locate",
            hideInTable: true,
            renderFormItem: (_, {type, defaultRender, formItemProps, fieldProps, ...rest}, form) => <Cascader
                options={require("asset/location.json")} {...fieldProps}/>
        },
        {
            title: "时间选择",
            key: "timer",
            hideInTable: true,
            renderFormItem: (_, {type, defaultRender, formItemProps, fieldProps, ...rest}, form) => {
                return (
                    <DatePicker.RangePicker {...fieldProps} inputReadOnly/>
                );
            }
        },
        {
            title: "异常状态",
            formItemProps: {name: "abnormalStatus"},
            valueType: "select",
            initialValue: this.props.location?.state?.abnormalStatus,
            hideInTable: true,
            fieldProps: {
                showSearch: false,
                debounce: 500,
                mode: 'multiple',
                maxTagCount: 'responsive',
            },
            request: () => {
                return DictApi.list({code : EnumDict.ABNORMAL_STATUS}).then(res => {
                    let selectData = [];
                    res.data.forEach(item => {
                        selectData.push({label: item.value, value: item.id});
                    });
                    return selectData;
                }).catch(() => [])
            },
        },
        {
            title: "操作",
            valueType: "option",
            render: (node, entity, index, action) => this.renderColumnOperations(node, entity, index, action)
        }
    ];

    /**
     * 渲染发起运维工单按钮
     * @returns {JSX.Element|null}
     */
    renderCreateOpsWorkorderBtn = () => {
        if (isDevEnvironment) {
            return <CreateOpsUnassignedWorkorderDrawer key="createSmp" onSuccess={this.reload}/>
        } else {
            // 2022/09/14 为俩用户专门添加发起运维工单权限
            return getUser().departments[0].id === "155562326307770368" || ["168955227586826240", "264588201702854656"].some(item => item === getUser().id) ?
                <CreateOpsUnassignedWorkorderDrawer key="createSmp" onSuccess={this.reload}/> : null
        }
    }

    /**
     * 导出与发起工单按钮
     * @returns {JSX.Element[]}
     */
    renderToolbar = () => {
        const userMenu = () => {
            return <Menu>
                <Menu.SubMenu key="exportOps" title="导出运维">
                    <Menu.Item key="smpExcel">
                        <ExportOpsWorkorderAsExcelModal onSuccess={this.reload} creatorId={getUser().id}/>
                    </Menu.Item>
                    <Menu.Item key="smpWord">
                        <ExportOpsWorkorderAsWordModal onSuccess={this.reload} creatorId={getUser().id}/>
                    </Menu.Item>
                </Menu.SubMenu>
                <Menu.SubMenu key="exportSmp" title="导出信创">
                    <Menu.Item key="opsExcel">
                        <ExportSmpWorkorderAsExcel onSuccess={this.reload} creatorId={getUser().id}/>
                    </Menu.Item>
                    <Menu.Item key="opsWord">
                        <ExportSmpWorkorderAsWordModal onSuccess={this.reload} creatorId={getUser().id}/>
                    </Menu.Item>
                </Menu.SubMenu>
                <Menu.SubMenu key="exportAll" title="导出所有">
                    <Menu.Item key="opsAndSmpExcel">
                        <ExportWorkorderAsExcelModal onSuccess={this.reload} creatorId={getUser().id}/>
                    </Menu.Item>
                    <Menu.Item key="opsAndSmpWord">
                        <ExportWorkorderAsWordModal onSuccess={this.reload} creatorId={getUser().id}/>
                    </Menu.Item>
                </Menu.SubMenu>
            </Menu>;
        };

        return [
            hasAnyRole(["admin", "customer-service"]) ? <>
                    <Dropdown overlay={userMenu()} arrow trigger={["click"]} key="1">
                        <Button>导出</Button>
                    </Dropdown>
                    <CreateOpsUnassignedWorkorderDrawer key="createSmp" onSuccess={this.reload}/>,
                    <CreateSmpUnassignedWorkorderDrawer key="createOps" onSuccess={this.reload} onInspect={this.openInspect}/>
                </>
                 : null,
            // this.renderCreateOpsWorkorderBtn(),

        ];
    };

    /**
     * 获取工单表格信息
     * @param params
     * @returns {*}
     */
    requestPage = params => {
        let userInfo = getUser();
        params.creatorId = userInfo.id;
        if (!!params.locate) {
            params.province = params.locate[0];
            params.city = params.locate[1];
            params.county = params.locate[2];
            params.locate = undefined;
        }
        if (!!params.timer) {
            params.startDate = moment(params.timer[0]).format("YYYY-MM-DD");
            params.endDate = moment(params.timer[1]).format("YYYY-MM-DD");
            params.timer = undefined;
        }
        return WorkorderApi.page(params).then(response => {
            this.clearSelect();
            if (!!response.data) {
                return {data: response.data.records, success: true, total: response.data.total};
            }
            return {data: [], success: true, total: 0};
        }).catch(error => {
            console.log("Find workorder page failed", error);
            return {data: [], success: true, total: 0};
        });
    };

    reload = () => {
        let userId = getUser().id;
        this.requestTotalWorkorder(userId);
        this.requestStartWorkorder(userId); // 创建 1
        this.requestDispatchWorkorder(userId); //已分配 5
        this.requestReceiveWorkorder(userId); // 已领取 9
        this.requestHangupWorkorder(userId); // 挂起 14
        this.requestOvertimeWorkorder(userId); // 超时
        this.actionRef?.reload();
    };

    onLoadingChange = () => {
        let userId = getUser().id;
        this.requestTotalWorkorder(userId);
        this.requestStartWorkorder(userId); // 创建 1
        this.requestDispatchWorkorder(userId); //已分配 5
        this.requestReceiveWorkorder(userId); // 已领取 9
        this.requestHangupWorkorder(userId); // 挂起 14
        this.requestOvertimeWorkorder(userId); // 超时
    };

    clearSelect = () => this.actionRef.clearSelected();

    onColumnStateMapChange = columnStateMap => this.setState({columnStateMap});

    /**
     * 统计值
     */
    headerContentRender = () => (<Row gutter={[8, 8]}>
        <Col xs={12} lg={4}>
            <StatisticCard statistic={{title: "已发起", value: this.state.totalWorkorder}}
                           bordered
                           loading={this.state.loadingTotal}/>
        </Col>
        <Col xs={12} lg={4}>
            <StatisticCard statistic={{title: "待分配", value: this.state.startWorkorder}}
                           bordered
                           loading={this.state.loadingStart}/>
        </Col>
        <Col xs={12} lg={4}>
            <StatisticCard statistic={{title: "待领取", value: this.state.dispatchWorkorder}}
                           bordered
                           loading={this.state.loadingDispatch}/>
        </Col>
        <Col xs={12} lg={4}>
            <StatisticCard statistic={{title: "待处理", value: this.state.receiveWorkorder}}
                           bordered
                           loading={this.state.loadingReceive}/>
        </Col>
        <Col xs={12} lg={4}>
            <StatisticCard statistic={{title: "已挂起", value: this.state.hangupWorkorder}}
                           bordered
                           loading={this.state.loadingHangup}/>
        </Col>
        <Col xs={12} lg={4}>
            <StatisticCard statistic={{title: "已超时", value: this.state.overtimeWorkorder, status: "error"}}
                           bordered
                           loading={this.state.loadingOvertime}/>
        </Col>
    </Row>);

    componentDidMount() {
        let userId = getUser()?.id;
        this.requestTotalWorkorder(userId);
        this.requestStartWorkorder(userId); // 创建 1
        this.requestDispatchWorkorder(userId); //已分配 5
        this.requestReceiveWorkorder(userId); // 已领取 9
        this.requestHangupWorkorder(userId); // 挂起 14
        this.requestOvertimeWorkorder(userId); // 超时
    }

    /**
     * 发起工单总数
     * @param id
     */
    requestTotalWorkorder = id => {
        return global.axios.get("/workorder/workorder/statistics/count?creatorId=" + id).then(response => {
            this.setState({
                totalWorkorder: response.data,
                loadingTotal: false
            });
        }).catch(error => {
            console.warn("Find workorder page failed", error);
            this.setState({
                totalWorkorder: "加载失败",
                loadingTotal: false
            });
        });

    };

    /**
     * 待分配的工单
     * @param id
     */
    requestStartWorkorder = id => {
        return global.axios.get("/workorder/workorder/statistics/count?progress=1&creatorId=" + id).then(response => {
            this.setState({
                startWorkorder: response.data,
                loadingStart: false
            });
        }).catch(error => {
            console.warn("Find workorder page failed", error);
            this.setState({
                startWorkorder: "加载失败",
                loadingStart: false
            });
        });
    };

    /**
     * 待领取的工单
     * @param id
     */
    requestDispatchWorkorder = id => {
        return global.axios.get("/workorder/workorder/statistics/count?progress=5&creatorId=" + id).then(response => {
            this.setState({
                dispatchWorkorder: response.data,
                loadingDispatch: false
            });
        }).catch(error => {
            console.warn("Find workorder page failed", error);
            this.setState({
                dispatchWorkorder: "加载失败",
                loadingDispatch: false
            });
        });
    };

    /**
     * 待处理的工单
     * @param id
     */
    requestReceiveWorkorder = id => {
        return global.axios.get("/workorder/workorder/statistics/count?progress=9&creatorId=" + id).then(response => {
            this.setState({
                receiveWorkorder: response.data,
                loadingReceive: false
            });
        }).catch(error => {
            console.warn("Find workorder page failed", error);
            this.setState({
                receiveWorkorder: "加载失败",
                loadingReceive: false
            });
        });
    };

    /**
     * 挂起中的工单
     * @param id
     */
    requestHangupWorkorder = id => {
        return global.axios.get("/workorder/workorder/statistics/count?progress=14&creatorId=" + id).then(response => {
            this.setState({
                hangupWorkorder: response.data,
                loadingHangup: false
            });
        }).catch(error => {
            console.warn("Find workorder page failed", error);
            this.setState({
                hangupWorkorder: "加载失败",
                loadingHangup: false
            });
        });
    };

    /**
     * 已超时的工单
     * @param id
     */
    requestOvertimeWorkorder = id => {
        return global.axios.get("/workorder/workorder/statistics/count/delayed?creatorId=" + id).then(response => {
            this.setState({
                overtimeWorkorder: response.data,
                loadingOvertime: false
            });
        }).catch(error => {
            console.warn("Find workorder page failed", error);
            this.setState({
                overtimeWorkorder: "加载失败",
                loadingOvertime: false
            });
        });
    };

    render() {
        return <PageContainer header={{title: "我发起的工单", breadcrumb: {}}} extra={this.renderToolbar()}
                              content={this.headerContentRender()}>
            <ProTable
                rowKey="id"
                options={{reload: true, setting: true, density: true}}
                defaultSize="small"
                onLoadingChange={this.onLoadingChange}
                columnsState={this.state.columnStateMap}
                onColumnsStateChange={this.onColumnStateMapChange}
                actionRef={actionRef => this.actionRef = actionRef}
                columns={this.columns}
                request={this.requestPage}/>

            <UpdateSmpWorkorderDrawer onSuccess={this.reload} ref={this.editSmpRef}/>
            <UpdateOpsWorkorderDrawer onSuccess={this.reload} ref={this.editOpsRef}/>
            <DeleteWorkorderModal onSuccess={this.reload} ref={this.deleteRef}/>
            <PreviewSmpWorkorderDrawer onSuccess={this.reload} ref={this.previewSmpRef}/>
            <PreviewOpsWorkorderDrawer onSuccess={this.reload} ref={this.previewOpsRef}/>
        </PageContainer>;
    }
}
