const StorehouseTypeApi = {
    /**
     * 分页查询仓库类型
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    page: params => global.axios.get("/repository/stocktypes/page", {params}),

    /**
     * 创建仓库类型
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    create: data => global.axios.post("/repository/stocktype", data),

    /**
     * 根据id查询类型信息
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    find: id => global.axios.get(`/repository/stocktype/${id}`),

    /**
     * 更新仓库
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    edit: data => global.axios.patch("/repository/stocktype", data),

    /**
     * 查询所有备件类型
     * @returns {Promise<AxiosResponse<any>>}
     */
    findAll: () => global.axios.get("/repository/stocktypes"),

    /**
     * 查询指定仓库下可用的类型
     * @param storehouseId
     * @returns {Promise<AxiosResponse<any>>}
     */
    findTypeForStorehouse: storehouseId => global.axios.get(`/repository/repository/${storehouseId}/available-stocktype`)
};

export default StorehouseTypeApi;
