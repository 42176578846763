import React, {Component} from "react";
import {Button, Col, Row, Space, Typography} from "antd";
import DraggableModal from "component/DraggableModal";
import FromWorkorderCreateKnowledgeArticleDrawer
    from "../../../equipment/repository/drawer/FromWorkorderCreateKnowledgeArticleDrawer";


class ConfirmCreateKnowledgeModal extends Component {
    state = {
        show: false,
        workorderInfo: {}
    };

    draggableRef = React.createRef();
    createKnowledgeArticleRef = React.createRef();

    show = param => {
        this.draggableRef.current.show();
        this.setState({workorderInfo: param});
    };

    close = () => {
        this.draggableRef.current.close();
        this.props.closeDrawer();
        this.props.reloadStatus();
    };

    handle = () => {
        this.createKnowledgeArticleRef.current.show(this.state.workorderInfo);
    };

    reload = () => this.close();

    render() {
        return <DraggableModal onCancel={this.close} title="是否创建知识库" ref={this.draggableRef}>
            <Typography>是否创建知识库</Typography>
            <Row gutter={8} justify="end">
                <Col>
                    <Space>
                        <Button onClick={this.close}>取消</Button>
                        <Button type="primary" onClick={this.handle}>创建</Button>
                    </Space>
                </Col>
            </Row>
            <FromWorkorderCreateKnowledgeArticleDrawer onSuccess={this.reload} ref={this.createKnowledgeArticleRef}/>
        </DraggableModal>;
    }
}

export default ConfirmCreateKnowledgeModal;
