const EquipmentRepositoryApi = {
    /**
     * 分页查询
     */
    page: (params) => global.axios.get("/resource/knowledges/page", {params}),
    /**
     * 新建文章知识库
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    create: (data) => global.axios.post("/resource/knowledge", data),
    /**
     * 知识库文章关联设备类型
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    associated: (data) => global.axios.post("/resource/device/type-article", data),
    /**
     * 删除文章知识库
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    delete: (id) => global.axios.delete(`/resource/knowledge/${id}`),

    /**
     * 获取指定知识库文章信息
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    getInfo: (id) => global.axios.get(`/resource/knowledge/${id}`),

    /**
     * 编辑知识库文章
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    edit: (data) => global.axios.patch(`/resource/knowledge`, data)
};

export default EquipmentRepositoryApi;
