import React from "react";
import {Button, Col, Row, Space} from "antd";

import {ProForm, ProFormText} from "@ant-design/pro-components";

import RoleApi from "api/user/role";
import {setFieldsError} from "plugin/formily";
import DraggableModal from "component/DraggableModal";

/**
 * 新建角色
 * 填写对应的表单信息 调用 新建角色接口 新建角色
 */
export default class CreateRoleModal extends React.Component {

    state = {
        show: false,
        loading: false
    };

    formRef = React.createRef();
    draggableRef = React.createRef();

    show = () => this.draggableRef.current.show();

    close = () => this.draggableRef.current.close();

    toggleLoading = () => this.setState({loading: !this.state.loading});

    handle = (data) => {
        this.toggleLoading();
        RoleApi.create(data).then((response) => {
            this.toggleLoading();
            this.close();
            this.props.onSuccess();
        }).catch(error => {
            this.toggleLoading();
            if (error.status === 40001 || error.status === 40402) {
                this.formRef.current.setFields([{name: "identifier", errors: ["角色标识符已存在"]}]);
            } else {
                setFieldsError(error,this.formRef);
            }
        });
    };

    trigger = () => {
        return <Button type="primary" onClick={() => this.show()}>新建</Button>;
    };

    dialog = () => {
        return <DraggableModal onCancel={this.close} title="新建角色" ref={this.draggableRef}>
            <ProForm formRef={this.formRef} onFinish={this.handle} layout={"vertical"} submitter={{
                resetButtonProps: {style: {display: "none"}},
                submitButtonProps: {style: {display: "none"}}
            }}>
                <ProFormText name="name" label="角色名称" placeholder="请输入角色名称" required
                             rules={[{required: true, message: "请输入角色名称"}, {pattern: /\S/, message: "不能为空"}]}/>
                <ProFormText name="identifier" label="角色标识符" placeholder="请输入角色标识符" required
                             rules={[{required: true, message: "请输入角色标识符"}, {pattern: /\S/, message: "不能为空"}]}/>
                <ProFormText name="description" label="角色说明" placeholder="请输入角色说明" required maxLength="120"
                             rules={[{required: true, message: "请输入角色说明"}, {pattern: /\S/, message: "不能为空"}]}/>
                <Row gutter={8} justify="end">
                    <Col>
                        <Space>
                            <Button disabled={this.state.loading} onClick={this.close}>取消</Button>
                            <Button loading={this.state.loading} type="primary" htmlType="submit">新建</Button>
                        </Space>
                    </Col>
                </Row>
            </ProForm>
        </DraggableModal>;
    };

    render() {
        return <>
            {this.trigger()}
            {this.dialog()}
        </>;
    }
}
