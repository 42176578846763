import React, {Component} from "react";
import {Button, Col, Row, Space} from "antd";
import Print from "react-print-html";
import axiosInterceptors from "axios";
import DraggableModal from "component/DraggableModal";

/**
 * 打印指定设备二维码
 * 根据对应id 获取设备得到二维码信息
 * 使用 Print 插件 唤起打印机 打印二维码
 */
export default class EquipmentQrCodeModal extends Component {

    qrCodeRef = React.createRef();
    draggableRef = React.createRef();

    state = {
        show: false,
        imgUrl: "",
        loading: false
    };

    show = id => {
        this.draggableRef.current.show();
        this.getQrData(id);
    };

    close = () => this.draggableRef.current.close();

    toggleLoading = () => this.setState({loading: !this.state.loading});

    getQrData = id => {
        axiosInterceptors.get(`${global.config.url}/resource/device/qr-code`, {
            responseType: "blob",
            headers: {token: window.localStorage.getItem("Token")},
            params: {codeId: id, width: 160}
        }).then(res => {
            let url = window.URL.createObjectURL(new Blob([res.data], {type: "image/png"}));
            this.setState({
                imgUrl: url
            });
        }).catch(error => {
            console.warn("Request interceptors failed.", error);
        });
    };

    handle = () => {
        this.toggleLoading();
        Print(this.qrCodeRef.current);
        this.toggleLoading();
        this.close();
        this.props.onSuccess();
    };

    render() {
        return <DraggableModal onCancel={this.close} title="打印指定设备二维码" ref={this.draggableRef}>
            <Row justify="center">
                <Col span={15}>
                    <div style={{textAlign: "center", width: "80mm", height: "50mm", position: "relative"}}
                         ref={this.qrCodeRef}>
                        <img src={this.state.imgUrl} style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%,-50%)"
                        }}/>
                    </div>
                </Col>
            </Row>
            <Row gutter={8} justify="end">
                <Col>
                    <Space>
                        <Button disabled={this.state.loading} onClick={this.close}>取消</Button>
                        <Button loading={this.state.loading} type="primary" onClick={this.handle}>打印</Button>
                    </Space>
                </Col>
            </Row>
        </DraggableModal>;
    }

}

