import React from "react";
import {Button, Col, Row, Space} from "antd";

import AccountApi from "api/user/account";
import {ProForm, ProFormCascader, ProFormText, ProFormTreeSelect} from "@ant-design/pro-components";
import {setFieldsError} from "plugin/formily";
import DraggableModal from "component/DraggableModal";
import DepartmentApi from "../../../../../api/user/department";
import {getUser} from "../../../../../utils/securityCheck";
import {hasAnyRole} from "../../../../../component/SecurityInterceptor";


/**
 * 编辑账号对话框
 * 根据id 获取对应账户信息
 * 修改表单信息 调用编辑账户接口 修改账户信息
 */
export default class EditAccountModal extends React.Component {

    state = {
        show: false,
        loading: false,
        editAccountId: ""
    };

    formRef = React.createRef();
    draggableRef = React.createRef();

    /**
     * 控制编辑对话框显示
     */
    show = id => {
        this.draggableRef.current.show();
        this.setState({editAccountId: id}, this.getAccountInfo);
    };

    close = () => {
        this.setState({loading: false});
        this.draggableRef.current.close();
    };

    /**
     *查询选中账号信息
     */
    getAccountInfo = () => {
        AccountApi.findUserInfo({params: {accountId: this.state.editAccountId}}).then(response => {
            let params = {
                cellphone: response.data.cellphone,
                email: response.data.email,
                name: response.data.name,
                address: response.data.address,
                department: response.data?.departments[0]?.id,
                ares: []
            };
            params.ares[0] = response.data.province;
            params.ares[1] = response.data.city;
            params.ares[2] = response.data.county;
            this.formRef.current.setFieldsValue(params);
        }).catch(error => {
            console.warn("Get customer Info failed", error);
        });
    };

    /**
     * 控制 loading 效果显示
     */
    toggleLoading = () => {
        this.setState({
            loading: !this.state.loading
        });
    };

    /**
     * 更新账户信息
     * @param data   表单数据
     */
    handle = (data) => {
        data.province = data.ares[0];
        data.city = data.ares[1];
        data.county = data.ares[2];
        data.id = this.state.editAccountId;
        this.toggleLoading();
        AccountApi.edit(data).then(response => {
            this.close();
            this.props.onSuccess();
        }).catch(error => {
            this.toggleLoading();
            console.warn("Update customer failed", error);
            setFieldsError(error, this.formRef);
        });
    };

    render() {
        return <DraggableModal onCancel={this.close} title="编辑账户" ref={this.draggableRef}>
            <ProForm formRef={this.formRef} onFinish={this.handle} layout={"vertical"} submitter={{
                resetButtonProps: {style: {display: "none"}},
                submitButtonProps: {style: {display: "none"}}
            }}>
                <ProFormCascader required rules={[{required: true, message: "请选择所在地区"}]} name="ares" label="区域"
                                 fieldProps={{options: require("asset/location.json")}}/>
                <ProFormTreeSelect required rules={[{required: true, message: "请选择机构"}]} name="department"
                                   label="机构"
                                   allowClear
                                   secondary
                                   request={async () => {
                                       if(hasAnyRole(["admin"])){
                                           return DepartmentApi.findAllTrees().then(res => res.data).catch(() => [])
                                       }
                                       return DepartmentApi.findDepartmentTree(getUser().departments[0].id).then(res => res.data).catch(() => [])
                                   }}
                                   fieldProps={{
                                       showArrow: false,
                                       filterTreeNode: true,
                                       showSearch: true,
                                       treeNodeFilterProp: "title",
                                       fieldNames: {label: "name", value: "id"},
                                   }}/>
                <ProFormText name="name" label="姓名" placeholder="请输入姓名" required
                             rules={[{required: true, message: "请输入姓名"}, {pattern: /\S/, message: "不能为空"}]}/>
                {/*<ProFormText name="cellphone" label="手机号" placeholder="请输入手机号" required*/}
                {/*             rules={[{required: true, message: "请输入手机号"}, {*/}
                {/*                 pattern: /^1[3456789]\d{9}$/g,*/}
                {/*                 message: "请输入正确的手机号"*/}
                {/*             }]}/>*/}
                <ProFormText name="email" label="邮箱" placeholder="请输入邮箱"
                             rules={[{
                                 pattern: /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/g,
                                 message: "请输入正确的邮箱"
                             }]}/>
                <Row gutter={8} justify="end">
                    <Col>
                        <Space>
                            <Button disabled={this.state.loading} onClick={this.close}>取消</Button>
                            <Button loading={this.state.loading} type="primary" htmlType="submit">编辑</Button>
                        </Space>
                    </Col>
                </Row>
            </ProForm>
        </DraggableModal>;
    }
}
