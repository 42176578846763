import React from "react";
import {Button, Col, DatePicker, Drawer, Form, Input, message, Row, Skeleton, Space} from "antd";
import CustomerDepartmentPicker from "component/customer/department/CustomerDepartmentPicker";
import FileUploadRender from "component/FileUploadRender";
import {setFieldsError} from "plugin/formily";
import dayjs from "dayjs";
import isDevEnvironment from "../../../../utils/isDevEnvironment";
import {getUser} from "../../../../component/SecurityInterceptor";
import CustomerDepartmentApi from "../../../../api/customer/department";

/**
 * 创建合同
 * 请求客户所属单位数据  并将数据处理为想要的树形数据
 * 填写表单对应数据 请求合同创建接口  创建合同
 */
export default class CreateContractDrawer extends React.Component {

    state = {
        show: false,
        loading: false,
        drawerWidth: 280,
        departmentData: [],
        contractDataLoading: true
    };

    formRef = React.createRef();
    filesRef = React.createRef();

    show = async () => {
        this.setState({
            show: true
        }, () => {
            this.setState({
                drawerWidth: window.innerWidth < 576 ? window.innerWidth : window.innerWidth * 0.55
            });
        });
        await this.requestDepartmentTree();
    };

    close = () => {
        this.setState({
            show: false,
            loading: false
        });
    };

    requestDepartmentTree = async () => {
        const {data} = await CustomerDepartmentApi.page();
        const treeData = [];
        data.forEach(department => {
            treeData.push(this.handleDepartmentTreeNode(department));
        });
        this.setState({
            departmentData: treeData,
            contractDataLoading: false
        });
    };

    handleDepartmentTreeNode = (department) => {
        const node = {
            title: department.name,
            key: department.id,
            value: department.id,
            children: []
        };
        if (!!department.children && department.children.length > 0) {
            department.children.forEach((subDepartment) => {
                node.children.push(this.handleDepartmentTreeNode(subDepartment));
            });
        }
        return node;
    };

    onRemoveFile = (file) => {
        if (!file.response || !file.response.data && file.status === "error") {
            return true;
        }
        const path = file.response.data;
        return global.axios.delete("/resource/resource/file", {params: {path}}).then(() => {
            console.log("Delete workorder file success", path);
            return true;
        }).catch(error => {
            console.warn("Delete workorder file failed", error);
            return false;
        });
    };


    toggleLoading = () => {
        this.setState({
            loading: !this.state.loading
        });
    };

    handle = (data) => {
        // 限制只能网联账号新建合同
        // if (getUser()?.departments[0]?.id !== "155562326307770368") {
        //     message.error("该功能暂未对外开放!")
        //     return;
        // }
        let flag = dayjs(data.expireDate).format("YYYYMMDD") - dayjs(data.startDate).format("YYYYMMDD");
        if (flag < 0) {
            this.formRef.current.setFields([{name: "expireDate", errors: ["合同结束时间不能小于合同开始时间"]}]);
            return;
        }
        this.toggleLoading();
        const param = {};
        param.name = data.name;
        param.comment = data.comment;
        param.categoryId = "163063635416059904";
        param.status = 1;
        param.type = "contract";
        param.infos = [];
        param.infos.push({
            name: "合同开始时间",
            type: "date",
            value: dayjs(data.startDate).format("YYYY-MM-DD")
        });
        param.infos.push({
            name: "服务范围",
            type: "string",
            value: data.serviceContent
        });
        param.infos.push({
            name: "合同到期时间",
            type: "date",
            value: dayjs(data.expireDate).format("YYYY-MM-DD")
        });
        param.infos.push({
            name: "合同所属客户单位",
            type: "string",
            value: data.departmentId
        });
        param.infos.push({
            name: "客户方联系人",
            type: "string",
            value: data.clientName
        });
        param.infos.push({
            name: "客户方电话",
            type: "string",
            value: data.clientCellphone
        });
        param.infos.push({
            name: "市场方负责人",
            type: "string",
            value: data.salesmanName
        });
        param.infos.push({
            name: "市场方电话",
            type: "string",
            value: data.salesmanCellphone
        });
        if (!!this.filesRef.current.getFilesValue() && this.filesRef.current.getFilesValue().length > 0) {
            this.filesRef.current.getFilesValue().forEach(item => {
                param.infos.push({
                    name: "合同附件",
                    type: "file",
                    value: item
                });
            });
        } else {
            param.infos.push({
                name: "合同附件",
                type: "file",
                value: ""
            });
        }
        global.axios.post("/resource/resource", param).then(response => {
            console.info("Create contract success", response.data);
            this.close();
            this.props.onSuccess();
        }).catch(error => {
            if (error.status === 40000) {
                setFieldsError(error, this.formRef);
            } else {
                message.error({key: "failed", content: error.data});
            }
            this.toggleLoading();
            console.warn("Create contract failed", error);
        });
    };

    renderTrigger = () => {
        return <Button type="primary" onClick={this.show}>新建合同</Button>;
    };

    /**
     * 获取上传状态
     * @param info  状态数据
     */
    uploadOnChange = (info) => {
        this.setState({
            loading: info.fileList.some((item) => {
                return item.status === "uploading";
            })
        });
    };

    renderDialog = () => {
        return <Drawer title="新建合同" open={this.state.show} width={this.state.drawerWidth} footer={null}
                       onClose={this.close} destroyOnClose maskClosable={false} closable={!this.state.loading}>
            <Skeleton active loading={this.state.contractDataLoading}>
                <Form ref={this.formRef} onFinish={this.handle} layout={"vertical"}>
                    <Row gutter={8}>
                        <Col span={12}>
                            <Form.Item label="合同名称" required name="name" rules={[{required: true, message: "请输入合同名称"}, {
                                pattern: /\S/,
                                message: "内容不能为空"
                            }]}>
                                <Input maxLength={60}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="合同编号" required name="comment"
                                       rules={[{required: true, message: "请输入合编号"}, {
                                           pattern: /\S/,
                                           message: "内容不能为空"
                                       }]}>
                                <Input maxLength={60}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={8}>
                        <Col span={12}>
                            <Form.Item label="合同开始时间" required name="startDate"
                                       rules={[{required: true, message: "请选择合同开始时间"}]}>
                                <DatePicker format="YYYY-MM-DD" inputReadOnly style={{width: "100%"}}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="合同到期时间" required name="expireDate"
                                       rules={[{required: true, message: "请选择合同到期时间"}]}>
                                <DatePicker format="YYYY-MM-DD" inputReadOnly style={{width: "100%"}}/>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <CustomerDepartmentPicker name="departmentId" label="合同所属客户单位"
                                                      rules={[{required: true, message: "请选择客户单位"}]}/>
                        </Col>
                    </Row>
                    <Row gutter={8}>
                        <Col span={12}>
                            <Form.Item label="客户方联系人" required name="clientName"
                                       rules={[{required: true, message: "请输入客户联系人"}, {
                                           pattern: /\S/,
                                           message: "内容不能为空"
                                       }]}>
                                <Input maxLength={60}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="客户方电话" required name="clientCellphone"
                                       rules={[{required: true, message: "请输入客户电话"}, {
                                           pattern: /\S/,
                                           message: "内容不能为空"
                                       }]}>
                                <Input maxLength={60}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="市场方负责人" required name="salesmanName"
                                       rules={[{required: true, message: "请输入市场方负责人"}, {
                                           pattern: /\S/,
                                           message: "内容不能为空"
                                       }]}>
                                <Input maxLength={60}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="市场方电话" required name="salesmanCellphone"
                                       rules={[{required: true, message: "请输入市场方电话"}, {
                                           pattern: /\S/,
                                           message: "内容不能为空"
                                       }]}>
                                <Input maxLength={60}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={8}>
                        <Col span={24}>
                            <Form.Item label="服务范围" required name="serviceContent"
                                       rules={[{required: true, message: "请输入服务范围"}, {
                                           pattern: /\S/,
                                           message: "内容不能为空"
                                       }]}>
                                <Input.TextArea maxLength={60} showCount/>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <FileUploadRender uploadPath="/resource/resource/file" uploadProps={{maxCount: 1}}
                                              ref={this.filesRef}/>
                        </Col>
                    </Row>
                    <Row gutter={8} justify="end">
                        <Col>
                            <Space>
                                <Button disabled={this.state.loading} onClick={this.close}>取消</Button>
                                <Button loading={this.state.loading} type="primary" htmlType="submit">新建合同</Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
            </Skeleton>
        </Drawer>;
    };

    render() {
        return <>
            {this.renderTrigger()}
            {this.renderDialog()}
        </>;
    };
}
