import { Row, Col, Divider, Avatar, Modal } from 'dingtalk-design-desktop';
import { memo, useEffect, useRef } from 'react';
import styles from './index.css';
import { Camera, Mic } from '../../../../../../component/Device';
import { useDevice } from '../../../../../../hooks/device';
import { useRecoilValue } from 'recoil';
import { currentUserInfo } from '../../../../../../store';
import { isIOS, isWeixin } from '../../../../../../utils/tools';

const Preview = () => {
  const { cameraEnabled, openMicAndCameraSameTime, operateCamera, operateMic } = useDevice('pre');
  const { userName } = useRecoilValue(currentUserInfo);
  const ref = useRef(null);

  useEffect(() => {
    openMicAndCameraSameTime().then(([track]) => {
      if (isIOS() && isWeixin()) {
        Modal.centerConfirm({
          title: '提示',
          content: 'iOS微信浏览器需要在回调内播放',
          okText: '确认',
          cancelText: '取消',
          onOk() {
            track.play(ref.current, { fit: 'cover' });
          },
          onCancel() {
            track.play(ref.current, { fit: 'cover' });
          }
        })
      } else {
        track.play(ref.current, { fit: 'cover' });
      }
    })
  }, []);

  return (
    <div className="previewWrap">
      <Col ref={ref}></Col>
      <div className="devices">
          <Camera onClick={operateCamera} />
          <Divider type="vertical" />
          <Mic onClick={operateMic} />
      </div>
    </div>
  );
};

export default memo(Preview);
