import React, {Component} from "react";
import {Button, Col, Row, Space,message, Typography} from "antd";
import DraggableModal from "component/DraggableModal";
import EquipmentListApi from "../../../../../api/equipment/list";
import CustomerApi from "../../../../../api/customer/customer";
import AssignedWorkorderApi from "../../../../../api/workorder/assigned";
import WorkorderApi from "../../../../../api/workorder";
const styleItem = {
    padding: '5px 0',
};
const styleSpan = {
    padding: '0 5px 0 0',
    fontWeight: 'bold',
};
const styleSpanError = {
    padding: '0 5px 0 0',
    fontWeight: 'bold',
    color: 'red'
};
export default class RepairCodeModal extends Component {
    state = {
        loading: false,
        show: false,
        equipment: {},
        customerId: "",
        customerDepartmentId: "",
        customer: {
            customerName: "",
        },
        customerDepartment:{
            departmentName: ""
        },
        workorderId: "",
        Msg:""
    };

    draggableRef = React.createRef();

    show = (id,msg) => {
        if (id){
            this.setState({workorderId: id, Msg:msg}, () => {
                this.getEquipmentInfo(id)
             });
        }else {
            message.error("请输入正确的报修码进行校验")
        }
    };

    close = () => {
        this.setState({loading: false});
        this.draggableRef.current.close();
    };

    toggleLoading = () => this.setState({loading: !this.state.loading});

    handle = () => {

        this.props.onSuccess({
            equipment: this.state.equipment,
            customerId: this.state.customerId,
            departmentId: this.state.customerDepartmentId,
            customer: this.state.customer,
        })
        this.toggleLoading();
        this.close();
    };

    // 根据ID查询设备信息
    getEquipmentInfo = (id) => {
        EquipmentListApi.info(id).then((res) => {
            if(res.status === 20000){
                this.setState({
                    equipment: res.data,
                    customerId: res.data.customerId,
                    customerDepartmentId: res.data.customerDepartmentId,
                    // skeletonLoading: false
                }, () => {
                    this.requestCustomerInfo();
                    this.requestDepartmentInfo();
                });
                this.requestEquipmentInfo(res.data); //验证当前设备是否能发起工单 -> this.requestEquipmentInfo(res.data);

            }
        }).catch(err => {
            message.error("请输入正确的报修码进行校验")
            console.error("Get equipment fail", err);

        });
    };
    // 验证当前设备是否能发起工单
    requestEquipmentInfo = (data) => {
        message.success("校验成功");
        this.draggableRef.current.show();
    }
    // 查询客户信息
    requestCustomerInfo = () => {
        CustomerApi.find(this.state.customerId).then(response => {
            this.setState({
                customer: {
                    customerName: response.data.name,
                }
            });
        }).catch(err => {
            console.error("Get customer fail", err);
        });
    };
    // 查询客户单位信息
    requestDepartmentInfo = () => {
        CustomerApi.findDepartment(this.state.customerDepartmentId).then(response => {
            this.setState({
                customerDepartment: {
                    departmentName: response.data.name
                }
            });
        }).catch(err => {
            console.error("Get customer fail", err);
        });

    };

    render() {
        return <DraggableModal onCancel={this.close} title="设备信息" ref={this.draggableRef}>
            <Row>
                <Col span={24} style={styleItem}><span style={styleSpan}>设备型号:</span>{this.state.equipment.model}</Col>
            </Row>
            <Row>
                <Col span={10} style={styleItem}><span style={styleSpan}>报修码:</span>{this.state.equipment.id}</Col>
                <Col span={14} style={styleItem}><span style={styleSpan}>设备名称:</span>{this.state.equipment.name}</Col>
            </Row>
            <Row>

                <Col span={10} style={styleItem}><span style={styleSpan}>设备过保时间:</span>{this.state.equipment.warrantyPeriod} </Col>
                <Col span={14} style={styleItem}><span style={styleSpan}>设备安装时间:</span> {this.state.equipment.installTime}</Col>

            </Row>
            <Row>
                <Col span={10} style={styleItem}><span style={styleSpan}>客户:</span>{this.state.customer.customerName}</Col>
                <Col span={14} style={styleItem}><span style={styleSpan}>客户单位:</span>{this.state.customerDepartment.departmentName}</Col>
            </Row>
            <Row>

                <Col span={24} style={styleItem}><span style={styleSpan}>描述信息:</span>{this.state.equipment.description}</Col>
                <Col span={24} style={styleItem}><span style={styleSpan}>楼号楼层及门牌号:</span>{this.state.equipment.storey}</Col>
                <Col span={24} style={styleItem}><span style={styleSpan}>地址信息:</span>
                {this.state.equipment.province} - { this.state.equipment.city} - { this.state.equipment.county} - { this.state.equipment.address}
                </Col>
            </Row>
            <Row>
                <Col span={24} style={styleItem}>
                    {this.state.Msg !== "" ? <span style={styleSpanError}>此设备已经有工单正在进行维护，无法再关联此设备，请跳过关联步骤或联系管理员处理正在进行中的工单</span> : ""}
                </Col>
            </Row>
            <Row gutter={8} justify="end">
                <Col>
                    <Space>
                        <Button disabled={this.state.loading} onClick={this.close}>取消</Button>
                        <Button loading={this.state.loading} disabled={this.state.Msg !== ""} type="primary" onClick={this.handle}>确定</Button>
                    </Space>
                </Col>
            </Row>
        </DraggableModal>;
    }
}
