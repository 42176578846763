import React from "react";
import {Button, Col, Row, Space} from "antd";

import WorkorderApi from "api/workorder";
import {ProForm, ProFormTextArea} from "@ant-design/pro-components";
import {setFieldsError} from "plugin/formily";
import DraggableModal from "component/DraggableModal";

/**
 * 驳回工单弹窗 Modal
 *
 * @description 驳回工单
 *
 * @author huang Carder
 * @since 2021-06-23
 * @version 1.0.0
 */
export default class DeniedWorkorderDialog extends React.Component {

    state = {
        loading: false,
        show: false
    };

    formRef = React.createRef();
    draggableRef = React.createRef();


    show = () => this.draggableRef.current.show();

    close = () => {
        this.setState({loading: false});
        this.draggableRef.current.close();
    };


    /**
     * 控制 loading 加载效果显示
     */
    toggleLoading = () => this.setState({loading: !this.state.loading});

    /**
     * 驳回工单
     * @param data 表单数据
     */
    handle = data => {
        this.toggleLoading();
        data.workorderId = this.props.workorderId;
        WorkorderApi.denied(data).then(response => {
            this.toggleLoading();
            this.close();
            if (!!this.props.onSuccess) {
                this.props.onSuccess();
            }
        }).catch(error => {
            this.toggleLoading();
            setFieldsError(error,this.formRef);
            console.warn("Denied workorder failed", error);
        });
    };

    /**
     * 创建驳回工单按钮
     */
    trigger = () => <Button danger onClick={this.show}>驳回</Button>;

    dialog = () => {
        return <DraggableModal onCancel={this.close} title="驳回工单" ref={this.draggableRef}>
            <ProForm formRef={this.formRef} onFinish={this.handle} layout={"vertical"} submitter={{
                resetButtonProps: {style: {display: "none"}},
                submitButtonProps: {style: {display: "none"}}
            }}>
                <ProFormTextArea name="result" label="驳回原因" placeholder="请填写驳回原因"/>
                <Row gutter={8} justify="end">
                    <Col>
                        <Space>
                            <Button disabled={this.state.loading} onClick={this.close}>取消</Button>
                            <Button loading={this.state.loading} type="primary" htmlType="submit">驳回</Button>
                        </Space>
                    </Col>
                </Row>
            </ProForm>
        </DraggableModal>;
    };

    render() {
        return <>
            {this.trigger()}
            {this.dialog()}
        </>;
    }
}
