import React, {Component} from "react";
import {Button, Space, Tooltip} from "antd";
import {DeleteOutlined, EditOutlined, LogoutOutlined} from "@ant-design/icons";
import {PageContainer, ProTable} from "@ant-design/pro-components";

import SecurityInterceptor from "component/SecurityInterceptor";
import AssignedWorkorderApi from "api/workorder/assigned";

import UpdateSmpUnassignedWorkorderDrawer from "./drawer/UpdateSmpUnassignedWorkorderDrawer";
import UpdateOpsUnassignedWorkorderDrawer from "./drawer/UpdateOpsUnassignedWorkorderDrawer";

import DispatchUnassignedWorkorderModal from "./modal/DispatchUnassignedWorkorderModal";
import DeleteUnassignedWorkorderModal from "./modal/DeleteUnassignedWorkorderModal";

/**
 * 待指派工单页面
 */
class UnassignedWorkorderPage extends Component {

    actionRef;
    deleteAssignedRef = React.createRef();
    dispatchWorkorderRef = React.createRef();
    editWorkorderRef = React.createRef();
    editSmpWorkorderRef = React.createRef();
    createSmpWorkorderRef = React.createRef();
    createWorkorderRef = React.createRef();

    renderColumnOperations = (node, entity, index, action) => {
        return <SecurityInterceptor role={["admin", "customer-service"]}>
            <Space>
                <Tooltip title="指派">
                    <Button icon size="small" onClick={() => this.dispatchWorkorderRef.current.show(entity.id, entity.clientDepartmentId)}>
                        <LogoutOutlined/>
                    </Button>
                </Tooltip>
                <Tooltip title="编辑">
                    <Button icon size="small" onClick={() => {
                        entity.logicType === "smp" ? this.editSmpWorkorderRef.current.show(entity.id) :
                            this.editWorkorderRef.current.show(entity.id);
                    }}>
                        <EditOutlined/>
                    </Button>
                </Tooltip>
                <Tooltip title="删除">
                    <Button danger icon size="small" onClick={() => this.deleteAssignedRef.current.show(entity.id)}>
                        <DeleteOutlined/>
                    </Button>
                </Tooltip>
            </Space>
        </SecurityInterceptor>;
    };

    columns = [
        {
            title: "ID",
            dataIndex: "id",
            copyable: true,
            editable: false
        },
        {
            title: "创建时间",
            dataIndex: "createTime",
            hideInSearch: true
        },
        {
            title: "设备ID",
            dataIndex: "resourceId",
            hideInTable: true
        },
        {
            title: "客户姓名",
            dataIndex: "clientName",
            hideInSearch: true
        },
        {
            title: "客户手机",
            dataIndex: "clientCellphone"
        },
        {
            title: "客户单位",
            dataIndex: "clientDepartmentName",
            hideInSearch: true
        },
        {
            title: "设备名称",
            dataIndex: "resourceName",
            hideInSearch: true
        },
        {
            title: "设备型号",
            dataIndex: "resourceType",
            hideInSearch: true
        },
        {
            title: "预约时间",
            dataIndex: "appointmentTime",
            hideInSearch: true
        },
        {
            title: "工单类型",
            dataIndex: "logicType",
            valueEnum: require("../assets/WorkorderLogicType").default
        },
        {
            title: "操作",
            valueType: "option",
            render: (node, entity, index, action) => this.renderColumnOperations(node, entity, index, action)
        }
    ];

    requestPage = params => {
        params.converted = 0;
        return AssignedWorkorderApi.page(params).then(res => {
            if (!!res.data) {
                return {data: res.data.records, success: true, total: res.data.total};
            }
            return {data: [], success: true, total: 0};
        }).catch(err => {
            console.error("request page data fail", err);
            return {data: [], success: true, total: 0};
        });
    };

    reload = () => {
        this.actionRef?.reload();
    };

    render() {
        return <PageContainer header={{title: "待指派工单", breadcrumb: {}}}>
            <ProTable
                options={{reload: true, setting: true, density: true}}
                defaultSize="small"
                rowKey="id"
                onColumnsStateChange={this.onColumnStateMapChange}
                actionRef={actionRef => this.actionRef = actionRef}
                columns={this.columns}
                request={this.requestPage}/>
            <UpdateOpsUnassignedWorkorderDrawer onSuccess={this.reload} ref={this.editWorkorderRef}/>
            <UpdateSmpUnassignedWorkorderDrawer onSuccess={this.reload} ref={this.editSmpWorkorderRef}/>
            <DeleteUnassignedWorkorderModal onSuccess={this.reload} ref={this.deleteAssignedRef}/>
            <DispatchUnassignedWorkorderModal onSuccess={this.reload} ref={this.dispatchWorkorderRef}/>
        </PageContainer>;
    }
}

export default UnassignedWorkorderPage;
