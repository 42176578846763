import React from "react";
import {PageContainer, ProTable} from "@ant-design/pro-components";
import {Button, message, Space, Tooltip} from "antd";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";

import CreateRoleModal from "./modal/CreateRoleModal";
import DeleteRoleModal from "./modal/DeleteRoleModal";
import RoleApi from "api/user/role";
import {isAdmin} from "../../../../component/SecurityInterceptor";

export default class Roles extends React.Component {
    actionRef = null;
    setRef = React.createRef();
    deleteRef = React.createRef();
    columns = [
        {
            title: "ID",
            hideInTable: true,
            hideInSearch: true,
            dataIndex: "id",
            editable: false
        },
        {
            title: "角色名称",
            dataIndex: "name",
            width: "120px"
        },
        {
            title: "角色标识符",
            copyable: true,
            ellipsis: true,
            dataIndex: "identifier",
            editable: false,
            width: "220px"
        },
        {
            title: "角色说明",
            dataIndex: "description",
            search: false
        },
        {
            title: "操作",
            valueType: "option",
            width: "200px",
            align: "center",
            render: (node, entity, index, action) => this.renderColumnOperations(node, entity, index, action)
        }
    ];

    rowEditConfig = {
        type: "single",
        onDelete: (key, row) => RoleApi.delete(row.id).then(() => {
            return true;
        }).catch(error => {
            console.warn("delete role failed", error);
            message.warn("删除失败：" + error.data).then();
            this.reload();
            return false;
        }),
        onSave: (key, row) => {
            console.log(row)
            if (row.description.trim() === "") {
                message.error("角色描述不能为空!");
                this.reload();
                return false;
            }
            if (row.name.trim() === "") {
                message.error("角色名称不能为空!");
                this.reload();
                return false;
            }
            return RoleApi.edit(row).then(() => {
                return true;
            }).catch(error => {
                console.warn("edit role failed", error);
                message.warn("编辑失败：" + error.data).then();
                this.reload();
                return false;
            });
        }
    };

    renderColumnOperations = (node, entity, index, action) => {
        return isAdmin() ? (<Space>
            <Tooltip title="编辑">
                <Button icon size="small"
                        onClick={() => action?.startEditable?.(entity.id)}><EditOutlined/></Button>
            </Tooltip>
            <Tooltip title="删除">
                <Button icon size="small" danger
                        onClick={() => this.dialog(entity.id, this.deleteRef)}><DeleteOutlined/></Button>
            </Tooltip>
        </Space>) : undefined
    }

    /**
     *控制弹窗
     */
    dialog = (id, ref) => {
        ref.current.show(id);
    };

    renderToolbar = () => <CreateRoleModal key="create" onSuccess={this.reload}/>;

    requestPage = params => {
        return RoleApi.page(params).then(response => {
            this.actionRef.clearSelected();
            return {data: response.data.records, success: true, total: response.data.total};
        }).catch(error => {
            console.warn("Request role pages failed", error);
            return {data: [], success: true, total: 0};
        });
    };

    reload = () => {
        this.actionRef?.reload();
    };

    render() {
        return <PageContainer header={{title: "角色管理", breadcrumb: {}}} extra={this.renderToolbar()}>
            <ProTable
                actionRef={actionRef => this.actionRef = actionRef}
                columns={this.columns}
                editable={this.rowEditConfig}
                options={{reload: true, setting: true, density: true}}
                defaultSize={"small"}
                bordered
                rowKey="id"
                request={this.requestPage}/>

            <DeleteRoleModal key="delete" onSuccess={this.reload} ref={this.deleteRef}/>
        </PageContainer>;
    }
}
