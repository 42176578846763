const HistorApi = {
    /**
     * 分页查询
     * @returns {Promise<AxiosResponse<any>>}
     */
    page: params => global.axios.get("resource/ahri/history/page", {params}),
    /**
     * 关联工单
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    associatedWorkorder: data => global.axios.post(`/resource/ahri/history`, data),
    /**
     * 退出远程连接
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    patchUpdate: id => global.axios.patch(`/resource/ahri/history/${id}`),
    /**
     * 删除
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    delete: id => global.axios.delete(`/resource/ahri/history/${id}`),

    /**
     * 远程连接全部历史
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    findAll: id => global.axios.get(`/resource/ahri/history/${id}`),
    /**
     * 查询历史详情
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    find: id => global.axios.get(`/resource/ahri/history/detail/${id}`),
    /**
     * 根据设备sn查询设备信息
     * @param sn
     * @returns {Promise<AxiosResponse<any>>}
     */
    findBySn: sn => global.axios.get(`/resource/ahri/ahri/detail/sn/${sn}`),
    /**
     * 根据设备远程视频列表
     */
    findVideo: params => global.axios.get(`/resource/sso/list`, {params})

}

export default HistorApi