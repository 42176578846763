import Join from "./components/Join";
import Preview from "./components/Preview";
import styles from './index.module.less';
import {memo, useCallback, useEffect, useState} from "react";
import { Row, Typography, Col, Button } from "dingtalk-design-desktop";
import {generateRandomString, urlParamsToObj} from "../../../../../utils/tool";
import {getUser} from "../../../../../utils/securityCheck";

const { Title } = Typography;

const Index = () => {
  const [testing, setTesting] = useState(false);
  const [watch, setWatch] = useState(false);
  const [ticktok, setTicktok] = useState(15);
  const onTestNetwork = useCallback(() => {
    setTesting(true);
    setTicktok(15);
    const interval = setInterval(() => {
      setTicktok((prev) => Math.max(0, prev - 1))
    }, 1000)
  }, [])

  /**
   * 判断缓存 localStorage 是否有watch 定时一次次
   */
  useEffect(
      () => {
        // 获取地址栏中的参数
        const params = urlParamsToObj(window.location.href);
        console.log('---------------------------')
        console.log(params)
        if (params.watch){
          setWatch(true)
        }
      },
      []
  )
  return (
      <Row className={styles.wrap}>
        {watch ?
            <></> : <Col className={styles.main}>
              <Preview />
              <Join />
            </Col>
        }
      </Row>
  )
}

export default memo(Index);