import React, {Component} from 'react';
import {PageContainer, ProTable} from "@ant-design/pro-components";
import {Button, Tooltip} from "antd";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import DictApi from "../../../api/dict";
import {buildTree} from "../../../utils/tool";
import CreateDictModal from "./modal/CreateDictModal";
import DeleteDictModal from "./modal/DeleteDictModal";
import EditDictModal from "./modal/EditDictModal";

class Dict extends Component {

    createDictRef = React.createRef();
    editDictRef = React.createRef();
    deleteDictRef = React.createRef();

    columns = [
        {
            title: "字典标识",
            dataIndex: "code",
            copyable: true,
            hideInSearch: true,
        },
        {
            title: "短名",
            dataIndex: "enKey",
            copyable: true,
            hideInSearch: true,
        },
        {
            title: "字典值",
            dataIndex: "value",
            hideInSearch: true,
        },
        {
            title: "字典描述",
            dataIndex: "description",
            hideInSearch: true,
        },
        // 创建时间
        {
            title: "创建时间",
            dataIndex: "createTime",
            hideInSearch: true,
        },
        // 修改时间
        {
            title: "修改时间",
            dataIndex: "modifyTime",
            hideInSearch: true,
        },
        {
            title: "操作",
            valueType: "option",
            render: (node, entity, index, action) => this.renderColumnOperations(node, entity, index, action)
        }
    ]

    renderColumnOperations = (node, entity) =>
        [
            <Tooltip title="编辑" key="edit">
                <Button icon size="small" onClick={() => this.editDictRef.current.show(entity.id)}>
                    <EditOutlined/>
                </Button>
            </Tooltip>,
            <Tooltip title="删除" key="delete">
                <Button icon size="small" danger onClick={() => this.deleteDictRef.current.show(entity.id)}>
                    <DeleteOutlined/>
                </Button>
            </Tooltip>,

        ];

    requestPage = params => {
        return DictApi.list().then(response => {
            if (!!response.data) {
                let data = buildTree(response.data, "0")
               return {data: data, success: true};
            }
            return {data: [], success: true, total: 0};
        }).catch(error => {
            console.log("Find worker page failed", error);
            return {data: [], success: true, total: 0};
        });
    };

    reload = () => {
        this.actionRef?.reload();
    }
    render() {
        return (
            <PageContainer header={{title: "字典管理", breadcrumb: {}}}>
                <ProTable rowKey="id"
                          defaultSize="small"
                          search={false}
                          toolBarRender={() => [
                              <Button type="primary" onClick={() => this.createDictRef.current.show()}>新增</Button>,
                          ]}
                          options={{reload: true, setting: true, density: true}}
                          actionRef={actionRef => this.actionRef = actionRef}
                          columns={this.columns}
                          request={this.requestPage}/>
                <CreateDictModal ref={this.createDictRef} onSuccess={this.reload}/>
                <EditDictModal ref={this.editDictRef} onSuccess={this.reload}/>
                <DeleteDictModal ref={this.deleteDictRef} onSuccess={this.reload}/>
            </PageContainer>
        );
    }
}

export default Dict;