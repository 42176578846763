const StorehouseApi = {

    /**
     * 分页查询仓库列表
     * @param params 查询参数
     * @returns {Promise<AxiosResponse<any>>}
     */
    page: (params) => global.axios.get("/repository/repositories/page", {params}),

    /**
     * 创建仓库
     * @param data  创建数据
     * @returns {Promise<AxiosResponse<any>>}
     */
    create: (data) => global.axios.post("/repository/repository", data),

    /**
     * 根据id查询仓库信息
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    find: id => global.axios.get(`/repository/repository/${id}`),

    /**
     * 更新仓库
     * @param data  更新数据
     * @returns {Promise<AxiosResponse<any>>}
     */
    update: data => global.axios.patch("/repository/repository", data),

    /**
     * 删除仓库
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    delete: id => global.axios.delete(`/repository/repository/${id}`),

    /**
     * 查询指定单位下的所有仓库
     * @param id 单位id
     * @returns {Promise<AxiosResponse<any>>}
     */
    findAll: params => global.axios.get(`/repository/repositories`,{params}),

    /**
     * 查询当前仓库下的所有管理员
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    findRepositoryManager: id => global.axios.get(`/repository/repository/${id}/repository-managers`),

    /**
     * 设置仓库管理员
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    setStorehouseAdmin: data => global.axios.put("/repository/repository/repository-managers", data),

    /**
     * 查询仓库下的所有工程师
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    findStorehouseEngineers: id => global.axios.get(`/repository/repository/${id}/engineers`),

    /**
     * 设置仓库工程师
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    setStorehouseEngineers: data => global.axios.put("/repository/repository/repository-engineers", data)
};

export default StorehouseApi;
