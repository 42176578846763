import React from "react";
import {Form, TreeSelect} from "antd";
import CustomerDepartmentApi from "api/customer/department";
import {getUser} from "../../SecurityInterceptor";
import DepartmentApi from "../../../api/user/department";

// noinspection JSUnresolvedVariable
export default class CustomerDepartmentPicker extends React.Component {

    state = {
        loading: true,
        treeData: []
    };

    componentDidMount() {
        this.reload();
    }

    reload = () => {
        const user = getUser();
        if (!user) {
            this.toggleLoading();
            return;
        }
        let isAdmin = false;
        let customerDepartmentIds = [];
        if (!!user.roles) {
            isAdmin = user.roles.includes("admin");
        }
        if (!user.departments && !isAdmin) {
            this.toggleLoading();
            return;
        }
        if (!!user.departments && !isAdmin) {
            for (let department of user.departments) {
                for (let role of department.roles) {
                    if (role.identifier === "admin") {
                        isAdmin = isAdmin || true;
                        break;
                    }
                }
                if (!!department.customerDepartmentIds) {
                    customerDepartmentIds = department.customerDepartmentIds
                }
            }
        }
        if (isAdmin) {
            this.requestCustomerDepartment();
        } else {
            if (customerDepartmentIds.length < 1) {
                this.toggleLoading();
                return;
            }
            // this.requestUserCustomerDepartment();
            this.requestCustomerDepartmentByUserDepartment();
        }
    };

    toggleLoading = () => this.setState({loading: !this.state.loading});

    requestCustomerDepartment = () => {
        CustomerDepartmentApi.tree(getUser().departments[0].id).then(response => {
            this.setState({treeData: this.processCustomerDepartmentTreeData(response.data), loading: false});
        }).catch(error => {
            this.toggleLoading();
            console.warn("Request customer department tree failed.", error);
        });
    };

    /**
     * 查询机构下的客户单位
     */
    requestCustomerDepartmentByUserDepartment = () => {
        DepartmentApi.requestDepartmentCustomerDepartment(getUser().departments[0].id).then(res => {
            this.setState({treeData: this.processCustomerDepartmentTreeData(res.data), loading: false});
        }).catch(err => {
            console.warn("获取机构单位失败", err)
        })
    }

    processCustomerDepartmentTreeData = tree => tree?.map(item => ({
        ...item,
        key: item.id,
        title: item.name,
        value: item.id,
        children: !!item.children ? this.processCustomerDepartmentTreeData(item.children) : undefined
    }));

    render() {
        return this.props.renderOnlyTree ? <TreeSelect treeData={this.state.treeData}
                                                       loading={this.state.loading}
                                                       showSearch
                                                       allowClear
                                                       treeNodeFilterProp="title"
                                                       onChange={this.props.onChange}/> :
            <Form.Item name={this.props.name ? this.props.name : "clientDepartment"}
                       label={this.props.label ? this.props.label : "客户单位"}
                       rules={this.props.rules}>
                <TreeSelect treeData={this.state.treeData}
                            loading={this.state.loading}
                            showSearch
                            allowClear
                            treeNodeFilterProp="title"
                            onChange={this.props.onChange}/>
            </Form.Item>;
    }
}
