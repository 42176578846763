import React, {useEffect, useState} from "react";
import style from "./index.module.css";
import workorderInfoApi from "api/workorderInfo";
import {Spin} from "antd";
import {isAdmin} from "../../../../../component/SecurityInterceptor";
import {useNavigate} from "react-router-dom";

/**
 * 服务概览
 * @description 已接入客户数量统计
 */
const CustomerStatistic = () => {

    const [loading, setLoading] = useState(false);
    const [customerNum, setCustomerNum] = useState("");

    const navigate = useNavigate()

    useEffect(() => {
        getCustomerNumber();
    }, [])

    const getCustomerNumber = () => {
        setLoading(true)
        workorderInfoApi.customerNumber().then(response => {
            setLoading(false)
            setCustomerNum(response.data)
        }).catch(error => {
            console.log("Get customer number filed", error);
            setLoading(false)
            setCustomerNum("-")
        });
    };

    return (<div className={style.systemDataItem} onClick={() => {
        if (isAdmin()) {
            navigate("/home/customers")
        }
    }}>
        <p>已接入客户数</p>
        {loading ? <Spin/> : <p>{customerNum}</p>}
    </div>);

}

export default CustomerStatistic;
