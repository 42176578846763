import React from "react";
import {Drawer} from "antd";
import {ProDescriptions} from "@ant-design/pro-components";


import ContractApi from "api/contract/index";

/**
 * 查看合同
 * 根据合同id 获取对应的合同数据
 * 把数据用 ProDescriptions 渲染
 */
export default class PreviewContractDrawer extends React.Component<> {

    state = {
        drawerWidth: 280,
        show: false,
        loading: false,
        contract: {
            clientCellphone: "",
            clientName: "",
            comment: "",
            expireDate: "",
            name: "",
            salesmanCellphone: "",
            salesmanName: "",
            serviceContent: "",
            startDate: "",
            file: []
        },
        resourceId: "",
        url: ""
    };

    onChange = false;


    show = (id) => {
        this.setState({
            show: true,
            resourceId: id,
            drawerWidth: window.innerWidth < 576 ? window.innerWidth : window.innerWidth * 0.55
        }, () => {
            this.requestWorkorder();
        });
    };

    close = () => {
        this.setState({show: false});
    };

    toggleLoading = () => {
        this.setState({loading: !this.state.loading});
    };

    requestWorkorder = () => {
        this.toggleLoading();
        ContractApi.getContract(this.state.resourceId).then(response => {
            let files = [];
            let params = {
                clientCellphone: "",
                clientName: "",
                comment: response.data.comment,
                expireDate: "",
                name: response.data.name,
                salesmanCellphone: "",
                salesmanName: "",
                serviceContent: "",
                startDate: "",
                file: files
            };
            response.data.infos.forEach(item => {
                switch (item.name) {
                    case "服务范围":
                        params.serviceContent = item.value;
                        break;
                    case "合同开始时间":
                        params.startDate = item.value;
                        break;
                    case "合同到期时间":
                        params.expireDate = item.value;
                        break;
                    case "客户方联系人":
                        params.clientName = item.value;
                        break;
                    case "客户方电话":
                        params.clientCellphone = item.value;
                        break;
                    case "市场方负责人":
                        params.salesmanName = item.value;
                        break;
                    case "市场方电话":
                        params.salesmanCellphone = item.value;
                        break;
                    case "合同附件":
                        files.push({
                            uid: item.id,
                            name: "合同附件",
                            url: item.value,
                            type: item.type
                        });
                        this.setState({
                            url: item.value
                        });
                        break;
                    default:
                        break;
                }
            });
            this.setState({contract: params, loading: false});
        }).catch(error => {
            this.toggleLoading();
            console.warn("Get workorder failed", error);
        });
    };

    render() {
        return <Drawer title="查看合同" width={this.state.drawerWidth} open={this.state.show}
                       closable={!this.state.loading}
                       onClose={this.close}>

            <ProDescriptions bordered size="small" layout="horizontal"
                             column={window.innerWidth < 800 ? 1 : 4}
                             title={`合同编号：${this.state.contract.comment}`}
                             dataSource={this.state.contract}
                             loading={this.state.loading}>

                <ProDescriptions.Item label="合同名称" span={4}>
                    {this.state.contract.name}
                </ProDescriptions.Item>

                <ProDescriptions.Item label="服务范围" span={4}>
                    {this.state.contract.serviceContent}
                </ProDescriptions.Item>

                <ProDescriptions.Item label="合同开始时间" span={2}>
                    {this.state.contract.startDate}
                </ProDescriptions.Item>

                <ProDescriptions.Item label="合同结束时间" span={2}>
                    {this.state.contract.expireDate}
                </ProDescriptions.Item>

                <ProDescriptions.Item label="客户方联系人" span={2}>
                    {this.state.contract.clientName}
                </ProDescriptions.Item>

                <ProDescriptions.Item label="客户方电话" span={2}>
                    {this.state.contract.clientCellphone}
                </ProDescriptions.Item>

                <ProDescriptions.Item label="市场方负责人" span={2}>
                    {this.state.contract.salesmanName}
                </ProDescriptions.Item>

                <ProDescriptions.Item label="市场方电话" span={2}>
                    {this.state.contract.salesmanCellphone}
                </ProDescriptions.Item>

                <ProDescriptions.Item label="合同附件" span={4}>
                    {this.state.url ? <a href={this.state.url} target="_blank">合同附件</a> : ""}
                </ProDescriptions.Item>

            </ProDescriptions>
        </Drawer>;
    }
}
