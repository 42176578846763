import React, {Component} from "react";
import {Spin} from "antd";

import workorderInfoApi from "api/workorderInfo";
import {isAdmin} from "component/SecurityInterceptor";
import {withNavigation} from "utils/RouteLink";

import style from "./index.module.css";

/**
 * 服务概览
 * @description 待接收工单数量统计
 */
class ReceiveStatistic extends Component {
    state = {
        loading: false,
        overNum: ""
    };

    componentDidMount() {
        this.getOverNumber();
    };


    getOverNumber = () => {
        this.setState({
            loading: true
        });
        workorderInfoApi.workorderNumber("5").then(response => {
            this.setState({
                overNum: response.data,
                loading: false
            });
        }).catch(error => {
            console.log("Get over number filed", error);
            this.setState({
                overNum: "-",
                loading: false
            });
        });
    };

    render() {
        return (
            <div className={style.workorderInfoItem}
                 onClick={() => isAdmin() ? this.props.navigate("/home/workorders/all-workorder", {state: {progress: "5"}}) : null}>
                <p>待接收</p>
                {this.state.loading ? <Spin/> : <p>{this.state.overNum}</p>}
            </div>
        );
    }
}

export default withNavigation(ReceiveStatistic);
