import React from "react";
import moment from "moment";
import {Button, Col, Row, Space} from "antd";
import {DownOutlined as DownOutlinedIcon, UpOutlined as UpOutlinedIcon} from "@ant-design/icons";
import WorkorderApi from "api/workorder";
import {ProForm, ProFormDatePicker} from "@ant-design/pro-components";
import DraggableModal from "component/DraggableModal";

/**
 * 接收工单弹窗 Modal
 *
 * @description 接收工单
 *
 * @author huang Carder
 * @since 2021-06-23
 * @version 1.0.0
 */
export default class TeamMemberReceiveModal extends React.Component {

    state = {
        loading: false,
        show: false,
        workorderId: null,
        deadline: null,
        modificationFrom: false
    };

    draggableRef = React.createRef();
    formRef = React.createRef();

    /**
     * 控制驳回工单对话框显示隐藏
     */

    show = (data) => {
        console.log(data)
        this.setState({workorderId: data.id, deadline: data.deadLine,}, () => {
            this.setState({ deadline: data.deadLine })
        });
        this.draggableRef.current.show();
    }

    close = () => {
        this.setState({loading: false});
        this.draggableRef.current.close();
    };

    /**
     * 控制 loading加载效果 显示
     */
    toggleLoading = () => this.setState({loading: !this.state.loading});

    /**
     * 控制修改计划时间表单显示
     */
    alterTime = (e) => {
        e.preventDefault();
        this.setState({
            modificationFrom: !this.state.modificationFrom
        });
    };

    /**
     * 接收工单
     * @param data 表单数据
     */
    handle = data => {
        this.toggleLoading();
        let { appointmentTime,deadline } = data;
        WorkorderApi.teamMemberReceive({
            appointmentTime: moment(appointmentTime).format("YYYY-MM-DD HH:mm"),
            deadline: deadline ? moment(deadline).format("YYYY-MM-DD HH:mm") : moment(this.state.deadline).format("YYYY-MM-DD HH:mm"),
            workorderId: this.state.workorderId
        }).then(res => {
            this.close();
            this.props.onSuccess();
        });
    };

    disabledDate(current) {
        return current && current < moment().subtract(1, "days");
    }

    dialog = () => {
        return <DraggableModal onCancel={this.close} title="接收工单" ref={this.draggableRef}>
            <ProForm formRef={this.formRef} onFinish={this.handle} layout={"vertical"} submitter={false}>
                <ProFormDatePicker name="appointmentTime" label="预约时间"
                                   fieldProps={{
                                       showTime: true,
                                       inputReadOnly: true,
                                       format: "YYYY-MM-DD HH:mm",
                                       disabledDate: this.disabledDate
                                   }}
                                   required
                                   rules={[{required: true, message: "请选择预约时间"}]}/>
                {/*<ProFormDatePicker name="deadline" label="计划时间"*/}
                {/*                   fieldProps={{*/}
                {/*                       showTime: true,*/}
                {/*                       inputReadOnly: true,*/}
                {/*                       format: "YYYY-MM-DD HH:mm",*/}
                {/*                       disabledDate: this.disabledDate*/}
                {/*                   }}*/}
                {/*                   rules={[{message: "请选择计划时间"}]}/>*/}

                <span>计划时间：</span>{this.state.deadline}
                <br/>
                <p onClick={this.alterTime}>
                    如需修改请点击
                    {
                        this.state.modificationFrom ? <UpOutlinedIcon/> : <DownOutlinedIcon/>
                    }
                </p>
                {
                    this.state.modificationFrom ? <ProFormDatePicker name="deadline" label="修改计划时间"
                                                                     fieldProps={{
                                                                         showTime: true,
                                                                         disabledDate: this.disabledDate,
                                                                         inputReadOnly: true,
                                                                         format: "YYYY-MM-DD HH:mm"
                                                                     }}/> : ""
                }

                <Row gutter={8} justify="end">
                    <Col>
                        <Space>
                            <Button disabled={this.state.loading} onClick={this.close}>取消</Button>
                            <Button loading={this.state.loading} type="primary" htmlType="submit">接收</Button>
                        </Space>
                    </Col>
                </Row>
            </ProForm>
        </DraggableModal>;
    };

    render() {
        return <>
            {this.dialog()}
        </>;
    }
}
