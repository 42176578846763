import { Button, Row, Space, Col } from 'dingtalk-design-desktop';
import {memo, useCallback, useEffect, useRef, useState} from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { client, constantConfig, currentUserInfo, globalFlag, localChannelInfo } from '../../../../../../store';
import styles from '../index.module.less';
import { Camera, Mic, Screen } from '../../../../../../component/Device';
import Icon from '../../../../../../component/Icon';
import Settings from './Settings';
import classNames from 'classnames';
import {useDevice} from "../../../../../../hooks/device";
import HistorApi from "../../../../../../api/remote/HistoryApi";
import {SendOutlined} from "dd-icons";
import {alertErrorMessage, objToUrlParams, urlParamsToObj} from "../../../../../../utils/tool";
import {message} from "antd";
import {useNavigate} from "react-router-dom";
import ClipboardModal from "../../../modal/ClipboardModal";
import RemoteApi from "../../../../../../api/remote/RemoteApi";

const Index = (props) => {
  const { onLeave: _onLeave } = props;
  const { screenTrack } = useRecoilValue(localChannelInfo);
  const [{ hideToolBar }, setGlobalFlag] = useRecoilState(globalFlag);
  const rtcClient = useRecoilValue(client);
  const [showSetting, setShowSetting] = useState(false);
  const [recordId, setRecordId] = useState('');
  const { isMobile } = useRecoilValue(constantConfig);
  const [showWatch, setShowWatch] = useState(true);
  const { channel, appId } = useRecoilValue(currentUserInfo);
  const { operateCamera, operateMic, operateScreen, cameraEnabled, micEnabled } = useDevice('in');
  const clipboardModalRef = useRef(null);
  const onLeave = useCallback(() => {
    _onLeave();
    rtcClient.leave();
    let id = localStorage.getItem('recordId')
    setRecordId(id)
    console.log('id',id)
    console.log('recordId',recordId)
    if(id){
      HistorApi.patchUpdate(id).then( res=> {
        localStorage.removeItem('recordId')
        localStorage.removeItem('onLeave')
      } )
    }
    navigate("/home/remote/list")
  }, [rtcClient, _onLeave]);

  useEffect(() => {
    let timer = null;
    const hideAfterStill = () => {
      if (timer) clearTimeout(timer);
      setGlobalFlag((prev) => ({ ...prev, hideToolBar: false }));
      timer = window.setTimeout(() => {
        setGlobalFlag((prev) => ({ ...prev, hideToolBar: true }));
      }, 10000);
    }
    document.addEventListener('mousemove', hideAfterStill)

    // 获取地址栏中的参数
    const params = urlParamsToObj(window.location.href);
    if (!params.watch){
      operateScreen() // 地址栏不存在watch时候自动屏幕共享
      startCloudRecord() // 开始云端录制
      setShowWatch(false)
    }

    return () => {
      document.removeEventListener('mousemove', hideAfterStill)
    }
  }, []);

  const toggleShowSettings = useCallback(() => {
    setShowSetting((prev) => !prev)
  }, [])

  //设置黏贴板
  const setClipboardText = () => {
    let params = objToUrlParams({
      channel,
      watch:1
    })
    let origin = window.location.origin
    let pathname = window.location.pathname
    let clipboardUrl = origin + pathname + '?' + params
    if (!navigator.clipboard) {
      alertErrorMessage('当前浏览器不支持复制 请手动复制')
      if (clipboardModalRef.current) {
        clipboardModalRef.current.show(clipboardUrl);
      }
      return
    }

    navigator.clipboard.writeText(clipboardUrl)
    console.log('复制地址',clipboardUrl)
    message.success('复制成功')
  }

  /**
   * 开始晕倒录制
   */
  const startCloudRecord = () => {
    let recordId = localStorage.getItem('recordId')
    RemoteApi.startRecordCloud({appId,channelId:channel,recordId}).then(res => {
      console.log('start cloud record success',res)
    }).catch(err => {
      console.error('start cloud record failed',err)
    })
  }

  const navigate = useNavigate()
  return (
      <Row className={classNames(styles.toolBarWrap, hideToolBar ? styles.hideToolBar : '')}>
        <Row className={styles.confInfo} gutter={16}>
          <Col>
            <span>应用: </span>
            <span>{appId}</span>
          </Col>
          <Col>
            <span>频道: </span>
            <span>{channel}</span>
          </Col>
        </Row>
        <Space size={[0, 8]}>
          <Row className={styles.toolBtn} onClick={operateCamera}>
            <Camera />
            <span>{cameraEnabled ? '关摄像头' : '开摄像头'}</span>
          </Row>
          <Row className={styles.toolBtn} onClick={operateMic}>
            <Mic />
            <span>{micEnabled ? '静音' : '解除静音'}</span>
          </Row>
          {showWatch ? null : (
              <Row
                  onClick={operateScreen}
                  className={`${styles.toolBtn} ${screenTrack ? styles.stopShare : ''}`}
              >
                <Screen />
                <span>{screenTrack ? '结束共享' : '共享'}</span>
              </Row>
          )}
          {/*<Row className={styles.toolBtn} onClick={toggleShowSettings}>*/}
          {/*  <Icon type='iconicon_x_Settings' />*/}
          {/*  <span style={{ marginTop: 4 }}>设置</span>*/}
          {/*</Row>*/}
          <Row className={styles.toolBtn} onClick={setClipboardText}>
            <SendOutlined />
            <span style={{ marginTop: 4 }}>复制分享链接</span>
          </Row>
        </Space>
        <Button type="danger" onClick={onLeave}>
          离开
        </Button>
        {showSetting ? <Settings onClose={toggleShowSettings} /> : null}
        <ClipboardModal ref={clipboardModalRef}/>
      </Row>
  );
};

export default memo(Index);
