import React from "react";
import {Button, Col, Row, Space, Typography} from "antd";

import CustomerApi from "api/customer/customer";
import DraggableModal from "component/DraggableModal";

/**
 * 删除客户弹窗 Modal
 *
 * @description 删除客户
 *
 * @author huang Carder
 * @since 2021-06-23
 * @version 1.0.0
 */
export default class DeleteCustomerDialog extends React.Component {

    state = {
        loading: false,
        show: false,
        items: []
    };

    draggableRef = React.createRef();

    show = id => {
        this.draggableRef.current.show();
        this.setState({items: id});
    };

    close = () => {
        this.setState({loading: false});
        this.draggableRef.current.close();
    };

    /**
     * 控制 loading 加载效果显示隐藏
     */
    toggleLoading = () => this.setState({loading: !this.state.loading});

    /**
     * 删除客户
     */
    handle = () => {
        if (this.state.items === null || this.state.items === undefined || this.state.items.length < 1) {
            return;
        }
        this.toggleLoading();
        const request = this.state.items.length === 1 ? CustomerApi.delete(this.state.items[0]) : CustomerApi.deletes(this.state.items);
        request.then(response => {
            this.close();
            this.props.onSuccess();
        }).catch(error => {
            this.toggleLoading();
            console.warn("Delete customer failed", error);
        });
    };

    render() {
        return <DraggableModal onCancel={this.close} title="是否删除客户" ref={this.draggableRef}>
            <Typography>数据删除后无法恢复，请谨慎操作！</Typography>
            <Row gutter={8} justify="end">
                <Col>
                    <Space>
                        <Button disabled={this.state.loading} onClick={this.close}>取消</Button>
                        <Button loading={this.state.loading} type="primary" onClick={this.handle}>删除</Button>
                    </Space>
                </Col>
            </Row>
        </DraggableModal>;
        // <Modal title="是否删除客户" visible={this.state.show} confirmLoading={this.state.loading} centered
        //               onOk={this.handle} onCancel={this.close}>
        //     <Typography>数据删除后无法恢复，请谨慎操作！</Typography>
        // </Modal>;
    }
}
