import React, {Component} from "react";
import {Button, Divider, Drawer, Skeleton, Space} from "antd";
import {ProDescriptions, ProTable} from "@ant-design/pro-components";

import StorehouseRecordApi from "api/spares/record";

import ReceiveRevertModal from "../modal/ReceiveRevertModal";
import RejectRevertModal from "../modal/RejectRevertModal";
import StorehouseApi from "api/spares/storehouse";
import AccountApi from "api/user/account";

class PreviewRevertRecordDrawer extends Component {

    columns = [
        {
            title: "类型",
            dataIndex: ["stocktype", "model"],
            editable: false,
            hideInSearch: true
        },
        {
            title: "名称",
            dataIndex: ["stocktype", "name"],
            hideInSearch: true
        },
        {
            title: "数量",
            dataIndex: "count",
            hideInSearch: true
        },
        {
            title: "是否SM",
            dataIndex: ["stocktype", "classified"],
            hideInSearch: true,
            valueEnum: {
                "0": {
                    "text": "否"
                },
                "1": {
                    "text": "是"
                }
            }
        }
    ];

    receiveRevertRef = React.createRef();
    rejectRevertRef = React.createRef();

    state = {
        show: false,
        loading: false,
        drawerWidth: 600,
        revertId: "",
        revertRecord: {},
        repositoryInputRecord: {},
        stocks: [],
        storehouseData: [],
        revertAuditor: ""
    };

    show = (id) => {
        console.log(id);
        this.setState({
            show: true,
            drawerWidth: window.innerWidth < 576 ? window.innerWidth : window.innerWidth * 0.55,
            revertId: id
        }, () => this.requestRevertRecord(id));
    };

    close = () => this.setState({show: false});

    toggleLoading = () => this.setState({loading: !this.state.loading});

    requestRevertRecord = (id) => {
        StorehouseRecordApi.revertRepository(id).then(res => {
            // this.requestRepositoryInputRecord(res.data.input);
            if (!!res.data.creator) {
                this.requestSolicitAuditor(res.data.creator)
            }
            this.setState({
                revertRecord: res.data,
                stocks: res.data.input.stocks,
                loading: false
            }, () => this.requestStorehouseInfo());
        }).catch(err => {
            console.error("Request revert record failed", err);
        });
    };

    requestRepositoryInputRecord = (id) => {
        StorehouseRecordApi.repositoryInput(id).then(res => {
            this.setState({repositoryInputRecord: res.data, stocks: res.data.stocks});
        }).catch(err => {
            console.error("Request repository input record failed", err);
        });
    };

    renderOptions = () => {
        const renderReceive = this.state.revertRecord.audit === 0;
        const renderReject = this.state.revertRecord.audit === 0 && this.state.revertRecord.audit !== 5;
        return [
            <Space key="topButton">
                {!!renderReceive ?
                    <Button type="primary"
                            onClick={() => this.receiveRevertRef.current.show(this.state.revertId)}>通过</Button> :
                    null}
                {!!renderReject ?
                    <Button danger
                            onClick={() => this.rejectRevertRef.current.show(this.state.revertId)}>驳回</Button> :
                    null}
            </Space>
        ];
    };

    renderProgress = (key) => {
        const actions = {
            0: "待审核",
            5: "审核通过",
            99: "已驳回"
        };
        return actions[key];
    };

    requestStorehouseInfo = () => {
        StorehouseApi.find(this.state.revertRecord.repository).then(res => {
            this.setState({storehouseData: res.data});
        }).catch(err => {
            console.error("Request origin storehouse info failed", err);
        });
    };

    requestSolicitAuditor = (id) => {
        AccountApi.findUserInfo({params: {accountId: id}}).then(res => {
            this.setState({revertAuditor: res.data.name});
        }).catch(err => {
            console.error("Request account info failed", err);
            return "";
        });
    };

    reload = () => this.requestRevertRecord(this.state.revertId);

    render() {
        return (<Drawer title="工程师退还记录"
                        destroyOnClose
                        width={this.state.drawerWidth}
                        open={this.state.show}
                        closable={!this.state.loading}
                        onClose={this.close}>

                <ProDescriptions bordered size="small" layout="horizontal"
                                 column={window.innerWidth < 800 ? 1 : 4}
                                 extra={this.renderOptions()}
                                 loading={this.state.loading}>
                    <ProDescriptions.Item label="类型" span={2}>
                        退还
                    </ProDescriptions.Item>
                    <ProDescriptions.Item label="进度" span={2}>
                        {this.renderProgress(this.state.revertRecord.audit)}
                    </ProDescriptions.Item>
                    <ProDescriptions.Item label="退还说明" span={4}>
                        {this.state.revertRecord.comment}
                    </ProDescriptions.Item>
                </ProDescriptions>

                <Skeleton active loading={this.state.loading}>
                    <ProDescriptions bordered size="small" layout="horizontal"
                                     column={window.innerWidth < 800 ? 1 : 4}
                                     request={this.requestStorehouseInfo}
                                     loading={this.state.loading}>
                        <ProDescriptions.Item label="仓库" span={4}>
                            {this.state.storehouseData.name}
                        </ProDescriptions.Item>
                        <ProDescriptions.Item label="单位" span={4}>
                            {!!this.state.storehouseData && !!this.state.storehouseData.department && !!this.state.storehouseData.department.name ? this.state.storehouseData.department.name : null}
                        </ProDescriptions.Item>
                        <ProDescriptions.Item label="退还" span={4}>
                            {this.state.revertAuditor}
                        </ProDescriptions.Item>
                    </ProDescriptions>
                </Skeleton>

                <Divider/>
                <ProTable headerTitle="退还物资列表"
                          search={false}
                          rowKey="id"
                          defaultSize="small"
                          pagination={false}
                          options={false}
                          columns={this.columns}
                          dataSource={this.state.stocks}/>
                <ReceiveRevertModal onSuccess={this.reload} ref={this.receiveRevertRef}/>
                <RejectRevertModal onSuccess={this.reload} ref={this.rejectRevertRef}/>
            </Drawer>
        );
    }
}

export default PreviewRevertRecordDrawer;
