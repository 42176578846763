import React, {Component} from "react";
import {Drawer, Skeleton} from "antd";
import {ProDescriptions} from "@ant-design/pro-components";
import WorkorderFileRender from "component/DescriptionFileRender";
import StorehouseTypeApi from "api/spares/type";

/**
 * 查看设备类型信息
 * 根据id调取接口 获取 设备类型信息 以及设备厂商信息
 * 使用 ProDescriptions 渲染到页面
 */
export default class PreviewStorehouseTypeDrawer extends Component {

    state = {
        address: "",
        drawerWidth: 600,
        loading: false,
        show: false,
        skeletonLoading: true,
        storehouse: {
            files: []
        },
        manufacturer: {}
    };

    show = id => {
        this.setState({
            show: true,
            drawerWidth: window.innerWidth < 576 ? window.innerWidth : window.innerWidth * 0.55
        }, () => {
            this.requestStorehouseData(id);
        });
    };

    close = () => this.setState({show: false});

    requestStorehouseData = id => {
        StorehouseTypeApi.find(id).then(res => {
            this.setState({
                storehouse: res.data,
                skeletonLoading: false
            });
            this.requestManufacturer();
        });
    };

    requestManufacturer = () => {
        global.axios.get(`/resource/manufacturer/${this.state.storehouse.manufacturer.id}`).then(response => {
            if (response.data.province && response.data.city && response.data.county) {
                this.setState({address: response.data.province + " - " + response.data.city + " - " + response.data.county});
            } else {
                this.setState({address: "-"});
            }
            this.setState({manufacturer: response.data});
        }).catch(error => {
            console.warn("Request manufacturer failed", error);
        });
    };

    render() {
        return <Drawer title="查看备件类型详情" width={this.state.drawerWidth} open={this.state.show}
                       destroyOnClose
                       closable={!this.state.loading}
                       onClose={this.close}>
            <Skeleton active loading={this.state.skeletonLoading}>
                <ProDescriptions bordered size="small" layout="horizontal"
                                 column={window.innerWidth < 800 ? 1 : 4}
                                 dataSource={this.state.storehouse}
                                 loading={this.state.loading}>
                    <ProDescriptions.Item label="设备类型名称" span={2} dataIndex="name"/>
                    <ProDescriptions.Item label="设备型号" span={2} dataIndex="model"/>
                    <ProDescriptions.Item label="是否为专用机" span={2} dataIndex="classified" valueEnum={{
                        0: {text: "否"},
                        1: {text: "是"}
                    }}/>
                    <ProDescriptions.Item label="价格(元)" span={2} dataIndex="price"/>
                    <ProDescriptions.Item label="描述信息" span={4} dataIndex="description"/>
                    <ProDescriptions.Item label="附件信息" span={4}>
                        <WorkorderFileRender file={this.state.storehouse.files}/>
                    </ProDescriptions.Item>
                </ProDescriptions>

                <ProDescriptions bordered size="small" layout="horizontal"
                                 column={window.innerWidth < 800 ? 1 : 4}>
                    <ProDescriptions.Item label="制造商名称" span={4}>
                        {this.state.manufacturer.name}
                    </ProDescriptions.Item>
                    <ProDescriptions.Item label="联系方式" span={4}>
                        {this.state.manufacturer.contactsCellphone}
                    </ProDescriptions.Item>
                    <ProDescriptions.Item label="地址信息" span={4}>
                        {this.state.address}
                    </ProDescriptions.Item>
                </ProDescriptions>
            </Skeleton>
        </Drawer>;
    }
}

