import React, {Component} from "react";
import {Button, Drawer, Space, Tooltip} from "antd";
import {ProTable} from "@ant-design/pro-components";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";

import DepartmentApi from "api/user/department";

import EditAccountModal from "../../account/modal/EditAccountModal";
import DeleteAccountModal from "../../account/modal/DeleteAccountModal";
import CreateAccountModal from "../../account/modal/CreateAccountModal";

/**
 * 查看单位用户
 * 根据id 获取 改单位下的所有用户
 */
class PreviewDepartmentUserDrawer extends Component {

    state = {
        departmentCustomerDrawerShow: false,
        drawerWidth: 300,
        departmentName: "",
        departmentId: "",
        columnsStateMap: {
            email: {
                show: false
            },
            createTime: {
                show: false
            },
            address: {
                show: false
            }
        },
        customerData: []
    };

    createRef = React.createRef();
    editRef = React.createRef();
    deleteRef = React.createRef();

    columns = [
        {
            title: "ID",
            hideInTable: true,
            hideInSearch: true,
            dataIndex: "id",
            editable: false
        },
        {
            title: "用户姓名",
            dataIndex: "name"
        },
        {
            title: "手机号",
            hideInForm: true,
            dataIndex: "cellphone",
            editable: false
        },
        {
            title: "邮箱",
            hideInSearch: true,
            dataIndex: "email",
            ellipsis: true,
            key: "email"
        },
        {
            title: "所在省",
            dataIndex: "province",
            hideInSearch: true
        },
        {
            title: "所在市",
            dataIndex: "city",
            hideInSearch: true
        },
        {
            title: "所在区/县",
            dataIndex: "county",
            hideInSearch: true
        },
        {
            title: "详细地址",
            dataIndex: "address",
            hideInSearch: true,
            ellipsis: true,
            key: "address"
        },
        {
            title: "创建时间",
            hideInSearch: true,
            dataIndex: "createTime",
            valueType: "dateTime",
            key: "createTime"
        },
        {
            title: "操作",
            valueType: "option",
            render: (_, entity) => this.renderColumnOperations(entity)
        }
    ];

    renderColumnOperations = entity => <Space>
        <Tooltip title="编辑">
            <Button icon size="small" onClick={() => this.editRef.current.show(entity.id)}><EditOutlined/></Button>
        </Tooltip>
        <Tooltip title="删除">
            <Button icon size="small" danger onClick={() => this.deleteRef.current.show([entity.id])}><DeleteOutlined/></Button>
        </Tooltip>
    </Space>;

    show = (id, name) => {
        this.setState({
            departmentCustomerDrawerShow: true,
            drawerWidth: window.innerWidth < 576 ? window.innerWidth : window.innerWidth * 0.55,
            departmentName: name,
            departmentId: id
        });
    };

    close = () => {
        this.setState({
            departmentCustomerDrawerShow: false
        });
    };

    onColumnsStateMap = (columnsStateMap) => {
        this.setState({
            columnsStateMap
        });
    };

    reload = () => {
        this.actionRef?.reload();
    };

    toolbarRender = () => <CreateAccountModal onSuccess={this.reload} ref={this.createRef}
                                               departmentId={this.state.departmentId}/>;

    requestPage = () => {
        return DepartmentApi.getDepartmentAccount(this.state.departmentId).then(response => {
            return {data: response.data, success: true, total: response.data.total};
        }).catch(error => {
            console.warn("Request department  pages failed", error);
            return {data: [], success: true, total: 0};
        });
    };

    render() {
        return <Drawer title={this.state.departmentName} placement="right" width={this.state.drawerWidth}
                       onClose={this.close} open={this.state.departmentCustomerDrawerShow}
                       destroyOnClose>

            <ProTable bordered
                      rowKey="id"
                      defaultSize="small"
                      search={false}
                      toolBarRender={this.toolbarRender}
                      options={{reload: true, setting: true, density: true}}
                      actionRef={actionRef => this.actionRef = actionRef}
                      columns={this.columns}
                      columnsState={this.state.columnsStateMap}
                      onColumnsStateChange={this.onColumnsStateMap}
                      request={this.requestPage}/>

            <EditAccountModal onSuccess={this.reload} ref={this.editRef}/>
            <DeleteAccountModal onSuccess={this.reload} ref={this.deleteRef}/>
        </Drawer>;
    }
}

export default PreviewDepartmentUserDrawer;
