import React from "react";
import {Button, Col, Row, Space} from "antd";

import WorkorderApi from "api/workorder";
import {ProForm} from "@ant-design/pro-components";
import {setFieldsError} from "plugin/formily";
import DraggableModal from "component/DraggableModal";

/**
 * 接收工单弹窗 Modal
 *
 * @description 接收工单
 *
 * @author huang Carder
 * @since 2021-06-23
 * @version 1.0.0
 */
export default class ContinueWorkorderDialog extends React.Component {

    state = {
        loading: false,
        show: false,
        modificationFrom: false,
        workorderId: "",
        hangUpComment: ""
    };

    formRef = React.createRef();
    draggableRef = React.createRef();

    show = id => {
        this.draggableRef.current.show();
        this.setState({workorderId: id}, () => {
            this.requestWorkorder();
        });
    };

    close = () => {
        this.setState({loading: false});
        this.draggableRef.current.close();
    };

    /**
     * 控制 loading加载效果 显示
     */
    toggleLoading = () => this.setState({loading: !this.state.loading});

    /**
     * 获取工单信息
     */
    requestWorkorder = () => {
        WorkorderApi.find(this.state.workorderId).then(response => {
            console.log(response);
            if (!!response.data.logs && response.data.logs.length > 0) {
                this.setState({hangUpComment: response.data.logs[response.data.logs.length - 1].comment});
            }
            // this.setState({workorder: response.data});
        }).catch(error => {
            console.warn("Get workorder failed", error);
        });
    };

    /**
     * 接收工单
     * @param data 表单数据
     */
    handle = data => {
        this.toggleLoading();
        data.workorderId = this.state.workorderId;
        WorkorderApi.start(data).then(response => {
            console.info("Receive workorder success", response);
            this.toggleLoading();
            this.close();
            if (!!this.props.onSuccess) {
                this.props.onSuccess();
            }
        }).catch(error => {
            console.warn("Receive workorder failed", error);
            this.toggleLoading();
            setFieldsError(error,this.formRef);
        });
    };

    render() {
        return <DraggableModal onCancel={this.close} title="继续工单" ref={this.draggableRef}>
            <ProForm formRef={this.formRef} onFinish={this.handle} layout={"vertical"} submitter={{
                resetButtonProps: {style: {display: "none"}},
                submitButtonProps: {style: {display: "none"}}
            }}>
                <div>工单挂起原因: <span
                    style={{fontWeight: "bold"}}>{this.state.hangUpComment === "" ? "未填写挂起原因" : this.state.hangUpComment}</span>
                </div>
                <div style={{marginTop: 10}}>是否继续处理该工单</div>
                <Row gutter={8} justify="end">
                    <Col>
                        <Space>
                            <Button disabled={this.state.loading} onClick={this.close}>取消</Button>
                            <Button loading={this.state.loading} type="primary" htmlType="submit">继续</Button>
                        </Space>
                    </Col>
                </Row>
            </ProForm>
        </DraggableModal>;
    }
}
