import React, {Component} from "react";
import {PageContainer, ProTable} from "@ant-design/pro-components";
import {Button, Dropdown, Menu, Space, Tooltip} from "antd";
import {EditOutlined, EyeOutlined, MoreOutlined} from "@ant-design/icons";

import CreateStorehouseModal from "./modal/CreateStorehouseModal";
import EditStorehouseModal from "./modal/EditStorehouseModal";
import DeleteStorehouseModal from "./modal/DeleteStorehouseModal";
import SetStorehouseAdminModal from "./modal/SetStorehouseAdminModal";
import SetStorehouseEngineerModal from "./modal/SetStorehouseEngineerModal";

import StorehouseApi from "api/spares/storehouse";
import style from "../../user/department/index.module.css";
import SecurityInterceptor, {getUser, hasAnyRole, isAdmin} from "component/SecurityInterceptor";
import {withNavigation} from "utils/RouteLink";

class StorehouseManage extends Component {

    createStorehouseRef = React.createRef();
    editStorehouseRef = React.createRef();
    deleteStorehouseRef = React.createRef();
    setStorehouseAdminRef = React.createRef();
    setStorehouseEngineerRef = React.createRef();

    columns = [
        {
            title: "ID",
            dataIndex: "id",
            editable: false,
            hideInSearch: true,
            hideInTable: true
        },
        {
            title: "仓库名称",
            dataIndex: "name"
        },
        {
            title: "所在省",
            dataIndex: "province",
            hideInSearch: true
        },
        {
            title: "所在市",
            dataIndex: "city",
            hideInSearch: true
        },
        {
            title: "所在区县",
            dataIndex: "county",
            hideInSearch: true
        },
        {
            title: "地址",
            dataIndex: "address",
            hideInSearch: true
        },
        {
            title: "创建时间",
            dataIndex: "createTime",
            hideInSearch: true
        },
        {
            title: "操作",
            valueType: "option",
            render: (node, entity, index, action) => this.renderColumnOperations(node, entity, index, action)
        }
    ];

    renderColumnOperations = (node, entity, index, action) => {
        return <Space>
            <Tooltip title="查看仓库">
                <Button icon size="small"
                        onClick={() => this.props.navigate("/home/spares/storehouse-manage/storehouse-details", {state: {id: entity.id}})}>
                    <EyeOutlined/>
                </Button>
            </Tooltip>
            <SecurityInterceptor role={["admin", "user-department-manager"]}>
                <Tooltip title="编辑仓库">
                    <Button icon size="small" onClick={() => this.editStorehouseRef.current.show(entity.id)}>
                        <EditOutlined/>
                    </Button>
                </Tooltip>
            </SecurityInterceptor>
            <SecurityInterceptor role={["admin", "user-department-manager"]}>
                <Tooltip title="更多">
                    <Dropdown key="more" overlay={this.renderColumnOptionsMenu(entity, action)} trigger={["click"]}>
                        <Button icon size="small"><MoreOutlined/></Button>
                    </Dropdown>
                </Tooltip>
            </SecurityInterceptor>
        </Space>;
    };

    renderColumnOptionsMenu = (entity, action) => <Menu>
        <Menu.Item key="1">
            <span onClick={() => this.setStorehouseAdminRef.current.show(entity.id, entity.department.id)}
                  className={style.btn}>设置仓库管理员</span>
        </Menu.Item>
        <Menu.Item key="2">
            <span onClick={() => this.setStorehouseEngineerRef.current.show(entity.id, entity.department.id)}
                  className={style.btn}>设置仓库工程师</span>
        </Menu.Item>
        <Menu.Item key="3" danger>
            <span onClick={() => this.deleteStorehouseRef.current.show(entity.id)}
                  className={style.btn}>删除</span>
        </Menu.Item>
    </Menu>;

    toolbarRender = () => (<Space>
        <SecurityInterceptor role={["admin", "user-department-manager"]}>
            <Button type="primary" onClick={() => this.createStorehouseRef.current.show()}>新增仓库</Button>
        </SecurityInterceptor>
    </Space>);

    requestPage = (data) => {
        if (hasAnyRole(["user-department-manager"]) && !hasAnyRole(["admin"])) {
            data.department = getUser().departments[0].id;
        }
        if (!isAdmin() && !hasAnyRole(["user-department-manager"])) {
            data.user = getUser().id;
        }
        return StorehouseApi.page(data).then(res => {
            return {data: res.data.records, success: true, total: res.data.total};
        }).catch(err => {
            console.error("Request page failed", err);
            return {data: [], success: false, total: 0};
        });
    };

    reload = () => this.actionRef.reload();

    render() {
        return <PageContainer header={{title: "仓库管理", breadcrumb: {}}} extra={this.toolbarRender()}>
            <ProTable headerTitle="仓库管理"
                      rowKey="id"
                      defaultSize="small"
                      options={{reload: true, setting: true, density: true}}
                      actionRef={actionRef => this.actionRef = actionRef}
                      columns={this.columns}
                      request={this.requestPage}/>
            <CreateStorehouseModal onSuccess={this.reload} ref={this.createStorehouseRef}/>
            <EditStorehouseModal onSuccess={this.reload} ref={this.editStorehouseRef}/>
            <DeleteStorehouseModal onSuccess={this.reload} ref={this.deleteStorehouseRef}/>
            <SetStorehouseAdminModal onSuccess={this.reload} ref={this.setStorehouseAdminRef}/>
            <SetStorehouseEngineerModal onSuccess={this.reload} ref={this.setStorehouseEngineerRef}/>
        </PageContainer>;
    }

}

export default withNavigation(StorehouseManage);
