import React from "react";
import {Button, Tooltip} from "antd";
import {PageContainer, ProTable} from "@ant-design/pro-components";
import CreateContractDrawer from "./drawer/CreateContractDrawer";

import DeleteContractDialog from "./modal/DeleteContractDialog";
import EditContractDrawer from "./drawer/EditContractDrawer";
import PreviewContractDrawer from "./drawer/PreviewContractDrawer";
import {DeleteOutlined, EditOutlined, EyeOutlined} from "@ant-design/icons";
import isDevEnvironment from "../../../utils/isDevEnvironment";
import {getUser} from "../../../component/SecurityInterceptor";

/**
 * 合同列表
 *获取列表页数据
 *根据每一条数据的id  组成以 , 分隔的字符串 请求每条数据的开始结束时间
 */
export default class Contracts extends React.Component {

    actionRef;

    previewRef = React.createRef();
    editRef = React.createRef();
    deleteRef = React.createRef();

    /**
     * 表各项
     */
    columns = [
        {
            title: "ID",
            hideInTable: true,
            hideInSearch: true,
            dataIndex: "id",
            editable: false
        },
        {
            title: "合同名称",
            dataIndex: "name"
        },
        {
            title: "合同编号",
            dataIndex: "comment"
        },
        {
            title: "开始时间",
            dataIndex: "startTime",
            hideInSearch: true
        },
        {
            title: "结束时间",
            dataIndex: "endTime",
            hideInSearch: true
        },
        {
            title: "操作",
            valueType: "option",
            render: (node, entity, index, action) => this.renderColumnOperations(node, entity, index, action)
        }
    ];

    /**
     * 渲染配置项
     * @param node
     * @param entity
     * @param index
     * @param action
     * @returns {JSX.Element[]}
     */
    renderColumnOperations = (node, entity, index, action) => {
        return [
            <Tooltip title="查看" key="preview">
                <Button icon size="small" onClick={() => this.dialog(entity.id, this.previewRef)}>
                    <EyeOutlined/>
                </Button></Tooltip>,
            <Tooltip title="编辑" key="edit">
                <Button icon size="small" onClick={() => this.dialog(entity.id, this.editRef)}>
                    <EditOutlined/>
                </Button></Tooltip>,
            <Tooltip title="删除" key="delete">
                <Button danger icon size="small" onClick={() => this.dialog(entity.id, this.deleteRef)}>
                    <DeleteOutlined/>
                </Button>
            </Tooltip>
        ];
    };

    dialog = (id, ref) => ref.current.show(id);

    renderToolbar = () => {
        return [<CreateContractDrawer onSuccess={this.reload} key="create"/>];
    };

    /**
     * 获取合同分页数据
     * @param params
     * @returns {Promise<{total, data: *, success: boolean}|{total: number, data: *[], success: boolean}>}
     */
    requestPage = async params => {
        // isDevEnvironment || getUser()?.departments[0]?.id === "155562326307770368 //限制只有网联账号 和在开发环境下才能查看的情况下设置

            const response = await global.axios.get("/resource/resources/page", {
                params: {
                    current: params.current,
                    size: params.pageSize,
                    categoryId: "163063635416059904",
                    name: params.name,
                    comment: params.comment
                }
            }).catch(err => {
                return {data: [], success: true, total: 0};
            })
            const data = response.data;
            if (response.status !== 20000) {
                return {data: [], success: true, total: 0};
            }
            let param = "";
            data.records.forEach((item, index) => {
                param = param + item.id;
                if (index < data.records.length - 1) {
                    param = param + ",";
                }
            });
            const contractData = await global.axios.get("/resource/resource/infos", {
                params: {
                    resourceInfoIds: param
                }
            });
            for (let info of contractData.data) {
                for (let contract of data.records) {
                    if (info.resourceId === contract.id) {
                        if (!contract.infos) {
                            contract.infos = [];
                        }
                        contract.infos.push(info);
                    }
                }
            }
            for (let contract of data.records) {
                if (!!contract.infos) {
                    for (let info of contract.infos) {
                        if (info.name === "合同到期时间") {
                            contract.endTime = info.value;
                        }
                        if (info.name === "合同开始时间") {
                            contract.startTime = info.value;
                        }
                    }
                }
            }
            return {data: data.records, success: true, total: data.total};


    };

    reload = () => this.actionRef.reload();

    render() {
        return <PageContainer header={{title: "合同管理", breadcrumb: {}}} extra={this.renderToolbar()}>
            <ProTable
                headerTitle="合同信息"
                rowKey="id"
                defaultSize="small"
                options={{reload: true, setting: true, density: true}}
                actionRef={actionRef => this.actionRef = actionRef}
                columns={this.columns}
                request={this.requestPage}/>

            <PreviewContractDrawer key="preview" ref={this.previewRef}/>
            <EditContractDrawer key="edit" onSuccess={this.reload} ref={this.editRef}/>
            <DeleteContractDialog key="delete" onSuccess={this.reload} ref={this.deleteRef}/>
        </PageContainer>;
    };
}
