import React, {Component} from 'react';
import DraggableModal from "../../../../component/DraggableModal";
import {Button, Col, Row, Space, Typography} from "antd";
import RemoteApi from "../../../../api/remote/RemoteApi";
import {ProForm, ProFormText, ProFormTextArea} from "@ant-design/pro-components";

/**
 * @author       : AngelBeats
 * @date         : 2023-06-01 16:07:05
 * @description  : 远程反馈
 */
export default class FeedBackModal extends Component {

    draggableRef = React.createRef();
    formRef = React.createRef();

    state = {
        loading: false,
        id: ""
    }

    /**
     * 显示
     */
    show = id => {
        this.draggableRef.current.show();
        this.setState({id: id})
    }

    /**
     * 隐藏
     */
    close = () => {
        this.draggableRef.current.close();
    }

    /**
     * 显示loading
     */
    showLoading = () => this.setState({loading: true});

    /**
     * 隐藏loading
     */
    closeLoading = () => this.setState({loading: false})

    /**
     * 提交
     */
    handle = () => {

        this.formRef.current.validateFields().then(values => {
            values.id = this.state.id;
            // console.log(values)
            RemoteApi.feedBackUpdate(values).then(res => {
                this.closeLoading();
                this.close();
                this.props.onSuccess();
            }).catch()
        }).catch(errorInfo => {
            console.log('Failed:', errorInfo);
        });

        // this.showLoading();
        // RemoteApi.feedBackUpdate(params).then(res => {
        //     this.closeLoading();
        //     this.close();
        //     this.props.onSuccess();
        // }).catch(err => {
        //     this.closeLoading();
        //     console.error("delete remote device failed", err)
        // })
    }

    render() {
        return (<DraggableModal title="反馈内容" ref={this.draggableRef} onCancel={this.close}>
            <ProForm formRef={this.formRef} onFinish={this.handle} layout={"vertical"} submitter={{
                resetButtonProps: {style: {display: "none"}},
                submitButtonProps: {style: {display: "none"}}
            }}>
                <ProFormTextArea
                    width="xl"
                    name="feedbackDes"
                    label="反馈详情"
                    placeholder="请输入反馈详情"
                    required
                    rules={[{required: true, message: "请输入反馈详情"}, {pattern: /\S/, message: "不能为空"}]}/>
                <Row gutter={8} justify="end">
                    <Col>
                        <Space>
                            <Button disabled={this.state.loading} onClick={this.close}>取消</Button>
                            <Button loading={this.state.loading} type="primary" onClick={this.handle}>确认</Button>
                        </Space>
                    </Col>
                </Row>
            </ProForm>

        </DraggableModal>)
    }
}
