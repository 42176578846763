import React, {Component} from "react";
import {ProCard} from "@ant-design/pro-components";
import {Pie} from "@antv/g2plot";

import "./index.css";
import WorkorderApi from "../../../../../api/workorder";

/**
 * 满意度走势图
 */
export default class TrendChart extends Component {

    state = {
        satisfied: null,
        verySatisfied: null,
        notSatisfied: null,
        loading: true
    };

    /**
     * 不满意、满意、非常满意数据分开请求
     * @returns
     */
    async componentDidMount() {
        await this.requestWorkorderFeedbackScoreStatisticsByFeedbackScore();
    }

    /**
     * 请求工单回访统计信息
     */
    requestWorkorderFeedbackScoreStatisticsByFeedbackScore = async () => {
        const {data} = await WorkorderApi.indicators({feedbackScore: 1});
        this.setState({notSatisfied: data}, () => this.requestWorkorderFeedbackScoreStatisticsByFeedbackScore2())
        // switch (feedbackScore) {
        //     case 1:
        //         this.setState({notSatisfied: data})
        //         break;
        //     case 2:
        //         this.setState({satisfied: data})
        //         break;
        //     case 3:
        //         this.setState({verySatisfied: data}, () => {
        //             console.log(this.state.verySatisfied)
        //         })
        //         break;
        //     default:
        //         this.setState({satisfied: data})
        //         break;
        // }
    };
    requestWorkorderFeedbackScoreStatisticsByFeedbackScore2 = async () => {
        const {data} = await WorkorderApi.indicators({feedbackScore: 2});
        this.setState({satisfied: data}, () => this.requestWorkorderFeedbackScoreStatisticsByFeedbackScore3())
    };
    requestWorkorderFeedbackScoreStatisticsByFeedbackScore3 = async () => {
        const {data} = await WorkorderApi.indicators({feedbackScore: 3});
        this.setState({verySatisfied: data}, () => {
            const data = [
                {type: '非常满意', value: this.state.verySatisfied},
                {type: '满意', value: this.state.satisfied},
                {type: '不满意', value: this.state.notSatisfied},
            ];

            console.log(data)

            const piePlot = new Pie('lineContainer', {
                appendPadding: 10,
                data,
                angleField: 'value',
                colorField: 'type',
                radius: 1,
                innerRadius: 0.6,
                color: ["#16B98E", "#40A8FF", "#FFA926"],
                label: {
                    type: 'inner',
                    offset: '-50%',
                    content: '{value}',
                    style: {
                        textAlign: 'center',
                        fontSize: 14,
                    },
                },
                legend: {
                    position: "bottom"
                },
                interactions: [{type: 'element-selected'}, {type: 'element-active'}],
                statistic: {
                    title: false,
                    content: {
                        style: {
                            whiteSpace: 'pre-wrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            fontSize: 18
                        },
                        content: `<div style="display: flex;flex-direction: column;justify-content: center">
                            <p>${this.state.notSatisfied + this.state.verySatisfied + this.state.satisfied}</p>
                            <p style="color: #999;font-size: 12px;">已评分</p>
                        </div>`,
                    },
                },
            });

            piePlot.render();
        })
    };

    render() {
        return <ProCard colSpan={{xs: 24, sm: 12}} title="满意度统计" headerBordered layout="center"
                        bordered size="small" style={{height: "100%"}}>
            <div id="lineContainer"/>
            {/*<Skeleton active loading={this.state.loading}>*/}
            {/*    {this.state.lineData.length > 0 ? <div id="lineContainer"/> : <span>暂无数据...</span>}*/}
            {/*</Skeleton>*/}
        </ProCard>;
    }
}
