import React, {forwardRef, useImperativeHandle, useRef, useState} from "react";
import {Button, Col, Row, Space, Typography} from "antd";

import DraggableModal from "component/DraggableModal";

import VersionApi from "api/user/version";

const DeleteVersionUpdate = (props, ref) => {

    const draggableRef = useRef(null);

    const [loading, setLoading] = useState(false);
    const [versionId, setVersionId] = useState(false);

    useImperativeHandle(ref, () => ({
        show
    }));

    const show = (id) => {
        setVersionId(id);
        draggableRef.current.show();
    };

    const close = () => {
        setLoading(false);
        draggableRef.current.close();
    };

    const toggleLoading = () => setLoading(!loading);

    const handle = () => {
        toggleLoading();
        VersionApi.delete(versionId).then(res => {
            toggleLoading();
            props.onSuccess();
            close();
        }).catch(err => {
            console.error("Delete failed", err);
        });
    };

    return (<DraggableModal onCancel={close} title="是否删除该数据" ref={draggableRef}>
        <Typography>数据删除后无法恢复，请谨慎操作！</Typography>
        <Row gutter={8} justify="end">
            <Col>
                <Space>
                    <Button disabled={loading} onClick={close}>取消</Button>
                    <Button loading={loading} type="primary" onClick={handle}>删除</Button>
                </Space>
            </Col>
        </Row>
    </DraggableModal>);
};

export default forwardRef(DeleteVersionUpdate);
