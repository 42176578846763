import React, {Component} from "react";
import {Drawer} from "antd";

import ScheduleInputRepository from "../component/ScheduleInputRepository";
import ScheduleOutputRepository from "../component/ScheduleOutputRepository";

class PreviewScheduleRecordDrawer extends Component {

    scheduleInputRepositoryRef = React.createRef();

    state = {
        show: false,
        loading: false,
        drawerWidth: 600,
        inOrOut: "",
        scheduleId: ""
    };

    show = (id, inOrOut) => {
        this.setState({
            show: true,
            inOrOut,
            scheduleId: id,
            drawerWidth: window.innerWidth < 576 ? window.innerWidth : window.innerWidth * 0.55
        });
    };

    close = () => {
        this.setState({show: false});
        this.props.onSuccess()
        //判断是否刷新调拨表格页
        // if (!!this.scheduleInputRepositoryRef && !!this.scheduleInputRepositoryRef.current.reloadSchedule) {
        //     !!this.scheduleInputRepositoryRef.current.reloadSchedule() ? this.props.onSuccess() : null;
        // }
    };

    toggleLoading = () => this.setState({loading: !this.state.loading});

    renderScheduleComponent = (key) => {
        const actions = {
            input: <ScheduleInputRepository scheduleId={this.state.scheduleId} ref={this.scheduleInputRepositoryRef}/>,
            output: <ScheduleOutputRepository scheduleId={this.state.scheduleId}/>
        };
        return actions[key];
    };

    render() {
        return (<Drawer title="库存调拨记录"
                        destroyOnClose
                        width={this.state.drawerWidth}
                        open={this.state.show}
                        closable={!this.state.loading}
                        onClose={this.close}>
                {this.renderScheduleComponent(this.state.inOrOut)}
            </Drawer>
        );
    }
}

export default PreviewScheduleRecordDrawer;
