import React, {Component} from "react";
import {Button, message, Tooltip} from "antd";
import {ApiOutlined, DeleteOutlined, EditOutlined, EyeOutlined} from "@ant-design/icons";
import {PageContainer, ProTable} from "@ant-design/pro-components";
import style from "./index.module.css";
import "./index.css"
import sbIcon from "../../../../asset/sb-icon.png";
import remoteSb from "../../../../asset/remote-sb.png";
import remoteSbOn from "../../../../asset/remote-sb-on.png";
import zhuanshuiBj from "../../../../asset/zhuanshuiBj.png";
import {isAdmin, isAdminDepartment} from "../../../../component/SecurityInterceptor";
import EventBus from "../../../../utils/eventBus";
import EquipmentMapApi from "../../../../api/equipment/map";
import PreviewRemoteDrawer from "../drawer/PreviewRemoteDrawer"
import RemoteApi from "../../../../api/remote/RemoteApi";
import RemoteLoading from "../modal/RemoteLoading";
import UseRemoteModal from "../modal/UseRemoteModal";
import {withNavigation} from "../../../../utils/RouteLink";

 class Index extends Component {
    map;
    cluster;
    previewMapTableRef = React.createRef();
    remoteLoadingRef = React.createRef();
    useremoteRef = React.createRef();
    state = {
        flag: true,
        isAdmin: false,
        devicesList: [],
        lnglats: [],
    };
    infoWindow = new AMap.InfoWindow({ offset: new AMap.Pixel(25, 0)});
    componentDidMount() {
        setTimeout(() => {
            this.map = new AMap.Map("equipment-map-container", {
                pitch: 0,
                viewMode: "3D",
                terrain: true,
                zoom: 15,
                resizeEnable: true,
                zooms: [4.6, 18],
                center: [114.973172, 25.817861],
                mapStyle: JSON.parse(localStorage.getItem("theme")) ? "amap://styles/darkblue" : null
            });

            this.renderPoints();
        }, 1000)
        EventBus.addListener("updateMapTheme", ({value}) => {
            this.map.setMapStyle(value ?? "amap://styles/normal")
        })
    }

    /**
     * 构建Html标记
     * @param item
     * @returns {string}
     */
    renderMarkerHtml = (item) => {
        let Html = ''
        item.forEach(item => {
            Html += `
             <div class="devicebox">
               <div class="header">
                    <div class="sb-logo">
                        <img src="${sbIcon}" alt="">
                     </div>
                    <ul class="msbox">
                        <li class="title">${item.name}</li>
                        <li class="status">未连接</li>
                    </ul>
               </div>
               <div class="bottom">设备识别码：${item.sn}</div>
               <div class="bottom">设备所在单位:${item.customerDepartmentObj.name}</div>
            </div>   
             `
        })
        return Html
    };

    renderEquipmentMaker = (item) => {
        console.log(item)
        if (item.length > 1){
            return `
        <div class='InfoWindow'>
            <h6>远程设备列表</h6>
            <div class="deviceboxlist">
             ${this.renderMarkerHtml(item)}
            </div>
        </div>
        `;
        }else {
            return `
            <div class='InfoWindow'>
             <div class="devicebox">
               <div class="header one">
                   <div class="sb-logo">
                        <img src="${sbIcon}" alt="">
                     </div>
                    <div class="status"> 连接中 </div>
               </div>
               <ul>
                    <li>设备识别码:${item[0].sn}</li>
                    <li>设备名称:${item[0].name}</li>
                    <li>设备所在单位:${item[0].customerDepartmentObj.name}</li>
                    <li>授权时间:${item[0].empowerStartTime} - ${item[0].empowerEndTime}</li>
               </ul>
               <div class="footer">
                    <span>使用</span>
                    <span id="engineer_detail" data-id="${item[0].id}" data-sn="${item[0].sn}">详情</span>
                </div> 
            </div>
        </div>
            `
        }

    }

    // 创建信息窗体
    createInfoWindow = async (lnglats) => {
        //信息窗体的内容
       let lngt = lnglats.split("|")[0]
        let offset = 0
        const response = await EquipmentMapApi.getLnglatEquipmentList({ lnglats });
        offset = response.data.records.length > 1 ? 10 : 0
        let content = this.renderEquipmentMaker(response.data.records)
        let infoWindow = new AMap.InfoWindow({
            //isCustom: true,
            content ,
            offset: new AMap.Pixel(offset, -20),
        });
        infoWindow.open(this.map, lngt.split(","));
        //绑定事件
        let departmentDetail = document.getElementById("engineer_detail");
        departmentDetail.addEventListener("click", () => {
            // 获取自定义属性
            let entity = {
                id :departmentDetail.getAttribute("data-id"),
                sn : departmentDetail.getAttribute("data-sn")
            }
            console.log(entity)
            this.previewMapTableRef.current.show(entity)
        })

    };

    renderPoints = () => {
        const customerDepartmentIds = localStorage.getItem("customerDepartmentIds");
        if (isAdmin() || isAdminDepartment()) {
            this.requestEquipmentMap();
        } else {
            if (!!customerDepartmentIds) {
                const data = {customerDepartmentIds: customerDepartmentIds};
                this.requestEquipmentMap(data);
            }
        }
    };

    /**
     * 地图标点
     * @returns {*}
     */
    requestEquipmentMap = () => {
        RemoteApi.page({
            current: 1,
            pageSize: 99999
        }).then(response => {
            let markerList = [];
            let lnglats = [];
            response.data.records.forEach(item => {
                 if (item.longitude !== null && item.latitude !== null ) {
                     if (item.longitude.indexOf(".") !== -1 || item.latitude.indexOf(".") !== -1) {
                         lnglats.push(item)
                         let marker = new global.AMap.Marker({
                             position: [item.longitude, item.latitude],
                             content: this.renderDeviceMaker(item),
                             offset: [-20, 0]
                         })
                         markerList.push(marker)
                     }
                }
            })
            console.log(lnglats)
            this.map.add(markerList)

            const markerClick = async (e, item) => {
                this.infoWindow.setContent(e.target.content);
                this.infoWindow.open(this.map, e.target.getPosition());

                //绑定事件
                let departmentDetail = document.getElementById("engineer_detail");
                let departmentVisit = document.getElementById("visit");
                departmentDetail.addEventListener("click", () => {
                    // 获取自定义属性
                    let entity = {
                        id :departmentDetail.getAttribute("data-id"),
                        sn : departmentDetail.getAttribute("data-sn")
                    }
                    this.previewMapTableRef.current.show(entity)

                })
                departmentVisit.addEventListener("click", () => {
                    // 获取自定义属性
                    let entity = {
                        id :departmentVisit.getAttribute("data-id"),
                        sn : departmentVisit.getAttribute("data-sn")
                    }
                    console.log(entity)
                    this.connectDevice(entity)
                })
            }
            for (let i = 0; i < lnglats.length; i++) {
                if (lnglats[i].location !== null) {
                    let marker = new global.AMap.Marker({
                        position: [lnglats[i].longitude, lnglats[i].latitude],
                        map: this.map,
                        content: this.renderDeviceMaker(lnglats[i]),
                        offset: [-20, 0]
                    });
                    marker.content = this.renderRemoteDetailDialog(lnglats[i]);
                    marker.on('click', (e) => markerClick(e, lnglats[i]));
                }
            }
        });
    };
    /**
     * 远程设备描点
     * @param item
     * @returns
     */
    renderDeviceMaker = item =>{
    return `
        <div>
         <span>${item?.name}</span>
         <img src="${remoteSb}" alt="">
        </div>
    ` }
    /**
     *远程设备详情
     * @param item
     */
    renderRemoteDetailDialog = (item) => {
        return `
        <div class='InfoWindow'>
             <div class="devicebox">
               <div class="header one">
                   <div class="sb-logo">
                        <img src="${sbIcon}" alt="">
                     </div>
                    <div class="status"> 连接中 </div>
               </div>
               <ul>
                   <li>设备识别码: ${item.sn}</li>
                   <li>设备名称: ${item.name}</li>
                   <li>设备所在单位: ${item.customerDepartmentObj.name}</li>
                   <li>授权时间: ${item.empowerStartTime} - ${item.empowerEndTime}</li>
               </ul>
               <div class="footer">
                    <span id="visit" data-id="${item.id}" data-sn="${item.sn}">使用</span>
                    <span id="engineer_detail" data-id="${item.id}" data-sn="${item.sn}">详情</span>
                </div>
               </div>
             </div>
        </div>`
    };
    useremoteSuccess = () =>{
        this.props.navigate('/home/remote/video-viewer')
    }
    /**
     * 开启连接
     * @param item
     */
    connectDevice = (item) => {
        this.remoteLoadingRef.current.show();

        RemoteApi.connectDevice(item.id).then(res => {
            const parse = JSON.parse(res.data);
            console.log("connect device success", parse);
            let { addr, sid } = parse;

            this.useremoteRef.current.show(item,addr,sid);
            this.remoteLoadingRef.current.close();
        }).catch(err => {
            message.error("连接失败！");
            this.remoteLoadingRef.current.close();
            console.error("connect device failed", err)
        })
    }

    render() {
       return (
           <PageContainer header={{title: "远程设备地图", breadcrumb: {}}}>
               <div className={style.map} id="equipment-map-container"/>
               <PreviewRemoteDrawer ref={this.previewMapTableRef}/>
               <RemoteLoading ref={this.remoteLoadingRef}/>
               <UseRemoteModal ref={this.useremoteRef} onSuccess={this.useremoteSuccess}/>
           </PageContainer>
       )
    }
}

export default withNavigation(Index)