import React from "react";
import {Button, Cascader, Col, Form, Input, message, Row, Skeleton, Space} from "antd";

import CustomerApi from "api/customer/customer";
import {setFieldsError} from "plugin/formily";
import DraggableModal from "component/DraggableModal";
import CustomerDepartmentPicker from "component/customer/department/CustomerDepartmentPicker";
import CustomerDepartmentApi from "../../../../../api/customer/department";
import {getUser} from "../../../../../component/SecurityInterceptor";


/**
 * 新建客户弹窗 Modal
 *
 * @description 新建客户弹窗
 *
 * @author huang Carder
 * @since 2021-06-23
 * @version 1.0.0
 */
export default class CreateCustomerModal extends React.Component {

    state = {
        show: false,
        loading: false,
        departmentTreeData: [],
        departmentTreeDataLoading: true,
        treeData: [],
        departmentId: undefined
    };

    formRef = React.createRef();
    draggableRef = React.createRef();

    /**
     * 控制新建对话框显示隐藏
     */
    show = id => {
        this.draggableRef.current.show();
        this.setState({departmentId: id});
        if (!id) {
            this.requestDepartmentTree()
        }
        if (!!id) {
            this.setState({departmentTreeDataLoading: false})
            // this.formRef.current.setFieldsValue({departmentId: id});
            this.onChangeDepartment(id);
        }
    };

    close = () => {
        this.setState({loading: false});
        this.draggableRef.current.close();
        this.formRef.current.resetFields();
    };

    /**
     * 请求单位树
     */
    requestDepartmentTree = () => {
        CustomerDepartmentApi.tree(getUser().departments[0].id).then(response => {
            this.setState({treeData: this.handleDepartmentTreeNode(response.data), departmentTreeDataLoading: false});
        }).catch(error => {
            this.setState({departmentTreeDataLoading: false});
            console.error("Request department tree failed", error);
        });
    }

    /**
     * 处理树形图数据
     * @param tree  树形图中的每一项
     */
    handleDepartmentTreeNode = tree => tree.map(item => ({
        ...item,
        title: item.name,
        key: item.id,
        value: item.id,
        children: !!item.children ? this.handleDepartmentTreeNode(item.children) : undefined
    }));

    /**
     * 控制 loading 加载效果显示隐藏
     */
    toggleLoading = () => this.setState({loading: !this.state.loading});

    /**
     * 创建新客户
     * @param data  表单数据
     */
    handle = data => {
        data.province = data.ares[0];
        data.city = data.ares[1];
        data.county = data.ares[2];
        data.name = data.name.trim();
        if (!!this.state.departmentId) {
            data.departmentId = this.state.departmentId;
        }
        this.toggleLoading();
        CustomerApi.create(data).then(response => {
            console.info("Create customer success", response);
            this.setState({show: false, loading: false}, this.formRef.current.resetFields);
            this.close();
            if (!!this.props.onSuccess) {
                this.props.onSuccess(data.departmentId, response.data);
            }
        }).catch(error => {
            console.log(error);
            if (error.status === 40000) {
                setFieldsError(error, this.formRef);
            } else {
                message.error({key: "failed", content: error.data});
            }
            this.toggleLoading();
            console.warn("Create customer failed", error);
        });
    };

    /**
     * 获取单位信息并把地址信息赋值
     * @param value 单位id
     */
    onChangeDepartment = value => {
        if (!!value) {
            CustomerApi.findDepartment(value).then(response => {
                let ares = [];
                ares[0] = response.data.province;
                ares[1] = response.data.city;
                ares[2] = response.data.county;
                this.formRef.current.setFieldsValue({ares});
            }).catch(error => {
                console.log("Get department info error", error);
            });
        } else {
            this.formRef.current.setFieldsValue({ares: null});
        }
    };

    render = () => <DraggableModal onCancel={this.close} title="新建客户" ref={this.draggableRef}>
        <Skeleton active loading={this.state.departmentTreeDataLoading}>
            <Form ref={this.formRef} onFinish={this.handle} layout="vertical">
                <Row gutter={[8, 0]}>
                    {
                        this.state.departmentId ? null : <Col span={12}>
                            <CustomerDepartmentPicker onChange={this.onChangeDepartment}
                                                      name="departmentId"
                                                      rules={[{required: true, message: "请选择客户单位"}]}/>
                        </Col>
                    }

                    <Col span={this.state.departmentId ? 24 : 12}>
                        <Form.Item name="name" label="客户姓名" required rules={[
                            {required: true, message: "请输入客户姓名"},
                            {pattern: /\S/g, message: "内容不能为空"}
                        ]}>
                            <Input/>
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item name="ares" label="所在区县" required rules={[{required: true, message: "请选择客户地址"}]}>
                            <Cascader options={require("asset/location.json")}/>
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item name="address" label="楼层及门牌号" required
                                   rules={[{required: true, message: "请填写详细地址"},
                                       {pattern: /\S/g, message: "内容不能为空"}]}>
                            <Input/>
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item name="cellphone" label="联系方式" required
                                   rules={[
                                       {required: true, message: "请输入手机号"},
                                       {pattern: /^[0-9]*$/g, message: "请输入正确的手机号"}
                                   ]}>
                            <Input/>
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item name="email" label="电子邮箱" rules={[
                            {pattern: /\S/g, message: "不能输入空字符串"}
                        ]}>
                            <Input/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={8} justify="end">
                    <Col>
                        <Space>
                            <Button disabled={this.state.loading} onClick={this.close}>取消</Button>
                            <Button loading={this.state.loading} type="primary" htmlType="submit">新建</Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </Skeleton>
    </DraggableModal>;
}
