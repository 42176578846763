import React, {Component} from "react";
import {Button, Tooltip} from "antd";
import {DeleteOutlined,EditOutlined} from "@ant-design/icons";
import {PageContainer,ProTable} from "@ant-design/pro-components";

import EquipmentMakerApi from "api/equipment/maker";

import CreateMakerModal from "./modal/CreateMakerModal";
import DeleteMakerModal from "./modal/DeleteMakerModal";
import EditMakerModal from "./modal/EditMakerModal";

/**
 * 设备制造商
 */
export default class EquipmentMaker extends Component {

    createEquipmentMakerRef = React.createRef();
    editEquipmentMakerRef = React.createRef();
    deleteEquipmentMakerRef = React.createRef();

    columns = [
        {
            title: "ID",
            hideInTable: true,
            hideInSearch: true,
            dataIndex: "id",
            editable: false
        },
        {
            title: "厂商名称",
            dataIndex: "name"
        },
        {
            title: "联系人",
            dataIndex: "contacts"
        },
        {
            title: "联系电话",
            dataIndex: "contactsCellphone"
        },
        {
            title: "所在省",
            dataIndex: "province",
            hideInSearch: true
        },
        {
            title: "所在市",
            dataIndex: "city",
            hideInSearch: true
        },
        {
            title: "所在区县",
            dataIndex: "county",
            hideInSearch: true
        },
        {
            title: "操作",
            valueType: "option",
            render: (node, entity, index, action) => this.renderColumnOperations(node, entity, index, action)
        }
    ];

    renderColumnOperations = (node, entity, index, action) => {
        return [
            <Tooltip title="编辑制造商" key="edit">
                <Button icon
                        size="small"
                        onClick={() => this.editEquipmentMakerRef.current.show(entity.id)}>
                    <EditOutlined/>
                </Button>
            </Tooltip>,
            <Tooltip title="删除制造商" key="delete">
                <Button icon
                        danger
                        size="small"
                        onClick={() => this.deleteEquipmentMakerRef.current.show(entity.id)}>
                    <DeleteOutlined/>
                </Button>
            </Tooltip>
        ];
    };

    toolbarRender = () => <Button type="primary"
                                  onClick={() => this.createEquipmentMakerRef.current.show()}>添加制造商</Button>;

    requestPage = data => {
        return EquipmentMakerApi.page(data).then(response => {
            return {data: response.data.records, success: true, total: response.data.total};
        }).catch(error => {
            console.warn("Request get equipment maker pages failed.", error);
        });
    };

    reload = () => this.actionRef?.reload();

    render() {
        return <PageContainer header={{title: "设备制造商", breadcrumb: {}}} extra={this.toolbarRender()}>
            <ProTable
                headerTitle="设备制造商"
                rowKey="id"
                defaultSize="small"
                options={{reload: true, setting: true, density: true}}
                actionRef={actionRef => this.actionRef = actionRef}
                columns={this.columns}
                request={this.requestPage}/>
            <CreateMakerModal onSuccess={this.reload} ref={this.createEquipmentMakerRef}/>
            <DeleteMakerModal onSuccess={this.reload} ref={this.deleteEquipmentMakerRef}/>
            <EditMakerModal onSuccess={this.reload} ref={this.editEquipmentMakerRef}/>
        </PageContainer>;
    }
}

