import {
    AuditOutlined,
    DeploymentUnitOutlined,
    LaptopOutlined,
    LinkOutlined,
    PieChartOutlined,
    SnippetsOutlined,
    TeamOutlined,
    ToolOutlined
} from "@ant-design/icons";

import Preview from "../page/home/preview";
import Workorders from "../page/home/workorder/workorder/WorkorderPage";
import TheWorkorderICreate from "../page/home/workorder/workorder/TheWorkorderICreatePage";
import TheWorkorderIHandle from "../page/home/workorder/workorder/TheWorkorderIHandlePage";
import PrepareWorkorder from "../page/home/workorder/prepare/PrepareWorkordersPage";
import FeedbackWorkorder from "../page/home/workorder/workorder/UnfeedbackWorkorderPage";
import TheAssignedWorkorder from "../page/home/workorder/unassigned/UnassignedWorkorderPage";

import Customers from "../page/home/customer/customer";
import CustomerDepartments from "../page/home/customer/department";

import Contracts from "../page/home/contract";

import EquipmentList from "../page/home/equipment/list";
import EquipmentMap from "../page/home/equipment/map";
import EquipmentType from "../page/home/equipment/type";
import EquipmentMaker from "../page/home/equipment/maker";
import RepositoryPage from "../page/home/equipment/repository";
import GrabWorkorder from "../page/home/workorder/grab";
import RemotePage from "../page/home/remote/RemotePage";
import RemoteRecordList from "../page/home/remote/RemoteRecordList"
import FeedBackList from "../page/home/remote/RemoteFeedBack";
import WorkRecList from "../page/home/remote/RemoteWorkRec";
import RemoteMap from "../page/home/remote/map"

import SparesRecord from "../page/home/spares/record";
import StorehouseManage from "../page/home/spares/storehouse";
import SparesType from "../page/home/spares/type";
import Accounts from "../page/home/user/account";
import Roles from "../page/home/user/role";
import Departments from "../page/home/user/department";
import DepartmentsDetail from "../page/home/user/department/departmentDetail";
import VersionUpdate from "../page/home/user/version";
import EngineerMap from "../page/home/user/map/EngineerMap";
import StorehouseDetails from "../page/home/spares/storehouse/details/StorehouseDetails";
import DisplaceList from "../page/home/equipment/displace";
import Dict from "../page/home/dict"
import VideoViewer from "../page/home/remote/VideoViewer";

/**
 * 路由集合
 * isShow 判断该menu 是否需要在页面上渲染出来
 * @type {[{path: string, component: *, name: string, icon: JSX.Element}]}
 */
const routes = [
    {
        path: "/home/preview",
        name: "服务概览",
        icon: <PieChartOutlined/>,
        component: <Preview/>
    },
    {
        name: "工单",
        icon: <SnippetsOutlined/>,
        path: "/home/workorder",
        children: [
            {
                name: "所有工单",
                path: "/home/workorders/all-workorder",
                roles: ["admin", "customer-service"],
                component: <Workorders/>
            },
            {
                name: "我发起的工单",
                path: "/home/workorders/the-workorder-i-create",
                component: <TheWorkorderICreate/>,
                roles: ["admin", "customer-service"]
            },
            {
                name: "我处理的工单",
                path: "/home/workorders/the-workorder-i-handle",
                component: <TheWorkorderIHandle/>
            },
            {
                name: "报修池",
                path: "/home/workorder/prepares",
                component: <PrepareWorkorder/>,
                tag: "repair",
                roles: ["admin", "customer-service"]
            },
            {
                name: "待指派",
                path: "/home/workorders/assigned-workorder",
                component: <TheAssignedWorkorder/>,
                tag: "dispatch",
                roles: ["admin", "customer-service"]
            },
            {
                name: "待团队成员接收",
                path: "/home/workorders/grab-workorder",
                component: <GrabWorkorder/>,
                tag: "team"
            },
            {
                name: "待回访工单",
                path: "/home/workorders/feedback-workorder",
                component: <FeedbackWorkorder/>,
                roles: ["admin", "customer-service"]
            }
        ]
    },
    {
        name: "客户",
        icon: <TeamOutlined/>,
        path: "/home/customer",
        roles: ["admin", "customer-service"],
        children: [
            {name: "客户", path: "/home/customers", component: <Customers/>},
            {name: "客户单位", path: "/home/customer/departments", component: <CustomerDepartments/>}
        ]
    },
    {
        name: "合同管理",
        icon: <AuditOutlined/>,
        path: "/home/contracts",
        component: <Contracts/>,
        roles: ["admin","user-department-manager"]
    },
    {
        name: "智能运维",
        icon: <LinkOutlined/>,
        path: "/home/remote-page",
        roles: ["admin"],
        children: [
            {name: "远程设备列表", path: "/home/remote/list", component: <RemotePage/>},
            {name: "远程操作历史", path: "/home/remote/remote-record-list", component: <RemoteRecordList/>,isShow: true},
            {name: "远控意见反馈", path: "/home/remote/feed-back-list", component:<FeedBackList/>,isShow: false},
            {name: "远控报单记录", path: "/home/remote/work-rec-list", component: <WorkRecList/>,isShow: false},
            {name: "远程设备地图", path: "/home/remote/map", component: <RemoteMap/>,isShow: true},
            {name: "远程查看", path: "/home/remote/video-viewer", component: <VideoViewer/>,isShow: false}
        ]
    },
    //{name: "远程查看", path: "/home/remote/video-viewer", component: <VideoViewer/>,isShow: false},
    {
        name: "设备管理",
        icon: <ToolOutlined/>,
        path: "/home/equipment",
        children: [
            {name: "设备列表", path: "/home/equipment/list", component: <EquipmentList/>},
            {name: "设备类型", path: "/home/equipment/type", component: <EquipmentType/>},
            {name: "知识库维护", path: "/home/equipment/repository-page", component: <RepositoryPage/>},
            {name: "设备制造商", path: "/home/equipment/maker", component: <EquipmentMaker/>},
            {name: "设备地图", path: "/home/equipment/map", component: <EquipmentMap/>},
            {name: "区域替换监控", path: "/home/equipment/displace-list", component: <DisplaceList/>,roles: ["admin"]}
        ]
    },
    {
        name: "备件管理",
        icon: <LaptopOutlined/>,
        path: "/home/spares",
        children: [
            {name: "仓库管理", path: "/home/spares/storehouse-manage", component: <StorehouseManage/>},
            {name: "备件类型", path: "/home/spares/spares-type", component: <SparesType/>},
            {
                name: "仓库详情",
                path: "/home/spares/storehouse-manage/storehouse-details",
                component: <StorehouseDetails/>,
                isShow: false
            },
            // {name: "备件出库", path: "/home/spares/spares-outbound", type: "menu"},
            // {name: "备件入库", path: "/home/spares/spares-warehoused", type: "menu"},
            // {name: "备件出入库", path: "/home/spares/inventory-list", type: "menu"},
            {name: "出入库记录", path: "/home/spares/spares-record", component: <SparesRecord/>}
        ]
    },
    {
        name: "系统管理",
        icon: <DeploymentUnitOutlined/>,
        path: "/home/user",
        roles: ["admin", "user-department-manager"],
        children: [
            {name: "账户", path: "/home/user/accounts", component: <Accounts/>},
            {name: "角色", path: "/home/user/roles", component: <Roles/>},
            // {name: "权限", path: "/home/user/permissions"},
            {name: "机构", path: "/home/user/departments", component: <Departments/>},
            {name: "机构详情", path: "/home/user/departments/detail", component: <DepartmentsDetail/>,isShow: false},
            {name: "系统更新", path: "/home/user/version-update", component: <VersionUpdate/>,roles: ["admin"]},
            {name: "工程师地图", path: "/home/user/engineer-map", component: <EngineerMap/>},
            {name: "字典管理", path: "/home/dict", component: <Dict/>,isShow: false},
            // {name: "工程师路线图", path: "/home/user/routine-map", component: <RoutineMap/>},
            // {name: "用户组", path: "/home/user/groups"},
            // {name: "身份", path: "/home/user/identities"}
        ]
    }
];

export default routes;
