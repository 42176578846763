import React, {Component} from "react";
import {Spin} from "antd";

import workorderInfoApi from "api/workorderInfo";
import {isAdmin} from "component/SecurityInterceptor";
import {withNavigation} from "utils/RouteLink";

import style from "./index.module.css";

/**
 * 服务概览
 * @description 已接入服务商数量统计
 */
class ServiceProvidersStatistic extends Component {
    state = {
        loading: false,
        serviceNum: ""
    };

    componentDidMount() {
        this.getServiceNumber();
    };


    getServiceNumber = () => {
        this.setState({
            loading: true
        });
        workorderInfoApi.serviceNumber().then(response => {
            this.setState({
                serviceNum: response.data,
                loading: false
            });
        }).catch(error => {
            console.log("Get department number filed", error);
            this.setState({
                serviceNum: "-",
                loading: false
            });
        });
    };

    render() {
        return (
            <div className={style.systemDataItem}
                 onClick={() => isAdmin() ? this.props.navigate("/home/user/departments") : null}>
                <p>已接入服务商数</p>
                {this.state.loading ? <Spin/> : <p>{this.state.serviceNum}</p>}
            </div>
        );
    }
}

export default withNavigation(ServiceProvidersStatistic);
