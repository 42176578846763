import React, {Component} from "react";
import {PageContainer, ProDescriptions} from "@ant-design/pro-components";

import CurrentInventory from "../commponent/CurrentInventory";
import InventoryChange from "../commponent/InventoryChange";
import ScheduleInputRecord from "../commponent/ScheduleInputRecord";
import RevertAuditRecord from "../commponent/RevertAuditRecord";

import StorehouseApi from "api/spares/storehouse";
import {getUser, hasAnyRole} from "component/SecurityInterceptor";
import {withNavigation} from "utils/RouteLink";

class StorehouseDetails extends Component {

    state = {
        tabChangeOver: "inventory",
        storehouseData: {},
        managers: "",
        managersIds: [],
        engineersIds: []
    };

    componentDidMount() {
        this.requestStorehouseDetail();
    }

    /**
     * tab栏列表
     * @returns {[{tab: string, key: string}, {tab: string, key: string}]}
     */
    renderTabList = () => {
        const renderTab = this.state.managersIds.includes(getUser().id) || hasAnyRole(["admin"]);
        return [
            {tab: "库存", key: "inventory"},
            {tab: "库存变化", key: "record"},
            renderTab ? {tab: "调拨入库审核", key: "output"} : {tab: "", key: "hidden"},
            renderTab ? {tab: "退还审核", key: "revert"} : {tab: "", key: "hiddenRevert"}
        ];
    };

    /**
     * 根据不同tab栏渲染不同内容
     * @param key
     * @returns {*}
     */
    renderTabListContent = (key) => {
        const actions = {
            inventory: <CurrentInventory storehouseId={this.props.location.state.id}
                                         storehouseAdminIds={this.state.managersIds}
                                         storehouseEngineersIds={this.state.engineersIds}/>,
            record: <InventoryChange storehouseId={this.props.location.state.id}/>,
            output: <ScheduleInputRecord storehouseId={this.props.location.state.id}/>,
            revert: <RevertAuditRecord storehouseId={this.props.location.state.id}/>
        };
        return actions[key];
    };

    /**
     * tab栏切换触发函数
     * @param value
     */
    onTabChange = (value) => this.setState({tabChangeOver: value});


    renderTitle = () => {
        switch (this.state.tabChangeOver) {
            case "inventory":
                return "当前仓库库存";
            case "record":
                return "当前库存变化";
            case "output":
                return "调拨入库审核";
            case "revert":
                return "退还审核";
            default:
                return "仓库详情";
        }
    };

    requestStorehouseDetail = () => {
        StorehouseApi.find(this.props.location.state.id).then(res => {
            if (res.data.managers !== undefined && res.data.managers !== null && res.data.managers.length > 0) {
                let managers = "";
                let managersIds = [];
                res.data.managers.forEach(item => {
                    managers += `${item.name}、`;
                    managersIds.push(item.id);
                });

                this.setState({managers: managers.slice(0, managers.length - 1), managersIds});
            }
            if (res.data.engineers !== undefined && res.data.engineers !== null && res.data.engineers.length > 0) {
                res.data.engineers.forEach(item => {
                    this.setState({engineersIds: [...this.state.engineersIds, item.id]});
                });
            }
            this.setState({storehouseData: res.data});
        }).catch(err => {
            console.error("Request storehouse detail failed", err);
        });
    };

    handleManagersInfo = (data) => {
        if (data !== undefined && data !== null && data.length > 0) {
            let managers = "";
            data.forEach(item => {
                managers += `${item.name}、`;
            });
            return managers;
        }
    };

    renderStorehouseDetail = () => {
        return <ProDescriptions column={window.innerWidth < 800 ? 1 : 2} style={{marginBottom: -16}}>
            <ProDescriptions.Item label="仓库名称" span={1}>
                {this.state.storehouseData.name}
            </ProDescriptions.Item>
            <ProDescriptions.Item label="仓库位置" span={1}>
                {this.state.storehouseData.address}
            </ProDescriptions.Item>
            <ProDescriptions.Item label="所属单位" span={1}>
                {!!this.state.storehouseData && !!this.state.storehouseData.department ? this.state.storehouseData.department.name : null}
            </ProDescriptions.Item>
            <ProDescriptions.Item label="创建时间" span={1}>
                {this.state.storehouseData.createTime}
            </ProDescriptions.Item>
            <ProDescriptions.Item label="仓库管理员" span={2}>
                {this.state.managers}
            </ProDescriptions.Item>
        </ProDescriptions>;
    };

    render() {
        return <PageContainer header={{title: this.renderTitle(), breadcrumb: {}}} fixedHeader
                              onTabChange={this.onTabChange}
                              tabList={this.renderTabList()}
                              content={this.renderStorehouseDetail()}
                              onBack={() => this.props.navigate(-1)}>
            {this.renderTabListContent(this.state.tabChangeOver)}
        </PageContainer>;
    }
}

export default withNavigation(StorehouseDetails);
