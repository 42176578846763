import React from "react";
import {Button, Cascader, Col, Form, Input, message, Row, Skeleton, Space} from "antd";

import CustomerDepartmentApi from "api/customer/department";
import CustomerDepartmentPicker from "component/customer/department/CustomerDepartmentPicker";
import {setFieldsError} from "plugin/formily";
import DraggableModal from "component/DraggableModal";
import {getUser} from "../../../../../component/SecurityInterceptor";

/**
 * 新建客户单位弹窗 Modal
 *
 * @description 新建客户单位
 *
 * @author huang Carder
 * @since 2021-06-23
 * @version 1.0.0
 */
export default class CreateCustomerDepartmentModal extends React.Component {

    state = {
        show: false,
        loading: false,
        treeData: "",
        departmentTreeData: [],
        parentDepartmentOptionsLoading: true,
        departmentId: ""
    };

    formRef = React.createRef();
    draggableRef = React.createRef();
    /**
     *  id : 机构ID
     */
    show = id => {
        this.draggableRef.current.show();
        this.setState({departmentId: id});

        CustomerDepartmentApi.tree(getUser().departments[0].id).then(response => {
            this.setState({
                treeData: this.handleDepartmentTreeNode(response.data),
                parentDepartmentOptionsLoading: false
            }, () => {
                this.formRef.current.setFieldsValue({parentId: id});
                //this.onChangeDepartments(id);
                this.setUser(getUser())
            });
        }).catch(error => {
            this.toggleLoading();
            console.warn("Get department tree failed", error);
        });
    };

    close = () => {
        this.setState({loading: false});
        this.draggableRef.current.close();
    };

    /**
     * 控制 loading 加载效果 显示
     */
    toggleLoading = () => this.setState({
        loading: !this.state.loading
    });

    /**
     * 处理树形图数据
     * @param tree  树形图中的每一项
     */
    handleDepartmentTreeNode = tree => tree?.map(item => ({
        ...item,
        title: item.name,
        key: item.id,
        value: item.id,
        children: !!item.children ? this.handleDepartmentTreeNode(item.children) : undefined
    }));

    // 获取单位详情
    setUser = (data) => {
        console.log(data)
        let ares = [];
        ares[0] = data.province;
        ares[1] = data.city;
        ares[2] = data.county;
        this.formRef.current.setFieldsValue({ares});
    };



    /**
     * 新建单位
     * @param data 表单数据
     */
    handle = data => {
        data.province = data.ares[0];
        data.city = data.ares[1];
        data.county = data.ares[2];
        this.toggleLoading();
        CustomerDepartmentApi.create(data).then((response) => {
            this.close();
            if (!!this.props.onSuccess) {
                this.props.onSuccess(response.data);
            }
        }).catch(error => {
            console.error(error);
            if (error.status === 40000) {
                setFieldsError(error, this.formRef);
            } else {
                message.error({key: "failed", content: error.data});
            }
            this.toggleLoading();
            console.warn("Create customer department failed", error);
        });
    };

    /**
     * 获取单位信息并把地址信息赋值
     * @param value 单位id
     */
    onChangeDepartments = (value) => {
        if (!!value) {
            CustomerDepartmentApi.findDepartment(value).then(response => {
                let ares = [];
                ares[0] = response.data.province;
                ares[1] = response.data.city;
                ares[2] = response.data.county;
                this.formRef.current.setFieldsValue({ares});
            }).catch(error => {
                console.log("Get department info error", error);
            });
        } else {
            this.formRef.current.setFieldsValue({ares: []});
        }
    };

    render() {
        return <DraggableModal onCancel={this.close} title="新建客户单位" ref={this.draggableRef}>
            <Skeleton active loading={this.state.parentDepartmentOptionsLoading}>
                <Form ref={this.formRef} onFinish={this.handle} layout={"vertical"}>
                    <Row gutter={[8, 0]}>
                        <Col span={12}>
                            <CustomerDepartmentPicker onChange={this.onChangeDepartments} name="parentId"
                                                      label="所属上级单位"/>
                        </Col>

                        <Col span={12}>
                            <Form.Item name="name" label="名称" required
                                       rules={[
                                           {required: true, message: "请输入名称"},
                                           {pattern: /\S/g, message: "内容不能为空"},
                                           {max: 128, message: "长度或条母数不能大于128"}
                                       ]}>
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name="ares" label="单位区域" required
                                       rules={[{required: true, message: "请选择单位区域"}]}>
                                <Cascader options={require("asset/location.json")}/>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name="address" label="详细地址" required
                                       rules={[
                                           {required: true, message: "请输入详细地址"},
                                           {pattern: /\S/g, message: "内容不能为空"},
                                           {max: 128, message: "长度或条母数不能大于128"}
                                       ]}>
                                <Input/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={8} justify="end">
                        <Col>
                            <Space>
                                <Button disabled={this.state.loading} onClick={this.close}>取消</Button>
                                <Button loading={this.state.loading} type="primary" htmlType="submit">新建</Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
            </Skeleton>
        </DraggableModal>;

    }
}
