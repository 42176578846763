import React, {Component} from "react";
import {Spin} from "antd";

import WorkorderApi from "api/workorder";
import {isAdmin} from "component/SecurityInterceptor";
import {withNavigation} from "utils/RouteLink";

import style from "./index.module.css";

/**
 * 服务概览
 * @description 待指派工单数量统计
 */
class DispatchStatistic extends Component {
    state = {
        loading: false,
        dispatchNum: ""
    };

    componentDidMount() {
        this.getDispatchNumber();
    };


    getDispatchNumber = () => {
        this.setState({
            loading: true
        });
        WorkorderApi.dispatchCount().then(response => {
            this.setState({
                dispatchNum: response.data,
                loading: false
            });
        }).catch(error => {
            console.log("Get dispatch number filed", error);
            this.setState({
                dispatchNum: "-",
                loading: false
            });
        });
    };

    render() {
        return (
            <div className={style.workorderInfoItem}
                 onClick={() => isAdmin() ? this.props.navigate("/home/workorders/assigned-workorder") : null}>
                <p>待指派</p>
                {this.state.loading ? <Spin/> : <p>{this.state.dispatchNum}</p>}
            </div>
        );
    }
}

export default withNavigation(DispatchStatistic);
