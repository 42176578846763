import React, {Component} from "react";
import {Button, Col, Drawer, Form, Row, Space} from "antd";
import EquipmentRepositoryApi from "api/equipment/repository";
import WangEditor from "component/editor/WangEditor";
import FileUploadRender from "component/FileUploadRender";
import {ProForm, ProFormSelect} from "@ant-design/pro-components";
import EquipmentMakerApi from "api/equipment/maker";
import EquipmentListApi from "api/equipment/list";
import {setFieldsError} from "plugin/formily";

/**
 * 编辑知识库文章
 * 使用 WangEditor  富文本编辑器
 * 修改对应表单信息 调用编辑知识库文章接口 修改知识库文章信息
 */
export default class EditArticleDrawer extends Component {

    formRef = React.createRef();
    wangEditorRef = React.createRef();
    questionFilesRef = React.createRef();
    solutionWangEditorRef = React.createRef();
    solutionFilesRef = React.createRef();


    state = {
        drawerWidth: 600,
        show: false,
        loading: false,
        articleId: "",
        fileData: [],
        manufacturerOptions: [],
        equipmentOptions: []
    };

    show = id => {
        this.setState({
            show: true,
            articleId: id,
            drawerWidth: window.innerWidth < 576 ? window.innerWidth : window.innerWidth * 0.55
        }, () => {
            this.requestEquipmentManufacturer();
        });
    };

    close = () => {
        this.setState({
            show: false
        }, () => {
            this.setState({
                articleId: "",
                fileData: [],
                manufacturerOptions: [],
                equipmentOptions: []
            });
        });
    };

    requestEquipmentManufacturer = () => {
        EquipmentMakerApi.queryAll().then(res => {
            if (res.data !== undefined && res.data !== null && res.data.length > 0) {
                res.data.forEach(item => {
                    this.setState({
                        manufacturerOptions: [...this.state.manufacturerOptions, {
                            label: item.name,
                            value: item.id
                        }]
                    });
                });
            }
            this.getArticleData(this.state.articleId);
        }).catch(err => {
            console.error("Request equipment type failed", err);
        });
    };

    formManufacturerFindType = (manufacturer) => {
        EquipmentListApi.queryType({manufacturer}).then(res => {
            if (res.data !== undefined && res.data !== null && res.data.length > 0) {
                res.data.forEach(item => {
                    this.setState({
                        equipmentOptions: [...this.state.equipmentOptions, {
                            label: item.name,
                            value: item.id
                        }]
                    });
                });
            }
        }).catch(err => {
            console.error("Form manufacturer find type failed", err);
        });
    };

    onManufacturerChange = (value) => {
        this.formRef.current.setFieldsValue({devicetype: ""});
        this.setState({equipmentOptions: []});
        this.formManufacturerFindType(value);
    };

    getArticleData = id => {
        EquipmentRepositoryApi.getInfo(id).then(res => {
            this.onManufacturerChange(res.data.devicetype.id);
            this.formRef.current.setFieldsValue({
                devicetype: res.data.devicetype.name,
                manufacturer: res.data.manufacturer.name,
                tag: !res.data.tag || res.data.tag === "" ? undefined : res.data.tag
            });
            if (!!res.data && !!res.data.questionFiles) {
                const files = [];
                res.data.questionFiles.forEach((file) => {
                    files.push({
                        uid: file.id,
                        name: file.name,
                        url: file.path,
                        thumbUrl: file.path,
                        type: file.type
                    });
                });
                this.questionFilesRef.current.setFilesValue(files);
            }
            if (!!res.data && !!res.data.solutionFiles) {
                const files = [];
                res.data.solutionFiles.forEach((file) => {
                    files.push({
                        uid: file.id,
                        name: file.name,
                        url: file.path,
                        thumbUrl: file.path,
                        type: file.type
                    });
                });
                this.solutionFilesRef.current.setFilesValue(files);
            }
            this.wangEditorRef.current.setValue(res.data.question);
            this.solutionWangEditorRef.current.setValue(res.data.solution);
        }).catch(error => {
            console.error("Get article info fail", error);
        });
    };

    toggleLoading = () => this.setState({loading: !this.state.loading});

    handle = data => {
        data.question = this.wangEditorRef.current.getValue();
        data.newQuestionFiles = this.questionFilesRef.current.getFilesValue();
        data.delQuestionFiles = this.questionFilesRef.current.getDeletedFiles();
        data.solution = this.solutionWangEditorRef.current.getValue();
        data.newSolutionFiles = this.solutionFilesRef.current.getFilesValue();
        data.delSolutionFiles = this.solutionFilesRef.current.getDeletedFiles();
        data.id = this.state.articleId;
        if (!!data.tag && data.tag.length > 0) {
            data.tag = data.tag.toString();
        }
        this.toggleLoading();
        EquipmentRepositoryApi.edit(data).then(() => {
            this.toggleLoading();
            this.close();
            this.props.onSuccess();
        }).catch(err => {
            this.toggleLoading();
            if (err.status === 40000) {
                setFieldsError(err, this.formRef);
            }
        });
    };

    render() {
        return <Drawer title="编辑设备知识文章"
                       destroyOnClose
                       width={this.state.drawerWidth}
                       open={this.state.show}
                       closable={!this.state.loading}
                       onClose={this.close}>
            <ProForm formRef={this.formRef} onFinish={this.handle} layout={"vertical"} submitter={{
                resetButtonProps: {style: {display: "none"}},
                submitButtonProps: {style: {display: "none"}}
            }}>
                <ProFormSelect
                    name="manufacturer"
                    label="制造商"
                    showSearch
                    disabled
                    fieldProps={{onChange: this.onManufacturerChange}}
                    options={this.state.manufacturerOptions}
                    placeholder="请选择设备制造商"
                    rules={[{required: true, message: "请选择设备制造商"}]}
                />
                <ProFormSelect
                    name="devicetype"
                    label="设备类型"
                    showSearch
                    disabled
                    options={this.state.equipmentOptions}
                    placeholder="请选择设备类型"
                    rules={[{required: true, message: "请选择设备类型"}]}
                />
                <ProFormSelect
                    name="tag"
                    label="设备标签"
                    placeholder="输入后回车自动生成标签"
                    fieldProps={{maxTagTextLength: 10, mode: "tags"}}/>
                <Form.Item name="question" label="问题描述" required>
                    <WangEditor ref={this.wangEditorRef}/>
                </Form.Item>
                <FileUploadRender name="questionFiles" uploadPath="/resource/knowledge/question/file"
                                  ref={this.questionFilesRef} label="问题附件"/>
                <Form.Item name="solution" label="解决方案" required>
                    <WangEditor ref={this.solutionWangEditorRef} documentNodeId="solution-node"/>
                </Form.Item>
                <FileUploadRender name="solutionFiles" uploadPath="/resource/knowledge/solution/file"
                                  ref={this.solutionFilesRef} label="解决方案附件"/>
                <Row gutter={8} justify="end">
                    <Col>
                        <Space>
                            <Button disabled={this.state.loading} onClick={this.close}>取消</Button>
                            <Button loading={this.state.loading} type="primary" htmlType="submit">编辑文章</Button>
                        </Space>
                    </Col>
                </Row>
            </ProForm>
        </Drawer>;
    }
}

