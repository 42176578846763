import React from "react";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {anyUserRoles} from "../utils/securityCheck";
import Login from "../page/login";
import RemoteLogin from "../page/remotelogin"
import Home from "../page/home";
import NotFound from "../page/404/NotFound";
import routes from "./routlist"
import VideoViewer from "../page/home/remote/VideoViewer";
import {isViewRemote} from "../utils/tool";

/**
 * 渲染 路由
 * @returns {Promise<unknown>}
 */
export const renderMenuItem = () => {
    if (isViewRemote()) {
        return new Promise(resolve => {
            resolve([{

            }])
        })
    }
    return new Promise(resolve => {
        resolve(filterMenuItem(routes));
    });
};

/**
 * 过滤出可在页面渲染的 路由
 * @param routes
 * @returns {*[]}
 */
export const filterMenuItem = (routes) => {
    if (routes === null || routes === undefined || routes.length === 0) {
        return [];
    }

    const resultMenu = [];

    for (const route of routes) {
        /**
         *  item?.roles ===> 当item上没有roles属性时返回 undefined
         *  anyUserRoles 函数 当  传入参数为 undefined 时 返回true
         *  当 用户具有路由上的权限 或者 路由上没有 roles 并且 isShow 为 true 是渲染menu
         */
        if (anyUserRoles(route?.roles) && (route.isShow || route?.isShow === undefined)) {
            resultMenu.push({...route, children: filterMenuItem(route.children)});
        }
    }

    return resultMenu;

};

/**
 * 筛选 可渲染路由
 * @param route
 * @param router
 * @returns {*[]}
 */
const getRoutes = (route = routes, router = []) => {
    route.forEach(item => {
        if (!item.children) {
            /**
             *  item?.roles ===> 当item上没有roles属性时返回 undefined
             *  anyUserRoles 函数 当  传入参数为 undefined 时 返回true
             *  当 用户具有路由上的权限 或者 路由上没有 roles 属性时 渲染路由
             */
            if (anyUserRoles(item?.roles)) {
                router.push(item);
            }
        } else {
            getRoutes(item.children, router);
        }
    });

    return router;
};

/**
 * 移除首屏加载动画
 */
const removeLaunchAnimation = () => {
    const loadingContainer = document.getElementById("loading");
    if (!!loadingContainer && !!loadingContainer.parentElement) {
        loadingContainer.parentElement.removeChild(loadingContainer);
    }
};

/**
 * 路由渲染
 * @returns {JSX.Element}
 */
export const renderRoutes = () => {
    // 调用移除首屏加载动画方法
    removeLaunchAnimation();
    return <Router>
        <Routes>
            <Route path="/" element={<Login/>}/>
            <Route path="/login" element={<Login/>}/>
            <Route path="/remotelogin" element={<RemoteLogin/>}/>
            {/*<Route path="/home/remote/video-viewer" element={<VideoViewer/>}/>*/}
            <Route path="/home" element={<Home/>}>
                {getRoutes().map(item => <Route path={item.path} element={item.component} key={item.path}/>)}</Route>
            <Route path="*" element={<NotFound/>}/>
        </Routes>
    </Router>

};
