import React, {Component} from "react";
import {Button, Col, Drawer, Form, Input, InputNumber, message, Radio, Row, Select, Space} from "antd";

import {setFieldsError} from "plugin/formily";
import FileUploadRender from "component/FileUploadRender";

import StorehouseTypeApi from "api/spares/type";
import EquipmentMakerApi from "api/equipment/maker";
import ExportAlreadyDeviceType from "page/home/spares/type/modal/ExportAlreadyDeviceType";

class EditStorehouseTypeDrawer extends Component {
    state = {
        show: false,
        loading: false,
        drawerWidth: 600,
        equipmentId: "",
        selectOption: []
    };
    formRef = React.createRef();
    filesRef = React.createRef();
    exportAlreadyDeviceTypeRef = React.createRef();

    show = id => this.setState({
        show: true,
        equipmentId: id,
        drawerWidth: window.innerWidth < 576 ? window.innerWidth : window.innerWidth * 0.55
    }, () => {
        this.requestStorehouseData(id);
        this.getMaker();
    });

    close = () => this.setState({
        show: false,
        loading: false
    });

    toggleLoading = () => this.setState({loading: !this.state.loading});

    requestStorehouseData = id => {
        StorehouseTypeApi.find(id).then(response => {
            this.formRef.current.setFieldsValue({
                name: response.data.name,
                price: response.data.price,
                model: response.data.model,
                manufacturer: response.data.manufacturer.id,
                description: response.data.description,
                classified: response.data.classified
            });
            if (!!response.data && !!response.data.files) {
                const files = [];
                response.data.files.forEach(file => {
                    files.push({
                        uid: file.id,
                        name: file.name,
                        url: file.path,
                        thumbUrl: file.path,
                        type: file.type
                    });
                });
                this.filesRef.current.setFilesValue(files);
            }
        }).catch(error => {
            console.error("Request storehouse type detail info", error);
        });
    };

    /**
     * 获取所有制造商
     */
    getMaker = () => {
        EquipmentMakerApi.queryAll().then(res => {
            let selectData = [];
            res.data.forEach(item => {
                selectData.push({label: item.name, value: item.id});
            });
            this.setState({
                selectOption: selectData
            });
        }).catch(err => {
            console.error("Get maker fail", err);
        });
    };

    /**
     * 选择设备类型回调
     * @param deviceType
     */
    callback = (deviceType) => {
        if (deviceType) {
            this.formRef.current.setFieldsValue({
                name: deviceType?.name,
                model: deviceType?.model,
                manufacturer: deviceType?.manufacturerId
            });
        }
    };

    /**
     * 导入已有设备类型
     */
    renderExportBtn = () => <Button type="primary"
                                    onClick={() => this.exportAlreadyDeviceTypeRef.current.show()}>导入已有设备类型</Button>;


    handle = data => {
        this.toggleLoading();
        data.id = this.state.equipmentId;
        data.newFiles = this.filesRef.current.getFilesValue();
        data.delFiles = this.filesRef.current.getDeletedFiles();
        StorehouseTypeApi.edit(data).then(response => {
            this.close();
            if (response.status === 20000 && !!this.props.onSuccess) {
                this.props.onSuccess();
            }
        }).catch(error => {
            if (error.status === 40000) {
                setFieldsError(error, this.formRef);
            } else {
                message.error({key: "failed", content: [error.data]});
            }
            this.toggleLoading();
            console.error("Create equipment error", error);
        });
    };

    render() {
        return <Drawer title="编辑备件类型"
                       destroyOnClose
                       width={this.state.drawerWidth}
                       open={this.state.show}
                       extra={this.renderExportBtn()}
                       closable={!this.state.loading}
                       onClose={this.close}>
            <Form ref={this.formRef} onFinish={this.handle} layout={"vertical"}>
                <Row gutter={[8, 0]} align="middle">
                    <Col span={12}>
                        <Form.Item name="name" label="类型名称" required
                                   rules={[{required: true, message: "请输入类型名称"},
                                       {pattern: /\S/g, message: "内容不能为空"}]}>
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="model" label="设备型号" required
                                   rules={[{required: true, message: "请输入设备型号"},
                                       {pattern: /\S/g, message: "内容不能为空"}]}>
                            <Input/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[8, 0]}>
                    <Col span={10}>
                        <Form.Item name="manufacturer" label="制造商" required
                                   rules={[{required: true, message: "请选择制造商"},
                                       {pattern: /\S/g, message: "内容不能为空"}]}>
                            <Select options={this.state.selectOption} optionFilterProp="label" showSearch/>
                        </Form.Item>
                    </Col>
                    <Col span={7}>
                        <Form.Item name="classified" label="是否SM" required
                                   rules={[{required: true, message: "请选择是否SM"}]}>
                            <Radio.Group onChange={this.radioChange} value={this.state.radioValue}>
                                <Radio value={0}>普通机</Radio>
                                <Radio value={1}>专用机</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                    <Col span={7}>
                        <Form.Item name="price" label="价格(元)" required
                                   rules={[{required: true, message: "请输入价格"},
                                       {pattern: /\S/g, message: "内容不能为空"}]}>
                            <InputNumber min={0} defaultValue={0} style={{width: "100%"}}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[8, 0]}>
                    <Col span={24}>
                        <Form.Item name="description" label="类型说明">
                            <Input.TextArea/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <FileUploadRender name="files" uploadPath="/repository/stocktype/file"
                                          ref={this.filesRef}/>
                    </Col>
                </Row>
                <Row gutter={8} justify="end">
                    <Col>
                        <Space>
                            <Button disabled={this.state.loading} onClick={this.close}>取消</Button>
                            <Button loading={this.state.loading} type="primary" htmlType="submit">编辑</Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
            <ExportAlreadyDeviceType ref={this.exportAlreadyDeviceTypeRef} onSuccess={this.callback}/>
        </Drawer>;
    }
}

export default EditStorehouseTypeDrawer;
