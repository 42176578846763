import React from "react";
import {Button, Drawer, Space} from "antd";
import {ProDescriptions} from "@ant-design/pro-components";

import WorkorderFileRender from "component/DescriptionFileRender";

import PrepareToSmpWorkorderDrawer from "./PrepareToSmpWorkorderDrawer";
import PrepareToOpsWorkorderDrawer from "./PrepareToOpsWorkorderDrawer";
import PreviewOpsWorkorderDrawer from "../../workorder/drawer/PreviewOpsWorkorderDrawer";
import PreviewSmpWorkorderDrawer from "../../workorder/drawer/PreviewSmpWorkorderDrawer";
import {getUser} from "component/SecurityInterceptor";

/**
 * 报修工单预览弹窗 Drawer
 *
 * @description 预览报修工单弹窗
 *
 * @author  Mae Carder
 * @version 1.0.0
 * @since 2021-11-1
 */
export default class PreviewPrepareWorkorderDrawer extends React.Component {

    state = {
        drawerWidth: 280,
        show: false,
        workorderId: "",
        workorder: {}
    };

    previewOpsRef = React.createRef();
    previewSmpRef = React.createRef();
    /**
     * 创建信创工单弹窗引用
     */
    prepareToSmpDrawer = React.createRef();

    /**
     * 创建运维工单弹窗引用
     */
    prepareToOpsDrawer = React.createRef();

    show = id => this.setState({
        show: true,
        workorderId: id,
        drawerWidth: window.innerWidth < 576 ? window.innerWidth : window.innerWidth * 0.55
    });

    renderToolbar = () => this.state.workorder.converted === 0 ? (<Space>
        <Button onClick={() => {
            this.prepareToSmpDrawer.current.show(this.state.workorder);
        }}>转为信创工单
        </Button>
        {/*2022/09/14 为俩用户专门添加发起运维工单权限 (愚蠢)*/}
        {getUser().departments[0].id === "155562326307770368" || ["168955227586826240", "264588201702854656"].some(item => item === getUser().id) ?
            <Button onClick={() => {
                this.prepareToOpsDrawer.current.show(this.state.workorder);
            }}>转为运维工单
            </Button> : null}
    </Space>) : <Button type="primary" onClick={this.previewWorkorder}>查看工单</Button>;

    /**
     * 查看工单详情
     */
    previewWorkorder = () => {
        this.state.workorder.type === "smp" ? this.previewSmpRef.current.show(this.state.workorder.id) :
            this.previewOpsRef.current.show(this.state.workorder.id);
    };

    /**
     * 请求保修工单信息
     */
    requestWorkorder = () => global.axios.get(`/workorder/workorder/prepare/${this.state.workorderId}`)
        .then(response => {
            this.setState({
                workorder: response.data
            });
            return {success: true, data: response.data};
        })
        .catch(error => console.warn("Get workorder failed", error));

    close = () => this.setState({show: false});

    onChanged = () => {
        this.close();
        if (!!this.props.onSuccess) {
            this.props.onSuccess();
        }
    };

    render() {
        return <Drawer title="查看报修工单" width={this.state.drawerWidth}
                       open={this.state.show} onClose={this.close}
                       destroyOnClose>

            <ProDescriptions bordered size="small" layout="horizontal"
                             column={window.innerWidth < 800 ? 1 : 4}
                             title={`工单编号：${this.state.workorderId}`}
                             request={this.requestWorkorder}
                             extra={this.renderToolbar()}>

                <ProDescriptions.Item label="ID" dataIndex="id" span={4}/>

                <ProDescriptions.Item label="客户姓名" span={4} dataIndex="clientName"/>

                <ProDescriptions.Item label="客户手机号码" span={4} dataIndex="clientCellphone"/>

                <ProDescriptions.Item label="客户地址" span={4} dataIndex="clientAddress"/>

                <ProDescriptions.Item label="预约时间" span={4} dataIndex="appointmentTime"/>

                <ProDescriptions.Item label="提交 IP 地址" span={4} dataIndex="remoteAddress"/>

                <ProDescriptions.Item label="创建时间" span={4} dataIndex="createTime"/>

                <ProDescriptions.Item label="修改时间" span={4} dataIndex="modifyTime"/>

                <ProDescriptions.Item label="工单描述" span={4} dataIndex="description" valueType="textarea"/>

                <ProDescriptions.Item label="附件" span={4}>
                    <WorkorderFileRender file={this.state.workorder.files}/>
                </ProDescriptions.Item>
            </ProDescriptions>

            <PrepareToSmpWorkorderDrawer ref={this.prepareToSmpDrawer} onSuccess={this.onChanged}/>
            <PrepareToOpsWorkorderDrawer ref={this.prepareToOpsDrawer} onSuccess={this.onChanged}/>
            <PreviewOpsWorkorderDrawer ref={this.previewOpsRef} onSuccess={this.onChanged}/>
            <PreviewSmpWorkorderDrawer ref={this.previewSmpRef} onSuccess={this.onChanged}/>
        </Drawer>;
    }
}
