import React, {Component} from "react";
import {ProCard} from "@ant-design/pro-components";
import {Bar} from "@antv/g2plot";
import workorderInfoApi from "api/workorderInfo";
import {Skeleton} from "antd";
import {convertWorkorderType} from "../WorkorderType";
import "./index.css";

/**
 * 信创问题统计图
 */
class EvaluationChart extends Component {
    state = {
        data: [],
        satisfiedNumber: 0,
        verySatisfiedNumber: 0,
        notSatisfiedNumber: 0,
        total: 0,
        loading: true
    };

    componentDidMount() {
        this.getFeedbackData();
    }

    getFeedbackData = () => {
        workorderInfoApi.feedbackData().then(res => {
            const columnData = [];
            res.data.forEach(item => {
                item.type = convertWorkorderType(item.type);
                let obj = {};
                obj.count = item.count;
                if (item.resourceName !== "" && item.resourceType !== "") {
                    obj.type = item.resourceType + " " + item.resourceName + " " + item.type;
                } else {
                    obj.type = item.type;
                }
                columnData.push(obj);
            });
            this.setState({
                data: columnData,
                loading: false
            }, () => {
                if (this.state.data.length > 0) {
                    const columnPlot = new Bar("container", {
                        data: this.state.data,
                        xField: "count",
                        yField: "type",
                        label: {
                            position: "middle",
                            style: {
                                fill: "#FFFFFF",
                                opacity: 0.6
                            }
                        }
                    });
                    columnPlot.render();
                }
            });
        })
    };

    render() {
        return <ProCard colSpan={{xs: 24, sm: 12}} gutter={12} wrap title="信创问题统计" headerBordered layout="center"
                        bordered size="small" style={{height: "100%", minHeight: "280px"}}>
            <Skeleton active loading={this.state.loading}>
                {this.state.data.length > 0 ?
                    <div id="container" style={{width: "100%", height: "100%"}}/> :
                    <span>暂无数据...</span>
                }
            </Skeleton>
        </ProCard>;
    }
}

export default EvaluationChart;
