import ReactDOM from 'react-dom/client';
import './config'
import {renderRoutes} from "./route";
import "antd/dist/reset.css";
import "./plugin/axios";
import "./plugin/formily";
import zhCN from 'antd/locale/zh_CN';
import {ConfigProvider} from "antd";
import 'dayjs/locale/zh-cn';
import {legacyLogicalPropertiesTransformer, StyleProvider} from "@ant-design/cssinjs";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<StyleProvider hashPriority="high" transformers={[legacyLogicalPropertiesTransformer]}>
    <ConfigProvider locale={zhCN}>
        {renderRoutes()}
    </ConfigProvider>
</StyleProvider>);
