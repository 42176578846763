import React, {Component} from "react";
import {PageContainer, ProTable} from "@ant-design/pro-components";
import {Button, Tag, Tooltip} from "antd";
import {DeleteOutlined, EditOutlined, EyeOutlined} from "@ant-design/icons";

import EquipmentRepositoryApi from "api/equipment/repository";

import DeleteArticleModal from "./modal/DeleteArticleModal";
import PreviewArticleDrawer from "./drawer/PreviewArticleDrawer";
import EditArticleDrawer from "./drawer/EditArticleDrawer";
import CreateKnowledgeArticleDrawer from "./drawer/CreateKnowledgeArticleDrawer";
import CreateIdBecomeCreateName from "component/CreateIdBecomeCreateName";
import RenderFormSelectItem from "../../../../component/RenderFormSelectItem";
import EquipmentTypeApi from "../../../../api/equipment/type";

export default class RepositoryPage extends Component {

    previewArticleRef = React.createRef();
    editArticleRef = React.createRef();
    deleteArticleRef = React.createRef();
    createArticleRef = React.createRef();

    columns = [
        {
            title: "ID",
            hideInSearch: true,
            hideInTable: true,
            dataIndex: "id",
            editable: false,
        },
        {
            title: "设备类型",
            dataIndex: ["devicetype", "name"],
            hideInSearch: true,
        },
        {
            title: "设备类型",
            dataIndex: "devicetype",
            renderFormItem: (_, item) => item.type === "form" ? null : (
                <RenderFormSelectItem requestapi={EquipmentTypeApi.selectAll} {...item.fieldProps}/>),
            hideInTable: true
        },
        {
            title: "类型型号",
            dataIndex: ["devicetype", "model"],
            hideInSearch: true
        },
        {
            title: "制造商名称",
            dataIndex: ["manufacturer", "name"],
            hideInSearch: true
        },
        {
            title: "问题描述",
            dataIndex: "questionKeyword",
            hideInTable: true,
            ellipsis: true
        },
        {
            title: "问题描述",
            dataIndex: "question",
            ellipsis: true,
            render: (node, entity, index, action) => {
                const parser = new DOMParser();
                const parsedHtml = parser.parseFromString(node.props.children, 'text/html');
                const stringHtml = parsedHtml.documentElement.textContent;
                return <Tooltip placement="topLeft"
                                title={<p dangerouslySetInnerHTML={{__html: node.props.children}}/>}>
                    <p style={{overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis',}}>
                        {stringHtml}
                    </p>
                </Tooltip>
            },
            hideInSearch: true
        },
        {
            title: "解决方案",
            dataIndex: "solution",
            ellipsis: true,
            render: (node, entity, index, action) => {
                const parser = new DOMParser();
                const parsedHtml = parser.parseFromString(node.props.children, 'text/html');
                const stringHtml = parsedHtml.documentElement.textContent;
                return <Tooltip placement="topLeft"
                                title={<p dangerouslySetInnerHTML={{__html: node.props.children}}/>}>
                    <p style={{
                        width: '100%',
                        display: 'inline-block',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                    }}>
                        {stringHtml}
                    </p>
                </Tooltip>
            },
            hideInSearch: true,
        },
        {
            title: '适配',
            dataIndex: 'tag',
            render: (_, record) => {
                if (!record.tag) {
                    return null;
                }
                const tags = record.tag.split(",");
                return <>
                    {
                        tags.map((item, index) => <Tag color="#108ee9" id={index}>{item}</Tag>)
                    }
                </>
            },
        },
        {
            title: "创建人",
            dataIndex: "creatorName",
            hideInSearch: true
        },
        {
            title: "创建时间",
            dataIndex: "createTime",
            hideInSearch: true
        },
        {
            title: "操作",
            valueType: "option",
            render: (node, entity, index, action) => this.renderColumnOperations(node, entity, index, action)
        }
    ];

    renderColumnOperations = (node, entity, index, action) => {
        return [
            <Tooltip title="查看文章" key="preview">
                <Button icon
                        size="small"
                        onClick={() => this.previewArticleRef.current.show(entity)}>
                    <EyeOutlined/>
                </Button>
            </Tooltip>,
            <Tooltip title="编辑文章" key="edit">
                <Button icon
                        size="small"
                        onClick={() => this.editArticleRef.current.show(entity.id)}>
                    <EditOutlined/>
                </Button>
            </Tooltip>,
            <Tooltip title="删除文章" key="delete">
                <Button icon
                        danger
                        size="small"
                        onClick={() => this.deleteArticleRef.current.show(entity.id)}>
                    <DeleteOutlined/>
                </Button>
            </Tooltip>
        ];
    };


    requestPage = data => {
        return EquipmentRepositoryApi.page(data).then(async response => {
            let page = await CreateIdBecomeCreateName(response.data.records, "creatorId");
            return {data: page, success: true, total: response.data.total};
        }).catch(error => {
            console.warn("Request get equipment repository pages failed.", error);
        });
    };

    toolbarRender = () => <Button type="primary"
                                  onClick={() => this.createArticleRef.current.show()}>新建知识库</Button>;

    reload = () => this.actionRef?.reload();

    render() {
        return <PageContainer header={{title: "知识库维护", breadcrumb: {}}} extra={this.toolbarRender()}>
            <ProTable
                headerTitle="知识库"
                rowKey="id"
                defaultSize="small"
                options={{reload: true, setting: true, density: true}}
                actionRef={actionRef => this.actionRef = actionRef}
                columns={this.columns}
                request={this.requestPage}/>
            <DeleteArticleModal ref={this.deleteArticleRef} onSuccess={this.reload}/>
            <PreviewArticleDrawer ref={this.previewArticleRef} onSuccess={this.reload}/>
            <EditArticleDrawer ref={this.editArticleRef} onSuccess={this.reload}/>
            <CreateKnowledgeArticleDrawer ref={this.createArticleRef} onSuccess={this.reload}/>
        </PageContainer>;
    }
}
