import React from "react";
import {Button, Cascader, Col, Form, Input, message, Row, Space} from "antd";

import CustomerDepartmentApi from "api/customer/department";
import {setFieldsError} from "plugin/formily";
import DraggableModal from "component/DraggableModal";

/**
 * 编辑客户单位弹窗 Modal
 *
 * @description 编辑客户单位
 *
 * @author huang Carder
 * @since 2021-06-23
 * @version 1.0.0
 */
export default class UpdateCustomerDepartmentModal extends React.Component {

    formRef = React.createRef();
    draggableRef = React.createRef();

    state = {
        show: false,
        loading: false,
        departmentId: "",
        oldDepartmentInformation: {}
    };

    show = id => {
        this.draggableRef.current.show();
        this.setState({departmentId: id}, this.getDepartmentInfo);
    };

    close = () => {
        this.setState({loading: false});
        this.draggableRef.current.close();
    };


    /**
     * 控制 loading 加载效果 显示
     */
    toggleLoading = () => this.setState({loading: !this.state.loading});

    /**
     * 获取客户单位信息
     */
    getDepartmentInfo = () => {
        CustomerDepartmentApi.find(this.state.departmentId).then(response => {
            let params = {
                statue: response.data.statue,
                name: response.data.name,
                address: response.data.address,
                ares: []
            };
            params.ares[0] = response.data.province;
            params.ares[1] = response.data.city;
            params.ares[2] = response.data.county;
            this.formRef.current.setFieldsValue(params);
            this.setState({oldDepartmentInformation: response.data});
        }).catch(error => {
            console.warn("Get department info", error);
        });
    };

    /**
     * 判断客户单位信息是否更改
     * @param param
     * @returns {boolean}
     */
    isNecessaryToHandle = param => {
        const old = {
            statue: undefined,
            name: this.state.oldDepartmentInformation.name,
            address: this.state.oldDepartmentInformation.address,
            ares: param.ares,
            id: this.state.oldDepartmentInformation.id,
            province: this.state.oldDepartmentInformation.province,
            city: this.state.oldDepartmentInformation.city,
            county: this.state.oldDepartmentInformation.county

        };

        if (Object.entries(param).toString() === Object.entries(old).toString()) {
            return true;
        }
    };

    /**
     * 更新客户单位
     * @param data   表单数据
     */
    handle = data => {
        data.id = this.state.departmentId;
        data.province = data.ares[0];
        data.city = data.ares[1];
        data.county = data.ares[2];
        if (this.isNecessaryToHandle(data)) {
            this.close();
            return;
        }
        this.toggleLoading();
        CustomerDepartmentApi.update(data).then(response => {
            this.close();
            this.props.onSuccess(response.data);
        }).catch(error => {
            this.toggleLoading();
            console.warn("Update department failed", error);
            if (error.status === 40000) {
                setFieldsError(error, this.formRef);
                return;
            }
            message.error(error.data)
        });
    };

    render() {
        return <DraggableModal onCancel={this.close} title="更新单位" ref={this.draggableRef}>

            <Form ref={this.formRef} onFinish={this.handle} layout="vertical">
                <Form.Item name="ares" label="单位区域" required
                           rules={[{required: true, message: "请选择单位区域"}]}>
                    <Cascader options={require("asset/location.json")}/>
                </Form.Item>
                <Form.Item name="address" label="详细地址" required
                           rules={[{required: true, message: "请输入详细地址"}]}>
                    <Input/>
                </Form.Item>
                <Form.Item name="name" label="名称" required
                           rules={[{required: true, message: "请输入名称"}]}>
                    <Input/>
                </Form.Item>
                <Row gutter={8} justify="end">
                    <Col>
                        <Space>
                            <Button disabled={this.state.loading} onClick={this.close}>取消</Button>
                            <Button loading={this.state.loading} type="primary" htmlType="submit">更新</Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </DraggableModal>;
    }
}
