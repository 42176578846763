import React, {Component} from "react";
import {ProTable,PageContainer} from "@ant-design/pro-components";
import {Button, Space, Tooltip} from "antd";
import {DeleteOutlined, EditOutlined, EyeOutlined, FolderViewOutlined, PlusSquareOutlined} from "@ant-design/icons";

import EquipmentTypeApi from "api/equipment/type";

import CreateEquipmentTypeDrawer from "./drawer/CreateEquipmentTypeDrawer";
import DeleteEquipmentTypeModal from "./modal/DeleteEquipmentTypeModal";
import EditEquipmentTypeDrawer from "./drawer/EditEquipmentTypeDrawer";
import PreviewEquipmentTypeDrawer from "./drawer/PreviewEquipmentTypeDrawer";
import PreviewKnowledgeArticleDrawer from "./drawer/PreviewKnowledgeArticleDrawer";
import CreateKnowledgeArticleDrawer from "../repository/drawer/CreateKnowledgeArticleDrawer";
import DictApi from "../../../../api/dict";
import EnumDict from "../../../../enum/dict";
import CustomerApi from "../../../../api/customer/customer";

class EquipmentType extends Component {
    state = {
        commonTypeOption:[]
    };
    columns = [
        {
            title: "ID",
            hideInSearch: true,
            hideInTable: true,
            dataIndex: "id",
            editable: false
        },
        {
            title: "类型名称",
            dataIndex: "name"
        },
        {
            title: "设备型号",
            dataIndex: "model"
        },
        {
            title: "设备大类",
            dataIndex: "commonType",
            hideInSearch: true,
            render: (value, entity) => {
                return this.state.commonTypeOption.find(item => item.value === entity.commonType).label;
            }
        },
        {
            title: "设备大类",
            formItemProps: {name: "commonType"},
            valueType: "select",
            hideInTable: true,
            fieldProps: {
                showSearch: true,
                debounce: 500,
            },
            request: () => {
                return DictApi.list({code : EnumDict.DEVICE_COMMON_TYPE}).then(res => {
                    let selectData = [];
                    res.data.forEach(item => {
                        selectData.push({label: item.value, value: item.id});
                    });
                    return selectData;
                }).catch(() => [])
            },

        },
        {
            title: "创建人",
            hideInTable: true,
            dataIndex: "creator",
            hideInSearch: true
        },
        {
            title: "创建时间",
            dataIndex: "createTime",
            sorter: (a, b) => a.createTime.localeCompare(b.createTime),
            hideInSearch: true
        },
        {
            title: "编辑人",
            hideInTable: true,
            dataIndex: "modifier",
            hideInSearch: true
        },
        {
            title: "编辑时间",
            hideInTable: true,
            dataIndex: "modifyTime",
            hideInSearch: true
        },
        {
            title: "是否SM",
            dataIndex: "classified",
            valueEnum: {
                "0": "否",
                "1": "是"
            },
            hideInSearch: true
        },
        {
            title: "操作",
            valueType: "option",
            render: (node, entity, index, action) => this.renderColumnOperations(node, entity, index, action)
        }
    ];

    createEquipmentTypeRef = React.createRef();
    editEquipmentTypeRef = React.createRef();
    deleteEquipmentTypeRef = React.createRef();
    previewEquipmentTypeRef = React.createRef();
    previewKnowledgeRef = React.createRef();
    createKnowledgeRef = React.createRef();

    renderColumnOperations = (node, entity, index, action) => {
        return <Space>
            <Tooltip title="查看类型">
                <Button icon
                        size="small"
                        onClick={() => this.previewEquipmentTypeRef.current.show(entity.id)}>
                    <EyeOutlined/>
                </Button>
            </Tooltip>
            <Tooltip title="编辑类型">
                <Button icon
                        size="small"
                        onClick={() => this.editEquipmentTypeRef.current.show(entity.id)}>
                    <EditOutlined/>
                </Button>
            </Tooltip>
            <Tooltip title="新建知识库文章">
                <Button icon size="small"
                        onClick={() => this.createKnowledgeRef.current.show(entity.manufacturer.id,entity.id)}><PlusSquareOutlined/></Button>
            </Tooltip>
            <Tooltip title="查看当前类型知识库文章">
                <Button icon size="small"
                        onClick={() => this.previewKnowledgeRef.current.show(entity.id)}>
                    <FolderViewOutlined/>
                </Button>
            </Tooltip>
            <Tooltip title="删除类型">
                <Button icon
                        danger
                        size="small"
                        onClick={() => this.deleteEquipmentTypeRef.current.show(entity.id)}>
                    <DeleteOutlined/>
                </Button>
            </Tooltip>
        </Space>;
    };

    /**
     * 获取设备大类列表
     * @returns {Promise<void>}
     */

    getCommonType = () => {
        DictApi.list({code : EnumDict.DEVICE_COMMON_TYPE}).then(res => {
            let selectData = [];
            res.data.forEach(item => {
                selectData.push({label: item.value, value: item.id});
            });
            this.setState({
                commonTypeOption: selectData
            });
        })
    };
    componentDidMount() {
        this.getCommonType();
    }


    toolbarRender = () => <Button type="primary" onClick={() => this.createEquipmentTypeRef.current.show()}>新建类型</Button>;

    requestPage = data => {
        return EquipmentTypeApi.page(data).then(response => {
            return {data: response.data.records, success: true, total: response.data.total};
        }).catch(error => {
            console.warn("Request get equipment type pages failed.", error);
        });
    };

    reload = () => this.actionRef?.reload();

    render() {
        return <PageContainer header={{title: "设备类型", breadcrumb: {}}} extra={this.toolbarRender()}>
            <ProTable headerTitle="设备类型"
                      rowKey="id"
                      defaultSize="small"
                      options={{reload: true, setting: true, density: true}}
                      actionRef={actionRef => this.actionRef = actionRef}
                      columns={this.columns}
                      request={this.requestPage}/>
            <CreateEquipmentTypeDrawer onSuccess={this.reload} ref={this.createEquipmentTypeRef}/>
            <DeleteEquipmentTypeModal onSuccess={this.reload} ref={this.deleteEquipmentTypeRef}/>
            <EditEquipmentTypeDrawer onSuccess={this.reload} ref={this.editEquipmentTypeRef}/>
            <PreviewEquipmentTypeDrawer onSuccess={this.reload} ref={this.previewEquipmentTypeRef}/>
            <PreviewKnowledgeArticleDrawer onSuccess={this.reload} ref={this.previewKnowledgeRef}/>
            <CreateKnowledgeArticleDrawer onSuccess={this.reload} ref={this.createKnowledgeRef}/>
        </PageContainer>;
    }
}

export default EquipmentType;
