import { getAppToken } from '../../../../../../utils/request';
import {
  client,
  currentUserInfo,
  globalFlag,
  localChannelInfo,
  mainViewPrefer,
  remoteChannelInfo,
} from '../../../../../../store';
import { print } from '../../../../../../utils/tools';
import DingRTC from 'dingrtc';
import { Button, Card, Form, Input, Toast } from 'dingtalk-design-desktop';
import {useCallback, memo, useState, useEffect} from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import styles from '../index.module.less';
import { useForm } from 'dingtalk-design-desktop/lib/form/Form';
import {getUser} from "../../../../../../utils/securityCheck";
import {generateRandomString, urlParamsToObj} from "../../../../../../utils/tool";

const Join = () => {
  const setGlobalData = useSetRecoilState(globalFlag);
  const [
    { userName, userId, channel, appId },
    setCurrentUserInfo,
  ] = useRecoilState(currentUserInfo);

  const clientInfo = useRecoilValue(localChannelInfo);
  const setMainPrefer = useSetRecoilState(mainViewPrefer);
  const setRemoteChannelInfo = useSetRecoilState(remoteChannelInfo);
  const newClient = useRecoilValue(client);
  const setLocalChannelInfo = useSetRecoilState(localChannelInfo);
  const initialValues = {
    appId,
    userId:getUser().id,
    userName:getUser().username,
    channelName: localStorage.getItem("relevancesn") || channel, //设备识别码
  };

  const [joining, setJoining] = useState(false);

  const [form] = useForm();
  localStorage.setItem("isWatch", "1")
  const onJoin = useCallback(async () => {
    const {
      userId: uid,
      appId: app,
      channelName,
      userName: name,
    } = await form.getFieldsValue();

    if (!uid || !app || !channelName || !name) {
      Toast.error('请检查参数填写');
      return;
    }

    localStorage.setItem('onLeave', true)
    setJoining(true);
    try {
      let appTokenResult = await getAppToken(uid, app, channelName);
      const loginParam = {
        appId: app,
        userId: uid,
        userName,
        channelName,
        appToken: appTokenResult.data,
      };
      if (appTokenResult.gslb?.length) {
        DingRTC.setClientConfig({ gslb: appTokenResult.gslb });
      }
      try {
        const result = await newClient.join({
          appId: loginParam.appId,
          token: loginParam.appToken,
          uid: loginParam.userId,
          channel: loginParam.channelName,
          userName: loginParam.userName,
        });
        setJoining(false);
        setCurrentUserInfo({
          userId: uid,
          appId: app,
          userName,
          channel: channelName,
        });
        setLocalChannelInfo((prev) => ({ ...prev, timeLeft: result.timeLeft }));
        //Toast.success('加入房间成功');
        setRemoteChannelInfo((prev) => ({ ...prev, remoteUsers: result.remoteUsers }));
        const subParams = [
          { uid: 'mcu', mediaType: 'audio', auxiliary: false }
        ];
        for (const user of result.remoteUsers) {
          if (user.hasAuxiliary) {
            subParams.push({ uid: user.userId, mediaType: 'video', auxiliary: true });
          }
          if (user.hasVideo) {
            subParams.push({ uid: user.userId, mediaType: 'video', auxiliary: false });
          }
        }
        let mainPrefer;
        const tasks = [];
        const subTask = newClient.batchSubscribe(subParams).then((batchSubscribeResult) => {
          for (const { error, track, uid: usrId, auxiliary } of batchSubscribeResult) {
            if (error) {
              // Toast.info(
              //     `subscribe user ${usrId} ${auxiliary ? 'screenShare' : 'camera'} failed: ${JSON.stringify(error)}`,
              // );
              print(
                  `subscribe user ${usrId} ${auxiliary ? 'screenShare' : 'camera'} failed: ${JSON.stringify(error)}`,
              );
              continue;
            }
            if (track.trackMediaType === 'audio') {
              print(`subscribe ${usrId} audio`);
              const audioTrack = track;
              setRemoteChannelInfo((prev) => ({ ...prev, mcuAudioTrack: audioTrack }));
              setGlobalData((pre) => ({ ...pre, mcuAudioSubscribed: true }));
              audioTrack.play();
            } else {
              if (!mainPrefer) {
                mainPrefer = { userId: usrId, prefer: auxiliary ? 'auxiliary' : 'camera' };
              }
              print(`subscribe user ${usrId} ${auxiliary ? 'screenShare' : 'camera'}`);
              setRemoteChannelInfo((prev) => ({
                ...prev,
                remoteUsers: [...newClient.remoteUsers],
              }));
            }
          }
          if (clientInfo.cameraTrack && !mainPrefer) {
            clientInfo.cameraTrack.stop();
            mainPrefer = { userId: uid, prefer: 'camera' };
          }
          if (mainPrefer) {
            setMainPrefer(mainPrefer)
          }
        });
        tasks.push(subTask);
        const localTracks = [clientInfo.cameraTrack, clientInfo.micTrack].filter((item) => !!item);
        if (localTracks.length) {
          const pubTask = newClient
              ?.publish(localTracks)
              .then(() => {
                setLocalChannelInfo((prev) => ({
                  ...prev,
                  publishedTracks: [...newClient.localTracks],
                }));
                print(`publish ${localTracks.map((item) => item.trackMediaType)} tracks`);
              })
              .catch((e) => {
                Toast.info(
                    `publish ${localTracks.map((item) => item.trackMediaType)} tracks failed: ${JSON.stringify(e)}`,
                );
                print(
                    `publish ${localTracks.map((item) => item.trackMediaType)} tracks failed: ${JSON.stringify(e)}`,
                );
                throw e;
              });
          tasks.push(pubTask);
        }
        Promise.all(tasks).finally(() => {
          setGlobalData((pre) => ({ ...pre, joined: true }));
        })
      } catch (e) {
        setJoining(false);
        setGlobalData((pre) => ({ ...pre, joined: false }));
        Toast.error(`加入房间失败${e?.reason || e?.message || JSON.stringify(e)}`);
      }
    } catch (error) {
      setGlobalData((pre) => ({ ...pre, joined: false }));
      setJoining(false);
      Toast.error(`访问appServer失败${JSON.stringify(error)}`);
      print('error to join', error);
    }
  }, [form, clientInfo]);

  const onFormChange = useCallback((field) => {
    const [item] = field;
    if (item.name[0] !== 'userName') return;
    setCurrentUserInfo((prev) => ({ ...prev, userName: item.value }));
  }, []);

  /**
   * 判断缓存 localStorage 是否有watch 定时一次次
   */
  useEffect(
      () => {
        // 获取地址栏中的参数
        const params = urlParamsToObj(window.location.href);
        console.log('---------------------------')
        console.log(params)
        form.setFieldsValue(
            {
              appId,
              userId:getUser().id || generateRandomString(5),
              channelName: localStorage.getItem("relevancesn") || params.channel || '',
              userName: params.userName || generateRandomString(5),
            }
        )

        // const timerId = setTimeout(() => {
        //   console.log('定时任务执行了');
        //   // 这里可以调用 onJoin() 或其他你需要执行的函数
        //   onJoin();
        //   clearTimeout(timerId);
        // }, 2000); // 1000 毫秒后执行
        if (params.watch === "1") {
          onJoin();
        }
      },
      []
  )

  return (
      <Card className={styles.joinPanel} title="加入频道">
        <Form
            layout="vertical"
            form={form}
            initialValues={initialValues}
            onFieldsChange={onFormChange}
        >
          <Form.Item name="appId" required label="应用Id" hidden>
            <Input placeholder="请输入appId" />
          </Form.Item>
          <Form.Item name="userId" required label="用户Id">
            <Input placeholder="请输入uid" />
          </Form.Item>
          <Form.Item name="channelName" required label="频道">
            <Input placeholder="请输入channelId" />
          </Form.Item>
          <Form.Item name="userName" required label="用户名">
            <Input placeholder="请输入userName" />
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit" size="large" type="primary" disabled={joining} onClick={onJoin}>
              加入
            </Button>

          </Form.Item>
        </Form>
      </Card>
  );
};

export default memo(Join);
