import React, {Component} from "react";
import {Spin} from "antd";

import workorderInfoApi from "api/workorderInfo";
import {isAdmin} from "component/SecurityInterceptor";
import {withNavigation} from "../../../../../utils/RouteLink";

import style from "./index.module.css";

/**
 * 服务概览
 * @description 工单总数统计
 */
class WorkorderStatistic extends Component {
    state = {
        loading: false,
        workorderTotal: ""
    };

    componentDidMount() {
        this.getWorkorderTotal();
    };


    getWorkorderTotal = () => {
        this.setState({
            loading: true
        });
        workorderInfoApi.workorderNumber("").then(response => {
            this.setState({
                workorderTotal: response.data,
                loading: false
            });
        }).catch(error => {
            console.log("Get dispatch number filed", error);
            this.setState({
                workorderTotal: "-",
                loading: false
            });
        });
    };

    render() {
        return (
            <div className={style.systemDataItem}
                 onClick={() => isAdmin() ? this.props.navigate({pathname: "/home/workorders/all-workorder"}) : null}>
                <p>工单总数</p>
                {this.state.loading ? <Spin/> : <p>{this.state.workorderTotal}</p>}
            </div>
        );
    }
}

export default withNavigation(WorkorderStatistic)
