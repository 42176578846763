import React, {Component} from "react";
import {Button, Cascader, Col, Drawer, Form, Input, message, Row, Select, Space} from "antd";
import CustomerApi from "api/customer/customer";
import CustomerDepartmentApi from "api/customer/department";
import EquipmentListApi from "api/equipment/list";
import CustomerDepartmentPicker from "component/customer/department/CustomerDepartmentPicker";
import {setFieldsError} from "plugin/formily";
import style from "component/equipment/map/index.module.css";
import {getUser} from "../../../../../component/SecurityInterceptor";

/**
 * 迁移设备
 * 调用请求客户单位接口获取客户单位数据
 * 根据客户单位变化 请求对应的客户接口 获取客户数据
 * 调用 component 中的 Map 组件 创建地图
 * 根据 地址和设备所在地址在地图上渲染对应的点
 * 调用迁移设备接口  对设备进行迁移
 */
export default class MigrationEquipmentDrawer extends Component {

    formRef = React.createRef();
    mapRef = React.createRef();
    geocoder;
    map;

    state = {
        show: false,
        loading: false,
        drawerWidth: 600,
        loadingCustomerDepartmentsOptions: false,
        loadingCustomersOptions: false,
        customerDepartmentsOptions: [],
        customersOptions: [],
        areaData: [],
        equipmentData: {},
        equipmentId: "",
        lnglatDataLng: "",
        lnglatDataLat: ""
    };

    show = id => {
        this.setState({
            show: true,
            drawerWidth: window.innerWidth < 576 ? window.innerWidth : window.innerWidth * 0.55,
            equipmentId: id
        }, () => {
            this.getMap();
            this.getEquipmentData(id);
            this.requestCustomerDepartmentsOptions();
        });
    };

    close = () => this.setState({show: false, loading: false}, () => {
        this.map.destroy();
    });

    toggleLoading = () => this.setState({loading: !this.state.loading});

    onCustomerDepartmentChange = departmentId => {
        this.formRef.current.setFieldsValue({customerId: null});
        this.requestCustomersOptions(departmentId);
        this.setState({departmentId});
    };

    handleDepartmentTreeNode = department => {
        const node = {
            title: department.name,
            key: department.id,
            value: department.id,
            children: []
        };
        if (!!department.children && department.children.length > 0) {
            department.children.forEach((subDepartment) => {
                node.children.push(this.handleDepartmentTreeNode(subDepartment));
            });
        }
        return node;
    };

    requestCustomerDepartmentsOptions = () => {
        this.toggleCustomerDepartmentsOptionsLoading();
        CustomerDepartmentApi.tree(getUser().departments[0].id).then(response => {
            const treeData = [];
            response.data.forEach((department) => {
                treeData.push(this.handleDepartmentTreeNode(department));
            });
            this.setState({customerDepartmentsOptions: treeData}, this.toggleCustomerDepartmentsOptionsLoading);
        }).catch(error => {
            console.warn("Get department tree data failed", error);
            this.toggleCustomerDepartmentsOptionsLoading();
        });
    };

    requestCustomersOptions = departmentId => {
        this.toggleCustomersOptionsLoading();
        CustomerApi.list({departmentId}).then(response => {
            const customerOptions = [];
            if (!!response.data && response.data.length > 0) {
                response.data.forEach((customer) => {
                    customerOptions.push(this.handleCustomerOption(customer));
                });
            }
            this.setState({customersOptions: customerOptions, loadingCustomersOptions: false});
        }).catch(error => {
            this.toggleCustomersOptionsLoading();
            console.warn("Get customers failed", error);
        });
    };

    handleCustomerOption = customer => {
        return {
            label: `${customer.name}(${customer.cellphone})`,
            value: customer.id
        };
    };

    toggleCustomerDepartmentsOptionsLoading = () => this.setState({loadingCustomerDepartmentsOptions: !this.state.loadingCustomerDepartmentsOptions});

    toggleCustomersOptionsLoading = () => this.setState({loadingCustomersOptions: !this.state.loadingCustomersOptions});

    convert = address => {
        let marker = new AMap.Marker();
        this.geocoder.getLocation(address, (status, result) => {
            if (status === "complete" && result.geocodes.length) {
                let lnglat = result.geocodes[0].location;
                this.setState({
                    lnglatData: lnglat
                });
                marker.setPosition(lnglat);
                this.map.add(marker);
                this.map.setFitView(marker);
            } else {
                console.error("根据地址查询位置失败");
            }
        });
    };

    areaChange = value => {
        this.setState({areaData: value});
        this.formRef.current.setFieldsValue({
            address: ""
        });
    };

    getEquipmentData = id => {
        EquipmentListApi.info(id).then(response => {
            this.onCustomerDepartmentChange(response.data.customerDepartmentId);
            let equipmentData = {
                customerDepartmentId: response.data.customerDepartmentId,
                customerId: response.data.customerId,
                area: [response.data.province, response.data.city, response.data.county],
                address: response.data.address,
                storey: response.data.storey
            };
            this.formRef.current.setFieldsValue(equipmentData);
            this.setState({
                equipmentData,
                areaData: [response.data.province, response.data.city, response.data.county],
                lnglatDataLat: response.data.latitude,
                lnglatDataLng: response.data.longitude
            });
            let address = response.data.province + response.data.city + response.data.county + response.data.address;
            this.convert(address);
        }).catch(error => {
            console.error("Get equipment detail info", error);
        });
    };

    handle = data => {
        let obj = {
            customerDepartmentId: data.customerDepartmentId,
            customerId: data.customerId,
            area: data.area,
            address: data.address
        };
        if (Object.entries(this.state.equipmentData).toString() === Object.entries(obj).toString()) {
            this.close();
            return;
        }
        this.toggleLoading();
        data.id = this.state.equipmentId;
        data.province = data.area[0];
        data.city = data.area[1];
        data.county = data.area[2];
        data.area = undefined;
        data.longitude = this.state.lnglatDataLng;
        data.latitude = this.state.lnglatDataLat;
        data.lnglat = `${this.state.lnglatDataLng},${this.state.lnglatDataLat}`;
        data.type = 0;
        EquipmentListApi.migration(data).then(res => {
            this.close();
            this.props.onSuccess();
        }).catch(err => {
            if (err.status === 40000) {
                setFieldsError(err, this.formRef);
            } else {
                message.error({key: "failed", content: err.data});
            }
            this.toggleLoading();
            console.error("Migration equipment fail", err);
        });
    };

    getMap = () => {
        this.map = new AMap.Map("migration-device-dialog-map", {
            pitch: 0,
            viewMode: "3D",
            terrain: true,
            center: [114.973172, 25.817861],
            zoom: 17,
            zoomEnable: false,
            dragEnable: false,
            resizeEnable: true
        });

        AMap.plugin(["AMap.Geocoder"], () => this.geocoder = new AMap.Geocoder());

        AMap.plugin(["AMap.PlaceSearch", "AMap.AutoComplete"], () => {
            new AMap.AutoComplete({input: "address"}).on("select", e => {
                this.formRef.current.setFieldsValue({address: e.poi.name});
                this.map.clearMap();
                this.map.add(new AMap.Marker({position: [e.poi.location.lng, e.poi.location.lat]}));
                this.setState({lnglatDataLng: e.poi.location.lng, lnglatDataLat: e.poi.location.lat});
                this.map.setFitView();
                let lnglat = [e.poi.location.lng, e.poi.location.lat];
                this.regeoCode(lnglat);
            });
        });
    };

    /**
     * 当所选的地址与详细地址不同时更新所选的地址
     * @param lnglat 经纬度
     */
    regeoCode = lnglat => new AMap.Geocoder().getAddress(lnglat, (status, result) => {
        // 特别行政区或直辖市的 city 为空 所有直接用 province
        this.formRef.current.setFieldsValue({
            area: [
                result.regeocode.addressComponent.province,
                result.regeocode.addressComponent.city ? result.regeocode.addressComponent.city : result.regeocode.addressComponent.province,
                result.regeocode.addressComponent.district
            ]
        });
    });

    render() {
        return <Drawer title="迁移设备"
                       width={this.state.drawerWidth}
                       open={this.state.show}
                       destroyOnClose
                       onClose={this.close}>
            <Form ref={this.formRef} onFinish={this.handle} layout="vertical">
                <CustomerDepartmentPicker name="customerDepartmentId" onChange={this.onCustomerDepartmentChange}
                                          rules={[{required: true, message: "请选择客户单位"}]}/>
                <Form.Item name="customerId" label="客户" required
                           rules={[{required: true, message: "请选择客户"}]}>
                    <Select options={this.state.customersOptions}
                            loading={this.state.loadingCustomersOptions}
                            showSearch optionFilterProp="label"/>
                </Form.Item>
                <Form.Item name="area" label="选择地址" required
                           rules={[{required: true, message: "请选择地址"},
                               {pattern: /\S/g, message: "内容不能为空"}]}>
                    <Cascader options={require("asset/location.json")}
                              onChange={this.areaChange}/>
                </Form.Item>

                <Form.Item name="address" label="设备所在地址" required
                           rules={[{required: true, message: "请输入详细地址"},
                               {pattern: /\S/g, message: "内容不能为空"}]}>
                    <Input id="address" autoComplete="off"/>
                </Form.Item>
                <Col span={24}>
                    <Form.Item name="storey" label="楼号楼层及门牌号">
                        <Input placeholder="示例：主楼6层  1601"/>
                    </Form.Item>
                </Col>
                <div id="migration-device-dialog-map" className={style.map}/>

                <Row gutter={8} justify="end">
                    <Col>
                        <Space>
                            <Button disabled={this.state.loading} onClick={this.close}>取消</Button>
                            <Button loading={this.state.loading} type="primary" htmlType="submit">迁移</Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </Drawer>;
    }
}
