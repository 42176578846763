/**
 * 工单优先级
 */
const priority = {
    "1": "低",
    "5": "中",
    "9": "高"
};

export const priorityOptions = () => {
    const options = [];
    for (let key in priority) {
        options.push({
            label: priority[key],
            value: key
        });
    }
    return options;
};

export default priority;
