import React from "react";
import {PageContainer, ProTable} from "@ant-design/pro-components";
import {Button, Dropdown, Menu, message, Tooltip} from "antd";
import {EyeOutlined, FileSearchOutlined, MoreOutlined, PlusSquareOutlined} from "@ant-design/icons";

import DepartmentApi from "api/user/department";
import SecurityInterceptor, {getUser, hasAnyRole, isAdmin} from "component/SecurityInterceptor";

import CreateUserDepartmentModal from "./modal/CreateUserDepartmentModal";
import DeleteUserDepartmentModal from "./modal/DeleteUserDepartmentModal";
import UpdateUserDepartmentModal from "./modal/UpdateUserDepartmentModal";
import SetDepartmentCustomerModal from "./modal/SetDepartmentCustomerModal";
import MigrationModal from "./modal/MigrationModal";
import PreviewUserDepartmentDetailModal from "./modal/PreviewUserDepartmentDetailModal";

import PreviewDepartmentUserDrawer from "./drawer/PreviewDepartmentUserDrawer";
import SetDepartmentCustomerDepartmentModal from "./modal/SetDepartmentCustomerDepartmentModal";
import SetUserDepartmentManagerModal from "./modal/SetUserDepartmentManagerModal";

import style from "./index.module.css";
import Upload from "antd/es/upload/Upload";
import {withNavigation} from "../../../../utils/RouteLink";

class Departments extends React.Component {

    actionRef = null;

    createRef = React.createRef();
    editRef = React.createRef();
    deleteRef = React.createRef();
    setRef = React.createRef();
    migrationRef = React.createRef();
    userDepartmentDetailRef = React.createRef(); // 预览部门用户 简洁版

    departmentCustomerRef = React.createRef();
    departmentCustomerDepartmentRef = React.createRef();
    departmentManagerRef = React.createRef();

    state = {
        level: null
    };

    /**
     * Render of column operations scope
     */
    renderColumnOperations = (node, entity, index, action) => {
        if (entity.weight < this.state.level) {
            return null;
        } else {
            return [
                <Tooltip key="create" title="新建子单位">
                    <Button icon size="small"
                            onClick={() => this.dialog(entity.id, this.createRef)}><PlusSquareOutlined/></Button>
                </Tooltip>,
                <Tooltip key="departmentCustomer" title="查看单位用户">
                    <Button icon
                            size="small"
                            onClick={() => this.previewDepartmentCustomer(entity)}>
                        <FileSearchOutlined/>
                    </Button>
                </Tooltip>,
                <Tooltip key="detail" title="查看单位详情">
                    <Button icon
                            size="small"
                            onClick={() => this.previewDepartmentDetail(entity)}>
                        <EyeOutlined/>
                    </Button>
                </Tooltip>,
                <Tooltip key="more" title="更多">
                    <Dropdown key="more" overlay={this.renderColumnOptionsMenu(entity, action)} trigger={["click"]}>
                        <Button icon size="small"><MoreOutlined/></Button>
                    </Dropdown>
                </Tooltip>
            ];
        }

    };

    columns = [
        {
            title: "ID",
            hideInTable: true,
            hideInSearch: true,
            align: "center",
            dataIndex: "id"
        },
        {
            title: "单位名称",
            ellipsis: true,
            dataIndex: "name"
        },
        {
            title: "操作",
            valueType: "option",
            render: (node, entity, index, action) => this.renderColumnOperations(node, entity, index, action)
        }
    ];

    /**
     * 查看单位详情
     */
    previewDepartmentDetail = entity => {
        // 简洁版
        // this.userDepartmentDetailRef.current.show(entity.id, entity.name);

        if (entity.id){
            DepartmentApi.getDepartmentDetail(entity.id).then(response => {
                localStorage.setItem("Entity", JSON.stringify({
                    id: response.data.id,
                    name: response.data.name,
                    address: response.data.address,
                    region: response.data.province + '-' + response.data.city + '-' + response.data.county,
                }))
                // 跳转路由
                let Entity = JSON.parse(localStorage.getItem("Entity"));
                if(Entity.id === entity.id) this.props.navigate("/home/user/departments/detail");

            })
        }
    };

    /**
     * 查看单位客户
     * @param entity
     */
    previewDepartmentCustomer = entity => {
        this.departmentCustomerRef.current.show(entity.id, entity.name);
    };

    moveDialog = (entity, ref) => !!entity.children ? message.error({
        key: "moveUserDepartment",
        content: "有子单位的暂时不能迁移"
    }) : ref.current.show(entity.id);

    deleteDialog = (entity, ref) => !!entity.children ? message.error({
        key: "delete",
        content: "该单位有子单位暂时不能删除"
    }) : ref.current.show(entity.id);

    /**
     *控制弹窗
     */
    dialog = (id, ref) => {
        ref.current.show(id);
    };

    /**
     * 上传logo回调
     * @param value
     */
    logoChange = value => {
        if (value.file.status === "done") {
            message.success("设置成功")
        }
    }

    renderColumnOptionsMenu = (entity, action) => <Menu>
        <Menu.Item key="0">
            <span onClick={() => this.dialog(entity.id, this.editRef)} className={style.btn}>编辑</span>
        </Menu.Item>
        {getUser().departments[0].id === entity.id ? null :
            <SecurityInterceptor role={["admin", "user-department-manager"]}>
                <Menu.Item key="2">
                <span onClick={() => this.moveDialog(entity, this.migrationRef)}
                      className={style.btn}>迁移</span>
                </Menu.Item>
            </SecurityInterceptor>}
        <Menu.Item key="1">
            <span onClick={() => this.dialog(entity.id, this.setRef)} className={style.btn}>设置用户</span>
        </Menu.Item>
        <Menu.Item key="setLogo">
            <Upload
                name="logo"
                accept="image/*"
                // method="patch"
                action={global.config.url + `/user/department/${entity.id}/logo`}
                headers={{token: localStorage.getItem("Token")}}
                maxCount={1}
                showUploadList={false}
                onChange={this.logoChange}>
                设置Logo
            </Upload>
        </Menu.Item>
        <Menu.Item key="4">
                <span onClick={() => this.dialog(entity.id, this.departmentCustomerDepartmentRef)}
                      className={style.btn}>设置客户单位</span>
        </Menu.Item>
        <Menu.Item key="5">
                <span onClick={() => this.dialog(entity.id, this.departmentManagerRef)}
                      className={style.btn}>设置机构管理员</span>
        </Menu.Item>
        <Menu.Item key="3" danger>
            <span onClick={() => this.deleteDialog(entity, this.deleteRef)} className={style.btn}>删除</span>
        </Menu.Item>
    </Menu>;

    renderToolbar = () => hasAnyRole(["admin"]) ? <Button key="create" type="primary" onClick={() => this.dialog("0", this.createRef)}>新建</Button> : <></>;

    /**
     * Request table data
     */
    requestPage = () => {
       // const shouldRequestAllDepartment = isAdmin() || hasAnyRole(["customer-service"]);

        return isAdmin() ? this.requestAllDepartment() : this.requestUserDepartment();
    };

    requestAllDepartment = () => {
        return DepartmentApi.findAllTrees().then(response => {
            console.log(response);
            return {data: response.data, success: true};
        }).catch(error => {
            console.log("Get user department page failed", error);
            return {data: [], success: true, total: 0};
        });
    };

    requestUserDepartment = () => {
        const userDepartments = getUser().departments;
        if (userDepartments !== undefined && userDepartments !== null && userDepartments.length >= 1) {
            return DepartmentApi.findDepartmentTree(userDepartments[0].id).then(response => {
                this.handleUserDepartment(response.data, userDepartments[0].id);
                return {data: response.data, success: true};
            }).catch(error => {
                console.log("Get user department page failed", error);
                return {data: [], success: true, total: 0};
            });
        } else {
            return {data: [], success: true, total: 0};
        }
    };

    /**
     * 判断当前用户所在单位 在整颗树上的等级（既所在单位在该树上的层级）
     * @param data  树形结构数据源
     * @param id    用户所在单位的单位ID
     */
    handleUserDepartment = (data, id) => {
        for (let item of data) {
            if (item.id === id) {
                this.setState({level: item.weight});
                break;
            }
            this.handleUserDepartment(item.children, id);
        }
    };

    /**
     * Refresh table data
     * @description Request table data. reset page states
     */
    reload = () => {
        this.actionRef?.reload();
    };

    render() {
        return <PageContainer header={{title: "机构管理", breadcrumb: {}}} extra={this.renderToolbar()}>
            <ProTable rowKey="id"
                      headerTitle="机构信息"
                      options={{reload: true, setting: true, density: true}}
                      actionRef={actionRef => this.actionRef = actionRef}
                      columns={this.columns}
                      search={false}
                      pagination={false}
                      defaultSize={"small"}
                      request={this.requestPage}/>

            <CreateUserDepartmentModal key="create" onSuccess={this.reload} ref={this.createRef}/>
            <DeleteUserDepartmentModal key="delete" onSuccess={this.reload} ref={this.deleteRef}/>
            <UpdateUserDepartmentModal onSuccess={this.reload} ref={this.editRef}/>
            <SetDepartmentCustomerModal onSuccess={this.reload} ref={this.setRef}/>
            <MigrationModal key="migration" ref={this.migrationRef} onSuccess={this.reload}/>
            {/* 简洁版 */}
            <PreviewUserDepartmentDetailModal ref={this.userDepartmentDetailRef} onSuccess={this.reload}/>
            <PreviewDepartmentUserDrawer ref={this.departmentCustomerRef} onSuccess={this.reload}/>
            <SetDepartmentCustomerDepartmentModal ref={this.departmentCustomerDepartmentRef} onSuccess={this.reload}/>
            <SetUserDepartmentManagerModal ref={this.departmentManagerRef} onSuccess={this.reload}/>
        </PageContainer>;
    }
}

export default withNavigation(Departments)
