import React from "react";
import {Button, Popconfirm, Space, Tooltip} from "antd";
import {DeleteOutlined, EditOutlined, EyeOutlined} from "@ant-design/icons";
import {PageContainer,ProTable} from "@ant-design/pro-components";

import StorehouseTypeApi from "api/spares/type";

import CreateStorehouseTypeDrawer from "./drawer/CreateStorehouseTypeDrawer";
import EditStorehouseTypeDrawer from "./drawer/EditStorehouseTypeDrawer";
import PreviewStorehouseTypeDrawer from "./drawer/PreviewStorehouseTypeDrawer";
import SecurityInterceptor, {getUser} from "component/SecurityInterceptor";
import CustomerDepartmentApi from "../../../../api/customer/department";
import EquipmentMakerApi from "../../../../api/equipment/maker";

export default function StorehouseType(props) {

    const tableActionRef = React.useRef();
    const createStorehouseTypeRef = React.useRef();
    const editStorehouseTypeRef = React.useRef();
    const previewStorehouseTypeRef = React.useRef();

    const columns = [
        {title: "ID", dataIndex: "id", hideInSearch: true, hideInTable: true},
        {title: "类型名称", dataIndex: "name"},
        {title: "类型型号", dataIndex: "model"},
        //{title: "制造商", dataIndex: ["manufacturer", "name"]},

        {
            title: "制造商",
            dataIndex:["manufacturer", "name"],
            formItemProps: {name: "manufacturerId"},
            valueType: "select",
            hideInTable: true,
            fieldProps: {
                showSearch: true,
                debounce: 500,
            },
            request: () => EquipmentMakerApi.queryAll().then(res => {
                let selectData = [];
                res.data.forEach(item => {
                    selectData.push({label: item.name, value: item.id});
                });
                return selectData;
            }).catch(() => []),
        },

        {title: "价格(元)", dataIndex: "price"},
        {title: "创建时间", dataIndex: "createTime"},
        {
            title: "是否SM",
            dataIndex: "classified",
            valueEnum: {
                "0": "否",
                "1": "是"
            },
            hideInSearch: true
        },
        {title: "操作", valueType: "option", render: (_, entity) => columnOptionsRender(entity)}
    ];

    const request = params => {
        console.log(params)
        return StorehouseTypeApi.page(params).then(res => {
            return {data: res.data.records, total: res.data.total, success: true};
        }).catch(err => {
            return {data: [], total: 0, success: true};
        });
    };

    const reloadTable = () => tableActionRef.current.reload();

    const onDelete = id => {
        return global.axios.delete(`/repository/stocktype/${id}`).then(res => reloadTable()).catch(err => false);
    };

    const tableToolbarRender = () => (<Space>
        <SecurityInterceptor role={["admin", "user-department-manager"]}>
            <Button type="primary" onClick={() => createStorehouseTypeRef.current.show()}>新建</Button>
        </SecurityInterceptor>
    </Space>);

    const columnOptionsRender = item => (<Space>
        <Tooltip title="详情">
            <Button icon={<EyeOutlined/>} size="small" onClick={() => previewStorehouseTypeRef.current.show(item.id)}/>
        </Tooltip>
        <SecurityInterceptor role={["admin", "user-department-manager"]}>
            <Tooltip title="编辑">
                <Button icon={<EditOutlined/>} size="small"
                        onClick={() => editStorehouseTypeRef.current.show(item.id)}/>
            </Tooltip>
        </SecurityInterceptor>
        <SecurityInterceptor role={["admin", "user-department-manager"]}>
            <Popconfirm title="是否删除该库存类型" okText="删除" cancelText="取消" onConfirm={() => onDelete(item.id)}>
                <Tooltip title="删除">
                    <Button danger icon={<DeleteOutlined/>} size="small"/>
                </Tooltip>
            </Popconfirm>
        </SecurityInterceptor>
    </Space>);

    return (<PageContainer title="备件类型信息" header={{breadcrumbRender: false}} extra={tableToolbarRender()}>
        <ProTable columns={columns}
                  actionRef={tableActionRef}
                  request={request}
                  options={{fullScreen: false, reload: true, setting: true, density: true}}
                  defaultSize="small"
                  cardBordered
                  rowKey="id"/>
        <CreateStorehouseTypeDrawer onSuccess={reloadTable} ref={createStorehouseTypeRef}/>
        <EditStorehouseTypeDrawer onSuccess={reloadTable} ref={editStorehouseTypeRef}/>
        <PreviewStorehouseTypeDrawer onSuccess={reloadTable} ref={previewStorehouseTypeRef}/>
    </PageContainer>);
}
