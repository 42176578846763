import React from "react";
import {Divider, Image, List} from "antd";


/**
 * 工单预览时文件组件
 *
 * @description 自动区分图片与其他类型附件，图片将使用图片预览方式显示，其他类型附件将使用文件列表显示
 */
export default class DescriptionFileRender extends React.Component {

    /**
     * 文件列表中是否有图片
     * @description 该标记位主要用于判断是否渲染图片与正常文件的分割线
     */
    hasImage = false;

    visibleFileType = ["pdf", "video"];

    renderImageFile = () => {
        if (!this.props.file) {
            return <></>;
        }
        const images = [];
        // 从外部传入的文件数组中取出图片文件
        this.props.file.forEach(file => {
            if (file.type.indexOf("image") > -1) {
                images.push(file);
            }
        });
        // 如果没有图片则返回空节点
        if (images.length < 1) {
            return <></>;
        }
        // 设置图片标记位
        this.hasImage = true;
        return <Image.PreviewGroup>
            {
                images.map(item => (<div key={item.id}
                                         style={{
                                             position: "relative",
                                             width: "24.4%",
                                             height: "200px",
                                             overflow: "hidden",
                                             display: "inline-block",
                                             textAlign: "center",
                                             margin: "2px",
                                             border: "0.5px solid",
                                             borderColor: "#F0F0F0",
                                             lineHeight: "200px"
                                         }}>
                    <div style={{
                        width: "100%",
                        height: "200px",
                        position: "absolute",
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0,
                        background: `url(${item.path})`,
                        filter: "blur(3px)"
                    }}>

                    </div>
                    <Image src={item.path}/>
                </div>))
            }
        </Image.PreviewGroup>;
    };

    renderFile = () => {
        const files = [];
        this.props.file.forEach(file => {
            if (file.type.indexOf("image") < 0) {
                files.push(file);
            }
        });
        if (this.hasImage && files.length < 1) {
            return <></>;
        }
        return <>
            {this.hasImage ? <Divider/> : null}
            <List dataSource={files} size="small" split={false} renderItem={item => {
                return <List.Item>
                    {this.isFileVisitable(item.type) ? <a href="#" rel="noreferrer" onClick={() => {
                        this.previewFile(item.path);
                    }}>{item.name}</a> : <a href={item.path} rel="noreferrer" target="_blank">{item.name}</a>}
                </List.Item>;
            }}/>
        </>;
    };

    isFileVisitable = type => {
        for (let visitableType of this.visibleFileType) {
            if (type.indexOf(visitableType) > -1) {
                return true;
            }
        }
        return false;
    };

    previewFile = path => {
        const top = (window.screen.availHeight - 30 - 560) / 2;
        const left = (window.screen.availWidth - 30 - 800) / 2;
        window.open(path, "newwindow", `top:${top},left:${left},height=560,width=800,location=no,toolbar=no,menubar=no,status=no`, true);
    };

    render() {
        if (this.props.file !== null && this.props.file !== undefined && this.props.file.length > 0) {
            return <>
                {this.renderImageFile()}
                {this.renderFile()}
            </>;
        }
        return "-";
    }
}
