import React, {Component} from "react";
import {Button, Space, Tooltip} from "antd";
import {EyeOutlined} from "@ant-design/icons";
import {ProTable} from "@ant-design/pro-components";

import PreviewAddRecordDrawer from "../../record/drawer/PreviewAddRecordDrawer";
import PreviewRevertRecordDrawer from "../../record/drawer/PreviewRevertRecordDrawer";
import PreviewSolicitRecordDrawer from "../../record/drawer/PreviewSolicitRecordDrawer";
import PreviewDestroyRecordDrawer from "../../record/drawer/PreviewDestroyRecordDrawer";
import PreviewScheduleRecordDrawer from "../../record/drawer/PreviewScheduleRecordDrawer";

import StorehouseRecordApi from "api/spares/record";
import AccountApi from "api/user/account";

export default class InventoryChange extends Component {

    previewAddRecordRef = React.createRef();
    PreviewRevertRecordRef = React.createRef();
    PreviewSolicitRecordRef = React.createRef();
    PreviewScheduleRecordRef = React.createRef();
    PreviewDestroyRecordRef = React.createRef();

    renderColumnOperations = (node, entity) => (<Space>
        <Tooltip title="查看详情">
            <Button icon size="small" onClick={() => this.previewRecord(entity)}><EyeOutlined/></Button>
        </Tooltip>
    </Space>);

    columns = [
        {
            title: "ID",
            dataIndex: "id",
            hideInTable: true,
            hideInSearch: true
        },
        {
            title: "名称",
            dataIndex: ["stocktype", "name"],
            hideInSearch: true
        },
        {
            title: "类型",
            dataIndex: ["stocktype", "model"],
            hideInSearch: true
        },
        {
            title: "记录类型",
            dataIndex: "type",
            valueEnum: {
                "add": {
                    "text": "添加库存"
                },
                "revert": {
                    "text": "工程师退还"
                },
                "solicit": {
                    "text": "工程师领用"
                },
                "schedule": {
                    "text": "库存调拨"
                },
                "destroy": {
                    "text": "消耗"
                }
            }
        },
        {
            title: "出入库数量",
            dataIndex: "changed",
            hideInSearch: true,
            align: 'right'
        },
        {
            title: "剩余数量",
            dataIndex: "balance",
            hideInSearch: true,
            align: 'right'
        },
        {
            title: "操作人",
            dataIndex: "creatorName",
            hideInSearch: true,
            align: 'center'
        },
        {
            title: "操作时间",
            dataIndex: "createTime",
            hideInSearch: true
        },
        {
            title: "操作",
            valueType: "option",
            render: this.renderColumnOperations
        }
    ];

    previewRecord = (entity) => {
        const inOrOut = entity.changed > 0 ? "input" : "output";
        const action = {
            add: () => this.previewAddRecordRef.current.show(entity.evidence),
            revert: () => this.PreviewRevertRecordRef.current.show(entity.evidence),
            solicit: () => this.PreviewSolicitRecordRef.current.show(entity.evidence),
            schedule: () => this.PreviewScheduleRecordRef.current.show(entity.evidence, inOrOut),
            destroy: () => this.PreviewDestroyRecordRef.current.show(entity.evidence)
        };
        action[entity.type]();
    };

    requestPage = (params) => {
        params.repository = this.props.storehouseId;
        return StorehouseRecordApi.page(params).then(async res => {
            let data = await this.handleCreateId(res.data.records);
            return {data, total: res.data.total, success: true};
        }).catch(err => {
            console.error("Request record page failed", err);
            return {data: [], total: 0, success: true};
        });
    };

    handleCreateId = async (data) => {
        let creatorId = [];
        let accountData;
        data.forEach(item => {
            if (!creatorId.includes(item.creator)) {
                creatorId.push(item.creator);
            }
        });
        accountData = await this.requestAccount(creatorId.join(","));
        accountData.forEach(item => {
            data.forEach(itemY => {
                if (item.id === itemY.creator) {
                    itemY["creatorName"] = item.name;
                }
            });
        });
        return data;
    };

    requestAccount = async (ids) => {
        const {data} = await AccountApi.batchRequestAccount({ids});
        return data;
    };

    reload = () => this.actionRef.reload();

    render() {
        return (
            <>
                <ProTable headerTitle="当前仓库库存变化"
                          rowKey="id"
                          defaultSize="small"
                          options={{reload: true, setting: true, density: true}}
                          actionRef={actionRef => this.actionRef = actionRef}
                          columns={this.columns}
                          request={this.requestPage}/>
                <PreviewAddRecordDrawer onSuccess={this.reload} ref={this.previewAddRecordRef}/>
                <PreviewRevertRecordDrawer onSuccess={this.reload} ref={this.PreviewRevertRecordRef}/>
                <PreviewSolicitRecordDrawer onSuccess={this.reload} ref={this.PreviewSolicitRecordRef}/>
                <PreviewDestroyRecordDrawer onSuccess={this.reload} ref={this.PreviewDestroyRecordRef}/>
                <PreviewScheduleRecordDrawer onSuccess={this.reload} ref={this.PreviewScheduleRecordRef}/>
            </>
        );
    }
}
