import React from "react";
import {Button, Col, Row, Space} from "antd";
import {ProForm, ProFormDigit, ProFormTextArea} from "@ant-design/pro-components";

import AccountApi from "api/user/account";
import DraggableModal from "component/DraggableModal";

/**
 *添加账号积分对话框
 */
export default class AddScoreModal extends React.Component {

    state = {
        loading: false, show: false, id: ""
    };

    draggableRef = React.createRef();
    formRef = React.createRef();

    show = id => {
        this.draggableRef.current.show();
        this.setState({id});
    };

    close = () => {
        this.setState({
            loading: false
        });
        this.draggableRef.current.close();
    };

    /**
     * 控制 loading 加载显示
     */
    toggleLoading = () => {
        this.setState({
            loading: !this.state.loading
        });
    };

    /**
     * 添加积分
     */
    handle = data => {
        this.toggleLoading();
        AccountApi.addUserScore(this.state.id, data).then(res => {
            console.log(res);
            this.toggleLoading();
            this.close();
            this.props.onSuccess();
        }).catch(err => {
            this.toggleLoading();
            console.error("Add user score failed", err);
        });
    };

    render() {
        return <DraggableModal onCancel={this.close} title="添加积分" ref={this.draggableRef}>
            <ProForm formRef={this.formRef} onFinish={this.handle} layout={"vertical"} submitter={{
                resetButtonProps: {style: {display: "none"}}, submitButtonProps: {style: {display: "none"}}
            }}>
                <ProFormDigit
                    label="积分数"
                    name="points"
                    placeholder="请输入添加积分数"
                    fieldProps={{
                        precision: 1, onChange: (v) => {
                            if (v > 10000) {
                                this.formRef.current.setFields([{name: "points", errors: ["积分数不能大于100000"]}]);
                            }
                        }
                    }}
                    min={false}
                    max={10000}
                    rules={[{
                        required: true,
                        message: "请输入积分"
                    }, {pattern: /^(\+|-)?([1-9][0-9]*(\.\d+)?|(0\.(?!0+$)\d+))$/g, message: "积分数不能为0"}]}
                />
                <ProFormTextArea
                    name="reason"
                    label="添加原因"
                    placeholder="请输入添加原因"
                />
                <Row gutter={8} justify="end">
                    <Col>
                        <Space>
                            <Button disabled={this.state.loading} onClick={this.close}>取消</Button>
                            <Button loading={this.state.loading} type="primary" htmlType="submit">添加</Button>
                        </Space>
                    </Col>
                </Row>
            </ProForm>
        </DraggableModal>;
    }

}
