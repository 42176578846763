import React from "react";
import {Button, Cascader, Col, Form, message, Radio, Row, Select, Skeleton, Space} from "antd";

import AssignedWorkorderApi from "api/workorder/assigned";
import DraggableModal from "component/DraggableModal";
import {getUser} from "../../../../../utils/securityCheck";
import AccountApi from "../../../../../api/user/account";
import DepartmentApi from "../../../../../api/user/department";
import {isAdmin} from "../../../../../component/SecurityInterceptor";

/**
 * 指派工单弹窗
 *
 * @description 需要提供工单 ID，用户可从弹窗中指派工单的处理人与协理人;
 * @description 当设置成功后会执行传入弹窗的回掉函数，父组件可在该回掉函数中继续执行相关业务逻辑;
 *
 * @author Wayne Carder
 * @since 2021-06-20 14:10
 * @version 1.0.0
 */
export default class DispatchUnassignedWorkorderModal extends React.Component {

    state = {
        show: false,
        loading: false,
        loadingOfHandlers: true,
        handlerId: "",
        workorderId: "",
        clientDepartmentId:"",
        handlerOptions: [],
        dispatchType:"",
        departmentId:0
    };

    formRef = React.createRef();
    draggableRef = React.createRef();
    //指派类型
    typeOptions = [
        {
            label: '工程师',
            value: 0,
        },
        {
            label: '团队',
            value: 1,
        }]
    /**
     * 控制指派工单对话框显示隐藏
     */
    show = (id, clientDepartmentId) => {
        this.draggableRef.current.show();
        this.setState({
            loading: false,
            loadingOfHandlers: true,
            clientDepartmentId,
            workorderId: id
        }, this.requestHandlers);
    };

    close = () => {
        this.draggableRef.current.close();
        this.props.onSuccess({
            draggable:"close"
        });
    }

    /**
     * 获取处理人id
     * @param value  选中的数据
     */
    onHandlerChange = value => {
        this.setState({handlerId: ''});
        if(this.state.dispatchType === 0){
            this.setState({handlerId: value[value.length - 1]});
        }else {
            this.setState({handlerId: value});
        }
    }

    /**
     * 控制 loading 加载显示
     */
    toggleLoading = () => this.setState({loading: !this.state.loading});

    requestHandlers = (type = 0) => {
        //所有工单处理人
        //global.axios.get("/user/accounts")
        // 当前机构下所有处理人
        this.setState({dispatchType:type});
        switch (type) {
            case 0:
                isAdmin() ? this.requestAdminUserAccount() : this.requestHandlersAccount();
                break;
            case 1:
                this.requestHandlersTeam();
                break;
            default:
                break;

        }
    };
    // 获取所有工单处理人
    requestHandlersAccount = () => {
        AccountApi.getDepartmentAccount(getUser().departments[0].id).then(response => {
            const provinces = this.handleHandlers(response.data);
            this.handlerOptions = [];
            for (let key in provinces) {
                this.handlerOptions.push(provinces[key]);
            }
            for (let i = 0; i < this.handlerOptions.length; i++) {
                for (let key in this.handlerOptions[i].test) {
                    if (!this.handlerOptions[i]["children"]) {
                        this.handlerOptions[i]["children"] = [];
                    }
                    this.handlerOptions[i]["children"].push(this.handlerOptions[i].test[key]);

                    this.handlerOptions[i]["children"].forEach((item) => {
                        item["children"] = [];
                        for (let key in item.test) {
                            item["children"].push(item.test[key]);
                        }
                    });

                }
            }
            this.setState({loadingOfHandlers: false});
            console.log(this.handlerOptions)
        }).catch(error => {
            console.warn("Get handlers failed", error);
            this.setState({loadingOfHandlers: false});
        });
    };

    requestAdminUserAccount = () => {
        AccountApi.batchRequestAccount({customerDepartmentId: this.state.clientDepartmentId}).then(response => {
            const provinces = this.handleHandlers(response.data);
            this.handlerOptions = [];
            for (let key in provinces) {
                this.handlerOptions.push(provinces[key]);
            }
            for (let i = 0; i < this.handlerOptions.length; i++) {
                for (let key in this.handlerOptions[i].test) {
                    if (!this.handlerOptions[i]["children"]) {
                        this.handlerOptions[i]["children"] = [];
                    }
                    this.handlerOptions[i]["children"].push(this.handlerOptions[i].test[key]);

                    this.handlerOptions[i]["children"].forEach((item) => {
                        item["children"] = [];
                        for (let key in item.test) {
                            item["children"].push(item.test[key]);
                        }
                    });

                }
            }
            this.setState({loadingOfHandlers: false});
            console.log(this.handlerOptions)
        }).catch(error => {
            console.warn("Get handlers failed", error);
            this.setState({loadingOfHandlers: false});
        });
    };

    requestHandlersTeam = () => {
        DepartmentApi.getTeamList({departmentId:getUser().departments[0].id}).then(response => {
            this.handlerOptions = [];
            let { records } = response.data;
            records.forEach(item => {
                this.handlerOptions.push({
                    label: item.name,
                    value: item.id,
                })
            })
            this.setState({loadingOfHandlers: false});

        })
    }

    /**
     * 处理工单处理人数据结构
     *
     * @description 将服务端返回的普通数组转换成 `省 -> 市 -> 县/区 -> 处理人` 的结构
     * @param handlers 服务端响应的处理人数组
     */
    handleHandlers = handlers => {
        const provinces = {};
        for (const handler of handlers) {
            // 初始化当前用户所在省份
            let province;
            if (!!provinces[handler.province]) {
                province = provinces[handler.province];
            } else {
                province = {
                    label: handler.province,
                    value: handler.province,
                    test: {}
                };
                provinces[handler.province] = province;
            }

            // 初始化当前用户所在市
            let city;
            if (!!province.test[handler.city]) {
                city = province.test[handler.city];
            } else {
                city = {
                    label: handler.city,
                    value: handler.city,
                    test: {}
                };
                province.test[handler.city] = city;
            }

            let county;
            // 初始化当前用户所在区县
            if (!!city.test[handler.county]) {
                county = city.test[handler.county];
            } else {
                county = {
                    label: handler.county,
                    value: handler.county,
                    children: []
                };
                city.test[handler.county] = county;
            }

            county.children.push({
                label: `${handler.name}`,
                value: handler.id
            });
        }
        return provinces;
    };
    /**
     * 切换指派类型
     */
    onChangeDispatchType = (e) => {
        this.formRef.current.resetFields();
        this.setState({dispatchType: e.target.value})
        this.requestHandlers(e.target.value)
    }
    /**
     * 指派工单
     * @param data  表单数据
     */
    handle = () => {
        this.toggleLoading();
        let data = {
            handlerId: this.state.handlerId,
            type:this.state.dispatchType // 0: 处理人, 1: 团队
        }
        AssignedWorkorderApi.dispatch(this.state.workorderId, data).then(() => {
            this.close();
            this.toggleLoading();
            if (!!this.props.onSuccess) {
                this.props.onSuccess();
            }
        }).catch(error => {
            this.toggleLoading();
            message.error({key: "failed", content: error.data});
            console.warn("Dispatch workorder failed", error);
        });
    };

    filter = (inputValue, path) => path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);

    render() {
        return (<DraggableModal onCancel={this.close} title="指派工单" ref={this.draggableRef}>

            <Skeleton active loading={this.state.loadingOfHandlers}>
                <Form ref={this.formRef} onFinish={this.handle} layout="vertical">
                    <Form.Item name="type" label="指派类型" required>
                        <Radio.Group options={this.typeOptions} onChange={this.onChangeDispatchType} value={this.state.dispatchType} />
                        <br/>
                    </Form.Item>
                    <Form.Item name="ares" label="选择指派对象" required rules={[{required: true, message: "请选择指对象"}]}>
                        {this.state.dispatchType === 0 ? <Cascader options={this.handlerOptions} onChange={this.onHandlerChange} showSearch={this.filter}/> : <Select options={this.handlerOptions} onChange={this.onHandlerChange} allowClear></Select> }
                    </Form.Item>
                    <Row gutter={8} justify="end">
                        <Col>
                            <Space>
                                <Button onClick={this.close} disabled={this.state.loading}>取消</Button>
                                <Button style={{marginLeft: 16}} loading={this.state.loading} htmlType="submit"
                                        type="primary">指派</Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
            </Skeleton>

        </DraggableModal>);
    }
}
