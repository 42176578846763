import React, {useState} from "react";
import {ProCard} from "@ant-design/pro-components";
import LoginForm from "./component/LoginForm";
import qjbj from "../../asset/xloginBg.png";

import {isAdmin} from "../../component/SecurityInterceptor";
import ForgetPassword from "./component/ForgetPassword";

export default function Login(props) {
    const [loginFormOrPasswordForm, setLoginFormOrPasswordForm] = useState("1");
    function onSuccess() {
        if (isAdmin()) {
            window.location.href = "/home/preview"
            // props.history.replace("/home/preview");
        } else {
            window.location.href = "/home/workorders/the-workorder-i-handle"
            // props.history.replace("/home/workorders/the-workorder-i-handle");
        }
    }

    const toggleLoginForm = value => setLoginFormOrPasswordForm(value);

    return <ProCard ghost style={{backgroundImage: `url(${qjbj})`, backgroundSize: "cover", height: "100vh"}}>
        {loginFormOrPasswordForm === "1" ? <LoginForm onSuccess={onSuccess} onToggleForm={toggleLoginForm}/> :
            <ForgetPassword onToggleForm={toggleLoginForm}/>}
            <div style={{position: "absolute", bottom: 0, color: "#bbb",lineHeight : "40px", textAlign: "center", width: "100%" }}>
                赣ICP备10200822号-3
            </div>
    </ProCard>;
};
