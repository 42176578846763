import React from "react";
import {Button, Col, DatePicker, Drawer, Form, Input, message, Row, Select, Skeleton, Space, TreeSelect} from "antd";

import WorkorderApi from "api/workorder";
import {OpsWorkorderType} from "../../assets/WorkorderType";

import CustomerDepartmentPicker from "component/customer/department/CustomerDepartmentPicker";
import {setFieldsError} from "plugin/formily";
import FileUploadRender from "component/FileUploadRender";
import dayjs from "dayjs";
import CustomerDepartmentApi from "../../../../../api/customer/department";

/**
 * 编辑运维工单 Drawer
 *
 * @description 编辑运维工单
 *
 * @author huang Carder
 * @since 2021-06-23
 * @version 1.0.0
 */
export default class EditWorkorderDialog extends React.Component {

    state = {
        drawerWidth: 280,
        show: false,
        loading: false,
        contractOption: [],
        workorderId: "",
        treeData: [],
        customerData: [],
        departmentTreeLoading: true,
        oldWorkorderInformation: {}
    };

    formRef = React.createRef();
    filesRef = React.createRef();

    show = async id => {
        this.setState({
            show: true,
            workorderId: id,
            drawerWidth: window.innerWidth < 576 ? window.innerWidth : window.innerWidth * 0.55
        });
        await this.requestDepartmentTree();
        this.getWorkorderInfo();
    };

    close = () => {
        this.setState({
            show: false,
            loading: false
        });
    };

    toggleLoading = () => this.setState({loading: !this.state.loading});

    /**
     * 获取选中工单信息
     */
    getWorkorderInfo = () => {
        WorkorderApi.find(this.state.workorderId).then(response => {
            let files = [];
            if (!!response.data && !!response.data.files) {
                response.data.files.forEach((file) => {
                    files.push({
                        uid: file.id,
                        name: file.name,
                        status: "done",
                        url: file.path,
                        thumbUrl: file.path,
                        type: file.type
                    });
                });
                this.filesRef.current.setFilesValue(files);
            }
            this.formRef.current.setFieldsValue({
                type: response.data.type,
                priority: response.data.priority.toString(),
                deadLine: dayjs(response.data.deadLine, "YYYY-MM-DD HH:mm"),
                clientDepartment: response.data.clientDepartmentId,
                clientCustomer: response.data.clientId,
                description: !!response.data.description ? response.data.description : "",
                files
            });
            this.setState({
                departmentTreeLoading: false,
                oldWorkorderInformation: response.data
            });
            this.clientDepartmentOnChange(response.data.clientDepartmentId, false);
        }).catch(error => {
            console.error("Get department info failed", error);
        });
    };

    /**
     * 获取所有客户单位
     * @returns {Promise<void>}
     */
    requestDepartmentTree = async () => {
        const {data} = await CustomerDepartmentApi.page();
        const treeData = [];
        data.forEach((department) => {
            treeData.push(this.handleDepartmentTreeNode(department));
        });
        this.setState({
            treeData: treeData,
            departmentTreeLoading: false
        });
    };

    /**
     * 转换所有客户单位为选项
     * @param department
     */
    handleDepartmentTreeNode = (department) => {
        const node = {
            title: department.name,
            key: department.id,
            value: department.id,
            children: []
        };
        if (!!department.children && department.children.length > 0) {
            department.children.forEach((subDepartment) => {
                node.children.push(this.handleDepartmentTreeNode(subDepartment));
            });
        }
        return node;
    };

    /**
     * 根据 departmentId 获取客户
     * @param departmentId 客户单位 ID
     */
    requestCustomers = async (departmentId) => {
        const {data} = await global.axios.get("/customer/customers", {params: {departmentId}});
        const customerOptions = [];
        if (!!data && data.length > 0) {
            data.forEach((customer) => {
                customerOptions.push(this.handleCustomerOption(customer));
            });
        }
        this.setState({
            customerData: customerOptions
        });
    };

    /**
     * 判断编辑时信息是否更改
     * @param param
     * @returns {boolean}
     */
    isNecessaryToHandle = param => {

        const data = {
            type: param.type,
            priority: param.priority,
            deadLine: param.deadLine.format("YYYY-MM-DD HH:mm:ss"),
            clientDepartment: param.clientDepartment,
            clientCustomer: param.clientCustomer,
            description: param.description,
            files: this.filesRef.current.getFilesValue()
        };

        const old = {
            type: this.state.oldWorkorderInformation.type,
            priority: this.state.oldWorkorderInformation.priority.toString(),
            deadLine: this.state.oldWorkorderInformation.deadLine,
            clientDepartment: this.state.oldWorkorderInformation.clientDepartmentId,
            clientCustomer: this.state.oldWorkorderInformation.clientId,
            description: this.state.oldWorkorderInformation.description ? this.state.oldWorkorderInformation.description : "",
            files: this.state.oldWorkorderInformation.files.map(item => item.path)
        };
        if (Object.entries(data).toString() === Object.entries(old).toString() && Object.entries(data.files).toString() === Object.entries(old.files).toString()) {
            return true;
        }
    };

    handle = data => {
        if (this.isNecessaryToHandle(data)) {
            this.close();
            return;
        }
        this.toggleLoading();
        const param = {
            clientDepartmentId: data.clientDepartment,
            clientId: data.clientCustomer,
            deadLine: data.deadLine.format("YYYY-MM-DD HH:mm"),
            description: data.description,
            priority: data.priority,
            title: data.title,
            type: data.type,
            files: this.filesRef.current.getFilesValue(),
            id: this.state.workorderId,
            deleteFiles: this.filesRef.current.getDeletedFiles()
        };

        WorkorderApi.update(param).then((response) => {
            this.toggleLoading();
            this.close();
            this.props.onSuccess();
        }).catch(error => {
            if (error.status === 40000) {
                setFieldsError(error, this.formRef);
            } else {
                message.error({key: "failed", content: [error.data]});
            }
            this.toggleLoading();
            console.warn("Create workorder failed", error);
        });
    };

    clientDepartmentOnChange = async (value, clear = true) => {
        if (clear) {
            this.formRef.current.setFieldsValue({clientCustomer: ""});
        }
        await this.requestCustomers(value);
    };

    /**
     * 转换客户信息为选项
     * @param customer 客户信息
     */
    handleCustomerOption = customer => {
        return {
            label: `${customer.name}(${customer.cellphone})`,
            value: customer.id
        };
    };

    render() {
        return <Drawer title="编辑运维工单"
                       destroyOnClose
                       width={this.state.drawerWidth}
                       open={this.state.show}
                       closable={!this.state.loading}
                       onClose={this.close}>
            <Skeleton active loading={this.state.departmentTreeLoading}>
                <Form ref={this.formRef} onFinish={this.handle} layout={"vertical"}>
                    <Row gutter={8}>
                        <Col span={12}>
                            <Form.Item name="type" label="服务类型" required
                                       rules={[{required: true, message: "请选择服务类型"}]}>
                                <TreeSelect treeData={OpsWorkorderType} showSearch treeNodeFilterProp="title"/>
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item name="priority" label="优先级" required
                                       rules={[{required: true, message: "请选择优先级"}]}>
                                <Select options={[
                                    {label: "低", value: "1"},
                                    {label: "中", value: "5"},
                                    {label: "高", value: "9"}
                                ]}/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24}>
                            {
                                this.state.oldWorkorderInformation.progress === 17 ? null :
                                    <Form.Item name="deadLine" label="计划时间" required
                                               rules={[{required: true, message: "请选择计划时间"}]}>
                                        <DatePicker showTime format="YYYY-MM-DD HH:mm" inputReadOnly
                                                    style={{width: "100%"}}/>
                                    </Form.Item>
                            }
                        </Col>
                    </Row>

                    <Row gutter={8}>
                        <Col span={12}>
                            <CustomerDepartmentPicker onChange={this.clientDepartmentOnChange}
                                                      rules={[{required: true, message: "请选择客户单位"}]}/>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="clientCustomer" label="客户" required
                                       rules={[{required: true, message: "请选择客户"}]}>
                                <Select options={this.state.customerData}
                                        loading={this.state.loadingCustomersOptions}
                                        showSearch
                                        optionFilterProp="label"/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24}>
                            <Form.Item name="description" label="工单描述" rules={[
                                {pattern: /\S/g, message: "内容不能为空"}
                            ]}>
                                <Input.TextArea/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24}>
                            <FileUploadRender name="files" uploadPath="/workorder/file"
                                              ref={this.filesRef}/>
                        </Col>
                    </Row>
                    <Row gutter={8} justify="end">
                        <Col>
                            <Space>
                                <Button disabled={this.state.loading} onClick={this.close}>取消</Button>
                                <Button loading={this.state.loading} type="primary" htmlType="submit">编辑</Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
            </Skeleton>
        </Drawer>;
    }
}
