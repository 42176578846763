import React from "react";
import {Button, Drawer, Skeleton, Transfer} from "antd";
import AccountApi from "api/user/account";

/**
 * 设置账户身份
 * 获取所有身份信息 获取当前账户已有的身份信息
 * 编辑账户身份 调用修改账户身份接口 为账户 添加 或 移除账户身份
 */
export default class SetAccountIdentityDrawer extends React.Component {

    state = {
        show: false,
        drawerWidth: 280,
        loading: false,
        loadingTransferTargetKeys: false,
        loadingTransferData: false,
        transferData: [],
        transferTargetKeys: [],
        accountId: ""
    };

    show = id => {
        this.setState({
            show: true,
            accountId: id,
            drawerWidth: window.innerWidth < 576 ? window.innerWidth : window.innerWidth * 0.75
        }, () => {
            this.requestTransferData();
            this.requestTargetKeys();
        });
    };

    close = () => {
        this.setState({
            show: false,
            loading: false
        });
    };

    toggleLoading = () => {
        this.setState({
            loading: !this.state.loading
        });
    };

    toggleLoadingTransferData = () => {
        this.setState({
            loadingTransferTargetKeys: !this.state.loadingTransferTargetKeys
        });
    };

    toggleLoadingTransferTargetKeys = () => {
        this.setState({
            loadingTransferData: !this.state.loadingTransferData
        });
    };

    requestTransferData = () => {
        this.toggleLoadingTransferData();
        AccountApi.getIdentitie({}).then(response => {
            this.setState({
                transferData: response.data,
                loadingTransferTargetKeys: false
            });
        }).catch(error => {
            this.toggleLoadingTransferData();
            console.warn("Get identities failed", error);
        });
    };

    requestTargetKeys = () => {
        this.toggleLoadingTransferTargetKeys();
        AccountApi.getIdentitieId({params: {accountId: this.state.accountId}}).then(response => {
            this.setState({
                transferTargetKeys: response.data,
                loadingTransferData: false
            });
        }).catch(error => {
            this.toggleLoadingTransferTargetKeys();
            console.warn("Get account's identities failed", error);
        });
    };

    handleTransferTargetChange = (nextTargetKeys) => {
        this.setState({
            transferTargetKeys: nextTargetKeys
        });
    };

    handler = () => {
        this.toggleLoading();
        AccountApi.setIdentitie({
            accountId: this.state.accountId,
            identityIds: this.state.transferTargetKeys
        }).then(() => {
            this.close();
            this.props.onSuccess();
        }).catch(error => {
            this.toggleLoading();
            console.warn("Set account's identities failed", error);
        })
    };

    renderDrawerFooter = () => <div style={{textAlign: "right"}}>
        <Button onClick={this.close}
                style={{marginRight: 8}}
                disabled={this.state.loading || this.state.loadingTransferData || this.state.loadingTransferTargetKeys}>
            关闭
        </Button>

        <Button onClick={this.handler} type="primary" loading={this.state.loading}>保存</Button>
    </div>;

    render() {
        return <Drawer title="设置用户身份"
                       width={this.state.drawerWidth}
                       destroyOnClose
                       open={this.state.show}
                       closable={!this.state.loading || !this.state.loadingTransferData || !this.state.loadingTransferTargetKeys}
                       onClose={this.close}
                       footer={this.renderDrawerFooter()}>
            <Skeleton active loading={this.state.loadingTransferData || this.state.loadingTransferTargetKeys}>
                <Transfer dataSource={this.state.transferData}
                          showSearch
                          operations={["添加", "移除"]}
                          titles={["所有身份", "当前身份"]}
                          listStyle={{width: this.state.drawerWidth * 0.8, height: 480}}
                          rowKey={record => record.id}
                          targetKeys={this.state.transferTargetKeys}
                          render={item => item.name}
                          onChange={this.handleTransferTargetChange}/>
            </Skeleton>
        </Drawer>;
    }
}
