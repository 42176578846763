import React from "react";
import {Button, Drawer, message, Skeleton, Steps, Timeline} from "antd";
import {ProDescriptions, ProTable} from "@ant-design/pro-components";

import WorkorderFileRender from "component/DescriptionFileRender";
import WorkorderApi from "api/workorder";
import CustomerDepartmentApi from "api/customer/department";
import {hasAnyRole, isUser} from "component/SecurityInterceptor";
import CustomerApi from "api/customer/customer";
import StorehouseInventoryApi from "api/spares/storehouse/inventory";

import DeniedWorkorderDialog from "../modal/bussiness/DeniedWorkorderDialog";
import FinishWorkorderDialog from "../drawer/business/CompleteWorkorderDrawer";
import ForwardWorkorderDialog from "../modal/bussiness/ForwardWorkorderDialog";
import ReceiveWorkorderDialog from "../modal/bussiness/ReceiveWorkorderDialog";
import StartWorkorderDialog from "../modal/bussiness/StartWorkorderDialog";
import HangUpWorkorderDialog from "../modal/bussiness/HangUpWorkorderDialog";
import FeedbackWorkorderDialog from "../modal/bussiness/FeedbackWorkorderDialog";
import ReworkWorkorderDialog from "../modal/bussiness/ReworkWorkorderDialog";
import PreviewEquipmentDrawer from "../../../equipment/list/drawer/PreviewEquipmentDrawer";
import ChooseStorehouse from "../modal/bussiness/ChooseStorehouse";
import FromWorkorderCreateKnowledgeArticleDrawer
    from "../../../equipment/repository/drawer/FromWorkorderCreateKnowledgeArticleDrawer";
import EnumServeType from "../../../../../enum/servetype";

const redTextStyle = {
    fontSize: "16px",
    color: 'red',
    fontWeight: "bold"
}
const hasTextStyle = {
    fontSize: "16px",
    color: "#000",
    fontWeight: "bold"
}
/**
 * 信创工单预览 Drawer
 *
 * @description 预览信创工单
 *
 * @author Wayne Carder
 * @since 2021-06-23
 * @version 1.0.0
 */
export default class PreviewSmpWorkorderDrawer extends React.Component {

    columns = [
        {
            title: "ID",
            dataIndex: ["stocks", "stock", "id"],
            hideInSearch: true,
            hideInTable: true
        }, {
            title: "型号",
            dataIndex: ["stocks", "stock", "stocktype", "model"],
            hideInSearch: true
        },
        {
            title: "类型名称",
            dataIndex: ["stocks", "stock", "stocktype", "name"]
        },
        {
            title: "数量",
            dataIndex: ["stocks", "stock", "count"],
            hideInSearch: true
        }
    ];

    state = {
        drawerWidth: 280,
        show: false,
        skeletonLoading: true,
        workorder: {
            logs: []
        },
        workorderId: "",
        clientDepartmentInfo: {},
        clientInfo: {},
        stocks: []
    };

    onChange = false;
    hangupRef = React.createRef();
    reworkRef = React.createRef();
    feedbackRef = React.createRef();
    equipmentRef = React.createRef();
    chooseStorehouse = React.createRef();
    createKnowledgeArticleRef = React.createRef();

    show = id => {
        this.setState({
            show: true,
            workorderId: id,
            drawerWidth: window.innerWidth < 576 ? window.innerWidth : window.innerWidth * 0.55
        }, () => {
            this.requestWorkorder();
            this.requestWorkorderSolicitSpares();
        });
    };

    close = () => {
        this.setState({show: false, stocks: []}, () => {
            if (this.onChange && !!this.props.onSuccess) {
                this.props.onSuccess();
            }
        });
    };

    /**
     * 获取工单信息
     */
    requestWorkorder = () => {
        WorkorderApi.find(this.state.workorderId).then(response => {
            response.data.typeName = EnumServeType[response.data.type];
            console.log("Get workorder success", response.data)
            this.setState({workorder: response.data, skeletonLoading: false}, () => {

                this.requestClientDepartmentInfo();
                this.requestClientInfo();
            });
        }).catch(error => {
            console.warn("Get workorder failed", error);
        });
    };

    reloadWorkorder = () => {
        this.onChange = true;
        this.requestWorkorder();
        this.requestWorkorderSolicitSpares();
    };

    /**
     * 获取工单当前的进度
     */
    renderOptions = () => {
        // 1：创建；  5：已分配； 9：领取； 13：开始处理；14：挂起; 17：完成； 21：关闭
        let progress = this.state.workorder.progress;

        const dispatchButtonVisitable = progress === 1 && isUser(this.state.workorder.creatorId);

        const receiveButtonVisitable = progress === 5 && isUser(this.state.workorder.handlerId, false);
        const forwardButtonVisitable = progress === 5 && (isUser(this.state.workorder.handlerId) || isUser(this.state.workorder.creatorId));
        const deniedButtonVisitable = progress === 5 && isUser(this.state.workorder.handlerId, false);

        const startButtonVisitable = (progress === 9 || progress === 14) && isUser(this.state.workorder.handlerId, false);

        const receiveSparesButtonVisitable = (progress === 9 || progress === 14) && isUser(this.state.workorder.handlerId);

        const hangUpButtonVisitable = progress === 13 && isUser(this.state.workorder.handlerId, false);

        const finishButtonVisitable = progress === 13 && isUser(this.state.workorder.handlerId, false);

        const feedbackButtonVisitable = progress === 17 && this.state.workorder.feedbackScore === 0 && (hasAnyRole(["customer-service", "admin"]) || isUser(this.state.workorder.creatorId));

        const createKnowledgeButtonVisitable = progress === 17 && isUser(this.state.workorder.handlerId, false);

        // 暂时不开放关闭工单功能
        const closeButtonVisitable = false;
        return [
            receiveButtonVisitable ? <ReceiveWorkorderDialog key="receive" workorderId={this.state.workorderId}
                                                             deadLine={this.state.workorder.deadLine}
                                                             onSuccess={this.reloadWorkorder}/> : null,
            forwardButtonVisitable ? <ForwardWorkorderDialog key="forward" workorderId={this.state.workorderId}
                                                             onSuccess={this.reloadWorkorder}/> : null,
            startButtonVisitable ? <StartWorkorderDialog key="start" workorder={this.state.workorder}
                                                         onSuccess={this.reloadWorkorder}/> : null,

            receiveSparesButtonVisitable ?
                <Button key="receive"
                        onClick={() => this.chooseStorehouse.current.show(this.state.workorder.id)}>领用备件</Button> : null,

            hangUpButtonVisitable ?
                <Button key="hang" onClick={() => this.hangup(this.state.workorderId)}>挂起</Button> : null,

            finishButtonVisitable ? <FinishWorkorderDialog key="finish" workorderId={this.state.workorderId}
                                                           equipmentId={this.state.workorder.resourceId}
                                                           origin={this.state.workorder.origin}
                                                           workorderType={this.state.workorder.type}
                                                           onSuccess={this.reloadWorkorder}
                                                           onConnectWithDevice={this.reloadWorkorder}/> : null,

            deniedButtonVisitable ? <DeniedWorkorderDialog key="denied" workorderId={this.state.workorderId}
                                                           onSuccess={this.reloadWorkorder}/> : null,
            createKnowledgeButtonVisitable ?
                <Button key="knowledge"
                        onClick={() => this.createKnowledgeArticleRef.current.show(this.state.workorderId)}>创建知识库</Button> : null,

            feedbackButtonVisitable ?
                <><Button key="feedback" onClick={() => this.rework(this.state.workorderId)}>返工</Button>
                    <Button key="feedback" onClick={() => this.feedback(this.state.workorderId)}>回访</Button>
                </> : null
        ];
    };

    hangup = id => this.hangupRef.current.show(id);

    feedback = id => this.feedbackRef.current.show(id);
    rework = id => this.reworkRef.current.show(id);
    requestWorkorderSolicitSpares = () => {
        StorehouseInventoryApi.previewWorkorderSolicit({target: this.state.workorderId}).then(res => {
            if (Object.prototype.toString.call(res.data) === "[object Array]" && res.data.length !== 0) {
                let stocks = [];
                res.data.forEach(item => {
                    item.stocks.forEach(itemY => {
                        stocks.push({...item, stocks: itemY});
                    });
                });
                this.setState({stocks});
            }
        }).catch(err => {
            console.error("Request workorder solicit spares failed", err);
        });
    };

    /**
     * 工单进度
     * @param progress 工单进度值
     */
    workorderStepsRender = progress => {
        const current = progress === 14 ? 4 : this.convertWorkorderProgressToStep(progress);
        return <Steps current={current}>
            <Steps.Step title="待分配"/>
            <Steps.Step title="待领取"/>
            <Steps.Step title="待处理"/>
            <Steps.Step title="处理中"/>
            {progress === 14 ? <Steps.Step title="挂起中"/> : null}
            {progress === 0 ? <Steps.Step title="驳回" status="error"/> : <Steps.Step title="已完成"/>}
        </Steps>;
    };

    /**
     * 工单的进度值
     * @param progress
     * @returns {number}
     */
    convertWorkorderProgressToStep = progress => {
        switch (progress) {
            case 0:
                return 4;
            case 1:
                return 0;
            case 5:
                return 1;
            case 9:
                return 2;
            case 13:
                return 3;
            case 17:
                return 4;
            default:
                return 0;
        }
    };

    /**
     * 客户满意度
     */
    feedbackScoreRender = () => {
        return this.state.workorder.feedbackScore !== 0 ?
            <>
                {(hasAnyRole(["customer-service"]) && isUser(this.state.workorder.creatorId)) || hasAnyRole(["admin"]) ?
                    <ProDescriptions.Item label="客户满意度" span={4} dataIndex="feedbackScore" valueEnum={{
                        1: {text: "不满意"},
                        2: {text: "满意"},
                        3: {text: "非常满意"}
                    }}/> : null}

                {(hasAnyRole(["customer-service"]) && isUser(this.state.workorder.creatorId)) || hasAnyRole(["admin"]) ?
                    <ProDescriptions.Item label="客户评价" span={4} dataIndex="feedback"/> : null}
            </> : null;
    };

    /**
     * 获取客户单位信息
     */
    requestClientDepartmentInfo = () => {
        CustomerDepartmentApi.find(this.state.workorder.clientDepartmentId).then(res => {
            this.setState({clientDepartmentInfo: res.data});
        }).catch(err => {
            console.error("Get client department info fail", err);
        });
    };

    /**
     * 获取客户信息
     */
    requestClientInfo = () => {
        CustomerApi.find(this.state.workorder.clientId).then(res => {
            this.setState({clientInfo: res.data});
        }).catch(err => {
            console.error("Request client info fail", err);
        });
    };

    openEquipmentDetail = id => !!id && id !== "0" ? this.equipmentRef.current.show(id) : message.error({
        key: "equipment",
        content: "没有设备ID"
    });

    renderDepartmentInfo = (name, department = this.state.clientDepartmentInfo) => department && department[name] ? department[name] : "";

    render() {
        return <Drawer title="查看信创工单" width={this.state.drawerWidth} open={this.state.show}
                       destroyOnClose
                       onClose={this.close}>
            <Skeleton active loading={this.state.skeletonLoading}>
                <ProDescriptions bordered size="small" layout="horizontal"
                                 title={<span style={this.state.workorder.delayed === 1 ? redTextStyle : hasTextStyle}>工单编号：{this.state.workorderId}</span>}
                                 column={window.innerWidth < 800 ? 1 : 4}
                                 dataSource={this.state.workorder}
                                 extra={this.renderOptions()}>

                    {
                        window.innerWidth > 576 ? <ProDescriptions.Item label="进度" span={4}>
                            {this.workorderStepsRender(this.state.workorder.progress)}
                        </ProDescriptions.Item> : null
                    }

                    <ProDescriptions.Item label="服务类型" span={2} dataIndex="typeName"/>

                    <ProDescriptions.Item label="优先级" span={2} dataIndex="priority" valueEnum={{
                        1: {text: "低", color: "green"},
                        5: {text: "中", color: "yellow"},
                        9: {text: "高", color: "red"}
                    }}/>
                    <ProDescriptions.Item label="异常状态" span={2} dataIndex="abnormalStatusName"/>
                    <ProDescriptions.Item label="故障类型" span={2} dataIndex="faultName"/>
                    <ProDescriptions.Item label="具体问题" span={2} dataIndex="faultDetailName"/>
                    <ProDescriptions.Item label="当前进度" span={2} dataIndex="progress" valueEnum={{
                        0: {text: "驳回"},
                        1: {text: "待分配"},
                        5: {text: "待领取"},
                        9: {text: "待处理"},
                        13: {text: "处理中"},
                        14: {text: "挂起中"},
                        17: {text: "已完成"}
                    }}/>

                    <ProDescriptions.Item label="计划时间" span={2} dataIndex="deadLine" valueType="dateTime"/>

                    <ProDescriptions.Item label="预约时间" span={2} dataIndex="appointmentTime" valueType="dateTime"/>

                    <ProDescriptions.Item label="设备类型" span={2} dataIndex="resourceType"/>

                    <ProDescriptions.Item label="设备名称" span={2} dataIndex="resourceName"/>

                    <ProDescriptions.Item label="报修码" span={2}>
                        <a href="#" onClick={() => this.openEquipmentDetail(this.state.workorder.resourceId)}>{this.state.workorder.resourceId}</a>
                    </ProDescriptions.Item>

                    <ProDescriptions.Item label="客户单位" span={4}>
                        {this.renderDepartmentInfo("name")}
                    </ProDescriptions.Item>

                    <ProDescriptions.Item label="客户单位地址" span={4}>
                        {`${this.renderDepartmentInfo("province")} - ${this.renderDepartmentInfo("city")} - ${this.renderDepartmentInfo("county")} - ${this.renderDepartmentInfo("address")}`}
                    </ProDescriptions.Item>

                    <ProDescriptions.Item label="客户姓名" span={2}>
                        {this.renderDepartmentInfo("name", this.state.clientInfo)}
                    </ProDescriptions.Item>

                    <ProDescriptions.Item label="客户电话" span={2}>
                        <a href={`tel:${this.renderDepartmentInfo("cellphone", this.state.clientInfo)}`}>{this.renderDepartmentInfo("cellphone", this.state.clientInfo)}</a>
                    </ProDescriptions.Item>

                    <ProDescriptions.Item label="客户地址" span={4}>
                        {`${this.renderDepartmentInfo("province", this.state.clientInfo)} - ${this.renderDepartmentInfo("city", this.state.clientInfo)} - ${this.renderDepartmentInfo("county", this.state.clientInfo)} - ${this.renderDepartmentInfo("address", this.state.clientInfo)}`}
                    </ProDescriptions.Item>

                    <ProDescriptions.Item label="问题描述" span={4} dataIndex="description" valueType="textarea"/>

                    <ProDescriptions.Item label="工单附件" span={4}>
                        <WorkorderFileRender file={this.state.workorder.files}/>
                    </ProDescriptions.Item>

                    <ProDescriptions.Item label="处理结果" span={4} dataIndex="result" valueType="textarea"/>

                    <ProDescriptions.Item label="处理附件" span={4}>
                        <WorkorderFileRender file={this.state.workorder.resultFiles}/>
                    </ProDescriptions.Item>

                    {this.feedbackScoreRender()}

                    <ProDescriptions.Item label="已领备件列表" span={4}>
                        <ProTable headerTitle=""
                                  rowKey="id"
                                  defaultSize="small"
                                  search={false}
                                  options={false}
                                  columns={this.columns}
                                  pagination={false}
                                  dataSource={this.state.stocks}/>
                    </ProDescriptions.Item>

                    <ProDescriptions.Item label="工单日志" span={4}>
                        <Timeline>
                            {
                                this.state.workorder.logs.map((item, index) => {
                                    return <Timeline.Item key={index}>
                                        {item.createTime} &nbsp;&nbsp; {item.log}
                                        {
                                            !!item.comment ? <><br/>原因：{item.comment}</> : null
                                        }
                                    </Timeline.Item>;
                                })
                            }
                        </Timeline>
                    </ProDescriptions.Item>
                </ProDescriptions>
            </Skeleton>

            <HangUpWorkorderDialog key="hang" onSuccess={this.reloadWorkorder} ref={this.hangupRef}/>
            <FeedbackWorkorderDialog key="feedback" onSuccess={this.reloadWorkorder} ref={this.feedbackRef}/>
            <ReworkWorkorderDialog key="rework" onSuccess={this.reloadWorkorder} ref={this.reworkRef}/>
            <PreviewEquipmentDrawer key="equipment" onSuccess={this.reloadWorkorder} ref={this.equipmentRef}/>
            <ChooseStorehouse onSuccess={this.reloadWorkorder} ref={this.chooseStorehouse}/>
            <FromWorkorderCreateKnowledgeArticleDrawer onSuccess={this.reloadWorkorder}
                                                       ref={this.createKnowledgeArticleRef}/>
        </Drawer>;
    }
}
