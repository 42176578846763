import React, {Component} from "react";
import {Button, Col, Drawer, Form, Input, message, Radio, Row, Select, Space} from "antd";
import EquipmentTypeApi from "api/equipment/type";
import EquipmentMakerApi from "api/equipment/maker";
import {setFieldsError} from "plugin/formily";
import FileUploadRender from "component/FileUploadRender";
import DictApi from "../../../../../api/dict";
import EnumDict from "../../../../../enum/dict";

/**
 * 创建设备类型
 * 填写对应表单信息  调用新建设备类型接口 创建设备类型
 */
export default class CreateEquipmentTypeDrawer extends Component {

    state = {
        show: false,
        loading: false,
        drawerWidth: 600,
        radioValue: 1,
        selectOption: [],
        sommonTypeOption: []

    };

    formRef = React.createRef();
    filesRef = React.createRef();

    show = () => {
        this.setState({
            show: true,
            drawerWidth: window.innerWidth < 576 ? window.innerWidth : window.innerWidth * 0.55
        }, () => {
            this.formRef.current.setFieldsValue({
                classified: 0
            });
            this.getMaker();
             this.getCommonType();
        });
    };

    close = () => {
        this.setState({
            show: false,
            loading: false
        }, () => {
            this.formRef.current.setFieldsValue({
                name: null,
                model: null,
                manufacturerId: null,
                commonType: null,
                description: "",
                files: [],
                classified: null
            });
        });
    };

    toggleLoading = () => this.setState({loading: !this.state.loading});

    radioChange = e => this.setState({radioValue: e.target.value});

    /**
     * 获取所有制造商
     */
    getMaker = () => {
        EquipmentMakerApi.queryAll().then(res => {
            let selectData = [];
            res.data.forEach(item => {
                selectData.push({label: item.name, value: item.id});
            });
            this.setState({
                selectOption: selectData
            });
            console.log(selectData)
        }).catch(err => {
            console.error("Get maker fail", err);
        });
    };
    /**
     * 获取设备大类列表
     * @returns {Promise<void>}
     */

    getCommonType = () => {
        DictApi.list({code : EnumDict.DEVICE_COMMON_TYPE}
        ).then(res => {
            let selectData = [];
            res.data.forEach(item => {
                selectData.push({label: item.value, value: item.id});
            });
            console.log(selectData)
            this.setState({
                commonTypeOption: selectData
            });
        })
    };
    /**
     * 提交表单
     * @param data
     */
    handle = data => {
        this.toggleLoading();
        data.files = this.filesRef.current.getFilesValue();
        EquipmentTypeApi.create(data).then(response => {
            this.close();
            if (response.status === 20000 && !!this.props.onSuccess) {
                this.props.onSuccess();
            }
        }).catch(error => {
            if (error.status === 40000) {
                setFieldsError(error, this.formRef);
            } else {
                message.error({key: "failed", content: [error.data]});
            }
            this.toggleLoading();
            console.error("Create equipment error", error);
        });
    };

    validatorFile = (rule, value, callback) => {
        if (value === undefined || value.fileList.length < 1) {
            callback("请上传文件");
        }
        callback();
    };

    render() {
        return <Drawer title="新建设备类型"
                       destroyOnClose
                       width={this.state.drawerWidth}
                       open={this.state.show}
                       closable={!this.state.loading}
                       onClose={this.close}>
            <Form ref={this.formRef} onFinish={this.handle} layout={"vertical"}>
                <Row gutter={[8, 0]} align="middle">
                    <Col span={12}>
                        <Form.Item name="name" label="类型名称" required
                                   rules={[{required: true, message: "请输入类型名称"},
                                       {pattern: /\S/g, message: "内容不能为空"}]}>
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="model" label="设备型号" required
                                   rules={[{required: true, message: "请输入设备型号"},
                                       {pattern: /\S/g, message: "内容不能为空"}]}>
                            <Input/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[8, 0]}>
                    <Col span={12}>
                        <Form.Item name="manufacturerId" label="制造商" required
                                   rules={[{required: true, message: "请选择制造商"},
                                       {pattern: /\S/g, message: "内容不能为空"}]}>
                            <Select options={this.state.selectOption} optionFilterProp="label" showSearch/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="classified" label="是否SM" required
                                   rules={[{required: true, message: "请选择是否SM"}]}>
                            <Radio.Group onChange={this.radioChange} value={this.state.radioValue}>
                                <Radio value={0}>普通机</Radio>
                                <Radio value={1}>专用机</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                    <Form.Item name="commonType" label="设备大类"
                        required
                        rules={[{required: true, message: "请选择设备大类"},
                        {pattern: /\S/g, message: "内容不能为空"}]}>
                        <Select options={this.state.commonTypeOption} optionFilterProp="label" showSearch/>
                    </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[8, 0]}>
                    <Col span={24}>
                        <Form.Item name="description" label="类型说明">
                            <Input.TextArea/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <FileUploadRender name="files" uploadPath="/resource/device/type/file"
                                          ref={this.filesRef}/>
                        {/*<Form.Item name="files" label="附件上传" required rules={[{validator: this.validatorFile}]}>*/}
                        {/*    <Upload name="file" showUploadList*/}
                        {/*            headers={{token: localStorage.getItem("Token")}}*/}
                        {/*            action={global.config.url + "/resource/device/type/file"}*/}
                        {/*            onRemove={this.onRemoveFile}*/}
                        {/*            onChange={this.uploadOnChange}>*/}
                        {/*        <Button disabled={this.state.loading}>上传附件</Button>*/}
                        {/*    </Upload>*/}
                        {/*</Form.Item>*/}
                    </Col>
                </Row>
                <Row gutter={8} justify="end">
                    <Col>
                        <Space>
                            <Button disabled={this.state.loading} onClick={this.close}>取消</Button>
                            <Button loading={this.state.loading} type="primary" htmlType="submit">新建</Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </Drawer>;
    }
}

