import React from "react";
import {Button, Col, Row, Space, Typography} from "antd";

import EquipmentMakerApi from "api/equipment/maker";
import DraggableModal from "component/DraggableModal";


/**
 * 删除制造商
 * 根据对应制造商id 删除制造商
 */
export default class DeleteMakerModal extends React.Component {

    state = {
        loading: false,
        show: false,
        makerId: ""
    };

    draggableRef = React.createRef();


    show = id => {
        this.draggableRef.current.show();
        this.setState({
            makerId: id
        });
    };

    close = () => {
        this.setState({loading: false});
        this.draggableRef.current.close();
    };

    /**
     * 控制 loading 加载效果显示隐藏
     */
    toggleLoading = () => {
        this.setState({
            loading: !this.state.loading
        });
    };

    /**
     * 删除客户
     */
    handle = () => {
        this.toggleLoading();
        EquipmentMakerApi.delete(this.state.makerId).then(res => {
            this.close();
            this.props.onSuccess();
        }).catch(err => {
            this.toggleLoading();
            console.error("Delete maker fail", err);
        });
    };

    render() {
        return <DraggableModal onCancel={this.close} title="是否删除制造商" ref={this.draggableRef}>
            <Typography>数据删除后无法恢复，请谨慎操作！</Typography>
            <Row gutter={8} justify="end">
                <Col>
                    <Space>
                        <Button disabled={this.state.loading} onClick={this.close}>取消</Button>
                        <Button loading={this.state.loading} type="primary" onClick={this.handle}>删除</Button>
                    </Space>
                </Col>
            </Row>
        </DraggableModal>;
    }
}
