import React from "react";
import {Button, Col, Row, Space, TreeSelect} from "antd";

import DraggableModal from "component/DraggableModal";
import {ProForm, ProFormCascader, ProFormText, ProFormTextArea, ProFormTreeSelect} from "@ant-design/pro-components";
import MonitoringApi from "../../../../api/monitoring";
import DictApi from "../../../../api/dict";
import {buildTree, treeData} from "../../../../utils/tool";
import josn from "qs";

/**
 * 创建
 * 创建监控列表
 * @author xuwei
 */
export default class CreateDictModal extends React.Component {

    state = {
        loading: false,
        show: false,
        id: "0",
        dictData: [],
        TreeData : []
    };

    draggableRef = React.createRef();
    formRef = React.createRef();
    /**
     * 控制对话框显示隐藏
     */
    show = id => {
        this.draggableRef.current.show();
        this.getDictDatalist()
    };

    componentDidMount() {
        this.getDictDatalist()

    }

    close = () => {
        this.setState({loading: false});
        this.draggableRef.current.close();
    };

    /**
     * 控制 loading 加载显示
     */
    toggleLoading = () => {
        this.setState({
            loading: !this.state.loading
        });
    };

    /**
     * 获取所有字典
     */
    getDictDatalist = () => {
        DictApi.list().then(response => {
            if (!!response.data) {
                let data = buildTree(response.data, "0")
                let TreeData = treeData(data)
                let finallyTreeData = [
                    {
                        value: "0",
                        title: "根节点",
                        key: "0",
                        children:TreeData
                    }
                ]
                this.setState({
                    TreeData:finallyTreeData
                })

            }
        })
    };

    /**
     * 提交
     */
    handle = () => {
        this.toggleLoading();
        if(this.formRef.current){
            let { pid,code,enKey,value,description } = this.formRef.current.getFieldsValue();
            let data = {
                pid: pid ? pid : "0",
                code,
                enKey,
                value,
                description
            }
            DictApi.create(data).then(response => {
                this.props.onSuccess();
                this.close()
            })
            this.close()
        }

    };

    render() {
        return <DraggableModal onCancel={this.close} title="新建字典" ref={this.draggableRef}>
            <ProForm formRef={this.formRef} onFinish={this.handle} layout={"vertical"} submitter={{
                resetButtonProps: {style: {display: "none"}},
                submitButtonProps: {style: {display: "none"}}
            }}>
                <ProFormTreeSelect
                    label="选择父级"
                    name='pid'
                    initialValue={"0"}
                    fieldProps={{
                        fieldNames: {label: 'title',},
                        treeData:this.state.TreeData,
                        showCheckedStrategy: TreeSelect.SHOW_PARENT,
                        placeholder: '默认根节点',
                    }}
                />
                <ProFormText name="code" label="字典类型" placeholder="请输入类型" required
                             rules={[
                                 {required: true, message: "请输入类型"},
                                 {pattern: /\S/, message: "不能为空"}
                             ]}/>
                <ProFormText name="enKey" label="短名" placeholder="请输入短名" required
                             rules={[
                                 {required: true, message: "请输入描述"},
                                 {pattern: /\S/, message: "不能为空"}
                             ]}/>
                <ProFormText name="value" label="数据键值" placeholder="请输入键值" required
                             rules={[
                                 {required: true, message: "请输入键值"},
                                 {pattern: /\S/, message: "不能为空"}
                             ]}/>
                <ProFormTextArea name="description" label="描述" placeholder="请输入描述"
                                 rules={[
                                     {required: true, message: "请输入描述"},
                                     {pattern: /\S/, message: "不能为空"}
                                 ]}/>
            </ProForm>
            <Row gutter={8} justify="end">
                <Col>
                    <Space>
                        <Button disabled={this.state.loading} onClick={this.close}>取消</Button>
                        <Button loading={this.state.loading} type="primary" onClick={this.handle}>提交</Button>
                    </Space>
                </Col>
            </Row>
        </DraggableModal>;
    }
}
