import React from "react";
import {Button, Col, DatePicker, Drawer, Form, Input, message, Row, Skeleton, Space} from "antd";


import ContractApi from "api/contract/index";
import CustomerDepartmentPicker from "component/customer/department/CustomerDepartmentPicker";
import {setFieldsError} from "plugin/formily";
import FileUploadRender from "component/FileUploadRender";
import dayjs from "dayjs";
import CustomerDepartmentApi from "../../../../api/customer/department";
import {getUser} from "../../../../component/SecurityInterceptor";

/**
 * 编辑合同
 * 根据id 获取对应的合同数据
 * 请求客户所属单位数据  并将数据处理为想要的树形数据
 * 修改表单对应数据 请求合同编辑接口  编辑合同
 */
export default class EditContractDrawer extends React.Component {

    state = {
        show: false,
        loading: false,
        drawerWidth: 280,
        url: "",
        resourceId: "",
        departmentData: [],
        contractDataLoading: true,
        fileData: []
    };

    formRef = React.createRef();
    filesRef = React.createRef();

    show = async (id) => {
        this.setState({
            show: true,
            resourceId: id,
            drawerWidth: window.innerWidth < 576 ? window.innerWidth : window.innerWidth * 0.55
        });
        await this.requestDepartmentTree();
        this.getContractInfo();
    };

    close = () => {
        this.setState({
            show: false,
            loading: false,
            fileData: []
        });
    };

    requestDepartmentTree = async () => {
        const {data} = await CustomerDepartmentApi.page();
        const treeData = [];
        data.forEach(department => {
            treeData.push(this.handleDepartmentTreeNode(department));
        });
        this.setState({
            departmentData: treeData,
            contractDataLoading: false
        });
    };

    handleDepartmentTreeNode = (department) => {
        const node = {
            title: department.name,
            key: department.id,
            value: department.id,
            children: []
        };
        if (!!department.children && department.children.length > 0) {
            department.children.forEach((subDepartment) => {
                node.children.push(this.handleDepartmentTreeNode(subDepartment));
            });
        }
        return node;
    };

    /**
     * 获取合同信息
     */
    getContractInfo = () => {
        ContractApi.getContract(this.state.resourceId).then(response => {
            let files = [];
            let params = {
                clientCellphone: "",
                clientName: "",
                comment: response.data.comment,
                expireDate: "",
                name: response.data.name,
                salesmanCellphone: "",
                salesmanName: "",
                serviceContent: "",
                startDate: "",
                file: files,
                departmentId: 0
            };
            response.data.infos.forEach(item => {
                switch (item.name) {
                    case "服务范围":
                        params.serviceContent = item.value;
                        break;
                    case "合同开始时间":
                        params.startDate = dayjs(item.value, "YYYY-MM-DD");
                        break;
                    case "合同到期时间":
                        params.expireDate = dayjs(item.value, "YYYY-MM-DD");
                        break;
                    case "客户方联系人":
                        params.clientName = item.value;
                        break;
                    case "客户方电话":
                        params.clientCellphone = item.value;
                        break;
                    case "市场方负责人":
                        params.salesmanName = item.value;
                        break;
                    case "市场方电话":
                        params.salesmanCellphone = item.value;
                        break;
                    case "合同附件":
                        if (!!item.value && item.value !== "") {
                            files.push({
                                uid: item.id,
                                name: "合同附件",
                                url: item.value,
                                thumbUrl: item.value,
                                type: item.type
                            });
                            this.filesRef.current.setFilesValue(files);
                        }
                        break;
                    case "合同所属客户单位":
                        params.departmentId = item.value;
                        break;
                    default:
                        break;
                }
            });
            this.formRef.current.setFieldsValue(params);
        });
    };

    toggleLoading = () => {
        this.setState({
            loading: !this.state.loading
        });
    };

    handle = (data) => {
        let flag = dayjs(data.expireDate).format("YYYYMMDD") - dayjs(data.startDate).format("YYYYMMDD");
        if (flag < 0) {
            this.formRef.current.setFields([{name: "expireDate", errors: ["合同结束时间不能小于合同开始时间"]}]);
            return;
        }
        this.toggleLoading();
        const param = {};
        param.name = data.name;
        param.comment = data.comment;
        param.id = this.state.resourceId;
        param.status = 1;
        param.type = "contract";
        param.infos = [];
        param.infos.push({
            name: "合同开始时间",
            value: dayjs(data.startDate).format("YYYY-MM-DD")
        });
        param.infos.push({
            name: "服务范围",
            value: data.serviceContent
        });
        param.infos.push({
            name: "合同到期时间",
            value: dayjs(data.expireDate).format("YYYY-MM-DD")
        });
        param.infos.push({
            name: "合同所属客户单位",
            type: "string",
            value: data.departmentId
        });
        param.infos.push({
            name: "客户方联系人",
            value: data.clientName
        });
        param.infos.push({
            name: "客户方电话",
            value: data.clientCellphone
        });
        param.infos.push({
            name: "市场方负责人",
            value: data.salesmanName
        });
        param.infos.push({
            name: "市场方电话",
            value: data.salesmanCellphone
        });
        if (!!this.filesRef.current.getFilesValue() && this.filesRef.current.getFilesValue().length > 0) {
            this.filesRef.current.getFilesValue().forEach(item => {
                param.infos.push({
                    name: "合同附件",
                    type: "file",
                    value: item
                });
            });
        } else {
            param.infos.push({
                name: "合同附件",
                type: "file",
                value: ""
            });
        }
        ContractApi.update(param).then(response => {
            console.info("Update contract success", response);
            this.close();
            this.props.onSuccess();
        }).catch(error => {
            if (error.status === 40000) {
                setFieldsError(error, this.formRef);
            } else {
                message.error({key: "failed", content: error.data});
            }
            this.toggleLoading();
            console.warn("Create contract failed", error);
        });
    };

    render() {
        return <Drawer title="编辑合同" open={this.state.show} width={this.state.drawerWidth} destroyOnClose
                       footer={null}
                       onClose={this.close} closable={!this.state.loading}>
            <Skeleton active loading={this.state.contractDataLoading}>
                <Form ref={this.formRef} onFinish={this.handle} layout={"vertical"}>
                    <Row gutter={8}>
                        <Col span={12}>
                            <Form.Item label="合同名称" required name="name" rules={[{required: true, message: "请输入合同名称"}, {
                                pattern: /\S/,
                                message: "内容不能为空"
                            }]}>
                                <Input maxLength={60}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="合同编号" required name="comment"
                                       rules={[{required: true, message: "请输入合编号"}, {
                                           pattern: /\S/,
                                           message: "内容不能为空"
                                       }]}>
                                <Input maxLength={60}/>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label="服务范围" required name="serviceContent"
                                       rules={[{required: true, message: "请输入服务范围"}, {
                                           pattern: /\S/,
                                           message: "内容不能为空"
                                       }]}>
                                <Input.TextArea maxLength={60} showCount/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={8}>
                        <Col span={12}>
                            <Form.Item label="合同开始时间" required name="startDate"
                                       rules={[{required: true, message: "请选择合同开始时间"}]}>
                                <DatePicker format="YYYY-MM-DD" inputReadOnly style={{width: "100%"}}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="合同到期时间" required name="expireDate"
                                       rules={[{required: true, message: "请选择合同到期时间"}]}>
                                <DatePicker format="YYYY-MM-DD" inputReadOnly style={{width: "100%"}}/>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <CustomerDepartmentPicker name="departmentId" label="合同所属客户单位"
                                                      rules={[{required: true, message: "请选择客户单位"}]}/>
                        </Col>
                    </Row>
                    <Row gutter={8}>
                        <Col span={12}>
                            <Form.Item label="客户方联系人" required name="clientName"
                                       rules={[{required: true, message: "请输入客户联系人"}, {
                                           pattern: /\S/,
                                           message: "内容不能为空"
                                       }]}>
                                <Input maxLength={60}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="客户方电话" required name="clientCellphone"
                                       rules={[{required: true, message: "请输入客户电话"}, {
                                           pattern: /\S/,
                                           message: "内容不能为空"
                                       }]}>
                                <Input maxLength={60}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="市场方负责人" required name="salesmanName"
                                       rules={[{required: true, message: "请输入市场方负责人"}, {
                                           pattern: /\S/,
                                           message: "内容不能为空"
                                       }]}>
                                <Input maxLength={60}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="市场方电话" required name="salesmanCellphone"
                                       rules={[{required: true, message: "请输入市场方电话"}, {
                                           pattern: /\S/,
                                           message: "内容不能为空"
                                       }]}>
                                <Input maxLength={60}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={8}>
                        <Col span={24}>
                            <FileUploadRender uploadPath="/resource/resource/file"
                                              ref={this.filesRef} uploadProps={{maxCount: 1}}/>
                        </Col>
                    </Row>
                    <Row gutter={8} justify="end">
                        <Col>
                            <Space>
                                <Button disabled={this.state.loading} onClick={this.close}>取消</Button>
                                <Button loading={this.state.loading} type="primary" htmlType="submit">编辑合同</Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
            </Skeleton>
        </Drawer>;
    };
}
