import React from "react";
import {PageContainer, ProTable, TableDropdown} from "@ant-design/pro-components";

import {Button, Dropdown, Menu, message, Space, Tooltip} from "antd";
import {InstagramOutlined, MoreOutlined, StopOutlined} from "@ant-design/icons";

import CreateAccountModal from "./modal/CreateAccountModal";
import AddScoreModal from "./modal/AddScoreModal";
import DeleteAccountModal from "./modal/DeleteAccountModal";

import SetAccountIdentityDrawer from "./drawer/SetAccountIdentityDrawer";
import SetAccountGroupDrawer from "./drawer/SetAccountGroupDrawer";
import ResetPasswordModal from "./modal/ResetPasswordModal";
import SetAccountPermissionDrawer from "./drawer/SetAccountPermissionDrawer";
import SetAccountRoleDrawer from "./drawer/SetAccountRoleDrawer";
import EditAccountModal from "./modal/EditAccountModal";
import PreviewScoreDetailDrawer from "./drawer/PreviewScoreDetailDrawer";

import AccountApi from "api/user/account";
import {getUser, hasAnyRole, isUser} from "component/SecurityInterceptor";
import style from "./index.module.css";
import Authority from "../../../../component/Authority";

export default class Accounts extends React.Component {

    actionRef = null;

    resetRef = React.createRef();
    editRef = React.createRef();
    setGroupRef = React.createRef();
    setIdentityRef = React.createRef();
    setPermissionRef = React.createRef();
    setRoleRef = React.createRef();
    deleteRef = React.createRef();
    addScoreRef = React.createRef();
    previewScoreDetailRef = React.createRef();

    columns = [
        {
            title: "ID",
            hideInTable: true,
            hideInSearch: true,
            dataIndex: "id"
        },
        {
            title: "用户名",
            align: "center",
            editable: false,
            dataIndex: "username"
        },
        {
            title: "姓名", dataIndex: "name", align: "center"
        },
        {
            title: "所属机构", dataIndex: "departmentName", hideInSearch: true, align: "center",
            renderText: (node, entity) => entity?.departments[0]?.name
        },
        {
            title: "所在区县",
            key: "locate",
            valueType: "option",
            render: (node, entity) => entity.province === entity.city ? `${entity.province}-${entity.county}` : `${entity.province}-${entity.county}-${entity.city}`
        },
        {
            title: "手机号", dataIndex: "cellphone", align: "center"
        },
        {
            title: "角色",
            key: "roles",
            valueType: "option",
            render: (node, entity) => {
                if (entity.roles !== null && !!entity.roles) {
                    return entity.roles.map(item => item.name + "\t")
                }
            }
        },
        {
            title: "状态", dataIndex: "status", align: "center", valueEnum: {
                1: {
                    text: "启用", status: "Success", color: "green"
                }, 0: {
                    text: "禁用", status: "Error", color: "red"
                }
            }
        }, {
            title: "创建时间",
            hideInSearch: true,
            hideInForm: true,
            align: "center",
            editable: false,
            dataIndex: "createTime",
            valueType: "dateTime",
            hideInTable: true
        }, {
            title: "操作",
            valueType: "option",
            render: (node, entity, index, action) => this.renderColumnOptions(node, entity, index, action)
        }];

    options = {reload: true, setting: true, density: true};

    editable = {
        type: "single", onDelete: (key, row) => AccountApi.editDelete({data: {accountId: row.id}}).then(response => {
            console.info("Delete account success", response);
            return true;
        }).catch(error => {
            console.warn("Delete account failed", error);
            message.warn("删除失败：" + error.data).then();
            this.reload();
            return false;
        }), onSave: (key, row) => {
            return AccountApi.edit(row).then(response => {
                return true;
            }).catch(error => {
                console.warn("Edit account failed", error);
                message.warn("编辑失败：" + error.data).then();
                this.reload();
                return false;
            });
        }
    };

    renderColumnOptions = (node, entity, index, action) => {
        if (isUser(entity.id, false)) {
            return null;
        }
        return (<Space>
            {entity.status === 0 ? <Tooltip title="启用" key="start">
                <Button icon size="small" onClick={() => this.requestEditAccountStatus(entity.id, 1)}>
                    <InstagramOutlined/>
                </Button>
            </Tooltip> : <Tooltip title="禁用" key="disable">
                <Button icon danger size="small" onClick={() => this.requestEditAccountStatus(entity.id, 0)}>
                    <StopOutlined/>
                </Button>
            </Tooltip>}
            <Dropdown key="more" overlay={this.renderColumnOptionsMenu(entity, action)} trigger={["click"]}>
                <Tooltip title="更多" key="more"><Button icon size="small"><MoreOutlined/></Button></Tooltip>
            </Dropdown>
        </Space>);
    };

    renderColumnOptionsMenu = (entity, action) => {
        return <Menu>
            <Menu.Item key="0">
                <Tooltip key="edit"><span onClick={() => this.dialog(entity.id, this.editRef)}
                                          className={style.btn}>编辑</span></Tooltip>
            </Menu.Item>
            <Menu.Item key="1">
                <Tooltip key="reset"><span onClick={() => this.dialog(entity.id, this.resetRef)}
                                           className={style.btn}>重置密码</span></Tooltip>
            </Menu.Item>
            {/*<Menu.Item key="3">*/}
            {/*    <span onClick={() => this.dialog(entity.id, this.setGroupRef)} className={style.btn}>设置用户组</span>*/}
            {/*</Menu.Item>*/}
            {/*<Menu.Item key="4">*/}
            {/*    <span onClick={() => this.dialog(entity.id, this.setIdentityRef)} className={style.btn}>设置身份</span>*/}
            {/*</Menu.Item>*/}
            {/*<Menu.Item key="5">*/}
            {/*    <span onClick={() => this.dialog(entity.id, this.setPermissionRef)} className={style.btn}>设置权限</span>*/}
            {/*</Menu.Item>*/}
            {/*{*/}
            {/*    hasAnyRole() ? */}
            {/*        : null*/}
            {/*}*/}
            <Authority permission={['admin']}>
                <Menu.Item key="6">
                    <Tooltip key="setRole"><span onClick={() => this.dialog(entity.id, this.setRoleRef)}
                                                 className={style.btn}>设置角色</span></Tooltip>
                </Menu.Item>
            </Authority>

            <Menu.Item key="9">
                <Tooltip key="addScore"><span onClick={() => this.dialog(entity.id, this.addScoreRef)}
                                              className={style.btn}>添加积分</span></Tooltip>
            </Menu.Item>
            <Menu.Item key="10">
                <Tooltip key="addScore">
                    <span onClick={() => this.dialog(entity.id, this.previewScoreDetailRef)}
                          className={style.btn}>查看用户积分</span>
                </Tooltip>
            </Menu.Item>
            <Menu.Item key="7" danger>
                <Tooltip key="delete"><span onClick={() => this.dialog([entity.id], this.deleteRef)}
                                            className={style.btn}>删除</span></Tooltip>
            </Menu.Item>
        </Menu>;
    };

    /**
     *控制弹窗
     * @param id
     */
    dialog = (id, ref) => {
        ref.current.show(id);
    };

    requestPage = params => {
        if (!hasAnyRole(["admin"])) {
            const departmentId = getUser().departments.length > 0 && getUser().departments !== undefined && getUser().departments !== null ? getUser().departments[0].id : undefined;
            params.departmentId = departmentId;
        }
        return AccountApi.page(params).then(response => {
            this.actionRef.clearSelected();
            return {data: response.data.records, success: true, total: response.data.total};
        }).catch(error => {
            console.warn("Request account pages failed.", error);
            return {data: [], success: true, total: 0};
        });
    };

    requestEditAccountStatus = (id, status) => {
        AccountApi.userState({accountId: id, status}).then(() => {
            this.actionRef.reload();
        }).catch(error => {
            console.warn("Request edit account status failed.");
        });
    };

    reload = () => {
        this.actionRef?.reload();
    };

    option = {
        show: true, density: false, fullScreen: false, setting: true
    };

    render() {
        return <PageContainer header={{title: "账户管理", breadcrumb: {}}}
                              extra={<CreateAccountModal onSuccess={this.reload}/>}>
            <ProTable bordered
                      actionRef={actionRef => this.actionRef = actionRef}
                      columns={this.columns}
                      defaultSize={"small"}
                      editable={this.editable}
                      options={{density: false, fullScreen: false, setting: true}}
                      size="small"
                      rowKey="id"
                      request={params => this.requestPage(params)}/>

            <ResetPasswordModal onSuccess={this.reload} ref={this.resetRef}/>
            <EditAccountModal onSuccess={this.reload} ref={this.editRef}/>
            <SetAccountGroupDrawer onSuccess={this.reload} ref={this.setGroupRef}/>
            <SetAccountIdentityDrawer onSuccess={this.reload} ref={this.setIdentityRef}/>
            <SetAccountPermissionDrawer onSuccess={this.reload} ref={this.setPermissionRef}/>
            <SetAccountRoleDrawer onSuccess={this.reload} ref={this.setRoleRef}/>
            <DeleteAccountModal onSuccess={this.reload} ref={this.deleteRef}/>
            <AddScoreModal onSuccess={this.reload} ref={this.addScoreRef}/>
            <PreviewScoreDetailDrawer onSuccess={this.reload} ref={this.previewScoreDetailRef}/>
        </PageContainer>;
    }
}
