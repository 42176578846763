const DepartmentApi = {
    /**
     * 分页
     * @param params
     * @returns {Promise<axios.AxiosResponse<any>>}
     */
    page: (params) => global.axios.get("/user/departments/page", {params}),

    /**
     * 查询单位树形结构
     * @param departmentId
     * @returns {Promise<axios.AxiosResponse<any>>}
     */
    findDepartmentTree: departmentId => global.axios.get("/user/department/tree", {params: {departmentId}}),

    /**
     * 查询所有单位树
     * @returns {Promise<axios.AxiosResponse<any>>}
     */
    findAllTrees: () => global.axios.get("/user/department/trees"),

    //迁移单位
    move: (data) => global.axios.patch("/user/department/parent", data),

    //编辑
    edit: (params) => global.axios.patch("/user/account", params),

    //创建
    create: (params) => global.axios.post("/user/department", params),

    //删除
    delete: (id) => global.axios.delete(`/user/department/${id}`),

    //删除多个
    deletes: (params) => global.axios.delete("/user/departments", {data: params}),

    //获取所有用户
    getCustomer: (params = {}) => global.axios.get("/user/accounts", params),

    //获取无单位用户
    NoDepartmentCustomer: (params = {}) => global.axios.get("/user/accounts/no-department", params),

    //设置所有用户
    setCustomer: (params = {}) => global.axios.put("/user/department/account", params),

    //获取当前单位所有用户的ID
    getDepartmentApiUser: (params) => global.axios.get("/user/department/accounts/id", params),

    //查询单位详情
    getDepartmentDetail: (id) => global.axios.get("/user/department", {params: {id}}),

    //查询单位下所有用户
    getDepartmentAccount: (id) => global.axios.get(`/user/department/${id}/accounts`),

    //设置单位与客户单位
    setDepartmentCustomerDepartment: (data) => global.axios.put(`/user/department/customer-department`, data),

    //查询机构下的客户单位
    requestDepartmentCustomerDepartment: (department) => global.axios.get(`/customer/user-department/${department}/customer-department/tree`),

    //设置单位角色
    setDepartmentRoles: (data) => global.axios.put(`/user/department/role`, data),

    //移除角色
    removeRoles: data => global.axios.delete("/user/accounts/roles", {data}),

    //创建机构团队
    createTeam: (data) => global.axios.post("/user/team", data),

    //更新机构团队
    updateTeam: (data) => global.axios.patch("/user/team", data),

    //删除机构团队
    deleteTeam: (id) => global.axios.delete(`/user/team/${id}`),

    //查询机构团队分页
    getTeamList: (params) => global.axios.get(`/user/team/page`, {params}),

};
export default DepartmentApi;

