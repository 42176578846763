import React from "react";
import {useNavigate} from "react-router";
import {Button, Result} from "antd";

/**
 * @author       : AngelBeats
 * @date         : 2022/11/1 17:00
 * @description  : 404页面
 */
export default function NotFound() {

    const navigate = useNavigate();
    return (
        <Result
            status="404"
            title="404"
            subTitle="Sorry, the page you visited does not exist."
            extra={<Button type="primary" onClick={() => {
                navigate("/home/preview");
            }}>返回主页面</Button>}
        />
    );
}
