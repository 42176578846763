import React, {Component} from "react";
import {Button, Cascader, Col, Form, Input, message, Row, Space} from "antd";
import EquipmentMakerApi from "api/equipment/maker";
import {setFieldsError} from "plugin/formily";
import DraggableModal from "component/DraggableModal";


/**
 * 编辑制造商信息
 * 修改表单信息
 * 调用编辑制造商接口  修改制造商信息
 */
export default class EditMakerModal extends Component {


    formRef = React.createRef();
    draggableRef = React.createRef();

    state = {
        show: false,
        loading: false,
        makerId: ""
    };

    show = id => {
        this.draggableRef.current.show();
        this.setState({makerId: id}, this.getMakerInfo);
    };

    close = () => {
        this.setState({loading: false});
        this.draggableRef.current.close();
    };

    toggleLoading = () => {
        this.setState({
            loading: !this.state.loading
        });
    };

    getMakerInfo = () => {
        EquipmentMakerApi.info(this.state.makerId).then(res => {
            console.log(res);
            this.formRef.current.setFieldsValue({
                name: res.data.name,
                ares: [res.data.province, res.data.city, res.data.county],
                contacts: res.data.contacts,
                contactsCellphone: res.data.contactsCellphone,
                address: res.data.address,
                description: res.data.description
            });
        }).catch(error => {
            console.error("Get maker info fail", error);
        });
    };

    handle = data => {
        this.toggleLoading();
        if (!!data.ares) {
            data.province = data.ares[0];
            data.city = data.ares[1];
            data.county = data.ares[2];
            data.ares = undefined;
        }
        data.id = this.state.makerId;

        EquipmentMakerApi.edit(data).then(() => {
            this.close();
            this.props.onSuccess();
        }).catch(error => {
            if (error.status === 40000) {
                setFieldsError(error, this.formRef);
            } else {
                message.error({key: "failed", content: error.data});
            }
            this.toggleLoading();
            console.error("Create equipment maker fail", error);
        });
    };


    render() {
        return <DraggableModal onCancel={this.close} title="编辑制造商" ref={this.draggableRef}>
            <Form ref={this.formRef} onFinish={this.handle} layout="vertical">
                <Row gutter={[8, 0]}>
                    <Col span={24}>
                        <Form.Item name="name" label="厂商名字" required
                                   rules={[{required: true, message: "请输入厂商名字"}, {pattern: /\S/, message: "内容不能为空"}]}>
                            <Input/>
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item name="ares" label="所在区县">
                            <Cascader options={require("asset/location.json")}/>
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item name="address" label="所在地址">
                            <Input/>
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item name="contacts" label="联系人名字">
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name="contactsCellphone" label="联系电话">
                            <Input/>
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item name="description" label="描述">
                            <Input.TextArea/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={8} justify="end">
                    <Col>
                        <Space>
                            <Button disabled={this.state.loading} onClick={this.close}>取消</Button>
                            <Button loading={this.state.loading} type="primary" htmlType="submit">编辑</Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </DraggableModal>;
    }
}
