import React, {Component} from "react";
import {Button, Col, Row, Space, Typography} from "antd";

import EquipmentTypeApi from "api/equipment/type";
import DraggableModal from "component/DraggableModal";

/**
 * 删除设备类型
 * 根据对应id 删除对应设备类型
 */
export default class DeleteEquipmentTypeModal extends Component {
    state = {
        loading: false,
        show: false,
        equipmentId: ""
    };

    draggableRef = React.createRef();

    show = id => {
        this.draggableRef.current.show();
        this.setState({equipmentId: id});
    };

    close = () => this.draggableRef.current.close();

    toggleLoading = () => this.setState({loading: !this.state.loading});

    handle = () => {
        this.toggleLoading();
        EquipmentTypeApi.delete(this.state.equipmentId).then(response => {
            this.toggleLoading();
            this.close();
            this.props.onSuccess();
        });
    };

    render() {
        return <DraggableModal onCancel={this.close} title="是否删除设备类型" ref={this.draggableRef}>
            <Typography>数据删除后无法恢复，请谨慎操作！</Typography>
            <Row gutter={8} justify="end">
                <Col>
                    <Space>
                        <Button disabled={this.state.loading} onClick={this.close}>取消</Button>
                        <Button loading={this.state.loading} type="primary" onClick={this.handle}>删除</Button>
                    </Space>
                </Col>
            </Row>
        </DraggableModal>;
    }
}

