import React from "react";
import {Button, Col, Row, Space} from "antd";

import WorkorderApi from "api/workorder";
import {ProForm, ProFormCascader, ProFormTextArea} from "@ant-design/pro-components";
import {setFieldsError} from "plugin/formily";
import DraggableModal from "component/DraggableModal";

/**
 * 转发工单弹窗 Modal
 *
 * @description 转发工单
 *
 * @author huang Carder
 * @since 2021-06-23
 * @version 1.0.0
 */
export default class ForwardWorkorderDialog extends React.Component {

    state = {
        loading: false,
        show: false
    };

    handlerOptions = [];

    /**
     * 放生命周期----使转发人搜索有效
     */
    componentDidMount() {
        this.requestWorkorderHandler();
    }

    formRef = React.createRef();
    draggableRef = React.createRef();


    show = () => this.draggableRef.current.show();

    close = () => {
        this.setState({loading: false});
        this.draggableRef.current.close();
    };

    /**
     * 控制 loading 加载显示
     */
    toggleLoading = () => this.setState({loading: !this.state.loading});

    /**
     * 查询所有工单处理人
     */
    requestWorkorderHandler = async () => {
        global.axios.get("/user/accounts").then(response => {
            const provinces = this.handleHandlers(response.data);
            for (let key in provinces) {
                this.handlerOptions.push(provinces[key]);
            }
            for (let i = 0; i < this.handlerOptions.length; i++) {
                for (let key in this.handlerOptions[i].test) {
                    if (!this.handlerOptions[i]["children"]) {
                        this.handlerOptions[i]["children"] = [];
                    }
                    this.handlerOptions[i]["children"].push(this.handlerOptions[i].test[key]);

                    this.handlerOptions[i]["children"].forEach((item) => {
                        item["children"] = [];
                        for (let key in item.test) {
                            item["children"].push(item.test[key]);
                        }
                    });

                }
            }
        }).catch(error => {
            console.warn("Get handlers failed", error);
        });
    };

    /**
     * 获取所有工单处理人的地址
     * @param handlers
     * @returns {{}}
     */
    handleHandlers = handlers => {
        const provinces = {};
        for (const handler of handlers) {
            // 初始化当前用户所在省份
            let province;
            if (!!provinces[handler.province]) {
                province = provinces[handler.province];
            } else {
                province = {
                    label: handler.province,
                    value: handler.province,
                    test: {}
                };
                provinces[handler.province] = province;
            }

            // 初始化当前用户所在市
            let city;
            if (!!province.test[handler.city]) {
                city = province.test[handler.city];
            } else {
                city = {
                    label: handler.city,
                    value: handler.city,
                    test: {}
                };
                province.test[handler.city] = city;
            }

            let county;
            // 初始化当前用户所在区县
            if (!!city.test[handler.county]) {
                county = city.test[handler.county];
            } else {
                county = {
                    label: handler.county,
                    value: handler.county,
                    children: []
                };
                city.test[handler.county] = county;
            }

            county.children.push({
                label: `${handler.name}`,
                value: handler.id
            });
        }
        return provinces;
    };

    /**
     * 转发工单
     * @param data 表单数据
     */
    handle = data => {
        this.toggleLoading();
        const parms = {
            workorderId: this.props.workorderId,
            handlerId: data.handlerId[3],
            reason: data.reason
        };
        WorkorderApi.forward(parms).then(response => {
            this.close();
            if (!!this.props.onSuccess) {
                this.props.onSuccess();
            }
        }).catch(error => {
            this.toggleLoading();
            console.warn("Forward workorder failed", error);
            setFieldsError(error,this.formRef);
        });
    };

    trigger = () => <Button onClick={this.show}>转发</Button>;

    filter = (inputValue, path) => path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);

    dialog = () => {
        return <DraggableModal onCancel={this.close} title="转发工单" ref={this.draggableRef}>
            <ProForm formRef={this.formRef} onFinish={this.handle} layout={"vertical"} submitter={{
                resetButtonProps: {style: {display: "none"}},
                submitButtonProps: {style: {display: "none"}}
            }}>
                <ProFormCascader required rules={[{required: true, message: "请选择指派人"}]} name="handlerId" label="处理人"
                                 fieldProps={{
                                     options: this.handlerOptions,
                                     onChange: this.onHandlerChange,
                                     showSearch: this.filter
                                 }}/>
                <ProFormTextArea name="reason" label="转发原因" placeholder="请填写转发原因"
                                 fieldProps={{maxLength: 120, showCount: true}}
                                 rules={[{required: true, message: "请输入转发原因"}, {pattern: /\S/, message: "不能为空"}]}/>
                <Row gutter={8} justify="end">
                    <Col>
                        <Space>
                            <Button disabled={this.state.loading} onClick={this.close}>取消</Button>
                            <Button loading={this.state.loading} type="primary" htmlType="submit">转发</Button>
                        </Space>
                    </Col>
                </Row>
            </ProForm>
        </DraggableModal>;
    };

    render() {
        return <>
            {this.trigger()}
            {this.dialog()}
        </>;
    }
}
