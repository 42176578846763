import React, {Component} from "react";
import {PageContainer, ProTable} from "@ant-design/pro-components";
import {Button, DatePicker, Tooltip} from "antd";
import {feedbackStatus} from "../../../type/Types";
import { EditOutlined } from "@ant-design/icons";
import RemoteApi from "../../../api/remote/RemoteApi";
import FeedBackModal from "./modal/FeedBackModal";

/**
 * @author       : AngelBeats
 * @date         : 2023-06-01 15:38:15
 * @description  : 远程意见反馈
 */
class RemoteFeedBack extends Component {

    actionRef = null

    editRef = React.createRef();

    columns = [
        {
            title: "设备识别码",
            dataIndex: "sn",
            editable: false
        },
        {
            title: "名称",
            dataIndex: "name",
            hideInSearch: true
        },
        {
            title: "联系方式",
            dataIndex: "phone",
            hideInSearch: true
        },
        {
            title: "建议内容",
            dataIndex: "feedbackDes",
            hideInSearch: true,
        },

        // {
        //     title: "管理员",
        //     dataIndex: ["customerObj", "name"],
        //     hideInSearch: true,
        //     hideInTable: true,
        //     editable: false
        // },
        // {
        //     title: "设备单位",
        //     dataIndex: ["customerDepartmentObj", "name"],
        //     formItemProps: {name: "customerDepartmentIds"},
        //     editable: false,
        //     valueType: "treeSelect",
        //     fieldProps: {
        //         showSearch: true,
        //         debounce: 500,
        //         fieldNames: {label: "name", value: "id"},
        //         treeNodeFilterProp: "name"
        //     },
        //     request: () => CustomerDepartmentApi.tree(getUser().departments[0].id).then(res => res.data).catch(() => []),
        // },
        {
            title: "状态",
            dataIndex: "status",
            // hideInSearch: true,
            valueEnum: feedbackStatus
        },
        {
            title: "创建时间",
            dataIndex: "time",
            hideInTable: true,
            renderFormItem: (_, {fieldProps}) => {
                return (
                    <DatePicker.RangePicker {...fieldProps} inputReadOnly/>
                );
            }
        },
        {
            title: "操作",
            valueType: "option",
            render: (node, entity, index, action) => this.renderColumnOperations(node, entity, index, action)
        }
    ]
    /**
     * 表格操作项
     * @param node
     * @param entity
     * @param index
     * @param action
     */
    renderColumnOperations = (node, entity, index, action) => [

        <Tooltip title="反馈" key="edit">
            <Button icon size="small" onClick={() => this.editRef.current.show(entity.id)}>
                <EditOutlined/>
            </Button></Tooltip>,

    ];
    /**
     * 渲染顶部按钮
     * @returns {JSX.Element[]}
     */
    renderToolbar = () => [
        // <Button key="create" type="primary" onClick={() => this.createRef.current.show()}>新建设备</Button>
    ]

    /**
     * 请求列表数据
     * @param params
     */
    requestPage = params => RemoteApi.feedBackPage(params).then(response => {
        return {data: response.data.records, success: true};
    }).catch(error => {
        console.warn("Get remote device list failed", error);
        return {data: [], success: true, total: 0};
    });
    /**
     * 表格页刷新
     * @returns {*}
     */
    reload = () => this.actionRef?.reload();

    render() {
        return (
            <PageContainer header={{title: "远控意见反馈", breadcrumb: {}}} extra={this.renderToolbar()}>
                <ProTable
                    rowKey="id"
                    defaultSize="small"
                    options={{reload: true, setting: true, density: true}}
                    actionRef={actionRef => this.actionRef = actionRef}
                    columns={this.columns}
                    request={this.requestPage}/>
                <FeedBackModal ref={this.editRef} onSuccess={this.reload}/>
            </PageContainer>
        )
    }
}
export default RemoteFeedBack
