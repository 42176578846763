import React, {Component} from 'react';
import {Alert, Button, Card, Col, Divider, Form, Input, message, Popconfirm, Row, Space, Typography} from "antd";
import DraggableModal from "../../../../component/DraggableModal";
import WorkorderApi from "../../../../api/workorder";
import {withNavigation} from "../../../../utils/RouteLink";
import HistorApi from "../../../../api/remote/HistoryApi";
import RemoteApi from "../../../../api/remote/RemoteApi";

/**
 * @author       : AngelBeats
 * @date         : 2024-09-19 16:07:05
 * @description  : 使用远程设备
 */
class UseRemoteModal extends Component {

    draggableRef = React.createRef();

    state = {
        loading: false,
        alertLoading : false,
        id: null,
        addr: "",
        sid: "",
        deviceName:"",
        deviceNumber: ""
    }

    /**
     * 显示
     */
    show = (item,addr,sid) => {
        this.draggableRef.current.show();
        this.setState({id:item.id,addr,sid}, ()=>{
            // console.log("show",item)
            localStorage.setItem('relevancesn',item.sn)
            this.requestRemoteDeviceInfo(item.id);
        })
    }

    /**
     * 隐藏
     */
    close = () => {
        this.draggableRef.current.close();
        this.setState({alertLoading: false})
    }

    /**
     * 查询远程设备信息
     */
    requestRemoteDeviceInfo = id => {
        RemoteApi.find(id).then(res => {
            this.setState({
                deviceName:res.data.name,
                deviceNumber:res.data.sn
            })
        })
    }

    /**
     * 显示loading
     */
    showLoading = () => this.setState({loading: true});

    /**
     * 隐藏loading
     */
    closeLoading = () => this.setState({loading: false})

    /**
     * 清楚输入框
     */
    clearInput = () => {
        this.setState({alertLoading: false})
    }
    /**
     * 查询工单是否存在
     * */
    requestWorkorder = id => {
        return WorkorderApi.find(id).then(response => {
            // 执行关联工单
            this.relevanceWorkorder(response.data)
        }).catch(error => {
            message.error("关联失败！");
            this.setState({alertLoading: true})
        });
    }

    /**
     * 关联
     */
    relevanceWorkorder = (data) => {
        let workorderData = {
            associatedWorkorderNumber:data ? data.id : null,
            deviceName:this.state.deviceName,
            deviceNumber:this.state.deviceNumber
        }
        HistorApi.associatedWorkorder(workorderData).then(res=>{
            localStorage.setItem("recordId",res.data)
            message.success("关联成功！");
            this.newStart()
        })
    }
    /**
     * 删除
     */
    handle = (data) => {
       if(data.workorderid){
           this.requestWorkorder(data.workorderid)
       }else {
           this.relevanceWorkorder()
       }
    }

    /**
     * 开始远程
     * */
    Start = (addr,sid) => {
        //const {addr,sid} = this.state
        window.open(`/resource/demo-sunlogin-sdk.html?address=${addr}&cid=${sid}`);
    }
    //新版
    newStart = () => {
        const {addr,sid} = this.state
        window.open(`/resource/demo-sunlogin-sdk.html?address=${addr}&cid=${sid}`, 'Popup','width=900,height=600');
        this.props.onSuccess() //打开直播窗口
    }
    render() {
        return (<DraggableModal title="使用远程设备" ref={this.draggableRef} onCancel={this.close}>
            <Form onFinish={this.handle}>
                    <Form.Item label="关联工单号:" name="workorderid">
                        <Input allowClear onChange={this.clearInput} placeholder="请输入工单号"/>
                    </Form.Item>
                    {this.state.alertLoading && <Typography.Text type="danger">系统不存在该工单，请联系管理员查询情况!</Typography.Text>}
                <Row gutter={8} justify="end">
                    <Col>
                        <Space>
                            <Button disabled={this.state.loading} onClick={this.close}>取消</Button>
                            <Button loading={this.state.loading} type="primary" htmlType="submit">确定</Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </DraggableModal>)
    }
}

export default UseRemoteModal;