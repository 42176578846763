import React, {Component} from 'react';

import styles from "./RemoteLoading.module.css"
import {Spin} from "antd";
import {SyncOutlined} from "@ant-design/icons";

/**
 * @author       : AngelBeats
 * @date         : 2023-06-02 14:14:09
 * @description  : 连接加载弹窗
 */
export default class RemoteLoading extends Component {

    state = {
        show: false
    }

    show = () => this.setState({show: true})

    close = () => this.setState({show: false})

    render() {
        return (<>
            {
                this.state.show ? <div className={styles.loading}>
                    <Spin tip="正在连接中" size="large" indicator={<SyncOutlined style={{fontSize: 40}} spin/>}/>
                </div> : null
            }
        </>)
    }
}
