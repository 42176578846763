import React, {Component} from "react";
import {Button, Col, Row, Skeleton, Space, Tree} from "antd";
import CustomerDepartmentApi from "api/customer/department";
import DepartmentApi from "api/user/department";
import DraggableModal from "component/DraggableModal";
import {getUser} from "../../../../../component/SecurityInterceptor";

/**
 * 设置单位与客户单位关联
 * 获取所有客户单位
 * 在数据的开头 添加  {id: "admin", name: "所有单位"}  数据项
 * 选中 所有单位时  所有的客户单位全部勾选
 * 选中 父单位时  改父单位下的所有子单位全部勾选
 * 当父单位下的所有子单位全部选中时  只把父单位的id 作为参数 传到后端
 * 选择  某一个 单位时  把改单位id 传入后端
 * 整合处理所有选中的客户单位id  调用 设置单位与客户单位关联 接口
 */
export default class SetDepartmentCustomerDepartmentModal extends Component {

    state = {
        show: false,
        treeData: [],
        checkedKeys: [],
        department: {},
        loadingDepartmentDetails: true,
        loadingCustomerDepartments: true
    };

    draggableRef = React.createRef();

    show = id => {
        this.draggableRef.current.show();
        this.requestDepartmentDetails(id);
    };

    close = () => {
        this.setState({loading: false});
        this.draggableRef.current.close();
    };

    requestDepartmentDetails = id => DepartmentApi.getDepartmentDetail(id).then(res => {
        this.setState({loadingDepartmentDetails: false, department: res.data}, this.requestCustomerDepartmentTree);
    }).catch(err => {
        this.setState({loadingDepartmentDetails: false, loadingCustomerDepartments: false});
        console.error("Request department details failed.", err);
    });

    requestCustomerDepartmentTree = () => CustomerDepartmentApi.tree(getUser().departments[0].id).then(res => {
        res.data.unshift({id: "admin", name: "所有单位"});
        this.setState({
            treeData: this.processCustomerDepartmentTreeData(res.data),
            loadingCustomerDepartments: false
        }, this.checkDepartmentCustomerDepartment);
    }).catch(err => {
        this.setState({loadingCustomerDepartments: false});
        console.error("Request customer department tree failed.", err);
    });

    /**
     * 加工服务端返回的树形结构
     * @description 对树形结构进行翻译：id - key, name - title
     */
    processCustomerDepartmentTreeData = tree => tree.map(item => ({
        ...item,
        key: item.id,
        title: item.name,
        children: !!item.children ? this.processCustomerDepartmentTreeData(item.children) : undefined
    }));

    checkDepartmentCustomerDepartment = () => {
        const isAdminDepartment = !!this.state.department.roles && this.state.department.roles.some(item => item.identifier === "admin");
        if (isAdminDepartment) {
            const checkCustomerDepartmentKeys = [];
            this.checkAllCustomerDepartment(this.state.treeData, checkCustomerDepartmentKeys);
            this.setState({checkedKeys: checkCustomerDepartmentKeys});
        } else {
            this.setState({checkedKeys: !!this.state.department.customerDepartmentIds ? this.state.department.customerDepartmentIds : []});
        }
    };

    /**
     * 设置单位客户单位
     */
    handle = () => {
        const isAdminDepartment = !!this.state.department.roles && this.state.department.roles.some(item => item.identifier === "admin");
        const isSetDepartmentAdminRole = !isAdminDepartment && this.state.checkedKeys.includes("admin");
        if (isAdminDepartment && this.state.department.customerDepartmentIds === this.state.checkedKeys) {
            // 如果选中的单位没有变化，则直接关闭弹窗，不用执行请求
            this.close();
            return;
        }
        if (isAdminDepartment && this.state.checkedKeys.includes("admin")) {
            // 当前单位为管理员单位，并且没有去除管理员单位选项（即选中所有）
            // 直接关闭弹窗，不用处理任何业务逻辑
            this.close();
            return;
        }
        if (isSetDepartmentAdminRole) {
            this.setDepartmentAdminRole();
            return;
        }
        const customerDepartmentId = JSON.parse(JSON.stringify(this.state.checkedKeys));
        this.slimCheckedCustomerDepartment(this.state.treeData, customerDepartmentId);
        DepartmentApi.setDepartmentCustomerDepartment({
            customerDepartmentId,
            departmentId: this.state.department.id
        }).then(() => {
            const isNeedRemoveDepartmentAdminRole = isAdminDepartment && !this.state.checkedKeys.includes("admin");
            if (isNeedRemoveDepartmentAdminRole) {
                this.removeDepartmentAdminRole();
            } else {
                this.onSetDepartmentCustomerDepartmentSuccess();
            }
        }).catch(this.onSetDepartmentCustomerDepartmentFailed);
    };

    /**
     * 将单位设置为管理员单位（为单位增加管理员角色）
     */
    setDepartmentAdminRole = () => {
        const params = {departmentId: this.state.department.id, roleIds: [1]};
        DepartmentApi.setDepartmentRoles(params).then(this.onSetDepartmentCustomerDepartmentSuccess).catch(this.onSetDepartmentCustomerDepartmentFailed);
    };

    /**
     * 移除单位的管理员角色
     */
    removeDepartmentAdminRole = () => {
        const params = {departmentId: this.state.department.id, roleIds: []};
        DepartmentApi.setDepartmentRoles(params).then(this.onSetDepartmentCustomerDepartmentSuccess).catch(this.onSetDepartmentCustomerDepartmentFailed);
    };

    checkAllCustomerDepartment = (tree, checkKeys) => {
        for (let treeElement of tree) {
            checkKeys.push(treeElement.id);
            if (!!treeElement.children) {
                this.checkAllCustomerDepartment(treeElement.children, checkKeys);
            }
        }
    };

    /**
     * 在请求接口时对选中的数据进行清洗和降噪
     */
    slimCheckedCustomerDepartment = (tree, checkedKeys) => {
        for (let treeElement of tree) {
            if (!!treeElement.children) {
                // 如果当前单位有子单位，先对子单位进行降噪
                this.slimCheckedCustomerDepartment(treeElement.children, checkedKeys);
            }
            // 当一个单位下的子单位全部选中时，父单位就会被默认选中，此时我们在选中单位的数组中清除这些被选中的子单位，保留父单位即可
            // 当父单位被选中时，从选中的单位中剔除自己的 ID
            if (this.state.checkedKeys.includes(treeElement.parentId)) {
                checkedKeys.splice(checkedKeys.indexOf(treeElement.id), 1);
            }
        }
    };

    /**
     * 设置单位客户单位成功
     */
    onSetDepartmentCustomerDepartmentSuccess = () => {
        this.close();
        this.props.onSuccess();
    };

    /**
     * 设置单位客户单位失败
     */
    onSetDepartmentCustomerDepartmentFailed = (err) => {
        console.error("Set department customer department failed", err);
    };

    onCustomerDepartmentCheck = (checkedKeys, {checked, node}) => {
        if (node.id === "admin") {
            if (checked) {
                const checkCustomerDepartmentKeys = [];
                this.checkAllCustomerDepartment(this.state.treeData, checkCustomerDepartmentKeys);
                this.setState({checkedKeys: checkCustomerDepartmentKeys});
            } else {
                this.setState({checkedKeys: []});
            }
            return;
        }
        if (checkedKeys.includes("admin")) {
            checkedKeys.splice(checkedKeys.indexOf("admin"), 1);
        }
        this.setState({checkedKeys});
    };

    render() {
        return <DraggableModal onCancel={this.close} title="设置单位与客户单位" ref={this.draggableRef}>
            <Skeleton active loading={this.state.loadingDepartmentDetails || this.state.loadingCustomerDepartments}>
                <Tree height={320}
                      blockNode
                      checkable
                      selectable={false}
                      treeData={this.state.treeData}
                      checkedKeys={this.state.checkedKeys}
                      onCheck={this.onCustomerDepartmentCheck}/>
                <Row gutter={8} justify="end">
                    <Col>
                        <Space>
                            <Button disabled={this.state.loading} onClick={this.close}>取消</Button>
                            <Button loading={this.state.loading} type="primary" onClick={this.handle}>设置</Button>
                        </Space>
                    </Col>
                </Row>
            </Skeleton>
        </DraggableModal>;
    }
}
