import AccountApi from "../api/user/account";

const handleCreateIdToCreateName = async (data, createId = "creator", creatorName = "creatorName") => {
    let creatorId = [];
    let accountData = null;
    data.forEach(item => {
        if (!creatorId.includes(item[createId])) {
            creatorId.push(item[createId]);
        }
    });
    if (creatorId.length > 0) {
        accountData = await requestAccount(creatorId.join(","));
        accountData.forEach(item => {
            data.forEach(itemY => {
                if (item.id === itemY[createId]) {
                    itemY[creatorName] = item.name;
                }
            });
        });
    }
    return data;
};

const requestAccount = async (ids) => {
    const {data} = await AccountApi.batchRequestAccount({ids});
    return data;
};

export default handleCreateIdToCreateName;
