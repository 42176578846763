import React, {Component} from "react";
import style from "./index.module.css";
import workorderInfoApi from "api/workorderInfo";
import {Spin} from "antd";
import {isAdmin} from "component/SecurityInterceptor";
import {withNavigation} from "utils/RouteLink";

/**
 * 服务概览
 * @description 已接入客户单位数量统计
 */
class DepartmentStatistic extends Component {
    state = {
        loading: false,
        departmentNum: ""
    };

    componentDidMount() {
        this.getDepartmentNumber();
    };


    getDepartmentNumber = () => {
        this.setState({
            loading: true
        });
        workorderInfoApi.departmentNumber().then(response => {
            this.setState({departmentNum: response.data, loading: false});
        }).catch(error => {
            console.log("Get department number filed", error);
            this.setState({departmentNum: "-", loading: false});
        });
    };

    render() {
        return (
            <div className={style.systemDataItem}
                 onClick={() => isAdmin() ? this.props.navigate("/home/customer/departments") : null}>
                <p>已接入客户单位数</p>
                {this.state.loading ? <Spin/> : <p>{this.state.departmentNum}</p>}
            </div>
        );
    }
}

export default withNavigation(DepartmentStatistic);
