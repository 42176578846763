import React from 'react';
import {message, Select} from "antd";

/**
 * @author       : AngelBeats
 * @date         : 2023-02-24 10:21:15
 * @description  : 渲染表单项下拉搜索
 */


export default function RenderFormSelectItem(props) {

    const [options, setOptions] = React.useState([])
    const [loading, setLoading] = React.useState(false)

    const requestOptions = () => {
        if (!props?.requestapi) {
            message.error("无效API!");
            return;
        }
        setLoading(true)
        props.requestapi(props.params ?? null).then(res => {
            setOptions(res.data.map(item => ({label: item.name, value: item.id})))
            setLoading(false)
        }).catch(err => {
            console.log("获取数据失败", err)
            setOptions([])
            setLoading(false)
        })
    }

    const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

    return (<Select showSearch options={options} loading={loading} filterOption={filterOption}
                    onFocus={requestOptions} {...props} />);

}
