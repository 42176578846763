import React from "react";
import {
    Alert,
    Button,
    Cascader,
    Col,
    DatePicker,
    Drawer,
    Form,
    Input,
    message,
    Modal, Radio,
    Row,
    Select,
    Space,
    TreeSelect
} from "antd";
import CustomerApi from "api/customer/customer";
import WorkorderApi from "api/workorder";
import EquipmentListApi from "api/equipment/list";

import CreateCustomerModal from "page/home/customer/customer/modal/CreateCustomerModal";
import CreateCustomerDepartmentModal from "page/home/customer/department/modal/CreateCustomerDepartmentModal";
import CustomerDepartmentPicker from "component/customer/department/CustomerDepartmentPicker";

import {SmpWorkorderType} from "../../assets/WorkorderType";
import FileUploadRender from "component/FileUploadRender";
import {ConvertSmpWorkorderType} from "../../../preview/component/WorkorderType";
import {setFieldsError} from "../../../../../plugin/formily";
import RepairCodeModal from "../modal/RepairCodeModal";
import DepartmentApi from "../../../../../api/user/department";
import {getUser} from "../../../../../utils/securityCheck";
import DispatchUnassignedWorkorderModal from "../modal/DispatchUnassignedWorkorderModal";

let handlerInfo = [];

/**
 * 发起信创工单 Drawer
 */
export default class CreateSmpUnassignedWorkorderDrawer extends React.Component {

    state = {
        id:"",
        drawerWidth: 280,
        show: false,
        loading: false,
        dispatchLoading: false,
        customerDepartmentsOptions: [],
        customersOptions: [],
        workorderHandlersOptions: [],
        equipmentOptions: [],
        loadingCustomerDepartmentsOptions: false,
        loadingCustomersOptions: false,
        loadingWorkorderHandlersOptions: false,
        uploadFiles: [],
        dispatch: false,
        showDispatchModal: false,
        progressDispatchWorkorder: false,
        workorderHandler: [],
        workorderId: "",
        customerDepartmentId: "",
        WorkorderHandlerId: "",
        departmentId: "",
        equipmentInfo: {},
        sbId: "",
        sbName: "",
        model: "",
        dispatchType:0,//0-工程师 1-团队
        //指派类型
        typeOptions: [
            {
                label: '工程师',
                value: 0,
            },
            {
                label: '团队',
                value: 1,
            }],
        inspectData:{}
    };

    formRef = React.createRef();
    creatDepartmentRef = React.createRef();
    dispatchFormRef = React.createRef();
    createCustomerRef = React.createRef();
    customerDepartmentRef = React.createRef();
    createRepairRef = React.createRef();
    filesRef = React.createRef();
    dispatchWorkorderRef = React.createRef();

    toggle = () => {
        this.setState({
            show: !this.state.show,
            loading: false,
            inspectData:{
                open: false,
                id: 0,
                message: "",
            },
            sbId: "",
            sbName: "",
            model: "",
            drawerWidth: window.innerWidth < 576 ? window.innerWidth : window.innerWidth * 0.55
        }, () => {
            if (this.state.show) {
                this.requestWorkorderHandlersOptions();
            } else {
                this.formRef.current?.setFieldsValue({
                    type: "dyjhf",
                    priority: "5",
                    deadLine: null,
                    clientDepartment: null,
                    clientCustomer: null,
                    description: null,
                    files: []
                });
                this.setState({
                    dispatch: false,
                    departmentId: null,
                    customersOptions: null,
                    dispatchLoading: false
                });
            }
        });
    };

    /**
     * 关闭指派工单的弹窗
     */
    toggleDispatchModal = (data) => {
        this.toggle();
        this.setState({ loading: !this.state.loading,dispatch:false });

        // this.setState({
        //     showDispatchModal: !this.state.showDispatchModal
        // }, () => {
        //     if (!this.state.showDispatchModal) {
        //         // 关闭外层发起工单 Drawer
        //         this.toggle();
        //
        //         if (!this.state.showDispatchModal) {
        //             this.props.onSuccess();
        //         }
        //         // 重置表单
        //         this.dispatchFormRef.current?.setFieldsValue({
        //             handlerId: null,
        //             workorderId: null
        //         });
        //     }
        // });
    };

    toggleLoading = () => this.setState({loading: !this.state.loading});

    toggleCustomerDepartmentsOptionsLoading = () => this.setState({loadingCustomerDepartmentsOptions: !this.state.loadingCustomerDepartmentsOptions});

    toggleCustomersOptionsLoading = () => this.setState({loadingCustomersOptions: !this.state.loadingCustomersOptions});

    toggleWorkorderHandlersOptionsLoading = () => this.setState({loadingWorkorderHandlersOptions: !this.state.loadingWorkorderHandlersOptions});

    /**
     * 通过 departmentId 获取客户
     * @param departmentId 客户单位 ID
     */
    requestCustomersOptions = departmentId => {
        this.toggleCustomersOptionsLoading();
        CustomerApi.list({departmentId}).then(response => {
            const customerOptions = [];
            if (!!response.data && response.data.length > 0) {
                response.data.forEach((customer) => {
                    customerOptions.push(this.handleCustomerOption(customer));
                });
            }
            this.setState({customersOptions: customerOptions}, this.toggleCustomersOptionsLoading);
        }).catch(error => {
            this.toggleCustomersOptionsLoading();
            console.warn("Get customers failed", error);
        });
    };

    /**
     * 请求指派的工程师
     */
    requestWorkorderHandlersOptions = () => {
        //重置处理人数据
        handlerInfo = [];
        this.toggleWorkorderHandlersOptionsLoading();
        global.axios.get("/user/accounts").then(response => {
            this.toggleWorkorderHandlersOptionsLoading();
            const provinces = {};

            for (const handler of response.data) {
                // 初始化当前用户所在省份
                let province;
                if (!!provinces[handler.province]) {
                    province = provinces[handler.province];
                } else {
                    province = {
                        label: handler.province,
                        value: handler.province,
                        test: {}
                    };
                    provinces[handler.province] = province;
                }

                // 初始化当前用户所在市
                let city;
                if (!!province.test[handler.city]) {
                    city = province.test[handler.city];
                } else {
                    city = {
                        label: handler.city,
                        value: handler.city,
                        test: {}
                    };
                    province.test[handler.city] = city;
                }

                let county;
                // 初始化当前用户所在区县
                if (!!city.test[handler.county]) {
                    county = city.test[handler.county];
                } else {
                    county = {
                        label: handler.county,
                        value: handler.county,
                        children: []
                    };
                    city.test[handler.county] = county;
                }

                county.children.push({
                    label: `${handler.name}`,
                    value: handler.id
                });
            }

            for (let key in provinces) {
                handlerInfo.push(provinces[key]);
            }
            for (let i = 0; i < handlerInfo.length; i++) {
                for (let key in handlerInfo[i].test) {
                    if (!handlerInfo[i]["children"]) {
                        handlerInfo[i]["children"] = [];
                    }
                    handlerInfo[i]["children"].push(handlerInfo[i].test[key]);

                    handlerInfo[i]["children"].forEach((item) => {
                        item["children"] = [];
                        for (let key in item.test) {
                            item["children"].push(item.test[key]);
                        }
                    });

                }
            }
        }).catch(error => {
            this.toggleWorkorderHandlersOptionsLoading();
            console.warn("Request user account failed.");
        });
    };

    // 设置报修码
    repairCodeChange = e => {
        this.setState({id: e.target.value})
    };

    /**
     * 获取选择的客户单位 ID
     * @param value
     */
    onCustomerDepartmentChange = value => {
        this.formRef.current?.setFieldsValue({clientCustomer: ""});
        this.requestCustomersOptions(value);
        this.setState({
            departmentId: value
        });
    };

    /**
     * 获取新建客户的客户单位 ID
     * @param departmentId 客户单位 ID
     * @param customerId 客户 ID
     */
    onCreateCustomerSuccess = (departmentId, customerId) => {
        const customerDepartmentId = this.formRef.current?.getFieldValue("clientDepartment");
        if (!!customerDepartmentId) {
            this.requestCustomersOptions(customerDepartmentId);
        }
        if (departmentId === customerDepartmentId) {
            this.formRef.current?.setFieldsValue({
                clientCustomer: customerId
            });
        }
    };

    //获取检验后的数据
    onCreateRepairSuccess = data => {
        let {equipment,customerId,departmentId ,customer} = data
        this.onCreateCustomerDepartmentSuccess(departmentId) //设置单位
        this.onCustomerChange(customerId)

        this.setState({
            sbId: equipment.id,
            sbName: equipment.name,
            model:equipment.model
        })

    };
    /**
     * 获取新建的客户单位 ID
     * @param departmentId 客户单位 ID
     */
    onCreateCustomerDepartmentSuccess = departmentId => {
        this.customerDepartmentRef.current.reload();
        this.requestCustomersOptions(departmentId);
        this.formRef.current?.setFieldsValue({
            clientDepartment: departmentId,
            clientCustomer: null
        });
        this.setState({departmentId});
    };

    /**
     * 转换客户信息为选项
     * @param customer
     */
    handleCustomerOption = customer => {
        return {
            label: `${customer.name}(${customer.cellphone})`,
            value: customer.id
        };
    };

    /**
     * 发起工单
     * @param data 提交的数据
     */
    handle = data => {

        this.toggleLoading();
        let param = {
            clientDepartmentId: data.clientDepartment,
            clientId: data.clientCustomer,
            deadLine: data.deadLine.format("YYYY-MM-DD HH:mm"),
            description: data.description,
            priority: data.priority,
            title: data.title,
            type:  data.type, // 默认为
            files: this.filesRef.current.getFilesValue(),
            logicType: "smp",
            origin: "default",
            resourceId: data.resourceId || this.state.sbId || undefined,
            // resourceName:(this.state.equipmentInfo.name + " " + this.state.equipmentInfo.model) || this.state.sbName || '-',
            resourceName: this.state.sbName || '-',
            //resourceType: !!this.state.equipmentInfo && !!this.state.equipmentInfo.manufacturer && !!this.state.equipmentInfo.manufacturer.name ? this.state.equipmentInfo.manufacturer.name : undefined
            resourceType: this.state.model || undefined,
        };
        if(!this.state.model){
            ConvertSmpWorkorderType(param);
        }
        WorkorderApi.create(param).then(response => {
            this.formRef.current?.resetFields();
            if (this.state.dispatch) {
                this.dispatchWorkorderRef.current.show(response.data,param.clientDepartmentId); //指派
                // 打开指派弹窗
                this.setState({
                    showDispatchModal: true,
                    workorderId: response.data
                });
            } else {

               this.toggle();
               this.props.onSuccess();
            }
        }).catch(error => {
            if (error.status === 40000) {
                setFieldsError(error, this.formRef);
            } else {
                message.error({key: "failed", content: error.data});
            }
            this.toggleLoading();
            console.warn("Create workorder failed", error);
        });
    };
    //发起工单并指派
    // handleAndDispatch = () => this.setState({dispatch: true}, () => {
    //     this.formRef.current?.submit();
    // });
    handleAndDispatch = () => {
        this.setState({dispatch: true}, () => {
            this.formRef.current?.submit();
        });
    }

    renderTrigger = () => <Button type="primary" onClick={this.toggle}>发起信创工单</Button>;

    /**
     * 选中处理人
     * @param value  选中处理人的数据
     */
    onWorkorderHandlerChange = value => {
        this.setState({WorkorderHandlerId: ''});
        if(this.state.dispatchType === 0){
            let id = value[value.length - 1];
            this.setState({WorkorderHandlerId: id});
        }else {
            this.setState({WorkorderHandlerId: value});
        }

    };

    /**
     * 切换指派类型
     *
     */
    onChangeDispatchType = (e) => {

        this.dispatchFormRef.current.resetFields();
        console.log(this.state.dispatchType)
        this.requestHandlers(e.target.value)
    }

    /**
     * 指派工单
     * @param data  表单数据
     */
    handleDispatchWorkorder = data => {
        this.setState({dispatchLoading: true});
        data.handlerId = this.state.WorkorderHandlerId;
        WorkorderApi.dispatch(this.state.workorderId, data).then(() => {
            this.setState({dispatchLoading: false});
            this.toggleDispatchModal();
            if (!!this.props.onSuccess) {
                this.props.onSuccess();
            }
        }).catch(error => {
            console.warn("Dispatch workorder failed", error);
            message.error({key: "failed", content: error.data});
            this.setState({dispatchLoading: false});
        });
    };

    /**
     *控制弹窗
     */
    showCustomerDialog = (id, ref) => ref.current.show(id);

    showDepartmentDialog = (id, ref) => ref.current.show(id);

    showRepairCodeDialog = (id, ref) => {
        WorkorderApi.findEquipmentWorkorder(id).then(res => {
            ref.current.show(id,"");
        }).catch( err => {
            ref.current.show(id,err.desc);
            console.error("Request repair code failed", err);
        } )

        //
    };
    onCustomerChange = (value) => {
        CustomerApi.find(value).then(res => {

            this.formRef.current.setFieldsValue({
                clientAddress: res.data.address,
                clientCustomer: value
            });

            this.getfindhint({
                clientId: value,
                type: this.formRef.current?.getFieldValue().type
            })
        }).catch(err => {
            console.error("Request client info failed", err);
        });
    };

    getfindhint = (data) => {
        WorkorderApi.findhint(data).then(res => {
            if (res.data) {
               // this.props.onInspect(res.data.id);
                this.setState({
                    inspectData:{
                        open: true,
                        id: res.data.id,
                        message: `注:近48小时内已给该客户派发过工单。问题:${res.data.description} - 处理结果:${res.data.result}`,
                    }
                })
            }
        }).catch(err => {
            console.error("Request client info failed", err);
        });
    };

    /**
     * 打开详情
     */
    showInspect = () => {
        this.props.onInspect(this.state.inspectData.id);
    }

    requestEquipment = (id) => {
        EquipmentListApi.queryEquipment(id).then(res => {
            if (!!res.data) {
                const equipmentOptions = [];
                res.data.forEach(item => {
                    let obj = {
                        label: "",
                        value: ""
                    };
                    obj.label = item.name;
                    obj.value = item.id;
                    equipmentOptions.push(obj);
                });
                this.setState({equipmentOptions});
            }
        });
    };

    onSelectEquipmentChange = value => {
        this.requestEquipmentInfo(value);
    };

    requestEquipmentInfo = (id) => {
        EquipmentListApi.queryEquipmentInfo(id).then(res => {
            console.log(res);
            this.setState({equipmentInfo: res.data});
        });
    };

    renderDrawer = () => {
        return <Drawer title="发起信创工单" width={this.state.drawerWidth} open={this.state.show}
                       closable={!this.state.loading} destroyOnClose
                       onClose={this.toggle}>
            <Form ref={this.formRef} onFinish={this.handle} layout="vertical">
                <Row gutter={8} align="middle">
                    <Col sm={9} xs={18}>
                        <Form.Item name="id" label="报修码" initialValue="">
                            <Input onChange={this.repairCodeChange} allowClear />
                        </Form.Item>
                    </Col>
                    <Col sm={3} xs={6} align="middle">
                        <Button block style={{marginTop: 6}}
                                onClick={() => this.showRepairCodeDialog(this.state.id, this.createRepairRef)}>校验</Button>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="type" label="服务类型" required initialValue="dyjhf"
                                   rules={[{required: true, message: "请选择服务类型"}]}>
                            <TreeSelect showSearch treeNodeFilterProp="title" treeData={SmpWorkorderType}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={8}>
                    <Col span={12}>
                        <Form.Item name="priority" label="优先级" required initialValue="5"
                                   rules={[{required: true, message: "请选择优先级"}]}>
                            <Select options={require("../../assets/WorkorderPriority").priorityOptions()}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="deadLine" label="计划时间" required
                                   rules={[{required: true, message: "请选择计划时间"}]}>
                            <DatePicker showTime inputReadOnly format="YYYY-MM-DD HH:mm" style={{width: "100%"}}/>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={8} align="middle">
                    <Col sm={9} xs={18}>
                        <CustomerDepartmentPicker required onChange={this.onCustomerDepartmentChange}
                                                  ref={this.customerDepartmentRef}
                                                  rules={[{required: true, message: "请选择客户单位"}]}/>
                    </Col>
                    <Col sm={3} xs={6} align="middle">
                        <Button block style={{marginTop: 6}}
                                onClick={() => this.showDepartmentDialog("", this.creatDepartmentRef)}>新建单位</Button>
                    </Col>
                    <Col sm={9} xs={18}>
                        <Form.Item name="clientCustomer" label="客户" required
                                   rules={[{required: true, message: "请选择客户"}]}>
                            <Select options={this.state.customersOptions}
                                    loading={this.state.loadingCustomersOptions}
                                    onChange={this.onCustomerChange}
                                    showSearch
                                    optionFilterProp="label"/>
                        </Form.Item>
                    </Col>
                    <Col sm={3} xs={6} align="middle">
                        <Button block style={{marginTop: 6}}
                                onClick={() => this.showCustomerDialog(this.state.departmentId, this.createCustomerRef)}>新建客户</Button>
                    </Col>
                    {/*<Col span={24}>*/}
                    {/*    <Form.Item name="resourceId" label="选择设备">*/}
                    {/*        <Select loading={this.state.loadingCustomersOptions}*/}
                    {/*                showSearch*/}
                    {/*                onChange={this.onSelectEquipmentChange}*/}
                    {/*                options={this.state.equipmentOptions}*/}
                    {/*                optionFilterProp="label"/>*/}
                    {/*    </Form.Item>*/}
                    {/*</Col>*/}
                </Row>

                {this.state.inspectData.open ? <Alert
                    message= {
                        this.state.inspectData.message
                    }
                    type="warning"
                    closable
                    action={
                        <Button size="small" type="link" block onClick={this.showInspect}>
                            详情
                        </Button>
                    }
                /> : <></>}

                <Row gutter={8} align="middle">
                    <Col span={24}>
                        <Form.Item name="clientAddress" label="客户详细地址">
                            <Input disabled/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item name="description" label="工单描述" rules={[
                            {pattern: /\S/, message: "内容不能为空"}
                        ]}>
                            <Input.TextArea/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <FileUploadRender ref={this.filesRef} name="files"
                                          uploadPath="/workorder/workorder/unassigned/file"/>
                    </Col>
                </Row>
                <Row gutter={8} justify="end">
                    <Col>
                        <Space>
                            <Button disabled={this.state.loading} onClick={this.toggle}>取消</Button>
                            <Button loading={this.state.loading} type="primary" htmlType="submit">发起工单</Button>
                            <Button loading={this.state.loading} type="primary"
                                    onClick={this.handleAndDispatch}>发起工单并指派</Button>
                        </Space>
                    </Col>
                </Row>
            </Form>

            <CreateCustomerDepartmentModal onSuccess={this.onCreateCustomerDepartmentSuccess}
                                           ref={this.creatDepartmentRef}/>
            <CreateCustomerModal onSuccess={this.onCreateCustomerSuccess} ref={this.createCustomerRef}/>
            <RepairCodeModal onSuccess={this.onCreateRepairSuccess} ref={this.createRepairRef}/>
            <DispatchUnassignedWorkorderModal onSuccess={this.toggleDispatchModal} ref={this.dispatchWorkorderRef}/>

        </Drawer>;
    };

    render() {
        return <>
            {this.renderTrigger()}
            {this.renderDrawer()}
        </>;
    };
}
