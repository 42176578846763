import React, {Component} from "react";
import PropTypes from "prop-types";
import {Button, Col, Form, message, Row, Upload} from "antd";
import {InboxOutlined} from "@ant-design/icons";
import "./index.css";

/**
 * 附件上传组件
 */
class FileUploadRender extends Component {

    static defaultProps = {
        name: "file",
        uploadFileName: "file",
        label: "附件上传",
        uploadParams:{},
        uploadPath: undefined,
        onChange: undefined,
        onRemove: undefined,
        required: false,
        uploadProps: {
            maxCount: undefined,
            accept: undefined
        }
    };

    state = {
        loading: false,
        fileData: [],
        updateFlag: true,
        deletePath: []
    };

    /**
     * 设置附件数据
     * @param files
     */
    setFilesValue = (files) => {
        this.setState({
            fileData: files
        });
    };

    /**
     * 获取上传状态
     * @returns {boolean}
     */
    getUploadState = () => {
        return this.state.loading;
    };

    /**
     * 获取删除文件数据
     * @returns {[]}
     */
    getDeletedFiles = () => {
        return this.state.deletePath;
    };

    /**
     * 获取上传文件数据
     * @returns {*[]}
     */
    getFilesValue = () => {
        const files = [];
        this.state.fileData.forEach(item => {
            files.push(item.url);
        });
        return files;
    };

    /**
     * 上传状态改变
     * @param value
     */
    onChange = (value) => {

        this.setState({oldFilesData: []});
        let fileList = [...value.fileList];
        fileList = fileList.map(file => {
            if (file.response) {
                file = {
                    uid: file.uid,
                    name: file.name,
                    url: file.response.data,
                    thumbUrl: file.response.data,
                    type: file.type,
                    origin: "offline"
                };
            }
            return file;
        });
        console.log( fileList)
        this.setState({fileData: fileList});

        let flag = value.fileList.some((item) => {
            return item.status === "uploading";
        });

        this.setState({loading: flag});

        if (value.file.status === "error") {
            message.error("导入失败")
        }

        if (!!this.props.onChange) {
            this.props.onChange(value,fileList);
        }
    };

    /**
     * 删除文件触发
     * @param file
     * @returns {Promise<unknown>|Promise<boolean>}
     */
    onRemove = file => {
        if (!!file.origin && file.origin === "offline") {
            if (!!this.props.onRemove) {
                this.props.onRemove(file);
            } else {
                return global.axios.delete(`${this.props.uploadPath}?path=${file.url}`).then(response => {
                    console.log("delete file success", response);
                    return true;
                }).catch(err => {
                    return false;
                });
            }

        } else {
            if (!!this.props.onRemove) {
                this.props.onRemove(file);
                return;
            }
            this.setState({deletePath: [...this.state.deletePath, file.url]});
            return new Promise((resolve => {
                resolve(true);
            }));
        }
    };

    render() {
        return (<Form.Item name={this.props.name} label={this.props.label} required={this.props.required}>
                <Row>
                    <Col xs={24} sm={24} md={0} lg={0} xl={0} xxl={0}>
                        <Upload name={this.props.uploadFileName}
                                showUploadList
                                fileList={this.state.fileData}
                                listType="picture"
                                data={this.props.uploadParams ?? undefined}
                                headers={{token: localStorage.getItem("Token")}}
                                action={global.config.url + this.props.uploadPath}
                                onChange={this.onChange}
                                onRemove={this.onRemove}
                                {...this.props.uploadProps}>
                            <Button disabled={this.state.loading}>上传附件</Button>
                        </Upload>
                    </Col>
                    <Col xs={0} sm={0} md={24} lg={24} xl={24} xxl={24}>
                        <Upload.Dragger name={this.props.uploadFileName}
                                        disabled={this.state.loading}
                                        showUploadList
                                        fileList={this.state.fileData}
                                        listType="picture"
                                        headers={{token: localStorage.getItem("Token")}}
                                        action={global.config.url + this.props.uploadPath}
                                        onChange={this.onChange}
                                        onRemove={this.onRemove}
                                        {...this.props.uploadProps}>
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined/>
                            </p>
                            <p className="ant-upload-text">点击或将文件拖拽到此区域进行上传</p>
                        </Upload.Dragger>
                    </Col>
                </Row>
            </Form.Item>
        );
    }
}

FileUploadRender.propTypes = {
    name: PropTypes.string,
    uploadFileName: PropTypes.string,
    label: PropTypes.string,
    uploadPath: PropTypes.string,
    maxCount: PropTypes.number,
    uploadProps: PropTypes.object,
    uploadParams:PropTypes.object
};

export default FileUploadRender;
