import { Popover } from 'dingtalk-design-desktop';
import { DisconnectNetwork } from './DisconnectNetwork';
import { NetworkScore } from './NormalNetwork';
import {useLocalChannel, useNetworkStats} from "../../../../../../../hooks/channel";

const NetworkCardContent = () => {
  const { rtcStats } = useNetworkStats();
  return (
    <div className="cardWrapper">
      <div className="labelWrap">
        <span className="label">{'码率'}</span>
        <span className="label">{'延迟'}</span>
        <span className="label">{'丢包率'}</span>
      </div>
      <div className="valueWrapper">
        <div className="value">
          <span style={{ marginRight: '0.1rem' }}>
            &uarr; {rtcStats.localBitrate ? `${Math.round(rtcStats.localBitrate / 1000)} kbps` : '--'}
          </span>{' '}
          &darr; {rtcStats.remoteBitrate ? `${Math.round(rtcStats.remoteBitrate / 1000)} kbps` : '--'}
        </div>
        <div className="value">
          {rtcStats.rtt ? `${Math.round(rtcStats.rtt)} ms` : '--'}
        </div>
        <div className="value">
          {`${Math.round(rtcStats.loss)} %`}
        </div>
      </div>
    </div>
  );
};

NetworkCardContent.displayName = 'NetworkCardContent';

const ScoreMap = (score) => {
  const scoreMap = {
    0: {
      text: '网络状态未知',
      icon: <DisconnectNetwork className="networkIcon" />,
    },
    1: {
      text: '网络极佳',
      icon: <NetworkScore signalNumber={3} className="networkIcon" />,
    },
    2: {
      text: '网络较好',
      icon: <NetworkScore signalNumber={2} className="networkIcon" />,
    },
    3: {
      text: '网络一般',
      icon: <NetworkScore signalNumber={2} className="networkIcon" />,
    },
    4: {
      text: '网络极差',
      icon: <NetworkScore signalNumber={1} className="networkIcon" />,
    },
    5: {
      text: '网络极差',
      icon: <NetworkScore signalNumber={1} className="networkIcon" />,
    },
    6: {
      text: '网络已断开',
      icon: <DisconnectNetwork className="networkIcon" />,
    },
  };
  return scoreMap[score];
};

export const NetworkDetector = () => {
  const { networkQuality } = useLocalChannel();
  return (
    <Popover
      placement="bottomLeft"
      title={`${ScoreMap(networkQuality).text}`}
      arrow={false}
      content={<NetworkCardContent />}
      trigger="hover"
    >
      <div className="networkTrigger">{ScoreMap(networkQuality).icon}</div>
    </Popover>
  );
};
