import React, {useState} from "react";
import {ProCard} from "@ant-design/pro-components";
import {Typography} from "antd";

import LoginForm from "./component/LoginForm";
import ForgetPassword from "./component/ForgetPassword";

import qjbj from "../../asset/backgroundBg.jpg";

import {isAdmin} from "../../component/SecurityInterceptor";

export default function Login(props) {

    const [loginFormOrPasswordForm, setLoginFormOrPasswordForm] = useState("1");

    function onSuccess() {
        if (isAdmin()) {
            window.location.href = "/home/preview"
            // props.history.replace("/home/preview");
        } else {
            window.location.href = "/home/workorders/the-workorder-i-handle"
            // props.history.replace("/home/workorders/the-workorder-i-handle");
        }
    }

    const toggleLoginForm = value => setLoginFormOrPasswordForm(value);

    return <ProCard ghost style={{height: "100vh"}}>
        <ProCard colSpan={{xs: 0, sm: 10, md: 8, lg: 8}} ghost layout="center"
                 style={{backgroundImage: `url(${qjbj})`, backgroundSize: "cover", height: "100vh"}}>
            <div style={{textAlign: "center", marginBottom: "100px"}}>
                <Typography.Title level={3}
                                  style={{color: "#21ABF1", letterSpacing: "5px"}}>数字化信创服务运营解决方案</Typography.Title>
                <Typography.Title level={5}>提效率&nbsp;&nbsp;保安全&nbsp;&nbsp;促规范</Typography.Title>
            </div>
        </ProCard>
        <ProCard colSpan={{xs: 24, sm: 14, md: 16, lg: 16}} ghost layout="center"
                 style={{
                     height: "100%",
                     backgroundColor: "#f0f2f5",
                     backgroundImage: "linear-gradient(to top, #DFE9F3 0%, #e7f0fd 100%)"
                 }}>
            {loginFormOrPasswordForm === "1" ? <LoginForm onSuccess={onSuccess} onToggleForm={toggleLoginForm}/> :
                <ForgetPassword onToggleForm={toggleLoginForm}/>}
            <div style={{position: "absolute", bottom: 0, color: "#bbb"}}>
                赣ICP备10200822号-3
            </div>
        </ProCard>
    </ProCard>;
};
