import isDevEnvironment from "./utils/isDevEnvironment";
import {NODEENV} from "./utils/environment";
import {prettyLog} from "./utils/tool";

const Log = prettyLog();
//const profile = isDevEnvironment ? "dev" : "prod";
const profile = NODEENV();
//const profile = "prod";
const profiles = {
    dev: {
        versionCode: 10,
        versionName: "6.8.0",
        url: "http://218.64.219.62:9005",
        //url: "http://192.168.2.7:8005",
    },
    prod: {
        versionCode: 5,
        versionName: "6.3.0",
        // url: "http://36.133.2.67:8120"
        // url: "http://218.87.150.78:8005"
        // url: "http://10.9.103.76:8005"
        // url: "http://218.64.219.61:8005"
        // url: "http://xcfw.cn:18005",
        //url: "http://192.168.2.97:9005/",
        url: "https://api.xcfw.cn",
        // url: "http://192.168.2.11:8005",
        // url: "http://120.25.106.40:6001",
        // url: "http://218.64.219.61:6001", /*测试环境*/
        // url: "http://172.17.13.182:8005" /*航天四创*/
        // url: "http://36.133.2.67:8121/api"
    }
};

Log.success(`${profile === "dev" ? "开发" : "生产"}环境:`, '' + profiles[profile].url)

const config = {
    url: profiles[profile].url
};

global.config = config;
window.config = config;
export default config;
