import React, {Component} from 'react';
import {ProDescriptions} from "@ant-design/pro-components";
import RemoteApi from "../../../../api/remote/RemoteApi";
import DraggableModal from "../../../../component/DraggableModal";

/**
 * @author       : AngelBeats
 * @date         : 2023-06-01 16:34:42
 * @description  : 查看远程设备信息
 */
export default class PreviewRemoteDevice extends Component {


    draggableRef = React.createRef();

    state = {
        drawerWidth: 600,
        show: false,
        remoteDeviceId: ""
    }

    /**
     * 显示
     */
    show = id => {
        this.draggableRef.current.show();
        this.setState({
            show: true,
            remoteDeviceId: id
        })
    }

    /**
     * 隐藏
     */
    close = () => {
        this.draggableRef.current.close();
    };

    /**
     * 查询远程设备信息
     */
    requestRemoteDeviceInfo = id => {
        return RemoteApi.find(id).then(res => {
            return {success: true, data: res.data};
        }).catch(err => {
            console.error("request remote device info failed", err)
        })
    }

    render() {
        return (<DraggableModal title="查看设备信息" ref={this.draggableRef} onCancel={this.close}>
            <ProDescriptions bordered size="small" layout="horizontal" column={window.innerWidth < 800 ? 1 : 4}
                             request={() => this.requestRemoteDeviceInfo(this.state.remoteDeviceId)}>
                <ProDescriptions.Item label="设备名称" span={4} dataIndex="name"/>
                <ProDescriptions.Item label="设备sn码" span={4} dataIndex="sn"/>
                <ProDescriptions.Item label="客户单位" span={4} dataIndex={["customerDepartmentObj", "name"]}/>
                <ProDescriptions.Item label="客户" span={4} dataIndex={["customerObj", "name"]}/>
                <ProDescriptions.Item label="创建时间" span={4} dataIndex="createTime"/>
                <ProDescriptions.Item label="修改时间" span={4} dataIndex="modifyTime"/>
            </ProDescriptions>
        </DraggableModal>)
    }
}
