import React from "react";
import {Button, Card, Col, Form, Input, message, Row, Typography} from "antd";
import {KeyOutlined, LockOutlined, PhoneOutlined} from "@ant-design/icons";
import {ProFormCaptcha} from "@ant-design/pro-components";

import style from "./LoginForm.module.css";
// import openEyes from "../../../asset/loginImg/openEyes.svg";
// import closeEyes from "../../../asset/loginImg/closeEyes.svg";

import axios from "axios";
import logoBg from "../../../asset/logobg.png";
import logoMsg from "../../../asset/logomsg.png";
import xlaqgfBg from "../../../asset/xlaqgfBg.png";

/**
 * 忘记密码表单组件
 *
 * @since 2021-06-25 14:34
 * @version 1.0.0
 */
export default class ForgetPassword extends React.Component {

    state = {
        loading: false,
        openOrCloseEyes: true
    };

    formRef = React.createRef();

    toggleLoading = () => {
        this.setState({
            loading: !this.state.loading
        });
    };

    passwordFocus = () => this.setState({openOrCloseEyes: false});

    passwordBlur = () => this.setState({openOrCloseEyes: true});

    onGetCaptcha = cellphone => {
        axios.get(`${global.config.url}/user/captcha/account/${cellphone}/forgot-password`).then(res => {
        }).catch(err => {
            console.error("Request captcha failed", err);
            this.formRef.current.setFields([{name: "captcha", errors: ["请求过于频繁"]}]);
        });
    };

    handle = (data) => {
        if (data.password !== data.confirmPassword) {
            this.formRef.current.setFields([{name: "password", errors: ["两次密码不一致"]}, {
                name: "confirmPassword",
                errors: ["两次密码不一致"]
            }]);
            return;
        }
        this.toggleLoading();
        axios.patch(`${global.config.url}/user/account/forgot-password`, data).then(res => {
            console.log(res);
            this.toggleLoading();
            this.props.onToggleForm("1");
        }).catch(err => {
            this.toggleLoading();
            console.error("Modify password failed", err);
            message.error("修改密码失败");
        });
    };

    render() {
        return <div className={style.Container}>
            <div className={style.logosBox}>
                <div className={style.logoBg}>
                    <img src={logoBg} alt="jiesh"/>
                </div>
                <div className={style.logoMsg}>
                    <img src={logoMsg} alt="jiesh"/>
                </div>
            </div>
            <div className={style.boxContainer}>
                <div className={style.fromContainer}>
                <Typography.Title level={2} style={{marginBottom: 30}}>信创运维远程服务平台</Typography.Title>
                <Form ref={this.formRef} onFinish={this.handle}>
                    <Row gutter={[16]}>
                        <Col span={24}>
                            <Form.Item name="cellphone" rules={[{required: true, message: "请输入手机号"}]}>
                                <Input placeholder="请输入手机号" prefix={<PhoneOutlined/>}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row justify="center" gutter={[16]}>
                        <Col span={24}>
                            <ProFormCaptcha
                                fieldProps={{prefix: <LockOutlined className={"prefixIcon"}/>}}
                                countDown={120}
                                placeholder={"请输入验证码"}
                                // captchaTextRender={(timing, count) => {
                                //     if (timing) {
                                //         return `${count} ${"获取验证码"}`;
                                //     }
                                //     return "获取验证码";
                                // }}
                                phoneName="cellphone"
                                name="captcha"
                                rules={[{required: true, message: "请输入验证码！"}]}
                                onGetCaptcha={this.onGetCaptcha}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item name="password" rules={[{required: true, message: "请输入密码"}]}>
                                <Input.Password autoComplete="new-password" onFocus={this.passwordFocus}
                                                onBlur={this.passwordBlur}
                                                placeholder="请输入密码" prefix={<KeyOutlined/>}/>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name="confirmPassword" rules={[{required: true, message: "请再次输入密码"}]}>
                                <Input.Password onFocus={this.passwordFocus} onBlur={this.passwordBlur}
                                                placeholder="请再次输入密码" prefix={<KeyOutlined/>}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <div style={{marginBottom: 24,textAlign:"right"}}>
                        <Button type="link" onClick={() => this.props.onToggleForm("1")}>账号登录</Button>
                    </div>
                    <Row justify="center" algin="center">
                        <Col span={12}>
                            <Button type="primary" htmlType="submit" loading={this.state.loading} block>修改密码</Button>
                        </Col>
                    </Row>
                </Form>
                </div>
                <div className={style.fromjieshImg}>
                    <img src={xlaqgfBg} alt=""/>
                </div>
            </div>
        </div>;
    }
}
