import React, {Component} from "react";
import style from "./index.module.css";
import workorderInfoApi from "api/workorderInfo";
import {Spin} from "antd";
import {isAdmin} from "component/SecurityInterceptor";
import {withNavigation} from "../../../../../utils/RouteLink";

/**
 * 服务概览
 * @description 已接入工程师数量统计
 */
class EngineerStatistic extends Component {
    state = {
        loading: false,
        engineerNum: ""
    };

    componentDidMount() {
        this.getEngineerNumber();
    };


    getEngineerNumber = () => {
        this.setState({
            loading: true
        });
        workorderInfoApi.engineerNumber().then(response => {
            this.setState({
                engineerNum: response.data,
                loading: false
            });
        }).catch(error => {
            console.log("Get engineer number filed", error);
            this.setState({
                engineerNum: "-",
                loading: false
            });
        });
    };

    render() {
        return (
            <div className={style.systemDataItem}
                 onClick={() => isAdmin() ? this.props.navigate('/home/user/accounts') : null}>
                <p>已接入工程师数</p>
                {this.state.loading ? <Spin/> : <p>{this.state.engineerNum}</p>}
            </div>
        );
    }
}

export default withNavigation(EngineerStatistic);
