import DingRTC, { CameraVideoTrack, DingRTCClient, LocalAudioTrack, LocalTrack, LocalVideoTrack, MicrophoneAudioTrack, RemoteAudioTrack, RemoteUser, VideoDimension, NetworkQuality } from "dingrtc";
import { atom } from "recoil";
import { isIOS, isMobile, isWeixin, logLevel, parseSearch } from "./utils/tools";
import configJson from './config.json'

DingRTC.setLogLevel(logLevel);

export const client = atom({
  key: 'Icient',
  dangerouslyAllowMutability: true,
  default: DingRTC.createClient(),
})

export const constantConfig = atom({
  key: 'IconstantConfig',
  default: {
    isMobile: isMobile(),
    hideLog: logLevel === 'none',
    isIOS: isIOS(),
    isWeixin: isWeixin(),
  }
})

export const currentUserInfo = atom({
  key: 'IcurrentUserInfo',
  default: {
    appId: parseSearch('appId') || configJson.appId || '',
    userId: parseSearch('userId') || configJson.userId || `${Math.ceil(Math.random() * 10000)}`,
    userName: parseSearch('userName') || configJson.userName || `Web-${Math.ceil(Math.random() * 100)}`,
    channel: parseSearch('channelId') || configJson.channelId || `${Math.ceil(Math.random() * 10000)}`,
  },
  dangerouslyAllowMutability: true,
});

export const deviceInfo = atom({
  key: 'IdeviceInfo',
  dangerouslyAllowMutability: true,
  default: {
    cameraId: '',
    micId: '',
    speakerId: '',
    cameraList: [],
    speakerList: [],
    micList: [],
    cameraFrameRate: 15,
    cameraMaxBitrate: 0,
    cameraDimension: 'VD_640x480',
    screenFrameRate: 5,
    screenMaxBitrate: 0,
    screenDimension: 'VD_1920x1080',
    facingMode: 'user',
  }
})

export const localChannelInfo = atom({
  key: 'ILocalChannelInfo',
  dangerouslyAllowMutability: true,
  default: {
    cameraTrack: null,
    screenTrack: null,
    customVideoTrack: null,
    customAudioTrack: null,
    micTrack: null,
    timeLeft: 0,
    networkQuality: 1,
    publishedTracks: [],
    rtcStats: {},
  }
});

export const remoteChannelInfo = atom({
  key: 'IremoteChannelInfo',
  dangerouslyAllowMutability: true,
  default: {
    mcuAudioTrack: null,
    remoteUsers: [],
    subscribeAllVideo: true,
    speakers: [],
  }
})

export const globalFlag = atom({
  key: 'IglobalFlag',
  default: {
    joined: false,
    hideToolBar: false,
  }
});

export const mainViewPrefer = atom({
  key: 'IMainViewPrefer',
  dangerouslyAllowMutability: true,
  default: {
    userId: '',
    prefer: 'camera',
  },
})
