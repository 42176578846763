const CustomerApi = {
    /**
     * 查询客户
     * @param id 客户 ID
     */
    find: (id) => global.axios.get(`/customer/customer/${id}`),
    /**
     * 分页查询客户
     */
    page: (params) => global.axios.get("/customer/customers/page", {params}),
    /**
     * 查询所有客户
     */
    list: (params) => global.axios.get("/customer/customers", {params}),
    /**
     * 创建客户
     */
    create: (data) => global.axios.post("/customer/customer", data),
    /**
     * 更新客户
     */
    update: (data) => global.axios.patch("/customer/customer", data),
    /**
     * 删除客户
     * @param id 客户 ID
     */
    delete: (id) => global.axios.delete(`/customer/customer/${id}`),
    /**
     * 批量删除客户
     */
    deletes: (customerIds) => global.axios.delete("/customer/customers", {data: {customerIds}}),
    /**
     * 查询客户单位信息
     */
    findDepartment: (id) => global.axios.get(`/customer/department/${id}`)
};

export default CustomerApi;
