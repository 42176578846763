import React, {Component} from "react";
import {Divider, Drawer, Skeleton} from "antd";
import {ProDescriptions,ProTable} from "@ant-design/pro-components";

import StorehouseRecordApi from "api/spares/record";
import StorehouseApi from "api/spares/storehouse";

import DescriptionFileRender from "component/DescriptionFileRender";

class PreviewAddRecordDrawer extends Component {

    columns = [
        {
            title: "类型",
            dataIndex: ["stocktype", "model"],
            editable: false,
            hideInSearch: true
        },
        {
            title: "名称",
            dataIndex: ["stocktype", "name"],
            hideInSearch: true
        },
        {
            title: "数量",
            dataIndex: "count",
            hideInSearch: true
        },
        {
            title: "是否SM",
            dataIndex: ["stocktype", "classified"],
            hideInSearch: true,
            valueEnum: {
                "0": {
                    "text": "否"
                },
                "1": {
                    "text": "是"
                }
            }
        }
    ];

    state = {
        show: false,
        loading: true,
        drawerWidth: 600,
        repositoryInputRecord: {},
        addRecord: {
            files: []
        },
        stocks: []
    };

    show = (id) => {
        this.setState({
            show: true,
            drawerWidth: window.innerWidth < 576 ? window.innerWidth : window.innerWidth * 0.55
        }, () => {
            this.requestAddRecord(id);
        });
    };

    close = () => this.setState({show: false});

    toggleLoading = () => this.setState({loading: !this.state.loading});

    requestAddRecord = (id) => {
        StorehouseRecordApi.addRepository(id).then(res => {
            if (!!res.data && res.data.input !== undefined && res.data.input !== null) {
                this.requestRepositoryInputRecord(res.data.input);
            }
            console.log(res);
            this.setState({addRecord: res.data, loading: false});
        }).catch(err => {
            this.setState({loading: false});
            console.error("Request add record failed", err);
        });
    };

    requestRepositoryInputRecord = (id) => {
        StorehouseRecordApi.repositoryInput(id).then(res => {
            console.log(res);
            this.setState({repositoryInputRecord: res.data, stocks: res.data.stocks});
        }).catch(err => {
            console.error("Request repository input record failed", err);
        });
    };

    requestStorehouseInfo = () => {
        return StorehouseApi.find(this.state.addRecord.repository).then(res => {
            return {success: true, data: res.data};
        }).catch(err => {
            console.error("Request origin storehouse info failed", err);
        });
    };

    render() {
        return (<Drawer title="添加库存记录"
                        destroyOnClose
                        width={this.state.drawerWidth}
                        open={this.state.show}
                        closable={!this.state.loading}
                        onClose={this.close}>
                <ProDescriptions bordered size="small" layout="horizontal"
                                 column={window.innerWidth < 800 ? 1 : 4}
                                 loading={this.state.loading}>
                    <ProDescriptions.Item label="类型" span={4}>
                        添加库存
                    </ProDescriptions.Item>
                    <ProDescriptions.Item label="添加说明" span={4}>
                        {this.state.addRecord.comment}
                    </ProDescriptions.Item>
                </ProDescriptions>

                <Skeleton active loading={this.state.loading}>
                    <ProDescriptions bordered size="small" layout="horizontal"
                                     column={window.innerWidth < 800 ? 1 : 4}
                                     request={this.requestStorehouseInfo}
                                     loading={this.state.loading}>
                        <ProDescriptions.Item label="仓库" span={4} dataIndex="name"/>
                        <ProDescriptions.Item label="单位" span={4} dataIndex={["department", "name"]}/>
                        <ProDescriptions.Item label="附件" span={4}>
                            <DescriptionFileRender file={this.state.addRecord.files}/>
                        </ProDescriptions.Item>
                    </ProDescriptions>
                </Skeleton>

                <Divider/>

                <ProTable headerTitle="添加物资列表"
                          search={false}
                          rowKey="id"
                          defaultSize="small"
                          pagination={false}
                          options={false}
                          columns={this.columns}
                          dataSource={this.state.stocks}/>
            </Drawer>
        );
    }
}

export default PreviewAddRecordDrawer;
