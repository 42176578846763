import React, {Component} from "react";
import moment from "moment";
import {Button, Cascader, DatePicker, Drawer, message, Skeleton, Timeline, Tooltip} from "antd";
import {DeleteOutlined, EditOutlined, EyeOutlined} from "@ant-design/icons";
import {ProTable,ProDescriptions} from "@ant-design/pro-components";

import EquipmentListApi from "api/equipment/list";
import CustomerApi from "api/customer/customer";
import WorkorderFileRender from "component/DescriptionFileRender";
import SecurityInterceptor, {hasAnyRole, isUser} from "component/SecurityInterceptor";

import PreviewSmpWorkorderDrawer from "page/home/workorder/workorder/drawer/PreviewSmpWorkorderDrawer";
import UpdateSmpWorkorderDrawer from "page/home/workorder/workorder/drawer/UpdateSmpWorkorderDrawer";
import UpdateOpsWorkorderDrawer from "page/home/workorder/workorder/drawer/UpdateOpsWorkorderDrawer";
import DeleteWorkorderModal from "page/home/workorder/workorder/modal/DeleteWorkorderModal";
import dayjs from "dayjs";
import DictApi from "../../../../../api/dict";
import EnumDict from "../../../../../enum/dict";

/**
 * 查看设备
 * 根据设备id 获取对应的设备信息
 * 使用 ProDescriptions 渲染到页面
 * 根据对应id 请求对应设备的列表信息 渲染到 Drawer 中
 */
export default class PreviewEquipmentDrawer extends Component {
    editSmpRef = React.createRef();
    deleteRef = React.createRef();
    previewSmpRef = React.createRef();

    state = {
        drawerWidth: 600,
        loading: false,
        show: false,
        skeletonLoading: true,
        equipment: {
            files: []
        },
        logs: [],
        equipmentId: "",
        customerId: "",
        customerDepartmentId: "",
        address: "",
        customer: {
            customerName: "",
            customerDepartmentName: ""
        },
        manufacturerData: {},
        columnStateMap: {
            priority: {
                show: false
            },
            appointmentTime: {
                show: false
            },
            deadLine: {
                show: false
            }
        },
        commonTypeData:[]

    };

    columns = [
        {
            title: "ID",
            dataIndex: "id",
            render: (node, entity) => {
                let time = dayjs(entity.deadLine).format("YYYYMMDD");
                let nowTime = dayjs().locale("zh-cn").format("YYYYMMDD");
                let flag;
                if (nowTime - time > 0 && entity.progress === 5) {
                    flag = true;
                }
                return entity.logicType === "smp" ?
                    <Button type="link" danger={entity.progress === 0 || flag}
                            size="small"
                            onClick={() => this.dialog(entity.id, this.previewSmpRef)}>{entity.id}</Button> :
                    <Button type="link" danger={entity.progress === 0 || flag}
                            size="small"
                            onClick={() => this.dialog(entity.id, this.previewOpsRef)}>{entity.id}</Button>;
            }
        },
        {
            title: "处理进度",
            dataIndex: "progress",
            valueEnum: require("page/home/workorder/assets/WorkorderProgress").default
        },
        {
            title: "服务类型",
            dataIndex: "type",
            valueEnum: require("page/home/workorder/assets/WorkorderType").WorkorderType
        },
        {
            title: "工单类型",
            dataIndex: "logicType",
            valueEnum: require("page/home/workorder/assets/WorkorderLogicType").default
        },
        {
            title: "客户姓名",
            dataIndex: "clientName"
        },
        {
            title: "客户电话",
            dataIndex: "clientCellphone"
        },
        {
            title: "填单人",
            dataIndex: "creatorName"
        },
        {
            title: "处理人",
            dataIndex: "handlerName"
        },
        {
            title: "创建时间",
            dataIndex: "createTime",
            hideInSearch: true
        },
        {
            title: "计划完成时间",
            dataIndex: "deadLine",
            key: "deadLine",
            hideInSearch: true
        },
        {
            title: "预约时间",
            dataIndex: "appointmentTime",
            key: "appointmentTime",
            hideInSearch: true
        },
        {
            title: "优先级",
            dataIndex: "priority",
            key: "priority",
            valueEnum: require("page/home/workorder/assets/WorkorderPriority").default
        },
        {
            title: "满意度",
            dataIndex: "feedbackScore",
            hideInTable: !hasAnyRole(["customer-service", "admin"]),
            hideInSearch: !hasAnyRole(["customer-service", "admin"]),
            valueEnum: require("page/home/workorder/assets/WorkorderFeedbackType").default
        },
        {
            title: "所在区县",
            key: "locate",
            hideInTable: true,
            renderFormItem: (_, {type, defaultRender, formItemProps, fieldProps}) => {
                return (
                    <Cascader options={require("asset/location.json")} {...fieldProps}/>
                );
            }
        },
        {
            title: "时间选择",
            key: "timer",
            hideInTable: true,
            renderFormItem: (_, {type, defaultRender, formItemProps, fieldProps}) => {
                return (
                    <DatePicker.RangePicker {...fieldProps} inputReadOnly/>
                );
            }
        },
        {
            title: "操作",
            valueType: "option",
            render: (node, entity, index, action) => this.renderColumnOperations(node, entity, index, action)
        }
    ];

    /**
     * 获取字典设备大类
     * @returns {Promise<void>}
     */
    getManufacturerData = () => {
        DictApi.list({code : EnumDict.DEVICE_COMMON_TYPE}).then(res => {
            let selectData = [];
            res.data.forEach(item => {
                selectData.push({label: item.value, value: item.id});
            });
            this.setState({
                commonTypeData: selectData
            });
        })
    }

    /**
     * 弹窗方法
     * @param id
     * @param ref
     * @returns {*}
     */
    dialog = (id, ref) => ref.current.show(id);

    renderColumnOperations = (_, entity) => {
        return [
            <Tooltip title="查看" key="preview">
                <Button icon size="small" onClick={() => this.previewSmpRef.current.show(entity.id)}><EyeOutlined/></Button>
            </Tooltip>,
            <SecurityInterceptor key="edit" role={["admin", "customer-service"]}>
                <Tooltip title="编辑">
                    <Button icon size="small" onClick={() => this.editSmpRef.current.show(entity.id)}><EditOutlined/></Button>
                </Tooltip>
            </SecurityInterceptor>,
            <SecurityInterceptor key="delete" role={["admin", "customer-service"]} permission={["workorder:delete"]}>
                {isUser(entity.creatorId) ?
                    <Tooltip title="删除"><Button danger icon size="small"
                                                onClick={() => this.deleteRef.current.show(entity.id)}><DeleteOutlined/></Button></Tooltip> : null}
            </SecurityInterceptor>
        ];
    };

    show = id => {
        this.setState({
            show: true,
            drawerWidth: window.innerWidth < 576 ? window.innerWidth : window.innerWidth * 0.55,
            equipmentId: id
        }, async () => {
            await this.getManufacturerData();
            this.getEquipmentLog(id);
            this.reload();
            await this.getEquipmentInfo(id);
        });
    };

    close = () => this.setState({show: false});

    getEquipmentInfo = (id) => {
        EquipmentListApi.info(id).then((res) => {
            this.state.commonTypeData.map(item => {
                if (item.value === res.data.deviceType.commonType) {
                    res.data.commonTypeName = item.label;
                }
            })
            this.setState({
                equipment: res.data,
                customerId: res.data.customerId,
                customerDepartmentId: res.data.customerDepartmentId,
                skeletonLoading: false
            }, () => {
                this.requestCustomerInfo();
                this.requestManufacturer();
            });
        }).catch(err => {
            console.error("Get equipment fail", err);
            this.setState({
                skeletonLoading: false
            });
        });
    };

    getEquipmentLog = id => {
        EquipmentListApi.log(id).then(response => {
            this.setState({
                logs: response.data
            });
        }).catch(error => {
            console.warn("Find get resource device logs failed", error);
        });
    };

    requestCustomerInfo = () => {
        CustomerApi.find(this.state.customerId).then(response => {
            console.log(response)
            this.setState({
                customer: {...this.state.customer, customerName: response.data.name}
            });
        }).catch(err => {
            console.error("Request customer info failed", err);
            this.setState({
                customer: {...this.state.customer, customerName: ""}
            });
            // message.error({key: "customer", content: "客户不存在"});
        });
        CustomerApi.findDepartment(this.state.customerDepartmentId).then(res => {
            this.setState({
                customer: {...this.state.customer, customerDepartmentName: res.data.name}
            });
        }).catch(err => {
            console.error("Request customer department info failed", err);
            this.setState({
                customer: {...this.state.customer, customerDepartmentName: ""}
            });
            message.error({key: "customerDepartment", content: "客户单位不存在"});
        });
    };

    onColumnStateMapChange = columnStateMap => this.setState({columnStateMap});

    requestPage = params => {
        if (!!params.locate) {
            params.province = params.locate[0];
            params.city = params.locate[1];
            params.county = params.locate[2];
            params.locate = undefined;
        }
        if (!!params.timer) {
            params.startDate = moment(params.timer[0]).format("YYYY-MM-DD");
            params.endDate = moment(params.timer[1]).format("YYYY-MM-DD");
            params.timer = undefined;
        }
        params.resourceId = this.state.equipmentId;
        return global.axios.get("/workorder/workorders/page", {params}).then(response => {
            return {data: response.data.records, success: true, total: response.data.total};
        }).catch(error => {
            console.log("Find workorder page failed", error);
            return {data: [], success: true, total: 0};
        });
    };

    requestManufacturer = () => {
        global.axios.get(`/resource/manufacturer/${this.state.equipment.deviceType.manufacturer.id}`).then(response => {
            if (response.data.province && response.data.city && response.data.county) {
                this.setState({address: response.data.province + " - " + response.data.city + " - " + response.data.county});
            } else {
                this.setState({address: "-"});
            }
            this.setState({
                manufacturerData: response.data
            });
        }).catch(error => {
            console.warn("Find get resource manufacturer failed", error);
        });
    };

    reload = () => this.actionRef?.reload();

    render() {
        return <Drawer title="查看设备详情" width={this.state.drawerWidth} open={this.state.show}
                       closable={!this.state.loading}
                       onClose={this.close}>
            <Skeleton active loading={this.state.skeletonLoading}>

                <ProDescriptions bordered size="small" layout="horizontal" column={window.innerWidth < 800 ? 1 : 4}
                                 dataSource={this.state.customer}>
                    <ProDescriptions.Item label="客户" span={4} dataIndex="customerName"/>
                    <ProDescriptions.Item label="客户单位" span={4} dataIndex="customerDepartmentName"/>
                </ProDescriptions>

                <ProDescriptions bordered size="small" layout="horizontal"
                                 column={window.innerWidth < 800 ? 1 : 4}
                                 dataSource={this.state.equipment}
                                 loading={this.state.loading}>
                    <ProDescriptions.Item label="报修码" span={2} dataIndex="id"/>
                    <ProDescriptions.Item label="设备名称" span={2} dataIndex="name"/>
                    <ProDescriptions.Item label="出厂序列号" span={2} dataIndex="sn"/>
                    <ProDescriptions.Item label="设备型号" span={2} dataIndex="model"/>
                    <ProDescriptions.Item label="设备大类" span={2} dataIndex="commonTypeName"/>
                    <ProDescriptions.Item label="设备安装时间" span={4} dataIndex="installTime" valueType="dateTime"/>
                    <ProDescriptions.Item label="设备过保时间" span={4} dataIndex="warrantyPeriod" valueType="dateTime"/>
                    <ProDescriptions.Item label="描述信息" span={4} dataIndex="description"/>
                    <ProDescriptions.Item label="楼号楼层及门牌号" span={4} dataIndex="storey"/>
                    <ProDescriptions.Item label="地址信息" span={4}>
                        {this.state.equipment.province} - {this.state.equipment.city} - {this.state.equipment.county} - {this.state.equipment.address}
                    </ProDescriptions.Item>
                    <ProDescriptions.Item label="附件信息" span={4}>
                        <WorkorderFileRender file={this.state.equipment.files}/>
                    </ProDescriptions.Item>
                    <ProDescriptions.Item label="日志信息" span={4}>
                        <Timeline>
                            {
                                this.state.logs.map(item => {
                                    return <Timeline.Item
                                        key={item.id}>{item.createTime} - {item.log}</Timeline.Item>;
                                })
                            }
                        </Timeline>
                    </ProDescriptions.Item>
                </ProDescriptions>

                <ProDescriptions dataSource={this.state.manufacturerData}
                                 bordered size="small" layout="horizontal"
                                 column={window.innerWidth < 800 ? 1 : 4}>
                    <ProDescriptions.Item label="制造商名称" span={4} dataIndex="name"/>
                    <ProDescriptions.Item label="联系方式" span={4} dataIndex="contactsCellphone"/>
                    <ProDescriptions.Item label="地址信息" span={4}>
                        {this.state.address}
                    </ProDescriptions.Item>
                </ProDescriptions>

                <ProTable cardBordered
                          style={{marginTop: 24}}
                          search={false}
                          cardProps={{title: "设备工单"}}
                          rowKey="id"
                          options={{reload: true, setting: true, density: true}}
                          defaultSize="small"
                          actionRef={actionRef => this.actionRef = actionRef}
                          columns={this.columns}
                          columnsState={this.state.columnStateMap}
                          onColumnsStateChange={this.onColumnStateMapChange}
                          request={this.requestPage}/>
                <UpdateSmpWorkorderDrawer onSuccess={this.reload} ref={this.editSmpRef}/>
                <UpdateOpsWorkorderDrawer onSuccess={this.reload} ref={this.editOpsRef}/>
                <DeleteWorkorderModal onSuccess={this.reload} ref={this.deleteRef}/>
                <PreviewSmpWorkorderDrawer onSuccess={this.reload} ref={this.previewSmpRef}/>
            </Skeleton>

        </Drawer>;
    }
}

