import React from "react";
import CustomerApi from "../../../../api/customer/customer";
import {Button, Cascader, Dropdown, Menu, Tooltip} from "antd";
import CustomerDepartmentApi from "../../../../api/customer/department";
import {getUser} from "../../../../component/SecurityInterceptor";

import {PageContainer, ProTable} from "@ant-design/pro-components";
import {DeleteOutlined, EditOutlined, EyeOutlined, MoreOutlined, SwapOutlined} from "@ant-design/icons";
import CreateDisplaceModal from "./modal/CreateDisplaceModal";
import CategorySettingDrawer from "./drawer/CategorySettingDrawer";
import EditDisplaceModal from "./modal/EditDisplaceModal";
import DeleteDisplaceModal from "./modal/DeleteDisplaceModal";
import MonitoringApi from "../../../../api/monitoring";
import WorkorderApi from "../../../../api/workorder";

class DisplaceList extends React.Component{


    state = {
        customersOptions: [],
        customerDepartmentIds: undefined
    };
    actionRef = React.createRef();
    createDisplaceRef = React.createRef();
    categorySettingRef = React.createRef();
    editDisplaceRef = React.createRef();
    deleteDisplaceRef = React.createRef();
    onCustomerDepartmentChange = value => {

    };


    renderColumnOperations = (node, entity, index, action) => {
        return [
            <Tooltip title="编辑" key="edit">
                <Button icon size="small" onClick={() => this.editDisplaceRef.current.show(entity.id)}>
                    <EditOutlined/>
                </Button>
            </Tooltip>,
            <Tooltip title="删除" key="delete">
                <Button icon size="small" danger onClick={() => this.deleteDisplaceRef.current.show(entity.id)}>
                    <DeleteOutlined />
                </Button>
            </Tooltip>,

        ];
    };

    columns = [
        {
            title: "所在区县",
            key: "locate",
            hideInTable: true,
            renderFormItem: (_, {type, defaultRender, formItemProps, fieldProps}) => {
                return (
                    <Cascader options={require("asset/location.json")} {...fieldProps}/>
                );
            }
        },

        {
            title: "所在区县",
            key: "locate",
            valueType: "option",
            render: (node, entity) => `${entity.province}-${entity.city}-${entity.county}`
        },
        {
            title: "现存设备总量",
            dataIndex: "count",
            hideInSearch: true
        },
        {
            title: "替换国产设备量",
            dataIndex: "replaceNum",
            hideInSearch: true
        },

        {
            title: "替换率",
            dataIndex: "replaceRate",
           // render: (node, entity) => `${entity.replaceNum/entity.count*100}%`,
            hideInSearch: true
        },

        {
            title: "操作",
            valueType: "option",
            render: (node, entity, index, action) => this.renderColumnOperations(node, entity, index, action)
        }
    ];

    requestPage = params => {
        if (!!params.locate) {
            params.province = params.locate[0];
            params.city = params.locate[1];
            params.county = params.locate[2];
            params.locate = undefined;
        }
        return MonitoringApi.page(params).then(response => {
            if (!!response.data) {
                return {data: response.data.records, success: true, total: response.data.total};
            }
            return {data: [], success: true, total: 0};
        }).catch(error => {
            console.log("Find workorder page failed", error);
            return {data: [], success: true, total: 0};
        });
    };

    requestEquipmentList = data => {

    };

    reload = () => this.actionRef?.reload();


    render() {
        return <PageContainer header={{title: "区域替换监控列表", breadcrumb: {}}}>
            <ProTable headerTitle="区域替换监控列表"
                      rowKey="id"
                      defaultSize="small"
                      toolBarRender={() => [
                          <Button type="primary" onClick={() => this.categorySettingRef.current.show()}>设备鉴别</Button>,
                          <Button type="primary" onClick={() => this.createDisplaceRef.current.show()}>新增</Button>,
                      ]}
                      options={{reload: true, setting: true, density: true}}
                      actionRef={actionRef => this.actionRef = actionRef}
                      columns={this.columns}
                      request={this.requestPage}/>
            <CreateDisplaceModal onSuccess={this.reload} ref={this.createDisplaceRef}/>
            <EditDisplaceModal onSuccess={this.reload} ref={this.editDisplaceRef}/>
            <DeleteDisplaceModal onSuccess={this.reload} ref={this.deleteDisplaceRef}/>
            <CategorySettingDrawer onSuccess={this.reload} ref={this.categorySettingRef}/>
        </PageContainer>;
    }
}

export default DisplaceList;