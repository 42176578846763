import React, {Component} from "react";
import {ProTable} from "@ant-design/pro-components";
import {Button, Tooltip} from "antd";
import {EyeOutlined} from "@ant-design/icons";

import PreviewScheduleRecordDrawer from "../../record/drawer/PreviewScheduleRecordDrawer";

import StorehouseInventoryApi from "api/spares/storehouse/inventory";
import AccountApi from "api/user/account";

class ScheduleInputRecord extends Component {

    columns = [
        {
            title: "ID",
            dataIndex: "id",
            editable: false
        },
        {
            title: "审核状态",
            dataIndex: "audit",
            editable: false,
            valueType: "select",
            valueEnum: {
                "1": {text: "待审核"},
                "5": {text: "审核通过"},
                "99": {text: "驳回"}
            }
        },
        {
            title: "源仓库",
            dataIndex: ["originRepository", "name"],
            hideInSearch: true
        },
        {
            title: "目标仓库",
            dataIndex: ["targetRepository", "name"],
            hideInSearch: true
        },
        {
            title: "发起时间",
            dataIndex: "createTime",
            hideInSearch: true
        },
        {
            title: "发起人",
            dataIndex: "creatorName",
            hideInSearch: true
        },
        {
            title: "操作",
            valueType: "option",
            render: (node, entity, index, action) => this.renderColumnOperations(node, entity, index, action)
        }
    ];

    previewScheduleRecord = React.createRef();

    renderColumnOperations = (node, entity, index, action) => {
        return [
            <Tooltip title="查看记录" key="preview">
                <Button icon size="small" onClick={() => this.previewScheduleRecord.current.show(entity.id, "input")}>
                    <EyeOutlined/>
                </Button>
            </Tooltip>
        ];
    };

    /**
     * audit 1: 需要审核  5：通过  99：驳回
     * @param params
     * @returns {Promise<{total: *, data: *, success: boolean} | {total: number, data: [], success: boolean}>}
     */
    requestPage = (params) => {
        params.targetRepository = this.props.storehouseId;
        return StorehouseInventoryApi.schedulePage(params).then(async (res) => {
            let page = await this.handleCreateId(res.data.records);
            return {data: page, total: res.data.total, success: true};
        }).catch(err => {
            return {data: [], total: 0, success: true};
        });
    };

    handleCreateId = async (data) => {
        let creatorId = [];
        let accountData = null;
        data.forEach(item => {
            if (!creatorId.includes(item.creator)) {
                creatorId.push(item.creator);
            }
        });
        if (creatorId.length > 0) {
            accountData = await this.requestAccount(creatorId.join(","));
            accountData.forEach(item => {
                data.forEach(itemY => {
                    if (item.id === itemY.creator) {
                        itemY["creatorName"] = item.name;
                    }
                });
            });
        }

        return data;
    };

    requestAccount = async (ids) => {
        const {data} = await AccountApi.batchRequestAccount({ids});
        return data;
    };

    reload = () => this.actionRef.reload();

    render() {
        return (<>
            <ProTable headerTitle="调拨入库审核"
                      rowKey="id"
                      defaultSize="small"
                      toolBarRender={this.tableToolbarRender}
                      options={{reload: true, setting: true, density: true}}
                      actionRef={actionRef => this.actionRef = actionRef}
                      columns={this.columns}
                      request={this.requestPage}/>
            <PreviewScheduleRecordDrawer onSuccess={this.reload} ref={this.previewScheduleRecord}/>
        </>);
    }
}

export default ScheduleInputRecord;
