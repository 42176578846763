import axios from "axios";

const _axios = axios.create({
    baseURL: global.config.url,
    timeout: 6000 * 10
});

_axios.interceptors.request.use(
    function (config) {
        // 在请求头中添加
        config.headers = {
            token: localStorage.getItem("Token")
        };
        return config;
    },
    function (error) {
        if (!!error.response) {
            return Promise.reject(error.response);
        } else {
            return Promise.reject({status: 50400, desc: "网络异常", data: "网络异常"});
        }
    }
);

_axios.interceptors.response.use(
    response => {
        if (response.data.status === 20000) {
            return response.data;
        }
        return Promise.reject(response.data);
    },
    error => {
        if (!!error.response) {
            if (error.response.data.status === 40300) {
                window.location.replace("/login");
                localStorage.clear();
            }
            return Promise.reject(error.response.data);
        } else {
            return Promise.reject({status: 50400, desc: "网络异常", data: "网络异常"});
        }
    }
);

global.axios = _axios;
window.axios = _axios;

export default _axios;
