import React, {Component} from "react";
import {Drawer, Tag} from "antd";
import {ProDescriptions} from "@ant-design/pro-components";

import EquipmentRepositoryApi from "api/equipment/repository";

import DescriptionFileRender from "component/DescriptionFileRender";

/**
 * 查看知识库文章
 * 根据id 获取对应的知识库文章数据
 * 使用 ProDescriptions 渲染
 */
class PreviewArticleDrawer extends Component {

    editRef = React.createRef();

    state = {
        show: false,
        drawerWidth: 600,
        loading: false,
        deviceTypeName: "",
        deviceTypeModel: "",
        articleData: {
            files: []
        }
    };

    show = entity => {
        this.setState({
            show: true,
            deviceTypeName: entity.deviceTypeName,
            deviceTypeModel: entity.deviceTypeModel,
            drawerWidth: window.innerWidth < 576 ? window.innerWidth : window.innerWidth * 0.55
        }, () => {
            this.getArticleInfo(entity.id);
        });
    };

    close = () => this.setState({show: false});

    getArticleInfo = id => {
        EquipmentRepositoryApi.getInfo(id).then(res => {
            this.setState({articleData: res.data});
        }).catch(error => {
            console.error("Get article info fail", error);
        });
    };

    renderDeviceTags = () => {
        if (!this.state.articleData.tag || this.state.articleData.tag === "") {
            return "-";
        }
        let tags = this.state.articleData.tag.split(",");
        return tags.map((item, index) => {
            return <Tag color="#2db7f5" key={index}>{item}</Tag>
        })
    }

    render() {
        return <Drawer title="查看知识库文章" width={this.state.drawerWidth} open={this.state.show}
                       destroyOnClose
                       closable={!this.state.loading}
                       onClose={this.close}>
            <ProDescriptions bordered size="small" layout="horizontal"
                             column={4}
                             dataSource={this.state.articleData}
                             loading={this.state.loading}>
                <ProDescriptions.Item label="设备类型" span={2} dataIndex={["devicetype", "name"]}/>
                <ProDescriptions.Item label="类型型号" span={2} dataIndex={["devicetype", "model"]}/>
                <ProDescriptions.Item label="问题描述" span={4}>
                    <p dangerouslySetInnerHTML={{__html: this.state.articleData.question}}/>
                </ProDescriptions.Item>
                <ProDescriptions.Item label="设备适配" span={4}>
                    {
                        this.renderDeviceTags()
                    }
                    {/*<Tag color="#2db7f5">#2db7f5</Tag>*/}
                    {/*<Tag color="#2db7f5">#2db7f5</Tag>*/}
                    {/*<Tag color="#2db7f5">#2db7f5</Tag>*/}
                    {/*<Tag color="#2db7f5">#2db7f5</Tag>*/}
                    {/*<p dangerouslySetInnerHTML={{__html: this.state.articleData.question}}/>*/}
                </ProDescriptions.Item>
                <ProDescriptions.Item label="描述附件" span={4}>
                    <DescriptionFileRender file={this.state.articleData.questionFiles}/>
                </ProDescriptions.Item>
                <ProDescriptions.Item label="解决方案" span={4}>
                    <p dangerouslySetInnerHTML={{__html: this.state.articleData.solution}}/>
                </ProDescriptions.Item>
                <ProDescriptions.Item label="解决方案附件" span={4}>
                    <DescriptionFileRender file={this.state.articleData.solutionFiles}/>
                </ProDescriptions.Item>
            </ProDescriptions>
        </Drawer>;
    }
}

export default PreviewArticleDrawer;
