import React from "react";
import {
    Button,
    Cascader,
    Col,
    DatePicker,
    Drawer,
    Form,
    Input,
    message,
    Modal,
    Row,
    Select,
    Space,
    TreeSelect
} from "antd";
import moment from "moment";

import CustomerApi from "api/customer/customer";
import WorkorderApi from "api/workorder";
import ContractApi from "api/contract";

import CreateCustomerModal from "page/home/customer/customer/modal/CreateCustomerModal";
import CreateCustomerDepartmentModal from "page/home/customer/department/modal/CreateCustomerDepartmentModal";
import {OpsWorkorderType} from "../../assets/WorkorderType";

import CustomerDepartmentPicker from "component/customer/department/CustomerDepartmentPicker";
import {setFieldsError} from "plugin/formily";
import FileUploadRender from "component/FileUploadRender";
import dayjs from "dayjs";
import DispatchUnassignedWorkorderModal from "../modal/DispatchUnassignedWorkorderModal";

let handlerInfo = [];

/**
 * 发起运维工单 Drawer
 */
export default class CreateOpsUnassignedWorkorderDrawer extends React.Component {

    state = {
        drawerWidth: 280,
        show: false,
        loading: false,
        dispatchLoading: false,
        customerDepartmentsOptions: [],
        customersOptions: [],
        workorderHandlersOptions: [],
        loadingCustomerDepartmentsOptions: false,
        loadingCustomersOptions: false,
        loadingWorkorderHandlersOptions: false,
        uploadFiles: [],
        dispatch: false,
        showDispatchModal: false,
        progressDispatchWorkorder: false,
        workorderHandler: [],
        workorderId: "",
        customerDepartmentId: "",
        WorkorderHandlerId: "",
        contractOption: [],
        contractPostTime: "",
        contractStartTime: "",
        departmentId: ""
    };

    formRef = React.createRef();
    creatDepartmentRef = React.createRef();
    dispatchFormRef = React.createRef();
    createCustomerRef = React.createRef();
    customerDepartmentRef = React.createRef();
    filesRef = React.createRef();
    dispatchWorkorderRef = React.createRef();
    toggle = () => {
        this.setState({
            show: !this.state.show,
            drawerWidth: window.innerWidth < 576 ? window.innerWidth : window.innerWidth * 0.55,
            loading: false
        }, () => {
            if (this.state.show) {
                this.requestWorkorderHandlersOptions();
                this.getAllContract();
            } else {
                this.formRef.current.setFieldsValue({
                    type: "ldcl",
                    priority: "5",
                    deadLine: null,
                    clientDepartment: null,
                    clientCustomer: null,
                    description: null,
                    files: []
                });
                this.setState({
                    dispatch: false,
                    departmentId: null,
                    customersOptions: null,
                    dispatchLoading: false
                });
            }
        });
    };

    /**
     * 关闭指派工单的弹窗
     */
    toggleDispatchModal = (data) => {
        this.toggle();
        this.setState({ loading: !this.state.loading });

        // this.setState({
        //     showDispatchModal: !this.state.showDispatchModal
        // }, () => {
        //     if (!this.state.showDispatchModal) {
        //         // 关闭外层发起工单 Drawer
        //         this.toggle();
        //
        //         if (!this.state.showDispatchModal) {
        //             this.props.onSuccess();
        //         }
        //         // 重置表单
        //         this.dispatchFormRef.current?.setFieldsValue({
        //             handlerId: null,
        //             workorderId: null
        //         });
        //     }
        // });
    };

    toggleLoading = () => this.setState({loading: !this.state.loading});

    toggleCustomerDepartmentsOptionsLoading = () => this.setState({loadingCustomerDepartmentsOptions: !this.state.loadingCustomerDepartmentsOptions});

    toggleCustomersOptionsLoading = () => this.setState({loadingCustomersOptions: !this.state.loadingCustomersOptions});

    toggleWorkorderHandlersOptionsLoading = () => this.setState({loadingWorkorderHandlersOptions: !this.state.loadingWorkorderHandlersOptions});

    /**
     * 通过 departmentId 获取客户
     * @param departmentId 客户单位
     */
    requestCustomersOptions = departmentId => {
        this.toggleCustomersOptionsLoading();
        CustomerApi.list({departmentId}).then(response => {
            const customerOptions = [];
            if (!!response.data && response.data.length > 0) {
                response.data.forEach((customer) => {
                    customerOptions.push(this.handleCustomerOption(customer));
                });
            }
            this.setState({customersOptions: customerOptions, loadingCustomersOptions: false});
        }).catch(error => {
            this.toggleCustomersOptionsLoading();
            console.warn("Get customers failed", error);
        });
    };

    /**
     * 获取所有指派人
     */
    requestWorkorderHandlersOptions = () => {
        //重置处理人数据
        handlerInfo = [];

        this.toggleWorkorderHandlersOptionsLoading();
        global.axios.get("/user/accounts").then(response => {
            this.toggleWorkorderHandlersOptionsLoading();
            const provinces = {};

            for (const handler of response.data) {
                // 初始化当前用户所在省份
                let province;
                if (!!provinces[handler.province]) {
                    province = provinces[handler.province];
                } else {
                    province = {
                        label: handler.province,
                        value: handler.province,
                        test: {}
                    };
                    provinces[handler.province] = province;
                }

                // 初始化当前用户所在市
                let city;
                if (!!province.test[handler.city]) {
                    city = province.test[handler.city];
                } else {
                    city = {
                        label: handler.city,
                        value: handler.city,
                        test: {}
                    };
                    province.test[handler.city] = city;
                }

                let county;
                // 初始化当前用户所在区县
                if (!!city.test[handler.county]) {
                    county = city.test[handler.county];
                } else {
                    county = {
                        label: handler.county,
                        value: handler.county,
                        children: []
                    };
                    city.test[handler.county] = county;
                }

                county.children.push({
                    label: `${handler.name}`,
                    value: handler.id
                });
            }

            for (let key in provinces) {
                handlerInfo.push(provinces[key]);
            }
            for (let i = 0; i < handlerInfo.length; i++) {
                for (let key in handlerInfo[i].test) {
                    if (!handlerInfo[i]["children"]) {
                        handlerInfo[i]["children"] = [];
                    }
                    handlerInfo[i]["children"].push(handlerInfo[i].test[key]);

                    handlerInfo[i]["children"].forEach((item) => {
                        item["children"] = [];
                        for (let key in item.test) {
                            item["children"].push(item.test[key]);
                        }
                    });
                }
            }
        }).catch(error => {
            this.toggleWorkorderHandlersOptionsLoading();
            console.warn("Request user account failed.", error);
        });

    };

    /**
     * 获取选择的客户单位 ID
     * @param departmentId 客户单位 ID
     */
    onCustomerDepartmentChange = departmentId => {
        this.formRef.current.setFieldsValue({clientCustomer: null});
        this.requestCustomersOptions(departmentId);
        this.setState({departmentId});
    };

    /**
     * 获取新建客户的客户单位 ID
     * @param departmentId 客户单位 ID
     * @param customerId 客户 ID
     */
    onCreateCustomerSuccess = (departmentId, customerId) => {
        const customerDepartmentId = this.formRef.current?.getFieldValue("clientDepartment");
        if (!!customerDepartmentId) {
            this.requestCustomersOptions(customerDepartmentId);
        }
        if (departmentId === customerDepartmentId) {
            this.formRef.current?.setFieldsValue({
                clientCustomer: customerId
            });
        }
    };

    /**
     * 获取新建的客户单位 ID
     * @param departmentId 客户单位 ID
     */
    onCreateCustomerDepartmentSuccess = departmentId => {
        this.customerDepartmentRef.current.reload();
        this.requestCustomersOptions(departmentId);
        this.formRef.current?.setFieldsValue({
            clientDepartment: departmentId,
            clientCustomer: null
        });
        this.setState({departmentId});
    };

    /**
     * 转换客户信息为选项
     * @param customer 客户
     */
    handleCustomerOption = customer => {
        return {
            label: `${customer.name}(${customer.cellphone})`,
            value: customer.id
        };
    };

    /**
     * 发起工单
     * @param data 提交数据
     */
    handle = data => {
        let nowTime = dayjs().locale("zh-cn").format("YYYYMMDD");
        let contractTime = dayjs(this.state.contractPostTime).format("YYYYMMDD");
        let contractStartTime = dayjs(this.state.contractStartTime).format("YYYYMMDD");
        if ((contractTime - nowTime) < 0) {
            this.formRef.current.setFields([{
                name: "resourceId",
                errors: ["合同已到期"]
            }]);
            return;
        }
        if ((nowTime - contractStartTime) < 0) {
            this.formRef.current.setFields([{
                name: "resourceId",
                errors: ["合同未开始"]
            }]);
            return;
        }
        this.toggleLoading();
        let param = {
            clientDepartmentId: data.clientDepartment,
            clientId: data.clientCustomer,
            deadLine: data.deadLine.format("YYYY-MM-DD HH:mm"),
            description: data.description,
            priority: data.priority,
            title: data.title,
            type: data.type,
            files: this.filesRef.current.getFilesValue(),
            logicType: "ops",
            resourceId: data.resourceId,
            origin: "default"
        };
        WorkorderApi.create(param).then(response => {
            this.formRef.current?.resetFields();

            if (this.state.dispatch) {
                // 打开指派弹窗
                this.setState({
                    // showDispatchModal: true,
                    workorderId: response.data
                });
                this.dispatchWorkorderRef.current.show(response.data,param.clientDepartmentId);// 打开指派弹窗
            } else {
                this.toggle();
                this.props.onSuccess();
            }
        }).catch(error => {
            if (error.status === 40000) {
                setFieldsError(error, this.formRef);
            } else {
                message.error({key: "failed", content: [error.data]});
            }
            this.toggleLoading();
            console.warn("Create workorder failed", error);
        });
    };

    handleAndDispatch = () => this.setState({dispatch: true}, () => {
        this.formRef.current?.submit();
    });
    // handleAndDispatch = () => {
    //     this.formRef.current?.submit();//发起工单
    //
    //     // this.setState({dispatch: true}, () => {
    //     //     this.formRef.current?.submit();
    //     // });
    // }

    renderTrigger = () => <Button type="primary" onClick={this.toggle}>发起运维工单</Button>;

    /**
     * 获取所有合同信息
     */
    getAllContract = () => {
        ContractApi.getAllContractInfo("163063635416059904").then(response => {
            let contractArr = [];
            response.data.forEach((item) => {
                let obj = {label: "", value: ""};
                obj.label = `${item.name}(${item.comment})`;
                obj.value = item.id;
                contractArr.push(obj);
            });
            this.setState({
                contractOption: contractArr
            });
        });
    };

    /**
     * 合同选中时触发
     * @param val
     */
    contractOnChange = val => {
        ContractApi.contractPostData("163063635416059904").then(response => {
            response.data.forEach((item) => {
                if (item.id === val) {
                    item.infos.forEach((itemY) => {
                        if (itemY.name === "合同到期时间") {
                            this.setState({
                                contractPostTime: itemY.value
                            });
                        } else if (itemY.name === "合同开始时间") {
                            this.setState({
                                contractStartTime: itemY.value
                            });
                        }
                    });
                }
            });
        });
    };

    /**
     * 选择客户后触发
     * @param value
     */
    onClientChange = value => {
        CustomerApi.find(value).then(res => {
            this.formRef.current.setFieldsValue({
                clientAddress: res.data.address
            });
        }).catch(err => {
            console.error("Request client info failed", err);
        });
    };

    /**
     *控制弹窗
     */
    showCustomerDialog = (id, ref) => ref.current.show(id);

    showDepartmentDialog = (id, ref) => ref.current.show(id);

    renderDrawer = () => {
        return <Drawer title="发起运维工单" width={this.state.drawerWidth} open={this.state.show}
                       closable={!this.state.loading} destroyOnClose maskClosable={false}
                       onClose={this.toggle}
                       footerStyle={{textAlign: "right"}} footer={<Space>
            <Button disabled={this.state.loading} onClick={this.toggle}>取消</Button>
            <Button loading={this.state.loading} type="primary"
                    onClick={() => this.formRef.current.submit()}>发起工单</Button>
            <Button loading={this.state.loading} type="primary"
                    onClick={this.handleAndDispatch}>发起工单并指派</Button>
        </Space>}>
            <Form ref={this.formRef} onFinish={this.handle} layout={"vertical"}>
                <Row gutter={[8, 0]} align="middle">
                    <Col span={12}>
                        <Form.Item name="type" label="服务类型" required initialValue="ldcl"
                                   rules={[{required: true, message: "请选择服务类型"}]}>
                            <TreeSelect treeData={OpsWorkorderType} showSearch treeNodeFilterProp="title"/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="priority" label="优先级" required initialValue="5"
                                   rules={[{required: true, message: "请选择优先级"}]}>
                            <Select options={require("../../assets/WorkorderPriority").priorityOptions()}/>
                        </Form.Item>
                    </Col>

                    <Col sm={9} xs={18}>
                        <CustomerDepartmentPicker required onChange={this.onCustomerDepartmentChange}
                                                  ref={this.customerDepartmentRef}
                                                  rules={[{required: true, message: "请选择客户单位"}]}/>
                    </Col>
                    <Col sm={3} xs={6} align="middle">
                        <Button block style={{marginTop: 6}}
                                onClick={() => this.showDepartmentDialog("", this.creatDepartmentRef)}>新建单位</Button>
                    </Col>
                    <Col sm={9} xs={18}>
                        <Form.Item name="clientCustomer" label="客户" required
                                   rules={[{required: true, message: "请选择客户"}]}>
                            <Select options={this.state.customersOptions}
                                    loading={this.state.loadingCustomersOptions}
                                    showSearch optionFilterProp="label" onChange={this.onClientChange}/>
                        </Form.Item>
                    </Col>
                    <Col sm={3} xs={6} align="middle">
                        <Button block style={{marginTop: 6}}
                                onClick={() => this.showCustomerDialog(this.state.departmentId, this.createCustomerRef)}>新建客户</Button>
                    </Col>
                </Row>
                <Row gutter={8} align="middle">
                    <Col span={24}>
                        <Form.Item name="clientAddress" label="客户详细地址">
                            <Input disabled/>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={8} align="middle">
                    <Col span={12}>
                        <Form.Item name="resourceId" label="合同" required
                                   rules={[{required: true, message: "请选择合同"}]}>
                            <Select options={this.state.contractOption} onChange={this.contractOnChange} showSearch
                                    optionFilterProp="label"/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="deadLine" label="计划时间" required
                                   rules={[{required: true, message: "请选择计划时间"}]}>
                            <DatePicker showTime format="YYYY-MM-DD HH:mm" inputReadOnly style={{width: "100%"}}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item name="description" label="工单描述" rules={[
                            {pattern: /\S/, message: "内容不能为空"}
                        ]}>
                            <Input.TextArea/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <FileUploadRender ref={this.filesRef} name="files"
                                          uploadPath="/workorder/workorder/unassigned/file"/>
                    </Col>
                </Row>
            </Form>
            {/*{this.renderDispatchWorkorderModal()}*/}

            <CreateCustomerDepartmentModal onSuccess={this.onCreateCustomerDepartmentSuccess}
                                           ref={this.creatDepartmentRef}/>
            <CreateCustomerModal onSuccess={this.onCreateCustomerSuccess} ref={this.createCustomerRef}/>
            <DispatchUnassignedWorkorderModal onSuccess={this.toggleDispatchModal} ref={this.dispatchWorkorderRef}/>
        </Drawer>;
    };

    render() {
        return <>
            {this.renderTrigger()}
            {this.renderDrawer()}
        </>;
    };
}
