import React from "react";
import {Button, Col, Row, Space, Typography} from "antd";
import DraggableModal from "component/DraggableModal";

/**
 * 删除报修池的工单弹窗 Modal
 */
export default class DeletePrepareWorkorderModal extends React.Component {

    state = {
        loading: false,
        show: false,
        workorderId: ""
    };

    draggableRef = React.createRef();

    show = id => {
        this.draggableRef.current.show();
        this.setState({workorderId: id});
    };

    close = () => {
        this.setState({loading: false});
        this.draggableRef.current.close();
    };

    toggleLoading = () => this.setState({loading: !this.state.loading});

    handle = () => {
        this.toggleLoading();
        global.axios.delete(`/workorder/workorder/prepare/${this.state.workorderId}`).then(() => {
            this.close();
            if (!!this.props.onSuccess) {
                this.props.onSuccess();
            }
        }).catch(error => {
            this.toggleLoading();
            console.warn("Delete workorder failed", error);
        });
    };

    render() {
        return <DraggableModal onCancel={this.close} title="是否删除工单" ref={this.draggableRef}>
            <Typography>数据删除后无法恢复，请谨慎操作！</Typography>
            <Row gutter={8} justify="end">
                <Col>
                    <Space>
                        <Button disabled={this.state.loading} onClick={this.close}>取消</Button>
                        <Button loading={this.state.loading} type="primary" onClick={this.handle}>删除</Button>
                    </Space>
                </Col>
            </Row>
        </DraggableModal>;
    }

}
