/**
 * 信创工单服务类型
 */
export const SmpWorkorderType = [
    {
        title: "软件维护", key: "rjwh", value: "rjwh", disabled: true, children: [
            {
                title: "国产OA维护",
                key: "gcoawh",
                value: "gcoawh",
                disabled: true,
                children: [
                    {
                        title: "硬件故障",
                        key: "oa/yjgz",
                        value: "oa/yjgz"
                    },
                    {
                        title: "系统故障",
                        key: "oa/xtgz",
                        value: "oa/xtgz"
                    },
                    {
                        title: "流程制定",
                        key: "oa/lcdz",
                        value: "oa/lcdz"
                    },
                    {
                        title: "日常维护",
                        key: "oa/rcwh",
                        value: "oa/rcwh"
                    },
                ]
            },
            {
                title: "系统软件维护",
                key: "xtrjwh",
                value: "xtrjwh"
            }
        ]
    },
    {
        title: "硬件维修", key: "yjwx", value: "yjwx", disabled: true, children: [
            {
                title: "专用机维护",
                key: "zyjwh",
                value: "zyjwh"
            },
            {
                title: "专用机维护（超越申泰 DT3000-L12）",
                key: "zyjwh-cyst-DT3000-L12",
                value: "zyjwh-cyst-DT3000-L12"
            },
            {
                title: "专用机维护（超越申泰 DT3010-L12）",
                key: "zyjwh-cyst-DT3010-L12",
                value: "zyjwh-cyst-DT3010-L12"
            },
            {
                title: "专用机维护（超越申泰 DT3020-L）",
                key: "zyjwh-cyst-DT3020-L",
                value: "zyjwh-cyst-DT3020-L"
            },
            {
                title: "专用机维护（超越申泰 DT3030-L）",
                key: "zyjwh-cyst-DT3030-L",
                value: "zyjwh-cyst-DT3030-L"
            },
            {title: "通用机维护", key: "tyjwh", value: "tyjwh"},
            {
                title: "通用机维护（天玥 TR1192）",
                key: "tyjwh-ty-TR1192",
                value: "tyjwh-ty-TR1192"
            },
            {
                title: "通用机维护（超越申泰 DT3010-F）",
                key: "tyjwh-cystDT-3010-F",
                value: "tyjwh-cystDT-3010-F"
            },
            {
                title: "通用机维护（超越申泰 DT3010-L）",
                key: "tyjwh-cystDT-3010-L",
                value: "tyjwh-cystDT-3010-L"
            },
            {
                title: "通用机维护（超越申泰 DT3010-L33）",
                key: "tyjwh-cystDT-3010-L33",
                value: "tyjwh-cystDT-3010-L33"
            },
            {
                title: "通用机维护（超越申泰 DT3020-L）",
                key: "tyjwh-cystDT-3020-L",
                value: "tyjwh-cystDT-3020-L"
            },
            {
                title: "通用机维护（超越申泰 DT3030-L）",
                key: "tyjwh-cystDT-3030-L",
                value: "tyjwh-cystDT-3030-L"
            },
            {
                title: "通用机维护（天熠 TR41A1）",
                key: "tyjwh-ty-TR41A1",
                value: "tyjwh-ty-TR41A1"
            },
            {
                title: "打印机维护",
                key: "dyjwh",
                value: "dyjwh"
            },
            {
                title: "打印机维护（光电通扫描仪 OEM200M）",
                key: "dyjwh-gdtsmy-OEM200M",
                value: "dyjwh-gdtsmy-OEM200M"
            },
            {
                title: "打印机维护（光电通白色一体机 OEM3000DN）",
                key: "dyjwh-gdtbsytj-OEM3000DN",
                value: "dyjwh-gdtbsytj-OEM3000DN"
            },
            {
                title: "打印机维护（光电通黑色一体机 OEP400DN）",
                key: "dyjwh-gdthsytj-OEP400DN",
                value: "dyjwh-gdthsytj-OEP400DN"
            },
            {
                title: "打印机维护（光电通 OEP102D）",
                key: "dyjwh-gdt-OEP102D",
                value: "dyjwh-gdt-OEP102D"
            },
            {
                title: "打印机维护（光电通 MP4025DN）",
                key: "dyjwh-gdt-MP4025DN",
                value: "dyjwh-gdt-MP4025DN"
            },
            {
                title: "打印机维护（光电通 OEP3015DN）",
                key: "dyjwh-gdt-OEP3015DN",
                value: "dyjwh-gdt-OEP3015DN"
            },
            {
                title: "打印机维护（光电通 MP4024DN）",
                key: "dyjwh-gdt-MP4024DN",
                value: "dyjwh-gdt-MP4024DN"
            },
            {
                title: "打印机维护（光电通 OEP3115CDN）",
                key: "dyjwh-gdt-OEP3115CDN",
                value: "dyjwh-gdt-OEP3115CDN"
            },
            {
                title: "打印机维护（光电通 MP3104CDN）",
                key: "dyjwh-gdt-MP3104CDN",
                value: "dyjwh-gdt-MP3104CDN"
            },
            {
                title: "打印机维护（光电通 MP3020DN）",
                key: "dyjwh-gdt-MP3020DN",
                value: "dyjwh-gdt-MP3020DN"
            },
            {
                title: "打印机维护（光电通 OEP6015DN）",
                key: "dyjwh-gdt-OEP6015DN",
                value: "dyjwh-gdt-OEP6015DN"
            },
            {
                title: "打印机维护（奔图 M7105DN）",
                key: "dyjwh-bt-M7105DN",
                value: "dyjwh-bt-M7105DN"
            }
        ]
    },
    {title: "硬件安装", key: "yjaz", value: "yjaz"},
    {title: "系统巡检", key: "xtxj", value: "xtxj"},
    {title: "漏洞处理", key: "ldcl", value: "ldcl"},
    {title: "打印机换粉", key: "dyjhf", value: "dyjhf"},
    {title: "硬件回收销毁", key: "yjhsxh", value: "yjhsxh"},
    {title: "纸质文件销毁", key: "zzwjxh", value: "zzwjxh"},
    {title: "大组工网运维", key: "dzgwyw", value: "dzgwyw"},
    {title: "其他-客户发起", key: "others-customer", value: "others-customer", disabled: true}
];

/**
 * 运维工单服务类型
 */
export const OpsWorkorderType = [
    {
        title: "软件维护", key: "rjwh", value: "rjwh", disabled: true, children: [
            {title: "智慧党建维护", key: "zhdjwh", value: "zhdjwh"},
            {title: "OA维护", key: "oawh", value: "oawh"},
            {title: "系统软件维护", key: "xtrjwh", value: "xtrjwh"},
            {title: "网站维护", key: "wzwh", value: "wzwh"}
        ]
    },
    {
        title: "硬件维修", key: "yjwx", value: "yjwx", disabled: true, children: [
            {title: "服务器维修", key: "fwqwx", value: "fwqwx"},
            {title: "网络设备维修", key: "wlsbwx", value: "wlsbwx"}
        ]
    },
    {title: "硬件安装", key: "yjaz", value: "yjaz"},
    {title: "系统巡检", key: "xtxj", value: "xtxj"},
    {title: "漏洞处理", key: "ldcl", value: "ldcl"},
    {title: "敏感字眼处理", key: "mgzycl", value: "mgzycl"}
];

/**
 * 工单类型
 * @description valueEnum
 */
export const WorkorderType = {
    "rjwh": {
        "text": "软件维护"
    },
    "gcoawh": {
        "text": "国产OA维护"
    },
    "oa/yjgz": {
        "text": "国产OA维护/硬件故障"
    },
    "oa/xtgz": {
        "text": "国产OA维护/系统故障"
    },
    "oa/lcdz": {
        "text": "国产OA维护/流程制定"
    },
    "oa/rcwh": {
        "text": "国产OA维护/日常维护"
    },
    "xtrjwh": {
        "text": "系统软件维护"
    },
    "zyjwh": {
        "text": "专用机维护"
    },
    "tyjwh": {
        "text": "通用机维护"
    },
    "dyjwh": {
        "text": "打印机维护"
    },
    // 线上已存在数据 无奈之举
    "dyjwh-gdt-MP4024DN": {
        "text": "打印机维护"
    },
    "yjaz": {
        "text": "硬件安装"
    },
    "xtxj": {
        "text": "系统巡检"
    },
    "ldcl": {
        "text": "漏洞处理"
    },
    "dyjhf": {
        "text": "打印机换粉"
    },
    "yjhsxh": {
        "text": "硬件回收销毁"
    },
    "zzwjxh": {
        "text": "纸质文件销毁"
    },
    "zhdjwh": {
        "text": "智慧党建维护"
    },
    "oawh": {
        "text": "OA维护"
    },
    "wzwh": {
        "text": "网站维护"
    },
    "fwqwx": {
        "text": "服务器维修"
    },
    "wlsbwx": {
        "text": "网络设备维修"
    },
    "mgzycl": {
        "text": "敏感字眼处理"
    },
    "dzgwyw": {
        "text": "大组工网运维"
    },
    "others-customer": {
        "text": "其他客户发起"
    }
};
/**
 * 工单超时
 */
export const TimeoutWorkorder = {
    "1": {
        "text": "超时领取工单"
    },

}

/**
 * 转换工单类型
 */
export const ConvertSmpWorkorderType = workorder => {
    switch (workorder.type) {
        case "zyjwh":
            workorder.type = "zyjwh";
            workorder.resourceType = "超越申泰";
            workorder.resourceName = "DT3000-L12";
            break;
        case "zyjwh-cyst-DT3010-L12":
            workorder.type = "zyjwh";

            workorder.resourceType = "超越申泰";
            workorder.resourceName = "DT3010-L12";
            break;
        case "zyjwh-cyst-DT3020-L":
            workorder.type = "zyjwh";

            workorder.resourceType = "超越申泰";
            workorder.resourceName = "DT3020-L";
            break;
        case "tyjwh-ty-TR1192":
            workorder.type = "tyjwh";
            workorder.resourceType = "天玥";
            workorder.resourceName = "TR1192";
            break;
        case "tyjwh-cystDT-3010-F":
            workorder.type = "tyjwh";
            workorder.resourceType = "超越申泰";
            workorder.resourceName = "DT3010-F";
            break;
        case "tyjwh-cystDT-3010-L":
            workorder.type = "tyjwh";
            workorder.resourceType = "超越申泰";
            workorder.resourceName = "DT3010-L";
            break;
        case "tyjwh-cystDT-3010-L33":
            workorder.type = "tyjwh";
            workorder.resourceType = "超越申泰";
            workorder.resourceName = "DT3010-L33";
            break;
        case "tyjwh-cystDT-3020-L":
            workorder.type = "tyjwh";
            workorder.resourceType = "超越申泰";
            workorder.resourceName = "DT3020-L";
            break;
        case "dyjwh-gdtsmy-OEM200M":
            workorder.type = "dyjwh";
            workorder.resourceType = "光电通";
            workorder.resourceName = "扫描仪 OEM200M";
            break;
        case "dyjwh-gdtbsytj-OEM3000DN":
            workorder.type = "dyjwh";
            workorder.resourceType = "光电通";
            workorder.resourceName = "白色一体机 OEM3000DN";
            break;
        case "dyjwh-gdthsytj-OEP400DN":
            workorder.type = "dyjwh";
            workorder.resourceType = "光电通";
            workorder.resourceName = "黑色一体机 OEP400DN";
            break;
        case "dyjwh-gdt-OEP102D":
            workorder.type = "dyjwh";
            workorder.resourceType = "光电通";
            workorder.resourceName = "OEP102D";
            break;
        case "dyjwh-gdt-MP4025DN":
            workorder.type = "dyjwh";
            workorder.resourceType = "光电通";
            workorder.resourceName = "MP4025DN";
            break;
        case "dyjwh-gdt-OEP3015DN":
            workorder.type = "dyjwh";
            workorder.resourceType = "光电通";
            workorder.resourceName = "OEP3015DN";
            break;
        case "dyjwh-bt-M7105DN":
            workorder.type = "dyjwh";
            workorder.resourceType = "奔图";
            workorder.resourceName = "M7105DN";
            break;
        default:
            workorder.resourceType = "";
            workorder.resourceName = "";
            break;
    }
};

export const convertWorkorderType = type => {
    switch (type) {
        case "zyjwh":
            return "专用机维护";
        case "rjwh":
            return "软件维护";
        case "gcoawh":
            return "国产OA维护";
        case "xtrjwh":
            return "系统软件维护";
        case "tyjwh":
            return "通用机维护";
        case "dyjwh":
            return "打印机维护";
        case "yjaz":
            return "硬件安装";
        case "xtxj":
            return "系统巡检";
        case "ldcl":
            return "漏洞处理";
        case "dyjhf":
            return "打印机换粉";
        case "yjhsxh":
            return "硬件回收销毁";
        case "zzwjxh":
            return "纸质文件销毁";
        case "oawh":
            return "OA维护";
        case "wzwh":
            return "网站维护";
        case "fwqwx":
            return "服务器维修";
        case "wlsbwx":
            return "网络设备维修";
        case "mgzycl":
            return "敏感字眼处理";
        default :
            return "未知工单类型";
    }
};
