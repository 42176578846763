import React from "react";
import moment from "moment";
import {Button, Col, DatePicker, Drawer, Form, Input, Row, Select, Space, TreeSelect,message} from "antd";
import {ProDescriptions} from "@ant-design/pro-components";

import ContractApi from "api/contract";
import CustomerApi from "api/customer/customer";
import WorkorderApi from "api/workorder";
import CustomerDepartmentApi from "api/customer/department";
import {setFieldsError} from "plugin/formily";
import {OpsWorkorderType} from "../../assets/WorkorderType";

import CreateCustomerModal from "page/home/customer/customer/modal/CreateCustomerModal";
import CreateCustomerDepartmentModal from "page/home/customer/department/modal/CreateCustomerDepartmentModal";
import DispatchUnassignedWorkorderModal from "../../unassigned/modal/DispatchUnassignedWorkorderModal";
import dayjs from "dayjs";
import {getUser} from "../../../../../component/SecurityInterceptor";


/**
 * 报修工单转为运维工单 Drawer
 * @description 创建运维工单
 *
 * @author Mae Carder
 * @version 1.0.0
 * @since 2021-11-5
 */


export default class PrepareToOpsWorkorderDrawer extends React.Component {

    state = {
        drawerWidth: 280,
        childrenDrawer: false,
        loading: false,
        dispatch: false,
        showDispatchModal: false,
        progressDispatchWorkorder: false,
        loadingWorkorderHandlersOptions: false,
        departmentId: "",
        description: "",
        workorderId: "",
        WorkorderHandlerId: "",
        customerData: {},
        prepareWorkorder: {},
        customerDepartmentsOptions: [],
        customersOptions: [],
        contractOption: []
    };

    /**
     * 表单引用
     */
    formRef = React.createRef();
    /**
     * 创建客户单位弹窗引用
     */
    createCustomerDepartmentModalRef = React.createRef();
    /**
     * 创建客户弹窗引用
     */
    createCustomerModalRef = React.createRef();

    /**
     * 指派弹窗的引用
     */
    dispatchWorkorderModalRef = React.createRef();

    dispatchWorkorder = React.createRef();

    show = entity => this.setState({
        childrenDrawer: true,
        prepareWorkorder: entity,
        description: entity.description,
        drawerWidth: window.innerWidth < 576 ? window.innerWidth : window.innerWidth * 0.55
    }, () => {
        this.formRef.current.setFieldsValue({
            description: this.state.description
        });
        this.requestCustomerDepartmentsOptions();
        this.getAllContract();
    });

    /**
     * 获取所有合同信息
     */
    getAllContract = () => {
        ContractApi.getAllContractInfo("163063635416059904").then(response => {
            let contractArr = [];
            response.data.forEach(item => {
                let obj = {label: "", value: ""};
                obj.label = `${item.name}(${item.comment})`;
                obj.value = item.id;
                contractArr.push(obj);
            });
            this.setState({
                contractOption: contractArr
            });
        });
    };

    /**
     * 选中合同时触发
     */
    contractOnChange = val => {
        console.log(this.dispatchWorkorder);
        ContractApi.contractPostData("163063635416059904").then(response => {
            response.data.forEach(item => {
                if (item.id === val) {
                    item.infos.forEach(itemY => {
                        if (itemY.name === "合同到期时间") {
                            this.setState({
                                contractPostTime: itemY.value
                            });
                        } else if (itemY.name === "合同开始时间") {
                            this.setState({
                                contractStartTime: itemY.value
                            });
                        }
                    });
                }
            });
        }).catch(error => {
            console.warn("Get customers failed", error);
        });
    };

    /**
     * 客户单位选中回调
     * @description 选中客户单位后触发
     */
    onCustomerDepartmentChange = departmentId => {
        // 取消当前选中客户
        this.formRef.current?.setFieldsValue({clientCustomer: ""});
        // 重新根据当前用户单位获取客户选项
        this.requestCustomersOptions(departmentId);
        this.setState({departmentId});
    };

    /**
     * 请求客户选项
     */
    requestCustomersOptions = departmentId => CustomerApi.list({departmentId}).then(response => {
        const customerOptions = [];
        if (!!response.data && response.data.length > 0) {
            response.data.forEach(customer => {
                customerOptions.push(this.handleCustomerOption(customer));
            });
        }
        this.setState({customersOptions: customerOptions});
    }).catch(error => {
        console.warn("Get customers failed", error);
    });

    /**
     * 将客户转化为选项
     * @param customer 客户
     */
    handleCustomerOption = customer => {
        return {
            label: `${customer.name}(${customer.cellphone})`,
            value: customer.id
        };
    };

    /**
     * 显示新建客户单位弹窗
     */
    showCreateDepartmentDialog = () => this.createCustomerDepartmentModalRef.current.show();

    /**
     * 显示新建客户弹窗
     */
    showCustomerDialog = () => this.createCustomerModalRef.current.show(this.state.departmentId);

    /**
     * 获取新建后的单位
     */
    onCreateCustomerDepartmentSuccess = departmentId => {
        this.requestCustomerDepartmentsOptions();
        this.requestCustomersOptions(departmentId);
        this.formRef.current?.setFieldsValue({
            clientDepartment: departmentId,
            clientCustomer: null
        });
        this.setState({departmentId});
    };

    /**
     * 请求客户单位选项
     */
    requestCustomerDepartmentsOptions = () => {
        return CustomerDepartmentApi.tree(getUser().departments[0].id).then(response => {
            const treeData = [];
            response.data.forEach(department => {
                treeData.push(this.handleDepartmentTreeNode(department));
            });
            this.setState({customerDepartmentsOptions: treeData});
        }).catch(error => {
            console.warn("Get department tree data failed", error);
        });
    };

    /**
     * 处理客户单位的下级单位
     * @description 将服务端返回的客户单位对象转换为选项节点
     */
    handleDepartmentTreeNode = department => {
        const node = {
            title: department.name,
            key: department.id,
            value: department.id,
            children: []
        };
        if (!!department.children && department.children.length > 0) {
            department.children.forEach(subDepartment => {
                node.children.push(this.handleDepartmentTreeNode(subDepartment));
            });
        }
        return node;
    };

    /**
     * 获取新建后的客户
     */
    onCreateCustomerSuccess = (departmentId, customerId) => {
        const customerDepartmentId = this.formRef.current?.getFieldValue("clientDepartment");
        if (!!customerDepartmentId) {
            this.requestCustomersOptions(customerDepartmentId);
        }
        if (departmentId === customerDepartmentId) {
            this.formRef.current?.setFieldsValue({
                clientCustomer: customerId
            });
        }
    };

    /**
     * 发起工单
     */
    handle = data => {
        let nowTime = dayjs().locale("zh-cn").format("YYYYMMDD");
        let contractTime = dayjs(this.state.contractPostTime).format("YYYYMMDD");
        let contractStartTime = dayjs(this.state.contractStartTime).format("YYYYMMDD");
        if ((contractTime - nowTime) < 0) {
            this.formRef.current.setFields([{
                name: "resourceId",
                errors: ["合同已到期"]
            }]);
            return;
        }
        if ((nowTime - contractStartTime) < 0) {
            this.formRef.current.setFields([{
                name: "resourceId",
                errors: ["合同未开始"]
            }]);
            return;
        }
        let param = {
            id: this.state.prepareWorkorder.id,
            clientDepartmentId: data.clientDepartment,
            clientId: data.clientCustomer,
            deadLine: data.deadLine.format("YYYY-MM-DD HH:mm"),
            description: data.description,
            priority: data.priority,
            title: data.title,
            type: data.type,
            logicType: "ops",
            origin: this.state.prepareWorkorder.origin,
            files: [],
            resourceId: data.resourceId
        };
        this.toggleLoading();

        WorkorderApi.create(param).then(response => {
            this.formRef.current?.resetFields();
            if (this.state.dispatch) {
                // 打开指派弹窗
                this.dispatchWorkorder.current.show(response.data);
            } else {
                this.onSuccess();
            }
        }).catch(error => {
            if (error.status === 40000) {
                setFieldsError(error, this.formRef);
            } else {
                message.error({key: "failed", content: error.data});
            }
            this.toggleLoading();
            console.warn("Create workorder failed", error);
        });
    };

    toggleLoading = () => this.setState({loading: !this.state.loading});

    /**·
     * 关闭 Drawer
     */
    close = () => {
        this.setState({
            childrenDrawer: false,
            loading: false
        }, () => {
            if (this.state.childrenDrawer) {
                this.setState({
                    drawerWidth: window.innerWidth < 576 ? window.innerWidth : window.innerWidth * 0.4
                });
                this.requestCustomerDepartmentsOptions();
                this.getAllContract();
            } else {
                this.props.onSuccess();
                this.setState({
                    dispatch: false,
                    departmentId: null,
                    customersOptions: null
                });
            }
        });
    };

    /**
     *弹出指派工单弹窗
     */
    handleAndDispatch = () => this.setState({dispatch: true}, () => {
        this.formRef.current?.submit();
    });

    onSuccess = () => {
        this.close();
        if (!!this.props.onSuccess) {
            this.props.onSuccess();
        }
    };

    render = () => <Drawer title="转发起运维工单" closable={!this.state.loading}
                           width={this.state.drawerWidth} open={this.state.childrenDrawer}
                           onClose={this.close} destroyOnClose>

        <ProDescriptions bordered size="small" layout="horizontal"
                         column={window.innerWidth < 800 ? 1 : 4}
                         dataSource={this.state.prepareWorkorder}>

            <ProDescriptions.Item label="客户姓名" span={4} dataIndex="clientName"/>
            <ProDescriptions.Item label="客户手机号码" span={4} dataIndex="clientCellphone"/>
            <ProDescriptions.Item label="客户地址" span={4} dataIndex="clientAddress"/>
        </ProDescriptions>

        <Form layout="vertical" ref={this.formRef} style={{marginTop: 24}} onFinish={this.handle}>
            <Row gutter={8}>
                <Col span={24}>
                    <Form.Item name="type" label="服务类型" required initialValue="ldcl"
                               rules={[{required: true, message: "请选择服务类型"}]}>
                        <TreeSelect treeData={OpsWorkorderType} showSearch treeNodeFilterProp="title"/>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={8}>
                <Col span={24}>
                    <Form.Item name="priority" label="优先级" required initialValue="5"
                               rules={[{required: true, message: "请选择优先级"}]}>
                        <Select options={require("../../assets/WorkorderPriority").priorityOptions()}/>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={8}>
                <Col span={24}>
                    <Form.Item name="deadLine" label="计划时间" required
                               rules={[{required: true, message: "请选择计划时间"}]}>
                        <DatePicker showTime format="YYYY-MM-DD HH:mm" inputReadOnly style={{width: "100%"}}/>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={8}>
                <Col span={24}>
                    <Form.Item name="resourceId" label="合同" required
                               rules={[{required: true, message: "请选择合同"}]}>
                        <Select options={this.state.contractOption} onChange={this.contractOnChange}
                                showSearch optionFilterProp="label"/>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={8} align="middle">
                <Col span={20}>
                    <Form.Item name="clientDepartment" label="客户单位" required
                               rules={[{required: true, message: "请选择客户单位"}]}>
                        <TreeSelect treeData={this.state.customerDepartmentsOptions}
                                    showSearch treeNodeFilterProp="title"
                                    onChange={this.onCustomerDepartmentChange}/>
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <Button block style={{marginTop: 6}} type="link"
                            onClick={this.showCreateDepartmentDialog}>新建单位</Button>
                </Col>
            </Row>
            <Row gutter={8} align="middle">
                <Col span={20}>
                    <Form.Item name="clientCustomer" label="客户" required
                               rules={[{required: true, message: "请选择客户"}]}>
                        <Select options={this.state.customersOptions}
                                showSearch optionFilterProp="label"/>
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <Button block style={{marginTop: 6}} type="link" onClick={this.showCustomerDialog}>新建客户</Button>
                </Col>
            </Row>
            <Row gutter={8}>
                <Col span={24}>
                    <Form.Item name="description" label="工单描述"
                               rules={[{pattern: /\S/, message: "内容不能为空"}]}>
                        <Input.TextArea/>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={8} justify="end">
                <Col span={24}>
                    <Space>
                        <Button disabled={this.state.loading} onClick={this.close}>取消</Button>
                        <Button loading={this.state.loading} type="primary" htmlType="submit">发起工单</Button>
                        <Button type="primary" loading={this.state.loading}
                                onClick={this.handleAndDispatch}>发起工单并指派</Button>
                    </Space>
                </Col>
            </Row>
        </Form>

        <CreateCustomerDepartmentModal onSuccess={this.onCreateCustomerDepartmentSuccess}
                                        ref={this.createCustomerDepartmentModalRef}/>
        <CreateCustomerModal onSuccess={this.onCreateCustomerSuccess} ref={this.createCustomerModalRef}/>

        <DispatchUnassignedWorkorderModal ref={this.dispatchWorkorder} onSuccess={this.onSuccess}/>
    </Drawer>;
}
