const ContractApi = {
    /**
     * 更新合同
     */
    update: (data) => global.axios.patch("/resource/resource", data),
    /**
     * 删除合同
     */
    delete: id => global.axios.delete(`/resource/resource/${id}`),

    /**
     * 获取合同信息
     */
    getContract: id => global.axios.get(`/resource/resource/${id}`),

    /**
     * 获取所有合同信息
     */
    getAllContractInfo: (data) => global.axios.get("/resource/resources", {params: {categoryId: data}}),

    /**
     * 获取合同过期时间
     */
    contractPostData: (data) => global.axios.get("/resource/resources-info", {params: {categoryId: data}})
};

export default ContractApi;
