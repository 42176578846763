import React from "react";
import {Button, Col, Row, Space, Typography} from "antd";

import CustomerDepartmentApi from "api/customer/department";
import DraggableModal from "component/DraggableModal";

/**
 * 删除客户单位弹窗 Modal
 *
 * @description 删除客户单位
 *
 * @author huang Carder
 * @since 2021-06-23
 * @version 1.0.0
 */
export default class DeleteCustomerDepartmentModal extends React.Component {

    state = {
        loading: false,
        show: false,
        departmentId: ""
    };

    draggableRef = React.createRef();

    show = id => {
        this.draggableRef.current.show();
        this.setState({departmentId: id});
    };

    close = () => {
        this.setState({loading: false});
        this.draggableRef.current.close();
    };

    /**
     * 控制 loading 加载效果 显示
     */
    toggleLoading = () => this.setState({loading: !this.state.loading});

    /**
     * 删除单位
     */
    handle = () => {
        this.toggleLoading();
        CustomerDepartmentApi.delete(this.state.departmentId).then(() => {
            this.close();
            this.props.onSuccess();
        }).catch(error => {
            this.toggleLoading();
            console.warn("delete department failed", error);
        });
    };

    render() {
        return <DraggableModal onCancel={this.close} title="是否删除客户单位" ref={this.draggableRef}>
            <Typography>数据删除后无法恢复，请谨慎操作！</Typography>
            <Row gutter={8} justify="end">
                <Col>
                    <Space>
                        <Button disabled={this.state.loading} onClick={this.close}>取消</Button>
                        <Button loading={this.state.loading} type="primary" onClick={this.handle}>删除</Button>
                    </Space>
                </Col>
            </Row>
        </DraggableModal>;
    }
}
