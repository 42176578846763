import React, {Component} from 'react';
import {PageContainer, ProTable} from "@ant-design/pro-components";
import {Button, DatePicker, message, Tooltip} from "antd";
import {ApiOutlined, DeleteOutlined, EditOutlined, EyeOutlined} from "@ant-design/icons";
import RemoteApi from "../../../api/remote/RemoteApi";

import DeleteRemoteDevice from "./modal/DeleteRemoteDevice";
import PreviewRemoteDevice from "./modal/PreviewRemoteDevice";
import RemoteLoading from "./modal/RemoteLoading";
import {linkStatus} from "../../../type/Types";
import CreateRemoteDrawer from "./drawer/CreateRemoteDrawer";
import UpdateRemoteDrawer from "./drawer/UpdateRemoteDrawer";
import UseRemoteModal from "./modal/UseRemoteModal";
import {withNavigation} from "../../../utils/RouteLink";
import PreviewRemoteDrawer from "./drawer/PreviewRemoteDrawer";
import CustomerDepartmentApi from "../../../api/customer/department";
import {getUser} from "../../../component/SecurityInterceptor";

/**
 * @author       : AngelBeats
 * @date         : 2023-06-01 15:38:15
 * @description  : 远程列表
 */
class RemotePage extends Component {

    actionRef = null

    createRef = React.createRef();
    previewRef = React.createRef();
    editRef = React.createRef();
    deleteRef = React.createRef();
    connectRef = React.createRef();
    remoteLoadingRef = React.createRef();
    useremoteRef = React.createRef();
    columns = [
        {
            title: "设备ID",
            dataIndex: "id",
            hideInSearch: true,
            editable: false
        },
        {
            title: "设备识别码",
            dataIndex: "sn",
            editable: false
        },
        {
            title: "设备名称",
            dataIndex: "name",
            editable: false
        },

        {
            title: "管理员",
            dataIndex: ["customerObj", "name"],
            hideInSearch: true,
            hideInTable: true,
            editable: false
        },
        {
            title: "设备单位",
            dataIndex: ["customerDepartmentObj", "name"],
            formItemProps: {name: "customerDepartmentIds"},
            editable: false,
            valueType: "treeSelect",
            fieldProps: {
                showSearch: true,
                debounce: 500,
                fieldNames: {label: "name", value: "id"},
                treeNodeFilterProp: "name"
            },
            request: () => CustomerDepartmentApi.tree(getUser().departments[0].id).then(res => res.data).catch(() => []),
        },
        {
            title: "状态",
            dataIndex: "type",
            // hideInSearch: true,
            valueEnum: linkStatus
        },
        {
            title: "授权时间",
            dataIndex: "time",
            hideInTable: true,
            renderFormItem: (_, {fieldProps}) => {
                return (
                    <DatePicker.RangePicker {...fieldProps} inputReadOnly/>
                );
            }
        },
        {
            title: "授权时间",
            dataIndex: "time",
            hideInSearch: true,
            render: (node, entity) => `${entity.empowerStartTime} ~ ${entity.empowerStartTime}`
        },
        {
            title: "详细地址",
            dataIndex: ["customerDepartmentObj", "address"],
            hideInSearch: true,
            editable: false
        },
        {
            title: "操作",
            valueType: "option",
            render: (node, entity, index, action) => this.renderColumnOperations(node, entity, index, action)
        }
    ]

    /**
     * 表格操作项
     * @param node
     * @param entity
     * @param index
     * @param action
     */
    renderColumnOperations = (node, entity, index, action) => [
        <Tooltip title="查看" key="preview">
            <Button icon size="small" onClick={() => this.previewRef.current.show(entity)}>
                <EyeOutlined/>
            </Button></Tooltip>,
        <Tooltip title="编辑" key="edit">
            <Button icon size="small" onClick={() => this.editRef.current.show(entity.id)}>
                <EditOutlined/>
            </Button></Tooltip>,
        <Tooltip title="连接" key="connect">
            <Button icon size="small" onClick={() => this.connectDevice(entity)}>
                <ApiOutlined/>
            </Button>
        </Tooltip>,
        <Tooltip title="删除" key="delete">
            <Button danger icon size="small" onClick={() => this.deleteRef.current.show(entity.id)}>
                <DeleteOutlined/>
            </Button>
        </Tooltip>,
    ];

    /**
     * 开启连接
     * @param item
     */
    connectDevice = (item) => {
        this.remoteLoadingRef.current.show();

        RemoteApi.connectDevice(item.id).then(res => {
            const parse = JSON.parse(res.data);
            console.log("connect device success", parse);
           let { addr, sid } = parse;
            //this.useremoteRef.current.Start(addr,sid);
            this.useremoteRef.current.show(item,addr,sid);
            this.remoteLoadingRef.current.close();
        }).catch(err => {
            message.error("连接失败！");
            this.remoteLoadingRef.current.close();
            console.error("connect device failed", err)
        })
    }

    /**
     * 请求列表数据
     * @param params
     */
    requestPage = params => RemoteApi.page(params).then(response => {
        return {data: response.data.records, success: true};
    }).catch(error => {
        console.warn("Get remote device list failed", error);
        return {data: [], success: true, total: 0};
    });

    useremoteSuccess = () =>{
        this.props.navigate('/home/remote/video-viewer')
    }
    /**
     * 渲染顶部按钮
     * @returns {JSX.Element[]}
     */
    renderToolbar = () => [
        <Button key="create" type="primary" onClick={() => this.createRef.current.show()}>新建设备</Button>
    ]

    /**
     * 表格页刷新
     * @returns {*}
     */
    reload = () => this.actionRef?.reload();

    render() {
        return (<PageContainer header={{title: "智能运维", breadcrumb: {}}} extra={this.renderToolbar()}>
            <ProTable
                headerTitle="控制设备信息"
                rowKey="id"
                defaultSize="small"
                options={{reload: true, setting: true, density: true}}
                actionRef={actionRef => this.actionRef = actionRef}
                columns={this.columns}
                request={this.requestPage}/>
            <CreateRemoteDrawer ref={this.createRef} onSuccess={this.reload}/>
            <UpdateRemoteDrawer ref={this.editRef} onSuccess={this.reload}/>
            <DeleteRemoteDevice ref={this.deleteRef} onSuccess={this.reload}/>
            <PreviewRemoteDrawer ref={this.previewRef} onSuccess={this.reload}/>
            <RemoteLoading ref={this.remoteLoadingRef}/>
            <UseRemoteModal ref={this.useremoteRef} onSuccess={this.useremoteSuccess}/>
        </PageContainer>)
    }
}
export default withNavigation(RemotePage)