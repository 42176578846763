import React, {Component} from "react";
import {Button, Col, Form, Row, Select, Space} from "antd";

import DraggableModal from "component/DraggableModal";

import StorehouseApi from "api/spares/storehouse";
import {getUser} from "component/SecurityInterceptor";

import SolicitSparesDrawer from "../../../../spares/storehouse/commponent/drawer/SolicitSparesDrawer";

class ChooseStorehouse extends Component {

    state = {
        show: false,
        storehouse: [],
        workorderId: ""
    };

    formRef = React.createRef();
    draggableRef = React.createRef();
    solicitSparesRef = React.createRef();

    show = (id) => {
        this.draggableRef.current.show();
        this.setState({workorderId: id});
        this.requestDepartmentAllStorehouse();
    };

    close = () => {
        this.setState({storehouse: []});
        this.draggableRef.current.close();
    };

    requestDepartmentAllStorehouse = () => {
        let params = {
            //departmentId: getUser().departments[0].id,
            user: getUser().id,
        };
        StorehouseApi.findAll(params).then(res => {
            console.log(res);
            if (!!res.data) {
                let storehouse = [];
                res.data.forEach(item => {
                    console.log({label: item.name, value: item.id});
                    storehouse.push({label: item.name, value: item.id});
                    //this.setState({storehouse: [...this.state.storehouse, {label: item.name, value: item.id}]});
                });
                this.setState({storehouse});
            }
        }).catch(err => {
            console.error("Request department all storehouse failed", err);
        });
    };

    handle = data => {
        console.log(data);
        this.solicitSparesRef.current.show(data.storehouse);
    };

    reload = () => {
        this.props.onSuccess();
        this.close();
    };

    render() {
        return (<DraggableModal onCancel={this.close} title="选择仓库" ref={this.draggableRef}>
                <Form ref={this.formRef} onFinish={this.handle} layout={"vertical"}>
                    <Form.Item name="storehouse" label="请选择仓库" rules={[{required: true, message: "请选择仓库"}]}>
                        <Select options={this.state.storehouse}/>
                    </Form.Item>
                    <Row gutter={8} justify="end">
                        <Col>
                            <Space>
                                <Button disabled={this.state.loading} onClick={this.close}>取消</Button>
                                <Button loading={this.state.loading} type="primary" htmlType="submit">前往仓库</Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
                <SolicitSparesDrawer onSuccess={this.reload} ref={this.solicitSparesRef}
                                     workorderId={this.state.workorderId}/>
            </DraggableModal>
        );
    }
}

export default ChooseStorehouse;
