import React from "react";
import {Button, Col, Row, Space, Typography} from "antd";

import AccountApi from "api/user/account";
import DraggableModal from "component/DraggableModal";

/**
 * 删除账号对话框
 * 根据id 删除对应的账号
 */
export default class DeleteAccountModal extends React.Component {

    state = {
        loading: false,
        show: false,
        items: []
    };

    draggableRef = React.createRef();

    /**
     * 控制删除单位对话框显示
     */

    show = id => {
        this.draggableRef.current.show();
        this.setState({
            items: id
        });
    };

    close = () => {
        this.setState({
            loading: false
        });
        this.draggableRef.current.close();
    };

    /**
     * 控制 loading 加载显示
     */
    toggleLoading = () => {
        this.setState({
            loading: !this.state.loading
        });
    };

    /**
     * 删除账号
     */
    handle = () => {
        if (this.state.items === null || this.state.items === undefined || this.state.items.length < 1) {
            return;
        }
        this.toggleLoading();
        const request = this.state.items.length === 1 ?
            AccountApi.delete({accountId: this.state.items[0]}) :
            AccountApi.deletes({accountIds: this.state.items});
        request.then(response => {
            this.close();
            this.props.onSuccess();
        }).catch(error => {
            this.toggleLoading();
            console.warn("delete account failed", error);
        });
    };

    render() {
        return <DraggableModal onCancel={this.close} title="是否删除账户" ref={this.draggableRef}>
            <Typography>数据删除后无法恢复，请谨慎操作！</Typography>
            <Row gutter={8} justify="end">
                <Col>
                    <Space>
                        <Button disabled={this.state.loading} onClick={this.close}>取消</Button>
                        <Button loading={this.state.loading} type="primary" onClick={this.handle}>删除</Button>
                    </Space>
                </Col>
            </Row>
        </DraggableModal>;
    }

}
