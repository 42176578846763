const MonitoringApi = {
    /**
     * 创建区域设备监控
     * @param data
     * @returns {Promise<axios.AxiosResponse<any>>}
     */
    create: (data) => global.axios.post("/resource/monitoring", data),
    /**
     * 删除区域设备监控
     * @param id
     * @returns {Promise<axios.AxiosResponse<any>>}
     }*/
    delete: (id) => global.axios.delete(`/resource/monitoring/${id}`),
    /**
     * 查询区域设备监控
     * @param id
     * @returns {Promise<axios.AxiosResponse<any>>}
     */
    find: id => global.axios.get(`/resource/monitoring/${id}`),
    /**
     * 分页查询区域设备监控
     * @param params
     * @returns {Promise<axios.AxiosResponse<any>>}
     */
    page: (params) => global.axios.get("/resource/monitoring/page", {params}),
    /**
     * 更新区域设备监控
     * @param data
     * @returns {Promise<axios.AxiosResponse<any>>}
     */
    update: (data) => global.axios.patch(`/resource/monitoring`, data)
}

export default MonitoringApi;