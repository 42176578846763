import React, {Component} from "react";
import {Button, Col, Drawer, message, Row, Space} from "antd";
import {EditableProTable, ProForm, ProFormTextArea} from "@ant-design/pro-components";

import FileUploadRender from "component/FileUploadRender";

import EquipmentMakerApi from "api/equipment/maker";
import StorehouseTypeApi from "api/spares/type";
import StorehouseInventoryApi from "api/spares/storehouse/inventory";
import ChooseSparesModal from "../modal/ChooseSparesModal";

class DestroySparesDrawer extends Component {
    state = {
        show: false,
        loading: false,
        drawerWidth: 600,
        repositoryId: "",
        typeSelect: [],
        manufacturerSelect: [],
        stocks: []
    };

    formRef = React.createRef();
    chooseSparesRef = React.createRef();
    filesRef = React.createRef();

    destroyColumns = [
        {
            title: "id",
            dataIndex: "id",
            hideInSearch: true,
            hideInTable: true
        },
        {
            title: "类型",
            dataIndex: "stocktypeName",
            hideInSearch: true,
            readonly: true
        },
        {
            title: "型号",
            dataIndex: "model",
            hideInSearch: true,
            readonly: true
        },
        {
            title: "制造商",
            dataIndex: "manufacturerName",
            readonly: true
        },
        {
            title: "数量",
            dataIndex: "count",
            hideInSearch: true,
            editable: () => true
        },
        {
            title: "是否SM",
            dataIndex: "classified",
            hideInSearch: true,
            readonly: true,
            valueEnum: {
                "0": {
                    "text": "否"
                },
                "1": {
                    "text": "是"
                }
            }
        },
        {
            title: "操作",
            valueType: "option",
            render: (text, record, _, action) => this.renderColumnOptions(text, record, _, action)
        }
    ];

    renderColumnOptions = (text, record, _, action) => {
        return [
            <span key="editable" onClick={() => {action?.startEditable?.(record.id);}}>编辑</span>,
            <span key="delete" onClick={() => {
                this.setState({stocks: this.state.stocks.filter((item) => item.id !== record.id)});
            }}>删除</span>
        ];
    };

    show = (id) => {
        this.setState({
            show: true,
            repositoryId: id,
            drawerWidth: window.innerWidth < 576 ? window.innerWidth : window.innerWidth * 0.65
        }, () => {
            this.requestStorehouseType();
            this.requestManufacturerInfo();
        });
    };

    close = () => {
        this.setState({
            show: false,
            loading: false,
            stocks: []
        });
    };

    toggleLoading = () => this.setState({loading: !this.state.loading});


    requestManufacturerInfo = () => {
        EquipmentMakerApi.queryAll().then(res => {
            res.data.forEach(item => {
                this.setState({
                    manufacturerSelect: [...this.state.manufacturerSelect, {
                        label: item.name,
                        value: item.id
                    }]
                });
            });
        }).catch(err => {
            console.error("Request manufacturer info failed", err);
        });
    };

    requestStorehouseType = () => {
        StorehouseTypeApi.findAll().then(res => {
            res.data.forEach(item => {
                this.setState({typeSelect: [...this.state.typeSelect, {label: item.name, value: item.id}]});
            });
        }).catch(err => {
            console.error("Request storehouse type failed", err);
        });
    };

    reload = (value) => {
        let stocks = this.state.stocks;
        const isStocksNotEmpty = stocks !== undefined && stocks !== null && stocks.length > 0;
        stocks = isStocksNotEmpty ? stocks : [];
        this.setState({stocks: [...stocks, value]});
    };

    /**
     * 编辑领用备件触发
     * @param _
     * @param data
     */
    onEditSave = (_, data) => {
        if (Number(data.count) > Number(data.total)) {
            message.error("数量超过最大库存");
        } else if (data.count <= 0) {
            message.error("数量不能小于等于1");
        } else if (!Number(data.count)) {
            message.error("请输入纯数字！");
        } else {
            this.state.stocks.forEach(item => {
                if (item.id === data.id) {
                    item.count = Number(data.count).toFixed(0);
                }
            });
        }
    };

    handle = data => {
        if (this.state.stocks.length === 0) {
            message.error({key: "delete", content: "请选择库存"});
            return;
        }
        if (data.file !== null && data.file !== undefined && !!data.file) {
            data.file = undefined;
            data.files = this.filesRef.current.getFilesValue();
        }
        data.repository = this.state.repositoryId;
        data.stocks = this.state.stocks;
        this.toggleLoading();
        StorehouseInventoryApi.destroy(data).then(res => {
            this.toggleLoading();
            this.props.onSuccess();
            this.close();
        }).catch(err => {
            this.toggleLoading();
            message.error(err.data);
            console.error("Destroy spares failed", err);
        });
    };

    render() {
        return <Drawer title="消耗备件"
                       destroyOnClose
                       width={this.state.drawerWidth}
                       open={this.state.show}
                       closable={!this.state.loading}
                       onClose={this.close}>
            <ProForm formRef={this.formRef} onFinish={this.handle} layout={"vertical"} submitter={{
                resetButtonProps: {style: {display: "none"}},
                submitButtonProps: {style: {display: "none"}}
            }}>
                <ProFormTextArea
                    name="comment"
                    label="消耗说明"/>
                <FileUploadRender label="上传附件" uploadPath="/repository/destroy/file" ref={this.filesRef}/>
                <EditableProTable headerTitle="领用库存"
                                  rowKey="id"
                                  defaultSize="small"
                                  search={false}
                                  options={false}
                                  pagination={false}
                                  editableFormRef={this.tableRef}
                                  recordCreatorProps={false}
                                  columns={this.destroyColumns}
                                  value={this.state.stocks}
                                  editable={{
                                      onSave: (_, data) => this.onEditSave(_, data),
                                      actionRender: (row, config, dom) => [dom.save, dom.cancel]
                                  }}/>

                <Button block style={{marginBottom: "16px"}}
                        onClick={() => this.chooseSparesRef.current.show(this.state.repositoryId, this.state.stocks)}>添加</Button>

                <Row gutter={8} justify="end">
                    <Col>
                        <Space>
                            <Button disabled={this.state.loading} onClick={this.close}>取消</Button>
                            <Button loading={this.state.loading} type="primary" htmlType="submit">消耗</Button>
                        </Space>
                    </Col>
                </Row>
            </ProForm>
            <ChooseSparesModal onSuccess={this.reload} ref={this.chooseSparesRef} title="选择消耗备件"/>
        </Drawer>;
    }
}

export default DestroySparesDrawer;
