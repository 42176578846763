const EquipmentListApi = {
    /**
     * 分页查询
     */
    page: (data) => global.axios.get("/resource/devices/page", {params: data}),
    /**
     * 新建设备
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    create: (data) => global.axios.post("/resource/device", data),
    /**
     * 删除设备
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    delete: (id) => global.axios.delete(`/resource/device/${id}`),
    /**
     * 查询指定设备信息
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    info: (id) => global.axios.get(`/resource/device/${id}`),
    /**
     * 查询所有设备类型
     * @returns {Promise<AxiosResponse<any>>}
     */
    queryType: params => global.axios.get("/resource/device/types", {params}),
    /**
     * 编辑设备
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    edit: (data) => global.axios.patch(`/resource/device`, data),
    /**
     * 获取设备日志
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    log: (id) => global.axios.get("/resource/device/logs", {params: {deviceId: id}}),
    /**
     * 批量获取二维码
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    migration: (data) => global.axios.patch("/resource/device/location", data),
    /**
     * 根据客户id查询设备
     */
    queryEquipment: customerIds => global.axios.get("/resource/devices", {customerIds}),
    /**
     * 根据设备id查询设备信息
     */
    queryEquipmentInfo: id => global.axios.get(`/resource/device/${id}`)
};

export default EquipmentListApi;
