/**
 * 机构详情
 *
 * @description 机构详情
 *
 * @author Mae Carder
 * @since 2021-10-28
 * @version 1.0.0
 */

import React from "react";
import {Button, DatePicker, Space, Tooltip, Descriptions} from "antd";
import {DeleteOutlined, EditOutlined, LeftOutlined} from "@ant-design/icons";
import {PageContainer, ProTable} from "@ant-design/pro-components";
import SecurityInterceptor, {getUser} from "component/SecurityInterceptor";

import moment from "moment";
import dayjs from "dayjs";
import CreateUserTeamModal from "./modal/CreateUserTeamModal";
import UpdateUserTeamModal from "./modal/UpdateUserTeamModal";
import DeleteUserTeamModal from "./modal/DeleteUserTeamModal";
import DepartmentApi from "../../../../api/user/department";
import AccountApi from "../../../../api/user/account";
import {withNavigation} from "../../../../utils/RouteLink";
import WorkorderApi from "../../../../api/workorder";

class DepartmentsDetail extends React.Component {
    state = {
        columnStateMap: {
            priority: {
                show: false
            },
            appointmentTime: {
                show: false
            },
            deadLine: {
                show: false
            }
        },
        accountS: [],
        departmentId: 0,
        name:"",
        address:"",
        region: "",
        handlerOptions: [],
        loadingOfHandlers:false,

    };

    actionRef;
    deleteRef = React.createRef();
    previewRef = React.createRef();
    createDepartmentTeamRef = React.createRef();
    updateDepartmentTeamRef = React.createRef();
    deleteDepartmentTeamRef = React.createRef();
    columns = [
        {
            title: "ID",
            dataIndex: "id",
            editable: false,
            copyable: true,
            hideInSearch: true
        },
        {
            title: "团队名称",
            dataIndex: "name"
        },
        {
            title: '团队成员',
            dataIndex: 'accountId',
            type: 'select',
            fieldProps: {
                showSearch: true,
                debounce: 500,
                fieldNames: {label: "name", value: "id"},
                treeNodeFilterProp: "name"
            },
            request: () => AccountApi.getDepartmentAccount(JSON.parse(localStorage.getItem("Entity")).id).then(res => res.data).catch(() => []),
            render: (text, record) => this.renderColumnText(record.accountIds)
        },

        {
            title: "操作",
            valueType: "option",
            render: (node, entity, index, action) => this.renderColumnOperations(node, entity, index, action)
        }
    ];

    componentDidMount() {
        let Entity = JSON.parse(localStorage.getItem("Entity"));
        if(Entity.id){
            this.setState({
                departmentId: Entity.id,
                name: Entity.name,
                address: Entity.address,
                region: Entity.region,
            });
        }
        this.requestAllAccount(Entity.id)

    }
    // 查询验证 获取当前团队是否有工单
    requestDepartmentTeam = (id) => {
        let query = '&teamIds=' + id;
        WorkorderApi.teamDispatchCount(query).then(res => {
            return res.status === 20000 && res.data.length > 0;
        });
    }
    // 查询当前单位所有成员
    requestAllAccount = (id) => {
        // 当前机构下所有处理人
        AccountApi.getDepartmentAccount(id).then(res => {
            let accountS = res.data.map(item => {
                return {
                    label: item.name,
                    value: item.id
                }
            })
            this.setState({
                accountS
            })
        });
    };

    //渲染列操作项
    renderColumnText = (ids) => {
        let result = ids.filter(id => this.state.accountS.some(item => item.value === id))
            .map(id => this.state.accountS.find(item => item.value === id).label);
        return <>{result.join(",")}</>
    }

    renderColumnOperations = (node, entity, index, action) => {
        return <SecurityInterceptor role={["admin", "customer-service","user-department-manager"]}>
            <Space>
                <Tooltip title="编辑">
                    <Button icon size="small" onClick={() => this.dialog(entity, this.updateDepartmentTeamRef)}>
                        <EditOutlined />
                    </Button>
                </Tooltip>
                <Tooltip title={this.requestDepartmentTeam(entity.id) ? "该团队下有工单，无法删除" : "删除"}>
                    <Button danger icon size="small" disabled={this.requestDepartmentTeam(entity.id)} onClick={() => this.dialog(entity.id, this.deleteDepartmentTeamRef)}>
                        <DeleteOutlined/>
                    </Button>
                </Tooltip>
            </Space>
        </SecurityInterceptor>;
    };

    dialog = (id, ref) => ref.current.show(id);

    /**
     * 请求表格数据
     * @param params
     */
    requestPage = params => {
        params.converted = 0;
        // if (!!params.timer) {
        //     params.createStartTime = dayjs(params.timer[0]).format("YYYY-MM-DD");
        //     params.createEndTime = dayjs(params.timer[1]).format("YYYY-MM-DD");
        //     params.timer = undefined;
        // }
        params.departmentId = this.state.departmentId;
        return DepartmentApi.getTeamList(params).then(response => {
            return {data: response.data.records, success: true, total: response.data.total};
        }).catch(error => {
            console.error("Find workorder page failed", error);
            return {data: [], success: true, total: 0};
        });

    };

    renderToolbar = () => {

    };

    reload = () => this.actionRef.reload();
    successTeam = (data) => {
        console.log(data)
        this.reload()
    };

    onColumnStateMapChange = columnStateMap => this.setState({columnStateMap});

    render() {
        return <PageContainer
            header={{title: "当前机构详情", breadcrumb: {}}}
            extra={this.renderToolbar()}
            content={
                <Descriptions column={2} style={{ marginBlockEnd: -16 }}>
                    <Descriptions.Item label="单位名称">{this.state.name}</Descriptions.Item>
                    <Descriptions.Item label="单位区域">{this.state.region}</Descriptions.Item>
                    <Descriptions.Item label="单位详细地址">{this.state.address}</Descriptions.Item>
                </Descriptions>
            }
            onBack={() => this.props.navigate(-1)}
            tabList={[
                {
                    tab: '团队信息',
                    key: '0',
                    disabled: true,
                },
            ]}
        >
            <ProTable
                options={{reload: true, setting: true, density: true}}
                defaultSize="small"
                rowKey="id"
                onColumnsStateChange={this.onColumnStateMapChange}
                actionRef={actionRef => this.actionRef = actionRef}
                columns={this.columns}
                toolBarRender={() => [
                    <Button type="primary" onClick={()=>{
                        this.dialog(this.state.departmentId, this.createDepartmentTeamRef)
                    }}>
                        新增
                    </Button>,
                ]}

                pagination={{
                    pageSize: 5,
                    onChange: (page) => console.log(page),
                }}
                request={this.requestPage}/>

            <CreateUserTeamModal key="create-team" onSuccess={this.successTeam} ref={this.createDepartmentTeamRef} />
            <UpdateUserTeamModal key="update-team" onSuccess={this.successTeam} ref={this.updateDepartmentTeamRef} />
            <DeleteUserTeamModal key="delete-team" onSuccess={this.successTeam} ref={this.deleteDepartmentTeamRef} />
        </PageContainer>;
    }
}

export default withNavigation(DepartmentsDetail);
