import React from "react";
import {ProForm, ProFormDateRangePicker, ProFormSelect} from "@ant-design/pro-components";
import {Button, Col, Row, Skeleton, Space} from "antd";
import axios from "axios";

import DraggableModal from "component/DraggableModal";
import StorehouseTypeApi from "api/spares/type";
import dayjs from "dayjs";

/**
 * 导出Excel文件
 * @description 导出备件记录Excel文件
 */
export default class ExportSparesRecordExcelModal extends React.Component {

    state = {
        show: false,
        loading: false,
        skeletonLoading: true,
        storehouseId: "",
        storehouseTypeData: []
    };

    formRef = React.createRef();
    draggableRef = React.createRef();


    show = (id) => {
        this.setState({storehouseId: id});
        this.draggableRef.current.show();
        this.requestAllStorehouseType();
    };

    close = () => {
        this.setState({loading: false});
        this.draggableRef.current.close();
    };

    toggleLoading = () => this.setState({loading: !this.state.loading});

    requestAllStorehouseType = () => {
        StorehouseTypeApi.findAll().then(res => {
            this.setState({
                storehouseTypeData: res.data.map(item => ({label: item.name, value: item.id})),
                skeletonLoading: false
            })
        }).catch(err => {
            this.setState({skeletonLoading: false});
            console.error("Request all storehouse type failed", err);
        });
    };

    /**
     * 导出
     * @param data
     */
    handle = data => {
        this.toggleLoading();
        data.repository = this.state.storehouseId;
        if (data.string) {
            data.startDate = dayjs(data.string[0]).format("YYYY-MM-DD");
            data.endDate = dayjs(data.string[1]).format("YYYY-MM-DD");
            data.string = undefined;
        }
        axios.get(`${global.config.url}/repository/repository-stock-changes/excel`, {
            responseType: "blob",
            headers: {token: window.localStorage.getItem("Token")},
            params: data
        }).then(response => {
            this.close();
            this.props.onSuccess();
            let url = window.URL.createObjectURL(
                new Blob([response.data], {
                    type:
                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8"
                })
            );
            let link = document.createElement("a");
            link.style.display = "none";
            link.href = url;
            link.setAttribute("download", "result.xlsx");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        }).catch(error => {
            this.toggleLoading();
            console.warn(error.response);
        });
    };

    renderDialog = () => {
        return <DraggableModal onCancel={this.close} title="导出备件记录" ref={this.draggableRef}>
            <Skeleton active loading={this.state.skeletonLoading}>
                <ProForm formRef={this.formRef} onFinish={this.handle} layout={"vertical"} submitter={{
                    resetButtonProps: {style: {display: "none"}},
                    submitButtonProps: {style: {display: "none"}}
                }}>
                    <ProFormDateRangePicker name="string" label="时间范围" style={{width: "100%"}}/>
                    <ProFormSelect
                        name="stocktype"
                        showSearch
                        label="库存类型"
                        options={this.state.storehouseTypeData}/>
                    <Row gutter={8} justify="end">
                        <Col>
                            <Space>
                                <Button onClick={this.close} disabled={this.state.loading}>取消</Button>
                                <Button type="primary" htmlType="submit" loading={this.state.loading}>下载</Button>
                            </Space>
                        </Col>
                    </Row>
                </ProForm>
            </Skeleton>

        </DraggableModal>;
    };

    render() {
        return <>
            {this.renderDialog()}
        </>;
    };
}
