import React from "react";
import {Button, Card, Checkbox, Col, Form, Input, message, Row, Typography} from "antd";
import axios from "axios";
import {KeyOutlined, ProfileOutlined, UserOutlined} from "@ant-design/icons";

import xlaqgfBg from "../../../asset/xlaqgfBg.png";
import logoBg from "../../../asset/logobg.png";
import logoMsg from "../../../asset/logomsg.png";
import style from "./LoginForm.module.css";

import {isAdmin} from "../../../component/SecurityInterceptor";
import config from "../../../config";

/**
 * 登录表单组件
 *
 * @author DunShun Huang, Wayne Carder
 * @since 2021-06-25 14:34
 * @version 1.0.0
 */
export default class LoginForm extends React.Component {

    state = {
        show: false,
        loading: false,
        base64Image: "",
        captchaId: "",
        openOrCloseEyes: true,
        check: localStorage.getItem("defaultLogin")
    };

    formRef = React.createRef();

    componentDidMount() {
        this.getCaptcha();
        if (localStorage.getItem("Token") && this.state.check === "1") {
            this.requestToken();
        }
        this.setState({loading: false});
    }

    toggleLoading = () => {
        this.setState({
            loading: !this.state.loading
        });
    };

    /**
     * 获取验证码
     */
    getCaptcha = () => {
        global.axios.get("/user/captcha/login/base64", {params: {height: 44, width: 180}}).then(res => {
            this.setState({base64Image: res.data.base64Image, captchaId: res.data.captchaId});
        }).catch(error => {
            console.warn("Get captcha failed", error);
        });
    };

    passwordFocus = () => this.setState({openOrCloseEyes: false});

    passwordBlur = () => this.setState({openOrCloseEyes: true});

    requestToken = () => {
        this.toggleLoading();
        axios.patch(`${config.url}/user/refresh-login`, {}, {
            headers: {token: localStorage.getItem("Token")}
        }).then(res => {
            this.toggleLoading();
            const roles = []
            const permissions = []
            res.data.data.roles.forEach(item => {
                roles.push(item.identifier)
                item.permissions.forEach(p => {
                    permissions.push(p.identifier)
                })
            })
            res.data.data.roles = roles;
            res.data.data.permissions = permissions;
            localStorage.setItem("Token", res.headers.token);
            localStorage.setItem("User", JSON.stringify(res.data.data));
            this.props?.onSuccess();
        }).catch(err => {
            this.setState({loading: false});
            console.error("Request token failed", err);
        });
    };

    onCheckChange = value => !!value.target.checked ? localStorage.setItem("defaultLogin", "1") : localStorage.setItem("defaultLogin", "0");

    /**
     * 登录功能
     * @param data  表单数据
     */
    handle = (data) => {
        this.toggleLoading();
        data.captchaId = this.state.captchaId;
        let customerDepartmentParentIds = "";
        axios.patch(`${global.config.url}/user/login`, data).then((response) => {
            if (response.data.status === 20000) {
                if (response.data.data.departments.length === 0) {
                    message.error("暂无机构,请联系相关人员添加")
                    return;
                }
                localStorage.setItem("Token", response.headers.token);

                const roles = []
                const permissions = []
                response.data.data.roles.forEach(item => {
                    roles.push(item.identifier)
                    item.permissions.forEach(p => {
                        permissions.push(p.identifier)
                    })
                })
                response.data.data.roles = roles;
                response.data.data.permissions = permissions;

                localStorage.setItem("User", JSON.stringify(response.data.data));
                if (!isAdmin()) {
                    if (!response.data.data.departments || response.data.data.departments[0] === null) {
                        message.error("暂未关联机构,请联系相关人员");
                        this.toggleLoading();
                        return;
                    }
                    response.data.data.departments.forEach(item => {
                        if (!!item.customerDepartmentIds) {
                            customerDepartmentParentIds = item.customerDepartmentIds.toString()
                        }
                    });
                    if (!!customerDepartmentParentIds) {
                        localStorage.setItem("customerDepartmentIds", customerDepartmentParentIds.toString());
                        this.props.onSuccess();
                    } else {
                        localStorage.setItem("customerDepartmentIds", "");
                        this.props.onSuccess();
                    }
                } else {
                    this.props.onSuccess();
                }
            } else {
                switch (response.data.status) {
                    case 40000:
                        this.formRef.current.setFieldsValue({captchaValue: ""});
                        this.formRef.current.setFields([{name: "captchaValue", errors: [response.data.data]}]);
                        break;
                    case 40307:
                        // 密码错误
                        this.formRef.current.setFieldsValue({captchaValue: ""});
                        this.formRef.current.setFields([{name: "password", errors: ["密码错误"]}]);
                        break;
                    case 40306:
                        this.formRef.current.setFieldsValue({captchaValue: ""});
                        this.formRef.current.setFields([{name: "username", errors: [response.data.data]}]);
                        break;
                    case 40400:
                        this.formRef.current.setFieldsValue({captchaValue: ""});
                        this.formRef.current.setFields([{name: "captchaValue", errors: [response.data.data]}]);
                        break;
                    case 40403:
                        // 账户被禁用
                        this.formRef.current.setFieldsValue({captchaValue: ""});
                        this.formRef.current.setFields([{name: "username", errors: [response.data.data]}]);
                        break;
                    default:
                        this.formRef.current.setFieldsValue({captchaValue: ""});
                        message.error({
                            content: response.data.data, key: "login"
                        });
                        break;
                }
                this.toggleLoading();
                // 重新获取验证码
                this.getCaptcha();
            }
        }).catch((err) => {
            console.error(err)
            this.toggleLoading();
            message.error({key: "loginError", content: "未知错误,请刷新浏览器重试！"});
            // 重新获取验证码
            this.getCaptcha();
        });
    };

    render() {
        return <div className={style.Container}>
            <div className={style.logosBox}>
                <div className={style.logoBg}>
                    <img src={logoBg} alt="jiesh"/>
                </div>
                <div className={style.logoMsg}>
                    <img src={logoMsg} alt="jiesh"/>
                </div>
            </div>
            <div className={style.boxContainer}>
                <div className={style.fromContainer}>
                <Typography.Title level={2} style={{marginBottom: 30}}>信创运维远程服务平台</Typography.Title>
                    <Form ref={this.formRef} onFinish={this.handle} layout={"vertical"}>
                        <Row>
                            <Col span={24}>
                                <Form.Item name="username" rules={[{required: true, message: "请输入账号"}]}>
                                    <Input disabled={this.state.loading} placeholder="请输入账号"
                                           prefix={<UserOutlined/>}/>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name="password" required rules={[{required: true, message: "请输入密码"}]}>
                                    <Input.Password onFocus={this.passwordFocus} onBlur={this.passwordBlur}
                                                    disabled={this.state.loading} prefix={<KeyOutlined/>}
                                                    placeholder="请输入密码"/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col xs={{span: 24}} lg={{span: 12}}>
                                <Form.Item name="captchaValue" rules={[{required: true, message: "请输入验证码"}]}>
                                    <Input disabled={this.state.loading} prefix={<ProfileOutlined/>}
                                           placeholder="请输入验证码"/>
                                </Form.Item>
                            </Col>
                            <Col xs={{span: 24}} lg={{span: 12}}>
                                <img src={this.state.base64Image} alt="验证码（点击刷新）" className={style.verifyImage}
                                     onClick={this.getCaptcha}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={8}>
                                <Checkbox onChange={this.onCheckChange}
                                          defaultChecked={this.state.check === "1"}>自动登录</Checkbox>
                            </Col>
                            <Col span={8} offset={8}>
                                <Button type="link" block onClick={() => this.props.onToggleForm("0")}>忘记密码</Button>
                            </Col>
                        </Row>
                        <Row justify="center" algin="center">
                            <Col span={12} style={{marginTop: 20}}>
                                <Button type="primary" htmlType="submit" loading={this.state.loading}
                                        block>登录</Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
                <div className={style.fromjieshImg}>
                    <img src={xlaqgfBg} alt=""/>
                </div>
            </div>
        </div>;
    }
}

