import React, {Component} from "react";
import {Button, Col, Form, message, Row, Space} from "antd";
import CustomerDepartmentApi from "api/customer/department";
import CustomerDepartmentPicker from "component/customer/department/CustomerDepartmentPicker";
import DraggableModal from "component/DraggableModal";
import {getUser, hasAnyRole} from "../../../../../component/SecurityInterceptor";

/**
 * 迁移客户单位弹窗 Modal
 */
export default class MoveCustomerDepartmentModal extends Component {

    formRef = React.createRef();
    draggableRef = React.createRef();

    state = {
        show: false,
        loading: false,
        id: "",
        treeData: []
    };

    show = id => {
        this.draggableRef.current.show();
        this.setState({id: id}, this.getDepartment);
    };

    close = () => {
        this.setState({loading: false});
        this.draggableRef.current.close();
    };

    toggleLoading = () => {
        this.setState({loading: !this.state.loading});
    };

    /**
     * 获取客户单位
     */
    getDepartment = () => {
        CustomerDepartmentApi.tree(getUser().departments[0].id).then(response => {
            this.setState({treeData: this.handleDepartmentTreeNode(response.data)});
        }).catch(error => {
            console.warn("Get department info", error);
        });
    };

    /**
     * 转换单位信息为树形节点
     * @param tree 单位信息
     */
    handleDepartmentTreeNode = tree => tree.map(item => ({
        ...item,
        title: item.name,
        key: item.id,
        value: item.id,
        children: !!item.children ? this.handleDepartmentTreeNode(item.children) : undefined
    }));

    /**
     * 迁移客户单位
     * @param value
     */
    handle = (value) => {
        if (this.state.id === value.clientDepartment) {
            this.formRef.current.setFieldsValue({clientDepartment: ""});
            this.formRef.current.setFields([{
                name: "clientDepartment",
                errors: ["不能迁移到自己本身"]
            }]);
        } else {
            let obj = {
                id: this.state.id,
                parentId: value.clientDepartment || 0
            };
            CustomerDepartmentApi.move(obj).then(response => {
                this.close();
                this.props.onSuccess();
            }).catch(error => {
                this.setState({loading: false})
                if (error.status === 40000) {
                    message.error("已在改单位下,无需迁移!")
                } else {
                    message.error({key: "failed", content: error.data});
                }
            });
        }

    };

    render() {
        return <DraggableModal onCancel={this.close} title="迁移单位" ref={this.draggableRef}>
            <Form ref={this.formRef} onFinish={this.handle} layout="vertical">
                {hasAnyRole(["admin"]) ?
                    <CustomerDepartmentPicker name="clientDepartment" label="选择迁移的父单位"/> :
                    <CustomerDepartmentPicker required rules={[{required: true, message: "选择迁移的父单位"}]} name="clientDepartment" label="选择迁移的父单位"/>
                }
                <Row justify="end" gutter={8}>
                    <Col>
                        <Space>
                            <Button disabled={this.state.loading} onClick={this.close}>取消</Button>
                            <Button loading={this.state.loading} type="primary" htmlType="submit">迁移</Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </DraggableModal>;
    }
}
