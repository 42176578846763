import React from "react";
import {Button, Col, DatePicker, Drawer, Form, Input, message, Row, Select, Skeleton, Space, TreeSelect} from "antd";
import moment from "moment";

import WorkorderApi from "api/workorder";
import {ConvertSmpWorkorderType, SmpWorkorderType} from "../../assets/WorkorderType";
import CustomerDepartmentPicker from "component/customer/department/CustomerDepartmentPicker";
import {setFieldsError} from "plugin/formily";
import FileUploadRender from "component/FileUploadRender";
import dayjs from "dayjs";

/**
 * 编辑信创工单 Drawer
 *
 * @description 编辑信创工单
 *
 * @author huang Carder
 * @since 2021-06-23
 * @version 1.0.0
 */
export default class UpdateSmpUnassignedWorkorderDrawer extends React.Component {

    state = {
        drawerWidth: window.innerWidth,
        show: false,
        loading: false,
        typeFlag: true,
        isNecessary: false,
        workorderId: "",
        customerData: [],
        fileData: [],
        workorderInfoLoading: true,
        workorderType: "",
        oldWorkorderInformation: {}
    };

    formRef = React.createRef();
    filesRef = React.createRef();

    show = id => {
        this.setState({
            show: true,
            workorderId: id,
            drawerWidth: window.innerWidth < 576 ? window.innerWidth : window.innerWidth * 0.55
        }, () => {
            this.getWorkorderInfo(id);
        });
    };

    close = () => this.setState({show: false, fileData: [], loading: false});

    toggleLoading = () => this.setState({loading: !this.state.loading});

    /**
     * 获取选中工单信息
     */
    getWorkorderInfo = id => {
        WorkorderApi.findDispatch(id).then(response => {
            let files = [];
            if (!!response.data.files) {
                response.data.files.forEach((file) => {
                    files.push({
                        uid: file.id,
                        name: file.name,
                        url: file.path,
                        thumbUrl: file.path,
                        type: file.type
                    });
                });
            }
            let obj = {
                type: response.data.type,
                priority: response.data.priority.toString(),
                deadLine: dayjs(response.data.deadLine, "YYYY-MM-DD HH:mm"),
                clientDepartment: response.data.clientDepartmentId,
                clientCustomer: response.data.clientId,
                description: !!response.data.description ? response.data.description : "",
                files
            };
            this.setState({
                fileData: files,
                workorderType: response.data.type,
                workorderInfoLoading: false,
                oldWorkorderInformation: response.data
            });
            //延时
            setTimeout(() => {
                this.filesRef.current.setFilesValue(files);
                this.formRef.current.setFieldsValue(obj);
            }, 100);
            this.clientDepartmentOnChange(response.data.clientDepartmentId, false);
        }).catch(error => {
            console.warn("Get workorder info failed", error);
        });
    };

    /**
     * 通过 departmentId 获取客户
     * @param departmentId 客户单位 ID
     * @returns {Promise<void>}
     */
    requestCustomers = async (departmentId) => {
        const {data} = await global.axios.get("/customer/customers", {params: {departmentId}});
        const customerOptions = [];
        if (!!data && data.length > 0) {
            data.forEach((customer) => {
                customerOptions.push(this.handleCustomerOption(customer));
            });
        }
        this.setState({
            customerData: customerOptions
        });
    };

    /**
     * 判断编辑时信息是否更改
     * @param param 新数据
     */
    isNecessaryToHandle = param => {

        const data = {
            type: param.type,
            priority: param.priority,
            deadLine: param.deadLine.format("YYYY-MM-DD HH:mm"),
            clientDepartment: param.clientDepartment,
            clientCustomer: param.clientCustomer,
            description: param.description,
            files: param.files
        };

        const old = {
            type: this.state.oldWorkorderInformation.type,
            priority: this.state.oldWorkorderInformation.priority.toString(),
            deadLine: this.state.oldWorkorderInformation.deadLine,
            clientDepartment: this.state.oldWorkorderInformation.clientDepartmentId,
            clientCustomer: this.state.oldWorkorderInformation.clientId,
            description: this.state.oldWorkorderInformation.description ? this.state.oldWorkorderInformation.description : "",
            files: this.state.oldWorkorderInformation.files
        };

        if (Object.entries(data).toString() === Object.entries(old).toString() && Object.entries(data.files).toString() === Object.entries(old.files).toString()) {
            return true;
        }
    };

    /**
     * 编辑工单
     * @param data 表单数据
     */
    handle = data => {
        // if (this.isNecessaryToHandle(data)) {
        //     this.close();
        //     return;
        // }
        this.toggleLoading();
        const param = {
            clientDepartmentId: data.clientDepartment,
            clientId: data.clientCustomer,
            deadLine: data.deadLine.format("YYYY-MM-DD HH:mm"),
            description: data.description,
            priority: data.priority,
            title: data.title,
            type: data.type,
            files: this.filesRef.current.getFilesValue(),
            id: this.state.workorderId,
            deleteFiles: this.filesRef.current.getDeletedFiles()
        };

        ConvertSmpWorkorderType(param);

        if (this.state.typeFlag) {
            param.resourceType = undefined;
            param.resourceName = undefined;
        }

        WorkorderApi.updateDispatch(param).then((response) => {
            this.setState({
                typeFlag: true,
                loading: false
            });
            this.close();
            this.props.onSuccess();
        }).catch(error => {
            if (error.status === 40000) {
                setFieldsError(error, this.formRef);
            } else {
                message.error({key: "failed", content: [error.data]});
            }
            this.toggleLoading();
            console.warn("Create workorder failed", error);
        });
    };

    /**
     * 转换客户信息为选项
     * @param customer 客户信息
     */
    handleCustomerOption = customer => {
        return {
            label: `${customer.name}(${customer.cellphone})`,
            value: customer.id
        };
    };

    /**
     * 获取所有客户单位
     * @param value
     * @param clear
     * @returns {Promise<void>}
     */
    clientDepartmentOnChange = async (value, clear = true) => {
        if (clear) {
            this.formRef.current.setFieldsValue({clientCustomer: ""});
        }
        await this.requestCustomers(value);
    };

    typeOnChange = value => {
        if (this.state.workorderType !== value) {
            this.setState({
                typeFlag: false
            });
        }
    };

    render() {
        return <Drawer title="编辑待指派工单" width={this.state.drawerWidth} open={this.state.show}
                       closable={!this.state.loading} destroyOnClose
                       onClose={this.close}>
            <Skeleton active loading={this.state.workorderInfoLoading}>
                <Form ref={this.formRef} onFinish={this.handle} layout={"vertical"}>
                    <Row gutter={8}>
                        <Col span={12}>
                            <Form.Item name="type" label="服务类型" required
                                       rules={[{required: true, message: "请选择服务类型"}]}>
                                <TreeSelect showSearch treeNodeFilterProp="title" treeData={SmpWorkorderType}
                                            onChange={this.typeOnChange}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="priority" label="优先级" required
                                       rules={[{required: true, message: "请选择优先级"}]}>
                                <Select options={[
                                    {label: "低", value: "1"},
                                    {label: "中", value: "5"},
                                    {label: "高", value: "9"}
                                ]}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item name="deadLine" label="计划时间" required style={{flex: 4}}
                                       rules={[{required: true, message: "请选择计划时间"}]}>
                                <DatePicker showTime inputReadOnly format="YYYY-MM-DD HH:mm" style={{width: "100%"}}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={8}>
                        <Col span={12}>
                            <CustomerDepartmentPicker onChange={this.clientDepartmentOnChange}
                                                      rules={[{required: true, message: "请选择客户单位"}]}/>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="clientCustomer" label="客户" required style={{flex: 4}}
                                       rules={[{required: true, message: "请选择客户"}]}>
                                <Select options={this.state.customerData}
                                        showSearch
                                        optionFilterProp="label"/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24}>
                            <Form.Item name="description" label="工单描述" rules={[
                                {pattern: /\S/g, message: "内容不能为空"}
                            ]}>
                                <Input.TextArea/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <FileUploadRender ref={this.filesRef} name="files"
                                              uploadPath="/workorder/workorder/unassigned/file"/>
                        </Col>
                    </Row>
                    <Row gutter={8} justify="end">
                        <Col>
                            <Space>
                                <Button disabled={this.state.loading} onClick={this.close}>取消</Button>
                                <Button loading={this.state.loading} type="primary" htmlType="submit">编辑</Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
            </Skeleton>
        </Drawer>;
    }
}
