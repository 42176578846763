import React from "react";
import {Button, Col, Row, Skeleton, Space, Transfer} from "antd";
import DepartmentApi from "api/user/department";
import DraggableModal from "component/DraggableModal";

/**
 * 设置单位用户
 * 获取所有用户 获取当前单位已有的用户
 * 修改单位用户 调用修改单位用户接口 为单位 添加 或 移除 用户
 */
export default class SetDepartmentCustomerModal extends React.Component {

    state = {
        show: false,
        loading: false,
        loadingTransferData: false,
        transferData: [],
        transferTargetKeys: [],
        managerKeys: [],
        departmentId: ""
    };

    draggableRef = React.createRef();

    show = id => {
        this.draggableRef.current.show();
        this.setState({
            departmentId: id
        }, async () => {
            await this.requestTransferData();
            this.requestTargetKeys();
        });
    };

    close = () => {
        this.setState({
            loading: false
        });
        this.draggableRef.current.close();
    };


    toggleLoading = () => {
        this.setState({
            loading: !this.state.loading
        });
    };

    toggleLoadingTransferData = () => {
        this.setState({
            loadingTransferData: !this.state.loadingTransferData
        });
    };

    /**
     * 获取所有用户
     */
    requestTransferData = async () => {
        this.toggleLoadingTransferData();
        const {data} = await DepartmentApi.getCustomer();
        let someData = await this.getNoDepartmentCustomer();
        let targetKeys = await this.requestTargetKeys();
        const noDepartmentAccountIds = [];
        for (let j = 0; j < someData.length; j++) {
            noDepartmentAccountIds.push(someData[j].id);
        }
        for (let i = 0; i < data.length; i++) {
            if (noDepartmentAccountIds.indexOf(data[i].id) < 0) {
                data[i]["disabled"] = true;
            }
        }
        for (let i = 0; i < data.length; i++) {
            if (targetKeys.indexOf(data[i].id) !== -1) {
                data[i]["disabled"] = false;
            }
        }
        this.setState({
            transferTargetKeys: targetKeys,
            transferData: data,
            loadingTransferData: false
        });
    };

    /**
     * 获取暂无单位用户
     */
    getNoDepartmentCustomer = async () => {
        const {data} = await DepartmentApi.NoDepartmentCustomer();
        return data;
    };

    requestTargetKeys = async () => {
        const {data} = await DepartmentApi.getDepartmentApiUser({params: {departmentId: this.state.departmentId}});
        return data;
    };

    handleTransferTargetChange = (nextTargetKeys, direction, targetKeys) => {
        direction === "left" ? this.onRemoveLeft(nextTargetKeys, direction, targetKeys) : this.onRemoveRight(nextTargetKeys, direction, targetKeys);
        this.setState({transferTargetKeys: nextTargetKeys});
    };

    onRemoveLeft = (allTargetKeys, direction, targetKeys) => {
        this.setState({managerKeys: [...this.state.managerKeys, ...targetKeys]});
    };

    onRemoveRight = (allTargetKeys, direction, targetKeys) => {
        this.setState({
            managerKeys: this.state.managerKeys.filter(item => {
                return targetKeys.indexOf(item) === -1;
            })
        });
    };

    handler = () => {
        this.toggleLoading();
        DepartmentApi.setCustomer({
            departmentId: this.state.departmentId,
            accountIds: this.state.transferTargetKeys
        }).then(() => {
            this.removeUserManagerRoles();
            this.close();
            this.props.onSuccess();
        }).catch(error => {
            this.toggleLoading();
            console.warn("Set account's identities failed", error);
        });
    };

    removeUserManagerRoles = () => {
        if (this.state.managerKeys === null || this.state.managerKeys === undefined || this.state.managerKeys.length === 0) {
            return;
        }
        DepartmentApi.removeRoles({accountIds: this.state.managerKeys, roleIds: [5]}).then(res => {
            console.log(res);
        }).catch(err => {
            console.error("Remove user manager roles failed", err);
        });
    };

    renderDrawerFooter = () => <div style={{textAlign: "right"}}>
        <Button onClick={this.close}
                style={{marginRight: 8}}
                disabled={this.state.loading || this.state.loadingTransferData}>
            关闭
        </Button>

        <Button onClick={this.handler} type="primary" loading={this.state.loading}>保存</Button>
    </div>;

    render() {
        return <DraggableModal onCancel={this.close} title="设置单位用户" ref={this.draggableRef}>
            <Skeleton active loading={this.state.loadingTransferData}>
                <Transfer dataSource={this.state.transferData}
                          showSearch
                          operations={["添加", "移除"]}
                          titles={["所有用户", "已有用户"]}
                          listStyle={{width: "100%", height: 320}}
                          rowKey={record => record.id}
                          targetKeys={this.state.transferTargetKeys}
                          render={item => item.name}
                          onChange={this.handleTransferTargetChange}/>
                <Row gutter={8} justify="end">
                    <Col>
                        <Space>
                            <Button disabled={this.state.loading} onClick={this.close}>取消</Button>
                            <Button loading={this.state.loading} type="primary" onClick={this.handler}>设置</Button>
                        </Space>
                    </Col>
                </Row>
            </Skeleton>
        </DraggableModal>;
    }
}
