// import {createForm, onFieldReact, registerValidateFormats} from "@formily/core";
// import {action} from "@formily/reactive";


export const regx = {
    //手机号正则
    phone: /(\d+)|(\\-?)/g,
    //邮箱号正则
    email: /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/g,
    //用户名正则 只能输入5-20个英文字母和数字
    username: /^[0-9a-zA-Z]*$/g,
    //匹配 身份证号正则
    identity: /(^\d{15}$)|(^\d{17}([0-9]|X|x)$)/g,
    //匹配腾讯QQ号
    qq: /[1-9][0-9]{4,}/g,
    //匹配中国邮政编码
    postal: /[1-9]d{5}(?!d)/g,
    //只能输入汉字
    chinese: /^[u4e00-u9fa5],*$/g,
    //只能输入数字
    number: /\D/g,
    //只能输入英文
    english: /[^a-zA-Z]/g,
    // 不能为纯空字符串
    // notBlank:/^\S.*\S$|(^\S{0,1}\S$)/,
    notBlank: /\S/,
    // 内容不能存在空字符串
    allNotBlank: /^[^\s]*$/g
};


/**
 *
 * @param form formily 表单引用
 * @param pattern 组件 name 属性
 * @param message 错误信息提示
 * @param resetField 是否重置字段值
 *
 * @author Wayne Carder
 * @since 2021-06-25 10:00
 * @version 1.0.0
 */
export const setFieldErrorFeedback = (form, pattern, message, resetField = false) => {
    form.query(pattern).forEach((field) => {
        field.setFeedback({triggerType: "onInput", code: "ValidateError", type: "error", messages: [message]});
        if (resetField) {
            field.value = null;
        }
    });
};

/**
 * 将服务端返回的错误对象提示自动设置到表单中
 *
 * 如服务端返回的错误提示对象为：
 *
 * <code>
 * {username:"请输入用户名",password:"请输入密码"}
 * </code>
 *
 * 并且表单对象为
 *
 * <code>
 * {username:"",password:""}
 * </code>
 *
 * 此方法将会自动设置错误提示到对应的表单字段中。
 *
 * @param form formily 表单引用
 * @param data 服务端返回的错误提示对象
 *
 * @author Wayne Carder
 * @since 2021-06-25 10:00
 * @version 1.0.0
 */
export const setFieldsErrorFeedback = (form, data) => {
    for (let errorField in data) {
        if (!data.hasOwnProperty(errorField)) {
            continue;
        }
        setFieldErrorFeedback(form, errorField, data[errorField]);
    }
};

export function setFieldsError(response, formRef) {
    if (typeof (response.data) === "object") {
        const errorInfos = [];
        for (let key in response.data) {
            errorInfos.push({
                name: key,
                errors: [response.data[key]]
            });
        }
        formRef.current.setFields(errorInfos);
    } else if (typeof response.data === "string") {
        const errorObj = JSON.parse(response.data);
        const errorInfos = [];
        for (let key in errorObj) {
            errorInfos.push({
                name: key,
                errors: [errorObj[key]]
            });
        }
        console.log(errorInfos);
        formRef.current.setFields(errorInfos);
    }
}


const formily = {
    setFieldErrorFeedback,
    setFieldsErrorFeedback
    // createForm
};

global.formily = formily;
window.formily = formily;

export default formily;
