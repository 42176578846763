import React, {Component} from "react";
import {Spin} from "antd";

import workorderInfoApi from "api/workorderInfo";
import {isAdmin} from "component/SecurityInterceptor";
import {withNavigation} from "utils/RouteLink";

import style from "./index.module.css";

/**
 * 服务概览
 * @description 已接入设备数量统计
 */
class EquipStatistic extends Component {

    state = {
        equipmentNumber: "",
        loading: true
    };

    componentDidMount() {
        this.requestEquipmentNumber();
    }

    requestEquipmentNumber = () => {
        workorderInfoApi.equipmentNumber().then(res => {
            this.setState({
                equipmentNumber: res.data,
                loading: false
            });
        }).catch(err => {
            console.error("Request equipment number fail", err);
        });
    };

    render() {
        return (
            <div className={style.systemDataItem}
                 onClick={() => isAdmin() ? this.props.navigate("/home/equipment/list") : null}>
                <p>设备数量</p>
                {this.state.loading ? <Spin/> : <p>{this.state.equipmentNumber}</p>}
            </div>
        );
    }
}

export default withNavigation(EquipStatistic);
