import React, {Component} from "react";
import {Button, Col, Form, message, Row, Space, TreeSelect} from "antd";
import DepartmentApi from "api/user/department";
import DraggableModal from "component/DraggableModal";

/**
 * 迁移单位
 * 获取所有单位
 * 该单位下有子单位的不能迁移
 * 选择需要迁移到的单位
 * 调用迁移单位接口  对单位进行迁移
 */
class MigrationModal extends Component {

    formRef = React.createRef();
    draggableRef = React.createRef();

    state = {
        show: false,
        loading: false,
        id: "",
        treeData: []
    };


    show = id => {
        this.draggableRef.current.show();
        this.setState({id: id}, this.getDepartment);
    };

    close = () => {
        this.setState({loading: false});
        this.draggableRef.current.close();
    };

    /**
     * 获取单位
     */
    getDepartment = () => {
        DepartmentApi.findAllTrees().then(response => {
            console.log("Get department info", response);
            const treeData = [];
            response.data.forEach((department) => {
                treeData.push(this.handleDepartmentTreeNode(department));
            });
            this.setState({
                treeData: treeData
            });
        }).catch(error => {
            console.warn("Get department info", error);
        });
    };

    /**
     * 转换单位信息为树形节点
     * @param department 单位信息
     */
    handleDepartmentTreeNode = department => {
        const node = {
            title: department.name,
            key: department.id,
            value: department.id,
            children: []
        };
        if (!!department.children && department.children.length > 0) {
            department.children.forEach((subDepartment) => {
                node.children.push(this.handleDepartmentTreeNode(subDepartment));
            });
        }
        return node;
    };


    /**
     * 控制 loading 加载效果 显示
     */
    toggleLoading = () => {
        this.setState({
            loading: !this.state.loading
        });
    };

    handle = value => {
        if (this.state.id === value.clientDepartment) {
            this.formRef.current.setFieldsValue({clientDepartment: ""});
            this.formRef.current.setFields([{
                name: "clientDepartment",
                errors: ["不能迁移到自己本身"]
            }]);
        } else {
            this.toggleLoading();
            let obj = {
                departmentId: this.state.id,
                parentId: value.clientDepartment || 0
            };
            DepartmentApi.move(obj).then(response => {
                this.close();
                this.props.onSuccess();
            }).catch(error => {
                this.setState({loading: false})
                if (error.status === 40000) {
                    message.error("已在改单位下,无需迁移!")
                } else {
                    message.error({key: "failed", content: error.data});
                }
                console.warn("Request department failed", error);
            });
        }
    };

    render() {
        return <DraggableModal onCancel={this.close} title="迁移单位" ref={this.draggableRef}>
            <Form ref={this.formRef} onFinish={this.handle} layout="vertical">
                <Form.Item name="clientDepartment" label="选择迁移的父单位(不选则将该单位设为顶层单位)">
                    <TreeSelect treeData={this.state.treeData}
                                showSearch
                                treeNodeFilterProp="title"/>
                </Form.Item>
                <Row gutter={8} justify="end">
                    <Col>
                        <Space>
                            <Button disabled={this.state.loading} onClick={this.close}>取消</Button>
                            <Button loading={this.state.loading} type="primary" htmlType="submit">迁移</Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </DraggableModal>;
    }
}

export default MigrationModal;
