import React, {Component} from "react";
import {Button, Col, message, Row, Space, Typography} from "antd";
import EquipmentListApi from "api/equipment/list";
import DraggableModal from "component/DraggableModal";

/**
 * 删除设备
 * 根据对应的id 删除对应设备
 */
class DeleteEquipmentModal extends Component {

    state = {
        loading: false,
        show: false,
        equipmentId: ""
    };

    draggableRef = React.createRef();

    show = id => {
        this.draggableRef.current.show();
        this.setState({equipmentId: id});
    };

    close = () => {
        this.setState({loading: false});
        this.draggableRef.current.close();
    };

    toggleLoading = () => {
        this.setState({loading: !this.state.loading});
    };

    handle = () => {
        this.toggleLoading();
        EquipmentListApi.delete(this.state.equipmentId).then(() => {
            this.close();
            this.props.onSuccess();
        }).catch(error => {
            this.toggleLoading();
            message.error(error.data)
            console.warn("Request delete equipment list failed.", error);
        });
    };

    render() {
        return <DraggableModal onCancel={this.close} title="是否删除设备" ref={this.draggableRef}>
            <Typography>数据删除后无法恢复，请谨慎操作！</Typography>
            <Row gutter={8} justify="end">
                <Col>
                    <Space>
                        <Button disabled={this.state.loading} onClick={this.close}>取消</Button>
                        <Button loading={this.state.loading} type="primary" onClick={this.handle}>删除</Button>
                    </Space>
                </Col>
            </Row>
        </DraggableModal>;
    }
}

export default DeleteEquipmentModal;
