import React, {Component} from "react";
import {Button, Col, Row, Space, Typography} from "antd";

import EquipmentRepositoryApi from "api/equipment/repository";
import DraggableModal from "component/DraggableModal";

/**
 * 删除知识库文章
 * 根据对应id 删除对应的知识库文章
 */
export default class DeleteArticleModal extends Component {

    state = {
        show: false,
        loading: false,
        articleId: ""
    };

    draggableRef = React.createRef();

    show = id => {
        this.draggableRef.current.show();
        this.setState({articleId: id});
    };

    close = () => this.draggableRef.current.close();

    toggleLoading = () => this.setState({loading: !this.state.loading});

    handle = () => {
        this.toggleLoading();
        EquipmentRepositoryApi.delete(this.state.articleId).then(() => {
            this.toggleLoading();
            this.close();
            this.props.onSuccess();
        }).catch(err => {
            this.toggleLoading();
            console.error("Delete article fail", err);
        });
    };

    render() {
        return <DraggableModal onCancel={this.close} title="是否删除知识库文章" ref={this.draggableRef}>
            <Typography>数据删除后无法恢复，请谨慎操作！</Typography>
            <Row gutter={8} justify="end">
                <Col>
                    <Space>
                        <Button disabled={this.state.loading} onClick={this.close}>取消</Button>
                        <Button loading={this.state.loading} type="primary" onClick={this.handle}>删除</Button>
                    </Space>
                </Col>
            </Row>
        </DraggableModal>;
    }
}

