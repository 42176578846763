import React, {Component} from 'react';
import DraggableModal from "../../../../../component/DraggableModal";
import {ProForm, ProFormText} from "@ant-design/pro-components";
import {Button, Col, Row, Space} from "antd";
import CustomerDepartmentApi from "../../../../../api/customer/department";

/**
 * @author       : AngelBeats
 * @date         : 2023-05-15 17:14:33
 * @description  : 创建单位账户
 */
export default class CreateDepartmentAccount extends Component {

    draggableRef = React.createRef();
    formRef = React.createRef();

    state = {
        departmentId: "",
        modalTitle: "新建单位账户",
        btnTitle: "新建"
    }

    /**
     * 显示modal
     * @param id
     */
    show = id => {
        this.draggableRef.current.show();
        this.setState({departmentId: id}, () => {
            this.requestCustomerDepartmentDetail()
        });
    }

    /**
     * 关闭modal
     */
    close = () => {
        this.draggableRef.current.close();
    }

    /**
     * 查询单位详情
     */
    requestCustomerDepartmentDetail = () => {
        CustomerDepartmentApi.find(this.state.departmentId).then(res => {
            this.formRef.current.setFieldsValue({username: res.data.username, password: res.data.password})
            if (!!res.data.username || !!res.data.password) {
                this.setState({modalTitle: "已存在以下账户", btnTitle: "更新"});
                return;
            }
            this.setState({modalTitle: "新建单位账户", btnTitle: "新建"});
        }).catch(err => {
            console.error("request customer department detail failed", err)
        })
    }

    /**
     * 表单提交
     * @param data
     */
    handle = data => {
        data.departmentId = this.state.departmentId
        data.username = data.username.trim();
        CustomerDepartmentApi.addDepartmentAccount(data).then(res => {
            this.props.onSuccess();
            this.close();
        }).catch(err => {
            console.error("create department account failed", err)
        })
    }

    render() {
        return (<DraggableModal onCancel={this.close} title={this.state.modalTitle} ref={this.draggableRef}>
            <ProForm formRef={this.formRef} onFinish={this.handle} layout={"vertical"} submitter={{
                resetButtonProps: {style: {display: "none"}},
                submitButtonProps: {style: {display: "none"}}
            }}>
                <ProFormText name="username" label="账户名" placeholder="请输入账户名" required
                             rules={[
                                 {required: true, message: "请输入账户名"},
                                 {pattern: /^[0-9a-zA-Z]{1,15}$/g, message: "请输入1-15个英文字母或数字"}
                             ]}/>
                <ProFormText.Password label="密码" name="password" required placeholder="请输入密码"
                                      rules={[
                                          {required: true, message: "请输入密码"},
                                          {pattern: /^\S+$/, message: "禁止输入空格"}
                                      ]} maxLength="20" minLength="6"/>

                <Row gutter={8} justify="end">
                    <Col>
                        <Space>
                            <Button disabled={this.state.loading} onClick={this.close}>取消</Button>
                            <Button loading={this.state.loading} type="primary"
                                    htmlType="submit">{this.state.btnTitle}</Button>
                        </Space>
                    </Col>
                </Row>
            </ProForm>
        </DraggableModal>)
    }
}
