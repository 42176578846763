import React from "react";

export function getUser() {
    const userStr = localStorage.getItem("User");
    if (!userStr) {
        return null;
    }
    return JSON.parse(userStr);
}

export function isLogin() {
    return !!localStorage.getItem("Token");
}

export function isAdmin() {
    if (!isLogin()) {
        return false;
    }
    const user = getUser();
    if (user === null) {
        return false;
    }
    //return hasSuperPermission(user.permissions) || hasAnyRole(["admin"]);
    return hasAnyRole(["admin"]);
}

export function isAdminDepartment() {
    if (!isLogin()) {
        return false;
    }

    const user = getUser();
    if (user === null) {
        return false;
    }
    let isAdminDepartment = false;
    for (let department of user.departments) {
        if (isAdminDepartment) {
            break;
        }
        if (!department.roles) {
            continue;
        }
        isAdminDepartment = isAdminDepartment || department.roles.includes("admin");
    }
    return isAdminDepartment;
}


/**
 * 是否拥有超级权限
 *
 * @param permissions 用户拥有的权限
 * @return boolean 拥有超级权限 | false 未拥有超级权限
 */
function hasSuperPermission(permissions) {
    if (!!permissions) {
        return permissions.indexOf("*:*") > -1;
    }
    return false;
}

/**
 * 检查当前用户是否拥有某些权限
 *
 * @param permissions 需要检查是否拥有的权限
 * @return boolean 拥有特定权限中的某一个权限 | false 为拥有任何一个权限
 */
export function hasAllPermission(permissions) {
    if (!permissions) {
        return true;
    }
    const user = getUser();
    if (!user) {
        return false;
    }
    if (hasSuperPermission(user.permissions)) {
        return true;
    }
    let success = true;
    for (let permission of permissions) {
        success = success && user.permissions.indexOf(permission) > -1;
    }
    return success;
}

/**
 * 拥有指定权限中的任意一个权限
 * @param permissions 指定权限
 */
export function hasAnyPermission(permissions) {
    if (!permissions) {
        return true;
    }
    const user = getUser();
    if (!user) {
        return false;
    }
    if (hasSuperPermission(user.permissions)) {
        return true;
    }
    for (let permission of permissions) {
        if (user?.permissions === undefined) {
            break;
        }
        if (user.permissions.indexOf(permission) > -1) {
            return true;
        }
    }
    return false;
}

export function hasAllRole(roles) {
    if (!roles) {
        return true;
    }
    const user = getUser();
    if (!user) {
        return false;
    }
    for (let role of roles) {
        if (user.roles.indexOf(role) > -1) {
            return true;
        }
    }
    return false;
}

export function hasAnyRole(roles) {
    if (!roles) {
        return true;
    }
    const user = getUser();
    if (!user) {
        return false;
    }
    for (let role of roles) {
        if (user.roles.indexOf(role) > -1) {
            return true;
        }
    }
    return false;
}

export function isUser(id, ignoreAdmin = true) {
    const user = getUser();
    if (hasSuperPermission(user.permissions) && ignoreAdmin) {
        return true;
    }
    if (!user) {
        return false;
    }
    return user.id === id;
}

/**
 * 安全拦截器组件
 * @description 自动根据配置的用户权限、角色决定是否渲染组件
 *
 * @author Wayne Carder
 * @since 2021-06-27
 * @version 1.0.0
 */
export default class SecurityInterceptor extends React.Component {

    static defaultProps = {
        permission: [],
        role: [],
        mode: "anyRoleOrPermission"
    };

    inspect = () => {
        switch (this.props.mode) {
            case "anyRoleOrPermission":
                return this.hasAnyRoleOrPermission();
            case "anyRoleAndPermission":
                return this.hasAnyRoleAndPermission();
            case "bothRoleOrPermission":
                return this.hasBothRoleOrPermission();
            case "bothRoleAndPermission":
                return this.hasBothRoleAndPermission();
            default:
                return this.hasAnyRoleOrPermission();
        }
    };

    hasAnyRoleOrPermission = () => {
        return hasAnyRole(this.props.role) || hasAnyPermission(this.props.permission);
    };

    hasAnyRoleAndPermission = () => {
        return hasAnyRole(this.props.role) && hasAnyPermission(this.props.permission);
    };

    hasBothRoleOrPermission = () => {
        return hasAllRole(this.props.role) || hasAllPermission(this.props.permission);
    };

    hasBothRoleAndPermission = () => {
        return hasAllRole(this.props.role) && hasAllPermission(this.props.permission);
    };

    render() {
        return this.inspect() ? this.props.children : null;
    }
}
