import React from "react";
import {Button, Col, message, Row, Space, Typography} from "antd";

import DepartmentApi from "api/user/department";
import DraggableModal from "component/DraggableModal";

/**
 * 删除机构对话框
 * 根据id 删除对应的机构
 */
export default class DeleteUserDepartmentModal extends React.Component {

    state = {
        loading: false,
        show: false,
        id: ""
    };

    draggableRef = React.createRef();

    /**
     * 控制删除对话框显示隐藏
     */
    show = id => {
        this.draggableRef.current.show();
        this.setState({id});
    };

    close = () => {
        this.setState({loading: false});
        this.draggableRef.current.close();
    };

    /**
     * 控制 loading 加载显示
     */
    toggleLoading = () => {
        this.setState({
            loading: !this.state.loading
        });
    };

    /**
     * 删除单位
     */
    handle = () => {
        this.toggleLoading();
        DepartmentApi.delete(this.state.id).then(response => {
            this.close();
            this.props.onSuccess();
        }).catch(error => {
            message.error(error.data)
            this.toggleLoading();
            console.warn("Delete customer failed", error);
        });
    };

    render() {
        return <DraggableModal onCancel={this.close} title="是否删除机构" ref={this.draggableRef}>
            <Typography>数据删除后无法恢复，请谨慎操作！</Typography>
            <Row gutter={8} justify="end">
                <Col>
                    <Space>
                        <Button disabled={this.state.loading} onClick={this.close}>取消</Button>
                        <Button loading={this.state.loading} type="primary" onClick={this.handle}>删除</Button>
                    </Space>
                </Col>
            </Row>
        </DraggableModal>;
    }
}
