/**
 * 工单满意度评价
 */
const feedbackTypes = {
    "0": "待回访",
    "1": "不满意",
    "2": "满意",
    "3": "非常满意"
}

export default feedbackTypes
