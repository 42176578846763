import React from "react";
import {Button, Col, Form, Input, message, Row, Skeleton, Space} from "antd";
import {ProForm, ProFormCascader, ProFormText} from "@ant-design/pro-components";

import DepartmentApi from "api/user/department";

import {setFieldsError} from "plugin/formily";
import DraggableModal from "component/DraggableModal";
import style from "../../../../../component/equipment/map/index.module.css";

/**
 * 新建机构
 * 填写表单对应信息 调用新建单位接口  新建单位
 */
export default class CreateUserDepartmentModal extends React.Component {

    state = {
        show: false,
        loading: false,
        userDepartmentId: "",
        createDepartmentSkeleton: false,
        lnglatDataLng: "",
        lnglatDataLat: "",
    };

    formRef = React.createRef();
    draggableRef = React.createRef();
    map;

    show = async (id) => {
        await this.draggableRef.current.show();
        this.setState({
            userDepartmentId: id
        }, () => {
            setTimeout(() => {
                this.getMap();
            }, 500)
            if (!!id && id !== "0") {
                this.setState({
                    createDepartmentSkeleton: true
                });
                this.getDepartmentDetail(id);
            }
        });

    };

    close = () => {
        this.setState({
            loading: false
        });
        this.draggableRef.current.close();
    };

    toggleLoading = () => {
        this.setState({
            loading: !this.state.loading
        });
    };

    getDepartmentDetail = (id) => {
        DepartmentApi.getDepartmentDetail(id).then(response => {
            if (response.status === 20000) {
                this.setState({
                    createDepartmentSkeleton: false
                });
                let aresData = [response.data.province, response.data.city, response.data.county];
                this.formRef.current.setFieldsValue({"ares": aresData});
            }

        }).catch(error => {
            this.setState({
                createDepartmentSkeleton: false
            });
            console.warn("Create department error", error);
        });
    };

    handle = (data) => {
        data.province = data.ares[0];
        data.city = data.ares[1];
        data.county = data.ares[2];
        data.parentId = this.state.userDepartmentId;
        if (!!this.state.lnglatDataLng && !!this.state.lnglatDataLat) {
            data.longitude = this.state.lnglatDataLng;
            data.latitude = this.state.lnglatDataLat;
            data.lnglat = `${this.state.lnglatDataLng},${this.state.lnglatDataLat}`;
        } else {
            message.warn({key: "lat", content: "经纬度不存在！"});
            this.toggleLoading();
            return;
        }
        this.toggleLoading();
        DepartmentApi.create(data).then((response) => {
            this.close();
            this.props.onSuccess();
        }).catch(error => {
            this.toggleLoading();
            console.warn("create userDepartment failed", error);
            setFieldsError(error, this.formRef);
        });
    };

    /**
     * 区县选择变化触发
     * @param value
     */
    areaChange = value => {
        this.setState({areaData: value});
        this.formRef.current.setFieldsValue({
            address: ""
        });
    };


    /**
     * 当所选的地址与详细地址不同时更新所选的地址
     * @param lnglat 经纬度
     */
    regeoCode = lnglat => new AMap.Geocoder().getAddress(lnglat, (status, result) => {
        // 特别行政区或直辖市的 city 为空 所有直接用 province
        this.formRef.current.setFieldsValue({
            ares: [
                result.regeocode.addressComponent.province,
                result.regeocode.addressComponent.city ? result.regeocode.addressComponent.city : result.regeocode.addressComponent.province,
                result.regeocode.addressComponent.district
            ]
        });
    });

    getMap = () => {
        this.map = new AMap.Map("department-container", {
            pitch: 0,
            // viewMode: "3D",
            terrain: true,
            center: [114.973172, 25.817861],
            zoom: 17,
            zoomEnable: false,
            dragEnable: false,
            resizeEnable: true
        });

        AMap.plugin(["AMap.PlaceSearch", "AMap.AutoComplete"], () => {
            new AMap.AutoComplete({input: "address"}).on("select", e => {
                this.formRef.current.setFieldsValue({address: e.poi.name});
                this.map.clearMap();
                this.map.add(new AMap.Marker({position: [e.poi.location.lng, e.poi.location.lat]}));
                this.setState({lnglatDataLng: e.poi.location.lng, lnglatDataLat: e.poi.location.lat});
                this.map.setFitView();
                let lnglat = [e.poi.location.lng, e.poi.location.lat];
                this.regeoCode(lnglat);
            });
        });
    };

    render() {
        return <DraggableModal onCancel={this.close} title="新建机构" ref={this.draggableRef} destroyOnClose>
            <Skeleton loading={this.state.createDepartmentSkeleton} active>
                <ProForm formRef={this.formRef} onFinish={this.handle} layout={"vertical"} submitter={{
                    resetButtonProps: {style: {display: "none"}},
                    submitButtonProps: {style: {display: "none"}}
                }}>
                    <ProFormCascader required rules={[{required: true, message: "请选择所在地区"}]} name="ares"
                                     label="机构区域"
                                     fieldProps={{options: require("asset/location.json"), onChange: this.areaChange}}/>
                    <ProFormText name="name" label="机构名称" placeholder="请输入机构名称" required
                                 rules={[{required: true, message: "请输入机构名称"}, {
                                     pattern: /\S/,
                                     message: "不能为空"
                                 }]}/>
                    <Col span={24}>
                        <Form.Item name="address" label="机构所在地址" required
                                   rules={[{required: true, message: "请输入详细地址"}, {pattern: /\S/g, message: "内容不能为空"}]}>
                            <Input id="address" autoComplete="off"/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <div className={style.map} id="department-container"/>
                    </Col>
                    <Row gutter={8} justify="end">
                        <Col>
                            <Space>
                                <Button disabled={this.state.loading} onClick={this.close}>取消</Button>
                                <Button loading={this.state.loading} type="primary" htmlType="submit">新建</Button>
                            </Space>
                        </Col>
                    </Row>
                </ProForm>
            </Skeleton>
        </DraggableModal>;
    }
}
