/**
 * 工单处理进度
 */
const progress = {
    "0": {
        "text": "驳回"
    },
    "1": {
        "text": "待分配"
    },
    "5": {
        "text": "待领取"
    },
    "9": {
        "text": "待处理"
    },
    "13": {
        "text": "处理中"
    },
    "14": {
        "text": "挂起中"
    },
    "17": {
        "text": "已完成"
    }
};

export default progress;
