import React from "react";
import {Button, Col, Form, Input, message, Row, Space} from "antd";
import {ProForm, ProFormCascader, ProFormSelect, ProFormText} from "@ant-design/pro-components";
import {setFieldsError} from "plugin/formily";
import DraggableModal from "component/DraggableModal";
import style from "../../../../../component/equipment/map/index.module.css";

/**
 * 编辑机构对话框
 * 填写表单对应信息  调用 更新机构接口 更新机构信息
 */
export default class UpdateUserDepartmentModal extends React.Component {

    state = {
        show: false,
        loading: false,
        updateUserDepartmentId: "",
        areaData: [],
        lnglatDataLng: "",
        lnglatDataLat: ""
    };

    formRef = React.createRef();
    draggableRef = React.createRef();
    geocoder;
    map;


    /**
     * 控制编辑对话框显示隐藏
     */
    show = id => {
        this.draggableRef.current.show();
        this.setState({updateUserDepartmentId: id}, () => {
            this.getUserDepartmentInfo();
        });
    };

    close = () => {
        this.setState({loading: false});
        this.draggableRef.current.close();
    };

    convert = address => {
        let marker = new AMap.Marker();
        this.geocoder.getLocation(address, (status, result) => {
            if (status === "complete" && result.geocodes.length) {
                let lnglat = result.geocodes[0].location;
                this.setState({
                    lnglatData: lnglat
                });
                marker.setPosition(lnglat);
                this.map.add(marker);
                this.map.setFitView(marker);
            } else {
                console.error("根据地址查询位置失败");
            }
        });
    };

    areaChange = value => {
        this.setState({areaData: value});
        this.formRef.current.setFieldsValue({
            address: ""
        });
    };


    /**
     * 当所选的地址与详细地址不同时更新所选的地址
     * @param lnglat 经纬度
     */
    regeoCode = lnglat => new AMap.Geocoder().getAddress(lnglat, (status, result) => {
        // 特别行政区或直辖市的 city 为空 所有直接用 province
        this.formRef.current.setFieldsValue({
            ares: [
                result.regeocode.addressComponent.province,
                result.regeocode.addressComponent.city ? result.regeocode.addressComponent.city : result.regeocode.addressComponent.province,
                result.regeocode.addressComponent.district
            ]
        });
    });

    getMap = () => {
        this.map = new AMap.Map("update-user-department-map", {
            pitch: 0,
            // viewMode: "3D",
            terrain: true,
            center: [114.973172, 25.817861],
            zoom: 17,
            zoomEnable: false,
            dragEnable: false,
            resizeEnable: true
        });

        AMap.plugin(["AMap.Geocoder"], () => this.geocoder = new AMap.Geocoder());

        AMap.plugin(["AMap.PlaceSearch", "AMap.AutoComplete"], () => {
            new AMap.AutoComplete({input: "address"}).on("select", e => {
                this.formRef.current.setFieldsValue({address: e.poi.name});
                this.map.clearMap();
                this.map.add(new AMap.Marker({position: [e.poi.location.lng, e.poi.location.lat]}));
                this.setState({lnglatDataLng: e.poi.location.lng, lnglatDataLat: e.poi.location.lat});
                this.map.setFitView();
                let lnglat = [e.poi.location.lng, e.poi.location.lat];
                this.regeoCode(lnglat);
            });
        });
    };


    /**
     *查询选中机构信息
     */
    getUserDepartmentInfo = () => {
        window.axios.get("/user/department", {
            params: {
                id: this.state.updateUserDepartmentId
            }
        }).then(response => {
            let params = {
                statue: response.data.statue,
                name: response.data.name,
                address: response.data.address,
                ares: []
            };
            params.ares[0] = response.data.province;
            params.ares[1] = response.data.city;
            params.ares[2] = response.data.county;
            this.formRef.current.setFieldsValue(params);
            console.log(response)
            this.setState({
                areaData: [response.data.province, response.data.city, response.data.county],
                lnglatDataLat: response.data.latitude,
                lnglatDataLng: response.data.longitude
            });
            let address = response.data.province + response.data.city + response.data.county + response.data.address;
            setTimeout(() => {
                this.getMap();
                this.convert(address);
            }, 500)
        }).catch(error => {
            console.warn("Get customer Info failed", error);
        });
    };

    /**
     * 控制 loading 加载显示
     */
    toggleLoading = () => {
        this.setState({
            loading: !this.state.loading
        });
    };

    /**
     * 更新机构信息
     * @param data   表单数据
     */
    handle = (data) => {
        data.id = this.state.updateUserDepartmentId;
        data.province = data.ares[0];
        data.city = data.ares[1];
        data.county = data.ares[2];
        data.ares = undefined;
        data.longitude = this.state.lnglatDataLng;
        data.latitude = this.state.lnglatDataLat;
        data.lnglat = `${this.state.lnglatDataLng},${this.state.lnglatDataLat}`;
        this.toggleLoading();
        window.axios.patch("/user/department", data).then((response) => {
            this.close();
            this.props.onSuccess();
        }).catch(error => {
            this.toggleLoading();
            console.warn("Update user department failed", error);
            if (error.status === 40001 || error.status === 40402) {
                message.error({content: "更新失败", key: "update"});
            } else {
                setFieldsError(error, this.formRef);
            }
        });
    };

    render() {
        return <DraggableModal onCancel={this.close} title="更新机构信息" ref={this.draggableRef} destroyOnClose>
            {/*<Form form={this.form} onAutoSubmit={(value) => this.handle(value)} layout="vertical">*/}
            {/*    <Field name="ares" title="机构区域" required decorator={[FormItem]} component={[Cascader]}*/}
            {/*           dataSource={require("asset/location.json")}/>*/}
            {/*    <Field name="address" title="详细地址" required decorator={[FormItem]} component={[Input.TextArea]}*/}
            {/*           validator={{format: "notBlank", message: "内容不能为空"}}/>*/}
            {/*    <SchemaField schema={schema}/>*/}
            {/*    <FormButtonGroup align="center">*/}
            {/*        <Button onClick={this.close} danger={this.state.loading}>取消</Button>*/}
            {/*        <Submit loading={this.state.loading}>更新</Submit>*/}
            {/*    </FormButtonGroup>*/}
            {/*</Form>*/}
            <ProForm formRef={this.formRef} onFinish={this.handle} layout={"vertical"} submitter={{
                resetButtonProps: {style: {display: "none"}},
                submitButtonProps: {style: {display: "none"}}
            }}>
                <ProFormCascader required rules={[{required: true, message: "请选择所在地区"}]} name="ares" label="机构区域"
                                 fieldProps={{options: require("asset/location.json")}} onChange={this.areaChange}/>
                <ProFormText name="name" label="机构名称" placeholder="请输入机构名称" required
                             rules={[{required: true, message: "请输入机构名称"}, {
                                 pattern: {pattern: /\S/, message: "不能为空"},
                                 message: "不能为空"
                             }]}/>
                <ProFormSelect name="statue" label="是否禁用" options={[{label: "是", value: 0},
                    {label: "否", value: 1}]} required
                               rules={[{required: true, message: "请选择是否禁用"}]}/>
                <Form.Item name="address" label="机构所在地址" required
                           rules={[{required: true, message: "请输入详细地址"}, {pattern: /\S/g, message: "内容不能为空"}]}>
                    <Input id="address" autoComplete="off"/>
                </Form.Item>
                <div id="update-user-department-map" className={style.map}/>
                <Row gutter={8} justify="end">
                    <Col>
                        <Space>
                            <Button disabled={this.state.loading} onClick={this.close}>取消</Button>
                            <Button loading={this.state.loading} type="primary" htmlType="submit">更新</Button>
                        </Space>
                    </Col>
                </Row>
            </ProForm>
        </DraggableModal>;
    }
}
