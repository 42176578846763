import React, {Component} from "react";
import {Button, Col, Row, Skeleton, Space, Transfer} from "antd";

import DraggableModal from "component/DraggableModal";
import DepartmentApi from "api/user/department";
import StorehouseApi from "api/spares/storehouse";

class SetStorehouseEngineerModal extends Component {

    state = {
        loading: false,
        loadingTransferData: false,
        transferData: [],
        transferTargetKeys: [],
        managerKeys: [],
        storehouseId: ""
    };

    draggableRef = React.createRef();

    show = (id, departmentId) => {
        this.draggableRef.current.show();
        this.setState({storehouseId: id});
        this.requestStorehouseAllUser(departmentId);
        this.requestStorehouseEngineers(id);
    };

    close = () => {
        this.draggableRef.current.close();
        this.setState({transferTargetKeys: []});
    };

    toggleLoading = () => this.setState({loading: !this.state.loading});

    requestStorehouseAllUser = (id) => {
        DepartmentApi.getDepartmentAccount(id).then(res => {
            this.setState({transferData: res.data, loadingTransferData: false});
        }).catch(err => {
            console.error("Request department admin failed", err);
        });
    };

    requestStorehouseEngineers = (id) => {
        StorehouseApi.findStorehouseEngineers(id).then(res => {
            this.setState({
                transferTargetKeys: res.data.map(item => item.engineer),
                loadingTransferData: false
            });
        }).catch(err => {
            console.error("Request storehouse admin failed", err);
        });
    };

    handleTransferTargetChange = (nextTargetKeys, direction, targetKeys) => {
        direction === "left" ? this.onRemoveLeft(nextTargetKeys, direction, targetKeys) : this.onRemoveRight(nextTargetKeys, direction, targetKeys);
        this.setState({transferTargetKeys: nextTargetKeys});
    };

    onRemoveLeft = (allTargetKeys, direction, targetKeys) => {
        this.setState({managerKeys: [...this.state.managerKeys, ...targetKeys]});
    };

    onRemoveRight = (allTargetKeys, direction, targetKeys) => {
        this.setState({
            managerKeys: this.state.managerKeys.filter(item => {
                return targetKeys.indexOf(item) === -1;
            })
        });
    };

    handler = () => {
        this.toggleLoading();
        StorehouseApi.setStorehouseEngineers({
            engineers: this.state.transferTargetKeys,
            repository: this.state.storehouseId
        }).then(res => {
            this.toggleLoading();
            this.props.onSuccess();
            this.close();
            console.log(res);
        }).catch(err => {
            this.toggleLoading();
            console.error("set storehouse admin failed", err);
        });
    };

    render() {
        return (<DraggableModal onCancel={this.close} title="设置仓库工程师" ref={this.draggableRef}>
                <Skeleton active loading={this.state.loadingTransferData}>
                    <Transfer dataSource={this.state.transferData}
                              showSearch
                              operations={["添加", "移除"]}
                              titles={["所有用户", "已有用户"]}
                              listStyle={{width: "100%", height: 320}}
                              rowKey={record => record.id}
                              targetKeys={this.state.transferTargetKeys}
                              render={item => item.name}
                              onChange={this.handleTransferTargetChange}/>
                    <Row gutter={8} justify="end">
                        <Col>
                            <Space>
                                <Button disabled={this.state.loading} onClick={this.close}>取消</Button>
                                <Button loading={this.state.loading} type="primary" onClick={this.handler}>设置</Button>
                            </Space>
                        </Col>
                    </Row>
                </Skeleton>
            </DraggableModal>
        );
    }
}

export default SetStorehouseEngineerModal;
