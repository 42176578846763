import React, { Component } from 'react';
import StreamApp from "./App"
import { RecoilRoot } from 'recoil';
class StreamReceiver extends Component {

    render() {
        return (
            <RecoilRoot>
                <StreamApp />
            </RecoilRoot>
        );
    }
}

export default StreamReceiver;