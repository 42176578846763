import React, {Component} from 'react';
import EventBus from "../../../../utils/eventBus";
import style from "../../equipment/map/index.module.css";
import {PageContainer} from "@ant-design/pro-components";

import './index.css'
import "./EngineerList.css"
import AccountApi from "../../../../api/user/account";

import WorkorderApi from "../../../../api/workorder";
import WorkorderInfoApi from "../../../../api/workorderInfo";
import engineerOnline from "asset/engineer_online_icon.png";
import engineerOffline from "asset/engineer_offline_icon.png";
import dayjs from "dayjs";
import defaultAvatar from "asset/defaultAvatar.jpeg"
import EngineerDetailDialog from "./dialog/EngineerDetailDialog";
import {Segmented} from "antd";

/**
 * @author       : AngelBeats
 * @date         : 2023-02-27 16:27:32
 * @description  : 工程师地图
 */
export default class EngineerMap extends Component {

    map;
    cluster;
    makersDialog;
    makerDialog;
    previewMapTableRef = React.createRef();
    engineerDetailDialogRef = React.createRef();

    // 定时器
    timer = null;

    state = {
        flag: true,
        isAdmin: false,
        workorder: "",
        isQueryAll: true,
        isDisableSegmented: false,
        makers: []
    };

    componentDidMount() {
        setTimeout(() => {
            this.initMap();
            this.requestAccountLocation(false);
        }, 10)
        EventBus.addListener("updateMapTheme", ({value}) => {
            this.map.setMapStyle(value ?? "amap://styles/normal")
        });

        this.timer = setInterval(() => {
            this.destroyMarkerCluster();
            this.requestAccountLocation(this.state.isQueryAll);
        }, 15000);

        window.engineerGroupClick = (id) => {
            this.engineerDetailDialogRef.current.show(id)
        }
    }

    componentWillUnmount() {
        // 销毁定时器
        clearInterval(this.timer);
    }

    /**
     * 销毁聚合点
     */
    destroyMarkerCluster = () => {
        this.cluster.setMap(null);
    }

    /**
     * 初始化地图
     */
    initMap = () => {
        this.map = new AMap.Map("engineer-map-container", {
            pitch: 0,
            viewMode: "3D",
            terrain: true,
            zoom: 15,
            resizeEnable: true,
            zooms: [4.6, 18],
            center: [114.973172, 25.817861],
            mapStyle: JSON.parse(localStorage.getItem("theme")) ? "amap://styles/darkblue" : null
        });
    }

    initSearch = () => {
        let autoOptions = {
            input: "engineer-search-ipt"
        };
        let that = this;
        AMap.plugin(['AMap.PlaceSearch', 'AMap.AutoComplete'], function () {
            let auto = new AMap.AutoComplete(autoOptions);
            let placeSearch = new AMap.PlaceSearch({
                map: that.map
            }); //构造地点查询类
            auto.on("select", select);//注册监听，当选中某条记录时会触发
            function select(e) {
                placeSearch.setCity(e.poi.adcode);
                placeSearch.search(e.poi.name); //关键字查询查询
            }
        });
    }

    /**
     * 查询用户积分
     */
    requestAccountIntegration = (accountId) => {
        AccountApi.queryUserScore(accountId).then(res => {
            let accountIntegration = document.getElementById("account-integration");
            accountIntegration.innerHTML = res.data;
        }).catch(err => {
            console.error("Request account integration failed", err)
        })
    }

    /**
     * 获取用户完成工单总数
     */
    requestWorkorderTotal = (accountId) => {
        WorkorderApi.indicators({handlerId: accountId, progress: 17}).then(res => {
            let finishWorkorder = document.getElementById("finish-workorder");
            finishWorkorder.innerHTML = res.data
        }).catch(err => {
            console.error("Request workorder total", err)
        })
    }

    /**
     * 查询用户正在进行中的工单
     * @param accountId
     */
    requestOngoingWorkorder = (accountId) => {
        WorkorderInfoApi.ongoingWorkorder(accountId).then(res => {
            let customerDepartment = document.getElementById("customer-department");
            let customerName = document.getElementById("customer-name");
            let address = document.getElementById("address");
            customerDepartment.innerHTML = res.data?.clientDepartmentName ?? "---";
            customerName.innerHTML = res.data?.clientName ?? "---";
            address.innerHTML = res.data?.clientDepartmentAddress ?? "---";
        }).catch(err => {
            console.error("Request ongoing workorder failed", err)
        })
    }

    /**
     * 获取用户工单好评率
     */
    requestWorkorderGoodReview = (accountId) => {
        WorkorderApi.findWorkorderGoodReview({handler: accountId}).then(res => {
            let workorderGoodReview = document.getElementById("workorder-good-review");
            workorderGoodReview.innerHTML = res.data.toFixed(2) * 100 + "%"
        }).catch(err => {
            console.error("Request workorder total", err)
        })
    }

    /**
     * 获取用户单日完成工单数
     */
    requestWorkorderTodayTotal = (accountId) => {
        let time = new Date();
        WorkorderApi.findWorkorderNumber({
            handlerId: accountId,
            startTime: `${time.getFullYear()}-${(time.getMonth() + 1).toString().padStart(2, 0)}-${time.getDate().toString().padStart(2, 0)} 00:00:00`,
            endTime: `${time.getFullYear()}-${(time.getMonth() + 1).toString().padStart(2, 0)}-${time.getDate().toString().padStart(2, 0)} 23:59:59`
        }).then(res => {
            let workorderTodayTotal = document.getElementById("today-finish-workorder");
            workorderTodayTotal.innerHTML = res.data
        }).catch(err => {
            console.error("Request workorder today total", err)
        })
    }

    /**
     * 渲染工程师列表聚合点弹窗
     * @param item
     */
    renderAssemblyDialog = (item) => {
        let content = `<div class="engineer-list-box">
               <div class="engineer-list-title">工程师列表</div>
               <div class="engineer-list">
                  ${item.clusterData.map(itemY => `<div class="engineer-list-item" onclick="engineerGroupClick('${itemY.location.account}')">
                        <img src="${itemY?.avatar ? itemY.avatar : defaultAvatar}" class="engineer-avatar-img">
                        <div class="engineer-name-department">
                            <p class="engineer-list-name">
                                ${itemY?.name}
                                <span style="${itemY.isOffline ? 'color:#C0C0C0' : 'color:#fff'}">[ ${itemY.isOffline ? "离线" : "在线"} ]</span>
                            </p>
                            <p class="engineer-list-department">${itemY?.department?.name}</p>
                        </div>
                    </div>`).join("")}
               </div>
        </div>`
        this.makersDialog = new AMap.InfoWindow({
            content,
            offset: new AMap.Pixel(23, -35),
            closeWhenClickMap: true
        })
        this.makersDialog.open(this.map, item.clusterData[0].lnglat);
    }

    /**
     * 渲染地图弹窗
     * @returns {string}
     */
    renderDialog = (item) => {
        if (item.clusterData.length === 1) {

            let content = `<div class='engineer_box'>
                                <div class="engineer_department">
                                  <span class="department_name">${item?.clusterData[0]?.department?.name}</span>
                                </div>
                                <div class="engineer_info">
                                  <img src="${item.clusterData[0]?.avatar ? item.clusterData[0]?.avatar : defaultAvatar}" alt="">
                                  <div class="engineer_name_mark">
                                     <p class="engineer_name">${item.clusterData[0]?.name}</p>
                                     <p class="engineer_mark"><span>积分：</span><span id="account-integration">---</span></p>
                                  </div>
                                </div>
                                <p class="today_work text_style"><span>今日完成工单：</span><span id="today-finish-workorder">--</span></p>
                                <p class="total-work text_style"><span>总计完成工单：</span><span id="finish-workorder">---</span></p>
                                <p class="praise text_style"><span>工单好评率：</span><span id="workorder-good-review">---</span></p>
                                <div class="engineer_department now_work">
                                  <span class="department_name">当前工单</span>
                                </div>
                                <p class="customer_department text_style"><span>客户单位：</span><span id="customer-department">---</span></p>
                                <p class="customer_name text_style"><span>客户姓名：</span><span id="customer-name">---</span></p>
                                <p class="address text_style"><span>详细地址：</span><span id="address">---</span></p>
                            <!--            <div class="detail">详情</div>-->
                       </div>`

            this.makerDialog = new AMap.InfoWindow({
                content,
                offset: new global.AMap.Pixel(23, -35),
                closeWhenClickMap: true
            })

            this.requestAccountIntegration(item?.clusterData[0].location.account);
            this.requestWorkorderTotal(item?.clusterData[0].location.account);
            this.requestOngoingWorkorder(item?.clusterData[0].location.account);
            this.requestWorkorderGoodReview(item?.clusterData[0].location.account);
            this.requestWorkorderTodayTotal(item?.clusterData[0].location.account);
            this.makerDialog.open(this.map, item.lnglat);

        } else {
            this.renderAssemblyDialog(item);
        }

    }

    /**
     * 渲染地图maker点标记图标
     * @param item
     */
    renderMaker = item => {
        let content = `<div class="maker_box">
                     <img src="${item?.data[0]?.isOffline ? engineerOffline : engineerOnline}" style="width: 60px;">
                     <span class="maker_name" style="width: 60px;${item?.data[0]?.name.length > 2 ? `left:10px` : null}">${item?.data[0]?.name}</span>
                </div>`
        item.marker.setContent(content)
    }

    /**
     * 自定义聚合点样式
     * @private
     * @param item
     */
    _renderClusterMarker = function (item) {
        let content = `<div class="maker_box">
                          <img src="${engineerOnline}" style="width: 60px;">
                          <span class="maker_name">${item?.count}位</span>
                        </div>`
        let offset = new AMap.Pixel(-28, -20);
        item.marker.setContent(content);
        item.marker.setOffset(offset)
    };

    /**
     * 请求工程师位置信息
     */
    requestAccountLocation = (queryAll = true) => {
        AccountApi.requestAccountLocation().then(response => {

            let accountData = [];

            response.data.forEach(item => {
                if (item.location !== null) {
                    if (queryAll) {
                        accountData.push(item)
                    }

                    if (!queryAll && (dayjs().unix() - 300) - dayjs(item.location.createTime).unix() < 0) {
                        accountData.push(item);
                    }
                }
            })

            this.cluster = new AMap.MarkerCluster(
                this.map,
                accountData.map(item => ({
                    lnglat: [item.location.longitude, item.location.latitude],
                    isOffline: (dayjs().unix() - 300) - dayjs(item.location.createTime).unix() > 0, ...item
                })),
                {
                    gridSize: 80,
                    renderClusterMarker: this._renderClusterMarker, // 自定义聚合点样式
                    renderMarker: this.renderMaker // 自定义非聚合点样式
                }
            );
            this.cluster.on("click", this.renderDialog);

            this.setState({
                isDisableSegmented: false,
            })

        }).catch(error => {
            console.warn("Request points failed.", error);
            this.setState({
                isDisableSegmented: false
            })
        });
    };

    change = (e) => {
        console.log(e.target.value)
    }

    /**
     * 在线离线工程师切换
     * @param value
     */
    previewEngineer = value => {
        this.setState({isQueryAll: value === "全部", isDisableSegmented: true}, () => {
            this.destroyMarkerCluster();
            if (this.makersDialog) {
                this.makersDialog.close();
            }
            if (this.makerDialog) {
                this.makerDialog.close();
            }
            if (value === "全部") {
                this.requestAccountLocation();
            }
            if (value === "在线") {
                this.requestAccountLocation(false);
            }
        })
    }

    render() {
        return (<PageContainer header={{title: "工程师地图", breadcrumb: {}}}>
            <div className={style.map} id="engineer-map-container"/>
            <div style={{position: "absolute", top: 80, right: 50, zIndex: 2}}>
                <Segmented options={['在线', '全部']} onChange={this.previewEngineer}
                           disabled={this.state.isDisableSegmented}/>
            </div>
            <EngineerDetailDialog ref={this.engineerDetailDialogRef}/>
            {/*<Input className="search-ipt" id="engineer-search-ipt" onChange={this.change}/>*/}
        </PageContainer>)
    }
}
