import React, {forwardRef, useRef} from "react";

import {Button, Tooltip} from "antd";
import {DeleteOutlined, EditOutlined, EyeOutlined} from "@ant-design/icons";
import {PageContainer, ProTable} from "@ant-design/pro-components";

import CreateVersionUpdateDrawer from "./drawer/CreateVersionUpdateDrawer";
import PreviewVersionUpdate from "./drawer/PreviewVersionUpdate";
import DeleteVersionUpdate from "./modal/DeleteVersionUpdate";
import EditVersionDrawer from "./drawer/EditVersionDrawer";

import VersionApi from "api/user/version";

const VersionUpdate = () => {

    const columns = [
        {
            title: "ID",
            dataIndex: "id"
        },
        {
            title: "版本名称",
            dataIndex: "versionName"
        },
        {
            title: "版本序号",
            dataIndex: "versionSerial"
        },
        {
            title: "平台",
            dataIndex: "platform"
        },
        {
            title: "状态",
            dataIndex: "status",
            hideInTable: true
        },
        {
            title: "类型",
            dataIndex: "type",
            hideInTable: true
        },
        {
            title: "下载地址",
            dataIndex: "path",
            hideInSearch: true,
            hideInTable: true
        }, {
            title: "创建时间",
            dataIndex: "createTime",
            hideInSearch: true
        },
        {
            title: "操作",
            valueType: "option",
            render: (node, entity, index, action) => renderColumnOperations(node, entity, index, action)
        }
    ];

    const actionRef = useRef(null);
    const deleteRef = useRef(null);
    const createRef = useRef(null);
    const previewRef = useRef(null);
    const updateRef = useRef(null);

    const renderColumnOperations = (node, entity, index, action) => {
        return [
            <Tooltip title="查看" key="preview">
                <Button icon size="small" onClick={() => previewRef.current.show(entity.id)}>
                    <EyeOutlined/>
                </Button>
            </Tooltip>,
            <Tooltip title="更新" key="edit">
                <Button icon size="small" onClick={() => updateRef.current.show(entity.id)}>
                    <EditOutlined/>
                </Button>
            </Tooltip>,
            <Tooltip title="删除" key="delete">
                <Button icon danger size="small" onClick={() => deleteRef.current.show(entity.id, entity.name)}>
                    <DeleteOutlined/>
                </Button>
            </Tooltip>
        ];
    };

    const renderPageContainer = () => {
        return [
            <Button type="primary" key="create" onClick={() => createRef.current.show()}>更新版本</Button>
        ];
    };

    const reload = () => actionRef.current.reload();

    const requestPage = params => {
        return VersionApi.page(params).then(res => {
            return {data: res.data.records, success: true, total: res.data.total};
        }).catch(err => {
            console.error("Request account page failed", err);
            return {data: [], success: true, total: 0};
        });
    };

    return (<PageContainer header={{title: "APP更新", breadcrumb: {}}} extra={renderPageContainer()}>
        <ProTable
            headerTitle="历史版本"
            rowKey="id"
            defaultSize="small"
            request={requestPage}
            columns={columns}
            actionRef={actionRef}/>
        <CreateVersionUpdateDrawer ref={createRef} onSuccess={reload}/>
        <PreviewVersionUpdate ref={previewRef} onSuccess={reload}/>
        <DeleteVersionUpdate ref={deleteRef} onSuccess={reload}/>
        <EditVersionDrawer ref={updateRef} onSuccess={reload}/>
    </PageContainer>);
};

export default forwardRef(VersionUpdate);
