import React, {Component} from "react";
import {Button, Drawer, Select, Tooltip} from "antd";
import {ProTable} from "@ant-design/pro-components";
import {DeleteOutlined,EditOutlined, EyeOutlined} from "@ant-design/icons";

import EquipmentMapApi from "api/equipment/map";
import DeleteEquipmentModal from "../../list/modal/DeleteEquipmentModal";
import EditEquipmentDrawer from "../../list/drawer/EditEquipmentDrawer";
import PreviewEquipmentDrawer from "../../list/drawer/PreviewEquipmentDrawer";
import CustomerDepartmentPicker from "component/customer/department/CustomerDepartmentPicker";
import CustomerApi from "../../../../../api/customer/customer";

/**
 * 设备列表信息
 * 根据对应的设备id数组 渲染对应得到设备列表信息
 */
export default class PreviewMapTable extends Component {

    editEquipmentRef = React.createRef();
    deleteEquipmentRef = React.createRef();
    previewEquipmentRef = React.createRef();
    state = {
        drawerShow: false,
        drawerWidth: 600,
        lnglat: "",
        columnsStateMap: {
            createTime: {
                show: false
            }
        },
        customerDepartmentId:null,
        customersOptions: []
    };

    show = lnglat => {
        this.setState({
            drawerShow: true,
            drawerWidth: window.innerWidth < 576 ? window.innerWidth : window.innerWidth * 0.55,
            lnglat
        });
    };

    close = () => this.setState({drawerShow: false});

    onColumnStateMapChange = columnStateMap => this.setState({columnsStateMap: columnStateMap});

    renderColumnOperations = (node, entity, index, action) => {
        return [
            <Tooltip title="查看设备" key="preview">
                <Button icon
                        size="small"
                        onClick={() => this.previewEquipmentRef.current.show(entity.id)}>
                    <EyeOutlined/>
                </Button>
            </Tooltip>,
            <Tooltip title="编辑设备" key="edit">
                <Button icon
                        size="small"
                        onClick={() => this.editEquipmentRef.current.show(entity.id)}>
                    <EditOutlined/>
                </Button>
            </Tooltip>,
            <Tooltip title="删除设备" key="delete">
                <Button icon
                        danger
                        size="small"
                        onClick={() => this.deleteEquipmentRef.current.show(entity.id)}>
                    <DeleteOutlined/>
                </Button>
            </Tooltip>
        ];
    };

    columns = [
        {
            title: "ID",
            hideInSearch: true,
            dataIndex: "id",
            editable: false
        },
        {
            title: "设备名称",
            dataIndex: "name"
        },
        {
            title: "设备型号",
            dataIndex: "model"
        },
        {
            title: "客户",
            dataIndex: ["customer", "name"],
            hideInSearch: true
        },
        {
            title: "客户单位",
            dataIndex: ["customer", "department", "name"],
            hideInSearch: true
        },
        {
            title: "维护次数",
            dataIndex: "num",
            hideInSearch: true
        },
        {
            title: "客户单位",
            key: "customerDepartmentIds",
            hideInTable: true,
            renderFormItem: (_, {type, defaultRender, formItemProps, fieldProps}) => {
                return <CustomerDepartmentPicker name="customerDepartmentId"
                                                 renderOnlyTree
                                                 onChange={this.onCustomerDepartmentChange}/>;
            }
        },
        // {
        //     title: "客户",
        //     key: "customerId",
        //     hideInTable: true,
        //     renderFormItem: (_, {type, defaultRender, formItemProps, fieldProps}) => {
        //         return <Select options={this.state.customersOptions}
        //                        showSearch optionFilterProp="label"/>;
        //     }
        // },
        {
            title: "地区",
            dataIndex: "province",
            hideInSearch: true,
            hideInTable: true,
            valueType: "option",
            render: (node, entity) => entity.province === entity.city ? `${entity.province}-${entity.county}` : `${entity.province}-${entity.city}-${entity.county}`
        },
        // {
        //     title: "所在省",
        //     dataIndex: "province",
        //     hideInSearch: true
        // },
        // {
        //     title: "所在市",
        //     dataIndex: "city",
        //     hideInSearch: true
        // },
        // {
        //     title: "所在区县",
        //     dataIndex: "county",
        //     hideInSearch: true
        // },
        {
            title: "创建时间",
            dataIndex: "createTime",
            key: "createTime",
            hideInSearch: true
        },
        {
            title: "安装时间",
            dataIndex: "installTime",
            hideInSearch: true
        },
        {
            title: "保修时间",
            dataIndex: "warrantyPeriod",
            hideInSearch: true
        },
        {
            title: "操作",
            valueType: "option",
            render: (node, entity, index, action) => this.renderColumnOperations(node, entity, index, action)
        }
    ];

    onCustomerDepartmentChange = departmentId => {
        console.log("departmentId", departmentId)
        this.setState({customerDepartmentId:departmentId});
        // CustomerApi.list(departmentId).then(response => {
        //     const customerOptions = response.data.map(customer => ({
        //         label: `${customer.name}(${customer.cellphone})`,
        //         value: customer.id
        //     }));
        //     console.log("customerOptions", customerOptions)
        //     this.setState({ customersOptions: customerOptions }, this.forceUpdate);
        // }).catch(error => {
        //     console.warn("Get customers failed", error);
        // });

        // CustomerApi.list({departmentId}).then(response => {
        //     const customerOptions = [];
        //     if (!!response.data && response.data.length > 0) {
        //         response.data.forEach((customer) => {
        //             customerOptions.push(this.handleCustomerOption(customer));
        //         });
        //     }
        //     this.setState({customersOptions: customerOptions},()=>{
        //         console.log(this.state.customersOptions)
        //         this.forceUpdate()
        //     });
        // }).catch(error => {
        //     console.warn("Get customers failed", error);
        //
        // });

    };
    handleCustomerOption = customer => {
        return {
            label: `${customer.name}(${customer.cellphone})`,
            value: customer.id
        };
    };

    toolbarRender = () => <Button type="primary" onClick={() => this.createEquipmentRef.current.show()}>新建设备</Button>;

    requestPage = (data) => {
        data.lnglats = this.state.lnglat;
        data.customerDepartmentId = this.state.customerDepartmentId;
        return EquipmentMapApi.getLnglatEquipment(data).then(response => {
            return {data: response.data.records, success: true, total: response.data.total};
        }).catch(error => {
            console.warn("Request get equipment map pages failed.", error);
        });
    };

    reload = () => this.actionRef?.reload();

    render() {
        return <Drawer title="设备信息列表" width={this.state.drawerWidth} onClose={this.close}
                       open={this.state.drawerShow}
                       destroyOnClose>
            <ProTable headerTitle="设备列表"
                      rowKey="id"
                      defaultSize="small"
                      options={{reload: true, setting: true, density: true}}
                      actionRef={actionRef => this.actionRef = actionRef}
                      columns={this.columns}
                      columnsState={this.state.columnsStateMap}
                      onColumnsStateChange={this.onColumnStateMapChange}
                      request={this.requestPage}/>
            <DeleteEquipmentModal onSuccess={this.reload} ref={this.deleteEquipmentRef}/>
            <EditEquipmentDrawer onSuccess={this.reload} ref={this.editEquipmentRef}/>
            <PreviewEquipmentDrawer onSuccess={this.reload} ref={this.previewEquipmentRef}/>
        </Drawer>;
    }
}
