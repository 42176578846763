import configJson from '../config.json'
import RtcApi from "../api/user/rtc";

export const request = (url, params) => {
  let res;
  return new Promise((resolve, reject) => {
    fetch(formmatUrl(url, params), {
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        res = response;
        if (response.ok) {
          return response.json();
        } else {
          return response.text();
        }
      })
      .then((data) => {
        if (res?.ok) {
          if (data.code) {
            reject(data);
          } else {
            resolve(data.data);
          }
        } else {
          reject(data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const formmatUrl = (url, params) => {
  if (!params) return url;
  const args = Object.keys(params).map(key => `${key}=${params[key]}`).join('&');
  return `${url.includes('?') ? url : `${url}?`}${args}`;
};

const token = configJson.token || '';

export const getAppToken = async (
  userId,
  appId,
  channelId,
) => {
  if (token) {
    return {
      token,
    }
  }
  const loginParam = {
    channelId,
    appId,
    userId,
    appKey: configJson.appKey,
  };
  //const result = await request(`${global.config.url}/user/rtc/token`, loginParam);
  const result = await RtcApi.getToken(loginParam);
  console.log('result:',result)
  return result;
};
