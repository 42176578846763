import React, {Component} from "react";
import {Button, Col, Row, Space, Typography} from "antd";

import DraggableModal from "component/DraggableModal";
import StorehouseRecordApi from "api/spares/record";

class RejectRevertModal extends Component {

    draggableRef = React.createRef();

    state = {
        loading: false,
        revertId: ""
    };

    toggleLoading = () => this.setState({loading: !this.state.loading});

    show = (id) => {
        this.draggableRef.current.show();
        this.setState({revertId: id});
    };

    close = () => {
        this.draggableRef.current.close();
    };

    handle = () => {
        this.toggleLoading();
        StorehouseRecordApi.rejectRevert(this.state.revertId).then(res => {
            this.toggleLoading();
            this.props.onSuccess();
            this.close();
            console.log(res);
        }).catch(err => {
            this.toggleLoading();
            console.error("Reject schedule failed", err);
        });
    };

    render() {
        return (<DraggableModal onCancel={this.close} title="驳回" ref={this.draggableRef}>
            <Typography>驳回退还请求</Typography>
            <Row gutter={8} justify="end">
                <Col>
                    <Space>
                        <Button disabled={this.state.loading} onClick={this.close}>取消</Button>
                        <Button loading={this.state.loading} type="primary" onClick={this.handle}>驳回</Button>
                    </Space>
                </Col>
            </Row>
        </DraggableModal>);
    }
}

export default RejectRevertModal;
