import React from "react";
import {Button, Col, Row, Space} from "antd";

import DraggableModal from "component/DraggableModal";
import {ProForm, ProFormCascader, ProFormText} from "@ant-design/pro-components";
import MonitoringApi from "../../../../../api/monitoring";
import styles from "../index.module.css";

/**
 * 创建
 * 创建监控列表
 * @author xuwei
 */
export default class CreateDisplaceModal extends React.Component {

    state = {
        loading: false,
        show: false,
        areaData: [],
    };

    draggableRef = React.createRef();
    formRef = React.createRef();
    /**
     * 控制对话框显示隐藏
     */
    show = id => {
        this.draggableRef.current.show();

    };

    close = () => {
        this.setState({loading: false});
        this.draggableRef.current.close();
    };

    /**
     * 控制 loading 加载显示
     */
    toggleLoading = () => {
        this.setState({
            loading: !this.state.loading
        });
    };

    /**
     * 区县选择变化触发
     * @param value
     */
    areaChange = value => {
        this.setState({areaData: value});
    };

    /**
     * 提交
     */
    handle = () => {
        this.toggleLoading();
        if(this.formRef.current){
            let { ares,count } = this.formRef.current.getFieldsValue();
            let data = {
                count:Number(count),
                province: ares[0],
                city: ares[1],
                county: ares[2],

            }
            MonitoringApi.create(data).then(response => {
                if(response.data){
                    this.close();
                    this.props.onSuccess();
                }
            })
        }

    };

    render() {
        return <DraggableModal onCancel={this.close} title="新建监控区域" ref={this.draggableRef}>
            <ProForm formRef={this.formRef} onFinish={this.handle} layout={"vertical"}
                     destroyOnClose={true} submitter={{
                render: (props, dom) => <div className={styles.submitButbox}>
                    <Space>
                        <Button disabled={this.state.loading} onClick={this.close}>取消</Button>
                        <Button loading={this.state.loading} type="primary" htmlType="submit">提交</Button>
                    </Space>
                </div>
            }}
            >
                <ProFormCascader required rules={[{required: true, message: "请选择所在地区"}]} name="ares"
                                 label="机构区域"
                                 fieldProps={{options: require("asset/location.json"), onChange: this.areaChange}}/>
                <ProFormText name="count" label="现存设备数量" placeholder="请输入数量" required
                             rules={[
                                 {required: true, message: "请输入数量"},
                                 {pattern: /\S/, message: "不能为空"},
                                 {pattern: /^[0-9]*$/, message: "请输入数字"}
                             ]}/>
            </ProForm>

        </DraggableModal>;
    }
}
