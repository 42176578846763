import React from "react";
import moment from "moment";
import {Button, Col, Row, Space} from "antd";
import {DownOutlined as DownOutlinedIcon, UpOutlined as UpOutlinedIcon} from "@ant-design/icons";
import WorkorderApi from "api/workorder";
import {ProForm, ProFormDatePicker} from "@ant-design/pro-components";
import {setFieldsError} from "plugin/formily";
import DraggableModal from "component/DraggableModal";


/**
 * 接收工单弹窗 Modal
 *
 * @description 接收工单
 *
 * @author huang Carder
 * @since 2021-06-23
 * @version 1.0.0
 */
export default class ReceiveWorkorderDialog extends React.Component {

    state = {
        loading: false,
        show: false,
        modificationFrom: false
    };

    draggableRef = React.createRef();
    formRef = React.createRef();

    /**
     * 控制驳回工单对话框显示隐藏
     */

    show = () => this.draggableRef.current.show();

    close = () => {
        this.setState({loading: false});
        this.draggableRef.current.close();
    };

    /**
     * 控制 loading加载效果 显示
     */
    toggleLoading = () => this.setState({loading: !this.state.loading});

    /**
     * 接收工单
     * @param data 表单数据
     */
    handle = data => {
        this.toggleLoading();
        data.workorderId = this.props.workorderId;
        WorkorderApi.receive(data).then(response => {
            console.info("Receive workorder success", response);
            this.close();
            if (!!this.props.onSuccess) {
                this.props.onSuccess();
            }
        }).catch(error => {
            this.toggleLoading();
            console.warn("Receive workorder failed", error);
            setFieldsError(error,this.formRef);
        });
    };

    /**
     * 控制修改计划时间表单显示
     */
    alterTime = (e) => {
        e.preventDefault();
        this.setState({
            modificationFrom: !this.state.modificationFrom
        });
    };

    disabledDate(current) {
        return current && current < moment().subtract(1, "days");
    }

    trigger = () => <Button onClick={this.show}>接收</Button>;

    dialog = () => {
        return <DraggableModal onCancel={this.close} title="接收工单" ref={this.draggableRef}>
            <ProForm formRef={this.formRef} onFinish={this.handle} layout={"vertical"} submitter={false}>
                <ProFormDatePicker name="appointmentTime" label="预约时间"
                                   fieldProps={{
                                       showTime: true,
                                       inputReadOnly: true,
                                       format: "YYYY-MM-DD HH:mm",
                                       disabledDate: this.disabledDate
                                   }}
                                   required
                                   rules={[{required: true, message: "请选择预约时间"}]}/>
                <span>计划时间：</span>{this.props.deadLine}
                <br/>
                <p onClick={this.alterTime}>
                    如需修改请点击
                    {
                        this.state.modificationFrom ? <UpOutlinedIcon/> : <DownOutlinedIcon/>
                    }
                </p>
                {
                    this.state.modificationFrom ? <ProFormDatePicker name="deadLine" label="修改计划时间"
                                                                     fieldProps={{
                                                                         showTime: true,
                                                                         disabledDate: this.disabledDate,
                                                                         inputReadOnly: true,
                                                                         format: "YYYY-MM-DD HH:mm"
                                                                     }}/> : ""
                }
                <Row gutter={8} justify="end">
                    <Col>
                        <Space>
                            <Button disabled={this.state.loading} onClick={this.close}>取消</Button>
                            <Button loading={this.state.loading} type="primary" htmlType="submit">接收</Button>
                        </Space>
                    </Col>
                </Row>
            </ProForm>
        </DraggableModal>;
    };

    render() {
        return <>
            {this.trigger()}
            {this.dialog()}
        </>;
    }
}
