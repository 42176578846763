import React from "react";
import {Button, DatePicker, Space, Tooltip} from "antd";
import {DeleteOutlined, EyeOutlined} from "@ant-design/icons";
import {PageContainer, ProTable} from "@ant-design/pro-components";

import SecurityInterceptor from "component/SecurityInterceptor";

import DeletePrepareWorkorderModal from "./modal/DeletePrepareWorkorderModal";
import PreviewPrepareWorkorderDrawer from "./drawer/PreviewPrepareWorkorderDrawer";
import moment from "moment";
import dayjs from "dayjs";


/**
 * 保修池
 *
 * @description 保修池
 *
 * @author Mae Carder
 * @since 2021-10-28
 * @version 1.0.0
 */
export default class PrepareWorkordersPage extends React.Component {

    state = {
        columnStateMap: {
            priority: {
                show: false
            },
            appointmentTime: {
                show: false
            },
            deadLine: {
                show: false
            }
        }
    };

    actionRef;
    deleteRef = React.createRef();
    previewRef = React.createRef();

    columns = [
        {
            title: "ID",
            dataIndex: "id",
            editable: false,
            copyable: true,
            hideInSearch: true
        },
        {
            title: "客户姓名",
            dataIndex: "clientName"
        },
        {
            title: "手机号码",
            dataIndex: "clientCellphone"
        },
        {
            title: "创建时间",
            dataIndex: "createTime",
            hideInSearch: true
        },
        {
            title: "修改时间",
            dataIndex: "modifyTime",
            hideInSearch: true
        },
        {
            title: "提交 IP 地址",
            dataIndex: "remoteAddress",
            hideInSearch: true
        },
        {
            title: "工单描述",
            dataIndex: "description",
            ellipsis: true,
            hideInSearch: true
        },
        {
            title: "时间选择",
            key: "timer",
            hideInTable: true,
            renderFormItem: (_, {type, defaultRender, formItemProps, fieldProps}) => {
                return (
                    <DatePicker.RangePicker {...fieldProps} inputReadOnly/>
                );
            }
        },
        // {
        //     title: "是否已转工单",
        //     dataIndex: "converted",
        //     valueEnum: require("./assets/WorkorderPrepare").default,
        //     hideInSearch: true
        // },
        {
            title: "操作",
            valueType: "option",
            render: (node, entity, index, action) => this.renderColumnOperations(node, entity, index, action)
        }
    ];

    continue = id => this.continueRef.current.show(id);

    renderColumnOperations = (node, entity, index, action) => {
        return <SecurityInterceptor role={["admin", "customer-service"]}>
            <Space>
                <Tooltip title="查看">
                    <Button icon size="small" onClick={() => this.dialog(entity.id, this.previewRef)}>
                        <EyeOutlined/>
                    </Button>
                </Tooltip>
                <Tooltip title="删除">
                    <Button danger icon size="small" onClick={() => this.dialog(entity.id, this.deleteRef)}>
                        <DeleteOutlined/>
                    </Button>
                </Tooltip>
            </Space>
        </SecurityInterceptor>;
    };

    dialog = (id, ref) => ref.current.show(id);

    /**
     * 请求表格数据
     * @param params
     */
    requestPage = params => {
        params.converted = 0;
        if (!!params.timer) {
            params.createStartTime = dayjs(params.timer[0]).format("YYYY-MM-DD");
            params.createEndTime = dayjs(params.timer[1]).format("YYYY-MM-DD");
            params.timer = undefined;
        }
        return global.axios.get("/workorder/workorder/prepares/page", {params}).then(response => {
            return {data: response.data.records, success: true, total: response.data.total};
        }).catch(error => {
            console.error("Find workorder page failed", error);
            return {data: [], success: true, total: 0};
        });
    };

    reload = () => this.actionRef.reload();

    onColumnStateMapChange = columnStateMap => this.setState({columnStateMap});

    render() {
        return <PageContainer header={{title: "报修池", breadcrumb: {}}}>
            <ProTable
                options={{reload: true, setting: true, density: true}}
                defaultSize="small"
                rowKey="id"
                onColumnsStateChange={this.onColumnStateMapChange}
                actionRef={actionRef => this.actionRef = actionRef}
                columns={this.columns}
                request={this.requestPage}/>

            <PreviewPrepareWorkorderDrawer onSuccess={this.reload} ref={this.previewRef}/>
            <DeletePrepareWorkorderModal onSuccess={this.reload} ref={this.deleteRef}/>
        </PageContainer>;
    }
}
