const RoleApi = {
    //分页
    page: (params) => global.axios.get("/user/roles/page", {params}),

    //创建
    create: (params) => global.axios.post("/user/role", params),

    //编辑
    edit: (params) => global.axios.patch("/user/role", params),

    //删除
    delete: (id) => global.axios.delete(`/user/role/${id}`),

    //删除多个
    deletes: (params) => global.axios.delete("/user/roles", {data: params}),

    //设置权限
    setPermission: (params) => global.axios.put("/user/role-permissions", params),

    //获取用户角色权限
    getRolePermission: (params) => global.axios.get("/user/role-permissions", params),

    //获取用户权限
    getPermission: (params) => global.axios.get("/user/permissions", params)

};
export default RoleApi;

