import React, {forwardRef, useImperativeHandle, useRef, useState} from "react";
import {Button, Col, Drawer, Form, message, Row, Space} from "antd";
import {ProForm, ProFormDigit, ProFormRadio, ProFormSelect, ProFormText} from "@ant-design/pro-components";

import FileUploadRender from "component/FileUploadRender";
import WangEditor from "component/editor/WangEditor";
import VersionApi from "api/user/version";

const EditVersionDrawer = (props, ref) => {

    const formRef = useRef(null);
    const filesRef = useRef(null);
    const WangEditorRef = useRef(null);

    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [versionId, setVersionId] = useState(false);
    const [isUploadApk, setIsUploadApk] = useState(true);

    useImperativeHandle(ref, () => ({
        show
    }));

    const show = (id) => {
        setVisible(true);
        setVersionId(id);
        requestVersionInfo(id);
    };

    const close = () => setVisible(false);

    const toggleLoading = () => setLoading(!loading);

    /**
     * 文件上传状态
     * @param list
     */
    const uploadOnChange = (list) => {
        if (list[0].status === "uploading") {
            setLoading(true);
        } else {
            setLoading(false);
        }
    };

    /**
     * 平台状态改变
     * @param value
     */
    const platformOnChange = (value) => value === "android" ? setIsUploadApk(true) : setIsUploadApk(false);

    /**
     * 获取版本信息
     * @param versionId
     */
    const requestVersionInfo = (versionId) => {

        VersionApi.find(versionId).then(res => {

            res.data.platform === "android" ? setIsUploadApk(true) : setIsUploadApk(false);

            formRef.current.setFieldsValue({
                path: res.data.path,
                versionName: res.data.versionName,
                versionSerial: res.data.versionSerial,
                platform: res.data.platform,
                necessary: res.data.necessary
            });

            WangEditorRef.current.setValue(res.data.description);

            if (res.data.platform === "android") {
                filesRef.current.setFilesValue([{
                    name: "泰狐服务",
                    url: res.data.path,
                    thumbUrl: res.data.path
                }]);
            }

        }).catch(err => {
            setLoading(false);
            console.error("Request version info failed", err);
        });
    };

    /**
     * 更新
     * @param data
     */
    const handle = data => {
        let Wangdata = WangEditorRef.current.getValue().replace(/<\/?[^>]*>/g, "").replace(/[|]*\n/, "");
        console.log(Wangdata)
        if (!Wangdata) {
            formRef.current.setFields([{name: "description", errors: ["请输入描述信息"]}]);
            return;
        }else {
            formRef.current.setFields([{name: "description", errors: [""]}]);
        }

        if (filesRef.current !== null && filesRef.current.getFilesValue().length === 0 && isUploadApk) {
            formRef.current.setFields([{name: "path", errors: ["请上传安装包"]}]);
            return;
        }

        if (isUploadApk) {
            data.path = filesRef.current.getFilesValue()[0];
        }

        data.description = WangEditorRef.current.getValue();

        data.id = versionId;

        toggleLoading();
        VersionApi.update(data).then(res => {
            toggleLoading();
            props.onSuccess();
            message.success('更新成功！');
            close();
        }).catch(err => {
            setLoading(false);
            console.error("Update version failed", err);
        });
    };

    return (<Drawer title="更新版本信息" open={visible} onClose={close} destroyOnClose
                    width={window.innerWidth < 576 ? window.innerWidth : window.innerWidth * 0.55}>
        <ProForm formRef={formRef} onFinish={handle} layout={"vertical"} onReset={close}>
            <ProFormText name="versionName" label="版本名称" rules={[{required: true}]}/>
            <ProFormDigit
                label="版本序号"
                name="versionSerial"
                min={1}
                fieldProps={{precision: 0}}
                rules={[{required: true}]}/>
            <ProFormSelect name="platform" label="平台" rules={[{required: true}]}
                           options={[{label: "安卓", value: "android"}, {label: "apple", value: "ios"}, {
                               label: "web",
                               value: "web"
                           }]}
                           fieldProps={{onChange: platformOnChange}}/>
            <ProFormRadio.Group name="necessary" label="是否强制更新" rules={[{required: true}]} initialValue={1}
                                options={[{label: "是", value: 1}, {label: "否", value: 0}]}/>
            {
                isUploadApk ?
                    <FileUploadRender name="path" label="下载地址" uploadPath="/general/workorder/unassigned/file" required
                                      uploadProps={{maxCount: 1, accept: ".apk"}}
                                      ref={filesRef} onChange={uploadOnChange}/> :
                    <ProFormText name="path" label="下载地址" rules={[{required: true}]}/>
            }
            <Form.Item name="description" label="描述" required>
                <WangEditor ref={WangEditorRef}/>
            </Form.Item>
        </ProForm>
    </Drawer>);
};

export default forwardRef(EditVersionDrawer);
