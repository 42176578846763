import React, {Component} from "react";
import {Button, Col, Row, Skeleton, Space} from "antd";
import {ProForm, ProFormSelect} from "@ant-design/pro-components";

import DraggableModal from "component/DraggableModal";

import AccountApi from "api/user/account";
import DepartmentApi from "api/user/department";

class SetUserDepartmentManagerModal extends Component {

    draggableRef = React.createRef();
    formRef = React.createRef();

    state = {
        departmentManager: [],
        departmentAccountLoading: true,
        loading: false
    };

    show = (id) => {
        this.draggableRef.current.show();
        this.requestDepartmentAccount(id);
    };

    close = () => {
        this.draggableRef.current.close();
    };

    toggleLoading = () => this.setState({loading: !this.state.loading});

    requestDepartmentAccount = (id) => {
        DepartmentApi.getDepartmentAccount(id).then(res => {
            this.handleDepartmentAccount(res.data);
            this.setState({departmentAccountLoading: false});
        }).catch(err => {
            console.error("Request department account failed", err);
        });
    };

    handleDepartmentAccount = data => {
        if (data !== undefined && data !== null && !!data) {
            const accountData = [];
            data.forEach(item => {
                accountData.push({label: item.name, value: item.id});
            });
            this.setState({departmentManager: accountData});
        }
    };

    handle = data => {
        data.roleIds = [5];
        this.toggleLoading();
        AccountApi.addRole(data).then(res => {
            this.toggleLoading();
            this.close();
        }).catch(err => {
            this.toggleLoading();
            console.error("Set department manager failed", err);
        });
    };

    render() {
        return (<DraggableModal onCancel={this.close} title="设置机构管理员" ref={this.draggableRef}>
                <Skeleton active loading={this.state.departmentAccountLoading}>
                    <ProForm formRef={this.formRef} onFinish={this.handle} layout={"vertical"} submitter={{
                        resetButtonProps: {style: {display: "none"}},
                        submitButtonProps: {style: {display: "none"}}
                    }}>
                        <ProFormSelect name="accountId" label="选择用户" options={this.state.departmentManager}
                                       placeholder="请选择要设置的用户" rules={[{required: true, message: "请选择设置用户"}]}
                                       fieldProps={{showSearch: true}}/>
                        <Row gutter={8} justify="end">
                            <Col>
                                <Space>
                                    <Button disabled={this.state.loading} onClick={this.close}>取消</Button>
                                    <Button loading={this.state.loading} type="primary" htmlType="submit">设置</Button>
                                </Space>
                            </Col>
                        </Row>
                    </ProForm>
                </Skeleton>
            </DraggableModal>
        );
    }
}

export default SetUserDepartmentManagerModal;
