import React, {Component} from "react";
import {ProDescriptions, ProTable} from "@ant-design/pro-components";
import {Button, Divider, Skeleton, Space} from "antd";

import StorehouseRecordApi from "api/spares/record";
import StorehouseApi from "api/spares/storehouse";

import ReceiveScheduleModal from "../modal/ReceiveScheduleModal";
import RejectScheduleModal from "../modal/rejectScheduleModal";
import AccountApi from "api/user/account";

class ScheduleInputRepository extends Component {

    receiveScheduleRef = React.createRef();
    rejectScheduleRef = React.createRef();

    columns = [
        {
            title: "类型",
            dataIndex: ["stocktype", "model"],
            editable: false,
            hideInSearch: true
        },
        {
            title: "数量",
            dataIndex: "count",
            hideInSearch: true
        }
    ];

    state = {
        scheduleRecord: {},
        repositoryOutputRecord: {},
        stocks: [],
        loading: true,
        targetStorehouseLoading: true,
        skeletonLoading: true,
        refreshTable: false,
        originAuditor: "-",
        targetAuditor: "-"
    };

    componentDidMount() {
        this.requestScheduleRecord(this.props.scheduleId);
    }

    requestScheduleRecord = (id) => {
        StorehouseRecordApi.scheduleRepository(id).then(res => {
            this.requestRepositoryOutputRecord(res.data.originOutput);
            if (!!res.data.originAuditor) {
                this.requestOriginAuditor(res.data.originAuditor)
            }
            if (!!res.data.targetAuditor) {
                this.requestTargetAuditor(res.data.targetAuditor)
            }
            this.setState({scheduleRecord: res.data, loading: false});
        }).catch(err => {
            this.setState({loading: false});
            console.error("Request schedule record failed", err);
        });
    };

    requestRepositoryOutputRecord = (id) => {
        StorehouseRecordApi.repositoryOutput(id).then(res => {
            this.setState({repositoryOutputRecord: res.data, stocks: res.data.stocks});
        }).catch(err => {
            console.error("Request repository output record failed", err);
        });
    };

    requestOriginAuditor = (id) => {
        AccountApi.findUserInfo({params: {accountId: id}}).then(res => {
            this.setState({originAuditor: res.data.name});
        }).catch(err => {
            console.error("Request account info failed", err);
            return "";
        });
    };

    requestTargetAuditor = (id) => {
        AccountApi.findUserInfo({params: {accountId: id}}).then(res => {
            this.setState({targetAuditor: res.data.name});
        }).catch(err => {
            console.error("Request account info failed", err);
            return "";
        });
    };

    requestOriginStorehouseInfo = () => {
        return StorehouseApi.find(this.state.scheduleRecord.originRepository).then(res => {
            return {success: true, data: res.data};
        }).catch(err => {
            console.error("Request origin storehouse info failed", err);
        });
    };

    requestTargetStorehouseInfo = () => {
        return StorehouseApi.find(this.state.scheduleRecord.targetRepository).then(res => {
            return {success: true, data: res.data};
        }).catch(err => {
            console.error("Request target storehouse info failed", err);
        });
    };

    renderOptions = () => {
        const renderReceive = this.state.scheduleRecord.audit === 1;
        const renderReject = this.state.scheduleRecord.audit === 1 && this.state.scheduleRecord.audit !== 5;
        return [
            <Space key="topButton">
                {!!renderReceive ?
                    <Button type="primary"
                            onClick={() => this.receiveScheduleRef.current.show(this.props.scheduleId)}>接收</Button> :
                    null}
                {!!renderReject ?
                    <Button danger
                            onClick={() => this.rejectScheduleRef.current.show(this.props.scheduleId)}>驳回</Button> :
                    null}
            </Space>
        ];
    };

    renderProgress = (key) => {
        const actions = {
            1: "待审核",
            5: "审核通过",
            99: "已驳回"
        };
        return actions[key];
    };

    reloadSchedule = () => this.state.refreshTable;

    reload = () => {
        this.requestScheduleRecord(this.props.scheduleId);
        this.setState({refreshTable: true});
    };

    render() {
        return (<>
                <ProDescriptions bordered size="small" layout="horizontal"
                                 column={window.innerWidth < 800 ? 1 : 4}
                                 extra={this.renderOptions()}
                                 loading={this.state.loading}>
                    <ProDescriptions.Item label="类型" span={2}>
                        调拨入库
                    </ProDescriptions.Item>
                    <ProDescriptions.Item label="进度" span={2}>
                        {this.renderProgress(this.state.scheduleRecord.audit)}
                    </ProDescriptions.Item>
                    <ProDescriptions.Item label="调拨说明" span={4}>
                        {this.state.scheduleRecord.comment}
                    </ProDescriptions.Item>
                </ProDescriptions>

                <Skeleton active loading={this.state.loading}>
                    <ProDescriptions bordered size="small" layout="horizontal"
                                     column={window.innerWidth < 800 ? 1 : 4}
                                     request={this.requestOriginStorehouseInfo}>
                        <ProDescriptions.Item label="源仓库" span={4} dataIndex={["department", "name"]}/>
                        <ProDescriptions.Item label="源单位" span={4} dataIndex="name"/>
                        <ProDescriptions.Item label="调拨人" span={4}>
                            {this.state.originAuditor}
                        </ProDescriptions.Item>
                    </ProDescriptions>

                    <ProDescriptions bordered size="small" layout="horizontal"
                                     column={window.innerWidth < 800 ? 1 : 4}
                                     request={this.requestTargetStorehouseInfo}>
                        <ProDescriptions.Item label="目标仓库" span={4} dataIndex={["department", "name"]}/>
                        <ProDescriptions.Item label="目标单位" span={4} dataIndex="name"/>
                        <ProDescriptions.Item label="审核人" span={4}>
                            {this.state.targetAuditor}
                        </ProDescriptions.Item>
                    </ProDescriptions>
                </Skeleton>


                <Divider/>

                <ProTable headerTitle="调拨物资列表"
                          search={false}
                          rowKey="id"
                          defaultSize="small"
                          pagination={false}
                          options={false}
                          columns={this.columns}
                          dataSource={this.state.stocks}/>
                <ReceiveScheduleModal onSuccess={this.reload} ref={this.receiveScheduleRef}/>
                <RejectScheduleModal onSuccess={this.reload} ref={this.rejectScheduleRef}/>
            </>
        );
    }
}

export default ScheduleInputRepository;
