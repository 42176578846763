import React, {Component} from "react";

import CustomerDepartmentApi from "api/customer/department";
import {Descriptions, Skeleton} from "antd";
import DraggableModal from "component/DraggableModal";

/**
 * 客户单位详情预览弹窗
 *
 * @description 客户单位详情预览
 */
class PreviewCustomerDepartmentModal extends Component {

    state = {
        previewDepartmentDetailDialog: false,
        departmentInfo: {},
        departmentName: "",
        departmentInfoLoading: true
    };

    draggableRef = React.createRef();

    show = (id, name) => {
        this.draggableRef.current.show();
        this.setState({
            departmentName: name
        }, () => {
            this.getDepartmentDetail(id);
        });
    };

    close = () => this.draggableRef.current.close();

    getDepartmentDetail = (id) => {
        CustomerDepartmentApi.findDepartment(id).then(response => {
            this.setState({
                departmentInfo: response.data,
                departmentInfoLoading: false
            });
        }).catch(error => {
            this.setState({departmentInfoLoading: false});
            console.error("Request department detail failed.");
        });
    };

    render() {
        return <DraggableModal onCancel={this.close} title={`${this.state.departmentName}的单位信息`}
                               ref={this.draggableRef}>
            <Skeleton loading={this.state.departmentInfoLoading}>
                <Descriptions
                    bordered
                    column={1}>
                    <Descriptions.Item label="单位名称">
                        {this.state.departmentInfo.name}
                    </Descriptions.Item>
                    <Descriptions.Item label="单位区域">
                        {`${this.state.departmentInfo.province} - ${this.state.departmentInfo.city} - ${this.state.departmentInfo.county}`}
                    </Descriptions.Item>
                    <Descriptions.Item label="单位详细地址">
                        {this.state.departmentInfo.address}
                    </Descriptions.Item>
                </Descriptions>
            </Skeleton>
        </DraggableModal>;
    }
}

export default PreviewCustomerDepartmentModal;
