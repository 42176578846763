const AssignedWorkorderApi = {
    /**
     * 获取待指派工单页面数据
     * @param 请求参数
     */
    page: params => global.axios.get(`/workorder/unassigned-workorders/page`, {params}),

    /**
     * 指派工单
     * @param workorderId
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    dispatch: (workorderId, data) => global.axios.post(`/workorder/unassigned-workorder/${workorderId}/handler`, data),

    /**
     * 查询待指派工单
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    find: id => global.axios.get(`/workorder/unassigned-workorder/${id}`)
};

export default AssignedWorkorderApi;
