import React, {Component} from "react";
import {ProTable} from "@ant-design/pro-components";
import {Button, Col, Row, Space} from "antd";

import DraggableModal from "component/DraggableModal";
import StorehouseInventoryApi from "api/spares/storehouse/inventory";
import EquipmentMakerApi from "api/equipment/maker";

class ChooseSparesModal extends Component {

    state = {
        repositoryId: "",
        loading: false,
        pagingFlag: true,
        sparesData: [],
        stocksIds: [],
        alreadyStocks: []
    };

    draggableRef = React.createRef();

    columns = [
        {
            title: "型号",
            dataIndex: ["stocktype", "model"],
            hideInSearch: true
        },
        {
            title: "类型名称",
            dataIndex: ["stocktype", "name"]
        },
        {
            title: "数量",
            dataIndex: "count",
            hideInSearch: true
        },
        {
            title: "操作",
            valueType: "option",
            render: (node, entity, index, action) => this.renderColumnOperations(node, entity, index, action)
        }
    ];

    renderColumnOperations = (node, entity, index, action) => (
        <Space>
            <Button size="small" type="link"
                    disabled={this.state.loading || this.state.stocksIds.includes(entity.stocktype.id) || entity.count == 0}
                    onClick={() => this.choose(entity)}>
                选择
            </Button>
        </Space>
    );

    choose = (entity) => {
        StorehouseInventoryApi.revertCount(this.state.repositoryId, {stocktype: entity.stocktype.id}).then(res => {
            // entity.count = entity.count - res.data;
            this.requestManufacturer(entity.stocktype.manufacturer, entity);
        }).catch(err => {
            console.error("Request solicit count failed", err);
        });
    };

    show = (id, stocks, alreadyStocks = [], pagingFlag = true) => {
        this.draggableRef.current.show();
        const stocksIds = [];
        stocks.forEach(item => stocksIds.push(item.id));
        this.setState({repositoryId: id, stocksIds, alreadyStocks, pagingFlag});
    };

    close = () => {
        this.draggableRef.current.close();
        this.setState({sparesData: []});
    };

    onTableChange = (keys, rows) => {
        rows.forEach(item => {
            this.setState({
                sparesData: [...this.state.sparesData, {
                    id: item.stocktype.id,
                    model: item.stocktype.model,
                    stocktype: item.stocktype.id,
                    stocktypeName: item.stocktype.name,
                    classified: item.stocktype.classified,
                    count: 1,
                    total: item.count,
                    manufacturer: item.stocktype.manufacturer,
                    manufacturerName: 1
                }]
            });
        });
    };

    requestManufacturer = (id, entity) => {
        this.setState({loading: true});
        EquipmentMakerApi.info(id).then(res => {
            this.props.onSuccess({
                id: entity.stocktype.id,
                model: entity.stocktype.model,
                stocktype: entity.stocktype.id,
                stocktypeName: entity.stocktype.name,
                classified: entity.stocktype.classified,
                count: 1,
                total: entity.count,
                manufacturer: entity.stocktype.manufacturer,
                manufacturerName: res.data.name
            });
            this.setState({loading: false});
            this.close();
        }).catch(err => {
            this.setState({loading: false});
            console.error("Request manufacture failed", err);
        });
    };

    requestPage = (params) => {
        if (!!this.state.pagingFlag) {
            params.repository = this.state.repositoryId;
            return StorehouseInventoryApi.page(params).then(res => {
                return {data: res.data.records, total: res.data.total, success: true};
            }).catch(err => {
                return {data: [], total: 0, success: true};
            });
        } else {
            return {data: this.state.alreadyStocks, total: this.state.alreadyStocks.length, success: true};
        }

    };

    render() {
        return (<DraggableModal onCancel={this.close} title={this.props.title || "选择领用备件"} ref={this.draggableRef}>
                <ProTable headerTitle="当前仓库库存"
                          rowKey="id"
                          defaultSize="small"
                          search={false}
                          options={false}
                          columns={this.columns}
                          pagination={this.state.pagingFlag ? {defaultPageSize: 10} : false}
                          request={this.requestPage}/>
                <Row justify="end">
                    <Col>
                        <Space>
                            <Button disabled={this.state.loading} onClick={this.close}>取消</Button>
                        </Space>
                    </Col>
                </Row>
            </DraggableModal>
        );
    }
}

export default ChooseSparesModal;
