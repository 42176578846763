import React, {Component} from "react";
import EquipmentListApi from "api/equipment/list";
import {Button, Col, DatePicker, Drawer, Form, Input, message, Row, Select, Space} from "antd";
import {setFieldsError} from "plugin/formily";
import FileUploadRender from "component/FileUploadRender";
import dayjs from "dayjs";

/**
 * 编辑设备
 * 设备类型接口 获取设备类型
 * 填写表单  调用编辑设备类型接口 更新设备
 */
class EditEquipmentDrawer extends Component {
    state = {
        show: false,
        loading: false,
        drawerWidth: 600,
        customerDepartmentsOptions: [],
        customersOptions: [],
        loadingCustomerDepartmentsOptions: false,
        loadingCustomersOptions: false,
        areaData: [],
        equipmentId: "",
        equipmentTypeData: []
    };

    creatDepartmentRef = React.createRef();
    formRef = React.createRef();
    filesRef = React.createRef();

    show = id => this.setState({
        show: true,
        equipmentId: id,
        drawerWidth: window.innerWidth < 576 ? window.innerWidth : window.innerWidth * 0.55
    }, () => {
        this.getEquipmentType();
        this.getEquipmentData(id);
    });

    close = () => this.setState({
        show: false,
        loading: false
    });

    toggleLoading = () => this.setState({loading: !this.state.loading});

    getEquipmentData = id => {
        EquipmentListApi.info(id).then(response => {
            console.log(response.data.installTime)
            console.log(response.data.warrantyPeriod)
            this.formRef.current.setFieldsValue({
                sn: response.data.sn,
                deviceTypeId: response.data.deviceType.id,
                manufacturerId: response.data.manufacturerId,
                installTime: response.data.installTime ? dayjs(response.data.installTime, "YYYY-MM-DD") : null,
                warrantyPeriod: response.data.warrantyPeriod ? dayjs(response.data.warrantyPeriod, "YYYY-MM-DD") : null,
                description: response.data.description,
                storey: response.data.storey
            });
            if (!!response.data && !!response.data.files) {
                const files = [];
                response.data.files.forEach(file => {
                    files.push({
                        uid: file.id,
                        name: file.name,
                        url: file.path,
                        thumbUrl: file.path,
                        type: file.type
                    });
                });
                this.filesRef.current.setFilesValue(files);
            }
        }).catch(error => {
            console.error("Get equipment detail info", error);
        });
    };

    getEquipmentType = () => {
        EquipmentListApi.queryType().then(response => {
            let selectData = [];
            response.data.forEach(item => {
                let obj = {
                    label: "",
                    value: ""
                };
                obj.label = `${item.name} (${item.model})`;
                obj.value = item.id;
                selectData.push(obj);
            });
            this.setState({equipmentTypeData: selectData});
        }).catch(error => {
            console.warn("Request get equipment list failed.", error);
        });
    };

    /**
     * 选择设备类型改变触发
     */
    selectChange = () => {
    };

    clientDepartmentOnChange = async (value, clear = true) => {
        if (clear) {
            this.formRef.current.setFieldsValue({clientCustomer: ""});
        }
        await this.requestCustomers(value);
    };

    handle = data => {
        this.toggleLoading();
        data.id = this.state.equipmentId;
        data.files = this.filesRef.current.getFilesValue();
        data.deleteFiles = this.filesRef.current.getDeletedFiles();
        if (!!data.installTime) {
            data.installTime = data.installTime.format("YYYY-MM-DD");
        }
        if (!!data.warrantyPeriod) {
            data.warrantyPeriod = data.warrantyPeriod.format("YYYY-MM-DD");
        }
        EquipmentListApi.edit(data).then(response => {
            this.close();
            if (response.status === 20000 && !!this.props.onSuccess) {
                this.props.onSuccess();
            }
        }).catch(error => {
            if (error.status === 40000) {
                setFieldsError(error, this.formRef);
            } else {
                message.error({key: "failed", content: [error.data]});
            }
            this.toggleLoading();
            console.error("Create equipment error", error);
        });
    };

    render() {
        return <Drawer title="编辑设备"
                       width={this.state.drawerWidth}
                       open={this.state.show}
                       closable={!this.state.loading}
                       destroyOnClose
                       onClose={this.close}>
            <Form ref={this.formRef} onFinish={this.handle} layout={"vertical"}>
                <Row gutter={[8, 0]} align="middle">
                    <Col span={12}>
                        <Form.Item name="sn" label="出厂序列号">
                            <Input disabled/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="deviceTypeId" label="选择设备类型" required
                                   rules={[{required: true, message: "请选择设备类型"}, {pattern: /\S/, message: "内容不能为空"}]}>
                            <Select options={this.state.equipmentTypeData} showSearch optionFilterProp="label"
                                    onChange={this.selectChange}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[8, 0]}>
                    <Col span={12}>

                        <Form.Item name="installTime" label="设备安装时间">
                            <DatePicker showTime format="YYYY-MM-DD" inputReadOnly style={{width: "100%"}}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="warrantyPeriod" label="设备保修期">
                            <DatePicker showTime format="YYYY-MM-DD" inputReadOnly style={{width: "100%"}}/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name="storey" label="楼号楼层及门牌号">
                            <Input placeholder="示例：主楼6层  1601"/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[8, 0]}>
                    <Col span={24}>
                        <Form.Item name="description" label="描述信息">
                            <Input.TextArea/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <FileUploadRender name="files" uploadPath="/resource/device/file"
                                          ref={this.filesRef}/>
                    </Col>
                </Row>
                <Row gutter={8} justify="end">
                    <Col>
                        <Space>
                            <Button disabled={this.state.loading} onClick={this.close}>取消</Button>
                            <Button loading={this.state.loading} type="primary" htmlType="submit">编辑</Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </Drawer>;
    }
}

export default EditEquipmentDrawer;
