import React, {Component} from "react";
import {Spin} from "antd";

import workorderInfoApi from "api/workorderInfo";

import style from "./index.module.css";
import {isAdmin} from "../../../../../component/SecurityInterceptor";
import {withNavigation} from "../../../../../utils/RouteLink";
import DictApi from "../../../../../api/dict";
import EnumDict from "../../../../../enum/dict";

/**
 * 服务概览
 * @description 超期工单数量统计
 */
class TimeoutWorkorderStatistic extends Component {
    state = {
        loading: false,
        timeOutNum: "",
        AbnormalStatus: []
    };

    componentDidMount() {
        this.getFinishNumber();
    };

    getFinishNumber = () => {

        this.setState({
            loading: true
        });
        workorderInfoApi.timeoutNumber().then(response => {
            this.setState({
                timeOutNum: response.data,
                loading: false
            });
        }).catch(error => {
            console.log("Get time out number filed", error);
            this.setState({
                timeOutNum: "-",
                loading: false
            });
        });

        DictApi.list({code : EnumDict.ABNORMAL_STATUS}).then(res => {
            let abnormalStatus = [];
            res.data.forEach(item => {
                abnormalStatus.push(item.id);
            });
            this.setState({
                AbnormalStatus: abnormalStatus
            })
        })
    };

    render() {
        return (
            <div className={style.workorderInfoItem} onClick={() => isAdmin() ? this.props.navigate("/home/workorders/all-workorder", {state: {abnormalStatus:this.state.AbnormalStatus}}) : null}>
                <p>超期工单</p>
                {this.state.loading ? <Spin/> : <p>{this.state.timeOutNum}</p>
                }
            </div>
        );
    }
}

export default withNavigation(TimeoutWorkorderStatistic);
