const VersionApi = {
    //分页
    page: (params) => global.axios.get("/general/app/page", {params}),

    //创建
    create: (params) => global.axios.post("/general/app", params),

    //更新
    update: (params) => global.axios.patch("/general/app", params),

    //查询详情
    find: id => global.axios.get(`/general/app/${id}`),

    //删除
    delete: (id) => global.axios.delete(`/general/app/${id}`)

};
export default VersionApi;

