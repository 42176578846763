import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import {Editor, Toolbar} from "@wangeditor/editor-for-react";
import '@wangeditor/editor/dist/css/style.css'

/**
 * @author       : AngelBeats
 * @date         : 2023-02-22 17:24:40
 * @description  : 富文本编辑器
 */
const WangEditor = (props, ref) => {

    const [editor, setEditor] = useState(null);

    const [html, setHtml] = useState('')

    const toolbarConfig = {}

    const editorConfig = {
        placeholder: '请输入内容...',
        MENU_CONF: {}
    }

    /**
     * 销毁
     */
    useEffect(() => {
        return () => {
            if (editor == null) return
            editor.destroy()
            setEditor(null)
        }
    }, [editor])

    /**
     * 暴露方法
     */
    useImperativeHandle(ref, () => ({
        setValue,
        getValue
    }))

    /**
     * 设置值
     * @param value
     */
    const setValue = value => setHtml(value)

    /**
     * 获取值
     * @returns {string}
     */
    const getValue = () => html;

    return (<>
        <div style={{border: '1px solid #ccc', zIndex: 100}}>
            <Toolbar
                editor={editor}
                defaultConfig={toolbarConfig}
                mode="default"
                style={{borderBottom: '1px solid #ccc'}}
            />
            <Editor
                defaultConfig={editorConfig}
                value={html}
                onCreated={setEditor}
                onChange={editor => setHtml(editor.getHtml())}
                mode="default"
                style={{height: '500px', overflowY: 'hidden'}}
            />
        </div>
    </>);
};

export default forwardRef(WangEditor);
