import React, {Component} from "react";
import {Button, DatePicker, Space, Tooltip} from "antd";
import {DeleteOutlined, EditOutlined, EyeOutlined} from "@ant-design/icons";
import {PageContainer, ProTable} from "@ant-design/pro-components";
import PreviewRecordDrawer from "./drawer/PreviewRecordDrawer";
import HistorApi from "../../../api/remote/HistoryApi";

export default class RemoteRecordList extends Component {
    previewrecordRef = React.createRef();
    /**
     * 列表项
     * @type {*[]}
     */
    columns = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id"
        },
        {
            title: "设备识别码",
            dataIndex: "deviceNumber",
            key: "deviceNumber"
        },
        {
            title: "设备名称",
            dataIndex: "deviceName",
            key: "deviceName"
        },
        {
            title: '工单编号',
            dataIndex: 'associatedWorkorderNumber',
            key: 'associatedWorkorderNumber',
        },
        {
            title: '客户单位',
            dataIndex: 'relatedCustomerDepartment',
            key: 'relatedCustomerDepartment',
        },
        {
            title: '操作人',
            dataIndex: 'operator',
            key: 'operator',
        },
        {
            title: '访问时间',
            dataIndex: 'startTime',
            key: 'startTime',
            hideInSearch: true,
        },
        {
            title: '退出时间',
            key: 'endTime',
            dataIndex: 'endTime',
            hideInSearch: true,
        },
        {
            title: "授权时间",
            dataIndex: "time",
            renderFormItem: (_, {fieldProps}) => {
                return (
                    <DatePicker.RangePicker {...fieldProps} inputReadOnly/>
                );
            }
        },
        {
            title: "操作",
            valueType: "option",
            render: (node, entity, index, action) => this.renderColumnOperations(node, entity, index, action)
        },
    ]

    /**
     * 请求列表数据
     * @param params
     */
    requestPage = params => HistorApi.page(params).then(response => {
        return {data: response.data.records, success: true};
    }).catch(error => {
        console.warn("Get remote device list failed", error);
        return {data: [], success: true, total: 0};
    });
    /**
     * 表格操作项
     * @param node
     * @param entity
     * @param index
     * @param action
     */
    renderColumnOperations = (node, entity, index, action) => [
        <Tooltip title="查看" key="preview">
            <Button icon size="small" onClick={() => this.previewrecordRef.current.show(entity)}>
                <EyeOutlined/>
            </Button>
        </Tooltip>,
    ];
    /**
     * 渲染顶部按钮
     * @returns {JSX.Element[]}
     */
    renderToolbar = () => [
        //<Button key="create" type="primary" onClick={() => this.createRef.current.show()}>新建设备</Button>
    ]
    /**
     * 表格页刷新
     * @returns {*}
     */
    reload = () => this.actionRef?.reload();

    render() {
        return (
            <PageContainer header={{title: "远程操作历史", breadcrumb: {}}} extra={this.renderToolbar()}>
                <ProTable
                    rowKey="id"
                    defaultSize="small"
                    options={{reload: true, setting: true, density: true}}
                    actionRef={actionRef => this.actionRef = actionRef}
                    columns={this.columns}
                    request={this.requestPage}/>
                <PreviewRecordDrawer ref={this.previewrecordRef} onSuccess={this.reload}/>
            </PageContainer>
        )
    }
}