const WorkorderInfoApi = {
    /**
     * 获取工单信息数
     */
    workorderNumber: (data) => global.axios.get("/workorder/workorder/statistics/count", {params: {progress: data}}),

    /**
     * 获取超期工单数
     */
    timeoutNumber: () => global.axios.get("/workorder/workorder/statistics/count/delayed"),

    /**
     * 已接入客户单位数
     */
    departmentNumber: () => global.axios.get("/customer/department/statistics/count"),

    /**
     * 已接入客户数
     */
    customerNumber: () => global.axios.get("/customer/customer/statistics/count"),

    /**
     * 已评价工单数量
     */
    statisticNumber: () => global.axios.get("/workorder/workorder/statistics/count/evaluated"),

    /**
     * 已接入工程师数
     */
    engineerNumber: () => global.axios.get("/user/account/statistics/count"),

    /**
     * 已接入服务商数
     */
    serviceNumber: () => global.axios.get("/user/department/statistics/count"),

    /**
     * 获取近三十天工单处理状况
     */
    workorderHandlerLatest: () => global.axios.get("/workorder/workorder/statistics/handler-latest-30-days"),

    /**
     * 获取工单满意度 饼状图数据
     */

    feedbackData: () => global.axios.get("/workorder/workorder/statistics/distribute"),

    /**
     * 获取折线
     */
    lineData: feedbackScore => global.axios.get("/workorder/workorder/statistics/feedback-score/trend/latest-10-days", {params: {feedbackScore}}),

    /**
     * 获取接入设备数量
     */

    equipmentNumber: () => global.axios.get("/resource/device/count"),

    /**
     * 查询用户正在进行中的工单
     * @param accountId
     * @returns {Promise<AxiosResponse<any>>}
     */
    ongoingWorkorder: accountId => global.axios.get(`/workorder/account/${accountId}/progressing-workorder`)
};

export default WorkorderInfoApi;
