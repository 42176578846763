import React, {Component} from "react";
import {Button, Cascader, Col, DatePicker, Drawer, Form, Input, message, Row, Select, Space} from "antd";

import CustomerApi from "api/customer/customer";
import EquipmentListApi from "api/equipment/list";
import {setFieldsError} from "plugin/formily";

import style from "component/equipment/map/index.module.css";

import CreateCustomerModal from "../../../customer/customer/modal/CreateCustomerModal";
import CreateCustomerDepartmentModal from "../../../customer/department/modal/CreateCustomerDepartmentModal";
import CustomerDepartmentPicker from "component/customer/department/CustomerDepartmentPicker";
import FileUploadRender from "component/FileUploadRender";

/**
 * 新建设备
 * 调用设备类型接口 请求设备类型
 * 调用请求客户单位接口获取客户单位数据
 * 根据客户单位变化 请求对应的客户接口 获取客户数据
 * 调用 component 中的 Map 组件 创建地图
 * 根据 地址和设备所在地址在地图上渲染对应的点
 * 填写表单  调用创建接口 新建设备
 */
export default class CreateEquipmentDrawer extends Component {

    state = {
        show: false,
        loading: false,
        drawerWidth: 600,
        customerDepartmentsOptions: [],
        customersOptions: [],
        loadingCustomerDepartmentsOptions: false,
        loadingCustomersOptions: false,
        areaData: [],
        equipmentTypeData: [],
        province: "",
        city: "",
        county: "",
        area: "",
        lnglatDataLng: "",
        lnglatDataLat: "",
        isAddMap: false
    };

    creatDepartmentRef = React.createRef();
    createCustomerRef = React.createRef();
    formRef = React.createRef();
    customerDepartmentRef = React.createRef();
    filesRef = React.createRef();
    map;

    show = () => {
        this.setState({
            show: true,
            drawerWidth: window.innerWidth < 576 ? window.innerWidth : window.innerWidth * 0.55
        }, () => {
           this.getMap();
            this.getEquipmentType();
        });
    };

    close = () => this.setState({show: false, loading: false},this.map.destroy());

    toggleLoading = () => this.setState({loading: !this.state.loading});

    onCustomerDepartmentChange = departmentId => {
        this.formRef.current.setFieldsValue({customerId: null});
        this.requestCustomersOptions(departmentId);
        this.setState({departmentId});
    };

    requestCustomersOptions = departmentId => {
        this.toggleCustomersOptionsLoading();
        CustomerApi.list({departmentId}).then(response => {
            const customerOptions = [];
            if (!!response.data && response.data.length > 0) {
                response.data.forEach((customer) => {
                    customerOptions.push(this.handleCustomerOption(customer));
                });
            }
            this.setState({customersOptions: customerOptions}, this.toggleCustomersOptionsLoading);
        }).catch(error => {
            console.warn("Get customers failed", error);
            this.toggleCustomersOptionsLoading();
        });
    };

    handleCustomerOption = customer => {
        return {
            label: `${customer.name}(${customer.cellphone})`,
            value: customer.id
        };
    };

    toggleCustomerDepartmentsOptionsLoading = () => this.setState({loadingCustomerDepartmentsOptions: !this.state.loadingCustomerDepartmentsOptions});

    toggleCustomersOptionsLoading = () => this.setState({loadingCustomersOptions: !this.state.loadingCustomersOptions});

    /**
     *控制弹窗
     */
    showCustomerDialog = (id, ref) => ref.current.show(id);

    showDepartmentDialog = (id, ref) => ref.current.show(id);

    onCreateCustomerDepartmentSuccess = departmentId => {
        this.customerDepartmentRef.current.reload();
        this.requestCustomersOptions(departmentId);
        this.formRef.current?.setFieldsValue({
            customerDepartmentId: departmentId,
            customerId: null
        });
        this.setState({departmentId});
    };

    onCreateCustomerSuccess = (departmentId, customerId) => {
        const customerDepartmentId = this.formRef.current?.getFieldValue("customerDepartmentId");
        if (!!customerDepartmentId) {
            this.requestCustomersOptions(customerDepartmentId);
        }
        if (departmentId === customerDepartmentId) {
            this.formRef.current?.setFieldsValue({
                customerId: customerId
            });
        }
    };

    areaChange = value => {
        this.setState({areaData: value});
        this.formRef.current.setFieldsValue({address: ""});
    };

    getEquipmentType = () => {
        EquipmentListApi.queryType().then(response => {
            let selectData = [];
            response.data.forEach(item => {
                let obj = {
                    label: "",
                    value: ""
                };
                obj.label = item.name + " - " + item.model;
                obj.value = item.id;
                selectData.push(obj);
            });
            this.setState({equipmentTypeData: selectData});
        });
    };

    selectSearch = (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

    handle = data => {
        this.toggleLoading();
        data.province = data.area[0];
        data.city = data.area[1];
        data.county = data.area[2];
        data.area = undefined;
        data.files = this.filesRef.current.getFilesValue();
        if (!!this.props.equipmentId) {
            data.id = this.props.equipmentId;
        }
        if (!!data.installTime) {
            data.installTime = data.installTime.format("YYYY-MM-DD");
        }
        if (!!data.warrantyPeriod) {
            data.warrantyPeriod = data.warrantyPeriod.format("YYYY-MM-DD");
        }
        if (!!this.state.lnglatDataLng && !!this.state.lnglatDataLat) {
            data.longitude = this.state.lnglatDataLng;
            data.latitude = this.state.lnglatDataLat;
            data.lnglat = `${this.state.lnglatDataLng},${this.state.lnglatDataLat}`;
        } else {
            message.warn({key: "lat", content: "经纬度不存在！"});
            this.toggleLoading();
            return;
        }
        EquipmentListApi.create(data).then(response => {
            this.close();
            if (response.status === 20000 && !!this.props.onSuccess) {
                this.props.onSuccess();
            }
        }).catch(error => {
            if (error.status === 40000) {
                setFieldsError(error, this.formRef);
            } else {
                message.error({key: "failed", content: [error.data]});
            }
            this.toggleLoading();
            console.error("Create equipment error", error);
        });
    };

    getMap = () => {
        this.map = new AMap.Map("container", {
            pitch: 0,
            viewMode: "3D",
            terrain: true,
            center: [114.973172, 25.817861],
            zoom: 17,
            zoomEnable: false,
            dragEnable: false,
            resizeEnable: true
        });

        AMap.plugin(["AMap.PlaceSearch", "AMap.AutoComplete"], () => {

            new AMap.AutoComplete({input: "address"}).on("select", e => {
                this.formRef.current.setFieldsValue({address: e.poi.name});
                this.map.clearMap();
                this.map.add(new AMap.Marker({position: [e.poi.location.lng, e.poi.location.lat]}));
                this.setState({lnglatDataLng: e.poi.location.lng, lnglatDataLat: e.poi.location.lat});
                this.map.setFitView();
                let lnglat = [e.poi.location.lng, e.poi.location.lat];
                this.regeoCode(lnglat);
            });
        });
    };

    /**
     * 当所选的地址与详细地址不同时更新所选的地址
     * @param lnglat 经纬度
     */
    regeoCode = lnglat => new AMap.Geocoder().getAddress(lnglat, (status, result) => {
        // 特别行政区或直辖市的 city 为空 所有直接用 province
        this.formRef.current.setFieldsValue({
            area: [
                result.regeocode.addressComponent.province,
                result.regeocode.addressComponent.city ? result.regeocode.addressComponent.city : result.regeocode.addressComponent.province,
                result.regeocode.addressComponent.district
            ]
        });
    });

    render() {
        return <Drawer title="新增设备"
                       width={this.state.drawerWidth}
                       open={this.state.show}
                       closable={!this.state.loading}
                       destroyOnClose
                       onClose={this.close}>
            <Form ref={this.formRef} onFinish={this.handle} layout={"vertical"}>
                <Row gutter={[8, 0]} align="middle">
                    <Col span={24}>
                        <Form.Item name="id" label="报修码" rules={[{pattern: /^[A-Za-z0-9]+$/, message: "请输入字母或数字!"}]}>
                            {!!this.props.equipmentId ? <Input defaultValue={this.props.equipmentId} disabled/> :
                                <Input/>}
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name="sn" label="出厂序列号" required rules={[{required: true, message: "请输入出厂序列号"}]}>
                            {!!this.props.sn ? <Input defaultValue={this.props.sn} disabled/> : <Input/>}
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name="deviceTypeId" label="选择设备类型" required
                                   rules={[{required: true, message: "请选择制造商"},
                                       {pattern: /\S/g, message: "内容不能为空"}]}>
                            <Select options={this.state.equipmentTypeData} showSearch optionFilterProp="label"/>
                        </Form.Item>
                    </Col>
                    <Col sm={9} xs={18}>
                        <CustomerDepartmentPicker ref={this.customerDepartmentRef}
                                                  name="customerDepartmentId"
                                                  rules={[{required: true, message: "请选择客户单位"}]}
                                                  onChange={this.onCustomerDepartmentChange}/>
                    </Col>
                    <Col sm={3} xs={6} align="middle">
                        <Button block style={{marginTop: 6}}
                                onClick={() => this.showDepartmentDialog("", this.creatDepartmentRef)}>新建单位</Button>
                    </Col>
                    <Col sm={9} xs={18}>
                        <Form.Item name="customerId" label="客户"
                                   rules={[{ message: "请选择客户"}]}>
                            <Select options={this.state.customersOptions}
                                    loading={this.state.loadingCustomersOptions}
                                    showSearch optionFilterProp="label"/>
                        </Form.Item>
                    </Col>
                    <Col sm={3} xs={6} align="middle">
                        <Button block style={{marginTop: 6}}
                                onClick={() => this.showCustomerDialog(this.state.departmentId, this.createCustomerRef)}>新建客户</Button>
                    </Col>
                    <Col span={24}>
                        <Form.Item name="area" label="选择地址" required hidden
                                   rules={[{required: true, message: "请选择地址"},
                                       {pattern: /\S/g, message: "内容不能为空"}]}>
                            <Cascader options={require("asset/location.json")}
                                      onChange={this.areaChange}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="installTime" label="设备安装时间">
                            <DatePicker showTime format="YYYY-MM-DD" inputReadOnly style={{width: "100%"}}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="warrantyPeriod" label="设备保修期">
                            <DatePicker showTime format="YYYY-MM-DD" inputReadOnly style={{width: "100%"}}/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name="description" label="描述信息">
                            <Input.TextArea/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name="address" label="设备所在地址" required
                                   rules={[{required: true, message: "请输入详细地址"}, {pattern: /\S/g, message: "内容不能为空"}]}>
                            <Input id="address" autoComplete="off"/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name="storey" label="楼号楼层及门牌号">
                            <Input placeholder="示例：主楼6层  1601"/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <div className={style.map} id="container">
                        </div>
                    </Col>
                    <Col span={24}>
                        <FileUploadRender name="files" uploadPath="/resource/device/file"
                                          ref={this.filesRef}/>
                    </Col>
                </Row>
                <Row gutter={8} justify="end">
                    <Col>
                        <Space>
                            <Button disabled={this.state.loading} onClick={this.close}>取消</Button>
                            <Button loading={this.state.loading} type="primary" htmlType="submit">新建</Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
            <CreateCustomerDepartmentModal onSuccess={this.onCreateCustomerDepartmentSuccess}
                                           ref={this.creatDepartmentRef}/>
            <CreateCustomerModal onSuccess={this.onCreateCustomerSuccess} ref={this.createCustomerRef}/>
        </Drawer>;
    }
}
