import React, {Component} from "react";
import {ProTable} from "@ant-design/pro-components";
import {Button, Tooltip} from "antd";
import {EyeOutlined} from "@ant-design/icons";

import StorehouseInventoryApi from "api/spares/storehouse/inventory";

import CreateInventoryDrawer from "./drawer/CreateInventoryDrawer";
import ScheduleInventoryDrawer from "./drawer/ScheduleInventoryDrawer";
import DestroySparesDrawer from "./drawer/DestroySparesDrawer";
import RevertSparesDrawer from "./drawer/RevertSparesDrawer";
import SolicitSparesDrawer from "./drawer/SolicitSparesDrawer";
import PreviewInventoryDrawer from "./drawer/PreviewInventoryDrawer";
import ExportSparesRecordExcelModal from "../../record/modal/ExportSparesRecordExcelModal";

import {isStorehouseAdmin, isStorehouseAdminOrEngineer} from "../../permissions/AuthorityManagement";

class CurrentInventory extends Component {

    createInventoryRef = React.createRef();
    scheduleInventoryRef = React.createRef();
    destroySparesRef = React.createRef();
    revertSparesRef = React.createRef();
    solicitSparesRef = React.createRef();
    previewInventoryRef = React.createRef();
    exportRecordRef = React.createRef();
    state = {
        total: ""
    };

    componentDidMount() {
        this.requestTotal();
    }

    renderColumnOperations = (node, entity, index, action) => {
        return [
            <Tooltip title="查看库存" key="preview">
                <Button icon size="small" onClick={() => this.previewInventoryRef.current.show(entity.id)}>
                    <EyeOutlined/>
                </Button>
            </Tooltip>
        ];
    };

    columns = [
        {
            title: "ID",
            dataIndex: "id",
            editable: false
        },
        {
            title: "型号",
            dataIndex: ["stocktype", "model"],
            hideInSearch: true
        },
        {
            title: "类型名称",
            formItemProps: {name: "name"},
            dataIndex: ["stocktype", "name"]
        },
        {
            title: "库存数量",
            dataIndex: "count",
            hideInSearch: true
        },
        {
            title: "操作时间",
            dataIndex: "modifyTime",
            hideInSearch: true
        },
        {
            title: "操作",
            valueType: "option",
            render: (node, entity, index, action) => this.renderColumnOperations(node, entity, index, action)
        }
    ];

    tableToolbarRender = () => {
        const renderButton = isStorehouseAdmin(this.props.storehouseAdminIds);
        const renderSolicitButton = isStorehouseAdmin(this.props.storehouseAdminIds) || isStorehouseAdminOrEngineer(this.props.storehouseEngineersIds);
        return [
            !!renderButton ?
                <Button type="primary"
                        onClick={() => this.exportRecordRef.current.show(this.props.storehouseId)}>导出</Button> : null,
            !!renderButton ? <Button type="primary"
                                     onClick={() => this.createInventoryRef.current.show(this.props.storehouseId)}>入库</Button> : null,
            !!renderSolicitButton ? <Button type="primary"
                                            onClick={() => this.solicitSparesRef.current.show(this.props.storehouseId)}>领用</Button> : null,
            !!renderButton ? <Button type="primary"
                                     onClick={() => this.scheduleInventoryRef.current.show(this.props.storehouseId)}>调拨</Button> : null,
            !!renderButton ? <Button type="primary" danger
                                     onClick={() => this.destroySparesRef.current.show(this.props.storehouseId)}>消耗</Button> : null,
            <Button style={{color: "#D3292D", border: "none", pointerEvents: "none"}}>总库存数量：{this.state.total}</Button>,
            // <span style={{color: "#D3292D"}}>总库存数量：{this.state.total}</span>
        ];
    };

    requestPage = (params) => {
        params.repository = this.props.storehouseId;
        return StorehouseInventoryApi.page(params).then(res => {
            return {data: res.data.records, total: res.data.total, success: true};
        }).catch(err => {
            return {data: [], total: 0, success: true};
        });
    };

    requestTotal = () => {
        StorehouseInventoryApi.inventoryTotal({repository: this.props.storehouseId}).then(res => {
            this.setState({total: res.data});
        }).catch(err => {
            console.error("Request inventory total", err);
        });
    };

    reload = () => {
        this.actionRef.reload();
        this.requestTotal();
    };

    render() {
        return (<>
                <ProTable headerTitle="当前仓库库存"
                          rowKey="id"
                          defaultSize="small"
                          toolBarRender={this.tableToolbarRender}
                          onLoad={this.requestTotal}
                          options={{reload: true, setting: true, density: true}}
                          actionRef={actionRef => this.actionRef = actionRef}
                          columns={this.columns}
                          request={this.requestPage}/>
                <CreateInventoryDrawer onSuccess={this.reload} ref={this.createInventoryRef}/>
                <ScheduleInventoryDrawer onSuccess={this.reload} ref={this.scheduleInventoryRef}/>
                <DestroySparesDrawer onSuccess={this.reload} ref={this.destroySparesRef}/>
                <RevertSparesDrawer onSuccess={this.reload} ref={this.revertSparesRef}/>
                <SolicitSparesDrawer onSuccess={this.reload} ref={this.solicitSparesRef}/>
                <PreviewInventoryDrawer onSuccess={this.reload} ref={this.previewInventoryRef}/>
                <ExportSparesRecordExcelModal onSuccess={this.reload} ref={this.exportRecordRef}/>

            </>

        );
    }
}

export default CurrentInventory;
