import React, {Component} from "react";
import {Button, Col, Form, Input, message, Row, Space} from "antd";
import {ProDescriptions} from "@ant-design/pro-components";

import EquipmentListApi from "api/equipment/list";
import CustomerApi from "api/customer/customer";
import CustomerDepartmentApi from "api/customer/department";
import WorkorderApi from "api/workorder";
import DraggableModal from "component/DraggableModal";

/**
 * 关联工单与设备 Modal
 *
 * @author DunShun Huang
 * @version 1.0.0
 */
export default class SetWorkorderDeviceModal extends Component {

    state = {
        show: false,
        loading: false,
        loadingOfEquipmentInfo: false,
        loadingOfCustomer: false,
        loadingOfCustomerDepartment: false,
        descriptionsShow: false,
        isAssociated: true,
        isOngoingWorkorder: true,
        equipment: {},
        customer: {},
        customerDepartment: {},
        equipmentId: "",
        commonType:""
    };

    formRef = React.createRef();
    draggableRef = React.createRef();

    toggleLoading = () => {
        this.setState({
            loading: !this.state.loading
        });
    };

    show = () => {
        this.draggableRef.current.show();
        this.setState({descriptionsShow: false});
    };

    close = () => this.draggableRef.current.close();

    /**
     * 点击查询设备信息
     * @param value
     */
    onResourceSearch = (value) => {
        if (!!value) {
            this.requestEquipmentInfo(value);
            this.requestForWorkorder(value);
            this.setState({
                descriptionsShow: true,
                equipment: {},
                customer: {},
                customerDepartment: {}
            });
        } else {
            message.warning({
                content: "请输入设备ID",
                key: "equipmentId"
            });
        }
    };

    requestForWorkorder = (id) => {
        WorkorderApi.findEquipmentWorkorder(id).then(res => {
            console.log(res);
            this.setState({
                isOngoingWorkorder: true
            });
        }).catch(err => {
            console.error("Request for workorder failed", err);
            if (err.data === false) {
                this.setState({
                    isOngoingWorkorder: false
                });
            }
        });
    };

    onResourceChange = () => {
        this.setState({
            descriptionsShow: false,
            isAssociated: true,
            equipment: {},
            customer: {},
            customerDepartment: {}
        });
    };

    /**
     * 查询设备信息
     */
    requestEquipmentInfo = (id) => {
        this.setState({loadingOfEquipmentInfo: true});
        EquipmentListApi.info(id).then(res => {
            this.setState({equipment: res.data, loadingOfEquipmentInfo: false,commonType: res.data.deviceType.commonType});
            this.requestCustomer(res.data.customerId);
            this.requestCustomerDepartment(res.data.customerDepartmentId);
        }).catch(err => {
            this.setState({loadingOfEquipmentInfo: false});
            if (err.status === 40400) {
                this.setState({
                    isAssociated: false
                });
            }
            console.error("request equipment failed", err);
        });
    };

    /**
     * 查询客户信息
     * @returns {JSX.Element}
     */
    requestCustomer = (id) => {
        this.setState({loadingOfCustomer: true});
        CustomerApi.find(id).then(res => {
            this.setState({customer: res.data, loadingOfCustomer: false});
        }).catch(err => {
            this.setState({loadingOfCustomer: false});
            console.error("Request customer failed", err);
        });
    };
    /**
     * 查询客户单位信息
     * @param id
     */
    requestCustomerDepartment = (id) => {
        this.setState({loadingOfCustomerDepartment: true});
        CustomerDepartmentApi.find(id).then(res => {
            this.setState({customerDepartment: res.data, loadingOfCustomerDepartment: false});
        }).catch(err => {
            console.error("Request customer department failed", err);
            this.setState({loadingOfCustomerDepartment: false});
        });
    };

    handle = (data) => {
        if (!!this.state.isAssociated) {
            this.toggleLoading();
            data.resourceName = this.state.equipment.name;
            data.resourceType = this.state.equipment.model;
            data.workorderId = this.props.workorderId;
            WorkorderApi.updateEquipment(data).then(res => {
                this.setState({
                    equipmentId: data.resourceId
                });
                this.toggleLoading();
                if (!!this.props.onSuccess) {
                    this.props.onSuccess(
                        {
                            commonType:this.state.commonType,
                            equipmentId: data.resourceId,
                        }
                    );
                }
                this.close();
            }).catch(err => {
                this.toggleLoading();
                console.error("Associated equipment failed", err);
            });
        } else {
            message.error({content: "没有查询到该设备，暂时无法关联！请把设备录入", key: "associated"});
        }
    };

    onSubmit = () => {
        if (!this.state.descriptionsShow) {
            return;
        }
        this.formRef.current.submit();
    };


    render() {
        return (
            <DraggableModal onCancel={this.close} title="关联设备" ref={this.draggableRef}>
                <Form ref={this.formRef} onFinish={this.handle} layout={"vertical"}>
                    <Form.Item name="resourceId" label="报修码" required
                               rules={[{required: true, message: "请输入设备ID"}]}>
                        <Input.Search placeholder="输入报修码" onSearch={this.onResourceSearch}
                                      onChange={this.onResourceChange} enterButton/>
                    </Form.Item>
                    {!!this.state.descriptionsShow && !this.state.loadingOfEquipmentInfo && !this.state.loadingOfCustomerDepartment && !this.state.loadingOfCustomer ?
                        <ProDescriptions bordered size="small" layout="horizontal"
                                         column={window.innerWidth < 800 ? 1 : 4}>
                            <ProDescriptions.Item label="设备名称" span={2}>
                                {this.state.equipment.name || "--"}
                            </ProDescriptions.Item>
                            <ProDescriptions.Item label="设备型号" span={2}>
                                {this.state.equipment.model || "--"}
                            </ProDescriptions.Item>
                            {
                                !!this.state.customer ? <ProDescriptions.Item label="客户姓名" span={2}>
                                    {this.state.customer.name || "--"}
                                </ProDescriptions.Item> : null
                            }
                            {
                                !!this.state.customer ? <ProDescriptions.Item label="客户电话" span={2}>
                                    {this.state.customer.cellphone || "--"}
                                </ProDescriptions.Item> : null
                            }
                            {
                                !!this.state.customerDepartment ? <ProDescriptions.Item label="客户单位" span={4}>
                                    {this.state.customerDepartment.name || "--"}
                                </ProDescriptions.Item> : null
                            }
                            <ProDescriptions.Item label="设备描述" span={4}>
                                {this.state.equipment.description || "--"}
                            </ProDescriptions.Item>
                            {!this.state.isOngoingWorkorder ?
                                <p style={{color: "red"}}>此设备已经有工单正在进行维护，无法再关联此设备，请跳过关联步骤或联系管理员处理正在进行中的工单</p> : null}
                        </ProDescriptions> : null}
                    <Row gutter={8} justify="end">
                        <Col>
                            <Space>
                                <Button
                                    disabled={this.state.loading || this.state.loadingOfEquipmentInfo || this.state.loadingOfCustomerDepartment || this.state.loadingOfCustomer}
                                    onClick={this.close}>取消</Button>
                                {!!this.state.descriptionsShow ? <Button
                                        loading={this.state.loading || this.state.loadingOfEquipmentInfo || this.state.loadingOfCustomerDepartment || this.state.loadingOfCustomer}
                                        type="primary" onClick={this.onSubmit}
                                        disabled={!this.state.isOngoingWorkorder}>关联</Button> :
                                    <Button type="primary"
                                            onClick={() => this.onResourceSearch(this.formRef.current.getFieldValue("resourceId"))}>查看</Button>}
                            </Space>
                        </Col>
                    </Row>
                </Form>
            </DraggableModal>
        );
    }
}
