import {isArrayAllSame, isArrayAnySame} from "./tool";

const USER = "User";
const USER_PERMISSION = "permissions";
const USER_ROLES = "roles";

/**
 * 获取用户信息
 */
export const getUser = () => {
    if (!localStorage.getItem(USER) || localStorage.getItem(USER) === "undefined") {
        return null;
    }

    return JSON.parse(localStorage.getItem(USER));
};

/**
 * 获取用户权限
 */
export const getUserPermissions = () => {

    if (getUser() === null || !getUser()[USER_PERMISSION] || getUser()[USER_PERMISSION].length === 0) {
        return [];
    }

    return getUser()[USER_PERMISSION];

};

/**
 * 检查用户 是否具有 某一项权限
 * @param requirePermission
 */
export const anyUserPermissions = (requirePermission) => {

    // 当 requirePermission 没有值 或 为空值的时候 返回true
    if (requirePermission === undefined || requirePermission === null || requirePermission === "" || requirePermission?.length === 0) {
        return true;
    }

    if (getUserPermissions().length === 0) {
        return false;
    }

    if (!Array.isArray(requirePermission)) {
        return getUserPermissions().includes(requirePermission);
    }

    return isArrayAnySame(getUserPermissions(), requirePermission);

};

/**
 * 判断用户是否具有所有指定的权限
 * @param requirePermission
 */
export const allUserPermissions = (requirePermission) => {

    // 当 requirePermission 没有值 或 为空值的时候 返回true
    if (requirePermission === undefined || requirePermission === null || requirePermission === "" || requirePermission?.length === 0) {
        return true;
    }

    if (getUserPermissions().length === 0) {
        return false;
    }

    if (!Array.isArray(requirePermission)) {
        return getUserPermissions().includes(requirePermission);
    }

    return isArrayAllSame(getUserPermissions(), requirePermission);

};

/**
 * 获取用户角色
 */
export const getUserRoles = () => {

    if (getUser() === null || !getUser()[USER_ROLES] || getUser()[USER_ROLES]?.length === 0) {
        return [];
    }

    return getUser()[USER_ROLES];

};

/**
 * 检查用户 是否具有 某一项角色
 * @param requireRoles
 */
export const anyUserRoles = (requireRoles) => {

    // 当 requireRoles 没有值 或 为空值的时候 返回true
    if (requireRoles === undefined || requireRoles === null || requireRoles === "" || requireRoles?.length === 0) {
        return true;
    }

    if (getUserRoles()?.length === 0) {
        return false;
    }

    if (!Array.isArray(requireRoles)) {
        return getUserRoles().includes(requireRoles);
    }

    return isArrayAnySame(getUserRoles(), requireRoles);

};

/**
 * 判断用户是否具有所有指定角色
 * @param requireRoles
 */
export const allUserRoles = (requireRoles) => {

    // 当 requireRoles 没有值 或 为空值的时候 返回true
    if (requireRoles === undefined || requireRoles === null || requireRoles === "" || requireRoles?.length === 0) {
        return true;
    }

    if (getUserRoles().length === 0) {
        return false;
    }

    if (!Array.isArray(requireRoles)) {
        return getUserRoles().includes(requireRoles);
    }

    return isArrayAllSame(getUserRoles(), requireRoles);
};
