const CustomerDepartmentApi = {
    /**
     * 查询客户单位
     * @param id 客户单位 ID
     */
    find: (id) => global.axios.get(`/customer/department/${id}`),
    /**
     * 单位迁移
     */
    move: (params) => global.axios.patch("/customer/department/move", params),
    /**
     * 分页查询客户单位树
     */
    page: () => global.axios.get("/customer/departments/tree"),
    /**
     * 查询客户单位树形结构
     * @userDepartment 用户部门 ID
     * @description 该方法会查询所有客户单位并形成树形结构
     */
   // tree: (parentIds) => global.axios.get("/customer/departments/tree", {params: {parentIds}}),
    tree: (userDepartment) => global.axios.get("/customer/user-department/" + userDepartment + "/customer-department/tree"),
    /**
     * 查询用户部门树形结构
     * @param parentIds 父级 ID
     * @returns {Promise<AxiosResponse<any>>}
     */
    userDepartmentTree: (parentIds) => global.axios.get("/customer/departments/tree", {params: {parentIds}}),
    /**
     * 创建客户单位
     */
    create: (data) => global.axios.post("/customer/department", data),
    /**
     * 更新客户单位
     */
    update: (data) => global.axios.patch("/customer/department", data),
    /**
     * 删除客户单位
     * @param id 客户单位 ID
     */
    delete: (id) => global.axios.delete(`/customer/department/${id}`),
    /**
     * 批量删除客户单位
     */
    deletes: (departmentIds) => global.axios.delete("/customer/departments", {data: {departmentIds}}),
    /**
     * 查询单位信息
     */
    findDepartment: (id) => global.axios.get(`/customer/department/${id}`),

    /**
     * 创建单位账户
     * @param params
     * @returns {*}
     */
    addDepartmentAccount: params => global.axios.patch(`/customer/${params.departmentId}/account`, params)
};

export default CustomerDepartmentApi;
