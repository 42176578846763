import React, {Component} from "react";
import {Button, Col, Drawer, message, Row, Space} from "antd";
import {
    ProForm,
    ProFormDigit,
    ProFormGroup,
    ProFormList,
    ProFormSelect,
    ProFormText,
    ProFormTextArea
} from "@ant-design/pro-components";

import AddSparesTypeInfo from "../modal/AddSparesTypeInfo";
import FileUploadRender from "component/FileUploadRender";

import StorehouseTypeApi from "api/spares/type";
import StorehouseApi from "api/spares/storehouse";
import EquipmentMakerApi from "api/equipment/maker";
import StorehouseInventoryApi from "api/spares/storehouse/inventory";

import "./index.css";

/**
 * 添加库存
 */
export default class CreateInventoryDrawer extends Component {

    state = {
        show: false,
        loading: false,
        drawerWidth: 600,
        repositoryId: "",
        typeSelect: [],
        manufacturerSelect: [],
        sparesData: []
    };

    formRef = React.createRef();
    filesRef = React.createRef();
    addSparesTypeInfoRef = React.createRef();

    show = (id) => {
        this.setState({
            show: true,
            repositoryId: id,
            drawerWidth: window.innerWidth < 576 ? window.innerWidth : window.innerWidth * 0.65
        }, () => {
            this.requestStorehouseType();
            this.requestStorehouseInfo(id);
            this.requestManufacturerInfo();
        });
    };

    close = () => {
        this.setState({
            show: false,
            loading: false
        });
    };

    toggleLoading = () => this.setState({loading: !this.state.loading});

    requestStorehouseInfo = id => {
        StorehouseApi.find(id).then(res => {
            this.formRef.current.setFieldsValue({
                name: res.data.name,
                address: res.data.province + res.data.city + res.data.county + res.data.address
            });
        }).catch(err => {
            console.error("Request storehouse info failed", err);
        });
    };

    requestManufacturerInfo = () => {
        EquipmentMakerApi.queryAll().then(res => {
            this.setState({
                manufacturerSelect: res.data.map(item => ({label: item.name, value: item.id}))
            })
        }).catch(err => {
            console.error("Request manufacturer info failed", err);
        });
    };

    requestStorehouseType = () => {
        StorehouseTypeApi.findAll().then(res => {
            this.setState({
                typeSelect: res.data.map(item => ({label: item.name, value: item.id}))
            });
        }).catch(err => {
            console.error("Request storehouse type failed", err);
        });
    };

    reload = (value) => {
        let stocks = this.formRef.current.getFieldValue().stocks;
        const isStocksNotEmpty = stocks !== undefined && stocks !== null && stocks.length > 0;
        stocks = isStocksNotEmpty ? stocks : [];
        console.log([...stocks, value]);
        this.formRef.current.setFieldsValue({
            stocks: [...stocks, value]
        });
    };

    handle = data => {
        data.files = this.filesRef.current.getFilesValue();
        data.repository = this.state.repositoryId;
        this.toggleLoading();
        console.log(data);
        StorehouseInventoryApi.create(data).then(res => {
            this.toggleLoading();
            this.props.onSuccess();
            this.close();
        }).catch(err => {
            this.toggleLoading();
            message.error(err.data);
            console.error("Create inventory failed", err);
        });
    };

    render() {
        return <Drawer title="添加库存"
                       destroyOnClose
                       width={this.state.drawerWidth}
                       open={this.state.show}
                       closable={!this.state.loading}
                       onClose={this.close}>
            <ProForm formRef={this.formRef} onFinish={this.handle} layout={"vertical"} submitter={{
                resetButtonProps: {style: {display: "none"}},
                submitButtonProps: {style: {display: "none"}}
            }}>
                <ProFormText name="name" label="仓库名称" disabled/>
                <ProFormText name="address" label="仓库地址" disabled/>
                <ProFormTextArea
                    name="comment"
                    label="描述"/>
                <ProFormList
                    copyIconProps={{style: {display: "none"}}}
                    creatorButtonProps={{style: {display: "none"}}}
                    name="stocks"
                    label="备件信息"
                    required
                    rules={[
                        {
                            validator: async (_, value) => {
                                console.log(value);
                                if (value && value.length > 0) {
                                    return;
                                }
                                throw new Error("至少要有一项！");
                            }
                        }
                    ]}>
                    <ProFormGroup key="group">
                        <ProFormSelect
                            label="类型"
                            showSearch
                            name="stocktype"
                            rules={[{required: true}]}
                            width="xs"
                            options={this.state.typeSelect}
                            disabled
                        />
                        <ProFormDigit
                            width="xs"
                            rules={[{required: true}]}
                            name="count"
                            label="数量"
                            min={1}
                            max={9999999999}
                            fieldProps={{precision: 0}}
                        />
                        <ProFormText
                            width="xs"
                            name="model"
                            label="型号"
                            disabled
                            value={this.state.model}
                        />
                        <ProFormSelect
                            label="制造商"
                            showSearch
                            name="manufacturer"
                            width="xs"
                            options={this.state.manufacturerSelect}
                            disabled
                        />
                        <ProFormSelect
                            label="是否SM"
                            name="classified"
                            width="xs"
                            options={[{label: "普通机", value: 0}, {label: "专用机", value: 1}]}
                            disabled
                        />
                    </ProFormGroup>
                </ProFormList>
                <Button
                    onClick={() => this.addSparesTypeInfoRef.current.show(this.formRef.current.getFieldValue().stocks)}
                    style={{width: "100%", marginBottom: "35px"}}>添加</Button>

                <FileUploadRender label="附件" uploadPath="/repository/add/file" ref={this.filesRef} name="files"/>

                <Row gutter={8} justify="end">
                    <Col>
                        <Space>
                            <Button disabled={this.state.loading} onClick={this.close}>取消</Button>
                            <Button loading={this.state.loading} type="primary" htmlType="submit">添加</Button>
                        </Space>
                    </Col>
                </Row>
            </ProForm>
            <AddSparesTypeInfo onSuccess={this.reload} ref={this.addSparesTypeInfoRef}/>
        </Drawer>;
    }
}

