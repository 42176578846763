import React from "react";
import {Button, Dropdown, Input, Menu, message, Space, Tooltip} from "antd";
import {EyeOutlined, FileSearchOutlined, MoreOutlined, PlusSquareOutlined, SearchOutlined} from "@ant-design/icons";
import {PageContainer, ProTable} from "@ant-design/pro-components";

import Highlighter from "react-highlight-words";

import CustomerDepartmentApi from "api/customer/department";
import style from "../../user/department/index.module.css";

import SecurityInterceptor, {getUser, hasAnyRole} from "component/SecurityInterceptor";

import CreateCustomerModal from "../customer/modal/CreateCustomerModal";
import CreateCustomerDepartmentModal from "./modal/CreateCustomerDepartmentModal";

import PreviewCustomerDepartmentModal from "./modal/PreviewCustomerDepartmentModal";
import UpdateCustomerDepartmentModal from "./modal/UpdateCustomerDepartmentModal";

import MoveCustomerDepartmentModal from "./modal/MoveCustomerDepartmentModal";
import DeleteCustomerDepartmentModal from "./modal/DeleteCustomerDepartmentModal";
import PreviewCustomerDepartmentCustomerDrawer from "./drawer/PreviewCustomerDepartmentCustomerDrawer";
import PreviewDepartmentEquipmentDrawer from "./drawer/PreviewDepartmentEquipmentDrawer";

import axios from "axios";
import styleTable from "./index.module.css";
import CreateDepartmentAccount from "./modal/CreateDepartmentAccount";
import BatchImportModal from "./modal/BatchImportModal";

/**
 * 客户单位
 */
export default class Departments extends React.Component {

    actionRef = null;

    state = {
        searchText: "",
        searchedColumn: "",
        expendKeys: [],
        expandAllRows: false
    };

    /**
     * 创建新客户单位弹窗 Ref
     */
    createDepartmentModalRef = React.createRef();
    /**
     * 编辑新客户单位弹窗 Ref
     */
    editDepartmentModalRef = React.createRef();
    /**
     * 删除新客户单位弹窗 Ref
     */
    deleteDepartmentModalRef = React.createRef();
    /**
     * 迁移新客户单位弹窗 Ref
     */
    moveDepartmentModalRef = React.createRef();

    /**
     * 创建单位账户ref
     */
    createDepartmentAccountRef = React.createRef();

    departmentDetailRef = React.createRef();

    createRef = React.createRef();

    departmentCustomerRef = React.createRef();

    previewDepartmentEquipmentRef = React.createRef();

    exportEquipmentSummaryRef = React.createRef();

    searchInput = React.createRef();

    batchImportDepartmentRef = React.createRef();

    /**
     * 表格操作
     * @param node
     * @param entity
     * @param index
     * @param action
     * @returns {JSX.Element}
     */
    columnOperationsRender = (node, entity, index, action) => <Space>
        <Tooltip title="添加子单位">
            <Button icon
                    size="small"
                    onClick={() => this.createDepartmentModalRef.current.show(entity.id)}>
                <PlusSquareOutlined/>
            </Button>
        </Tooltip>
        <Tooltip title="查看单位客户">
            <Button icon
                    size="small"
                    onClick={() => this.previewDepartmentCustomer(entity)}>
                <EyeOutlined/>
            </Button>
        </Tooltip>
        <Tooltip title="查看单位详情">
            <Button icon
                    size="small"
                    onClick={() => this.previewDepartmentDetail(entity)}>
                <FileSearchOutlined/>
            </Button>
        </Tooltip>
        <Tooltip key="more" title="更多">
            <Dropdown key="more" overlay={this.renderColumnOptionsMenu(entity, action)} trigger={["click"]}>
                <Button icon size="small"><MoreOutlined/></Button>
            </Dropdown>
        </Tooltip>
    </Space>;

    /**
     * 列操作菜单
     * @param selectedKeys
     * @param confirm
     * @param dataIndex
     */
    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
            expendKeys: [],
            expandAllRows: true
        });
    };
    /**
     * 重置
     * @param clearFilters
     * @param selectedKeys
     * @param confirm
     * @param dataIndex
     */
    handleReset = (clearFilters, selectedKeys, confirm, dataIndex) => {
        clearFilters();
        this.setState({
            searchedColumn: dataIndex,
            expendKeys: [],
            searchText: selectedKeys[0]
        }, () => {
            this.handleSearch([], confirm, dataIndex);
        });
    };
    /**
     * 筛选
     * @param record
     * @param dataIndex
     * @param value
     * @returns {boolean}
     */
    filterIndex = (record, dataIndex, value) => {
        let res = record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        if (!res && record.children) {
            if (record.children.some(item => item[dataIndex].toString()
                .toLowerCase()
                .includes(value.toLowerCase()))) {
                res = true;
            } else {
                for (let i = 0; i < record.children.length; i++) {
                    res = this.filterIndex(record.children[i], dataIndex, value);
                    if (res) {
                        break;
                    }
                }
            }
        }
        return res;
    };

    getIndex = (children, dataIndex, value) => {
        let indexs = [];
        for (let i = 0; i < children.length; i++) {
            if (children[i][dataIndex].toString()
                .toLowerCase()
                .includes(value.toLowerCase())) {
                indexs.push(children[i].key);
            }
            if (children[i].children) {
                const ch = this.getIndex(children[i].children, dataIndex, value);
                indexs.push(...ch);
                if (ch && ch.length > 0) {
                    indexs.push(children[i].key);
                }
            }
        }
        return indexs;
    };

    getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
            <div style={{padding: 8}}>
                <Input ref={this.searchInput}
                       placeholder={"单位名称"}
                       value={selectedKeys[0]}
                       onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                       onPressEnter={() => this.handleReset(selectedKeys, confirm, dataIndex)}
                       style={{marginBottom: 8, display: "block"}}/>
                <Space>
                    <Button type="primary" onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined/>} size="small" style={{width: 90}}>
                        搜索
                    </Button>
                    <Button
                        onClick={() => this.handleReset(clearFilters, selectedKeys, confirm, dataIndex)}
                        size="small" style={{width: 90}}>
                        重置
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined style={{fontSize: "16px", color: filtered ? "#1890ff" : undefined}}/>
        ),
        onFilter: (value, record) => {
            let expendKeys = this.state.expendKeys;
            if (record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase())) {
                expendKeys.push(record.key);
            }
            if (record.children) {
                const ch = this.getIndex(record.children, dataIndex, value);
                expendKeys.push(...ch);
                if (ch && ch.length > 0) {
                    expendKeys.push(record.key);
                }
            }
            expendKeys = expendKeys.filter((item, index) => expendKeys.indexOf(item, 0) === index);
            return this.filterIndex(record, dataIndex, value);
        },
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => this.searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: "#ffc069",
                        padding: 0
                    }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ""}
                />
            ) : (text)
    });

    columns = [
        {
            title: "ID",
            hideInTable: true,
            hideInSearch: true,
            dataIndex: "id"
        },
        {
            title: "单位名称",
            dataIndex: "name",
            key: "name",
            ...this.getColumnSearchProps("name")
        },
        {
            title: "操作",
            valueType: "option",
            render: (node, entity, index, action) => this.columnOperationsRender(node, entity, index, action)
        }
    ];

    onExpandedRowsChange = e => {
        this.setState({
            expendKeys: e
        });
    };

    /**
     * 表格操作
     * @param entity
     * @param action
     * @returns {JSX.Element}
     */
    renderColumnOptionsMenu = (entity, action) => <Menu>
        <Menu.Item key="0">
            <span onClick={() => this.dialog(entity.id, this.editDepartmentModalRef)} className={style.btn}>编辑</span>
        </Menu.Item>
        <Menu.Item key="1">
            <span onClick={() => this.dialog(entity.id, this.createRef)} className={style.btn}>添加单位用户</span>
        </Menu.Item>
        <Menu.Item key="addDepartmentAccount">
            <span onClick={() => this.createDepartmentAccountRef.current.show(entity.id)}
                  className={style.btn}>添加单位账户</span>
        </Menu.Item>
        <Menu.Item key="2">
            <span onClick={() => this.moveDialog(entity, this.moveDepartmentModalRef)} className={style.btn}>迁移单位</span>
        </Menu.Item>
        <Menu.Item key="4">
            <span onClick={() => this.dialog(entity.id, this.previewDepartmentEquipmentRef)}
                  className={style.btn}>查看单位设备</span>
        </Menu.Item>
        {hasAnyRole(["admin"]) ? <Menu.Item key="5">
            <span onClick={() => this.exportEquipmentSummary(entity.id)}
                  className={style.btn}>导出设备摘要统计</span>
        </Menu.Item> : null}
        {
            hasAnyRole(["admin"]) ? <Menu.Item key="6">
            <span onClick={() => this.exportEquipmentStatistics(entity.id)}
                  className={style.btn}>导出设备统计</span>
            </Menu.Item> : null
        }
        <Menu.Item key="3" danger>
            <span onClick={() => this.deleteDialog(entity, this.deleteDepartmentModalRef)}
                  className={style.btn}>删除</span>
        </Menu.Item>
    </Menu>;

    /**
     * 导出设备摘要
     * @param departmentId  单位id
     */
    exportEquipmentSummary = (departmentId) => {
        axios.get(`${global.config.url}/resource/customer-department/${departmentId}/devices/summary-report`, {
            responseType: "blob",
            headers: {token: window.localStorage.getItem("Token")}
        }).then(response => {
            let url = window.URL.createObjectURL(
                new Blob([response.data], {
                    type:
                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8"
                })
            );
            let link = document.createElement("a");
            link.style.display = "none";
            link.href = url;
            link.setAttribute("download", "result.xlsx");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        }).catch(error => {
            console.warn(error.response);
        });
    };

    /**
     * 导出设备统计
     * @param departmentId 单位id
     */
    exportEquipmentStatistics = (departmentId) => {
        axios.get(`${global.config.url}/resource/customer-department/${departmentId}/devices/report`, {
            responseType: "blob",
            headers: {token: window.localStorage.getItem("Token")}
        }).then(response => {
            let url = window.URL.createObjectURL(
                new Blob([response.data], {
                    type:
                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8"
                })
            );
            let link = document.createElement("a");
            link.style.display = "none";
            link.href = url;
            link.setAttribute("download", "result.xlsx");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        }).catch(error => {
            console.warn(error.response);
        });
    };

    /**
     * 控制弹窗
     */
    dialog = (id, ref) => ref.current.show(id);

    // moveDialog = (entity, ref) => {
    //     !!entity.children ? message.error({key: "move", content: "该单位有子单位暂时不能迁移"}) : ref.current.show(entity.id);
    // };

    moveDialog = (entity, ref) => {
        ref.current.show(entity.id);
    };

    deleteDialog = (entity, ref) => {
        !!entity.children ? message.error({key: "delete", content: "该单位有子单位暂时不能删除"}) : ref.current.show(entity.id);
    };

    /**
     * 添加单位用户
     */
    addDepartmentUser = id => this.createRef.current.show(id);

    /**
     * 查看单位客户
     * @param entity
     */
    previewDepartmentCustomer = entity => this.departmentCustomerRef.current.show(entity.id, entity.name);

    /**
     * 查看单位详情
     */
    previewDepartmentDetail = entity => this.departmentDetailRef.current.show(entity.id, entity.name);

    /**
     * 显示单位迁移弹窗
     * @param entity
     */
    showMoveDepartmentDialog = entity => {
        if (!!entity.children) {
            message.error({content: "有子单位的暂时不能迁移", key: "cannotMoveCustomerDepartment"});
        } else {
            this.moveDepartmentModalRef.current.show(entity.id);
        }
    };

    toolbarRender = () => hasAnyRole(["admin"]) ? <Button type="primary" onClick={() => this.createDepartmentModalRef.current.show()}>新建单位</Button> : <></>

    /**
     * 请求客户单位数据
     * @returns {*}
     */
    requestPage = () => hasAnyRole(["admin"]) ? this.requestAllCustomerDepartment() : this.requestUserCustomerDepartment();
    //requestPage = () =>this.requestUserCustomerDepartment();

    requestAllCustomerDepartment = () => CustomerDepartmentApi.tree(getUser().departments[0].id).then(response => {
        return {data: response.data, success: true};
    }).catch(error => {
        console.warn("Get customer department failed", error);
        return {data: [], success: true, total: 0};
    });

    requestUserCustomerDepartment = () => CustomerDepartmentApi.tree(getUser().departments[0].id).then(res => {
        return {data: res.data, success: true};
    }).catch(err => {
        console.error("Request user customer department failed", err);
        return {data: [], success: true, total: 0};
    });

    tableRender = () => {
        return <Button type="primary" onClick={() => this.batchImportDepartmentRef.current.show()}>导入客户单位</Button>
    }

    reload = () => this.actionRef?.reload();

    render() {
        return <PageContainer header={{title: "客户单位", breadcrumb: {}}} extra={this.toolbarRender()}>
            <ProTable headerTitle="客户单位信息"
                      className={styleTable.searchTitle}
                      rowKey="id"
                      defaultSize="small"
                      pagination={false}
                      search={false}
                      toolBarRender={this.tableRender}
                      onExpandedRowsChange={this.onExpandedRowsChange}
                      options={{reload: true, setting: true, density: true}}
                      actionRef={actionRef => this.actionRef = actionRef}
                      columns={this.columns}
                      request={this.requestPage}/>

            <CreateCustomerDepartmentModal ref={this.createDepartmentModalRef} onSuccess={this.reload}/>
            <CreateDepartmentAccount ref={this.createDepartmentAccountRef} onSuccess={this.reload}/>
            <UpdateCustomerDepartmentModal ref={this.editDepartmentModalRef} onSuccess={this.reload}/>
            <DeleteCustomerDepartmentModal ref={this.deleteDepartmentModalRef} onSuccess={this.reload}/>
            <MoveCustomerDepartmentModal ref={this.moveDepartmentModalRef} onSuccess={this.reload}/>
            <PreviewCustomerDepartmentModal ref={this.departmentDetailRef} onSuccess={this.reload}/>
            <CreateCustomerModal onSuccess={this.reload} ref={this.createRef}/>
            <PreviewCustomerDepartmentCustomerDrawer onSuccess={this.reload} ref={this.departmentCustomerRef}/>
            <PreviewDepartmentEquipmentDrawer onSuccess={this.reload} ref={this.previewDepartmentEquipmentRef}/>
            <BatchImportModal onSuccess={this.reload} ref={this.batchImportDepartmentRef}/>
        </PageContainer>;
    }
}
