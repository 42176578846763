import React from "react";
import {Button, Col, Row, Space} from "antd";

import WorkorderApi from "api/workorder";
import {ProForm, ProFormTextArea} from "@ant-design/pro-components";
import DraggableModal from "component/DraggableModal";

/**
 * 挂起工单弹窗 Modal
 *
 * @description 挂起工单
 *
 * @author huang Carder
 * @since 2021-06-23
 * @version 1.0.0
 */
export default class HangUpWorkorderDialog extends React.Component {

    state = {
        loading: false,
        show: false,
        workorderId: ""
    };

    draggableRef = React.createRef();
    formRef = React.createRef();

    /**
     * 控制驳回工单对话框显示隐藏
     */

    show = id => {
        this.draggableRef.current.show();
        this.setState({workorderId: id});
    };

    close = () => {
        this.setState({loading: false});
        this.draggableRef.current.close();
    };

    /**
     * 控制 loading 加载效果显示
     */
    toggleLoading = () => this.setState({loading: !this.state.loading});

    /**
     * 挂起工单
     * @param data 表单数据
     */
    handle = data => {
        this.toggleLoading();
        data.workorderId = this.state.workorderId;
        WorkorderApi.hang(data).then(() => {
            this.close();
            if (!!this.props.onSuccess) {
                this.props.onSuccess();
            }
        }).catch(error => {
            this.toggleLoading();
            global.formily.setFieldsErrorFeedback(this.form, error.data);
            console.warn("Denied workorder failed", error);
        });
    };

    render() {
        return <DraggableModal onCancel={this.close} title="挂起工单" ref={this.draggableRef}>
            <ProForm formRef={this.formRef} onFinish={this.handle} layout={"vertical"} submitter={{
                resetButtonProps: {style: {display: "none"}},
                submitButtonProps: {style: {display: "none"}}
            }}>
                <ProFormTextArea name="reason" label="挂起原因" placeholder="请填写挂起原因"
                                 fieldProps={{maxLength: 120, showCount: true}}
                                 rules={[{pattern: /\S/, message: "不能为空"}]}/>
                <Row gutter={8} justify="end">
                    <Col>
                        <Space>
                            <Button disabled={this.state.loading} onClick={this.close}>取消</Button>
                            <Button loading={this.state.loading} type="primary" htmlType="submit">挂起</Button>
                        </Space>
                    </Col>
                </Row>
            </ProForm>
        </DraggableModal>;
    }
}
