import React from "react";
import {Button, Col, DatePicker, Form, Row, Select, Skeleton, Space, Switch, TreeSelect} from "antd";
import axios from "axios";
import ContractApi from "api/contract";
import moment from "moment";
import DraggableModal from "component/DraggableModal";
import dayjs from "dayjs";
import CustomerDepartmentApi from "../../../../../../api/customer/department";

/**
 * 导出Word文件
 * @description 导出工单Word文件
 */
export default class ExportOpsWorkorderAsWordModal extends React.Component {

    state = {
        show: false,
        loading: false,
        skeletonLoading: true,
        treeData: [],
        contractOption: []
    };

    formRef = React.createRef();
    draggableRef = React.createRef();

    // toggle = () => {
    //     this.setState({
    //         show: !this.state.show,
    //         loading: false
    //     }, async () => {
    //         if (this.state.show) {
    //             this.getAllContract();
    //             await this.getAllDepartment();
    //         }
    //     });
    // };

    show = async () => {
        this.draggableRef.current.show();
        await this.getAllDepartment();
        this.getAllContract();
    };

    close = () => {
        this.setState({loading: false});
        this.draggableRef.current.close();
    };

    toggleLoading = () => this.setState({loading: !this.state.loading});

    handle = data => {
        this.toggleLoading();
        if (this.props.creatorId) {
            data.creatorId = this.props.creatorId;
        }
        data.logicType = "ops";
        if (data.string) {
            data.startDate = dayjs(data.string[0]).format("YYYY-MM-DD");
            data.endDate = dayjs(data.string[1]).format("YYYY-MM-DD");
            data.string = undefined;
        }
        if (!!data.childrenClientDepartment) {
            data.childrenClientDepartment = 1
        }
        axios.get(`${global.config.url}/workorder/workorders/word`, {
            responseType: "blob",
            headers: {token: window.localStorage.getItem("Token")},
            params: data
        }).then(response => {
            this.close();
            this.props.onSuccess();
            let url = window.URL.createObjectURL(
                new Blob([response.data], {
                    type:
                        "application/msword"
                })
            );
            let link = document.createElement("a");
            link.style.display = "none";
            link.href = url;
            link.setAttribute("download", "result.doc");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        }).catch(error => {
            this.toggleLoading();
            console.warn("Export word fail", error.response);
        });
    };

    renderTrigger = () => <Button type="link" onClick={this.show}>导出Word</Button>;

    /**
     * 获取所有客户单位
     */
    getAllDepartment = async () => {
        const response = await CustomerDepartmentApi.page();
        const treeData = [];
        response.data.forEach((department) => {
            treeData.push(this.handleDepartmentTreeNode(department));
        });
        this.setState({
            treeData: treeData,
            skeletonLoading: false
        });
    };

    /**
     * 获取所有合同信息
     */
    getAllContract = () => {
        ContractApi.getAllContractInfo("163063635416059904").then(response => {
            let contractArr = [];
            response.data.forEach((item) => {
                let obj = {label: "", value: "" ,title: ""};
                obj.label = `${item.name}(${item.comment})`;
                obj.title = `${item.name}(${item.comment})`;
                obj.value = item.id;
                contractArr.push(obj);
            });
            this.setState({
                contractOption: contractArr
            });
        });
    };



    /**
     * 处理树形图数据
     * @param department  树形图中的每一项
     */
    handleDepartmentTreeNode = department => {
        const node = {
            title: department.name,
            key: department.id,
            value: department.id,
            children: []
        };
        if (!!department.children && department.children.length > 0) {
            department.children.forEach((subDepartment) => {
                node.children.push(this.handleDepartmentTreeNode(subDepartment));
            });
        }
        return node;
    };

    renderDialog = () => {
        return <DraggableModal onCancel={this.close} title="导出运维工单Word文件" ref={this.draggableRef}>
            <Skeleton active loading={this.state.skeletonLoading}>
                <Form ref={this.formRef} onFinish={this.handle} layout={"vertical"}>
                    <Form.Item name="string" label="时间范围">
                        <DatePicker.RangePicker style={{width: "100%"}}/>
                    </Form.Item>
                    <Form.Item name="type" label="服务类型">
                        <Select options={[
                            {label: "智慧党建维护", value: "zhdjwh"},
                            {label: "OA维护", value: "oawh"},
                            {label: "系统软件维护", value: "xtrjwh"},
                            {label: "网站维护", value: "wzwh"},
                            {label: "服务器维修", value: "fwqwx"},
                            {label: "网络设备维修", value: "wlsbwx"},
                            {label: "硬件安装", value: "yjaz"},
                            {label: "系统巡检", value: "xtxj"},
                            {label: "漏洞处理", value: "ldcl"},
                            {label: "敏感字眼处理", value: "mgzycl"}
                        ]}/>
                    </Form.Item>
                    <Row gutter={12} align="middle" justify="center">
                        <Col span={18}>
                            <Form.Item name="clientDepartmentId" label="客户单位">
                                <TreeSelect treeData={this.state.treeData} showSearch treeNodeFilterProp="title"/>
                            </Form.Item>
                        </Col>
                        <Col span={6} style={{marginTop: 26}}>
                            <Form.Item name="childrenClientDepartment">
                                <Switch checkedChildren="导出下级" unCheckedChildren="仅导出本级"/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item name="resourceId" label="合同名称">
                        <Select options={this.state.contractOption} showSearch filterOption={(input, option) => option.props.title === input || option.props.title.indexOf(input) !== -1}/>
                    </Form.Item>
                    <Row gutter={8} justify="end">
                        <Col>
                            <Space>
                                <Button onClick={this.toggle} disabled={this.state.loading}>取消</Button>
                                <Button htmlType="submit" loading={this.state.loading}>下载</Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
            </Skeleton>

        </DraggableModal>;
    };

    render() {
        return <>
            {this.renderTrigger()}
            {this.renderDialog()}
        </>;
    };
}
