import React, {Component} from "react";
import {ProForm, ProFormCascader, ProFormText, ProFormTreeSelect} from "@ant-design/pro-components";
import {Button, Col, Row, Space} from "antd";


import DraggableModal from "component/DraggableModal";
import {getUser, hasAnyRole, isAdmin} from "component/SecurityInterceptor";
import DepartmentApi from "api/user/department";
import StorehouseApi from "api/spares/storehouse";
import FileUploadRender from "component/FileUploadRender";

class EditStorehouseModal extends Component {

    draggableRef = React.createRef();
    formRef = React.createRef();
    fileUploadRef = React.createRef();
    map;
    geocoder;

    state = {
        departmentData: [],
        departmentAdmin: [],
        loading: false,
        storehouseId: "",
        lnglatDataLng: "",
        lnglatDataLat: ""
    };

    toggleLoading = () => this.setState({loading: !this.state.loading});

    show = (id) => {
        this.setState({storehouseId: id});
        this.draggableRef.current.show();
        this.requestUserDepartment();
        this.requestStorehouseInfo(id);
        //增加延迟时间 使 DOM 渲染出来后调用 getMap方法
        setTimeout(this.getMap, 100);
    };

    close = () => {
        this.draggableRef.current.close();
        this.setState({loading: false});
    };

    /**
     * 请求用户机构
     */
    requestUserDepartment = () => {
        const shouldRequestAllDepartment = isAdmin() || hasAnyRole(["customer-service"]);
        shouldRequestAllDepartment ? this.requestAllDepartment() : this.requestDepartment();
    };

    /**
     * 请求当前用户的机构
     */
    requestDepartment = () => {
        const userDepartments = getUser().departments;
        if (userDepartments !== undefined && userDepartments !== null && userDepartments.length >= 1) {
            DepartmentApi.findDepartmentTree(userDepartments[0].id).then(response => {
                this.setState({departmentData: this.processUserDepartmentTreeData(response.data)});
            }).catch(error => {
                console.log("Get user department failed", error);
            });
        } else {
            this.setState({departmentData: []});
        }
    };

    /**
     * 请求所有机构
     */
    requestAllDepartment = () => {
        DepartmentApi.findAllTrees().then(response => {
            this.setState({departmentData: this.processUserDepartmentTreeData(response.data)});
        }).catch(error => {
            console.log("Get all department failed", error);
        });
    };

    /**
     * 处理树形结构
     * @param tree
     */
    processUserDepartmentTreeData = tree => tree.map(item => ({
        title: item.name,
        value: item.id,
        children: !!item.children ? this.processUserDepartmentTreeData(item.children) : undefined
    }));

    onDepartmentChange = (value) => {
        this.requestDepartmentAdmin(value);
    };

    /**
     * 请求机构管理员
     * @param id
     */
    requestDepartmentAdmin = (id) => {
        DepartmentApi.getDepartmentAccount(id).then(res => {
            this.handleDepartmentAdmin(res.data);
        }).catch(err => {
            console.error("Request department admin failed", err);
        });
    };

    /**
     * 处理机构管理员
     * @param data
     */
    handleDepartmentAdmin = (data) => {
        data.forEach(item => {
            this.setState({departmentAdmin: [...this.state.departmentAdmin, {label: item.name, value: item.id}]});
        });
    };

    convert = address => {
        AMap.plugin(["AMap.Geocoder"], () => this.geocoder = new AMap.Geocoder());
        let marker = new AMap.Marker();
        this.geocoder.getLocation(address, (status, result) => {
            if (status === "complete" && result.geocodes.length) {
                let lnglat = result.geocodes[0].location;
                this.setState({
                    lnglatData: lnglat
                });
                marker.setPosition(lnglat);
                this.map.add(marker);
                this.map.setFitView(marker);
            } else {
                console.error("根据地址查询位置失败");
            }
        });
    };

    /**
     * 根据id请求仓库数据
     * @param id
     */
    requestStorehouseInfo = (id) => {
        StorehouseApi.find(id).then(res => {
            this.formRef.current.setFieldsValue({
                name: res.data.name,
                ares: [res.data.province, res.data.city, res.data.county],
                department: res.data.department.id,
                address: res.data.address
            });
            let address = res.data.province + res.data.city + res.data.county + res.data.address;
            this.convert(address);
            if (!!res.data && !!res.data.files) {
                const files = [];
                res.data.files.forEach(file => {
                    console.log(file);
                    files.push({
                        uid: file.id,
                        name: file.name,
                        url: file.path,
                        thumbUrl: file.path,
                        type: file.type
                    });
                });
                console.log(files);
                this.fileUploadRef.current.setFilesValue(files);
            }
        }).catch(err => {
            console.error("Request storehouse failed", err);
        });
    };

    getMap = () => {
        this.map = new AMap.Map("map-container", {
            pitch: 0,
            viewMode: "3D",
            terrain: true,
            center: [114.973172, 25.817861],
            zoom: 17,
            zoomEnable: false,
            dragEnable: false,
            resizeEnable: true
        });

        AMap.plugin(["AMap.PlaceSearch", "AMap.AutoComplete"], () => {
            new AMap.AutoComplete({input: "address"}).on("select", e => {
                this.formRef.current.setFieldsValue({address: e.poi.name});
                this.map.clearMap();
                this.map.add(new AMap.Marker({position: [e.poi.location.lng, e.poi.location.lat]}));
                this.setState({lnglatDataLng: e.poi.location.lng, lnglatDataLat: e.poi.location.lat});
                this.map.setFitView();
                let lnglat = [e.poi.location.lng, e.poi.location.lat];
                this.regeoCode(lnglat);
            });
        });
    };

    /**
     * 当所选的地址与详细地址不同时更新所选的地址
     * @param lnglat 经纬度
     */
    regeoCode = lnglat => new AMap.Geocoder().getAddress(lnglat, (status, result) => {
        // 特别行政区或直辖市的 city 为空 所有直接用 province
        this.formRef.current.setFieldsValue({
            ares: [
                result.regeocode.addressComponent.province,
                result.regeocode.addressComponent.city ? result.regeocode.addressComponent.city : result.regeocode.addressComponent.province,
                result.regeocode.addressComponent.district
            ]
        });
    });

    handle = (data) => {
        if (!!data.ares) {
            data.province = data.ares[0];
            data.city = data.ares[1];
            data.county = data.ares[2];
            data.ares = undefined;
        }
        data.id = this.state.storehouseId;
        data.longitude = this.state.lnglatDataLng || null;
        data.latitude = this.state.lnglatDataLat || null;
        data.newFiles = this.fileUploadRef.current.getFilesValue();
        data.delFiles = this.fileUploadRef.current.getDeletedFiles();
        this.toggleLoading();
        StorehouseApi.update(data).then(res => {
            this.toggleLoading();
            if (!!this.props.onSuccess) {
                this.props.onSuccess()
            }
            this.close();
        }).catch(err => {
            this.toggleLoading();
            console.error("Update storehouse failed", err);
        });
    };

    render() {
        return (<DraggableModal onCancel={this.close} title="编辑仓库" ref={this.draggableRef}>
                <ProForm formRef={this.formRef} onFinish={this.handle} layout={"vertical"} submitter={{
                    resetButtonProps: {style: {display: "none"}},
                    submitButtonProps: {style: {display: "none"}}
                }}>
                    <ProFormText name="name" label="仓库名称" placeholder="请输入仓库名称" required
                                 rules={[{required: true, message: "请输入仓库名称"}, {pattern: /\S/, message: "不能为空"}]}/>
                    <ProFormCascader required rules={[{required: true, message: "请选择所在地区"}]} name="ares" label="仓库地区"
                                     fieldProps={{options: require("asset/location.json")}} hidden/>
                    <ProFormTreeSelect required disabled rules={[{required: true, message: "请选择机构"}]} name="department"
                                       label="机构"
                                       fieldProps={{
                                           options: this.state.departmentData,
                                           showSearch: true,
                                           filterTreeNode: true,
                                           treeNodeFilterProp: "title",
                                           onChange: this.onDepartmentChange
                                       }}/>
                    {/*<ProFormSelect required rules={[{required: true, message: "请选择管理员"}]} name="ares1" label="管理员"*/}
                    {/*               fieldProps={{options: this.state.departmentAdmin, mode: "multiple"}}/>*/}
                    <ProFormText name="address" label="详细地址" placeholder="请输入详细地址" required id="address"
                                 autoComplete="off"
                                 rules={[{required: true, message: "请输入详细地址"}, {pattern: /\S/, message: "不能为空"}]}/>
                    <div style={{width: "100%", height: "200px", margin: "16px 0", pointerEvents: "none"}}
                         id="map-container">
                    </div>
                    <FileUploadRender label="附件上传(最多三张)" uploadPath="/repository/repository/file"
                                      ref={this.fileUploadRef}
                                      uploadProps={{maxCount: 3}}/>
                    <Row justify="end">
                        <Col>
                            <Space>
                                <Button disabled={this.state.loading} onClick={this.close}>取消</Button>
                                <Button loading={this.state.loading} type="primary" htmlType="submit">更新</Button>
                            </Space>
                        </Col>
                    </Row>
                </ProForm>
            </DraggableModal>
        );
    }
}

export default EditStorehouseModal;
