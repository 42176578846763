import React, {Component} from "react";
import {PageContainer} from "@ant-design/pro-components";
import style from "./index.module.css";
import EquipmentMapApi from "api/equipment/map";
import PreviewMapTable from "./table";
import {isAdmin, isAdminDepartment} from "component/SecurityInterceptor";
import EventBus from "../../../../utils/eventBus";

/**
 * 设备地图
 * 调用高德地图 api  创建地图
 * 调用查询设备经纬度接口  获取设备经纬度
 * 以海量点的方式把经纬度渲染到地图上
 * 点击每个点 弹出 Drawer 显示对应信息
 * 根据账号权限渲染对应点  管理员所有设备在地图上均可见  否则 根据所拥有的客户单位 来渲染显示对应的设备点
 *
 * FIXME 地图上的点聚合时点击可能无法获取到全部的聚合点信息：当多个点聚合在一起时点击，触发的点击事件中包含的点信息与实际的点信息不符，获取到的点数量少于实际点击的点数量
 */
// noinspection JSUnresolvedletiable,JSUnresolvedFunction,JSCheckFunctionSignatures,JSIgnoredPromiseFromCall,JSUnresolvedVariable
export default class EquipmentMap extends Component {

    map;
    previewMapTableRef = React.createRef();

    state = {
        flag: true,
        isAdmin: false
    };

    componentDidMount() {
        setTimeout(() => {
            this.map = new AMap.Map("equipment-map-container", {
                pitch: 0,
                viewMode: "3D",
                terrain: true,
                zoom: 15,
                resizeEnable: true,
                zooms: [4.6, 18],
                center: [114.973172, 25.817861],
                mapStyle: JSON.parse(localStorage.getItem("theme")) ? "amap://styles/darkblue" : null
            });
            this.renderPoints();
        }, 1000)
        EventBus.addListener("updateMapTheme", ({value}) => {
            this.map.setMapStyle(value ?? "amap://styles/normal")
        })
    }

    /**
     * 清除地图标记物
     */
    clearMarkersAndPoints = () => {
        this.map.clearMap();
    };

    renderPoints = () => {
        const customerDepartmentIds = localStorage.getItem("customerDepartmentIds");
        if (isAdmin() || isAdminDepartment()) {
            this.requestEquipmentMap();
        } else {
            if (!!customerDepartmentIds) {
                const data = {customerDepartmentIds: customerDepartmentIds};
                this.requestEquipmentMap(data);
            }
        }
    };

    requestEquipmentMap = data => {
        return EquipmentMapApi.queryCountyInside(data).then(response => {
            const cluster = new AMap.MarkerCluster(
                this.map,
                response.data.map(item => ({lnglat: item.split(",")})),
                {gridSize: 80}
            );
            cluster.on("click", this.onClusterClick);
        }).catch(error => console.warn("Request points failed.", error));
    };

    onClusterClick = value => {
        let obj = [];
        value.clusterData.forEach(item => {
            obj = `${obj}|${item.lnglat.lng},${item.lnglat.lat}`;
        });
        let newObj = obj.replace(/^\|/g, "").split("|");
        let lnglats = [...new Set(newObj)].join("|");
        this.previewMapTableRef.current.show(lnglats);
    };

    onMarkerClick = value => {
        this.previewMapTableRef.current.show(`${value.target.De.position.lng},${value.target.De.position.lat}`);
    };

    render() {
        return <PageContainer header={{title: "设备地图", breadcrumb: {}}}>
            <div className={style.map} id="equipment-map-container"/>
            <PreviewMapTable ref={this.previewMapTableRef}/>
        </PageContainer>;
    }
}

