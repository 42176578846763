import React, {Component} from "react";
import {Button, Col, Row, Space, Typography} from "antd";

import DraggableModal from "component/DraggableModal";

import StorehouseRecordApi from "api/spares/record";

class ReceiveScheduleModal extends Component {

    draggableRef = React.createRef();

    state = {
        loading: false,
        scheduleId: ""
    };

    toggleLoading = () => this.setState({loading: !this.state.loading});

    show = (id) => {
        this.draggableRef.current.show();
        this.setState({scheduleId: id});
    };

    close = () => {
        this.draggableRef.current.close();
    };

    handle = () => {
        this.toggleLoading();
        StorehouseRecordApi.auditSchedule(this.state.scheduleId).then(res => {
            this.toggleLoading();
            this.props.onSuccess();
            this.close();
            console.log(res);
        }).catch(err => {
            this.toggleLoading();
            console.error("Audit schedule failed", err);
        });
    };

    render() {
        return (<DraggableModal onCancel={this.close} title="接收" ref={this.draggableRef}>
                <Typography>数据确认无误 准备接收！</Typography>
                <Row gutter={8} justify="end">
                    <Col>
                        <Space>
                            <Button disabled={this.state.loading} onClick={this.close}>取消</Button>
                            <Button loading={this.state.loading} type="primary" onClick={this.handle}>接收</Button>
                        </Space>
                    </Col>
                </Row>
            </DraggableModal>
        );
    }
}

export default ReceiveScheduleModal;
