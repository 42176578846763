import React from "react";
import {Button, DatePicker, Divider, Drawer, Dropdown, Menu, Typography} from "antd";
import {ProDescriptions, ProTable} from "@ant-design/pro-components";

import AccountApi from "api/user/account";
import dayjs from "dayjs";

/**
 * 设置账户用户组
 * 获取所有用户组信息 获取当前账户已有的用户组信息
 * 编辑账户用户组 调用修改账户用户组接口 为账户 添加 或 移除账户用户组
 */
export default class PreviewScoreDetailDrawer extends React.Component {

    actionRef = React.createRef()

    state = {
        show: false,
        drawerWidth: 280,
        loading: false,
        accountId: "",
        userScore: "" | 0,
        selectTimerKey: "all",
        defaultPickerValue: ""
    };

    columns = [{
        title: "变化分数", dataIndex: "points", editable: false, hideInSearch: true
    }, {
        title: "变化原因", dataIndex: "reason", editable: false, hideInSearch: true
    }, {
        title: "变化时间", dataIndex: "createTime", editable: false, hideInSearch: true
    }, {
        title: "选择时间",
        key: "timer",
        hideInTable: true,
        renderFormItem: (_, {type, defaultRender, formItemProps, fieldProps, ...rest}, form) => {
            return (<DatePicker.RangePicker inputReadOnly showTime/>);
        }
    },]

    show = (id) => {
        this.setState({
            show: true,
            accountId: id,
            drawerWidth: window.innerWidth < 576 ? window.innerWidth : window.innerWidth * 0.75
        }, this.requestUserScore);
    };

    close = () => {
        this.setState({
            show: false, loading: false
        });
    };

    toggleLoading = () => {
        this.setState({
            loading: !this.state.loading
        });
    };

    /**
     * 查询用户总积分
     */
    requestUserScore = () => {
        AccountApi.queryUserScore(this.state.accountId).then(res => {
            this.setState({userScore: res.data})
        }).catch(err => {
            console.error("Request user score failed", err)
        })
    }

    requestScoreChange = params => {
        if (!!params.timer) {
            params.startTime = dayjs(params.timer[0]).format("YYYY-MM-DD hh:mm:ss")
            params.endTime = dayjs(params.timer[1]).format("YYYY-MM-DD hh:mm:ss")
            params.timer = undefined
        }
        params.accountId = this.state.accountId
        return AccountApi.queryUserScoreChange(params).then(res => {
            return {data: res.data.records, success: true, total: res.data.total};
        }).catch(err => {
            return {data: [], success: true, total: 0};
        })
    }

    /**
     * 查询年度积分
     * @param year
     */
    requestUserYearScore = year => {
        AccountApi.queryUserYearScore(this.state.accountId, {year}).then(res => {
            console.log(res)
            this.setState({userScore: res.data?.points})
        }).catch(err => {
            console.error("Request user year score failed", err)
        })
    }

    /**
     * 查询月度积分
     * @param params
     */
    requestUserMonthScore = params => {
        console.log(params)
        AccountApi.queryUserMonthScore(this.state.accountId, params).then(res => {
            this.setState({userScore: res.data?.points})
        }).catch(err => {
            console.error("Request user month score failed", err)
        })
    }

    onDataPickerChange = value => {
        if (!value) {
            this.requestUserScore();
            return
        }
        if (this.state.selectTimerKey === "year") {
            this.requestUserYearScore(dayjs(value).format("YYYY"))
        } else if (this.state.selectTimerKey === "month") {
            let params = {
                year: dayjs(value).format("YYYY-MM").split('-')[0],
                month: dayjs(value).format("YYYY-MM").split('-')[1],
            }
            this.requestUserMonthScore(params)
        }
    }

    onMenuSelect = value => {
        this.setState({
            selectTimerKey: value.key
        })
        if (value.key === "year") {
            this.requestUserYearScore(dayjs(new Date()).format("YYYY"));
            this.setState({defaultPickerValue: dayjs(new Date()).format("YYYY")})
            return;
        }
        if (value.key === "month") {
            let params = {
                year: dayjs(new Date()).format("YYYY-MM").split('-')[0],
                month: dayjs(new Date()).format("YYYY-MM").split('-')[1],
            }
            this.requestUserMonthScore(params)
            return;
        }
        this.requestUserScore()
    }

    renderSelectText = key => {
        const action = {
            "year": <Button>当前查看年度积分</Button>, "month": <Button>当前查看月度积分</Button>, "all": <Button>当前查看总积分</Button>
        }
        return action[key]
    }

    renderTimerSelect = key => {
        const action = {
            "year": <DatePicker onChange={this.onDataPickerChange} defaultValue={dayjs()} key="year" picker="year" format="YYYY"/>,
            "month": <DatePicker onChange={this.onDataPickerChange} defaultValue={dayjs()} key="month" picker="month" format="YYYY-MM"/>,
            "all": ""
        }

        return action[key]
    }

    renderProDescriptionsContent = () => {
        return [<span>
            {this.renderTimerSelect(this.state.selectTimerKey)}
            <Dropdown overlay={<Menu
                onClick={this.onMenuSelect}
                items={[{
                    key: 'year', label: (<span>年度积分</span>),
                }, {
                    key: 'month', label: (<span>月度积分</span>),
                }, {
                    key: 'all', label: (<span>总积分</span>),
                }]}/>} placement="bottomLeft" arrow={{pointAtCenter: true}}>
                         {this.renderSelectText(this.state.selectTimerKey)}
                </Dropdown>
            </span>]
    }


    render() {
        return <Drawer title="查看用户积分详情"
                       width={this.state.drawerWidth}
                       destroyOnClose
                       open={this.state.show}
                       onClose={this.close}>
            <ProDescriptions bordered size="small" layout="horizontal"
                             column={window.innerWidth < 800 ? 1 : 4}
                             extra={this.renderProDescriptionsContent()}>
                <ProDescriptions.Item label="用户总积分" span={4}>
                    {this.state.userScore ?? 0}
                </ProDescriptions.Item>
            </ProDescriptions>

            <Divider orientation="left">
                <Typography.Title level={5}>积分变化情况</Typography.Title>
            </Divider>

            <ProTable headerTitle={null}
                      rowKey="id"
                      defaultSize="small"
                      actionRef={this.actionRef}
                      options={false}
                      columns={this.columns}
                      request={this.requestScoreChange}/>

        </Drawer>;
    }
}
