import React, {Component} from "react";
import {ProCard} from "@ant-design/pro-components";
import workorderInfoApi from "api/workorderInfo";
import {Bar} from "@antv/g2plot";
import "./index.css";
import {Skeleton} from "antd";

/**
 * 服务概览
 * @description 工程师完成工单统计图
 */
export default class WorkorderStatisticsChart extends Component {
    state = {
        barData: [],
        loading: true
    };

    componentDidMount() {
        this.getWorkorderHandlerLatest();
    };

    /**
     * 请求工程师完成数量统计
     */
    getWorkorderHandlerLatest = () => {
        workorderInfoApi.workorderHandlerLatest().then(res => {
            const data = [];
            res.data.forEach((item) => {
                data.push({
                    name: item.name,
                    完成工单数: item.value
                });
            });
            this.setState({
                barData: data,
                loading: false
            }, () => {
                if (this.state.barData.length > 0) {
                    const piePlot = new Bar("barContainer", {
                        data: this.state.barData,
                        isGroup: true,
                        marginRatio: 0,
                        xField: "完成工单数",
                        yField: "name",
                        legend: {
                            position: "right"
                        },
                        label: {
                            position: "right",
                            offset: 4
                        },
                        interactions: [{type: "active-region", enable: false}]
                    });
                    piePlot.render();
                }
            });
        })


    };

    render() {
        return <ProCard colSpan={{xs: 24, sm: 8}} title="工程师完成工单统计" headerBordered size="small"
                        layout="center" style={{height: "100%"}}>
            <Skeleton active loading={this.state.loading}>
                {this.state.barData.length > 0 ? <div id="barContainer"/> : <span>暂无数据...</span>}
            </Skeleton>
        </ProCard>;
    }
}
