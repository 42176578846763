const StorehouseRecordApi = {
    /**
     * 分页查询出入库记录
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    page: params => global.axios.get("/repository/repository-stock-changes/page", {params}),

    /**
     * 查询入库申请单
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    repositoryInput: (id) => global.axios.get(`/repository/input/${id}`),

    /**
     * 查询出库申请单
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    repositoryOutput: (id) => global.axios.get(`/repository/output/${id}`),

    /**
     * 查询添加库存申请单
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    addRepository: (id) => global.axios.get(`/repository/add/${id}`),

    /**
     * 查询销毁库存申请单
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    destroyRepository: (id) => global.axios.get(`/repository/destroy/${id}`),

    /**
     * 查询退还申请单
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    revertRepository: (id) => global.axios.get(`/repository/revert/${id}`),

    /**
     * 查询申领记录
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    solicitRepository: (id) => global.axios.get(`/repository/solicit/${id}`),

    /**
     * 调拨记录
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    scheduleRepository: (id) => global.axios.get(`/repository/schedule/${id}`),

    /**
     * 审核调拨单
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    auditSchedule: (id) => global.axios.patch(`/repository/schedule/${id}/audit`),

    /**
     * 驳回调拨单
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    rejectSchedule: (id) => global.axios.patch(`/repository/schedule/${id}/reject`),

    /**
     * 退还审核
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    auditRevert: id => global.axios.patch(`/repository/revert/${id}/audit`),

    /**
     * 驳回退还
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    rejectRevert: id => global.axios.patch(`/repository/revert/${id}/reject`)
};

export default StorehouseRecordApi;
