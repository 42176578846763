import React, {Component} from "react";
import RemoteApi from "../../../api/remote/RemoteApi";
import {PageContainer, ProTable} from "@ant-design/pro-components";
import {workRecStatus} from "../../../type/Types";
import {DatePicker} from "antd";

/**
 * @author       : AngelBeats
 * @date         : 2023-06-01 15:38:15
 * @description  : 远程报单记录
 */
class RemoteWorkRec extends Component {

    actionRef = null
    columns = [
        {
            title: "设备识别码",
            dataIndex: "sn",
            editable: false
        },
        {
            title: "发起设备报修码",
            dataIndex: "deviceId",
            hideInSearch: true
        },
        {
            title: "创建时间",
            dataIndex: "createTime",
            hideInSearch: true
        },
        {
            title: "处理人",
            dataIndex: "dealUserName",
            hideInSearch: true
        },
        {
            title: "状态",
            dataIndex: "status",
            // hideInSearch: true,
            valueEnum: workRecStatus
        },
        {
            title: "创建时间",
            dataIndex: "time",
            hideInTable: true,
            renderFormItem: (_, {fieldProps}) => {
                return (
                    <DatePicker.RangePicker {...fieldProps} inputReadOnly/>
                );
            }
        },
    ]
    /**
     * 渲染顶部按钮
     * @returns {JSX.Element[]}
     */
    renderToolbar = () => [
        // <Button key="create" type="primary" onClick={() => this.createRef.current.show()}>新建设备</Button>
    ]

    /**
     * 请求列表数据
     * @param params
     */
    requestPage = params => RemoteApi.WorkRecPage(params).then(response => {
        return {data: response.data.records, success: true};
    }).catch(error => {
        console.warn("Get remote device list failed", error);
        return {data: [], success: true, total: 0};
    });
    /**
     * 表格页刷新
     * @returns {*}
     */
    reload = () => this.actionRef?.reload();
    render() {
        return (
            <PageContainer header={{title: "远控保单记录", breadcrumb: {}}} extra={this.renderToolbar()}>
                <ProTable
                    rowKey="id"
                    defaultSize="small"
                    options={{reload: true, setting: true, density: true}}
                    actionRef={actionRef => this.actionRef = actionRef}
                    columns={this.columns}
                    request={this.requestPage}/>
            </PageContainer>
        )
    }

}
export default RemoteWorkRec
