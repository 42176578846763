import React, {Component} from "react";
import {PageContainer, ProCard} from "@ant-design/pro-components";

import DispatchStatistic from "./component/workorder/DispatchStatistic";
import ReceiveStatistic from "./component/workorder/ReceiveStatistic";
import TodoWorkorderStatistic from "./component/workorder/TodoWorkorderStatistic";
import FinishedStatistic from "./component/workorder/FinishedStatistic";
import TrendStatistic from "./component/workorder/TrendStatistic";
import TimeoutWorkorderStatistic from "./component/workorder/TimeoutWorkorderStatistic";

import WorkorderStatistic from "./component/system/WorkorderStatistic";
import DepartmentStatistic from "./component/system/DepartmentStatistic";
import CustomerStatistic from "./component/system/CustomerStatistic";
import ServiceProvidersStatistic from "./component/system/ServiceProvidersStatistic";
import EngineerStatistic from "./component/system/EngineerStatistic";
import EquipStatistic from "./component/system/EquipStatistic";

import EvaluationChart from "./component/chart/EvaluationChart";
import TrendChart from "./component/chart/TrendChart";
import WorkorderStatisticsChart from "./component/chart/WorkorderStatisticsChart";

import dzp from "asset/preview/dzp.png";
import djs from "asset/preview/djs.png";
import dwc from "asset/preview/dwc.png";
import ywc from "asset/preview/ywc.png";
import ypj from "asset/preview/ypj.png";
import cqgd from "asset/preview/cqgd.png";

/**
 * 服务概览
 */
export default class Index extends Component {

    render() {
        return <PageContainer>
            <ProCard wrap ghost>
                <ProCard title="工单信息" headerBordered gutter={[12, 12]} wrap size="small">

                    <ProCard colSpan={{xs: 24, sm: 12, lg: 4}} layout="center"
                             style={{backgroundImage: `url(${dzp})`, backgroundSize: "cover", borderRadius: "8px"}}
                             bordered>
                        <DispatchStatistic/>
                    </ProCard>

                    <ProCard colSpan={{xs: 24, sm: 12, lg: 4}} layout="center"
                             style={{backgroundImage: `url(${djs})`, backgroundSize: "cover", borderRadius: "8px"}}
                             bordered>
                        <ReceiveStatistic/>
                    </ProCard>

                    <ProCard colSpan={{xs: 24, sm: 12, lg: 4}} layout="center"
                             style={{backgroundImage: `url(${dwc})`, backgroundSize: "cover", borderRadius: "8px"}}
                             bordered>
                        <TodoWorkorderStatistic/>
                    </ProCard>

                    <ProCard colSpan={{xs: 24, sm: 12, lg: 4}} layout="center"
                             style={{backgroundImage: `url(${ywc})`, backgroundSize: "cover", borderRadius: "8px"}}
                             bordered>
                        <FinishedStatistic/>
                    </ProCard>

                    <ProCard colSpan={{xs: 24, sm: 12, lg: 4}} layout="center"
                             style={{backgroundImage: `url(${ypj})`, backgroundSize: "cover", borderRadius: "8px"}}
                             bordered>
                        <TrendStatistic/>
                    </ProCard>

                    <ProCard colSpan={{xs: 24, sm: 12, lg: 4}} layout="center"
                             style={{backgroundImage: `url(${cqgd})`, backgroundSize: "cover", borderRadius: "8px"}}
                             bordered>
                        <TimeoutWorkorderStatistic/>
                    </ProCard>
                </ProCard>

                <ProCard wrap ghost gutter={[0, 12]}>
                    <ProCard colSpan={{xs: 24, sm: 16}} layout="center" ghost>
                        <ProCard gutter={[12, 12]} wrap ghost>
                            <ProCard colSpan={24} gutter={[12, 12]} title="系统数据" headerBordered wrap layout="center"
                                     size="small">
                                <ProCard colSpan={{xs: 24, sm: 12, lg: 4}} ghost layout="center"
                                         style={{borderColor: "#A8D9FF", borderRadius: "6px"}} bordered>
                                    <WorkorderStatistic/>
                                </ProCard>
                                <ProCard colSpan={{xs: 24, sm: 12, lg: 4}} ghost layout="center"
                                         style={{borderColor: "#A8D9FF", borderRadius: "6px"}} bordered>
                                    <DepartmentStatistic/>
                                </ProCard>
                                <ProCard colSpan={{xs: 24, sm: 12, lg: 4}} ghost layout="center"
                                         style={{borderColor: "#A8D9FF", borderRadius: "6px"}} bordered>
                                    <CustomerStatistic/>
                                </ProCard>
                                <ProCard colSpan={{xs: 24, sm: 12, lg: 4}} ghost layout="center"
                                         style={{borderColor: "#A8D9FF", borderRadius: "6px"}} bordered>
                                    <ServiceProvidersStatistic/>
                                </ProCard>
                                <ProCard colSpan={{xs: 24, sm: 12, lg: 4}} ghost layout="center"
                                         style={{borderColor: "#A8D9FF", borderRadius: "6px"}} bordered>
                                    <EngineerStatistic/>
                                </ProCard>
                                <ProCard colSpan={{xs: 24, sm: 12, lg: 4}} ghost layout="center"
                                         style={{borderColor: "#A8D9FF", borderRadius: "6px"}} bordered>
                                    <EquipStatistic/>
                                </ProCard>
                            </ProCard>
                            <ProCard colSpan={24} layout="center" ghost>
                                <ProCard gutter={[12, 12]} wrap ghost>
                                    <ProCard colSpan={{xs: 24, sm: 16}} wrap ghost headerBordered layout="center"
                                             bordered>
                                        <EvaluationChart/>
                                    </ProCard>
                                    <ProCard colSpan={{xs: 24, sm: 8}} ghost headerBordered layout="center"
                                             bordered>
                                        <TrendChart/>
                                    </ProCard>
                                </ProCard>
                            </ProCard>
                        </ProCard>
                    </ProCard>
                    <ProCard colSpan={{xs: 24, sm: 8}} ghost style={{height: "100%"}} headerBordered
                             layout="center" bordered>
                        <WorkorderStatisticsChart/>
                    </ProCard>
                </ProCard>
            </ProCard>
        </PageContainer>;
    }
}
