const EquipmentTypeApi = {
    /**
     * 分页查询
     */
    page: (data) => global.axios.get("/resource/device/types/page", {params: data}),
    /**
     * 新建设备类型
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    create: (data) => global.axios.post("/resource/device/type", data),
    /**
     * 删除设备类型
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    delete: (id) => global.axios.delete(`/resource/device/type/${id}`),
    /**
     * 查询指定设备类型信息
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    info: (id) => global.axios.get(`/resource/device/type/${id}`),
    /**
     * 编辑设备类型
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    edit: (data) => global.axios.patch(`/resource/device/type`, data),

    /**
     * 创建知识库文章
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    createKnowledge: (data) => global.axios.post(`/resource/article`, data),

    /**
     * 查询所有设备类型
     */
    selectAll: (params) => {
        //return global.axios.get("/resource/device/types/selectAll")
        return global.axios.get("/resource/device/types", {params})
    },
    /**
     * 查询所有已经监控设备类型
     * @returns {Promise<AxiosResponse<any>>}
     */
    selectAllMonitoring: () => global.axios.get("/resource/device/all/monitoring"),
    /**
     * 更新设备类型的监控状态
     * @param ids
     */
    updateMonitoring: (ids) => global.axios.patch("/resource/device/monitoring", ids),

};

export default EquipmentTypeApi;
