import React, {Component} from "react";
import {Button, Tooltip} from "antd";
import {EyeOutlined} from "@ant-design/icons";
import {PageContainer, ProTable} from "@ant-design/pro-components";

import StorehouseRecordApi from "api/spares/record";

import PreviewAddRecordDrawer from "./drawer/PreviewAddRecordDrawer";
import PreviewRevertRecordDrawer from "./drawer/PreviewRevertRecordDrawer";
import PreviewSolicitRecordDrawer from "./drawer/PreviewSolicitRecordDrawer";
import PreviewDestroyRecordDrawer from "./drawer/PreviewDestroyRecordDrawer";
import PreviewScheduleRecordDrawer from "./drawer/PreviewScheduleRecordDrawer";
import {getUser, hasAnyRole, isAdmin} from "component/SecurityInterceptor";
import CreateIdBecomeCreateName from "component/CreateIdBecomeCreateName";

class SparesRecord extends Component {

    previewAddRecordRef = React.createRef();
    previewRevertRecordRef = React.createRef();
    previewSolicitRecordRef = React.createRef();
    previewDestroyRecordRef = React.createRef();
    previewScheduleRecordRef = React.createRef();

    columns = [
        {
            title: "ID",
            dataIndex: "id",
            editable: false,
            hideInTable: true,
            hideInSearch: true
        },
        {
            title: "仓库名",
            dataIndex: ["repository", "name"],
            editable: false,
            hideInSearch: true
        },
        {
            title: "名称",
            dataIndex: ["stocktype", "name"],
            hideInSearch: true
        },
        {
            title: "类型",
            dataIndex: ["stocktype", "model"],
            editable: false,
            hideInSearch: true
        },
        {
            title: "记录类型",
            dataIndex: "type",
            valueEnum: {
                "add": {
                    "text": "添加库存"
                },
                "revert": {
                    "text": "工程师退还"
                },
                "solicit": {
                    "text": "工程师领用"
                },
                "schedule": {
                    "text": "库存调拨"
                },
                "destroy": {
                    "text": "消耗"
                }
            }
        },
        {
            title: "出入库数量",
            dataIndex: "changed",
            hideInSearch: true,
            align: "right"
        },
        {
            title: "剩余数量",
            dataIndex: "balance",
            hideInSearch: true,
            align: "right"
        },
        {
            title: "操作人",
            dataIndex: "creatorName",
            hideInSearch: true
        },
        {
            title: "操作时间",
            dataIndex: "createTime",
            hideInSearch: true
        },
        {
            title: "操作",
            valueType: "option",
            render: (node, entity, index, action) => this.renderColumnOperations(node, entity, index, action)
        }
    ];

    renderColumnOperations = (node, entity, index, action) => {
        return [
            <Tooltip title="查看记录" key="preview">
                <Button icon size="small" onClick={() => this.previewRecord(entity)}>
                    <EyeOutlined/>
                </Button>
            </Tooltip>
        ];
    };

    previewRecord = (entity) => {
        const inOrOut = entity.changed > 0 ? "input" : "output";
        const action = {
            add: () => this.previewAddRecordRef.current.show(entity.evidence),
            revert: () => this.previewRevertRecordRef.current.show(entity.evidence),
            solicit: () => this.previewSolicitRecordRef.current.show(entity.evidence),
            schedule: () => this.previewScheduleRecordRef.current.show(entity.evidence, inOrOut),
            destroy: () => this.previewDestroyRecordRef.current.show(entity.evidence)
        };
        action[entity.type]();
    };


    requestPage = async (params) => {
        if (hasAnyRole(["user-department-manager"]) && !hasAnyRole(["admin"])) {
            params.department = getUser().departments[0].id;
        }
        if (!isAdmin() && !hasAnyRole(["user-department-manager"])) {
            params.user = getUser().id;
        }
        return StorehouseRecordApi.page(params).then(async (res) => {
            let page = await CreateIdBecomeCreateName(res.data.records);
            return {data: page, total: res.data.total, success: true};
        }).catch(err => {
            console.error("Request record page failed", err);
            return {data: [], total: 0, success: true};
        });
    };

    reload = () => this.actionRef.reload();

    render() {
        return <PageContainer header={{title: "出入库记录", breadcrumb: {}}}>
            <ProTable headerTitle="出入库记录"
                      rowKey="id"
                      defaultSize="small"
                      options={{reload: true, setting: true, density: true}}
                      actionRef={actionRef => this.actionRef = actionRef}
                      columns={this.columns}
                      request={this.requestPage}/>
            <PreviewAddRecordDrawer onSuccess={this.reload} ref={this.previewAddRecordRef}/>
            <PreviewRevertRecordDrawer onSuccess={this.reload} ref={this.previewRevertRecordRef}/>
            <PreviewSolicitRecordDrawer onSuccess={this.reload} ref={this.previewSolicitRecordRef}/>
            <PreviewDestroyRecordDrawer onSuccess={this.reload} ref={this.previewDestroyRecordRef}/>
            <PreviewScheduleRecordDrawer onSuccess={this.reload} ref={this.previewScheduleRecordRef}/>
        </PageContainer>;
    }
}

export default SparesRecord;
