const AccountApi = {
    //分页
    page: (params) => global.axios.get("/user/accounts/page", {params}),

    //编辑
    edit: (params) => global.axios.patch("/user/account", params),

    //创建
    create: (params) => global.axios.post("/user/account", params),

    //用户状态
    userState: (params) => global.axios.patch("/user/account/status", params),

    //分页编辑删除
    editDelete: (params) => global.axios.delete("/user/account", params),

    //删除
    delete: (params) => global.axios.delete(`/user/account/${params.accountId}`),

    //删除多个
    deletes: (params) => global.axios.delete("/user/accounts", {data: params}),

    //重置密码
    resetPassword: (params) => global.axios.patch("/user/account/rest-password", params),

    //获取所有用户组
    getGroup: (params) => global.axios.get("/user/groups", {params}),

    //获取所有用户组
    getGroupId: (params) => global.axios.get("/user/account/group-ids", params),

    //设置用户组
    setGroup: (params) => global.axios.put("/user/groups-account", params),

    //获取所有用户组
    getIdentitie: (params) => global.axios.get("/user/identities", {params}),

    //获取所有用户组
    getIdentitieId: (params) => global.axios.get("/user/account/identity-ids", params),

    //设置用户组
    setIdentitie: (params) => global.axios.put("/user/identities-account", params),

    //获取所有权限
    getPermissions: (params = {}) => global.axios.get("/user/permissions", {params}),

    //设置权限
    setPermissions: (params) => global.axios.put("/user/account-permissions", params),

    //获取所有角色
    getRoles: (params = {}) => global.axios.get("/user/roles", {params}),

    //设置角色
    setRole: (params) => global.axios.put("/user/account/roles", params),

    //增加角色
    addRole: (params) => global.axios.patch("/user/account/roles", params),

    //获取当前账号权限
    getUserPermissions: (params) => global.axios.get("/user/account/permission-ids", params),

    //获取当前账号的角色
    getUserRole: (params) => global.axios.get("/user/account/role-ids", params),

    //查询选中账号信息
    findUserInfo: (params) => global.axios.get("/user/account", params),

    /**
     * 查询-单位下所有账户
     * @departmentId 单位id
     * @returns {Promise<AxiosResponse<any>>}
     */
    getDepartmentAccount: (departmentId) => global.axios.get(`/user/department/${departmentId}/accounts`),


    /**
     * 批量增加账号角色
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    addAccountRoles: data => global.axios.patch("/user/accounts/role", data),

    /**
     * 批量查询账号信息
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    batchRequestAccount: params => global.axios.get("/user/accounts", {params}),

    /**
     * 设置用户状态
     * @param id
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    setUserStatus: (id, data) => global.axios.patch(`/user/account/${id}/situation`, data),

    /**
     * 添加用户积分
     * @param accountId
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    addUserScore: (accountId, data) => global.axios.post(`/user/account/${accountId}/points/calc`, data),

    /**
     * 查询用户积分
     * @param accountId
     * @returns {Promise<AxiosResponse<any>>}
     */
    queryUserScore: accountId => global.axios.get(`/user/account/${accountId}/points`),

    /**
     * 分页查询用户积分变化情况
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    queryUserScoreChange: params => global.axios.get('/user/points-changes/page', {params}),

    /**
     * 查询用户年度积分
     * @param accountId
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    queryUserYearScore: (accountId, params) => global.axios.get(`/user/account/${accountId}/points/yearly`, {params}),

    /**
     * 查询用户月度积分
     * @param accountId
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    queryUserMonthScore: (accountId, params) => global.axios.get(`/user/account/${accountId}/points/monthly`, {params}),

    /**
     * 请求工程师位置信息
     * @returns {Promise<AxiosResponse<any>>}
     */
    requestAccountLocation: () => global.axios.get("/user/account/location/list"),

    /**
     * 查询工程师详情
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    findAccountDetail: params => global.axios.get("/user/account", {params}),

}
export default AccountApi;

