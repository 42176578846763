import React, {Component} from "react";
import {ProForm, ProFormSelect} from "@ant-design/pro-components";
import {Button, Col, Row, Space} from "antd";

import DraggableModal from "component/DraggableModal";

import StorehouseTypeApi from "api/spares/type";

class AddSparesTypeInfo extends Component {

    draggableRef = React.createRef();
    formRef = React.createRef();

    state = {
        typeSelect: [],
        sparesInfo: {},
        stocks: [],
        typeId: ""
    };

    show = (stocks, storehouseId) => {
        this.draggableRef.current.show();
        !!storehouseId ? this.requestStorehouseType(storehouseId) : this.requestAllStorehouseType();
        this.setState({stocks});
    };

    close = () => {
        this.draggableRef.current.close();
        this.setState({typeSelect: [], sparesInfo: {}});
    };

    requestAllStorehouseType = () => {
        StorehouseTypeApi.findAll().then(res => {
            this.setState({
                typeSelect: res.data.map(item => ({label: item.name, value: item.id}))
            });
        }).catch(err => {
            console.error("Request all storehouse type failed", err);
        });
    };

    requestStorehouseType = (id) => {
        StorehouseTypeApi.findTypeForStorehouse(id).then(res => {
            res.data.forEach(item => {
                this.setState({typeSelect: [...this.state.typeSelect, {label: item.name, value: item.id}]});
            });
        }).catch(err => {
            console.error("Request storehouse type failed", err);
        });
    };

    onTypeChange = (value) => {
        StorehouseTypeApi.find(value).then(res => {
            this.setState({
                typeId: value,
                sparesInfo: {
                    model: res.data.model,
                    stocktype: res.data.id,
                    classified: res.data.classified,
                    count: 1,
                    manufacturer: res.data.manufacturer.id
                }
            });
        }).catch(err => {
            console.error("Find storehouse type failed", err);
        });
    };

    closeOfParams = () => {
        console.log(this.state.sparesInfo);
        this.props.onSuccess(this.state.sparesInfo);
        this.close();
    };

    handle = () => {
        if (this.state.stocks === undefined || this.state.stocks === null) {
            this.closeOfParams();
        } else {
            this.state.stocks.some(item => item.stocktype === this.state.typeId) ? this.formRef.current.setFields([{
                name: "stocktype",
                errors: ["该类型已存在"]
            }]) : this.closeOfParams();
        }
    };

    render() {
        return (<DraggableModal onCancel={this.close} title="添加备件信息" ref={this.draggableRef}>
                <ProForm formRef={this.formRef} onFinish={this.handle} layout={"vertical"} submitter={{
                    resetButtonProps: {style: {display: "none"}},
                    submitButtonProps: {style: {display: "none"}}
                }}>
                    <ProFormSelect
                        label="类型"
                        showSearch
                        name="stocktype"
                        rules={[{required: true}]}
                        options={this.state.typeSelect}
                        onChange={this.onTypeChange}
                    />
                    <Row gutter={8} justify="end">
                        <Col>
                            <Space>
                                <Button disabled={this.state.loading} onClick={this.close}>取消</Button>
                                <Button loading={this.state.loading} type="primary" htmlType="submit">添加</Button>
                            </Space>
                        </Col>
                    </Row>
                </ProForm>
            </DraggableModal>
        );
    }
}

export default AddSparesTypeInfo;
