import React, {Component} from "react";
import {Button, Col, Drawer, message, Row, Space} from "antd";
import {
    EditableProTable,
    ProForm,
    ProFormSelect,
    ProFormText,
    ProFormTextArea,
    ProFormTreeSelect
} from "@ant-design/pro-components";

import DepartmentApi from "api/user/department";
import StorehouseApi from "api/spares/storehouse";
import EquipmentMakerApi from "api/equipment/maker";
import StorehouseTypeApi from "api/spares/type";
import StorehouseInventoryApi from "api/spares/storehouse/inventory";
import ChooseSparesModal from "../modal/ChooseSparesModal";

class ScheduleInventoryDrawer extends Component {
    state = {
        show: false,
        loading: false,
        drawerWidth: 600,
        repositoryId: "",
        typeSelect: [],
        manufacturerSelect: [],
        sparesData: [],
        storehouseOption: [],
        stocks: []
    };

    formRef = React.createRef();
    chooseSparesRef = React.createRef();

    show = (id) => {
        this.setState({
            show: true,
            repositoryId: id,
            drawerWidth: window.innerWidth < 576 ? window.innerWidth : window.innerWidth * 0.65
        }, () => {
            this.requestStorehouseType();
            this.requestStorehouseInfo(id);
            this.requestManufacturerInfo();
        });
    };

    close = () => {
        this.setState({
            show: false,
            loading: false,
            storehouseOption: [],
            stocks: []
        });
    };

    renderColumnOptions = (text, record, _, action) => {
        return [
            <a key="editable" onClick={() => {
                action?.startEditable?.(record.id);
            }}>
                编辑
            </a>,
            <a key="delete" onClick={() => {
                this.setState({stocks: this.state.stocks.filter((item) => item.id !== record.id)});
            }}>
                删除
            </a>
        ];
    };

    solicitColumns = [
        {
            title: "id",
            dataIndex: "id",
            hideInSearch: true,
            hideInTable: true
        },
        {
            title: "类型",
            dataIndex: "stocktypeName",
            hideInSearch: true,
            readonly: true
        },
        {
            title: "型号",
            dataIndex: "model",
            hideInSearch: true,
            readonly: true
        },
        {
            title: "制造商",
            dataIndex: "manufacturerName",
            readonly: true
        },
        {
            title: "数量",
            dataIndex: "count",
            hideInSearch: true,
            editable: () => true
        },
        {
            title: "是否SM",
            dataIndex: "classified",
            hideInSearch: true,
            readonly: true,
            valueEnum: {
                "0": {
                    "text": "否"
                },
                "1": {
                    "text": "是"
                }
            }
        },
        {
            title: "操作",
            valueType: "option",
            render: (text, record, _, action) => this.renderColumnOptions(text, record, _, action)
        }
    ];

    renderColumnOperations = (node, entity, index, action) => (
        <Space>
            <Button size="small" type="link" onClick={() => this.solicit(entity)}>
                调拨
            </Button>
        </Space>
    );

    toggleLoading = () => this.setState({loading: !this.state.loading});

    requestStorehouseInfo = id => {
        StorehouseApi.find(id).then(res => {
            this.formRef.current.setFieldsValue({
                name: res.data.name,
                address: `${res.data.province} - ${res.data.city} - ${res.data.county} - ${res.data.address}`
            });
        }).catch(err => {
            console.error("Request storehouse info failed", err);
        });
    };

    requestManufacturerInfo = () => {
        EquipmentMakerApi.queryAll().then(res => {
            res.data.forEach(item => {
                this.setState({
                    manufacturerSelect: [...this.state.manufacturerSelect, {
                        label: item.name,
                        value: item.id
                    }]
                });
            });
        }).catch(err => {
            console.error("Request manufacturer info failed", err);
        });
    };

    requestStorehouseType = () => {
        StorehouseTypeApi.findAll().then(res => {
            res.data.forEach(item => {
                this.setState({typeSelect: [...this.state.typeSelect, {label: item.name, value: item.id}]});
            });
        }).catch(err => {
            console.error("Request storehouse type failed", err);
        });
    };

    requestAllDepartment = () => {
        return DepartmentApi.findAllTrees().then(response => {
            return this.processUserDepartmentTreeData(response.data);
        }).catch(error => {
            console.log("Get all department failed", error);
            return [];
        });
    };

    processUserDepartmentTreeData = tree => tree.map(item => ({
        title: item.name,
        value: item.id,
        children: !!item.children ? this.processUserDepartmentTreeData(item.children) : undefined
    }));

    requestAllStorehouse = (id) => {
        StorehouseApi.findAll({departmentId: id}).then(res => {
            let data = [];
            res.data.forEach(item => {
                data.push({
                    label: item.name,
                    value: item.id
                })
            });
            this.formRef.current.setFieldsValue({targetRepository: ""})
            this.setState({storehouseOption: data});
        }).catch(err => {
            console.error("Request all storehouse failed", err);
        });
    };

    onTargetDepartmentChange = (value) => {
        this.requestAllStorehouse(value);
        this.setState({storehouseOption: []})
    };

    reload = (value) => {
        let stocks = this.state.stocks;
        const isStocksNotEmpty = stocks !== undefined && stocks !== null && stocks.length > 0;
        stocks = isStocksNotEmpty ? stocks : [];
        this.setState({stocks: [...stocks, value]});
    };

    /**
     * 编辑领用备件触发
     * @param _
     * @param data
     */
    onEditSave = (_, data) => {
        if (Number(data.count) > Number(data.total)) {
            message.error("数量超过最大库存");
        } else if (data.count <= 0) {
            message.error("数量不能小于等于1");
        } else if (!Number(data.count)) {
            message.error("请输入纯数字！");
        } else {
            this.state.stocks.forEach(item => {
                if (item.id === data.id) {
                    item.count = Number(data.count).toFixed(0);
                }
            });
        }
    };

    handle = data => {
        if (this.state.stocks.length === 0) {
            message.error({key: "delete", content: "请选择库存"});
            return;
        }
        data.originRepository = this.state.repositoryId;
        data.stocks = this.state.stocks;
        this.toggleLoading();
        StorehouseInventoryApi.schedule(data).then(res => {
            this.toggleLoading();
            this.props.onSuccess();
            this.close();
        }).catch(err => {
            this.toggleLoading();
            message.error(err.data);
            console.error("Storehouse schedule failed", err);
        });
    };

    render() {
        return <Drawer title="调拨"
                       destroyOnClose
                       width={this.state.drawerWidth}
                       open={this.state.show}
                       closable={!this.state.loading}
                       onClose={this.close}>
            <ProForm formRef={this.formRef} onFinish={this.handle} layout={"vertical"} submitter={{
                resetButtonProps: {style: {display: "none"}},
                submitButtonProps: {style: {display: "none"}}
            }}>
                <ProFormText name="name" label="源仓库" disabled/>
                <ProFormText name="address" label="所在地址" disabled/>
                <ProFormTreeSelect
                    name="targetDepartment"
                    label="目标单位"
                    allowClear
                    secondary
                    rules={[{required: true, message: "请选择目标单位"}]}
                    request={async () => {
                        return this.requestAllDepartment();
                    }}
                    fieldProps={{
                        showArrow: false,
                        filterTreeNode: true,
                        showSearch: true,
                        treeNodeFilterProp: "title",
                        onChange: this.onTargetDepartmentChange
                    }}
                />
                <ProFormSelect
                    label="目标仓库"
                    showSearch
                    name="targetRepository"
                    options={this.state.storehouseOption}
                    rules={[{required: true, message: "请选择目标仓库"}]}/>
                <ProFormTextArea
                    name="comment"
                    label="调拨说明"/>
                <EditableProTable headerTitle="领用库存"
                                  rowKey="id"
                                  defaultSize="small"
                                  search={false}
                                  options={false}
                                  pagination={false}
                                  editableFormRef={this.tableRef}
                                  recordCreatorProps={false}
                                  columns={this.solicitColumns}
                                  value={this.state.stocks}
                                  editable={{
                                      onSave: (_, data) => this.onEditSave(_, data),
                                      actionRender: (row, config, dom) => [dom.save, dom.cancel]
                                  }}/>
                <Button block style={{marginBottom: "16px"}}
                        onClick={() => this.chooseSparesRef.current.show(this.state.repositoryId, this.state.stocks)}>添加</Button>

                <Row gutter={8} justify="end">
                    <Col>
                        <Space>
                            <Button disabled={this.state.loading} onClick={this.close}>取消</Button>
                            <Button loading={this.state.loading} type="primary" htmlType="submit">调拨</Button>
                        </Space>
                    </Col>
                </Row>
            </ProForm>
            <ChooseSparesModal onSuccess={this.reload} ref={this.chooseSparesRef} title="选择调拨备件"/>
        </Drawer>;
    }
}

export default ScheduleInventoryDrawer;
