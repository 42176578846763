/**
 * 设备链接状态
 */
const linkStatus = {
    "0": {
        "text": "未连接"
    },
    "1": {
        "text": "已连接"
    },
    "2": {
        "text": "使用中"
    }
};
const feedbackStatus = {
    "0": {
        "text": "未完成"
    },
    "1": {
        "text": "已完成"
    }
};
//0 未接通 1已接通 2已转单
const workRecStatus = {
    "0": {
        "text": "未接通"
    },
    "1": {
        "text": "已接通"
    },
    "2": {
        "text": "已转单"
    }
};

export {
    linkStatus,
    feedbackStatus,
    workRecStatus
};
