import React, {Component} from 'react';
import DraggableModal from "../../../../component/DraggableModal";
import {Button, Col, Row, Space, Typography} from "antd";
import RemoteApi from "../../../../api/remote/RemoteApi";

/**
 * @author       : AngelBeats
 * @date         : 2023-06-01 16:07:05
 * @description  : 删除远程设备
 */
export default class DeleteRemoteDevice extends Component {

    draggableRef = React.createRef();

    state = {
        loading: false,
        remoteDeviceId: ""
    }

    /**
     * 显示
     */
    show = id => {
        this.draggableRef.current.show();
        this.setState({remoteDeviceId: id})
    }

    /**
     * 隐藏
     */
    close = () => {
        this.draggableRef.current.close();
    }

    /**
     * 显示loading
     */
    showLoading = () => this.setState({loading: true});

    /**
     * 隐藏loading
     */
    closeLoading = () => this.setState({loading: false})

    /**
     * 删除
     */
    handle = () => {
        this.showLoading();
        RemoteApi.remove(this.state.remoteDeviceId).then(res => {
            this.closeLoading();
            this.close();
            this.props.onSuccess();
        }).catch(err => {
            this.closeLoading();
            console.error("delete remote device failed", err)
        })
    }

    render() {
        return (<DraggableModal title="删除远程设备" ref={this.draggableRef} onCancel={this.close}>
            <Typography>数据删除后无法恢复，请谨慎操作！</Typography>
            <Row gutter={8} justify="end">
                <Col>
                    <Space>
                        <Button disabled={this.state.loading} onClick={this.close}>取消</Button>
                        <Button loading={this.state.loading} type="primary" onClick={this.handle}>删除</Button>
                    </Space>
                </Col>
            </Row>
        </DraggableModal>)
    }
}
