import React, {Component} from "react";
import {Button, Cascader, Col, DatePicker, Drawer, Form, Input, message, Row, Select, Space} from "antd";

import CustomerApi from "api/customer/customer";
import EquipmentListApi from "api/equipment/list";
import {setFieldsError} from "plugin/formily";

import style from "component/equipment/map/index.module.css";

import CustomerDepartmentPicker from "component/customer/department/CustomerDepartmentPicker";
import RemoteApi from "../../../../api/remote/RemoteApi";
import {dateToObject} from "../../../../utils/tools";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;

export default class UpdateRemoteDrawer extends Component {

    state = {
        show: false,
        loading: false,
        drawerWidth: 600,
        customerDepartmentsOptions: [],

        loadingCustomerDepartmentsOptions: false,
        areaData: [],
        equipmentTypeData: [],
        address: "",
        province: "",
        city: "",
        county: "",
        area: "",
        lnglatDataLng: "",
        lnglatDataLat: "",
        lnglat: "",
        isAddMap: false
    };
    formRef = React.createRef();
    customerDepartmentRef = React.createRef();
    filesRef = React.createRef();
    map;

    show = (id) => {
        this.setState({
            show: true,
            remoteDeviceId: id,
            drawerWidth: window.innerWidth < 576 ? window.innerWidth : window.innerWidth * 0.55
        }, () => {
            this.requestRemoteDeviceInfo(id)
            //this.getMap();
            this.getEquipmentType();
        });
    };

    close = () => this.setState({show: false, loading: false});

    toggleLoading = () => this.setState({loading: !this.state.loading});

    onCustomerDepartmentChange = departmentId => {
        this.formRef.current.setFieldsValue({customerId: null});
        this.requestCustomersOptions(departmentId);
        this.setState({departmentId});
    };

    requestCustomersOptions = departmentId => {

        CustomerApi.list({departmentId}).then(response => {
            const customerOptions = [];
            if (!!response.data && response.data.length > 0) {
                response.data.forEach((customer) => {
                    customerOptions.push(this.handleCustomerOption(customer));
                });
            }
            this.setState({customersOptions: customerOptions}, this.toggleCustomersOptionsLoading);
        }).catch(error => {
            console.warn("Get customers failed", error);

        });
    };

    handleCustomerOption = customer => {
        return {
            label: `${customer.name}(${customer.cellphone})`,
            value: customer.id
        };
    };

    /**
     * 查询远程设备信息
     * @param id
     */
    requestRemoteDeviceInfo = id => {
        RemoteApi.find(id).then(res => {
            this.setState({
                address: res.data.address,
                latitude: res.data.latitude,
                longitude: res.data.longitude,
                lnglat:`${res.data.longitude},${res.data.latitude}`
            }, () => {
                this.formRef.current.setFieldsValue({
                    sn: res.data.sn,
                    name: res.data.name,
                    customerDepartment: res.data.customerDepartment,
                    customer: res.data.customer,
                    administratorsName: res.data.administratorsName,
                    administratorsTel: res.data.administratorsTel,
                    empowerTime: [dayjs(res.data.empowerStartTime) , dayjs(res.data.empowerEndTime)],
                    address: res.data.address,
                    area: [ res.data.province, res.data.city, res.data.county],
                    detailAddress: res.data.detailAddress,
                })
                this.requestCustomersOptions(res.data.customerDepartment);
            })
        }).catch(err => {
            console.error("request remote device info failed", err)
        })
    }

    /**
     *控制弹窗
     */

    areaChange = value => {
        this.setState({areaData: value});
        this.formRef.current.setFieldsValue({address: ""});
    };

    getEquipmentType = () => {
        EquipmentListApi.queryType().then(response => {
            let selectData = [];
            response.data.forEach(item => {
                let obj = {
                    label: "",
                    value: ""
                };
                obj.label = item.name + " - " + item.model;
                obj.value = item.id;
                selectData.push(obj);
            });
            this.setState({equipmentTypeData: selectData});
        });
    };

    handle = data => {
        this.toggleLoading();
        data.id = this.state.remoteDeviceId;
        data.address = this.state.address;
        data.province = data.area[0];
        data.city = data.area[1];
        data.county = data.area[2];
        data.empowerStartTime = data.empowerTime[0];
        data.empowerEndTime = data.empowerTime[1];
        data.customerDepartment = this.state.customerDepartment;
        if (!!this.props.equipmentId) {
            data.id = this.props.equipmentId;
        }
        // if (!!data.installTime) {
        //     data.installTime = data.installTime.format("YYYY-MM-DD");
        // }
        // if (!!data.warrantyPeriod) {
        //     data.warrantyPeriod = data.warrantyPeriod.format("YYYY-MM-DD");
        // }
        if (!!this.state.longitude && !!this.state.latitude) {
            data.longitude = this.state.longitude;
            data.latitude = this.state.latitude;
            data.lnglat = `${this.state.longitude},${this.state.latitude}`;
        } else {
            message.warn({key: "lat", content: "经纬度不存在！"});
            this.toggleLoading();
            return;
        }
        delete data.area
        delete data.empowerTime
        //console.log(data)
        /**
         * 编辑远程设备
         *  RemoteApi.update
         *
         */

        RemoteApi.update(data).then(res => {
            this.toggleLoading();
            this.close();
            this.props.onSuccess();
        }).catch(err => {
            this.toggleLoading();
            console.error("create remote device failed", err)
        })

    };

    getMap = () => {
        this.map = new AMap.Map("container", {
            pitch: 0,
            viewMode: "3D",
            terrain: true,
            center: [114.973172, 25.817861],
            zoom: 17,
            zoomEnable: false,
            dragEnable: false,
            resizeEnable: true
        });

        AMap.plugin(["AMap.PlaceSearch", "AMap.AutoComplete"], () => {

            new AMap.AutoComplete({input: "address"}).on("select", e => {
                this.formRef.current.setFieldsValue({address: e.poi.name});
                this.map.clearMap();
                this.map.add(new AMap.Marker({position: [e.poi.location.lng, e.poi.location.lat]}));
                this.setState({lnglatDataLng: e.poi.location.lng, lnglatDataLat: e.poi.location.lat});
                this.map.setFitView();
                let lnglat = [e.poi.location.lng, e.poi.location.lat];
                this.regeoCode(lnglat);
            });
        });
    };

    /**
     * 当所选的地址与详细地址不同时更新所选的地址
     * @param lnglat 经纬度
     */
    regeoCode = lnglat => new AMap.Geocoder().getAddress(lnglat, (status, result) => {
        // 特别行政区或直辖市的 city 为空 所有直接用 province
        this.formRef.current.setFieldsValue({
            area: [
                result.regeocode.addressComponent.province,
                result.regeocode.addressComponent.city ? result.regeocode.addressComponent.city : result.regeocode.addressComponent.province,
                result.regeocode.addressComponent.district
            ]
        });
    });

    render() {
        return <Drawer title="更新远程设备"
                       width={this.state.drawerWidth}
                       open={this.state.show}
                       closable={!this.state.loading}
                       destroyOnClose
                       onClose={this.close}>
            <Form ref={this.formRef} onFinish={this.handle} layout={"vertical"}>
                <Row gutter={[8, 0]} align="middle">
                    <Col span={24}>
                        <Form.Item name="sn" label="设备识别码" required rules={[{required: true, message: "请输入设备识别码"}]}>
                            {!!this.props.sn ? <Input defaultValue={this.props.sn} disabled/> : <Input placeholder="请输入设备识别码"/>}
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name="name" label="设备名称" required
                                   rules={[{required: true, message: "请输入设备名称"}]}>
                            <Input placeholder="请输入设备名称"/>
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <CustomerDepartmentPicker ref={this.customerDepartmentRef}
                                                  label="设备所在单位"
                                                  name="customerDepartment"
                                                  rules={[{required: true, message: "请选择客户单位"}]}
                                                  onChange={this.onCustomerDepartmentChange}/>
                    </Col>

                    <Col span={24}>
                        <Form.Item name="administratorsName" label="管理员" required
                                   rules={[{required: true, message: "请输入设备名称"}]}>
                            <Input placeholder="请输入管理员"/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name="customer" label="客户"
                                   rules={[{ message: "请选择客户"}]}>
                            <Select options={this.state.customersOptions}
                                    loading={this.state.loadingCustomersOptions}
                                    showSearch optionFilterProp="label"/>
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item name="administratorsTel" label="管理员联系方式" required
                                   rules={[{required: true, message: "请输入管理员联系方式"}]}>
                            <Input placeholder="请输入管理员联系方式"/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name="area" label="选择地址" required
                                   rules={[{required: true, message: "请选择地址"},
                                       {pattern: /\S/g, message: "内容不能为空"}]}>
                            <Cascader options={require("asset/location.json")}
                                      onChange={this.areaChange}/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name="empowerTime" label="授权时间">
                            <RangePicker showTime format="YYYY-MM-DD" inputReadOnly style={{width: "100%"}}/>
                        </Form.Item>
                    </Col>

                    {/*<Col span={24}>*/}
                    {/*    <Form.Item name="address" label="设备所在地址" required*/}
                    {/*               rules={[{required: true, message: "请输入详细地址"}, {pattern: /\S/g, message: "内容不能为空"}]}>*/}
                    {/*        <Input id="address" autoComplete="off"/>*/}
                    {/*    </Form.Item>*/}
                    {/*</Col>*/}

                    {/*<Col span={24}>*/}
                    {/*    <div className={style.map} id="container">*/}
                    {/*    </div>*/}
                    {/*</Col>*/}
                    <Col span={24}>
                        <Form.Item name="detailAddress" label="楼号楼层及门牌号">
                            <Input placeholder="示例：主楼6层  1601"/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={8} justify="end">
                    <Col>
                        <Space>
                            <Button disabled={this.state.loading} onClick={this.close}>取消</Button>
                            <Button loading={this.state.loading} type="primary" htmlType="submit">更新</Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </Drawer>;
    }
}
