import React, {Component} from "react";
import {Button, Col, Drawer, Form, message, Row, Space} from "antd";
import {ProForm, ProFormSelect} from "@ant-design/pro-components";

import WangEditor from "component/editor/WangEditor";
import FileUploadRender from "component/FileUploadRender";

import EquipmentMakerApi from "api/equipment/maker";
import EquipmentListApi from "api/equipment/list";
import EquipmentRepositoryApi from "api/equipment/repository";
import WorkorderApi from "api/workorder";

import {setFieldsError} from "plugin/formily";

/**
 * 新建知识库文章
 * 填写表单对应内容 调用新建知识库文章接口 新建知识库文章
 */
export default class FromWorkorderCreateKnowledgeArticleDrawer extends Component {

    formRef = React.createRef();
    wangEditorRef = React.createRef();
    solutionWangEditorRef = React.createRef();
    questionFilesRef = React.createRef();
    solutionFilesRef = React.createRef();

    state = {
        drawerWidth: 600,
        show: false,
        loading: false,
        typeId: "",
        equipmentOptions: [],
        manufacturerOptions: [],
        workorderId: "",
        workorderInfo: {},
        equipmentInfo: {},
        workroderQuestionFiles: [],
        workroderSolutionFiles: []
    };

    show = workorderId => {
        this.setState({
            show: true,
            workorderId,
            drawerWidth: window.innerWidth < 576 ? window.innerWidth : window.innerWidth * 0.55
        }, () => {
            this.requestEquipmentManufacturer();
            this.requestWorkorderInfo(workorderId);
        });
    };

    close = () => {
        this.setState({
            show: false,
            loading: false,
            equipmentOptions: [],
            manufacturerOptions: [],
            workorderId: "",
            workorderInfo: {},
            equipmentInfo: {},
            workroderQuestionFiles: [],
            workroderSolutionFiles: []
        });
    };

    requestWorkorderInfo = (id) => {
        WorkorderApi.find(id).then(res => {
            const questionFiles = [];
            const solutionFiles = [];
            if (res.data.files !== null && res.data.files !== undefined && res.data.files.length > 0) {
                res.data.files.forEach(item => {
                    questionFiles.push({
                        uid: item.id,
                        name: item.name,
                        url: item.path,
                        thumbUrl: item.path,
                        type: item.type,
                        workorderId: item.workorderId
                    });
                });
            }
            if (res.data.resultFiles !== null && res.data.resultFiles !== undefined && res.data.resultFiles.length > 0) {
                res.data.resultFiles.forEach(item => {
                    solutionFiles.push({
                        uid: item.id,
                        name: item.name,
                        url: item.path,
                        thumbUrl: item.path,
                        type: item.type,
                        workorderId: item.workorderId
                    });
                });
            }
            this.questionFilesRef.current.setFilesValue(questionFiles);
            this.solutionFilesRef.current.setFilesValue(solutionFiles);
            this.wangEditorRef.current.setValue(res.data.description || "");
            this.solutionWangEditorRef.current.setValue(res.data.result || "");
            if (res.data.resourceId !== "0") {
                this.requestEquipmentInfo(res.data.resourceId);
            }
            this.setState({
                workorderInfo: res.data,
                workroderQuestionFiles: res.data.files,
                workroderSolutionFiles: res.data.resultFiles
            });
        }).catch(err => {
            console.error("Request workorder info failed", err);
        });
    };

    requestEquipmentInfo = (id) => {
        EquipmentListApi.info(id).then(res => {
            this.onManufacturerChange(res.data.manufacturerId);
            this.formRef.current.setFieldsValue({
                manufacturer: res.data.deviceType.manufacturer.id,
                devicetype: res.data.deviceType.id
            });
        }).catch(err => {
            console.error("Request equipment info failed", err);
        });
    };

    requestEquipmentManufacturer = () => {
        EquipmentMakerApi.queryAll().then(res => {
            if (res.data !== undefined && res.data !== null && res.data.length > 0) {
                let data = []
                res.data.forEach(item => {
                    data.push({label: item.name, value: item.id})
                });
                this.setState({
                    manufacturerOptions: data
                });
            }
        }).catch(err => {
            console.error("Request equipment type failed", err);
        });
    };

    formManufacturerFindType = (manufacturer) => {
        EquipmentListApi.queryType({manufacturer}).then(res => {
            if (res.data !== undefined && res.data !== null && res.data.length > 0) {
                res.data.forEach(item => {
                    this.setState({
                        equipmentOptions: [...this.state.equipmentOptions, {
                            label: item.name,
                            value: item.id
                        }]
                    });
                });
            }
        }).catch(err => {
            console.error("Form manufacturer find type failed", err);
        });
    };

    onManufacturerChange = (value) => {
        this.formRef.current.setFieldsValue({devicetype: ""});
        this.setState({equipmentOptions: []});
        this.formManufacturerFindType(value);
    };

    removeQuestionFiles = (file) => {
        console.log(file);
        if (!!file.workorderId) {
            this.setState({workroderQuestionFiles: this.state.workroderQuestionFiles.filter(item => item.workorderId !== file.workorderId)});
            return true;
        }
        return global.axios.delete(`/resource/knowledge/question/file?path=${file.url}`).then(response => {
            console.log("delete file success", response);
            return true;
        }).catch(err => {
            return false;
        });
    };
    removeSolutionFiles = (file) => {
        if (!!file.workorderId) {
            this.setState({workroderSolutionFiles: this.state.workroderSolutionFiles.filter(item => item.workorderId !== file.workorderId)});
            return true;
        }
        return global.axios.delete(`/resource/knowledge/solution/file?path=${file.url}`).then(response => {
            console.log("delete file success", response);
            return true;
        }).catch(err => {
            return false;
        });
    };

    toggleLoading = () => this.setState({loading: !this.state.loading});

    handle = data => {
        data.question = this.wangEditorRef.current.getValue();
        data.questionFiles = this.questionFilesRef.current.getFilesValue().filter(item => !item.workorderId);
        data.solution = this.solutionWangEditorRef.current.getValue();
        data.solutionFiles = this.solutionFilesRef.current.getFilesValue().filter(item => !item.workorderId);
        data.workroderQuestionFiles = this.state.workroderQuestionFiles;
        data.workroderSolutionFiles = this.state.workroderSolutionFiles;
        data.workorder = this.state.workorderId;
        this.toggleLoading();
        EquipmentRepositoryApi.create(data).then(res => {
            this.toggleLoading();
            this.close();
            this.props.onSuccess();
        }).catch(err => {
            this.toggleLoading();
            if (err.status === 40000) {
                this.toggleLoading();
                setFieldsError(err, this.formRef);
            } else {
                message.error({key: "failed", content: [err.data]});
            }
            console.warn("Create equipment repository failed.", err);
        });
    };

    render() {
        return <Drawer title="新建设备知识文章"
                       destroyOnClose
                       width={this.state.drawerWidth}
                       open={this.state.show}
                       closable={!this.state.loading}
                       onClose={this.close}>
            <ProForm formRef={this.formRef} onFinish={this.handle} layout={"vertical"} submitter={{
                resetButtonProps: {style: {display: "none"}},
                submitButtonProps: {style: {display: "none"}}
            }}>
                <ProFormSelect
                    name="manufacturer"
                    label="制造商"
                    showSearch
                    fieldProps={{onChange: this.onManufacturerChange}}
                    options={this.state.manufacturerOptions}
                    placeholder="请选择设备制造商"
                    rules={[{required: true, message: "请选择设备制造商"}]}
                />
                <ProFormSelect
                    name="devicetype"
                    label="设备类型"
                    showSearch
                    options={this.state.equipmentOptions}
                    placeholder="请选择设备类型"
                    rules={[{required: true, message: "请选择设备类型"}]}
                />
                <Form.Item name="question" label="问题描述" required>
                    <WangEditor ref={this.wangEditorRef}/>
                </Form.Item>
                <FileUploadRender name="questionFiles" uploadPath="/resource/knowledge/question/file"
                                  ref={this.questionFilesRef} label="问题附件"
                                  onRemove={this.removeQuestionFiles}/>
                <Form.Item name="solution" label="解决方案" required>
                    <WangEditor ref={this.solutionWangEditorRef} documentNodeId="solution-Node"/>
                </Form.Item>
                <FileUploadRender name="solutionFiles" uploadPath="/resource/knowledge/solution/file"
                                  ref={this.solutionFilesRef} label="解决方案附件" onRemove={this.removeSolutionFiles}/>
                <Row gutter={8} justify="end">
                    <Col>
                        <Space>
                            <Button disabled={this.state.loading} onClick={this.close}>取消</Button>
                            <Button loading={this.state.loading} type="primary" htmlType="submit">新建文章</Button>
                        </Space>
                    </Col>
                </Row>
            </ProForm>
        </Drawer>;
    }
}
