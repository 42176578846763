/**
 * 工单逻辑类型
 */
const logicType = {
    "ops": {
        "text": "运维"
    },
    "smp": {
        "text": "信创"
    }
};

export default logicType;
