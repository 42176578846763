import React, {Component} from "react";
import WorkorderApi from "../../../../../../api/workorder";
import {setFieldsError} from "../../../../../../plugin/formily";
import DraggableModal from "../../../../../../component/DraggableModal";
import {Button, Col, Row, Space, Typography} from "antd";

/**
 * 反工
 *
 * @description 工单反工
 */
export default class ReworkWorkorderDialog extends Component {

    state = {
        loading: false,
        show: false,
        workorderId: ""
    };
    draggableRef = React.createRef();

    show = id => {
        this.draggableRef.current.show();
        this.setState({workorderId: id});
    };

    close = () => {
        this.setState({loading: false});
        this.draggableRef.current.close();
    };

    toggleLoading = () => this.setState({loading: !this.state.loading});

    /**
     * 工单提交返工
     * @param data
     */
    handle = data => {
        this.toggleLoading();
        data.workorderId = this.state.workorderId;

    };
    render() {
        return (<DraggableModal title="删除远程设备" ref={this.draggableRef} onCancel={this.close}>
            <Typography>数据删除后无法恢复，请谨慎操作！</Typography>
            <Row gutter={8} justify="end">
                <Col>
                    <Space>
                        <Button disabled={this.state.loading} onClick={this.close}>取消</Button>
                        <Button loading={this.state.loading} type="primary" onClick={this.handle}>删除</Button>
                    </Space>
                </Col>
            </Row>
        </DraggableModal>)
    }
}
