import React from "react";
import {Button, Col, Row, Space} from "antd";

import WorkorderApi from "api/workorder";
import {ProForm} from "@ant-design/pro-components";
import {setFieldsError} from "plugin/formily";
import DraggableModal from "component/DraggableModal";

/**
 * 开始工单弹窗 Modal
 *
 * @description 开始工单
 *
 * @author huang Carder
 * @since 2021-06-23
 * @version 1.0.0
 */
export default class ReceiveWorkorderDialog extends React.Component {

    state = {
        loading: false,
        show: false,
        modificationFrom: false
    };

    draggableRef = React.createRef();
    formRef = React.createRef();

    /**
     * 控制驳回工单对话框显示隐藏
     */

    show = () => this.draggableRef.current.show();

    close = () => {
        this.setState({loading: false});
        this.draggableRef.current.close();
    };

    /**
     * 控制 loading加载效果 显示
     */
    toggleLoading = () => this.setState({loading: !this.state.loading});

    /**
     * 开始工单
     * @param data 表单数据
     */
    handle = data => {
        this.toggleLoading();
        data.workorderId = this.props.workorder.id;
        WorkorderApi.start(data).then(response => {
            this.close();
            if (!!this.props.onSuccess) {
                this.props.onSuccess();
            }
        }).catch(error => {
            this.toggleLoading();
            console.warn("Receive workorder failed", error);
            setFieldsError(error,this.formRef);
        });
    };

    renderWorkorderHangUpComment = () => {
        return <div>工单挂起原因: <span
            style={{fontWeight: "bold"}}>{this.props.workorder.logs[this.props.workorder.logs.length - 1].comment === "" ? "未填写挂起原因" : this.props.workorder.logs[this.props.workorder.logs.length - 1].comment}</span>
        </div>;
    };

    trigger = () => <Button onClick={this.show}>开始工作</Button>;

    dialog = () => {
        return <DraggableModal onCancel={this.close} title="开始工单" ref={this.draggableRef}>
            <ProForm formRef={this.formRef} onFinish={this.handle} layout={"vertical"} submitter={{
                resetButtonProps: {style: {display: "none"}},
                submitButtonProps: {style: {display: "none"}}
            }}>
                {
                    this.props.workorder.progress === 14 ? this.renderWorkorderHangUpComment() : null
                }
                <span>计划时间：</span>{this.props.workorder.deadLine}
                <Row gutter={8} justify="end">
                    <Col>
                        <Space>
                            <Button disabled={this.state.loading} onClick={this.close}>取消</Button>
                            <Button loading={this.state.loading} type="primary" htmlType="submit">开始工单</Button>
                        </Space>
                    </Col>
                </Row>
            </ProForm>
        </DraggableModal>;
    };

    render() {
        return <>
            {this.trigger()}
            {this.dialog()}
        </>;
    }
}
