import {getUser, hasAnyRole, isAdmin} from "component/SecurityInterceptor";

export function isStorehouseAdmin(storehouseAdminIds) {
    if (!!storehouseAdminIds) {
        let isStorehouseAdmin = storehouseAdminIds.some(item => item === getUser().id);
        return !!isAdmin() || hasAnyRole(["repository-manager", "user-department-manager"]) || isStorehouseAdmin;
    } else {
        return !!isAdmin() || hasAnyRole(["repository-manager", "user-department-manager"]);
    }
}

export function isStorehouseAdminOrEngineer(storehouseEngineersIds) {
    if (!!storehouseEngineersIds) {
        let isStorehouseEngineers = storehouseEngineersIds.some(item => item === getUser().id);
        return !!isAdmin() || hasAnyRole(["repository-manager", "user-department-manager"]) || isStorehouseEngineers;
    } else {
        return !!isAdmin() || hasAnyRole(["repository-manager", "user-department-manager"]);
    }
}
